import React, { useContext, useEffect, useState } from 'react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { g_cns } from '../common/cns';
import { appIconTypes } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import GenericIcon from '../genericControls/GenericIcon';
import { itemActionTypes } from '../viewSettings/enums/itemActionTypes';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ComponentContext } from '../cnr/contexts/ComponentContext';

let _iconSize = 'large'
const _allowSettingsPopup = false

/**
 * 
 * @param {object} props (forArray, componentContexts)
 * @returns a popup icon element that will appear at the bottom right of 
 * uiItem IF there are valid actions associated with the item
 */
const UiItemPopupIcon = (props) => {

  const { forArray, viewItem } = props

  // componentContexts
  const componentContext = useContext(ComponentContext)
  const { component_state } = componentContext ?? {}
  const { componentContexts } = component_state ?? {}

  // componentContexts
  const { dataContext, pageAreaContext } = componentContexts ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, paps_state, videoConference_state } = states

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { dashboardType } = framework_state ?? {}

  // pageContext
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}
  const { imageMapping: dataOptions, dataPermissions } = aps_global ?? {}
  const { reduceSingleItemMenus } = dataOptions ?? {}
  const { showOptionsInNavigation } = dataPermissions ?? {}

  // papsContext 
  const { viewPathKey } = paps_state ?? {}

  // videoConferenceContext 
  const { meetingRooms } = videoConference_state ?? {}

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.actionButton] : {}

  if (forArray) { _iconSize = 'small' }

  // pageAreaContext 
  const { pageArea_state } = pageAreaContext ?? {}
  const { pageItemsShown } = pageArea_state ?? {}

  // dataContext 
  const { data_state } = dataContext ?? {}
  let { uivi } = data_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state, item_handlers } = uiItemContext ?? {}
  const { navigationOptions, allowItemPopup, modifyActionType } = item_state ?? {}
  const { handleShow_itemSidebar, handleShow_settingsSidebar } = item_handlers ?? {}

  const meetingInProgress = meetingRooms && meetingRooms[viewPathKey] && meetingRooms[viewPathKey].meetingInProgress ? true : false

  let _viewItem;

  if (viewItem) {
    _viewItem = viewItem
    uivi = viewItem.key
  } else {
    _viewItem = pageItemsShown ? pageItemsShown[uivi] : {}
  }

  const [singleItem, setSingleItem] = useState()

  const getSingleItem = (actionItems) => {
    let _singleItem;
    if (actionItems) {
      Object.keys(actionItems).forEach(actionItemKey => {
        const actionItem = actionItems[actionItemKey]
        Object.keys(actionItem).forEach(itemKey => {
          _singleItem = {
            actionItemKey,
            itemKey
          }
        })
      })
    }
    return _singleItem
  }

  useEffect(() => {
    if (allowItemPopup && allowItemPopup.count && navigationOptions && showOptionsInNavigation) {
      setSingleItem(getSingleItem(navigationOptions))
      const navItems = {}
      navItems['search'] = { icon: 'content', caption: 'More', oc: handleShow_itemSidebar }
      framework_handlers.handleAdd_navigationItems(navItems)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [uivi]);

  const handleSingleClick = () => {
    const { dataModifications } = _viewItem ?? {}
    const { add } = dataModifications ?? {}
    const { appFormType } = add ?? {}
    const at = { modifyActionType: singleItem.itemKey, appFormType }
    item_handlers.handleSet_modifyAction(at)
  }

  const optionButtons = {
    showSave: true,
    showCancel: true
  }

  // turn off save and cancel
  switch (modifyActionType) {
    case itemActionTypes.add:
    case itemActionTypes.appUserInfo:
    case itemActionTypes.createGlobalData:
    case itemActionTypes.createNotification:
    case itemActionTypes.dataLinking:
    case itemActionTypes.dataActions:
    case itemActionTypes.delete:
    case itemActionTypes.createFilter:
    case itemActionTypes.createTopic:
    case itemActionTypes.edit:
    case itemActionTypes.googleSheetLink:
    case itemActionTypes.googleSheetUpdate:
    case itemActionTypes.imageMapping:
    case itemActionTypes.imageUpload:
    case itemActionTypes.openDatabase:
    case itemActionTypes.rating:
    case itemActionTypes.subAction:
    case itemActionTypes.viewAppUserAuthorizations:
      optionButtons.showSave = false
      optionButtons.showCancel = false
      break;

    default:
    // nothing
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const itemPopup = () => {
    if (reduceSingleItemMenus && allowItemPopup.count === 1) {
      return <span className={g_cns.item_popup + ' ' + uivi}>
        {gi('item-icon-ask', 'add', handleSingleClick, null, { circular: true, size: _iconSize, color: 'blue', inverted: true, sac: styleAndClass })}
      </span>
    } else {
      return <span className={g_cns.item_popup + ' ' + uivi}>
        {gi('item-icon-ask', appIconTypes.ellipsisVertical, handleShow_itemSidebar, null, { circular: true, size: _iconSize ? _iconSize : null, color: 'blue', inverted: true, sac: styleAndClass })}
      </span>
    }
  }

  const settingsPopup = () => <span className={g_cns.item_settings}>
    {gi('item-icon-settings', appIconTypes.cog, handleShow_settingsSidebar, null, { circular: true, size: _iconSize, color: 'green', inverted: true })}
  </span>

  const meetingPopup = () => <span className={g_cns.item_meeting}>
    {gi('item-icon-meeting', appIconTypes.videos, handleShow_itemSidebar, null, { circular: true, size: _iconSize, color: 'green', inverted: true })}
  </span>

  if (allowItemPopup.allow) {
    return <React.Fragment>
      {_allowSettingsPopup && settingsPopup()}
      {allowItemPopup.allow && !showOptionsInNavigation && itemPopup()}
      {meetingInProgress && meetingPopup()}
    </React.Fragment>
  } else {
    return <div></div>
  }
}

export default UiItemPopupIcon