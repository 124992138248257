import React, { useContext, useEffect, useRef } from 'react';
import { Reveal } from 'semantic-ui-react';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { PageDimmerContext } from '../../cnr/contexts/PageDimmerContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { _animateTypes } from '../../motions/AnimateComponent';
import MotionComponent from '../../motions/MotionComponent';
import GridItemsSort from './GridItemsSort';
import IconGrid, { permissionGroupTypes } from './IconsGrid';
import { gridIconElements } from './gridItems/SegIconGroup';
import { AppSettingsContext } from '../../cnr/contexts/AppSettingsContext';

/**
 * 
 * @param {object} props (pageIcons) 
 * @returns an element containing a group of gridIcons. Each gridIcon will redirect the app to a new page
 */
const IconGrids = (props) => {

  const { pageIcons } = props

  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}

  const { appSettings_state, page_state, storage_state, transition_state } = states
  const { page_fns } = fns
  const { globalFiles } = storage_state ?? {}
  const { image: image_global } = globalFiles ?? {}

  // transitionContext  
  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.landing] : null
  const { showTransition } = transition ?? {}

  // appSettingsContext 
  const { propOverrideOn } = appSettings_state

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { permissionGroups } = framework_state ?? {}

  const pageDimmerContext = useContext(PageDimmerContext)
  const { pageDimmer_handlers } = pageDimmerContext ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles, aps_page, aps_viewItems } = pageSettings ?? {}
  const { syncViewItems } = page_fns ?? {}

  const backgroundSaC = aps_styles ? aps_styles[gEnums.projectStyles.background] : {}
  const { style } = backgroundSaC ?? {}
  const { bgi } = style ?? {}

  const { appVoting } = aps_global ?? {}
  const { themedItems } = aps_global ?? {}
  const { background } = themedItems ?? {}
  const { useBackgroundImage } = background ?? {}

  const { allowVoting, showVotingManagerOnHomeScreen, showVotingOnHomeScreen } = appVoting ?? {}
  const { gridIcons } = aps_global && aps_global.themedItems ? aps_global.themedItems : {}
  const { iconsPerRow: iconsPerRow_gi } = gridIcons ?? {}

  let _backgroundImage;
  if (useBackgroundImage && image_global && bgi && image_global[bgi] && image_global[bgi].urls && image_global[bgi].urls.full) {
    _backgroundImage = image_global[bgi].urls.full
    backgroundSaC.style.backgroundImage = `url(${_backgroundImage})`
    delete backgroundSaC.style.bgi
  }

  // eslint-disable-next-line 
  const globalIconsPerRow = iconsPerRow_gi ? iconsPerRow_gi : 3

  const { layout: layout_page } = aps_page ?? {}
  const { iconsPerRow } = layout_page ?? {}

  let _iconsPerRow = iconsPerRow ? iconsPerRow : globalIconsPerRow

  const segProps = {}

  const defaultSnC = aps_styles ? aps_styles[gEnums.projectStyles.gridIcons] : {}

  if (syncViewItems) { syncViewItems(aps_global, aps_viewItems, aps_page) }

  let iconCount = permissionGroups && permissionGroups.page ? Object.keys(permissionGroups.page).length : 0

  if (!pageIcons && allowVoting && (showVotingManagerOnHomeScreen || showVotingOnHomeScreen)) { iconCount++ }

  if (iconCount <= _iconsPerRow) { _iconsPerRow = iconCount }

  const itemRef = useRef()
  const { current: currentRef } = itemRef ?? {}

  useEffect(() => {
    pageDimmer_handlers && pageDimmer_handlers.handleKill_pageDimmer()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentRef]);

  const gridContainer = (contents, appUserContents, appAdminContents, ticketingContents, registrationContents, adminContents, hiddenContents) => {
    return <div key={uniqueKey('iggc')} {...backgroundSaC}>
      <div ref={itemRef}></div>
      <div className={'grid-icons-container'}  {...segProps}>
        <div className={defaultSnC.className}>
          {contents}
          {appUserContents}
          {appAdminContents}
          {ticketingContents}
          {registrationContents}
          {adminContents}
          {hiddenContents}
        </div>
      </div>
    </div>
  }

  const content = () => {
    const { ready, pageHidden, appAdmin, appUser, admin, ticketing } = permissionGroups ?? {}
    if (ready) {
      return gridContainer(
        <IconGrid permissionGroupType={permissionGroupTypes.page} />,
        appUser.length > 0 && <IconGrid permissionGroupType={permissionGroupTypes.appUser} />,
        appAdmin.length > 0 && <IconGrid permissionGroupType={permissionGroupTypes.appAdmin} />,
        ticketing.length > 0 && <IconGrid permissionGroupType={permissionGroupTypes.ticketing} />,
        admin.length > 0 && <IconGrid permissionGroupType={permissionGroupTypes.admin} />,
        pageHidden.length > 0 && <IconGrid permissionGroupType={permissionGroupTypes.pageHidden} />,
      )
    } else {
      return <div></div>
    }
  }

  const gridContent = () => {
    if (showTransition) {
      return <MotionComponent transition={transition}>
        {content()}
      </MotionComponent>
    } else {
      return content()
    }
  }

  const settingsReveal = (c) => {
    return <Reveal animated='small fade'>
      <Reveal.Content visible>
        {c}
      </Reveal.Content>
      <Reveal.Content hidden>
        <div>Grid Settings</div>
      </Reveal.Content>
    </Reveal>
  }

  const useReveal = false

  if (propOverrideOn) {
    const gridItems = gridIconElements({ gridItemGroup: permissionGroups.page, textLimit: 14 })
    return <GridItemsSort
      gridItems={gridItems}
      gridItemGroup={permissionGroups.page}
      defaultSnC={defaultSnC}
      backgroundSaC={backgroundSaC}
      iconsPerRow={_iconsPerRow}
    />
  } else {
    return useReveal ? settingsReveal(gridContent()) : gridContent()
  }
}

export default IconGrids