import React, { createContext, useEffect, useReducer } from 'react';
import { serviceWorkerHandlers, serviceWorkerInitialState, serviceWorkerReducer } from '../reducers/ServiceWorkerReducer';

export const _appVersion = '2.8.9';

export const ServiceWorkerContext = createContext();

/**
 * Simple wrapper to handle app settings changes
 */
const ServiceWorkerProvider = (props) => {

  const init_state = {}
  const [serviceWorker_state, serviceWorker_dispatch] = useReducer(serviceWorkerReducer, serviceWorkerInitialState(init_state))
  const serviceWorker_handlers = serviceWorkerHandlers(serviceWorker_dispatch)

  useEffect(() => {
    serviceWorker_handlers.handleInit_serviceWorker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ServiceWorkerContext.Provider value={{ serviceWorker_state, serviceWorker_handlers, appVersion: _appVersion }}>
    {props.children}
  </ServiceWorkerContext.Provider>
};

export default ServiceWorkerProvider