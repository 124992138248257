import { _appVersion } from '../../cnr/contexts/ServiceWorkerContext';
import { dataFix } from '../../common/dataFix';
import { getNow } from '../../common/dateFormatting';
import { _settingsFs } from '../../viewSettings/actions/getSettings';
import { createRefPath } from '../appData/appRefPaths';
import { doc_set } from "../appData/fsData";

/**
 * Converts the `settings_dev` document in the `settings` database
 * @param {*} baseRef 
 * @param {*} dataUpdate 
 * @param {*} isGlobal 
 * @param {*} updateViewItems 
 * @param {*} updateViews 
 * @returns 
 */
export const convertSettingsUpdate = async (settingsDocName, previewInfo, appUser, optz) => {

  const { noMerge } = optz ? optz : {}

  const promises = []

  const { settings } = previewInfo
  const { global, viewItems, views } = dataFix.removeAllEmpties(settings)

  const { uid, displayName } = appUser ?? {}

  const status = {
    appVersion: _appVersion,
    lastUpdate: getNow(),
    hasSettings: true,
  }

  if (uid) { status.uid = uid }
  if (displayName) { status.displayName = displayName }

  const baseRef = createRefPath([_settingsFs.root, settingsDocName])

  const merge = noMerge ? false : true

  promises.push(doc_set(baseRef, status, merge))

  const rp_g = createRefPath([_settingsFs.collection, 'global'], baseRef)
  promises.push(doc_set(rp_g, global, true))

  const rp_vi = createRefPath([_settingsFs.collection, 'viewItems'], baseRef)
  promises.push(doc_set(rp_vi, viewItems, true))

  const rp_v = createRefPath([_settingsFs.collection, 'views'], baseRef)
  promises.push(doc_set(rp_v, views, true))

  return Promise.all(promises)
}
