import { removeFromObject } from "../../../../../global/common/sorting"
import _ from 'lodash'
// import { geGCGHandicapIndex } from "../../../golfCalcs/courseHandicap"
import { golfEnums } from "../../../enums/golfEnums"
import { getMatchHandicap } from "../../../removed/createGolfRoundMatches"

export const getAllMatches = (rounds, matches) => {
  let _matches = {}
  if (rounds && matches) {
    Object.keys(rounds).forEach(roundKey => {
      if (matches[roundKey]) {
        _matches = { ..._matches, ...matches[roundKey] }
      }
    })
  }
  return _matches
}

// /clients/6r28jfLCnvuUEcm7Nmuy/events/MclBA6G2EKB6wlaGLqZb/golfTournaments/Mob4SKF9zEHawpGolfG9iEgoE/details/details
// /clients/6r28jfLCnvuUEcm7Nmuy/events/MclBA6G2EKB6wlaGLqZb/golfTournaments/Mob4SKF9zEHawG9iEgoE/details/details

export const getTournamentCourses = (state) => {
  const { details, golfEvent_state } = state ?? {}
  const { golfCourses: golfCourses_all } = golfEvent_state ?? {}
  const { rounds } = details ?? {}

  if (golfCourses_all && rounds) {
    const _golfCourses = {}
    Object.keys(rounds).forEach(key => {
      const round = rounds[key]
      const { golfCourse } = round ? round : {}
      if (golfCourse && golfCourses_all[golfCourse]) {
        _golfCourses[golfCourse] = golfCourses_all[golfCourse]
      }
    })
    return _golfCourses
  }
}

export const ammendTournamentGolfers = (golfers_event, golfers_tournament, golfers_auth) => {

  const golfers = {}

  if (golfers_event && golfers_tournament) {
    // loop the golfers in the tournament
    Object.keys(golfers_tournament).forEach(gk => {
      const golfer_event = golfers_event[gk]
      const glf = golfers_tournament[gk]
      if (golfer_event) {
        const { firstName, lastName, handicapSourceType, handicapIndex, _dateProps } = golfer_event
        glf._itemKey = gk
        glf.handicapIndex = handicapIndex
        glf.handicapSourceType = handicapSourceType
        glf._dateProps = _dateProps
        glf.firstName = firstName
        glf.lastName = lastName
        glf.name = firstName + ' ' + lastName
        glf.shortName = getShortName(glf)
        if (golfers_auth && golfers_auth[gk]) { glf._authData = golfers_auth[gk] }
        removeFromObject(glf, ['oldKey', 'tournamentTeams', 'handicap'])
        golfers[gk] = glf
      }
    })

    // Object.keys(golfers).forEach(k => {
    //   const g = golfers[k]
    //   golfers[k].tournamentHandicapIndex = golfers[k].handicapIndex
    // })
    // }

    // console.log('ammendGolfers', golfers)

    return golfers
  }
}

export const ammendMatchTeamGolfers = (match, teamGolfers, golfers, allGolfers, tournamentGolfCourses, rounds, missing, golfers_auth) => {

  if (teamGolfers) {

    Object.keys(teamGolfers).forEach(gk => {
      const glf = teamGolfers[gk]
      let golfer = golfers[gk]
      if (!golfer) {
        if (allGolfers && allGolfers[glf.oldKey]) {
          golfer = allGolfers[glf.oldKey]
          missing[gk] = glf
        }
      }

      if (golfer) {

        const { round: roundType } = match
        // const _round = rounds && rounds[roundType]
        // const { golfCourse: golfCourseKey } = _round ? _round : {}
        // const _ch = geGCGHandicapIndex(false, golfer, golfCourseKey, tournamentGolfCourses)

        glf.firstName = golfer.firstName
        glf.lastName = golfer.lastName
        glf.name = golfer.name
        glf.courseHandicaps = golfer.courseHandicaps
        glf.courseTeeboxes = golfer.courseTeeboxes
        glf.courseTeebox = golfer.courseTeeboxes ? Object.values(golfer.courseTeeboxes)[0] : null
        glf.courseHandicap = golfer.courseHandicaps ? Object.values(golfer.courseHandicaps)[0] : null
        glf.tournamentHandicapIndex = golfer.tournamentHandicapIndex
        glf.matchHandicap = getMatchHandicap(glf.courseHandicap, roundType)
        glf.shortName = getShortName(glf)
        if (golfers_auth && golfers_auth[gk]) { glf._authData = golfers_auth[gk] }

        removeFromObject(glf, ['oldKey', 'tournamentTeams', 'handicap'])

      }
    })
  }
}

export const ammendMatchGolfers = (golfTournamentMatches, golfers, allGolfers, tournamentGolfCourses, rounds, missing, golfers_auth) => {
  Object.keys(golfTournamentMatches).forEach(k1 => {
    const match = golfTournamentMatches[k1]
    const { team1, team2 } = match ?? {}
    ammendMatchTeamGolfers(match, team1, golfers, allGolfers, tournamentGolfCourses, rounds, missing, golfers_auth)
    ammendMatchTeamGolfers(match, team2, golfers, allGolfers, tournamentGolfCourses, rounds, missing, golfers_auth)
  })
}

/**
 * Add additional information about the golfer into the lineups
 * @param {object} lineups 
 * @param {object} golfers 
 */
export const ammendLineupsWithGolfers = (lineups, golfers) => {
  if (lineups) {
    Object.keys(lineups).forEach(teamKey => {
      const teamLineups = lineups[teamKey]
      Object.keys(teamLineups).forEach(tlKey => {
        const teamLineup = teamLineups[tlKey]
        if (_.isArray(teamLineup)) {
          teamLineup.forEach(golfer => {
            const golfer_event = golfers[golfer.golferKey]
            const { firstName, lastName, name, courseHandicaps, courseTeeboxes, teebox } = golfer_event ? golfer_event : {}
            golfer.key = golfer.golferKey
            golfer.firstName = firstName
            golfer.lastName = lastName
            golfer.name = name
            golfer.courseHandicaps = courseHandicaps
            golfer.courseTeeboxes = courseTeeboxes
            golfer.teebox = teebox
          })
        } else {
          const { golfers } = teamLineup ? teamLineup : {}
          if (golfers) {
            Object.keys(golfers).forEach(gKey => {
              const g = golfers[gKey]
              g.key = gKey
            })
          }
        }
      })
    })
  }
}

export const getSeletedTeamGolfers = (selectedLineupItems, golfers_lineup, golfers_team) => {
  const _selectedTeamGolfers = {}
  if (selectedLineupItems) {
    if (golfers_lineup) {
      selectedLineupItems.forEach(key => {
        const fg = _.find(golfers_lineup, { golferKey: key })
        if (fg) {
          _selectedTeamGolfers[key] = fg
        }
      })
    } else if (golfers_team) {
      selectedLineupItems.forEach(key => {
        if (golfers_team[key]) {
          _selectedTeamGolfers[key] = golfers_team[key]
        }
      })
    }
  }
  return _selectedTeamGolfers
}

export const getPar = (holes) => {
  let _par = 0
  if (holes) {
    Object.keys(holes).forEach(hKey => {
      _par += parseInt(holes[hKey].par)
    })
  }
  return _par
}

export const roundColor = (roundType) => {
  switch (roundType) {
    case golfEnums.roundTypes.singles:
      return '#65c031'
    case golfEnums.roundTypes.alternateShot:
    case golfEnums.roundTypes.alternateShot1:
    case golfEnums.roundTypes.alternateShot2:
      return '#e08f06'
    default:
      return '#57a7fd'
  }
}

const getShortName = (glf) => {
  const { firstName, lastName } = glf
  return firstName.substring(0, 1) + '. ' + lastName
}

