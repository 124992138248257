import _ from 'lodash';
import React, { Suspense, useContext, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { fsfn_createGolfRoundMatches, fsfn_updateGolfRoundMatches } from '../../../../projectSpecific/golf/functions/fbGolf';
import { createMatchScorecardsWithResults } from '../../../../projectSpecific/golf/golfCalcs/createMatchScorecards';
import CreateLab from '../../../../projectSpecific/lab/create/CreateLab';
import { SportsSeasonContext } from '../../../../projectSpecific/sports/cnr/contexts/SportsSeasonContext';
import { createScheduleTypes } from '../../../../projectSpecific/sports/cnr/reducers/CreateSportsReducer';
import { matchUpdateTypes } from '../../../../projectSpecific/sports/cnr/reducers/SportsSeasonReducer';
import { updateTeamAlignment } from '../../../../projectSpecific/sports/dbActions/updateAlignment';
import { updateAllStarTeams } from '../../../../projectSpecific/sports/dbActions/updateAllStarTeams';
import { updateTheMatch } from '../../../../projectSpecific/sports/dbActions/updateMatch';
import { updateRankings } from '../../../../projectSpecific/sports/dbActions/updateRankings';
import { updateScheduleDates } from '../../../../projectSpecific/sports/dbActions/updateScheduleDates';
import { updateScheduledMatches } from '../../../../projectSpecific/sports/dbActions/updateSeasonSchedule';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { AppComponentContext } from '../../../cnr/contexts/AppComponentContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import { creatingHelpers } from '../../../common/creating';
import SuspenseDimmer from '../../../components/alerts/SuspenseDimmer';
import { gEnums } from '../../../enums/globalEnums';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import UiConfirmation from '../UiConfirmation';
import UpdateWrapper from '../UpdateWrapper';
import { CreateMatchResult_withProvider } from '../../../../projectSpecific/sports/create/CreateMatchResult';

const AppUserTicketingWithProvider = React.lazy(() => import('../../../../projectSpecific/ticketing/components/AppUserTicketing'));
const CreateAlignment = React.lazy(() => import('../../../../projectSpecific/sports/create/subs/CreateAlignment'));
const CreateGolf = React.lazy(() => import('../../../../projectSpecific/golf/create/CreateGolf'));
const CreateMatchResult = React.lazy(() => import('../../../../projectSpecific/sports/create/CreateMatchResult'));
const CreateSchedule = React.lazy(() => import('../../../../projectSpecific/sports/create/subs/CreateSchedule'));
const CreateSectionDates = React.lazy(() => import('../../../../projectSpecific/sports/create/subs/CreateSectionDates'));
const CreateSports = React.lazy(() => import('../../../../projectSpecific/sports/create/CreateSports'));
const GolfParentProvider = React.lazy(() => import('../../../../projectSpecific/golf/cnr/contexts/GolfParentContext'));
const MatchCreate = React.lazy(() => import('../../../../projectSpecific/sports/elements/matches/MatchCreate'));
const QrScan = React.lazy(() => import('../pageItemActions/QrScan'));

// const AppUserProfilePagesWithProvider = React.lazy(() => import("../../components/user/AppUserProfilePages")); 

const UiSubAction = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { subActionType, fromDashboard, subActionTypeDirect, handleClose } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, appUser_state, page_state } = states

  // papsContext 
  const { pathViews, viewKey } = paps_state ?? {}

  // pageContext
  // const pageContext = useContext(PageContext);
  // const { appUser_state, page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_viewItems } = pageSettings ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { email } = appUserAccess ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { latestSeason, sportsKey, sportPermissions } = sportsSeason_state ?? {}

  const giftCardContext = useContext(AppComponentContext)
  const { giftCard_handlers } = giftCardContext ?? {}

  // only to get the editProps
  // UiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers, item_state } = uiItemContext ?? {}
  const { viewItem, viewListData } = item_state ?? {}
  const { key: viKey, dataModifications } = viewItem ?? {}
  const { subAction } = dataModifications ?? {}
  const { subActionTypes, subEditProps } = subAction ?? {}

  const actionDataItem = viewListData ? viewListData[Object.keys(viewListData)[0]] : null

  let trueSubActionType = subActionTypes && subActionType && subActionTypes.includes(subActionType) ? subActionType : 'none'
  if (subActionTypeDirect) { trueSubActionType = subActionTypeDirect }

  const appContext = useContext(AppComponentContext)

  const subEditVi = aps_viewItems && aps_viewItems[viKey] ? aps_viewItems[viKey] : null
  const { props: viProps } = subEditVi ?? {}
  let modifyProps = {}

  const [previewResult, setPreviewResult] = useState()
  const [actionProps, setActionProps] = useState()
  const [commitSub, setCommitSub] = useState()
  const { previewData } = previewResult ?? {}

  const allowSave = previewData || commitSub

  const handleDone = () => {
    console.log('handleDone')
    item_handlers.handleShow_itemSidebar()
  }

  const handleConfirm = () => {
    switch (trueSubActionType) {
      case _asat.sendAppUserEmail:
        if (giftCard_handlers) {
          giftCard_handlers.handleSendEmail(email)
        }
        break;
      default:
      // nothing
    }
  }

  const handleActionPreview = (aps) => {

    const { golfTournament_state } = appContext ?? {}
    const { golfMatches, holes } = golfTournament_state ?? {}
    let wins = {}

    switch (trueSubActionType) {

      case _asat.createMatch:
        setActionProps(aps)
        setCommitSub(aps.ready)
        break;

      case _asat.editMatch:
      case _asat.updateMatchScore:
        setActionProps(aps)
        setCommitSub(true)
        break;

      case _asat.createRankings:
        setActionProps(aps)
        setCommitSub(true)
        break;

      case _asat.createSchedule:
        setCommitSub(true)
        break;

      case _asat.createAlignment:
        setActionProps(aps)
        setCommitSub(true)
        break;

      case _asat.createAllStarTeams:
        setActionProps(aps)
        setCommitSub(true)
        break;

      case _asat.createScheduleDates:
        setActionProps(aps)
        setCommitSub(true)
        break;

      case _asat.createTempScorecards:
        const { tournamentRounds } = pathViews ?? {}
        let matchPreviews = { ...golfMatches }
        const roundMatchPreviews = tournamentRounds ? _.filter(matchPreviews, { 'round': tournamentRounds }) : {}
        if (roundMatchPreviews) {
          roundMatchPreviews.forEach(m => {
            const matchStatus = createMatchScorecardsWithResults(wins, m, holes, 1)
            m.holeWinners = matchStatus.holeWinners
            m.holeScores = matchStatus.holeScores
          })
          matchPreviews = creatingHelpers.createObject(roundMatchPreviews, 'id')
        }
        setPreviewResult({ previewSuccess: true, previewData: matchPreviews })
        break;

      case _asat.createTempScorecard:
        const match = { ...actionDataItem }
        const matchStatus = createMatchScorecardsWithResults(wins, match, holes, 1)
        match.holeWinners = matchStatus.holeWinners
        match.holeScores = matchStatus.holeScores
        setPreviewResult({ previewSuccess: true, previewData: match })
        break;

      default:
      // nothing
    }
  }

  /** commits the action */
  const handleActionCommit = () => {
    switch (trueSubActionType) {
      case _asat.createRankings:
        updateRankings(paps_state, viewItem, actionProps, handleDone)
        break;
      case _asat.editMatch:
      case _asat.updateMatchScore:
        updateTheMatch(matchUpdateTypes.updateScore, pathViews, latestSeason, sportsKey, actionProps, handleDone)
        // updateMatchScore(pathViews, viewItem, actionProps, handleDone)
        break;
      case _asat.createAlignment:
        updateTeamAlignment(sportPermissions, paps_state, latestSeason, actionProps, handleDone)
        break;
      case _asat.createAllStarTeams:
        updateAllStarTeams(sportPermissions, paps_state, latestSeason, actionProps, handleDone)
        break;
      case _asat.createScheduleDates:
        updateScheduleDates(paps_state, latestSeason, actionProps, handleDone)
        break;
      case _asat.createSchedule:
        updateScheduledMatches(paps_state, latestSeason, actionProps, handleDone)
        break;
      case _asat.createRoundMatches:
        fsfn_createGolfRoundMatches(paps_state, actionDataItem.id, previewData, handleDone)
        // createGolfRoundMatches(paps_state, actionDataItem.id, previewData, handleDone)
        break;
      case _asat.createTempScorecards:
        fsfn_updateGolfRoundMatches(paps_state, previewData, handleDone)
        // updateGolfRoundMatches(paps_state, previewData, handleDone)
        break;
      case _asat.createTempScorecard:
        fsfn_createGolfRoundMatches(paps_state, previewData, handleDone)
        // updateGolfRoundMatches(paps_state, [previewData], handleDone)
        break;
      default:
      // nothing
    }
  }

  if (subEditProps && Object.keys(subEditProps).length > 0 && viProps) {
    Object.keys(viProps).forEach(propKey => {
      if (subEditProps.includes(propKey)) {
        modifyProps[propKey] = viProps[propKey]
      }
    })
  } else {
    modifyProps = viProps
  }

  const containerContent = (previewContent, noPreview) => <UpdateWrapper
    caption={trueSubActionType}
    content={previewContent}
    handleSave={handleActionCommit}
    handlePreview={handleActionPreview}
    handleClose={item_handlers.handleShow_itemSidebar}
    allowSave={allowSave}
  />

  const container = () => {

    switch (trueSubActionType) {

      // case _asat.backupSeasons:
      case _asat.createAllStarTeams:
      case _asat.createHistory:
      case _asat.createPlayoff:
      case _asat.createRankings:
      case _asat.createRoster:
      case _asat.createSchools:
      case _asat.createSeason:
      case _asat.createSportsTeams:
      case _asat.createStaff:
      case _asat.createTournament:
      case _asat.editSeasonDetails:
      case _asat.editTryoutRoster:
      case _asat.editSeason:
      case _asat.nominatePlayers:
      case _asat.submitRankings:
      case _asat.updateDistricts:
      case _asat.updateLeagueSports:
      case _asat.updateOrganizations:
      case _asat.updateOrganizationSports:
      case _asat.updateSchoolSports:
      case _asat.sportsDashboard:
        return <CreateSports
          fromDashboard={fromDashboard}
          subActionType={trueSubActionType}
          latestSeason={latestSeason}
          viewItem={viewItem}
          handleActionPreview={handleActionPreview}
          commitSub={commitSub}
          handleCancel={handleClose ? handleClose : handleDone}
        />

      case _asat.importGolfTournament:
      case _asat.createGolfTournament:
      case _asat.createRoundMatches:
      case _asat.createGolfLineups:
        return <GolfParentProvider>
          <CreateGolf subActionType={trueSubActionType} viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} previewData={previewData} />
        </GolfParentProvider>

      case _asat.createTickets:
        return <AppUserTicketingWithProvider />

      case _asat.createSchedule:
        return containerContent(<CreateSchedule viewItem={viewItem} handleActionPreview={handleActionPreview} />, true)

      case _asat.createAlignment:
        return containerContent(<CreateAlignment viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} />, true)

      // case _asat.createTournament:
      //   return containerContent(<CreateTournament viewItem={viewItem} handleActionPreview={handleActionPreview} commitSub={commitSub} />, true)

      case _asat.createScheduleDates:
        return containerContent(<CreateSectionDates createScheduleType={createScheduleTypes.season} viewItem={viewItem} handleActionPreview={handleActionPreview} />, true)

      case _asat.createMatch:
        return <MatchCreate shows={{ showTeams: true, showDate: true }} solo={true} handleClose={item_handlers.handleShow_itemSidebar} />

      case _asat.editMatch:
      case _asat.updateMatchScore:
        return <CreateMatchResult_withProvider matchKey={viewKey} />

      case _asat.sendAppUserEmail:
        return <UiConfirmation
          caption={'Send Email?'}
          text={'Are you sure you want to send the AppUser an email.'}
          updateCaption={'Send'}
          handleUpdate={handleConfirm}
          handleCancel={handleDone}
        />

      case _asat.scanQrCode:
        return <QrScan />

      case _asat.createWeeklyLabRun:
      case _asat.updateLabResult:
      case _asat.findLabResult:
        return <CreateLab subActionType={trueSubActionType} handleClose={item_handlers.handleShow_itemSidebar} />

      default:
        return <FullPageWrapper
          content={<Segment basic>{'No component has been set for the sub action ' + trueSubActionType}</Segment>}
          topperCaption={_.startCase(trueSubActionType)}
        />
    }
  }

  return <Suspense fallback={<SuspenseDimmer origin={'Ui Sub Action'} />}>
    {container()}
  </Suspense>

}

export default UiSubAction