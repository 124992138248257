import _ from 'lodash';
import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { propModTypes } from '../cnr/reducers/DataManagmentReducer';
import { sortObject } from "../common/sorting";
import { propHelpers } from '../common/tester';
import { allPageTypes } from '../enums/allPageTypes';
import { usaStates } from '../genericData/states';

const vitCaptions = (vit) => {
  if (vit) {
    switch (vit) {
      case allPageTypes.cityInfos:
        return 'City Info'
      case allPageTypes.facilitys:
        return 'Facilities'
      case allPageTypes.securitys:
        return 'Security'
      case allPageTypes.transportations:
        return 'Transportation'
      default:
        return null
    }
  } else {
    return null
  }
}

export const listHelpers = {
  listList: (list, noNone, exception, keyy, noneAs, ignoreStartCase) => listList(list, noNone, exception, keyy, noneAs, ignoreStartCase),
  listListA: (list, prop) => listListA(list, prop),
  listListI: (value) => listListI(value),
  labelListHeader: (propAdjustmentTypes, opts) => labelListHeader(propAdjustmentTypes, opts),
  labelList: (list, noNone, exception, keyy, noneAs, opts) => labelList(list, noNone, exception, keyy, noneAs, opts),
  listListO: (list, exception, noNone) => listListO(list, exception, noNone),
  listListL: (list, exception, noNone) => listListL(list, exception, noNone),
  listStatic: (list, listName, noNone, isNameSelect, staticView_data) => listStatic(list, listName, noNone, isNameSelect, staticView_data),
  listRss: (feeds) => listRss(feeds),
  listYears: () => listYears(),
  listStates: () => listStates(),
  listItemKey: (list) => listItemKey(list),
}
/**
 * 
 * @param {object} list - ARRAY
 * @param {boolean} noNone 
 * @param {object} exception 
 * @param {string} keyy 
 * @param {string} noneAs 
 * @returns a key/value/text options list
 */
export const listList = (list, noNone, exception, keyy, noneAs, ignoreStartCase) => {
  const items = []
  list = _.sortBy(list)
  list.forEach((key, index) => {
    if (key !== exception) {
      if (_.isObject(key)) {
        items.push(key)
      } else {
        const _text = ignoreStartCase ? key : _.startCase(key)
        items.push({
          text: vitCaptions(key) ? vitCaptions(key) : _text,
          value: key,
          key: keyy ? keyy + '-' + key + '-' + index : key + '-' + index,
        })
      }
    }
  })
  if (!noNone) addNone(items, noneAs)
  return items
}

export const listItemKey = (list) => {
  const items = []
  const _list = _.sortBy(list, 'name')
  _.each(_list, (listItem) => {
    const { name, _itemKey } = listItem
    items.push({
      text: name,
      value: _itemKey,
      key: _itemKey,
    })
  })
  return items
}


const listListA = (list, prop) => {
  const items = []
  list = _.sortBy(list)
  list.forEach(li => {
    const t = li[prop]
    items.push({
      text: _.startCase(t),
      value: t,
      key: t,
    })
  })
  return items
}

export const listListI = (value, prop) => {
  return [{
    text: _.startCase(value),
    value: value,
    key: value,
  }]
}


const removeTypes = (pats, propKey) => {
  if (propKey) {
    if (!propHelpers.isDateProp(propKey)) {
      const pat = _.findIndex(pats, { modType: propModTypes.fixDates })
      if (pat) { pats.splice(pat, 1) }
    }
    if (!propHelpers.isTimeProp(propKey)) {
      const pat = _.findIndex(pats, { modType: propModTypes.fixTime })
      if (pat) { pats.splice(pat, 1) }
    }
    if (!propHelpers.isEmailProp(propKey)) {
      const pat = _.findIndex(pats, { modType: propModTypes.fixEmails })
      if (pat) { pats.splice(pat, 1) }
    }
  }

}

export const labelListHeader = (propAdjustmentTypes, opts) => {
  const { propKey, handleItemClick } = opts ?? {}
  const items = []
  const _propAdjustmentTypes = _.sortBy(propAdjustmentTypes, 'modType')
  removeTypes(_propAdjustmentTypes, propKey)
  _propAdjustmentTypes.forEach((pa, index) => {
    const { modType, icon, color } = pa
    const tvk = {
      text: _.startCase(modType),
      value: modType,
      key: modType + '-' + index,
      icon: <Icon color={color} name={icon} size={'small'} onClick={() => { handleItemClick && handleItemClick({ propKey, modType: modType }) }}></Icon>
    }
    items.push(tvk)
  })
  return items
}

export const labelList = (list, noNone, exception, keyy, noneAs, opts) => {
  const { icon, label, propKey, handleItemClick } = opts ?? {}
  const items = []
  list = _.sortBy(list)
  list.forEach((key, index) => {
    if (key !== exception) {
      if (_.isObject(key)) {
        items.push(key)
      } else {
        const tvk = {
          text: vitCaptions(key) ? vitCaptions(key) : _.startCase(key),
          value: key,
          key: keyy ? keyy + '-' + key + '-' + index : key + '-' + index,
        }
        tvk.label = label && <Label circular empty color='blue' title={key}></Label>
        tvk.icon = icon && <Icon name='check' size={'small'} onClick={() => { handleItemClick && handleItemClick({ propKey, itemAction: key }) }}></Icon>
        items.push(tvk)
      }
    }
  })
  if (!noNone) addNone(items, noneAs)
  return items
}

/** Retuns a key, value, text list from an object */

/**
 * 
 * @param {*} list - OBJECT
 * @param {*} exception 
 * @param {*} noNone 
 * @returns a key/value/text options list
 */
export const listListO = (list, exception, noNone) => {
  const l = { ...list }
  if (l && exception) { delete l[exception] }
  const slist = sortObject(l, 'key')
  const items = Object.keys(slist).map(key => (
    {
      text: vitCaptions(key) ? vitCaptions(key) : _.startCase(key),
      value: key,
      key: key,
    }
  ))
  if (!noNone) addNone(items)
  return items
}

export const listListL = (list, exception, noNone) => {
  const l = { ...list }
  if (l && exception) { delete l[exception] }
  const slist = sortObject(l, 'key')
  const items = Object.keys(slist).map(key => {
    const li = slist[key]
    return {
      text: li.text,
      value: li.value,
      // key: li.key,
    }
  })
  if (!noNone) addNone(items)
  return items
}

export const listStatic = (list, listName, noNone, isNameSelect, staticView_data) => {
  const l = { ...list }
  delete l.uid
  delete l.id
  delete l.key
  const sortBy = (listName === 'attendees' || listName === 'golfers') ? 'lastName' : 'name'
  const slist = sortObject(l, sortBy)
  const items = []
  Object.keys(slist).forEach(key => {
    const item = slist[key]
    if (key !== 'id' && key !== 'key' && key !== undefined) {
      let text = item.name ? item.name : null
      if (!text) { text = item.firstName && item.lastName ? item.lastName + ', ' + item.firstName : null }
      if (text) {
        items.push({
          text: text,
          value: isNameSelect ? text : key,
          key: isNameSelect ? text : key,
          altvalue: isNameSelect && staticView_data ? key : null,
          altprop: isNameSelect && staticView_data ? staticView_data : null
        })
      }
    }
  })
  if (!noNone) addNone(items)
  return items
}

export const listRss = (feeds) => {
  const opts = []
  Object.keys(feeds).forEach(key => {
    const feed = feeds[key]
    const { show, source, baseUrl, categories } = feed
    if (show && baseUrl) {
      if (categories) {
        feed.categories.forEach(category => {
          opts.push({
            key: category.name,
            text: source + ' - ' + _.startCase(category.name),
            value: key
          })
        })
      } else {
        opts.push({ key: source, text: source, value: key })
      }
    }
  })
  return opts
}

export const listYears = () => {
  const d = new Date()
  const optsA = []
  for (var i = d.getFullYear() + 5; i >= d.getFullYear() - 60; i--) {
    // for (var i = d.getFullYear() - 60; i <= d.getFullYear() + 5; i++) {
    optsA.push(
      {
        text: i,
        value: i,
        key: i,
      }
    )
  }
  return optsA
}

const listStates = () => {
  const opts = []
  _.each(usaStates, (state, key) => {
    opts.push({ key: key, value: key, text: usaStates[key] })
  })
  return opts
}

const addNone = (items, noneAs) => {
  items.unshift({
    text: 'none',
    value: noneAs ? noneAs : null,
    key: noneAs ? noneAs : 'none',
  })
}