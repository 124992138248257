import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { convertHelpers } from '../../common/convert';
import { gEnums } from '../../enums/globalEnums';
import { getAllAppUserUrls } from '../../storage/storageHelpers';
import { eventInfoHandlers, eventInfoInitialState, eventInfoReducer } from '../reducers/EventInfoReducer';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { AppUsersContext } from './AppUsersContext';

/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const EventInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const EventInfoProvider = (props) => {

  const aat = gEnums.appAreaTypes
  const svc = [aat.clients, aat.clubs, aat.districts, aat.event, aat.leagues, aat.schools, aat.organizations, aat.client]

  const appUsersContext = useContext(AppUsersContext);
  const { appUsers_handlers } = appUsersContext
  const { handleSet_eventAppUsers } = appUsers_handlers ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { appArea, pathViews } = paps_state ?? {}

  // pageContext
  const pageContext = useContext(PageContext);
  const { page_state } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings, aps_viewItems } = pageSettings ?? {}
  const { dataOverview, appDataSource, topMenu } = aps_global ?? {}
  const { appUserCollection, appUserCollections, appUserViewTypeProp, allowPresenceTracking } = aps_appUserSettings ?? {}
  const { useStartDataCollections, getAllDataCollections, startDataCollections } = dataOverview ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  const _appUserCollections = appUserCollections ? appUserCollections : []
  if (_appUserCollections.length === 0 && appUserCollection) { _appUserCollections.push(appUserCollection) }

  // eventInfoInitialState
  const initState = eventInfoInitialState({ pathViews })
  const [eventInfo_state, dispatch] = useReducer(eventInfoReducer, initState);
  const eventInfo_handlers = eventInfoHandlers(dispatch)

  const { staticViews, staticEventKey, appDataDocuments, appDataLinks } = eventInfo_state ?? {}
  const { attendees } = staticViews ?? {}

  const getStaticName = (staticView, staticValue, returnFound) => {
    const _staticView = staticView === 'guests' ? 'attendees' : staticView
    if (staticViews && staticViews[_staticView] && staticViews[_staticView][staticValue]) {
      const svv = staticViews[_staticView][staticValue]
      if (returnFound) {
        return { sv: convertHelpers.getItemName(svv) }
      } else {
        return convertHelpers.getItemName(svv)
      }
    } else {
      if (returnFound) {
        return { sv: staticValue, notFound: true }
      } else {
        return staticValue
      }
    }
  }

  useEffect(
    () => {
      eventInfo_handlers.handleInit_event({ appUserCollections, aps_viewItems, pathViews, appDataSource, mainFixedCaption })
      if (svc.includes(appArea)) {
        if (staticEventKey !== pathViews.events) {
          eventInfo_handlers.handleGet_eventStaticViews(pathViews)
        }
      }

      if (useStartDataCollections && (startDataCollections || getAllDataCollections)) {
        const _collectionItems = aps_viewItems ? Object.keys(aps_viewItems) : []
        eventInfo_handlers.handleGet_startDataCollections(startDataCollections, getAllDataCollections, _collectionItems, pathViews)
      }

      if (allowPresenceTracking) {
        eventInfo_handlers.handleGet_appUserPresence(pathViews)
      }

      eventInfo_handlers.handleGet_appGlobalData(pathViews)

    },
    // eslint-disable-next-line
    [pathViews.events, appDataSource]
  )

  useEffect(
    () => {
      if (attendees && (appUserViewTypeProp === gEnums.appUserProfileImageTypes.profile)) {
        getAllAppUserUrls(attendees, eventInfo_handlers.handleSet_appUserUrls)
      }
    },
    // eslint-disable-next-line
    [attendees]
  )

  useEffect(
    () => {
      if (appDataDocuments && appUserCollection && appDataDocuments[appUserCollection]) {
        handleSet_eventAppUsers(appDataDocuments[appUserCollection])
      }
    },
    // eslint-disable-next-line
    [appDataDocuments]
  )

  const eventInfo_fns = {
    getStaticName: (staticView, staticValue, returnFound) => getStaticName(staticView, staticValue, returnFound)
  }

  if (svc.includes(appArea)) {
    if (staticEventKey) {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    } else {
      return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
        {props.children}
      </EventInfoContext.Provider>
    }
  } else {
    return <EventInfoContext.Provider value={{ eventInfo_state, eventInfo_handlers, eventInfo_fns }}>
      {props.children}
    </EventInfoContext.Provider>
  }
}

export default EventInfoProvider