import _ from 'lodash';
import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { appIconTypes } from '../../enums/appIconTypes';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import GenericButton from '../../genericControls/GenericButton';
import GenericIcon from '../../genericControls/GenericIcon';

/** Save, Cancel, Delete, Preview */
const UiSaveButtons = (props) => {

  const { ddb, cn, others, ddo, dropdowns, dropdownsRight, dictaphone, save, cancel, delete: _delete, selected, preview, help, other, paste, printt, clickOptions, copy, dropdown, rows, json, color } = props

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_styles } = pageSettings ?? {}

  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.saveButtons] : {}
  const { className } = styleAndClass ?? {}

  let buttons;

  if (others) {
    const btns = []
    others.forEach((other, index) => {
      const { caption, fl, color: color_other, clickOptions: co } = other
      if (caption) {
        btns.push(btn('btn-' + index, other, co ? co : clickOptions, color_other ? color_other : color, fl))
      } else {
        btns.push(icn('icn-' + index, other, co ? co : clickOptions, color_other ? color_other : color, true, fl))
      }
    })
    if (dropdowns) {
      if (dropdownsRight) {
        btns.push(dropdowns)
      } else {
        btns.unshift(dropdowns)
      }
    }
    if (ddb) {
      if (dropdownsRight) {
        btns.push(ddb)
      } else {
        btns.unshift(ddb)
      }
    }
    buttons = btns
  } else {
    // buttons = <React.Fragment>
    buttons = <div key={uniqueKey('sv', 'btns')} className={'btn-save-container ' + className}>
      {ddb}
      {btn('cancel', cancel, clickOptions, color)}
      {btn('delete', _delete, clickOptions, color, true)}
      {btn('json', json, clickOptions, color)}
      {btn('other', other, clickOptions, color)}
      {btn('paste', paste, clickOptions, color, true)}
      {btn('selected', selected, clickOptions, color, true)}
      {btn('preview', preview, clickOptions, color, true)}
      {btn('save', save, clickOptions, color)}
      {icn('copy', copy, clickOptions, color, true)}
      {icn('help', help, clickOptions, color, true)}
      {icn('printt', printt, clickOptions, color, true)}
      {dropdown && dropdownOptionButton(dropdown)}
      {dropdowns && dropdowns}
    </div>
    // </React.Fragment>
  }

  if (cn) {
    return <div className={cn}>
      {dictaphone && dictaphone}
      {buttons}
      {ddo && ddo}
    </div>
  } else if (rows) {
    const cn = rows > 1 ? 'btn-rows two' : 'btn-rows one'
    return <div className={cn}>
      {dictaphone && dictaphone}
      {ddo && ddo}
      {buttons}
    </div>
  } else {
    return buttons
  }
}

export default UiSaveButtons

const gb = (key, caption, icon, onClick, clickOptions, opts, noE) => <GenericButton key={uniqueKey('usb', 'gb', key)} gbProps={{ key, caption, icon, onClick, clickOptions, opts, noE }} />

/**
 * 
 * @param {string} key 
 * @param {object} item 
 * @param {object} clickOptions 
 * @param {object} cl 
 * @param {object} fl 
 * @returns 
 */
const btn = (key, item, clickOptions, cl, fl) => {
  const { caption, oc, icon, color, disabled, inverted, cn, opts } = item ?? {}
  const _color = cl ? cl : color
  if (oc) {
    return gb(uniqueKey('usb', 'b', key), caption ? caption : _.startCase(key), icon ? icon : appIconTypes[key], oc, { ...clickOptions, ...opts }, { disabled, color: _color ? _color : iconColorTypes[key], inverted, cn, fl, ...opts })
  }
}

const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon key={uniqueKey('usb', 'gi', key)} giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

const icn = (key, item, clickOptions, cl, fl) => {
  const { oc, icon, disabled, inverted, color } = item ?? {}
  const _color = cl ? cl : color
  if (oc) {
    return gi('btn-help', icon ? icon : appIconTypes[key], oc, clickOptions, { disabled, color: _color ? _color : iconColorTypes.default, fl, inverted })
  }
}

const dropdownOptionButton = ({ options, handleClick, handleDDChange }) => {
  const items = () => options.map((option, index) => <Dropdown.Item key={uniqueKey('usb', index)} onClick={() => { handleClick(option) }} text={_.startCase(option)} />)
  return <Dropdown
    icon='cog'
    floating
    button
    upward
    className='icon list-button'
    onChange={handleDDChange && handleDDChange}
  >
    <Dropdown.Menu>
      <Dropdown.Header icon='cog' content='Options' />
      <Dropdown.Divider />
      {items()}
    </Dropdown.Menu>
  </Dropdown>
}