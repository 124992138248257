import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { copyObj } from '../../../../global/common_web/copy';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_update_doc } from '../../../../global/firestoreData/appData/fsData';
import { fsfn_sheets } from '../../../../global/functions/fbSheetsFuntions';
import { realTimeScoring } from '../../dbActions/realtimeScoring';
import { matchCalcs } from '../../helpers/match';
import { _useSportsKey } from './SportsDistrictReducer';
import { _sportsOriginTypes } from './SportsReducer';
import { getSportsData_parentKeyCollections, getUpcomingMatches } from './reducerHelpers/seasonSportsData';
import { seasonalHelpers, seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';
import { sportsHelpers } from './reducerHelpers/sportsHelpers';
import { where } from 'firebase/firestore';

export const _matchSeasonalCollection = 'matches_seasonal'

const _getScoresFromScores = true

const dataItems_init = {
  sports: { parentKeys: ['organizations', 'districts', 'name'], returnFirstObject: true },
}

const dataItems_sportsSeason = {
  gls_teams: { collectionName: 'gls_teams', noYear: true },
  history: { collectionName: 'history', noYear: true },
  seasonMatches: { collectionName: _matchSeasonalCollection, collectionSubs: ['matches', 'playoffMatches', 'playoffMatches_backup', 'scores', 'playoffScores'], subItemKey: '_matchCollectionKey' },
  rankings: { collectionName: 'rankings' },
  seasonals: { collectionName: 'seasonals', collectionDoc: 'details' },
  sportsAccess_sports: { collectionName: 'sportsAccess_sports', noYear: true },
  teamRosters: { collectionName: 'teamRosters' },
  teams: { collectionName: 'teams' },
  teamStaff: { collectionName: 'teamStaff' },
  tournaments: { collectionName: 'tournaments' },
}

const dataItems_sportsSeasonSchedule = {
  // matches: { collectionName: 'matches' },
  seasonMatches: { collectionName: _matchSeasonalCollection, collectionSubs: ['matches', 'playoffMatches', 'playoffMatches_backup', 'scores', 'playoffScores'], subItemKey: '_matchCollectionKey' },
}


export const sportsDbCollections = {
  seasonal: {
    allStartNominations: 'allStartNominations',
    allStarTeams: 'allStarTeams',
    districts: 'districts',
    globals: 'globals',
    googleLinks: 'googleLinks',
    history: 'history',
    matches: 'matches',
    playoffs: 'playoffs',
    rankings: 'rankings',
    rosters: 'rosters',
    scheduleDates: 'rosters',
    schools: 'rosters',
    seasonals: 'seasonals',
    teams: 'teams',
  },
  normal: {
    athletes: 'athletes',
    districts: 'districts',
    globals: 'globals',
    organizations: 'organizations',
    schools: 'schools',
    teams: 'teams',
  }
}

export const matchUpdateTypes = {
  addConsolationMatch: 'addConsolationMatch',
  addToHistory: 'addToHistory',
  cancelMatch: 'cancelMatch',
  confirmMatchDate: 'confirmMatchDate',
  deleteMatch: 'deleteMatch',
  deleteRealtimeMatch: 'deleteRealtimeMatch',
  deleteScore: 'deleteScore',
  notifyMatch: 'notifyMatch',
  openDatabase: 'openDatabase',
  postponeMatch: 'postponeMatch',
  resetByeMatch: 'resetByeMatch',
  updateFullMatchDetails: 'updateFullMatchDetails',
  updateGsScore: 'updateGsScore',
  updateMatchDetails: 'updateMatchDetails',
  updateMatchListResults: 'updateMatchListResults',
  updateMatchListResultsFromGs: 'updateMatchListResultsFromGs',
  updatePendingMatch: 'updatePendingMatch',
  updatePrePendingMatch: 'updatePrePendingMatch',
  updateResults: 'updateResults',
  updateScore: 'updateScore',
}

export const gameScoringTypes = {
  half: 'half',
  quarter: 'quarter',
  match: 'match',
  matchPoints: 'matchPoints',
  inning: 'innning',
}

export const sectionSortingTypes = {
  sectionWinningPercentage: 'sectionWinningPercentage',
  points: 'points',
}

export const sportsShowTypes = {
  compareSections: 'compareSections',
  compareSchedules: 'compareSchedules',
  compareTeams: 'compareTeams',
  matchesRemaining: 'matchesRemaining',
  playoffPicture: 'playoffPicture',
  teamLookup: 'teamLookup',
  matchResults: 'matchResults',
  updatePlayoffMatches: 'updatePlayoffMatches',
  realtimeMatch: 'realtimeMatch',
  updateMatch: 'updateMatch',
  updateResults: 'updateResults',
}

// const pojoSports = {
//   _matches: {
//     docKey: {
//       parentKeys: { keys: 'odsy' },
//       matches: {
//         docKey: {
//           away: {},
//           home: {},
//           data: {}
//         }
//       }
//     }
//   },
//   _teams: {
//     docKey: {
//       parentKeys: { keys: 'odsy' },
//       teams: {
//         docKey: {
//           data: {}
//         }
//       }
//     }
//   },
//   _scheduleDates: {
//     docKey: {
//       parentKeys: { keys: 'odsy' },
//       scheduleDates: {
//         '6': [],
//         '7': [],
//       }
//     }
//   },
//   _rankings: {
//     docKey: {
//       parentKeys: { keys: 'odsy' },
//       rankings: {
//         docKey: {
//           data: {}
//         }
//       }
//     }
//   },
//   _playoffs: {
//     docKey: {
//       parentKeys: { keys: 'odsy' },
//       playoffInfo: {},
//       playoffs: {
//         docKey: {
//           data: {}
//         }
//       }
//     }
//   },
//   _history: {
//     docKey: {
//       parentKeys: { keys: 'ods' },
//       history: {
//         docKey: {
//           '2A': {
//             '2008': {
//               winner: 'Team1',
//               runnerUp: 'Team2',
//             }
//           },
//           '3A': {}
//         }
//       }
//     }
//   },
//   _rosters: {
//     docKeys: {
//       parentKeys: { keys: 'odsyt' },
//       athletes: {
//         docKey: {
//           data: {}
//         }
//       }
//     }
//   },
// }

const rts = {
  handleAmmend_mediaMatches: 'handleAmmend_mediaMatches',
  handleAmmend_sportsAccess: 'handleAmmend_sportsAccess',
  handleAmmend_teamsAccess: 'handleAmmend_teamsAccess',
  handleBackup_seasonMatches: 'handleBackup_seasonMatches',
  handleCalc_matchesRemaining: 'handleCalc_matchesRemaining',
  handleCalc_sectionCompares: 'handleCalc_sectionCompares',
  handleCalc_teamCompares: 'handleCalc_teamCompares',
  handleDbUpdate_sportsDoc: 'handleDbUpdate_sportsDoc',
  handleFilter_sports: 'handleFilter_sports',
  handleGet_gsRoster: 'handleGet_gsRoster',
  handleGet_matchesData: 'handleGet_matchesData',
  handleGet_mediaMatches: 'handleGet_mediaMatches',
  handleGet_parentKeyCollectionItems: 'handleGet_parentKeyCollectionItems',
  handleGet_seasonMatches: 'handleGet_seasonMatches',
  handleGet_sportsSeasonInfo: 'handleGet_sportsSeasonInfo',
  handleGet_sportsSeasonInit: 'handleGet_sportsSeasonInit',
  handleGoTo_match: 'handleGoTo_match',
  handleGoToTeam: 'handleGoToTeam',
  handleResults_parentKeyCollections: 'handleResults_parentKeyCollections',
  handleSet_accessTeams: 'handleSet_accessTeams',
  handleSet_currentHistory: 'handleSet_currentHistory',
  handleSet_gsRoster: 'handleSet_gsRoster',
  handleSet_parentKeyCollectionItems: 'handleSet_parentKeyCollectionItems',
  handleSet_realtimeResults: 'handleSet_realtimeResults',
  handleSet_seasonMatches: 'handleSet_seasonMatches',
  handleSet_sportsMode: 'handleSet_sportsMode',
  handleSet_sportsSeasonData: 'handleSet_sportsSeasonData',
  handleSet_state: 'handleSet_state',
  handleSetSidebar: 'handleSetSidebar',
  handleShow_sportsElem: 'handleShow_sportsElem',
  handleUpdate_sportsSeasonSubData: 'handleUpdate_sportsSeasonSubData',
  handleUpdate_sportsSeasonTs: 'handleUpdate_sportsSeasonTs',
  ...grts,
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsSeasonReducer = (state, action) => {

  const {
    _sportsModeSport,
    currents,
    currentSidebars,
    dataParents,
    firestore_fns,
    gameScoringType,
    latestSeason,
    matches_info,
    pathViews,
    rtSettings,
    useScoringCollection,
    sportPermissions
  } = state

  const { matches } = matches_info ?? {}
  const _allowRtMatchData = rtSettings.allowRtMatchData

  const { type, dispatch, sportsKey: sports_key } = action

  // const state = {
  //   history: {
  //     '2A': []
  //   },
  //   matches: {
  //     'matchKey1': { matchData: {} },
  //     'matchKey2': { matchData: {} },
  //   },
  //   playoffMatches: {
  //     'matchKey1': { matchData: {isPlayoff: true} },
  //     'matchKey2': { matchData: {isPlayoff: true} },
  //   },
  //   rankings: {
  //     'week1': {
  //       '2A': ['team1', 'team2'],
  //       '3A': ['team1', 'team2']
  //     }
  //   },
  //   scheduleDates: {
  //     '6': ['date1', 'date2'],
  //     '7': ['date1', 'date2'],
  //   },
  //   teams: {
  //     'teamKey1': {},
  //     'teamKey2': {}
  //   }
  // }

  const sportsSeason_handlers = sportsSeasonHandlers(dispatch)
  const { handleFunctionResponse, handleSet_accessTeams, handleGet_sportsSeasonInfo, handleResults_parentKeyCollections, handleSet_seasonMatches, handleSet_realtimeResults, handleSet_parentKeyCollectionItems, handleSet_gsRoster } = sportsSeason_handlers

  switch (type) {

    case rts.handleSet_accessTeams:
      return { ...state, access_teams: action.sportsAccess }

    case rts.handleSet_sportsMode:
      let _sms = action.sportsModeKey
      _sms = _sms.replace('womens', '')
      _sms = _sms.replace('mens', '')
      _sms = _sms.toLowerCase()
      const { gameScoringType: gst, gameAllowTies } = sportsHelpers.getScoreType(action.sportsModeKey)
      return { ...state, _sportsMode: action.sportsMode, _sportsModeKey: action.sportsModeKey, _sportsModeSport: _sms, gameScoringType: gst, gameAllowTies }

    case rts.handleGet_sportsSeasonInit:

      testData(pathViews)

      _.each(dataItems_init, (dataItemDirect, key) => {
        const { returnFirstObject } = dataItemDirect
        const refPath_sports = createRefPath_event(pathViews, [key])
        const { wheres } = sportsHelpers.getWheres_sports(pathViews, dataItemDirect)
        if (_useSportsKey) {
          const refPath_sports2 = createRefPath_event(pathViews, [key, pathViews.sports])
          fs_get_data({ refPath: refPath_sports2, opts: { returnFirstObject } }).then(sports_info => {
            switch (key) {
              case 'sports':
                const { latestSeason: latest_season, _itemKey } = sports_info ? sports_info : {}
                const _latestSeason = action.selectedSeason ? action.selectedSeason : latest_season
                // trigger to get the data for the sports season
                handleGet_sportsSeasonInfo(_itemKey, _latestSeason, sports_info)
                break;
              default:
            }
          })
        } else {
          fs_get_data({ refPath: refPath_sports, wheres, opts: { returnFirstObject } }).then(sports_info => {
            switch (key) {
              case 'sports':
                const { latestSeason: latest_season, _itemKey } = sports_info ? sports_info : {}
                const _latestSeason = action.selectedSeason ? action.selectedSeason : latest_season
                // trigger to get the data for the sports season
                handleGet_sportsSeasonInfo(_itemKey, _latestSeason, sports_info)
                break;
              default:
            }
          })
        }

        const cb_team = (data) => {
          const { sportsSeasonalData } = data
          const { teams } = sportsSeasonalData ? sportsSeasonalData : {}
          handleSet_accessTeams(teams)
        }

        const _pks_t = seasonalHelpers.getPks(pathViews, null, null, ['teams'])
        seasonalHelpers.getSports_collectionItem(pathViews, _pks_t, '_sportsAccess_sports', true, null, cb_team)
      })

      return { ...state }

    case rts.handleGet_sportsSeasonInfo:

      const { sports_info } = action
      const additionalDataParents = ['sports', 'sportsYear']
      // get data from history, matches, matchesNonSection, playoffs, rankings, rosters, scheduleDates, teams 
      const x = {
        dataItems: dataItems_sportsSeason,
        dataParents, additionalDataParents,
        gameScoringType,
        handleSetDataResults: handleResults_parentKeyCollections,
        pathViews,
        selectedSeason: action.selectedSeason,
        sportsKey: sports_key,
      }
      getSportsData_parentKeyCollections(_sportsOriginTypes.sportsSeason, x, firestore_fns)

      return { ...state, sportsKey: sports_key, sports_info, selectedSeason: action.selectedSeason }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleResults_parentKeyCollections:

      const { sports_data, matches_data, parentDataKeys } = action

      const {
        levelDateGroups,
        levelLastDates,
        levelLatestDates,
        levelMatches,
        matchDateGroups,
        playoffTournaments,
        selectedSeason: _selectedSeason,
      } = matches_data ? matches_data : {}

      const drs = getDRS(dataItems_sportsSeason, sports_data)

      let _teamsFromMatches;

      if (drs.matches && drs.teams && _.isEmpty(drs.teams)) {
        _teamsFromMatches = getTeamsFromMatches(drs.matches)
      }

      // getSectionRecords(drs.matches, drs.teams)

      const {
        scheduleDates,
        sectionDates,
      } = sports_data ?? {}

      const _rankings = drs.rankings ? drs.rankings.rankings : null

      // if (_allowUpdateSeasonalScores && useScoringCollection) {
      //   if (drs.matches) { sportsHelpers.updateSeasonalScoresFromMatches(drs.matches, pathViews, key_matches_seasonal, false, _forceScoresUpdate) }
      //   if (drs.playoffMatches) { sportsHelpers.updateSeasonalScoresFromMatches(drs.playoffMatches, pathViews, key_matches_seasonal, true, _forceScoresUpdate) }
      // }

      if (_getScoresFromScores) {
        sportsHelpers.ammendScoresWithScores(drs.matches, drs.scores)
        sportsHelpers.ammendScoresWithScores(drs.playoffMatches, drs.playoffScores)
      }

      if (drs.matches) { matchCalcs.calcAllVballMatches(drs.matches, gameScoringType) }
      if (drs.playoffMatches) { matchCalcs.calcAllVballMatches(drs.playoffMatches, gameScoringType) }

      const _lMatches = drs.playoffMatches ? { ...drs.matches, ...drs.playoffMatches } : drs.matches
      const levelUpcomingMatches = getUpcomingMatches(_lMatches)

      const ssi = drs.teams && drs.matches ? sportsHelpers.ammendTeams(drs.teams, drs.matches, _rankings, _sportsModeSport) : null
      const { sportLevels, teams } = ssi ?? {}
      const { matchDateKeys, matchDateKeys_count } = sportsHelpers.getMatchDateKeys(matchDateGroups)

      const matchesTeams = {}

      const amm = sportsHelpers.ammendMatchesInfo(drs.matches, drs.playoffMatches)

      if (amm.matches) {

        if (teams) {
          Object.keys(teams).forEach(tk => {
            const tms = _.filter(amm.matches, function (m) { return m.teams && m.teams.includes(tk); })
            const today = new Date();
            const _next = _.takeRight(_.filter(_.orderBy(tms, ['startDate'], ['desc']), item => new Date(item.startDate).setHours(state._startHour, 0, 0, 0) >= today), 1);
            const _latest = _.takeRight(_.filter(_.orderBy(tms, ['startDate'], ['asc']), item => (item.results && item.results.complete === true) && new Date(item.startDate).setHours(state._startHour, 0, 0, 0) < today), 5);
            matchesTeams[tk] = {
              all: tms,
              next: _next,
              latest: _latest
            }
          })
        }
      }

      if (_allowRtMatchData) {
        realTimeScoring.getRealtimeScores(_allowRtMatchData, pathViews, latestSeason, handleSet_realtimeResults)
      }

      const _sections = teams ? _.groupBy(teams, 'sections') : null
      const levelVisibleDates = {}

      if (levelLatestDates) {
        _.each(levelLatestDates, (lld, lk) => {
          levelVisibleDates[lk] = [lld]
        })
      }

      const { sportsAccess_sports } = drs ?? {}
      const access = { ...sportsAccess_sports }

      const accessAndSheets = ammendSportAccess(drs)

      return {
        ...state,
        latestSeason: _selectedSeason,
        latestSeason_full: _selectedSeason ? (_selectedSeason - 1) + '-' + _selectedSeason : _selectedSeason,
        history_info: {
          history: drs.history,
          teams: sportsHelpers.getTeamHistories(drs.teams, drs.history)
        },
        matches_info: {
          matchDateGroups,
          matchDateKeys_count,
          matchDateKeys,
          levelMatches,
          levelDateGroups,
          levelLastDates,
          levelLatestDates,
          levelVisibleDates,
          levelUpcomingMatches,
          matches: amm.matches,
          playoffMatches_backup: drs.playoffMatches_backup ? drs.playoffMatches_backup : {},
          playoffMatches: amm.playoffMatches,
          matchesAll: drs.playoffMatches ? { ...amm.matches, ...drs.playoffMatches } : amm.matches,
          matchesRetrieved: true,
          scores: drs.scores,
          playoffScores: drs.playoffScores,
        },
        playoff_info: {
          playoffTournaments: playoffTournaments ? playoffTournaments : null,
        },
        teams_info: {
          teams: teams,
          rosters: drs.teamRosters,
          staff: drs.teamStaff,
          playoffTeams: teams && sportsHelpers.getPlayoffTeams(teams),
          sectionKeys: _sections ? Object.keys(_sections).sort() : [],
          teams_fromMatches: _teamsFromMatches
        },
        tournaments_info: {
          tournaments: drs.tournaments,
        },
        rankings_info: {
          rankings: _rankings,
          lastestRanking: sportsHelpers.getLastestRankings(_rankings)
        },
        seasonal_info: {
          scheduleDates,
          sectionDates,
          sportLevels,
          totalWeeks: amm.totalWeeks,
          firstMatchDate: amm.firstMatchDate,
          lastMatchDate: amm.lastMatchDate,
          seasonReady: true,
        },
        details_info: {
          details: drs.details,
        },
        googleLinks_info: {
          gls_teams: drs.gls_teams
        },
        sportsAccess_sports: {
          teams: drs.sportsAccess_sports ? { ...drs.sportsAccess_sports.teams } : {},
        },
        gls_teams: drs.gls_teams ? { ...drs.gls_teams } : {},
        parentDataKeys,
      }

    case rts.handleGet_seasonMatches:
      const additionalDataParents_sm = ['sports', 'sportsYear']
      // const y = {
      //   additionalDataParents: additionalDataParents_sm,
      //   dataItems: dataItems_sportsSeasonSchedule,
      //   dataParents,
      //   gameScoringType,
      //   handleSet_realtimeResults,
      //   handleSetDataResults: handleSet_seasonMatches,
      //   pathViews,
      //   selectedSeason: action.selectedSeason,
      //   sportsKey: state.sportsKey,
      // }
      const yy = {
        additionalDataParents: additionalDataParents_sm,
        dataItems: dataItems_sportsSeasonSchedule,
        dataParents,
        gameScoringType,
        handleSetDataResults: handleSet_seasonMatches,
        pathViews,
        selectedSeason: action.selectedSeason,
        sportsKey: state.sportsKey,
      }
      getSportsData_parentKeyCollections(_sportsOriginTypes.sportsSeasonMatches, yy)
      return { ...state }

    case rts.handleSet_seasonMatches:

      const { matches_data: matches_data_sm, gameScoringType: gameScoringType_sm } = action
      const { matches: matches_season, matchDateGroups: matchDateGroups_sm, dataResult_sm } = matches_data_sm
      const { matchDateKeys: matchDateKeys_sm, matchDateKeys_count: matchDateKeys_count_sm } = sportsHelpers.getMatchDateKeys(matchDateGroups_sm)

      matchCalcs.calcAllVballMatches(matches_season, gameScoringType_sm)

      const amm_sm = sportsHelpers.ammendMatchesInfo(matches_season)

      return {
        ...state,
        seasonMatches_info: {
          matchDateGroups: matchDateGroups_sm,
          matchDateKeys_count: matchDateKeys_count_sm,
          matchDateKeys: matchDateKeys_sm,
          matches: amm_sm.matches,
        }
      }

    case rts.handleBackup_seasonMatches:
      const { matchesToBackup, currentYear, callback } = action
      seasonalHelpers.cleanDataToUpdate(matchesToBackup)
      _.each(matchesToBackup, (match) => {
        if (!match.setsToWin) { match.setsToWin = 3 }
        if (!match.setCount) { match.setCount = 5 }
        delete match._itemKey
      })
      const pks = seasonalHelpers.getPks(pathViews, currentYear, state.sportsKey)
      const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, callback, 'backup_matches', 'matches', matchesToBackup)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.backupMatches, _updateProps)
      callback()
      return { ...state }

    case rts.handleSet_currentHistory:
      return { ...state, selectedHistory: action.selectedHistory }

    case rts.handleSet_state:
      if (action.groupProp) {
        const _groupProp = state[action.groupProp] ? state[action.groupProp] : {}
        _groupProp[action.stateProp] = action.value
        return { ...state, [action.groupProp]: _groupProp }
      } else {
        return { ...state, [action.stateProp]: action.value }
      }

    case rts.handleSet_sportsSeasonData:
      if (action && action.ssData) {
        console.log('action.ssData', action.ssData)
      }
      return { ...state }

    case rts.handleSet_realtimeResults:

      const { realtimeResults: rtr } = action

      if (rtr) {
        Object.keys(rtr).forEach(mKey => {
          const rtMatch = rtr[mKey]
          Object.keys(rtMatch).forEach(matchUserKey => {
            const rtUserMatch = rtMatch[matchUserKey]
            const { results } = rtUserMatch
            const { scores } = results
            if (scores && _.isArray(scores)) {
              let _scores = {}
              scores.forEach((score, setIndex) => {
                _scores[setIndex] = score
              })
              results.scores = _scores
            }
            const m = { results }
            matchCalcs.calcVballMatch(m, gameScoringType)
          })
        })
      }

      if (matches_info) {
        matches_info.matches_realtime = rtr ? rtr : null
        return { ...state, matches_info: matches_info }
      } else {
        return { ...state, matches_info: { matches_realtime: rtr } }
      }

    case rts.handleUpdate_sportsSeasonTs:
      return { ...state, timestamp: new Date() }

    case rts.handleDbUpdate_sportsDoc:
      const { dataToUpdate } = action
      const _refPath = createRefPath_event(pathViews, ['sports', state.sportsKey])
      console.log('_refPath', _refPath)
      fs_update_doc(_refPath, dataToUpdate, action.callback)
      return { ...state }

    case rts.handleUpdate_sportsSeasonSubData:
      return { ...state, [action.subDataType]: action.subData }

    case rts.handleSetSidebar:
      const crts = { ...currents }
      const crtsbrs = { ...currentSidebars }
      crts[action.clickType] = action.selected
      crtsbrs[action.sbItemType] = action.selected
      return { ...state, currents: crts, currentSidebars: crtsbrs }

    case rts.handleGoToTeam:
      if (action.team.id) {
        action.pushSimple({ key: 'teams' }, action.team.id)
        return { ...state, currentTeam: action.team }
      } else {
        return { ...state }
      }

    case rts.handleGoTo_match:
      action.pushSimple(action.clickedItem, action.itemKey, action.modifyMode, action.opts, action.ddn, action.ddProps, action.ddGroupIndex)
      return { ...state, match_edit: matches ? matches[action.itemKey] : null }

    case rts.handleGet_parentKeyCollectionItems:
      getAllParentKeyCollectionItems(pathViews, action.collectionName, state.sportsKey, { collectionName: action.collectionName }, handleSet_parentKeyCollectionItems)
      return { ...state }

    case rts.handleSet_parentKeyCollectionItems:
      const { collectionName, sportsSeasonalData } = action.d
      return { ...state, [collectionName]: sportsSeasonalData }

    case rts.handleGet_gsRoster:
      if (action.roster_gs) {
        const rosterData = {
          sheetName: action.roster_gs.rosterSheet,
          googleSheetsId: action.roster_gs.googleSheetsId
        }
        fsfn_sheets.getGoogleSheetRange(rosterData, handleSet_gsRoster, null, true, action.team)
      }
      return { ...state, roster_gs: null }

    case rts.handleSet_gsRoster:
      if (action.team) {
        return { ...state, ['roster_gs_' + action.team]: action.roster_gs }
      } else {
        return { ...state, roster_gs: action.roster_gs }
      }

    case rts.handleAmmend_mediaMatches:
      return { ...state, mediaMatches: action.mediaMatches }

    case rts.handleAmmend_sportsAccess:
      // const _sportsAccess_sportss = state.sportsAccess_sports
      // _sportsAccess_sports.teams = action.access_sports
      return { ...state }

    case rts.handleAmmend_teamsAccess:
      const _sportsAccess_sports = state.sportsAccess_sports
      _sportsAccess_sports.teams = action.access_teams
      return { ...state, sportsAccess_sports: _sportsAccess_sports }

    case rts.handleShow_sportsElem:
      const { options } = action
      const { sportsShowType } = options ?? {}
      return { ...state, sportsShowType, sportsShowOptions: options }

    case rts.handleCalc_sectionCompares:
      const tc = getSectionRecords(matches, state.teams_info.teams)
      return { ...state, sportCompares: { ...tc } }

    case rts.handleCalc_teamCompares:
      if (action.selectedTeams) {
        const tc = getTeamCompares(matches, action.selectedTeams)
        return { ...state, sportCompares: { ...tc } }
      } else {
        return { ...state, sportCompares: null }
      }

    case rts.handleCalc_matchesRemaining:
      if (action.selectedTeams && action.selectedTeams.length > 0) {
        const tc = getMatchesRemaing(matches, action.selectedTeams)
        return { ...state, sportCompares: { ...tc } }
      } else {
        return { ...state, sportCompares: null }
      }

    case rts.handleFilter_sports:
      return { ...state, sportFilter: action.sf }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation }, { gsMatchedRefreshed: false })


    default:
      return { ...state }
  }
}

export const sportsSeasonHandlers = (dispatch) => {
  return {
    handleAmmend_mediaMatches: (mediaMatches) => { dispatch({ type: rts.handleAmmend_mediaMatches, dispatch, mediaMatches }) },
    handleAmmend_sportsAccess: (access_sports) => { dispatch({ type: rts.handleAmmend_sportsAccess, dispatch, access_sports }) },
    handleAmmend_teamsAccess: (access_teams) => { dispatch({ type: rts.handleAmmend_teamsAccess, dispatch, access_teams }) },
    handleBackup_seasonMatches: (currentYear, matchesToBackup, callback) => { dispatch({ type: rts.handleBackup_seasonMatches, dispatch, currentYear, matchesToBackup, callback }) },
    handleDbUpdate_sportsDoc: (dataToUpdate, callback) => { dispatch({ type: rts.handleDbUpdate_sportsDoc, dataToUpdate, callback }) },
    handleGet_gsRoster: (roster_gs, team) => { dispatch({ type: rts.handleGet_gsRoster, dispatch, roster_gs, team }) },
    handleGet_parentKeyCollectionItems: (collectionName) => { dispatch({ type: rts.handleGet_parentKeyCollectionItems, dispatch, collectionName }) },
    handleGet_seasonMatches: (selectedSeason) => { dispatch({ type: rts.handleGet_seasonMatches, dispatch, selectedSeason }) },
    handleGet_sportsSeasonInfo: (sportsKey, selectedSeason, sports_info) => { dispatch({ type: rts.handleGet_sportsSeasonInfo, dispatch, sportsKey, selectedSeason, sports_info }) },
    handleGet_sportsSeasonInit: (selectedSeason) => { dispatch({ type: rts.handleGet_sportsSeasonInit, dispatch, selectedSeason }) },
    handleGoTo_match: (pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => { dispatch({ type: rts.handleGoTo_match, dispatch, pushSimple, clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex }) },
    handleGoToTeam: (pushSimple, team) => { dispatch({ type: rts.handleGoToTeam, dispatch, pushSimple, team }) },
    handleResults_parentKeyCollections: (sports_data, matches_data, parentDataKeys, sportsKey) => { dispatch({ type: rts.handleResults_parentKeyCollections, dispatch, sports_data, matches_data, parentDataKeys, sportsKey }) },
    handleSet_accessTeams: (sportsAccess) => { dispatch({ type: rts.handleSet_accessTeams, dispatch, sportsAccess }) },
    handleSet_currentHistory: (selectedHistory) => { dispatch({ type: rts.handleSet_currentHistory, dispatch, selectedHistory }) },
    handleSet_gsRoster: (matches_gs, team) => { dispatch({ type: rts.handleSet_gsRoster, dispatch, matches_gs, team }) },
    handleSet_parentKeyCollectionItems: (d) => { dispatch({ type: rts.handleSet_parentKeyCollectionItems, dispatch, d }) },
    handleSet_realtimeResults: (realtimeResults) => { dispatch({ type: rts.handleSet_realtimeResults, dispatch, realtimeResults }) },
    handleSet_seasonMatches: (sports_data, matches_data, parentDataKeys, sportsKey) => { dispatch({ type: rts.handleSet_seasonMatches, dispatch, sports_data, matches_data, parentDataKeys, sportsKey }) },
    handleSet_sportsMode: (sportsMode, sportsModeKey) => { dispatch({ type: rts.handleSet_sportsMode, dispatch, sportsMode, sportsModeKey }) },
    handleSet_sportsSeasonData: (ssData) => { dispatch({ type: rts.handleSet_sportsSeasonData, dispatch, ssData }) },
    handleSet_state: (stateProp, value, groupProp) => { dispatch({ type: rts.handleSet_state, dispatch, stateProp, value, groupProp }) },
    handleSetSidebar: (clickType, selected, sidebarType, sbItemType) => { dispatch({ type: rts.handleSetSidebar, dispatch, clickType, selected, sidebarType, sbItemType }) },
    handleUpdate_sportsSeasonSubData: (subDataType, subData) => { dispatch({ type: rts.handleUpdate_sportsSeasonSubData, dispatch }) },
    handleShow_sportsElem: (options) => { dispatch({ type: rts.handleShow_sportsElem, dispatch, options }) },
    handleUpdate_sportsSeasonTs: () => { dispatch({ type: rts.handleUpdate_sportsSeasonTs, dispatch }) },
    handleCalc_sectionCompares: (selectedTeams) => { dispatch({ type: rts.handleCalc_sectionCompares, dispatch, selectedTeams }) },
    handleCalc_teamCompares: (selectedTeams) => { dispatch({ type: rts.handleCalc_teamCompares, dispatch, selectedTeams }) },
    handleCalc_matchesRemaining: (selectedTeams) => { dispatch({ type: rts.handleCalc_matchesRemaining, dispatch, selectedTeams }) },
    handleFilter_sports: (sf) => { dispatch({ type: rts.handleFilter_sports, dispatch, sf }) },
    ...responseHandlers(dispatch),
  }
}

export const sportsSeasonInitialState = (init_state) => {
  return {
    ...init_state,
    currents: {},
    currentSidebars: {},
    _shows_sports: {}
  }
};

const getAllParentKeyCollectionItems = (pathViews, collectionName, sportsKey, cbProps, callback) => {
  const _pks = seasonalHelpers.getPks(pathViews, null, sportsKey)
  seasonalHelpers.getSports_collectionItem(pathViews, _pks, collectionName, false, cbProps, callback)
}

const ammendSportAccess = (drs) => {
  const { sportsAccess_sports, gls_teams } = drs ?? {}
  const access = {
    ...sportsAccess_sports,
  }
  const settings = {
    ...gls_teams,
  }

  return { access, settings }
}

/**
 * Loops the dataItems_sportsSeason object and fills the drs object with the values from sports_data
 * @param {object} dataItems_sportsSeason 
 * @param {object} sports_data 
 * @returns drs
 */
const getDRS = (dataItems_sportsSeason, sports_data) => {

  const drs = {}

  _.each(dataItems_sportsSeason, (dataItem, dataItemKey) => {
    const { collectionName, collectionDoc, collectionSubs } = dataItem ?? {}
    switch (collectionName) {
      case _matchSeasonalCollection:
        if (collectionSubs) {
          _.each(collectionSubs, (collectionSub) => {
            drs[collectionSub] = sports_data[collectionSub]
          })
        }
        break;
      default:
        if (collectionDoc) {
          drs[collectionDoc] = sports_data[collectionDoc]
        } else if (sports_data[collectionName]) {
          drs[dataItemKey] = sports_data[dataItemKey]
        }
    }
  })

  return drs
}

const getTeamsFromMatches = (matches) => {

  const groupedMatches = _.groupBy(matches, match => `${match.home.levels}-${match.home.sections}`);

  const allTeams = {}

  Object.keys(groupedMatches).forEach(gt => {
    const groupMatches = groupedMatches[gt]
    const sss = _.groupBy(groupMatches, match => `${match.home.id}`);
    Object.keys(sss).forEach(teamKey => {
      const ms = sss[teamKey] // array
      ms.forEach(m => {
        const { home } = m
        if (home.id && !allTeams[teamKey]) {
          allTeams[teamKey] = {
            _itemKey: home.id,
            name: home.name,
            id: home.id,
            levels: home.levels,
            sections: home.sections,
            record: {}
          }
        }
      })
    })
  })

  return allTeams

}


const getSectionRecords = (matches, teams) => {

  const _matches = copyObj(matches)
  _.each(_matches, (match) => {
    match.away.ls = match.away.levels + '_' + match.away.sections
    match.home.ls = match.home.levels + '_' + match.home.sections
  })

  const levelRecords = {}

  const allLevels = _.flatMap(teams, 'levels');
  const _allLevels = _.uniq(allLevels);

  _.each(_allLevels, (levelKey) => {

    levelRecords[levelKey] = {}

    // get all the matches where the home.level or away.level === levelKey
    const filteredMatches_level = _.filter(_matches, match => (match?.away?.levels === levelKey || match?.home?.levels === levelKey) && match.results);

    const allSections = _.flatMap(filteredMatches_level, 'sections');
    const _allSections = _.uniq(allSections);

    _.each(_allSections, (sectionKey) => {

      // get all the matches where the home.sections or away.sections === sectionKey
      const filteredMatches_section = _.filter(filteredMatches_level, match =>
        (match?.away?.sections === sectionKey || match?.home?.sections === sectionKey)
        && match.results
      )

      const record = {};

      record.totalMatches = _.pickBy(filteredMatches_section, m => (m?.away.ls !== m?.home.ls));
      record.wins = _.pickBy(record.totalMatches, m => (m?.results?.winner?.sections === sectionKey));

      const sectionRecord = {
        tm: _.size(record.totalMatches),
        w: _.size(record.wins)
      };

      sectionRecord.l = sectionRecord.tm - sectionRecord.w
      sectionRecord.wp = sectionRecord.tm > 0 ? (sectionRecord.w / (sectionRecord.tm)) : .500
      sectionRecord.wp = sectionRecord.wp.toFixed(3)
      sectionRecord.sectionKey = sectionKey

      levelRecords[levelKey][sectionKey] = sectionRecord
    })
  })
  return { levelRecords }

}

const getMatchesRemaing = (matches, selectedTeams) => {
  if (selectedTeams && selectedTeams.length > 0) {
    const currentDate = new Date();
    const teamMatches = {}
    selectedTeams.forEach(team => {
      const _matches = _.filter(matches, function (m) {
        const matchStartDate = new Date(
          parseInt(m.startDate.split('/')[2]), // year
          parseInt(m.startDate.split('/')[0]) - 1, // month (zero-based)
          parseInt(m.startDate.split('/')[1]) // day
        );
        return m.teams && m.teams.includes(team) && matchStartDate > currentDate;
      }
      )
      teamMatches[team] = _matches
    })
    return { teamMatches }
  }
}

const getTeamCompares = (matches, selectedTeams) => {
  const _teamMatches = {}
  selectedTeams.forEach(team => {
    const _matches = _.filter(matches, function (m) { return m.teams && m.teams.includes(team) && m.results; })
    _teamMatches[team] = _matches
  })
  if (Object.keys(selectedTeams).length > 1) {
    return getSames(matches, _teamMatches, selectedTeams)
  }
}

const getSames = (matches, tms, selectedTeams) => {

  const team1Key = Object.keys(tms)[0]
  const team2Key = Object.keys(tms)[1]
  const team3Key = Object.keys(tms)[2]

  const team1Matches = tms[team1Key]
  const team2Matches = tms[team2Key]
  const team3Matches = team3Key ? tms[team3Key] : null

  let team1Opponents = getOpponents(team1Matches, team1Key)
  let team2Opponents = getOpponents(team2Matches, team2Key)
  let team3Opponents = team3Key ? getOpponents(team3Matches, team3Key) : null

  let _commonOpponents;

  if (team3Key) {
    _commonOpponents = team1Opponents && team2Opponents && team1Opponents.filter(item => team2Opponents.includes(item) && team3Opponents.includes(item));
  } else {
    _commonOpponents = team1Opponents && team2Opponents && team1Opponents.filter(item => team2Opponents.includes(item));
  }

  _commonOpponents = [...new Set(_commonOpponents)]

  const commonMatches = {}
  const directMatches = {}
  const opponentMatches = {}
  const teamResults = {}

  if (_commonOpponents) {
    _commonOpponents.forEach(co => {

      const _matches_common = _.filter(matches, m =>
        (m.teams && m.results && _.intersection(m.teams, [co, team1Key, team2Key]).length >= 2)
      );

      const _matches_direct = _.filter(matches, m =>
        (m.teams && m.results && _.intersection(m.teams, [team1Key, team2Key]).length === 2)
      );

      _.forEach(_matches_common, m => {
        commonMatches[m._itemKey] = commonMatches[m._itemKey] || m;
      });

      _.forEach(_matches_direct, m => {
        directMatches[m._itemKey] = directMatches[m._itemKey] || m;
      });
    });

    _commonOpponents.forEach(co => {
      const _matches_opp = _.filter(commonMatches, m => (m.teams && m.teams.includes(co)));
      opponentMatches[co] = _matches_opp;
    });

    _.forEach(selectedTeams, st => {
      const _matches_team = _.filter(commonMatches, m => (m.teams && m.teams.includes(st)));
      teamResults[st] = {}
      teamResults[st].matchCount = _matches_team.length;
      teamResults[st].wins = _.pickBy(_matches_team, m => (m?.results?.winner?.id === st));
      teamResults[st].w = _.size(teamResults[st].wins)
      teamResults[st].wp = (teamResults[st].w / (teamResults[st].matchCount))
    })

    return { commonMatches, directMatches, opponentMatches, teamResults }

  }

}

const getOpponents = (teamMatches, teamKey) => {

  let team1Opponents = []

  teamMatches.forEach(tm => {
    const { teams } = tm
    teams.forEach(team => {
      if (team !== teamKey) {
        team1Opponents.push(team)
      }
    })
  })

  return team1Opponents
}

const testData = async (pathViews, spreadsheetId) => {
  const gsi = '17wW4AqQOkVkHTAQ9ttl__AIQQhHadfVf4OppcAZSkLg'
  const eventRef = createRefPath_event(pathViews, ['_gls_scores'])
  const wheres = []
  // wheres.push(where('parentKeys.districts', '==', pathViews.districts))
  // wheres.push(where('gls_scores.googleSheetsId', '==', gsi))
  const docs = await fs_get_data({ refPath: eventRef, wheres })
  if (docs) {
    _.each(docs, (doc) => {
      const { gls_scores } = doc ?? {}
      if (gls_scores) {
        console.log('gls_scores', gls_scores)
        const x = _.find(gls_scores, { 'googleSheetsId': gsi })
        console.log('x', x)
      }
    })
  }

}
