import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import { getAlphaFromList } from '../../../../global/common/convert';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import { AlphabetMenuWithProvider } from '../../../../global/components/menus/AlphabetMenu';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import { getStatePaSchoolData } from '../../../../global/viewSettings/importing/schoolResults';
import { getStateResults } from '../../../../global/viewSettings/importing/stateResults';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { SportsOrganizationContext } from '../../cnr/contexts/SportsOrganizationContext';
import { seasonalHelpers } from '../../cnr/reducers/reducerHelpers/seasonalSportsData';

const sidebarType = 'districts'
const _collectionName = 'schoolsByState'

export const OrganizationSchools = () => {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  const sportsOrganizationContext = useContext(SportsOrganizationContext)
  const { sportsOrganization_state, sportsOrganization_handlers } = sportsOrganizationContext ?? {}
  const { districts, schools } = sportsOrganization_state ?? {}

  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { district, schools: schools_district } = sportsDistrict_state ?? {}
  const { districtId } = district ?? {}

  const [districtKeys, setAllDistrictKeys] = useState()
  const [districtSchools, setAllDistrictSchools] = useState()
  const [selectedDistrictAlphaSchools, setSelectedDistrictAlphaSchools] = useState()
  const [selectedDistrictGroupedSchools, setSelectedDistrictGroupedSchools] = useState()
  const [selectedDistrictSchools, setSelectedDistrictSchools] = useState()
  const [sportsYearCollectionItem, setSportsYearCollectionItem] = useState()
  const [updating, setUpdating] = useState()

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { dimmed, sidebar_items, currents } = sidebar_state ?? {}
  const { setInit } = sidebar_handlers ?? {}

  const { ms_district } = sidebar_items ?? {}
  const { selected } = ms_district ?? {}
  const { item: selectedDistrictId } = selected ?? {}

  const selectedDistrictKey = districts ? _.findKey(districts, { districtId: parseInt(selectedDistrictId) }) : null

  useEffect(() => {
    if (selectedDistrictKey) {
      const pks = { organizations: pathViews.organizations, districts: selectedDistrictKey }
      seasonalHelpers.getSports_collectionItem(pathViews, pks, _collectionName, true).then(_sportsYearCollectionItem => {
        setSportsYearCollectionItem(_sportsYearCollectionItem)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedDistrictKey]);

  useEffect(() => {
    const cb = (results) => {
      if (results) {
        const districts = _.groupBy(results, 'district');
        setAllDistrictSchools(districts)
        setAllDistrictKeys(districts ? Object.keys(districts) : [])
        setUpdating(false)
      }
    }
    setUpdating(true)
    getStateResults(districtId, cb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (districtKeys) {
      const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }
      setInit({ smt: sidebarMenuTypes.one, items: districtKeys, currents, as: 'district', ..._initProps })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [districtKeys]);

  useEffect(() => {
    if (selectedDistrictId) {
      if (districtSchools && districtSchools[selectedDistrictId]) {
        const _districtSchools = districtSchools[selectedDistrictId]
        const _groupedSchools = _.groupBy(_districtSchools, obj => obj.name[0]);
        setSelectedDistrictSchools(_districtSchools)
        setSelectedDistrictGroupedSchools(_groupedSchools)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [districtSchools, selectedDistrictId]);

  useEffect(() => {
    if (selectedDistrictSchools) {
      const { firstAsc } = getAlphaFromList(selectedDistrictSchools, 'name')
      handleAlphaChange(firstAsc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedDistrictSchools]);

  function findLikeName(list, inputName) {
    const lowerCaseInput = inputName.toLowerCase();
    return _.find(list, item => item.name.toLowerCase() !== lowerCaseInput && _.includes(item.name.toLowerCase(), lowerCaseInput));
  }

  const handleUpdateSchools = async () => {
    const cb = () => setUpdating(false)
    if (selectedDistrictSchools && selectedDistrictKey) {
      setUpdating(true)
      sportsOrganization_handlers.handleUpdate_organizationSchools(sportsYearCollectionItem, selectedDistrictKey, selectedDistrictSchools, schools, cb)

    }
  }

  const handleGetSchoolData = () => {
    const cb = (ssd) => {
      setSelectedDistrictSchools(ssd)
      const _groupedSchools = _.groupBy(ssd, obj => obj.name[0]);
      setSelectedDistrictGroupedSchools(_groupedSchools)
      setUpdating(false)
    }
    setUpdating(true)
    getStatePaSchoolData(selectedDistrictSchools, cb)
  }

  const handleAlphaChange = (alphaItem) => {
    const _alphaChar = String.fromCharCode(alphaItem)
    const alphaList = selectedDistrictGroupedSchools && selectedDistrictGroupedSchools[_alphaChar] ? selectedDistrictGroupedSchools[_alphaChar] : {}
    setSelectedDistrictAlphaSchools(alphaList)
  }

  const schoolSportList = (sportLevels) => {
    const sports = Object.keys(sportLevels).map(slk => {
      const sportLevel = sportLevels[slk]
      const { level, coop } = sportLevel ?? {}
      return <Label color={coop ? 'grey' : 'blue'}>{slk}{': '} {level} </Label>
    })
    return <div className='sportLevels-school'>
      {sports}
    </div>
  }

  const schoolLine = (value, icon) => {
    if (value) {
      return <div><Icon name={icon} />{value}</div>
    }
  }

  const schoolsList = () => {
    if (selectedDistrictAlphaSchools) {
      const _schools = _.sortBy(selectedDistrictAlphaSchools, 'name')
      return _schools.map(school => {
        const { name, fullName, webSite, phoneNumber, address, schoolKey, district, enrollmentTotal, enrollmentMale, enrollmentFemale, sportLevels, contacts } = school ?? {}
        const _schoolExistingKey = schools ? _.findKey(schools, { name: name }) : null
        return <div>
          <div><Icon name={_schoolExistingKey ? 'check circle outline' : 'circle outline'} color={_schoolExistingKey ? 'green' : 'grey'} /> {name}</div>
          <div><Icon name={'trophy'} color={sportLevels ? 'green' : 'grey'} />Has Sports</div>
          <div><Icon name={'flag'} color={_schoolExistingKey ? 'green' : 'grey'} />{_schoolExistingKey}</div>
          <div>{fullName}</div>
          {schoolLine(schoolKey, 'key')}
          {schoolLine(district, 'map outline')}
          {schoolLine(address, 'map marker alternate')}
          {schoolLine(phoneNumber, 'phone')}
          {schoolLine(webSite, 'globe')}
          {schoolLine(enrollmentTotal, 'users')}
          {schoolLine(enrollmentTotal, 'male')}
          {schoolLine(enrollmentTotal, 'female')}
          {sportLevels && schoolSportList(sportLevels[2024])}
          {contacts && <div>{JSON.stringify(contacts)}</div>}
        </div>
      })
    }
  }

  const content = () => <div>
    <AlphabetMenuWithProvider
      useVertical={true}
      dataItems={selectedDistrictSchools}
      handleAlphaChange={handleAlphaChange}
      restrictToExisting={true}
    />
    <div className='schools-list vlo'>
      {selectedDistrictAlphaSchools && schoolsList()}
    </div>
  </div>

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={selectedDistrictSchools && content()}
    dimmed={dimmed}
  />

  const footer = () => {
    const btns = []
    btns.push({ oc: handleUpdateSchools, caption: sportsYearCollectionItem ? 'Update Schools' : 'Create Schools', icon: 'save', color: sportsYearCollectionItem ? 'blue' : 'green', disabled: !selectedDistrictSchools || !selectedDistrictKey })
    btns.push({ oc: handleGetSchoolData, caption: 'Get School Data', icon: 'arrow right', fl: true })
    return <UiSaveButtons others={btns} />
  }

  const wrapper = () => <Wrapper
    content={menuSidebars()}
    footer={footer()}
    updating={updating}
    wrapperType={wrapperTypes.paddedFooter}
  ></Wrapper>

  return wrapper()

}
