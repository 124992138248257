import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { _checkComponentTimes, _showStates } from '../../../App';
import { _projectAppNames, getAppName, isLocalhost } from '../../../project/appConfiguration';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import FullPageAlert from '../../components/alerts/FullPageAlert';
import { currentHelpers } from '../../redirection/current';
import { startTypes } from '../reducers/StartReducer';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserContext } from './AppUserContext';
import { PapsContext } from './PapsContext';
import { StartContext } from './StartContext';

const _ignoreSa = false

/** 
 * @state clientSettings_state (`clientSettings`)
 * @contexts (`AppSettingsContext`, `HomeSettingsContext`, `PapsContext`, `StartContext`)
 */
export const ClientSettingsContext = createContext();
/** 
 * @state eventSettings_state (`eventSettings`)
 * @functions eventSettings_fns (`getGlobalSetting`, `getGlobalViewItem`, `getGlobalViewItemProps`, `getHomeGlobalSetting`, `mergeViewItemProps`)
 * @contexts (`AppSettingsContext`, `ClientSettingsContext`, `HomeSettingsContext`,`PapsContext`, `StartContext`)
 */
export const EventSettingsContext = createContext();
/** 
 * @state homeSettings_state (`homeSettings`)
 * @contexts (`AppSettingsContext`, `PapsContext`, `StartContext`)
 */
export const HomeSettingsContext = createContext();

const _useAll = false

/**
 * Returns the home, client and event settings contexts from the database 
 * @param {object} props (ignoreStart)
 * @provides ClientSettingsContext (clientSettings)
 * @provides EventSettingsContext (eventSettings)
 * @provides HomeSettingsContext (homeSettings)
 * @contexts [StartContext, AuthContext, AppSettingContext, PapsProvider] 
 * @returns 
 */
const SettingsDataProvider = (props) => {

  const { ignoreStart } = props ?? {}

  const _isLocalhost = isLocalhost()
  const _host_doc = document.location.host

  // authContext 
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { isSuperAdmin } = appUserAccess ?? {}

  const _isSuperAdmin = _ignoreSa ? false : isSuperAdmin

  // startContext
  const startContext = useContext(StartContext);
  const { start_state, start_handlers, start_fns } = startContext ?? {}
  const { startDocName } = start_state ?? {}

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}
  const { settingsDocName, settingsDocKeys, pathViews, pathname } = paps_state ?? {}

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext);
  const { appSettings_state, appSettings_handlers } = appSettingsContext ?? {}
  const { appConfigSettings, fbErrorCode, allAppSettings, appSettings, sandboxOn, appLanding_global, appShortUrls_global, modifiedAppSettings, currentPathName } = appSettings_state ?? {}
  const { useAppLanding, landingUrl, clientKey, eventKey, appLandingUrls } = appLanding_global ?? {}
  const { useAppShortUrls, appLandingShortUrls } = appShortUrls_global ?? {}
  const { appNames } = appConfigSettings ?? {}
  const { appName } = appNames ?? {}

  const _isHome = !pathViews.clients && !pathViews.events
  const _isClient = pathViews.clients
  const _isEvent = pathViews.events

  const { hceSettings } = appSettings ?? {}
  const { settingsReady, settingsError } = appSettings ?? {}

  _checkComponentTimes && start_fns.useTimeMeasurement('SettingsDataProvider', settingsReady)

  const isClient = pathViews && pathViews.clients ? true : false
  let _clientSettings;

  if (hceSettings) {
    _clientSettings = isClient ? hceSettings.client_key : hceSettings.clients
  }

  const newPath = !_.isEqual(currentPathName, pathname)

  const homeSettings_state = hceSettings ? { homeSettings: hceSettings.home } : {}
  const clientSettings_state = _clientSettings ? { clientSettings: _clientSettings } : {}
  const eventSettings_state = hceSettings ? { eventSettings: hceSettings.event_key } : {}

  const navigate = useNavigate()

  const needSettings = (sdk) => {
    let getSettings;
    if (!allAppSettings) {
      getSettings = true
    } else {
      getSettings = allAppSettings[sdk] ? false : true
    }
    return getSettings
  }

  // if the settingsDocName changes, get the settings
  // the callback is handleAmmend_appSettings or handleAmmend_hceAppSettings
  // RERUNS: settingsDocName, pathViews
  useEffect(
    () => {
      if (_useAll) {
        const apsaProps = {
          view: paps_state && paps_state.view,
          pathViews,
          pathname,
          sandboxOn,
          settingsDocKeys,
        }
        appSettings_handlers.handleGet_hceSettings_all(apsaProps)
      } else {
        // loop the settingsDocKeys   
        // the settingsDocKeys is an array container the needed settings keys (home, client, event)    

        const apsPropsBase = {
          view: paps_state && paps_state.view,
          pathViews,
          pathname,
          sandboxOn,
          settingsDocKeys,
        }

        const settingsToGet = []

        Object.keys(settingsDocKeys).forEach(settingsDocKey => {
          const sdn = settingsDocKeys[settingsDocKey]
          const _needSettings = needSettings(sdn);
          if (_needSettings && settingsDocKey) {
            const apsProps = { settingsDocKey, settingsDocName: sdn }
            settingsToGet.push({ ...apsPropsBase, ...apsProps })
          }
        })

        if (settingsToGet.length > 0) {
          if (start_handlers.handleAmmend_startDimmer && (startDocName !== settingsDocName) && !ignoreStart) {
            start_handlers.handleAmmend_startDimmer(startTypes.event, settingsDocName)
          }
          settingsToGet.forEach(stg => {
            appSettings_handlers.handleGet_hceSettings(stg)
          })
        } else {
          if (start_handlers.handleAmmend_startDimmer && (startDocName !== settingsDocName) && !ignoreStart) {
            start_handlers.handleAmmend_startDimmer(startTypes.event, settingsDocName)
          }
          appSettings_handlers.handleAmmend_appSettings(allAppSettings[settingsDocName], pathViews, settingsDocKeys)
          console.log('--- set existing settings ---', settingsDocName)
        }
      }
    },
    // eslint-disable-next-line
    [settingsDocName]
  )

  useEffect(() => {
    if (modifiedAppSettings && newPath) {
      appSettings_handlers.handleAmmend_modifiedAppSettings()
    }
  }, [newPath, modifiedAppSettings]);

  // triggers the redirection of the app to the `landingUrl` if useAppLanding and `landingUrl` are set.
  useEffect(
    () => {

      let _hostUrl;

      if (!isClient) {
        if (useAppLanding && !_isSuperAdmin) {
          if (landingUrl) {
            _hostUrl = '/' + landingUrl
            // navigate('/' + landingUrl)
          } else if (clientKey && eventKey) {
            _hostUrl = '/clients/' + clientKey + '/events/' + eventKey
            // navigate('/clients/' + clientKey + '/events/' + eventKey)
          } else if (appLandingUrls && appLandingUrls) {
            appLandingUrls.forEach(appLandingUrl => {
              const { host, hostUrl } = appLandingUrl
              if (_host_doc === host) {
                if (hostUrl) {
                  _hostUrl = hostUrl
                  // navigate(hostUrl)
                }
              }
            })
          }
        }

        if (useAppShortUrls && appLandingShortUrls && _isHome) {
          const splits = currentHelpers.getSplits()
          appLandingShortUrls.forEach(appLandingShortUrl => {
            const { host, shortUrl, redirctUrl } = appLandingShortUrl
            if (_host_doc === host || _isLocalhost) {
              const shortMatch = splits.includes(shortUrl)
              if (shortMatch) {
                _hostUrl = redirctUrl
                // navigate(redirctUrl)
              }
            }
          })
        }
      }


      if (_hostUrl) { navigate(_hostUrl) }

    },
    // eslint-disable-next-line
    [useAppLanding, useAppShortUrls]
  )

  useEffect(
    () => {
      if (settingsReady) {
        const { event_key } = hceSettings
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready
            .then((registration) => {
              registration.active.postMessage({ action: 'cache-settings', settings: event_key, eventKey: pathViews.events });
            });
        }
      }
      // eslint-disable-next-line
      [settingsReady]
    }
  )

  const homeSettings_fns = {
    getSignInHelp: (helpType) => {
      const { homeSettings } = homeSettings_state ?? {}
      const { global } = homeSettings ?? {}
      const { signInHelp } = global ?? {}
      const help = signInHelp ? signInHelp[helpType] : null
      return help
    }
  }

  const eventSettings_fns = {
    getHomeGlobalSetting: (key, subKey) => {
      const { global } = hceSettings.home ?? {}
      const globalItem = global ? global[key] : {}
      if (globalItem) {
        if (subKey) {
          return globalItem ? globalItem[subKey] : null
        } else {
          return globalItem
        }
      } else {
        return null
      }
    },
    getGlobalSetting: (key, subKey) => {
      const { global } = hceSettings.events ?? {}
      const globalItem = global ? global[key] : {}
      if (globalItem) {
        if (subKey) {
          return globalItem ? globalItem[subKey] : null
        } else {
          return globalItem
        }
      } else {
        return null
      }
    },
    getGlobalViewItem: (key) => {
      const { viewItems } = hceSettings.events ?? {}
      const viewItem = viewItems ? viewItems[key] : {}
      return viewItem
    },
    getGlobalViewItemProps: (key) => {
      const { viewItems } = hceSettings.events ?? {}
      const viewItem = viewItems ? viewItems[key] : {}
      const { props } = viewItem ?? {}
      return props
    },
    mergeViewItemProps: (viewItem) => {
      const { props, key } = viewItem ?? {}
      const { viewItems } = hceSettings.events ?? {}
      const viewItem_g = viewItems ? viewItems[key] : {}
      const { props: props_g } = viewItem_g ?? {}
      const np = {}
      if (props_g) {
        Object.keys(props_g).forEach(pKey => {
          if (props[pKey]) {
            const prop_g = props_g[pKey]
            props[pKey] = _.merge(prop_g, props[pKey])
          }
        })
      }
      return np
    }
  }

  if (_showStates.any && _showStates.settingsData) {
    console.log(Object.keys(eventSettings_fns).sort())
  }

  // , appSettings_handlers: appSettings_handlers // was in EventSettingsContext > NOT needed
  const providerContext = () => <HomeSettingsContext.Provider value={{ homeSettings_state, homeSettings_fns }}>
    <ClientSettingsContext.Provider value={{ clientSettings_state }}>
      <EventSettingsContext.Provider value={{ eventSettings_fns, eventSettings_state }}>
        {props.children}
      </EventSettingsContext.Provider>
    </ClientSettingsContext.Provider>
  </HomeSettingsContext.Provider>

  if (settingsError) {
    console.log('settingsError', settingsError)
    switch (appName) {
      case _projectAppNames.pojo:
        return <FullPageAlert
          title={'Thumbstat'}
          message={"Hello there! Currently working on improving your experience. We apologize for any inconvenience this may cause. Thank you for your patience and understanding! Please try back again at a later time."}
        />
      default:
        return <FullPageAlert
          title={appName}
          message={fbErrorCode ? fbErrorCode : settingsError.toString()}
        />
    }

  } else {
    return settingsReady ? providerContext() : <div></div>
  }

}

export default SettingsDataProvider 