import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Menu, Message, Segment, Sidebar } from 'semantic-ui-react'
import { SportsDistrictContext } from '../../../../projectSpecific/sports/cnr/contexts/SportsDistrictContext'
import { SportsSeasonContext, _sportsFormProps } from '../../../../projectSpecific/sports/cnr/contexts/SportsSeasonContext'
import { _district_glsCollections } from '../../../../projectSpecific/sports/cnr/reducers/SportsDistrictReducer'
import { seasonalHelpers, seasonalUpdateTypes } from '../../../../projectSpecific/sports/cnr/reducers/reducerHelpers/seasonalSportsData'
import SportFormProps from '../../../../projectSpecific/sports/create/SportFormProps'
import { _sdm } from '../../../../projectSpecific/sports/elements/dashboard/SportsDashboard'
import { ParentContext } from '../../../cnr/contexts/ParentContext'
import { uniqueKey } from '../../../common/keys'
import UiSaveButtons from '../../../components/buttons/UiSaveButtons'
import { appViewerColors } from '../../../components/viewers/AppDashboard'
import FullPageWrapper from '../../../wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper'
import UiSideMenuWrapper from './UiSideMenuWrapper'

const _menuDefaults = {
  selectTeam: 'selectTeam',
  selectSport: 'selectSport',
  selectAccess: 'selectAccess',
  selectDistrict: 'selectDistrict',
}

const accessOptions = {
  full: 'full',
  limited: 'limited'
}

const _useSideMenu = true

export const UiDashboardForm = (props) => {

  const { handleCancel, forSportsManagement, sportsDataMode, fromAccess } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states
  const { pathViews, viewKey } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { gls_access, gls_district, sportPermissions } = sportsDistrict_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { sportsKey, googleLinks_info, teams_info, links_score } = sportsSeason_state ?? {}
  const { teams } = teams_info ?? {}
  const { gls_teams } = googleLinks_info ?? {}

  const _pks = seasonalHelpers.getPks(pathViews, null, sportsKey, [viewKey])
  delete _pks.teams

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateTypes.googleLinks]

  // local state
  const [formData, setFormData] = useState()
  const [menuList, setMenuList] = useState()
  const [showItemOptions, setItemOptions] = useState()
  const [currentMenuKey, setCurrentMenuKey] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [scoreLinks, setScoreLinks] = useState()
  const [teamLinks, setTeamLinks] = useState()
  const [accessLinks, setAccessLinks] = useState()
  const [districtLinks, setDistrictLinks] = useState()
  const [updating, setUpdating] = useState()

  const { name: teamName } = currentItem ?? {}

  useEffect(() => {


    let items = []

    switch (sportsDataMode) {

      case _sdm.district.glsAccess:
        setCurrentMenuKey(_menuDefaults.selectAccess)
        items = { [_menuDefaults.selectSport]: { name: 'District', _itemKey: _menuDefaults.selectAccess } }
        break;

      case _sdm.district.glsDistrict:
        setCurrentMenuKey(_menuDefaults.selectDistrict)
        items = { [_menuDefaults.selectSport]: { name: 'District', _itemKey: _menuDefaults.selectDistrict } }
        break;

      case _sdm.district.glsScores:
        setCurrentMenuKey(_menuDefaults.selectSport)
        items = { [_menuDefaults.selectSport]: { name: 'Sport', _itemKey: _menuDefaults.selectSport } }
        break;

      case _sdm.sports.accessTeams:
      case _sdm.sports.glsTeam:
        setCurrentMenuKey(_menuDefaults.selectTeam)
        items = { ...teams }
        break;
      default:
      // nothing
    }

    setMenuList(items)

    setScoreLinks(links_score)
    setTeamLinks(gls_teams)
    setAccessLinks(gls_access)
    setDistrictLinks(gls_district)

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsDataMode]);

  useEffect(() => {

    if (menuList && currentMenuKey) {

      let links;

      switch (currentMenuKey) {
        case _menuDefaults.selectAccess:
          links = accessLinks
          break;

        case _menuDefaults.selectDistrict:
          links = districtLinks
          break;

        default:
          links = currentMenuKey === _menuDefaults.selectSport ? scoreLinks : teamLinks
      }

      setCurrentItem(menuList[currentMenuKey])

      const form_props = _sportsFormProps[sportsDataMode]

      let _formData = {}

      switch (currentMenuKey) {
        case _menuDefaults.selectAccess:
        case _menuDefaults.selectDistrict:
        case _menuDefaults.selectSport:
          const gls = links ?? {}
          ammendFormData(_formData, form_props, gls)
          break;

        default:
          const glt = links && links[currentMenuKey] ? links[currentMenuKey] : {}
          ammendFormData(_formData, form_props, glt)
      }

      setFormData(_formData)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsDataMode, menuList, currentMenuKey]);


  const handleSave = () => {

    const cb = (r) => setUpdating()

    setUpdating(true)

    let updateProps;

    switch (sportsDataMode) {

      case _sdm.district.glsDistrict:
        updateProps = {
          collectionName: _district_glsCollections.glsDistrict,
          dataToUpdate: {
            [_district_glsCollections.gls_access]: districtLinks
          }
        }
        const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, _pks, cb, updateProps.collectionName, null, updateProps.dataToUpdate, { ignoreSeason: true })
        seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.googleLinks, _updateProps)

        break;

      // case _sdm.district.glsLinksScore:
      case _sdm.sports.accessTeams:
      case _sdm.sports.glsTeam:
        updateProps = {
          collectionName: _district_glsCollections.gls_teams,
          dataToUpdate: {
            [_district_glsCollections.gls_teams]: teamLinks
          }
        }
        // const datasToUpdate_s = {
        //   team: {
        //     collectionName: _sdm.collections.gls_teams,
        //     dataToUpdate: {
        //       [_sdm.collections.gls_district]: teamLinks
        //     }
        //   }
        // }
        // seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.googleLinks, pathViews, _pks, datasToUpdate_s.team.collectionName, null, datasToUpdate_s.team.dataToUpdate, cb, { ignoreSeason: true })
        // seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.googleLinks, pathViews, _pks, datasToUpdate_s.sport.collectionName, null, datasToUpdate_s.sport.dataToUpdate, cb, { ignoreSeason: true })
        break;

      default:
      // nothing
    }

    setUpdating(false)

  }

  const ammendFormData = (formData, form_props, link) => {
    if (form_props && formData) {
      Object.keys(form_props).forEach(fp => {
        switch (fp) {
          case 'accessTeam':
            formData[fp] = link && link[fp] ? link[fp] : []
            break;
          default:
            formData[fp] = link && link[fp] ? link[fp] : ''
        }
      })
    }
  }

  const handleFormChange = (e, data) => {

    const _formData = { ...formData }

    console.log('data', data)

    _formData[data.propname] = data.value

    setFormData(_formData)

    const links = currentMenuKey === _menuDefaults.selectSport ? scoreLinks : teamLinks
    const _links = { ...links }
    const _link = _links[currentMenuKey] ? _links[currentMenuKey] : {}

    switch (currentMenuKey) {
      case _menuDefaults.selectDistrict:
        setDistrictLinks(_formData)
        break;

      case _menuDefaults.selectSport:
        setScoreLinks(_formData)
        break;

      default:
        _links[currentMenuKey] = { ..._link, ..._formData }
        setTeamLinks(_links)
        break;
    }
  }

  const handleShowItemOptions = () => setItemOptions(!showItemOptions)

  const handleItemSelect = (key) => {
    setItemOptions()
    setCurrentMenuKey(key)
  }

  const itemOptionsButton = () => {
    const btns = [
      { caption: teamName ? teamName : _.startCase(currentMenuKey), oc: handleShowItemOptions, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.dmv}
      rows={1}
    />
  }

  const formElements = () => {
    const inputs = []
    if (formData) {
      return <SportFormProps formData={formData} handleFormChange={handleFormChange} accessOptions={accessOptions} />
    }
    return inputs
  }

  const header = () => <div className={'header-flex'}>
    {itemOptionsButton()}
  </div>

  const getDescription = () => {
    let description;
    switch (sportsDataMode) {
      case _sdm.district.glsAccess:
        description = 'These are the settings for District Access to the App';
        break;
      case _sdm.district.glsDistrict:
        description = 'These are the settings for District Access to the App';
        break;
      case _sdm.district.glsScores:
        description = 'These are the settings for Scores Access to the App';
        break;
      default:
    }
    return <Message content={description} />
  }

  const form = () => <Form>
    {getDescription()}
    {formElements()}
  </Form>

  const footer = () => <UiSaveButtons save={{ oc: handleSave, caption: 'Save ' + _.startCase(sportsDataMode), color: allowUpdate ? 'blue' : 'olive' }} />

  const wrapperContent = () => {
    switch (sportsDataMode) {
      case _sdm.district.glsAccess:
      case _sdm.district.glsDistrict:
      case _sdm.district.glsScores:
        return form()
      default:
        if (_useSideMenu) {
          return <UiSideMenuWrapper menuItems={menuList} menuObjects={teamLinks} formElements={formElements()} setCurrentMenuKey={setCurrentMenuKey} sportsDataMode={sportsDataMode} />
        } else {
          return currentMenuKey === _menuDefaults.selectTeam ? < div>Select your team</div> : form()
        }
    }
  }

  const wrapper = () => <Wrapper
    header={!_useSideMenu && header()}
    content={wrapperContent()}
    footer={!fromAccess && footer()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={updating}
  />

  const menu = () => {
    const mis = []
    const _items = _.sortBy(menuList, 'name')
    _items.forEach(team => {
      mis.push(<Menu.Item key={uniqueKey('dv', 'mi', team._itemKey)} onClick={() => { handleItemSelect(team._itemKey) }}>{_.startCase(team.name)}</Menu.Item>)
    })
    return <Menu vertical fluid={!_useSideMenu} inverted className={'av-menu df'}>{mis}</Menu>
  }

  const optionsMenu = () => <Segment basic>{menu()}</Segment>

  const OptionsSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='vsl-container'
      onHide={() => handleShowItemOptions()}
    >
      {menuList && optionsMenu()}
    </Sidebar>
  )

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <OptionsSidebar visible={showItemOptions ? true : false} />
    <Sidebar.Pusher dimmed={showItemOptions ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const content = () => {
    switch (sportsDataMode) {
      case _sdm.district.glsScores:
        return wrapper()
      default:
        return _useSideMenu ? wrapper() : sidebar()
    }
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={content()}
    topperCaption={'Google Link'}
    topperCaption2={teamName}
    handleCancel={handleCancel}
  />

  return forSportsManagement || fromAccess ? content() : fullPageWrapper()

}

