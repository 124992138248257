import _ from 'lodash'
import React, { useContext } from 'react'
import { formatItem, formatTypes } from '../../../global/common/dateFormatting'
import { SportsEditMatchContext } from '../cnr/contexts/SportsEditMatchContext'
import { SportsMatchContext } from '../cnr/contexts/SportsMatchContext'
import { _resultsProp } from '../cnr/contexts/SportsSeasonContext'

const MatchHeader = () => {

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true } = sportsMatch_state ?? {}

  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state } = sportsEditMatchContext ?? {}
  const { match_edit } = sportsEditMatch_state ?? {}

  const {
    _itemKey,
    away,
    home,
    isPlayoff,
    key: key_match,
    levels,
    matchNumber,
    poolKey,
    sections,
    startDate,
    startTime,
  } = match_edit ? match_edit : {}

  const results = match_edit[_resultsProp]

  const { name: name_away } = away ?? {}
  const { name: name_home } = home ?? {}

  const { complete: complete_actual, scoreDisplays } = results ?? {}
  const { full: scoreDisplayFull_actual } = scoreDisplays ?? {}

  const _matchKey = key_match ? key_match : _itemKey

  const header = () => <div className={'match-menu-header'}>
    <div>{complete_actual ? scoreDisplayFull_actual : name_away + ' vs. ' + name_home}</div>
    {isPlayoff && <div>{_.startCase(poolKey) + ' - Match #' + matchNumber}</div>}
    <div>{formatItem(formatTypes.shortDate, startDate)}{' - '}{startTime}</div>
    <div>{levels}{sections && ' - ' + sections}</div>
    <div>{_matchKey}</div>
    {/* {complete_actual && <div className='rf'>{'Match results have been updated'}</div>} */}
  </div>

  return header()

}

export default MatchHeader