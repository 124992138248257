import React, { useContext } from 'react';
import AppUserManagement from '../appUsers/AppUserManagement';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { frameworkLeftTypes } from '../cnr/reducers/FrameworkReducer';
import { clickOriginTypes } from '../enums/globalEnums';
import { CreateEventWithProvider } from '../viewSettings/create/addSubs/CreateEvent';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { allPageTypes } from '../enums/allPageTypes';

const AppDataSidebar = React.lazy(() => import('../viewSettings/data/AppDataSidebar'));
const FullPageContent = React.lazy(() => import('../components/layout/FullPageContent'));

const PageFrameworkLeft = () => {

  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkLeftType } = framework_state ?? {}

  console.log('frameworkLeftType', frameworkLeftType)

  switch (frameworkLeftType) {
    case frameworkLeftTypes.appProfilesManager:
    case frameworkLeftTypes.appUsersManager:
    case allPageTypes.meetingsManager:
    case allPageTypes.credentialScan:
    case allPageTypes.notificationsManager:
    case allPageTypes.qAndAManager:
    case allPageTypes.surveyManager:
    case allPageTypes.ticketingManager:
    case allPageTypes.votingManager:
      return <FullPageContent fullPageType={frameworkLeftType} clickOriginType={clickOriginTypes.framework} />

    case frameworkLeftTypes.appUsersPresense:
      return <AppUserManagement clickOriginType={clickOriginTypes.dataViewer} />

    case frameworkLeftTypes.bulkImageUpload:
      return <AppUserManagement clickOriginType={clickOriginTypes.dataViewer} />

    case frameworkLeftTypes.appData:
      return <AppDataSidebar />

    case frameworkLeftTypes.createNewEvent:
    case frameworkLeftTypes.recreateNewEvent:
      return <Wrapper
        header={'Create from Google Sheets'}
        content={<CreateEventWithProvider />}
        // content={<GoogleDashboardWithProvider noModal={true} fromCreate={true} />}
        wrapperType={wrapperTypes.paddedHeader}
      ></Wrapper>
    default:
    // nothing
  }

  return <div>{frameworkLeftType}</div>;

}

export default PageFrameworkLeft
