import React, { useContext } from 'react';
import { Header, Icon, Label, Segment } from 'semantic-ui-react';
import { g_cns } from '../../common/cns';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { _animateTypes } from '../../motions/AnimateComponent';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import MotionComponent from '../../motions/MotionComponent';

/**
 * 
 * @param {object} props (viewItem, altCaption, inverted, noCaption, fullCaption)
 * @returns 
 */
const NoData = (props) => {

  const { viewItem, altCaption, inverted, noCaption, fullCaption, action, labelColor } = props ?? {}
  const { display } = viewItem ?? {}
  const { caption, noDataDisplay, showNoDataDisplay } = display ?? {}
  const _caption = altCaption ? altCaption : caption

  let _actualCaption = _caption ? 'No ' + _caption + ' Items' : 'No Items'
  if (fullCaption) { _actualCaption = fullCaption }
  if (showNoDataDisplay && noDataDisplay) { _actualCaption = noDataDisplay }

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, transition_state } = states ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { themeColors } = aps_global ?? {}
  const { color1 } = themeColors ?? {}

  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.map] : null
  const { showTransition } = transition ?? {}

  const label = () => <Label color={'blue'} style={{ width: '100%', textAlign: 'center' }} onClick={(e) => { action.handleClick(e) }}>{action.caption}</Label>

  const header = () => <Header as='h4' inverted={inverted}>
    <Icon name='info circle' style={{ color: color1 }} color={labelColor ? labelColor : null} />
    {_actualCaption}
  </Header>


  const wrapper = () => <Wrapper
    content={<div className='no-data cntr'>
      {header()}
      {!noCaption && !altCaption && !fullCaption && "No items matching '" + _caption + "'."}
      {action && label()}
    </div>}
    css={{ container: 'ct-no-data' }}
    wrapperType={wrapperTypes.noContent}
  />

  return showTransition ? <MotionComponent transition={transition} >
    {wrapper()}
  </MotionComponent>
    :
    wrapper()
}

export const NoDataCaption = (props) => {
  const { caption } = props
  return <div className={g_cns.app_content}>
    <div className={'no-data-container'}>
      <Header icon as={'h5'} size='tiny'>
        <Icon name='info circle' size='tiny' color={iconColorTypes.info} />
        {"No " + caption}
      </Header>
    </div>
  </div>
}

export const NoDataFetch = (props) => {
  const { text } = props
  return <Segment className={'vs-seg'} placeholder style={{ height: '100%' }}>
    <Header as='h5' icon>
      <Icon name='info circle' color='blue' />
      {text}
    </Header>
  </Segment>
}

export default NoData