import _ from 'lodash';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { approvalRoles, emailTypes } from '../reducers/FieldTripReducer';
import { fieldTripsHandlers, fieldTripsInitialState, fieldTripsReducer } from '../reducers/FieldTripsReducer';

export const FieldTripsContext = createContext();

const FieldTripsProvider = () => {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { eventInfo_state, paps_state, page_state } = states
  const { appDataDocuments } = eventInfo_state
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { fieldTripSettings, appDataSource } = aps_global ?? {}
  const { autoSendOnAdd } = fieldTripSettings ?? {}

  // papsContext
  const { viewKey } = paps_state ?? {}

  // fieldTripReducer
  const init_state = { paps_state, viewKey, fieldTripSettings, appDataSource }
  const [fieldTrips_state, fieldTrips_dispatch] = useReducer(fieldTripsReducer, init_state, fieldTripsInitialState)
  const fieldTrips_handlers = fieldTripsHandlers(fieldTrips_dispatch, fieldTrips_state)
  const { fieldTrips } = fieldTrips_state ?? {}

  useEffect(() => {
    fieldTrips_handlers.handleInit_fieldTrips(appDataDocuments)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appDataDocuments]);

  useEffect(() => {
    if (fieldTrips) {
      const _fieldTripsNotSent = _.filter(fieldTrips, item => !_.has(item, 'status.secretariesEmailSentDate'));
      if (autoSendOnAdd && _fieldTripsNotSent && _fieldTripsNotSent.length > 0) {
        _.each(_fieldTripsNotSent, (ft) => {
          console.log('handleTrigger_email', ft)
          fieldTrips_handlers.handleTrigger_email(ft, approvalRoles.secretaries)
        })
        // console.log('_fieldTripsNotSent', _fieldTripsNotSent)
        // approvalRoles.secretaries
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [fieldTrips]);

  return { fieldTrips_state, fieldTrips_handlers }

};

export default FieldTripsProvider 