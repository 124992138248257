import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { uniqueKey } from '../../../../global/common/keys';
import { gEnums } from '../../../../global/enums/globalEnums';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { gameScoringTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { TeamRecord } from '../teams/TeamRecord';
import { updateArrayInclusion } from '../../../../global/common/sorting';
import { SportsStandingsContext } from './LeagueStandings';
import { CreatePlayoffContext } from '../../create/CreatePlayoff';

const _allowSelect = false

const _tris = {
  l: 'l',
  mr: 'mr',
  n: 'n',
  nsl: 'nsl',
  nst: 'nst',
  nsw: 'nsw',
  op: 'op',
  p: 'p',
  sl: 'sl',
  smr: 'smr',
  sp: 'sp',
  st: 'st',
  sw: 'sw',
  t: 't',
  w: 'w',
}

const _teamNames = ['Catholic', 'Christian', 'Academy', 'County']
const _teamNamesReplace = ['Cath.', 'Christ.', 'Acad.', 'Co.']

const SectionStandings = (props) => {

  // forPlayoff will indicate the teams selected and
  // showMatchesRemainingCount && showMatchesRemainingCount

  const _asat = gEnums.availableSubActionTypes
  const { sectionOpts } = props
  const { teams, selectedTeam, selectedTeams, caption, teamOnly, forOverview, forPlayoff, isFavSection } = sectionOpts

  // parentContext
  const parentContext = useContext(ParentContext);
  const { fns, states } = parentContext ?? {}
  const { appUser_state } = states ?? {}
  const { page_fns } = fns
  const { pushSimple } = page_fns ?? {}

  // authContext 
  const { appUser } = appUser_state ?? {}
  const { profileData } = appUser ?? {}
  const { pageFavs } = profileData ?? {}
  const { teams: team_favs } = pageFavs ?? {}

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_fns, sportsSeason_handlers, sportsSeason_state } = sportsSeasonContext ?? {}
  const { teams_info, details_info, gameScoringType, gameAllowTies, sports_info, _sportsModeSport, showRankingsInStandings } = sportsSeason_state ?? {}
  const { playoffTeams: playoffTeams_season } = teams_info ?? {}
  const { details } = details_info ?? {}
  const { playoffTeamsPerSection } = details ?? {}
  const { showMatchesRemainingCount } = sports_info ?? {}

  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state, createPlayoff_handlers } = createPlayoffContext ?? {}
  const { subActionType, playoff_info } = createPlayoff_state ?? {}
  const { playoffTeams } = playoff_info ?? {}

  const sportsStandingsContext = useContext(SportsStandingsContext)
  const { sportsStandings_state, sportsStandings_handlers } = sportsStandingsContext ?? {}
  const { showSelected, selected_teams, showPlayoffPicture } = sportsStandings_state ?? {}

  const allowSelect = (subActionType === _asat.createPlayoff)

  const sortedTeams = sportsSeason_fns && teams ? sportsSeason_fns.sortSectionStandings(teams, playoffTeamsPerSection, _sportsModeSport, forPlayoff) : {}

  const [selected_team, setSelectedTeam] = useState()

  const handleSelectedTeams = (team) => {
    const _sts = [...selected_teams]
    updateArrayInclusion(_sts, team._itemKey)
    sportsStandings_handlers.handleSet_selected_teams(_sts)
  }

  let cellCount = 0
  const tableType = 'tst'

  const isNonSection = false

  let valueW = 2
  // let spanW = 4
  // let nameW = 8

  // switch (gameScoringType) {
  //   case gameScoringTypes.match:
  //     nameW = 8
  //     spanW = 4
  //     break;
  //   default:
  //     nameW = 6
  //     spanW = 6
  //     break;
  // }

  useEffect(() => {
    if (allowSelect) {
      createPlayoff_handlers.handleInit_playoffTeams(playoffTeams_season)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleTeamClick = (team) => {
    if (forPlayoff) {
      _allowSelect && setSelectedTeam(selected_team ? null : team)
      createPlayoff_handlers.handleSelect_playoffTeamRecord(team)
    } else {
      team && pushSimple && sportsSeason_handlers.handleGoToTeam(pushSimple, team)
    }
  }

  const handlePlayoffClick = (e, team) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    createPlayoff_handlers.handleSelect_playoffTeam(team)
  }

  const handleSelectClick = (e, team) => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    handleSelectedTeams(team)
  }

  const teamRecordDiv = (record) => {

    let trs = []

    const cells = []

    switch (gameScoringType) {
      case gameScoringTypes.match:
      case gameScoringTypes.matchPoints:
        if (record) {
          if (isNonSection) {
            trs = [_tris.sw, _tris.sl]
          } else {
            if (tableType === 'normal') {
              trs = [_tris.nsw, _tris.nsl]
            } else {
              trs = [_tris.sw, _tris.sl]
            }
            if (gameScoringType === gameScoringTypes.matchPoints) {
              trs.push(_tris.p)
            }
            trs.push(_tris.w)
            trs.push(_tris.l)
            if (gameScoringType === gameScoringTypes.matchPoints) {
              trs.push(_tris.op)
            }
          }
        } else {
          trs = [_tris.n, _tris.n]
          if (!isNonSection) {
            if (tableType === 'normal') {
              trs.push(_tris.n)
              trs.push(_tris.n)
            } else {
              trs.push(_tris.n)
              trs.push(_tris.n)
            }
          }
        }
        break;

      case gameScoringTypes.half:
        if (record) {
          if (isNonSection) {
            trs = [_tris.sw, _tris.sl, _tris.st]
          } else {
            if (tableType === 'normal') {
              trs = [_tris.nsw, _tris.nsl, _tris.nst, _tris.nsp]
            } else {
              trs = [_tris.sw, _tris.sl, _tris.st, _tris.sp]
            }
            trs.push(_tris.w)
            trs.push(_tris.l)
            trs.push(_tris.t)
            trs.push(_tris.op)
          }
        } else {
          trs = [_tris.n, _tris.n]
          if (!isNonSection) {
            if (tableType === 'normal') {
              trs.push(_tris.n)
              trs.push(_tris.n)
              trs.push(_tris.n)
              trs.push(_tris.n)
            } else {
              trs.push(_tris.n)
              trs.push(_tris.n)
              trs.push(_tris.n)
              trs.push(_tris.n)
            }
          }
        }
        break;

      case gameScoringTypes.quarter:
        if (record) {
          if (isNonSection) {
            trs = [_tris.sw, _tris.sl, _tris.st]
          } else {
            if (tableType === 'normal') {
              trs = [_tris.nsw, _tris.nsl, _tris.nst]
            } else {
              if (gameAllowTies) {
                trs = [_tris.sw, _tris.sl, _tris.st]
              } else {
                trs = [_tris.sw, _tris.sl]
              }
            }
            trs.push(_tris.w)
            trs.push(_tris.l)
            gameAllowTies && trs.push(_tris.t)
          }
        } else {
          trs = [_tris.n, _tris.n]
          if (!isNonSection) {
            if (tableType === 'normal') {
              trs.push(_tris.n)
              trs.push(_tris.n)
              gameAllowTies && trs.push(_tris.n)
            } else {
              trs.push(_tris.n)
              trs.push(_tris.n)
              gameAllowTies && trs.push(_tris.n)
            }
          }
        }
        break;

      default:
        if (record) {
          if (isNonSection) {
            trs = [_tris.sw, _tris.sl, _tris.st]
          } else {
            if (tableType === 'normal') {
              trs = [_tris.nsw, _tris.nsl, _tris.nst, _tris.nsp]
            } else {
              trs = [_tris.sw, _tris.sl, _tris.st, _tris.sp]
            }
            trs.push(_tris.w)
            trs.push(_tris.l)
            trs.push(_tris.t)
            trs.push(_tris.op)
          }
        } else {
          trs = [_tris.n, _tris.n]
          if (!isNonSection) {
            if (tableType === 'normal') {
              trs.push(_tris.n)
              trs.push(_tris.n)
              trs.push(_tris.n)
            } else {
              trs.push(_tris.n)
              trs.push(_tris.n)
              trs.push(_tris.n)
            }
          }
        }
        break;
    }

    trs.forEach(t => {
      switch (t) {
        case _tris.n:
          cells.push(recordDiv(0, valueW))
          break;
        default:
          cells.push(recordDiv(record[t], valueW))
      }

    })

    return cells
  }

  const standingsHeader = () => {
    let cn = 'standing-section-header'
    let hdrs1;
    if (isNonSection) {
      hdrs1 = ['P', '#', 'team', 'SW', 'SL']
    } else {
      console.log('tableType', tableType, gameScoringType)
      if (tableType === 'normal') {
        hdrs1 = ['team', 'section', 'nonSection', 'w', 'l', 'w', 'l']
      } else {
        switch (gameScoringType) {
          case gameScoringTypes.match:
            hdrs1 = ['section', 'overall', 'team', 'w', 'l', 'ow', 'ol']
            break;
          case gameScoringTypes.matchPoints:
            hdrs1 = ['section', 'overall', 'team', 'w', 'l', 'p', 'ow', 'ol', 'op']
            cn += ' pts'
            break;
          case gameScoringTypes.quarter:
            if (gameAllowTies) {
              hdrs1 = ['section', 'overall', 'team', 'w', 'l', 't', 'ow', 'ol', 'ot']
            } else {
              hdrs1 = ['section', 'overall', 'team', 'w', 'l', 'ow', 'ol']
            }
            break;
          case gameScoringTypes.half:
            hdrs1 = ['team', 'section', 'overall', 'w', 'l', 't', 'p', 'ow', 'ol', 'ot', 'op']
            break;
          default:
            hdrs1 = ['team', 'section', 'overall', 'w', 'l', 't', 'p', 'ow', 'ol', 'ot', 'op']
        }
      }
    }
    return <div key={uniqueKey('ss', 'sh')} className={cn}>
      {hdrs1.map((hi, index) => {
        const _hi = hi.length === 2 ? hi.replace('o', '') : hi
        return <div key={uniqueKey('ss', 'sh', index)} className={'ss-' + hi}>{_.startCase(_hi)}</div>
      })}
    </div>
  }

  // const teamIcon = (team, rowKey, key, defaultImage) => {
  //   const def = (defaultImage) ? defaultImage : 'volleyball'
  //   return <Image key={uniqueKey('ss', 'rc', rowKey, key)} size={settings.imageSize} src={icons.getTeamIcon(team)} className={styles.teamRowIcon} onError={e => e.target.src = '/images/' + def + '.png'} />
  // }


  const recordDiv = (value, width) => {
    cellCount++
    return <div key={uniqueKey('ss', 'rc', cellCount)} >{value}</div>
  }

  const sectionRankLabel = (team, sectionRank, _selected) => {

    const handleClick_label = (e) => {
      e.stopPropagation()
      allowSelect && handlePlayoffClick(e, team)
      showPlayoffPicture && handleSelectClick(e, team)
    }

    let cn;
    if (showPlayoffPicture) {
      cn = 'srl-pp'
      if (_selected) {
        cn += '-sel'
      }
    }

    const playoffTeam = playoffTeams && team ? _.find(playoffTeams, { id: team._itemKey }) : null
    const checked = playoffTeam ? true : false
    const colorSelect = checked ? 'green' : 'grey'
    let color = allowSelect ? colorSelect : null
    return <Label size={'mini'} className={cn} onClick={(e) => { handleClick_label(e) }} color={color} >{sectionRank}</Label>
  }

  const matchRemainingLabel = (smr, mr) => <Label size='mini' className='sssmr' title={'Remaining'}>
    <Icon name='caret right' />{smr}{'/'}{mr}
  </Label>

  const currentRankLabel = (currentRank) => <Label size={'mini'} className={'team-rank'} circular>{currentRank}</Label>
  const sectionLabel = (sectionRank) => <Label size='mini'>{sectionRank}</Label>

  const teamName = (name) => {
    let _name = name
    for (let i = 0; i < _teamNames.length; i++) {
      if (name.includes(_teamNames[i])) {
        _name = name.replace(_teamNames[i], _teamNamesReplace[i]);
      }
    }
    return _name
  }

  const standingsTeamRow = (team, rowKey, rowCn, firstCn, index, _selected) => {

    const { name, record, _itemKey, currentRank, teamNumber } = team ?? {}
    const { playoffTeam, nonPlayoffTeam, playoffEliminated, playoffAssured, sectionRank, smr, mr, tmm } = record ?? {}

    const _name = teamName(name)
    const _firstCn = _name.length > 14 ? firstCn + ' nmr' : firstCn

    const isFav = team_favs && team_favs[_itemKey] ? true : false
    let _rowCn = 'standing-team smr'
    _rowCn += isFav ? ' fav' : ''
    _rowCn += gameScoringType === gameScoringTypes.matchPoints ? ' pts' : ''
    _rowCn += ' ' + rowCn
    const _rowFavIcon = isFav ? <Icon className={'ssfav'} name={'star'} color={'blue'} size={'tiny'} /> : null
    if (isNonSection) {
      return <div key={uniqueKey('ss', 'sr', rowKey)} className={_rowCn}>
        <div>{index + 1}</div>
        <div>{'#' + teamNumber}</div>
        <div>{_name}</div>
      </div>
    } else {
      if (playoffTeam) { _rowCn += ' ss-pt' }
      if (nonPlayoffTeam) { _rowCn += ' ss-npt' }
      if (playoffEliminated) { _rowCn += ' ss-npte' }
      if (playoffAssured) { _rowCn += ' ss-npta' }
      if (tmm && tmm > 0) { _rowCn += ' ss-mm' }
      return <div key={uniqueKey('ss', 'sr', rowKey)} className={_rowCn} onClick={(e) => handleTeamClick(team)}>
        <div className={_firstCn}>
          {sectionRankLabel(team, sectionRank, _selected)}
          {_name}
          {_rowFavIcon}
          {showRankingsInStandings && currentRank && currentRankLabel(currentRank)}
          {!forPlayoff && showMatchesRemainingCount > smr && matchRemainingLabel(smr, mr)}
          {forPlayoff && sectionLabel(sectionRank)}
        </div>
        {teamRecordDiv(record)}
      </div>
    }
  }

  const getSelectedStandings = (sortedTeams) => {

    const _selected = []
    const _teams = Object.values(sortedTeams)
    const index = _.findIndex(_teams, { key: selectedTeam });
    const before = _teams[index - 1];
    const selected = _teams[index];
    const after = _teams[index + 1];
    _selected.push(before)
    _selected.push(selected)
    _selected.push(after)
    return _selected;
  }

  const standingsTeamRows = () => {

    const rows = []
    let _sortedTeams;

    if (forOverview) {
      _sortedTeams = getSelectedStandings(sortedTeams, 1)
    } else {
      _sortedTeams = sortedTeams
    }

    Object.keys(_sortedTeams).forEach((teamKey, index) => {

      const rowKey = 'r' + index
      const team = _sortedTeams[teamKey]

      if (team) {
        const selected = selectedTeam === team.id
        const _selected = selected_teams && (selected_teams.includes(team.id))
        let cellCn = null
        let rowCn = team && (selected) ? 'standings-selected' : null
        if (team && selectedTeams && selectedTeams.includes(team.id)) { rowCn = 'standings-selected' }

        let allowTeam = true

        if (showSelected && selected_teams) {
          if (!selected_teams.includes(team.id)) {
            allowTeam = false
          }
        }

        if (allowTeam) {
          rows.push(standingsTeamRow(team, rowKey, rowCn, cellCn, index, _selected))
          if (showPlayoffPicture && _selected) {
            rows.push(<TeamRecord team={team} selected_teams={selected_teams} />)
          }
        }
      }
    })
    return rows
  }

  const standingsSection = () => {
    const items = []
    items.push(<div key={uniqueKey('ss', 'si')} className='standing-section'>
      {standingsTeamRows()}
    </div>)
    // items.unshift(tableHeader())
    return items
  }

  const content = () => <div>
    {standingsHeader()}
    {standingsSection()}
  </div>

  let cn = 'standings-container'
  if (teamOnly) { cn += ' team' }

  let cnt = 'standings-table ' //  + gameScoringType

  switch (gameScoringType) {
    case gameScoringTypes.half:
      cnt += 'ties'
      break;
    default:
      cnt += 'no-ties'
      break;
  }

  if (gameScoringType === gameScoringTypes.matchPoints) {
    cnt += ' pts'
  }

  return <div className={cn} key={uniqueKey('ss', 's', 'sg')}>
    {!forOverview && <div key={uniqueKey('ss', 's', 'sh')} className={'standings-header'}>
      {isFavSection && <Icon name={'star'} />}
      {caption}
    </div>
    }
    <div key={uniqueKey('ss', 's', 'st')} className={cnt}>
      {content()}
    </div>
  </div>
}

export default SectionStandings 