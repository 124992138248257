import React, { useContext } from 'react';
import { renderHtml } from '../../html/renderHtml';
import Checker from './Checker';
import { FormItemContext } from '../FormItem';
import { FormContext } from '../../../cnr/contexts/FormContext';

const CheckboxInput = (props) => {

  const { captionQuestion, fip: fip_props, data_localForm: data_localForm_props } = props

  // formContext
  const formContext = useContext(FormContext)
  const { form_state } = formContext ?? {}
  const { data_localForm } = form_state ?? {}

  // formItemContext
  const formItemContext = useContext(FormItemContext)
  const { formItem_state } = formItemContext ?? {}
  const { fip, itemProp } = formItem_state ?? {}

  const _fip = fip_props ? fip_props : fip
  const { propname } = _fip ?? {}
  const { prop } = itemProp ?? {}

  const _prop = propname ? propname : prop
  const _data_local = data_localForm_props ? data_localForm_props : data_localForm

  const _value = _data_local && _data_local[_prop]

  const { key, label } = _fip
  const checked = _value ? _value : false
  // delete fip.value

  const handleClick = (e) => {
    const data = { ..._fip, value: checked, checked: !checked, type: 'checkbox' }
    console.log('data', data)
    _fip.onChange(e, data)
  }

  if (captionQuestion) {
    // , border: 'solid 1px lightgrey'
    return <React.Fragment>
      <div style={{ marginBottom: '.5em', padding: '.5em' }}>{renderHtml(captionQuestion)}</div>
      <Checker key={key} item={_fip} label={label} handleClick={handleClick} checked={checked} wrapperType={'inline'} />
    </React.Fragment>
  } else {
    return <Checker key={key} item={_fip} label={label} handleClick={handleClick} checked={checked} wrapperType={'inline'} />
  }
}

export default CheckboxInput

