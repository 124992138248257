import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { updateArrayInclusion } from '../../../../global/common/sorting';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import DataPaste from '../../../../global/components/pasting/DataPaste';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import { matchUpdateTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { sportsHelpers } from '../../cnr/reducers/reducerHelpers/sportsHelpers';
import MatchHeader from '../../create/MatchHeader';
import MatchForm from './MatchForm';
import MatchPlayoffPending from './MatchPlayoffPending';

const MatchPendingUpdate = (props) => {

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext
  const { teams_info, matches_info, sportPermissions } = sportsSeason_state ?? {}
  const { matches, playoffMatches } = matches_info ?? {}
  const { teams } = teams_info ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { matchDataUpdates, newMatchDate, match_true } = sportsMatch_state ?? {}
  const { _itemKey } = match_true ?? {}

  // sportsEditMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { matchPendingUpdate } = sportsEditMatch_state ?? {}
  const { matchUpdateType } = matchPendingUpdate ?? {}

  const _updateStatus = sportPermissions.any && sportPermissions.parentKeySportsData ? true : false

  // eslint-disable-next-line 
  const [matchReady, setMatchReady] = useState()
  const [winnerMatches, setWinnerMatches] = useState()

  useEffect(() => {
    switch (matchUpdateType) {
      case matchUpdateTypes.updatePendingMatch:
        const pendings = {
          levelMatchUpdates: {},
          matchWinners: {},
          matchLosers: {}
        }
        sportsHelpers.getPendingMatchInfo(match_true, playoffMatches, pendings, teams)
        setWinnerMatches(pendings.matchWinners)
        sportsEditMatch_handlers.handleSet_matchDataUpdates(pendings.levelMatchUpdates)
        break;
      case matchUpdateTypes.updatePrePendingMatch:
        const { data_match } = sportsHelpers.getPrePendingMatches(match_true, playoffMatches)
        sportsEditMatch_handlers.handleSet_matchDataUpdates(data_match)
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);


  const [selectedMatchKeys, setSelectedMatchKeys] = useState([])

  const handleItemData = (itemData) => sportsEditMatch_handlers.handleCreate_matchesFromList({ itemData, teams, matches })
  const handleConfirm = () => sportsEditMatch_handlers.handleUpdate_matchPending()

  const handleSelectedMatchKeys = (matchKey) => {
    const _selectedMatchKeys = [...selectedMatchKeys]
    updateArrayInclusion(_selectedMatchKeys, matchKey)
    setSelectedMatchKeys(_selectedMatchKeys)
  }

  const optsList = []
  if (teams) {
    const teamsS = _.sortBy(teams, 'name')
    teamsS.forEach(team => {
      optsList.push(
        {
          key: team.id,
          value: team.id,
          text: team.name
        }
      )
    })
  }

  const form_details = () => <MatchForm shows={{ showDate: true, showTeams: false, showLocation: true }} setMatchReady={setMatchReady} />
  const form_fullDetails = () => <MatchForm shows={{ showDate: true, showTeams: true, showLocation: true, showLevel: true }} setMatchReady={setMatchReady} />
  const form_results = () => <DataPaste handleItemData={handleItemData} />

  const match_pending = () => {
    if (winnerMatches) {
      return <div>
        <MatchPlayoffPending matches={winnerMatches} handleSelectedMatchKeys={handleSelectedMatchKeys} selectedMatchKeys={selectedMatchKeys} />
        <div>{JSON.stringify(matchDataUpdates)}</div>
      </div>
    }
  }

  const match_prePending = () => {
    if (matchDataUpdates) {
      return <div>
        <div>{JSON.stringify(matchDataUpdates)}</div>
      </div>
    }
  }

  const pending = () => {

    let content = _.startCase(matchUpdateType) + '?'

    switch (matchUpdateType) {
      case matchUpdateTypes.updateScore:
        if (matchPendingUpdate.realtimeMatch && matchPendingUpdate.realtimeMatch.currentResults) {
          content += ' (' + matchPendingUpdate.realtimeMatch.currentResults.scoreDisplays.full + ')?'
        }
        break;
      case matchUpdateTypes.updatePendingMatch:
        content = match_pending()
        break;
      case matchUpdateTypes.updatePrePendingMatch:
        content = match_prePending()
        break;
      case matchUpdateTypes.updateMatchDetails:
        content = form_details()
        break;
      case matchUpdateTypes.updateFullMatchDetails:
        content = form_fullDetails()
        break;
      case matchUpdateTypes.updateMatchListResults:
        content = form_results()
        break;
      case matchUpdateTypes.confirmMatchDate:
        content += 'Update Match Date? (' + newMatchDate + ')?'
        break;
      case matchUpdateTypes.updateGsScore:
        content += 'Update GS Score?'
        break;
      default:
        break;
    }

    const isDisabled = () => {
      switch (matchUpdateType) {
        case matchUpdateTypes.updatePendingMatch:
          return matchDataUpdates && Object.keys(matchDataUpdates).length > 0 ? false : true
        case matchUpdateTypes.deleteMatch:
          return true
        default:
          return false
      }
    }

    const header = () => <MatchHeader />

    const footer = () => <UiSaveButtons
      save={{ oc: handleConfirm, caption: _.startCase(matchUpdateType), color: _updateStatus && !isDisabled() ? 'blue' : 'olive', disabled: isDisabled() }}
    />

    return <Wrapper
      header={header()}
      content={content}
      footer={footer()}
      wrapperType={wrapperTypes.padded}
    />
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={pending()}
    topperCaption={_.startCase(matchUpdateType)}
    topperCaption2={_itemKey}
    handleCancel={props.handleCancel}
  />

  return fullPageWrapper()
}

export default MatchPendingUpdate