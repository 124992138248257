import _ from 'lodash';
import { convertHelpers } from '../../../../../global/common/convert';
import { creatingHelpers } from '../../../../../global/common/creating';
import { formatItem, formatTypes, getFormattedDate } from '../../../../../global/common/dateFormatting';
import { addIdsToDataItem } from '../../../../../global/firestoreData/appData/ammendData';
import { getParentKeyData_sports } from './parentKeySportsData';
import { _matchSeasonalCollection } from '../SportsSeasonReducer';

const _showRefPath = true

/**
 * Gets the data for the dataItems (teams, matches, rankings, history, scheduleDates and playoffMatches)
 * @param {object} pathViews 
 * @param {object} dataParents 
 * @param {object} additionalDataParents 
 * @param {string} selectedSeason 
 * @param {function} handleSetDataResults  
 */

// pathViews, dataItems, dataParents, additionalDataParents, sportsKey, selectedSeason, handleSetDataResults, gameScoringType
export const getSportsData_parentKeyCollections = async (sportsOriginType, props, firestore_fns) => {

  const { pathViews, dataItems, dataParents, additionalDataParents, sportsKey, selectedSeason, handleSetDataResults, gameScoringType } = props

  const sports_data = {}
  const sports_refs = {}
  const parentDataKeys = {}

  const matches_data = {
    playoffTournaments: null,
    matchDateGroups: null,
    levelMatches: null,
    levelDateGroups: null,
    levelLastDates: null,
    levelLatestDates: null,
    // levelUpcomingMatches: null,
  }

  const callback_datas = () => {

    let valid = true
    const notValids = []

    Object.keys(dataItems).forEach(dataItemKey => {

      const _dataItem = dataItems[dataItemKey]
      const { collectionName, collectionSubs, collectionDoc } = _dataItem ? _dataItem : {}

      switch (collectionName) {
        case _matchSeasonalCollection:
          if (collectionSubs) {
            _.each(collectionSubs, (collectionSub) => {
              if (!sports_data[collectionSub]) {
                valid = false
                notValids.push(collectionSub)
              }
            })
          }
          break;
        default:
          if (collectionDoc) {
            if (!sports_data[collectionDoc]) {
              valid = false
              notValids.push(collectionDoc)
            }
          } else if (!sports_data[collectionName]) {
            valid = false
            notValids.push(collectionName)
          }
      }
    })


    if (valid) {

      if (sports_refs) {
        const all = {}
        Object.keys(sports_refs).forEach(k => {
          all[k] = {
            ref: sports_refs[k],
            data: sports_data && sports_data[k] ? sports_data[k] : null,
            parentDataKey: parentDataKeys && parentDataKeys[k] ? parentDataKeys[k] : null,
          }
        })
      }

      const { matches, rosters, teams, staff, details } = sports_data

      if (matches) {
        const { _matches, _playoffMatches, _dateGroups, _levelMatches, _levelDateGroups, missingMatches } = getAllMatches(sports_data)
        matches_data.matches = _matches
        matches_data.playoffMatches = _playoffMatches
        matches_data.matchDateGroups = _dateGroups
        matches_data.levelMatches = _levelMatches
        matches_data.levelDateGroups = _levelDateGroups
        const { _lastLevelDates, _latestLevelDates } = getLastMatchDates(_levelDateGroups)
        matches_data.levelLastDates = _lastLevelDates
        matches_data.levelLatestDates = _latestLevelDates
        // matches_data.levelUpcomingMatches = getUpcomingMatches(_matches)
        matches_data.playoffTournaments = createPlayoffTournaments(_playoffMatches)
        matches_data.selectedSeason = selectedSeason
      }

      if (teams && rosters) { matches_data['rosters'] = getAllTeamItems(matches_data, 'rosters', 'athletes') }
      if (teams && staff) { matches_data['staff'] = getAllTeamItems(matches_data, 'staff', 'staff') }
      if (details) { matches_data['details'] = details }

      handleSetDataResults(sports_data, matches_data, parentDataKeys, sportsKey, sports_refs)
    }
  }

  // data, opts
  const callback_data = (data, opts) => {

    const { dataItem, sub, _hasMultipleObjects, itemKey: itemKey_opts, _refPath, wherez } = opts ?? {}
    const { collectionName, collectionDoc, docField, docFields } = dataItem ?? {}

    const _cName = sub ? sub : collectionName

    let dataResultName = collectionDoc ? collectionDoc : _cName
    let dataCollectionName = collectionDoc ? collectionDoc : _cName
    let ignoreData = false;

    const { _itemKey } = data ?? {}
    let d = data ? data[dataResultName] : {}

    switch (_cName) {
      case 'matches_backup':
      case 'matches':
      case 'playoffs':
      case 'teams':
        const itemKey = itemKey_opts ? itemKey_opts : _itemKey
        addIdsToDataItem(d)
        if (itemKey) {
          parentDataKeys[collectionName] = itemKey
        }
        break;
      default:
      // nothing
    }

    switch (_cName) {
      case 'googleLinks':
        if (docFields) {
          ignoreData = true
          docFields.forEach(df => {
            d = data ? data[df] : {}
            sports_data[collectionName + '_' + df] = d ? d : {}
            parentDataKeys[collectionName + '_' + df] = _itemKey ? _itemKey : null
          })
        } else if (docField) {
          d = data ? data[docField] : {}
          dataCollectionName = docField
        }
        break;

      case 'seasonals':
        if (collectionDoc) {
          d = data ? data[collectionDoc] : {}
          dataCollectionName = collectionDoc
          parentDataKeys[collectionName] = _itemKey
        }

        break;

      case 'staff':
      case 'rosters':
      case 'rankings':
        parentDataKeys[collectionName] = _itemKey
        d = data
        break;

      case 'matches_backup':
      case 'matches':
      case 'matchesNonSection':
      case 'playoffs':
        break;

      case 'schoolsByState':
        d = { schools: data && data.schools ? data.schools : {} }
        break;

      case 'sportsAccess_district':
        d = {
          schools: data && data.schools ? data.schools : {},
          sports: data && data.sports ? data.sports : {},
        }
        break;

      case 'sportsAccess_schools':
        d = { schools: data && data.schools ? data.schools : {} }
        break;

      case 'sportsAccess_sports':
        d = { teams: data && data.teams ? data.teams : {} }
        break;

      default:
      // nothing
    }

    if (!ignoreData) {
      sports_data[dataCollectionName] = d ? d : {}
      sports_refs[dataCollectionName] = { _refPath, wherez }
      if (_itemKey) {
        parentDataKeys[dataCollectionName] = _itemKey ? _itemKey : null
      }
    }

    callback_datas()

  }

  const _refPaths = []

  // loop the dataItems, the items that will get retrieved from the database
  Object.keys(dataItems).forEach(key => {
    const dataItem = dataItems[key]
    getParentKeyData_sports(pathViews, dataItem, dataParents, callback_data, additionalDataParents, sportsKey, selectedSeason, null, firestore_fns, _refPaths)
  })

  console.log('getSportsData_parentKeyCollections: refPaths', _refPaths)


}

const getAllMatches = (datas) => {
  // playoffMatches is coming from it's own collection
  const { matches, playoffMatches } = datas
  if (matches) {
    let _matches = matches
    const _playoffMatchesFromMatches = _.pickBy(_matches, { _matchCollectionKey: 'playoffs' })
    const _playoffMatches = !_.isEmpty(playoffMatches) ? playoffMatches : _playoffMatchesFromMatches
    // combine the matches if needed
    if (!_.isEmpty(playoffMatches)) { _matches = { ...matches, ...playoffMatches } }
    const { dateGroups: _dateGroups, missingMatches } = getMatchDateGroups(_matches)
    const { _levelMatches, _levelDateGroups } = getLevelMatches(_matches)
    return { _matches, _playoffMatches, _dateGroups, _levelMatches, _levelDateGroups, missingMatches }
  }
}

const getLevelMatches = (matches) => {

  // group the matches by level.
  const matches_groupedByLevel = _.groupBy(matches, 'levels')

  const _levelMatches = {}
  const _levelDateGroups = {}

  if (matches_groupedByLevel) {

    Object.keys(matches_groupedByLevel).forEach(levelKey => {

      let matches_level = matches_groupedByLevel[levelKey]

      if (matches_groupedByLevel.Non) {
        const matches_home = _.filter(matches_groupedByLevel.Non, m => m.home && m.home.levels === levelKey);
        const matches_away = _.filter(matches_groupedByLevel.Non, m => m.away && m.away.levels === levelKey);
        matches_level = [...matches_level, ...matches_home, ...matches_away]
      }

      if (matches_groupedByLevel.nonDistrict) {
        const matches_home = _.filter(matches_groupedByLevel.nonDistrict, m => m.home && m.home.levels === levelKey);
        const matches_away = _.filter(matches_groupedByLevel.nonDistrict, m => m.away && m.away.levels === levelKey);
        matches_level = [...matches_level, ...matches_home, ...matches_away]
      }

      // create the _itemKeys
      const _groupMatches = creatingHelpers.createKeyedObjectFromKey(matches_level, null, '_itemKey')
      _levelMatches[levelKey] = _groupMatches
      const { dateGroups: _dateGroups, missingMatches } = getMatchDateGroups(_groupMatches)
      _levelDateGroups[levelKey] = _dateGroups
    })
  }

  return { _levelMatches, _levelDateGroups }

}

const getAllTeamItems = (datas, itemType, itemPropType) => {
  const _items = {}
  const { teams } = datas
  const _data = datas[itemType]
  if (teams && _data) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(_data, function (item) {
        return (item && item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _items[tk] = xxx[0][itemPropType]
      }
    })
  }
  return _items
}

const getAllTeamRosters = (datas) => {
  const _teamRosters = {}
  const { teams, rosters } = datas
  if (teams && rosters) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(rosters, function (item) {
        return (item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _teamRosters[tk] = xxx[0].athletes
      }
    })
  }
  return _teamRosters
}

const getAllTeamStaff = (datas) => {
  const _teamStaff = {}
  const { teams, staff } = datas
  if (teams && staff) {
    Object.keys(teams).forEach(tk => {
      const xxx = _.filter(staff, function (item) {
        return (item.parentKeys && item.parentKeys.teams === tk);
      })
      if (xxx && xxx.length === 1) {
        _teamStaff[tk] = xxx[0].staff
      }
    })
  }
  return _teamStaff
}

const getMatchDateGroups = (sortedMatches) => {

  convertHelpers.createItemKeys(sortedMatches)

  const today = new Date()
  const todayF = formatItem(formatTypes.fullDate, today)

  let matchDates = _.groupBy(sortedMatches, 'startDate')

  const dateGroups = []
  let missingMatches = []

  // let groupKeys = Object.keys(x)
  let groupKeys = Object.keys(matchDates)
  groupKeys = _.sortBy(groupKeys, function (value) { return new Date(value); })

  groupKeys.forEach(gk => {
    dateGroups.push({
      matchDate: gk,
      matches: creatingHelpers.createKeyedObjectFromKey(matchDates[gk], null, '_itemKey')
    })
  })

  if (groupKeys[todayF]) {
    dateGroups.unshift({
      matchDate: todayF,
      matches: creatingHelpers.createKeyedObjectFromKey(matchDates[todayF], null, '_itemKey')
    })
  }

  const mz = getMissing(sortedMatches)

  if (mz && Object.keys(mz).length > 0) {
    missingMatches = mz
  }

  return { dateGroups, missingMatches }

}

const createPlayoffTournaments = (playoffMatches) => {
  const ts = []
  const levelMatches = _.groupBy(playoffMatches, 'levels')
  if (levelMatches) {
    Object.keys(levelMatches).forEach(levelKey => {
      ts[levelKey] = { rounds: { playoffs: { itemKey: 'playoffs', pools: {} } } }
      const lm = levelMatches[levelKey]
      const poolMatches = _.groupBy(lm, 'poolKey')
      Object.keys(poolMatches).forEach(poolKey => {
        ts[levelKey].rounds.playoffs.pools[poolKey] = {
          poolKey: poolKey,
          matches: mz(poolMatches[poolKey])
        }
      })
    })
  }
  return ts
}

const mz = (matches) => {
  const ms = {}
  matches.forEach(m => {
    ms['match' + m.matchNumber] = m
  })
  return ms
}

const getMissing = (matches) => {
  if (matches) {
    const missing = _.filter(Object.values(matches), function (m) {
      const today = new Date()
      const yesterday = today.setDate(today.getDate() - 1)
      return (
        new Date(m.startDate) < yesterday
        && !m.results
      );
    })
    if (missing) {
      const m_s = creatingHelpers.createKeyedObjectFromKey(missing)
      return m_s
    }
  }
}

export const getUpcomingMatches = (matches) => {

  const today = new Date()
  const _today = getFormattedDate(today)

  const matches_level = _.groupBy(matches, 'levels')

  const filterMatchesByStartDate = (group, formattedMatchDate) => _.filter(group, match => match.startDate === formattedMatchDate && !match?._status?.isBye);

  const levelUpcomingMatches = {}

  // Iterate through groups using forEach
  Object.keys(matches_level).forEach(level => {

    if (level !== 'undefined') {
      const matches = matches_level[level]

      const dateLimits = getMatchDateLimits(matches)

      let _startDate = new Date();
      _startDate.setHours(0, 0, 0, 0);

      let _startDateFound;
      let filteredMatches = [];
      let selectedMatches = [];
      let iterations = 0;

      if (dateLimits.latestStartDate >= _startDate) {
        // Continue searching until you find matches with a non-zero count
        while (filteredMatches.length === 0 && iterations < 80) {
          const formattedMatchDate = getFormattedDate(_startDate)
          _startDateFound = formattedMatchDate
          filteredMatches = filterMatchesByStartDate(matches_level[level], formattedMatchDate);
          _startDate.setDate(_startDate.getDate() + 1); // Move to the next day
        }
        selectedMatches = selectedMatches.concat(filteredMatches);
        levelUpcomingMatches[level] = {
          matches: filteredMatches,
          startDate: _startDateFound
        }
      } else {
        levelUpcomingMatches[level] = {
          matches: [],
          offSeason: true
        }
      }
    }

  });

  Object.keys(levelUpcomingMatches).forEach(lk => {
    const lms = levelUpcomingMatches[lk]
    const { startDate, matches, offSeason } = lms
    levelUpcomingMatches[lk].offSeason = offSeason
    levelUpcomingMatches[lk].hasMatches = startDate === _today ? true : false
    levelUpcomingMatches[lk].matchCount = startDate === _today ? matches.length : 0
    levelUpcomingMatches[lk].caption = startDate === _today ? matches.length + ' matches today' : 'No matches today.'
    levelUpcomingMatches[lk].subCaption = startDate === _today ? '' : 'Next match ' + formatItem(formatTypes.fullDate, startDate) + '.'
    if (offSeason) {
      levelUpcomingMatches[lk].subCaption = 'No Matches Scheduled'
    }
  })

  return levelUpcomingMatches

}

const getLastMatchDates = (levelDateGroups) => {
  const _lastLevelDates = {}
  const _latestLevelDates = {}
  Object.keys(levelDateGroups).forEach(key => {
    _lastLevelDates[key] = getLastDateKey(levelDateGroups[key])
    _latestLevelDates[key] = getLatestDateKey(levelDateGroups[key])
  })
  return { _lastLevelDates, _latestLevelDates }
}

const getLastDateKey = (ldg) => {

  const ldgKeys = []

  if (ldg) {
    Object.keys(ldg).forEach(ldgKey => {
      ldgKeys.push(ldg[ldgKey].matchDate)
    })
  }

  let dd = false;
  let _dateKey;

  ldgKeys.forEach((dgk, index) => {
    var d1 = new Date(dgk);
    var d2 = new Date();
    var diff = d2.getTime() - d1.getTime();

    _dateKey = ldgKeys[index]

    if (diff < 0 && !dd) {
      const xxx = ldgKeys.indexOf(dgk)
      const yyy = xxx - 1
      const zzz = ldgKeys[yyy]
      _dateKey = zzz
      dd = true
    }
  })

  return _dateKey
}

const getLatestDateKey = (ldg) => {

  const ldgKeys = [] // matchDateKeys

  if (ldg) {
    Object.keys(ldg).forEach(ldgKey => {
      ldgKeys.push(ldg[ldgKey].matchDate)
    })
  }

  let dateIsSet = false;
  let _dateKeyFirst = ldgKeys ? ldgKeys[0] : null;
  let _dateKeyLast = ldgKeys ? ldgKeys[ldgKeys.length - 1] : null;

  // loop the keys to find a match
  ldgKeys.forEach(dgk => {

    var date_matchKey = new Date(dgk);
    var date_today = new Date();

    var diff = date_today.getTime() - date_matchKey.getTime();

    if (diff < 0 && !dateIsSet) {
      const xxx = ldgKeys.indexOf(dgk)
      const yyy = xxx - 1
      const zzz = ldgKeys[yyy]
      _dateKeyLast = zzz ? zzz : _dateKeyFirst
      dateIsSet = true
    }
  })
  return _dateKeyLast
}

const getMatchDateLimits = (matches) => {
  // Initialize variables to hold the earliest and latest startDate
  let earliestStartDate = null;
  let latestStartDate = null;

  // Iterate through the list of matches
  matches.forEach(match => {
    const matchStartDate = new Date(match.startDate);

    // Update earliestStartDate and latestStartDate
    if (earliestStartDate === null || matchStartDate < earliestStartDate) {
      earliestStartDate = matchStartDate;
    }

    if (latestStartDate === null || matchStartDate > latestStartDate) {
      latestStartDate = matchStartDate;
    }
  });

  return { earliestStartDate, latestStartDate }
}