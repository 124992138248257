import React from 'react'
import { Sidebar } from 'semantic-ui-react'

const DataSidebar = (props) => {

  const { visible, content, onHide, direction, width } = props

  const sidebar = () => <Sidebar
    animation={'overlay'}
    direction={direction ? direction : 'left'}
    icon='labeled'
    visible={visible}
    width={width ? width : 'very wide'}
    className='vsr-container'
    onHide={() => onHide()}
  >
    {visible && content}
  </Sidebar>

  return visible ? sidebar() : <div></div>

}

export default DataSidebar