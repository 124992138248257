import _ from 'lodash'
import { dataFix } from '../../common/dataFix'
import { copyObj } from "../../common_web/copy"
import { gEnums } from "../../enums/globalEnums"
import { createRefPath_any, createRefPath_client } from "../../firestoreData/appData/appRefPaths"
import { fs_get_data } from "../../firestoreData/appData/fsAppData"
import { fs_set_doc } from "../../firestoreData/appData/fsData"
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"

const rts = {
  handleGet_clientEvents: 'handleGet_clientEvents',
  handleGet_eventsInfo: 'handleGet_eventsInfo',
  handleSet_clientEvents: 'handleSet_clientEvents',
  handleShow_selectedClientEvent: 'handleShow_selectedClientEvent',
  handleUpdate_clientEvent: 'handleUpdate_clientEvent',
  handleUpdate_clientEvents: 'handleUpdate_clientEvents',
  ...grts
}

const _allowUpdate = true

export const clientInfoReducer = (state, action) => {

  const { pathViews } = state
  const { type, dispatch } = action

  const clientInfo_handlers = clientInfoHandlers(dispatch)
  const { handleSet_clientEvents, handleStopUpdate } = clientInfo_handlers

  console.log('clientInfoReducer', action)

  switch (type) {

    case rts.handleShow_selectedClientEvent:
      return { ...state, selectedEvent: action.selectedEvent }

    case rts.handleGet_clientEvents:
      getClientEvents(pathViews, handleSet_clientEvents)
      return { ...state }

    case rts.handleGet_eventsInfo:
      getClientEventSessions(pathViews, handleSet_clientEvents)
      return { ...state }

    case rts.handleSet_clientEvents:
      return { ...state, clientEvents: action.clientEvents, latest: action.latest, updating: false }

    case rts.handleUpdate_clientEvent:
      const { currentEvent } = action
      const _refPath = createRefPath_client(pathViews, ['events', currentEvent._itemKey])
      dataFix.ammendDeleteProps(currentEvent)
      dataFix.ammendDeleteFields(currentEvent)
      if (_allowUpdate) {
        fs_set_doc(_refPath, currentEvent, true, handleStopUpdate)
      } else {
        handleStopUpdate()
      }
      return { ...state }

    case rts.handleUpdate_clientEvents:
      const _currentAppData = [...action.currentAppData]
      const activeEvents = []
      _currentAppData.forEach(itemData => {
        const _selectedItem = copyObj(itemData)
        if (_selectedItem.eventStatusType === gEnums.eventStatusTypes.active) {
          activeEvents.push(_selectedItem._itemKey)
        }
        const _refPath = createRefPath_client(pathViews, ['events', _selectedItem._itemKey])
        dataFix.ammendDeleteProps(_selectedItem)
        dataFix.ammendDeleteFields(_selectedItem)
        if (_allowUpdate) {
          fs_set_doc(_refPath, _selectedItem, true)
          handleStopUpdate()
        } else {
          handleStopUpdate()
        }
      })
      // const _refPathC = createRefPath_client(pathViews)
      // const clientData = { activeEvents: activeEvents }
      // fs_set_doc(_refPathC, clientData, true, action.cb)
      return { ...state }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.handleCloseConfirmation:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const clientInfoInitialState = (initState) => {
  return { ...initState }
};

export const clientInfoHandlers = (dispatch) => {
  return {
    handleGet_clientEvents: (pathViews) => { dispatch({ type: rts.handleGet_clientEvents, dispatch, pathViews }) },
    handleGet_eventsInfo: () => { dispatch({ type: rts.handleGet_eventsInfo, dispatch }) },
    handleSet_clientEvents: (clientEvents, latest) => { dispatch({ type: rts.handleSet_clientEvents, dispatch, clientEvents, latest }) },
    handleShow_selectedClientEvent: (selectedEvent) => { dispatch({ type: rts.handleShow_selectedClientEvent, dispatch, selectedEvent }) },
    handleUpdate_clientEvent: (currentEvent) => { dispatch({ type: rts.handleUpdate_clientEvent, dispatch, currentEvent }) },
    handleUpdate_clientEvents: (currentAppData) => { dispatch({ type: rts.handleUpdate_clientEvents, dispatch, currentAppData }) },
    ...responseHandlers(dispatch)
  }
}

const getClientEvents = async (pathViews, callback) => {
  const _refPath = createRefPath_client(pathViews, ['events'])
  const events = await fs_get_data({ refPath: _refPath })
  callback(events)
}

const getClientEventSessions = async (pathViews, callback) => {

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const _refPath = createRefPath_client(pathViews, ['events'])
  const events = await fs_get_data({ refPath: _refPath })

  const fetchEvents = async () => {
    // Calculate earliest and latest start dates for each event
    const updatedEvents = Object.keys(events).map(async (eventKey) => {

      const event = events[eventKey]
      const _refPath = createRefPath_client(pathViews, ['events', event._itemKey, 'sessions'])
      const sessions = await fs_get_data({ refPath: _refPath })

      if (Object.keys(sessions).length > 0) {

        const startDates = Object.keys(sessions).map((sessionKey) => new Date(sessions[sessionKey].startDate));
        const earliestStartDate = new Date(Math.min(...startDates));
        const latestStartDate = new Date(Math.max(...startDates));

        // Check if today's date is between earliestStartDate and latestStartDate
        const statuses = {
          isOngoing: today >= earliestStartDate && today <= latestStartDate,
          isCompleted: latestStartDate < new Date(),
          isRecent: latestStartDate >= lastMonth
        }

        return {
          ...event,
          startDate: formatDate(earliestStartDate),
          endDate: formatDate(latestStartDate),
          eventStatusType: statuses.isOngoing ? gEnums.eventStatusTypes.ongoing : statuses.isRecent ? gEnums.eventStatusTypes.recent : statuses.isCompleted ? gEnums.eventStatusTypes.completed : event.eventStatusType,
        };
      } else {
        return {
          ...event,
          eventStatusType: gEnums.eventStatusTypes.pending
        };
      }
    });

    // Update state with events including earliest and latest start dates
    Promise.all(updatedEvents).then((updatedEventsData) => {
      const latest = getSessionsData(updatedEventsData)
      callback(updatedEventsData, latest);
    })
  };

  fetchEvents();

}

const getSessionsData = (clientEvents, callback) => {

  // Find the next and last events based on earliestStartDate using lodash
  const sortedEvents = _.sortBy(clientEvents, (event) => new Date(event.earliestStartDate));

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const nextEvent = _.find(sortedEvents, (event) => new Date(event.earliestStartDate) >= today);
  const lastEvent = _.last(sortedEvents);

  console.log('Next Event:', nextEvent);
  console.log('Last Event:', lastEvent);

  return { nextEvent, lastEvent }

}

const formatDate = (date) => {
  const pad = (num) => (num < 10 ? `0${num}` : num);
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};