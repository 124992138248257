import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { getFbConfig } from './appConfiguration';

const config = getFbConfig()
const firebaseApp = initializeApp(config)

let messaging;

try {
  messaging = getMessaging()
} catch (error) {
  console.error(error)
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus 
export const handleOnMessage = () => {
  return new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    }
  });
}

export default firebaseApp;
