import _ from 'lodash';
import { ammendTeamName } from "../SportsMatchReducer";
import { matchStatusTypes } from '../SportsDataReducer';
import { _matchFoundTypes, _teamsFoundTypes } from './seasonSportsMatchData';
import { _resultsProp } from '../../contexts/SportsSeasonContext';

export const getMatchInfo = (_matches_flat, match_gs, teams, matches) => {

  let { home: home_gs, away: away_gs, cancelled, location: location_gs } = match_gs ?? {}

  // get the score from the google sheet match
  const scores_gs = getMatchScore(match_gs)

  // ammend the team names
  const _homeName = ammendTeamName(home_gs)
  const _awayName = ammendTeamName(away_gs)
  const _locationName = ammendTeamName(location_gs)

  const match_info = {
    home_existing: _.find(teams, { name: _homeName }), // find the HOME team in the teams collection by the name of the home team
    away_existing: _.find(teams, { name: _awayName }), // find the AWAY team in the teams collection by the name of the home team
    teamsFoundType: null,
    matchFoundType: null,
    match_existing: { // get the existing match
      found: _.find(_matches_flat, { home: _homeName, away: _awayName }),
      foundKey: _.findKey(_matches_flat, { home: _homeName, away: _awayName }),
    },
    match_inversed: {},
    match_nonDistrict: {},
    match_gs: match_gs,
    matchStatusTypes: [],
    startDate: null,
    matchScores: {
      gs: scores_gs,
    }
  }

  // TEAMS
  if (match_info.home_existing && match_info.away_existing) {
    // BOTH teams exist in the teams collection
    match_info.teamsFoundType = _teamsFoundTypes.bothTeams
    flattenTeam(match_info, 'home', match_info.home_existing)
    flattenTeam(match_info, 'away', match_info.away_existing)
  } else if (match_info.home_existing || match_info.away_existing) {
    // only ONE team exist in the teams collection
    match_info.teamsFoundType = _teamsFoundTypes.singleTeam
    flattenTeam(match_info, 'home', match_info.home_existing, _homeName)
    flattenTeam(match_info, 'away', match_info.away_existing, _awayName)
  } else {
    // NEITHER team exists in the teams collection
    match_info.teamsFoundType = _teamsFoundTypes.noTeams
  }

  // if (_locationName && (_locationName !== _homeName)) {
  //   match_info.location_existing = _.find(teams, { name: _locationName })
  //   flattenTeam(match_info, 'location', match_info.location_existing, _locationName)
  //   console.log('match_info', match_info)
  // }

  switch (match_info.teamsFoundType) {

    // if both or one team exists
    case _teamsFoundTypes.bothTeams:
    case _teamsFoundTypes.singleTeam:

      // MATCH
      if (match_info.match_existing.found) {
        // FOUND
        match_info.matchStatusTypes.push(matchStatusTypes.existing)
        match_info.matchFoundType = _matchFoundTypes.existing

        ammendMatchInfo(match_info, match_info.match_existing.found)

        match_info.location = '???'

        ammendDbMatchScore(match_info, matches, match_info.match_existing.found._itemKey)

        delete match_info.match_inversed
        delete match_info.match_nonDistrict

      } else {
        // check to see if the gs home and away are inversed, but with the correct home and away teams
        match_info.match_inversed = {
          found: _.find(_matches_flat, { home: _awayName, away: _homeName }),
          foundKey: _.findKey(_matches_flat, { home: _awayName, away: _homeName }),
        }
        if (match_info.match_inversed.found) {
          // FOUND INVERSED
          match_info.matchFoundType = _matchFoundTypes.inversed
          ammendMatchInfo(match_info, match_info.match_inversed.found)
          ammendDbMatchScore(match_info, matches, match_info.match_inversed.found._itemKey)
          delete match_info.match_existing
        } else {
          // check to see if the gs home and away are non district by checking against the team names from the google sheet, not the data
          match_info.match_nonDistrict = {
            found: _.find(_matches_flat, { home: home_gs, away: away_gs }),
            foundKey: _.findKey(_matches_flat, { home: home_gs, away: away_gs })
          }
          if (match_info.match_nonDistrict.found) {
            // FOUND NON DISTRICT
            match_info.matchStatusTypes.push(matchStatusTypes.nonDistrict)
            match_info.matchFoundType = _matchFoundTypes.nonDistrict
            ammendMatchInfo(match_info, match_info.match_nonDistrict.found)
            ammendDbMatchScore(match_info, matches, match_info.match_nonDistrict.found._itemKey)
            delete match_info.match_existing
            delete match_info.match_inversed
          } else {
            if (cancelled === 'TRUE') {
              // FOUND CANCELLED
              match_info.matchStatusTypes.push(matchStatusTypes.cancelled)
              match_info.matchFoundType = _matchFoundTypes.cancelled
            } else {
              match_info.matchStatusTypes.push(matchStatusTypes.new)
              match_info.matchFoundType = _matchFoundTypes.new
              match_info.matchScores = scores_gs
            }
            delete match_info.match_existing
            delete match_info.match_inversed
            delete match_info.match_nonDistrict
          }
        }
      }
      break;

    default:
      match_info.matchStatusTypes.push(matchStatusTypes.notFound)
      match_info.matchFoundType = _matchFoundTypes.new
  }

  // if (!match_info._matchCollectionKey) {
  //   console.log('match_info', match_info)
  // }

  return match_info
}

/**
 * adds the matchFlatKey, startDate and _level key to  match_info
 * @param {object} match_info 
 * @param {object} match 
 */
const ammendMatchInfo = (match_info, match) => {
  match_info.matchFlatKey = match._itemKey
  match_info.startDate = match.startDate
  match_info._matchCollectionKey = match._matchCollectionKey
  if (!match_info._matchCollectionKey) {
    console.log('match_gs', match_info)
  }
}

/**
 * adds matchScores.db to match_info. Add the matchFoundType and adds scoreChanged to the matchStatusTypes
 * @param {object} match_info 
 * @param {object} matches 
 * @param {string} _itemKey 
 */
const ammendDbMatchScore = (match_info, matches, _itemKey) => {
  const { matchScores } = match_info
  const { gs } = matchScores ?? {}
  const dbMatch = matches[_itemKey]
  const results = dbMatch ? dbMatch[_resultsProp] : undefined;
  const { score } = results ?? {}
  const { away: score_away_db, home: score_home_db } = score ?? {}
  match_info.matchScores.db = score
  if (score && gs) {
    if ((gs.away !== score_away_db) || (gs.home !== score_home_db)) {
      match_info.matchFoundType = _matchFoundTypes.scoreChanged
      match_info.matchStatusTypes.push(matchStatusTypes.scoreChanged)
    }
  }
}

const getScoreResult = (match_gs, propKey) => match_gs[propKey] || _.isNumber(match_gs[propKey]) ? parseInt(match_gs[propKey]) : null

const getScoreResults = (match_gs, propKey) => {
  const scores = {}
  for (var i = 1; i <= 5; i++) {
    const sr = getScoreResult(match_gs, propKey + i)
    if (sr) {
      scores[i] = sr
    }
  }
  return scores
}

const getMatchScore = (match_gs) => {
  const matchScores = {
    away: getScoreResult(match_gs, 'awayScore'),
    home: getScoreResult(match_gs, 'homeScore'),
    scores: {
      away: getScoreResults(match_gs, 'away'),
      home: getScoreResults(match_gs, 'home'),
    }
  }

  if (_.isNumber(matchScores.home) && _.isNumber(matchScores.away)) {
    return matchScores
  }
  return null
}


const flattenTeam = (matchInfo, team, matchInfoTeam, teamName) => {
  if (matchInfoTeam) {
    matchInfo[team + '_name'] = matchInfoTeam.name
    matchInfo[team + '_id'] = matchInfoTeam.id
  } else {
    matchInfo[team + '_name'] = teamName
  }
}