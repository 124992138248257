import _ from 'lodash';
import { convertGsToHtml, convertToWYSIWYG, getStaticItems, getStaticKey } from '../../common/convert';
import { dataFix } from '../../common/dataFix';
import { ammendAllDates, ammendDateItemsWithUTC } from '../../common/dateFormatting';
import { createFsDocKey } from '../appData/fsAppData';

const _useSeparator = false

/**
 * Creates the viewItems for the views
 * @param {object} trueResult 
 */
export const updateViewItemViews = (trueResult) => {
  const { projectSettings } = trueResult
  const { views, viewItems } = projectSettings ?? {}
  if (views && viewItems) {
    const viKeys = Object.keys(viewItems)
    Object.keys(views).forEach(viewKey => { // attendees
      if (viewKey.indexOf('_') < 0) {
        const viewItem = views[viewKey]
        const { viewItems: viewItems_view } = viewItem ?? {}
        if (viewItems_view && viewItems_view[viewKey]) {
          const _viewItem = viewItems_view[viewKey]
          const { props } = _viewItem ?? {}
          if (props) {
            Object.keys(props).forEach(propKey => {
              if (viKeys.includes(propKey)) {
                if (viewItems[propKey] && !viewItems_view[propKey]) {
                  if (views[propKey] && views[propKey].viewItems && !views[propKey].viewItems[viewKey]) {
                    console.log('>>>>>>>>', viewKey, propKey)
                  }
                }
              }
            })
          }
        }
      }
    })
  }
}

/** updates: staticViews, parentKeys */
export const appendProjectData = (trueResult) => {
  const { projectData } = trueResult
  const { staticViews, dataCollections } = projectData ?? {}
  // schools 
  if (dataCollections) {
    Object.keys(dataCollections).forEach(dcKey => {
      // school
      const dataCollection = dataCollections[dcKey]
      Object.keys(dataCollection).forEach(dciKey => {
        // dciKey = organization
        const _dataItem = dataCollection[dciKey]
        if (_dataItem.name && _dataItem.lastName && _dataItem.firstName) {
          // delete _dataItem.name
        }
        Object.keys(_dataItem).forEach(dcipKey => {
          const dciPropValue = _dataItem[dcipKey]
          if (staticViews && staticViews[dcipKey]) {
            const staticView = staticViews[dcipKey]
            const sci = _.find(staticView, { name: dciPropValue })
            if (sci && sci.name) {
              const keyy = _.findKey(staticView, { name: dciPropValue })
              if (!_dataItem.parentKeys) { _dataItem.parentKeys = {} }
              _dataItem.parentKeys[dcipKey] = keyy
            }
          }
        })
      })
    })
  }
}

/** updates: staticViews, parentKeys */
export const appendProjectDataNames = (trueResult) => {
  const { projectData } = trueResult
  const { dataCollections } = projectData ?? {}
  // schools 
  if (dataCollections) {
    Object.keys(dataCollections).forEach(dcKey => {
      const dataCollection = dataCollections[dcKey]
      Object.keys(dataCollection).forEach(dciKey => {
        const _dataItem = dataCollection[dciKey]
        const { name, lastName, firstName } = _dataItem ?? {}
        if (name && lastName && firstName) {
          const nameS = name.split(' ')
          switch (nameS.length) {
            case 2:
              break;
            case 3:
              if (name.indexOf('.') >= 0) {
                _dataItem.firstName = nameS[0]
                _dataItem.middleName = nameS[1]
                _dataItem.lastName = nameS[2]
              } else {
                if (!lastName && !firstName) {
                  _dataItem.firstName = nameS[0]
                  _dataItem.lastName = nameS[1] + ' ' + nameS[2]
                }
              }
              break;
            case 4:
              if (name.indexOf(',') >= 0) {
                _dataItem.firstName = nameS[0]
                _dataItem.middleName = nameS[1]
                _dataItem.lastName = nameS[2]
                _dataItem.suffix = nameS[3]
              }
              break;
            default:
            // nothing
          }
          _dataItem.lastName = _dataItem.lastName.replace(',', '')
        }
      })
    })
  }
}

/**
 * Update the google data
 * @param {*} result - The data to be updated
 * @param {*} googleSheetsImportOptions (allowSingleColumnArrays, singleColumnArraySeparator, convertIdsToFirebaseKeys, dateFormat) - Options for the google data
 * @returns The resulting data
 */
export const ammendGoogleResult = async (result, googleSheetsImportOptions, staticViews_app, ignoreAppSvs, forDataManagement, aps_viewItems) => {

  const newCounts = {}

  const { projectData } = result ?? {}

  const {
    allowGuests,
    allowMultiColumnArraysAsObjects,
    allowSingleColumnArrays,
    byPassArrayKeys,
    convertIdsToFirebaseKeys,
    dateFormat,
    singleColumnArraySeparator,
  } = googleSheetsImportOptions ?? {}

  if (projectData) {

    const { dataCollections } = projectData ?? {}

    const dcKeys = Object.keys(dataCollections)

    if (dataCollections) {

      ammendAllDates(dataCollections)

      // loop the dataCollections
      Object.keys(dataCollections).forEach(dcKey => {

        switch (dcKey) {
          case 'issues':
            break;
          default:
            const dataCollection = dataCollections[dcKey]

            dataFix.cleanUpData(dataCollection, true)

            const { newCollection: _dataCollection, newCount } = ammendDataIdsWithFirebaseKeys(dataCollection, convertIdsToFirebaseKeys, staticViews_app, forDataManagement, dcKey, allowGuests, aps_viewItems)

            newCounts[dcKey] = newCount
            if (allowGuests) { ammendGuests(_dataCollection, convertIdsToFirebaseKeys, staticViews_app, forDataManagement) }
            // update the dates
            ammendDateItemsWithUTC(_dataCollection, dateFormat)

            // console.log('dcKey', key)
            convertGsToHtml(_dataCollection)
            // ammendItemsForWeb(_dataCollection, ['description'], dcKey)
            allowSingleColumnArrays && ammendDataItemWithArrays(_dataCollection, singleColumnArraySeparator ? singleColumnArraySeparator : ';', dcKeys)
            dataCollections[dcKey] = _dataCollection
        }

      })

      projectData.staticViews = await createGsStaticViews(dataCollections)

      if (!byPassArrayKeys) {
        ammendDataConnectionsWithKeys(dataCollections, true, allowMultiColumnArraysAsObjects, projectData.staticViews, staticViews_app, ignoreAppSvs, forDataManagement, allowGuests)
      }

      if (dataCollections) {
        ammendCollectionsToCollections(dataCollections)
      }

      projectData.dataCollections = dataCollections
    }
  }

  result.newCounts = newCounts

  return result

}

export const ammendGoogleSheet = (googleSheetsImportOptions, dataCollection, dateFormat) => {

  const { allowSingleColumnArrays, singleColumnArraySeparator, convertIdsToFirebaseKeys } = googleSheetsImportOptions ?? {}

  let _dataCollection;

  if (convertIdsToFirebaseKeys) {
    _dataCollection = ammendDataIdsWithFirebaseKeys(dataCollection)
  } else {
    _dataCollection = dataCollection
  }
  ammendDateItemsWithUTC(_dataCollection, dateFormat)
  allowSingleColumnArrays && ammendDataItemWithArrays(_dataCollection, singleColumnArraySeparator ? singleColumnArraySeparator : ';')
  dataFix.removeEmpties(_dataCollection)
  return _dataCollection
}

/**
 * 
 * @param {object} value 
 * @returns true if the value is NOT boolean, number, object or array
 */
const validValue = (value) => !_.isBoolean(value) && !_.isNumber(value) && !_.isObject(value) && !_.isArray(value)

/**
 * Converts and strings separated by the `separator` and turns them into an array. This does NOT convert the data to their corresponding keys
 * @param {object} items List of data object
 * @param {string} separator The string the separates the list of items in a single data item
 * @param {array} dcKeys  List of collectionKeys
 */
const ammendDataItemWithArrays = (items, separator, dcKeys) => {
  if (items) {
    Object.keys(items).forEach(key => {
      const item = items[key]
      Object.keys(item).forEach(propKey => {
        const value = item[propKey]
        try {
          if (value && validValue(value)) {
            if (_useSeparator && separator && (value.indexOf(separator) >= 0)) {
              const splitV = value.split(separator)
              if (splitV) {
                const sv = []
                splitV.forEach(v => {
                  if (!_.isEmpty(v)) {
                    sv.push(v.trim())
                  }
                })
                item[propKey] = sv
              }
            } else if (dcKeys && dcKeys.includes(propKey)) {
              item[propKey] = [value]
            } else {
              dcKeys.forEach(dcKey => {
                if (propKey.toLowerCase().indexOf(dcKey.toLowerCase()) >= 0) {
                  item[propKey] = [value]
                }
              })
            }
          }
        } catch (error) {
          console.log('value', value, error)
        }
      })
    })
  }
}

/**
 * Converts all the arrays to their corresponding keys
 * @param {object} dataCollections List of dataCollections
 * @param {boolean} getReverse  1uh5CnHG3ptfH9fDv7EpTOT8zTs4VO8-i5qgixwzo4fs
 */
const ammendDataConnectionsWithKeys = (dataCollections, getReverse, allowMultiColumnArraysAsObjects, staticViews_gs, staticViews_app, ignoreAppSvs, forDataManagement, allowGuests) => {

  const reverseCollections = []
  const dcKeys = Object.keys(dataCollections)
  // loop the dataCollections
  Object.keys(dataCollections).forEach(collectionName => {

    const dataCollection = dataCollections[collectionName]

    // loop the rows in the collection 
    Object.keys(dataCollection).forEach(collectionRowKey => {

      const dcRow = dataCollection[collectionRowKey]

      // loop the columns in the dataCollection
      Object.keys(dcRow).forEach(columnName => {
        // if the column name IS a colletion
        let validColumnName;
        let altColumnName;
        if (dcKeys.includes(columnName)) {
          validColumnName = columnName
        } else if (allowGuests && columnName === 'guests') {
          validColumnName = 'attendees'
          altColumnName = 'attendees'
        } else {
          dcKeys.forEach(dcKey => {
            if (columnName.toLowerCase().indexOf(dcKey.toLowerCase()) >= 0) {
              validColumnName = dcKey
            }
          })
        }

        if (validColumnName) {

          const columnCollection = dataCollections[validColumnName]
          const _staticView_app = staticViews_app && staticViews_app[validColumnName] ? staticViews_app[validColumnName] : null
          const _staticView_gs = staticViews_gs && staticViews_gs[validColumnName] ? staticViews_gs[validColumnName] : null

          const _columnName = altColumnName ? altColumnName : columnName

          if (columnCollection) {

            // get the value of the collection column
            const itemValue = dcRow[_columnName]
            // is it an array
            if (_.isArray(itemValue)) {

              itemValue.forEach(iv => {
                let matchedKey_static_app;
                let matchedKey_static_gs;
                let matchedKey_collection;

                if (forDataManagement) {
                  if (_staticView_app && !ignoreAppSvs) {
                    matchedKey_static_app = ammendKey(dcRow, _columnName, _staticView_app, iv, allowMultiColumnArraysAsObjects)
                  } else if (_staticView_gs && !ignoreAppSvs) {
                    matchedKey_static_gs = ammendKey(dcRow, _columnName, _staticView_gs, iv, allowMultiColumnArraysAsObjects)
                  } else {
                    matchedKey_collection = ammendKey(dcRow, _columnName, columnCollection, iv, allowMultiColumnArraysAsObjects)
                  }
                } else {
                  if (_staticView_gs) {
                    matchedKey_static_gs = ammendKey(dcRow, _columnName, _staticView_gs, iv, allowMultiColumnArraysAsObjects)
                  } else if (_staticView_app && !ignoreAppSvs) {
                    matchedKey_static_app = ammendKey(dcRow, _columnName, _staticView_app, iv, allowMultiColumnArraysAsObjects)
                  } else {
                    matchedKey_collection = ammendKey(dcRow, _columnName, columnCollection, iv, allowMultiColumnArraysAsObjects)
                  }
                }

                if (getReverse) {
                  reverseCollections.push({
                    collectionName,
                    collectionRowKey,
                    columnName: columnName,
                    validColumnName,
                    iv,
                    matchedKey_static_app,
                    matchedKey_static_gs,
                    matchedKey_collection
                  })
                }
              })
              flipValues(dcRow, _columnName)
            }
          }
        }
      })
    })
  })

  if (getReverse) {
    const columnKeys = _.groupBy(reverseCollections, 'columnName')
    if (columnKeys) {
      Object.keys(columnKeys).forEach(reverseKey => {
        const columnKeyArray = columnKeys[reverseKey]
        columnKeyArray.forEach(_columnKey => {
          const { collectionName, collectionRowKey, matchedKey_collection, matchedKey_static_app, matchedKey_static_gs, validColumnName } = _columnKey

          if (matchedKey_collection) {
            const dcRow_collection = dataCollections[validColumnName][matchedKey_collection]
            if (dcRow_collection) {
              if (!dcRow_collection[collectionName]) { dcRow_collection[collectionName] = [] }
              try {
                if (!dcRow_collection[collectionName].includes(collectionRowKey)) {
                  dcRow_collection[collectionName].push(collectionRowKey)
                }
              } catch (error) {
                console.log('dcRow_collection Error', collectionName, error)
              }

            }
          } else if (matchedKey_static_app) {
            const dcRow_static = staticViews_app[validColumnName]
            if (dcRow_static) {
              if (!dcRow_static[collectionName]) { dcRow_static[collectionName] = [] }
              try {
                if (!dcRow_static[collectionName].includes(collectionRowKey)) {
                  dcRow_static[collectionName].push(collectionRowKey)
                }
              } catch (error) {
                console.log('dcRow_collection Error', collectionName, error)
              }

            }
          } else if (matchedKey_static_gs) {
            const dcRow_static = staticViews_gs[validColumnName]
            if (dcRow_static) {
              if (!dcRow_static[collectionName]) { dcRow_static[collectionName] = [] }
              try {
                if (!dcRow_static[collectionName].includes(collectionRowKey)) {
                  dcRow_static[collectionName].push(collectionRowKey)
                }
              } catch (error) {
                console.log('dcRow_collection Error', collectionName, error)
              }
            }
          }
        })
      })
    }
  }
}

const ammendCollectionsToCollections = (dataCollections) => {
  const dcKeys = Object.keys(dataCollections)
  Object.keys(dataCollections).forEach(collectionKey => {
    const dataCollection = dataCollections[collectionKey]
    if (dataCollection) {
      Object.keys(dataCollection).forEach(collectionItemKey => {
        const dataCollectionItem = dataCollection[collectionItemKey]
        if (dataCollectionItem) {
          Object.keys(dataCollectionItem).forEach(relatedItemKey => {
            if (dcKeys.includes(relatedItemKey)) {
              const relatedPropValue = dataCollectionItem[relatedItemKey]
              const relatedCollection = dataCollections[relatedItemKey]
              if (_.isArray(relatedPropValue)) {
                relatedPropValue.forEach(relatedArrayItemKey => {
                  if (relatedCollection && relatedCollection[relatedArrayItemKey]) {
                    const relatedItem = relatedCollection[relatedArrayItemKey]
                    // add a prop array for the collectionKey
                    if (!relatedItem[collectionKey]) { relatedItem[collectionKey] = [] }
                    if (!relatedItem[collectionKey].includes(collectionItemKey)) {
                      relatedItem[collectionKey].push(collectionItemKey)
                    }
                  }
                })
              }
            }
          })
        }
      })
    }
  })
}

const ammendKey = (dcRow, columnName, columnCollection, itemValue, allowMultiColumnArraysAsObjects) => {
  // find the key in the item's columnCollection 
  const matchedKey = _.findKey(columnCollection, function (o) { return ((o.name === itemValue) || (o.firstName + ' ' + o.lastName === itemValue)); });
  if (matchedKey) {
    if (!dcRow['_' + columnName]) { dcRow['_' + columnName] = [] }
    dcRow['_' + columnName].push(matchedKey)
    if (allowMultiColumnArraysAsObjects) {
      if (!dcRow._svs) { dcRow._svs = {} }
      if (!dcRow._svs[columnName]) { dcRow._svs[columnName] = {} }
      dcRow._svs[columnName][matchedKey] = itemValue
    }
  }
  return matchedKey
}

const flipValues = (dcRow, columnName) => {
  dcRow[columnName] = dcRow['_' + columnName]
  delete dcRow['_' + columnName]
}

/**
 * Creates a new data collection with firebase keys
 * @param {object} dataCollection - The data collection
 * @returns the ammended data collection
 */
const ammendDataIdsWithFirebaseKeys = (dataCollection, convertIdsToFirebaseKeys, staticViews_app, forDataManagement, collectionKey, allowGuests, aps_viewItems, useItemKey) => {

  const newCollection = {}
  let newCount = 0
  let itemCount = 0

  try {
    const stsps = []
    const _staticList = dataCollection ? getStaticItems(Object.values(dataCollection), 'name', stsps) : []
  } catch (error) {
    console.log('error', error)
  }

  Object.keys(dataCollection).forEach(dcKey => {

    const _dataItem = dataCollection[dcKey]

    const { itemKey } = _dataItem

    itemCount++

    let _itemKey;
    let _new;

    if (forDataManagement) {
      _itemKey = getStaticKey(staticViews_app, collectionKey, _dataItem, allowGuests, aps_viewItems)

      if (!_itemKey) {
        // console.log('No Item Key', collectionKey, _dataItem)
        _itemKey = createFsDocKey('sheets', collectionKey)
        const xxx = getStaticKey(staticViews_app, collectionKey, _dataItem, allowGuests, aps_viewItems)
        _new = true
      }
    } else {
      if (convertIdsToFirebaseKeys) {
        _itemKey = createFsDocKey('sheets', collectionKey)
        _new = true
      } else {
        _itemKey = dcKey
      }
    }

    if (itemKey) {
      _itemKey = itemKey
    }

    newCollection[_itemKey] = { ..._dataItem }
    newCollection[_itemKey]._itemKey = _itemKey

    if (_new) {
      newCollection[_itemKey]._new = _new
      newCount++
    }

  })

  return { newCollection, newCount }
}

const ammendGuests = (dataCollection, convertIdsToFirebaseKeys, staticViews_app, forDataManagement) => {
  const guestItems = []
  Object.keys(dataCollection).forEach(dcKey => {
    let hasGuest = false
    const guestProps = {}
    const dcRow = dataCollection[dcKey]
    const rowProps = Object.keys(dcRow)
    rowProps.forEach(rp => {
      if (rp.toLowerCase().startsWith('guest')) {
        hasGuest = true
        const _rp = rp.indexOf('Type') > 0 ? rp : _.camelCase(rp.replace('guest', ''))
        guestProps[_rp] = dcRow[rp]
      }
    })
    if (hasGuest) {
      guestProps.guestOfId = dcKey
      guestProps.guestOfEmail = dcRow.email
      guestProps.registrationTypes = 'Guest'
      guestItems.push(guestProps)
    }
  })
  if (guestItems && guestItems.length > 0) {
    const startIndex = Object.keys(dataCollection).length + 2
    let count = startIndex
    guestItems.forEach(gi => {
      const { guestOfId } = gi
      if (convertIdsToFirebaseKeys) {
        let guestKey;
        if (forDataManagement && staticViews_app) {
          guestKey = getStaticKey(staticViews_app, 'attendees', gi, true)
        } else {
          guestKey = createFsDocKey()
        }
        dataCollection[guestKey] = gi
        if (!dataCollection[guestOfId].guests) { dataCollection[guestOfId].guests = [] }
        dataCollection[guestOfId].guests.push(guestKey)
      } else {
        dataCollection[count] = gi
      }
      count++
    })
  }
  return guestItems
}

const createGsStaticViews = async (dataCollections) => {
  const svs = {}
  if (dataCollections && Object.keys(dataCollections).length > 0) {
    Object.keys(dataCollections).forEach(dcKey => {
      if (dcKey) {
        svs[dcKey] = {}
        const staticView = svs[dcKey]
        const dataCollection = dataCollections[dcKey]
        if (staticView && dataCollection && Object.keys(dataCollection).length > 0) {
          Object.keys(dataCollection).forEach(dciKey => {
            try {
              const dci = dataCollection[dciKey]
              //@ts-ignore
              const { name, firstName, lastName } = dci ? dci : {}
              if (firstName && lastName) {
                staticView[dciKey] = { lastName, firstName }
              } else if (name) {
                staticView[dciKey] = { name }
              }
            } catch (error) {
              console.error(error);
              // nothing - it does not exist
            }
          })
        }
      }
    })
  }
  return svs
}

export const ammendItemsForWeb = (items, webList, dcKey) => {
  if (items) {
    Object.keys(items).forEach(key => {
      const item = items[key]
      Object.keys(item).forEach(propKey => {
        let value = item[propKey]
        if (webList.includes(propKey)) {
          item[propKey] = convertToWYSIWYG(value)
          // const x = convertToBetterText(value)
          // console.log('convertToWYSIWYG', x)
          // console.log('formatToWYSIWYG', formatToWYSIWYG(value))
          // console.log('item[propKey]', item[propKey])
        }
      })
    })
  }
}

