import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { seasonMatchesHandlers, seasonMatchesInitialState, seasonMatchesReducer } from "../reducers/SeasonMatchesReducer";
import { SportsSeasonContext, matchScheduleTypes } from "./SportsSeasonContext";

export const SeasonMatchesContext = createContext()

export default function SeasonMatchesProvider(props) {

  const {
    commonMatches,
    latestMatchesCount,
    forBackup,
    matchScheduleType,
    scheduleDisplayType,
    matchScheduleDisplayType,
    previewMatches,
    teamKey,
  } = props

  const {
    poolMatches,
    pools,
  } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { paps_state } = states
  const { page_fns } = fns
  const { pushSimple } = page_fns ?? {}

  const { pathViews } = paps_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { matches_info, seasonMatches_info, sportPermissions } = sportsSeason_state ?? {}

  const _matches_info = forBackup && seasonMatches_info ? seasonMatches_info : matches_info

  const _teamKey = teamKey ? teamKey : pathViews.teams

  const scheduleTypes = {
    district: pathViews && pathViews.districts && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: teamKey || (pathViews && pathViews.teams) ? true : false,
    common: commonMatches ? true : false
  }

  let _sepBySection = true
  if (scheduleTypes.team || scheduleTypes.match || scheduleTypes.common) {
    _sepBySection = false
  }

  const seasonMatches_fns = {
    getMatchCn: (match, useScoreBlock, tournamentType, playoffDates, allUpdates, matchResultFullBackground) => {
      const { key, id, complete, isPlayoff, _flipHa } = match ?? {}
      const _isPlayoff = false
      const keyy = key ? key : id

      let wcn = 'schedule-match'

      if (matchScheduleType === matchScheduleTypes.latest) { wcn += ' lmo' }
      if (useScoreBlock) { wcn += ' blocked' }
      if (complete) { wcn += ' complete' }

      if (tournamentType) {
        wcn += ' pool'
      } else if (scheduleTypes.match) {
        wcn += ' match'
      } else if (scheduleTypes.team && !_isPlayoff) {
        wcn += ' teams'
      } else {
        wcn += ' section'
      }

      if (matchResultFullBackground) {
        wcn += ' fmrb'
      }

      if (playoffDates || isPlayoff) {
        wcn += ' playoff'
        if (_flipHa) {
          wcn += ' flip'
        }
      }

      if (allUpdates && allUpdates[keyy]) {
        wcn += ' mmr'
      }

      return wcn
    }
  }

  // sidebar
  const init = {
    _teamKey,
    commonMatches,
    latestMatchesCount,
    matchScheduleType,
    scheduleDisplayType,
    matchScheduleDisplayType,
    matchesInfo: _matches_info,
    pathViews,
    previewMatches,
    pushSimple,
    scheduleTypes,
    sepBySection: _sepBySection,
    sportsSeason_fns,
    sportPermissions,
  }

  const [seasonMatches_state, seasonMatches_dispatch] = useReducer(seasonMatchesReducer, seasonMatchesInitialState(init));
  const seasonMatches_handlers = seasonMatchesHandlers(seasonMatches_dispatch)

  // update the scheduledMatches
  useEffect(() => {
    seasonMatches_handlers.handleMatches_init(_matches_info, commonMatches)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, _matches_info, matchScheduleType, commonMatches]);

  useEffect(() => {
    seasonMatches_handlers.handleMatches_initPlayoff(pools, poolMatches)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, poolMatches, pools]);

  return <SeasonMatchesContext.Provider
    value={{ seasonMatches_state, seasonMatches_handlers, seasonMatches_fns }}>
    {props.children}
  </SeasonMatchesContext.Provider>
}