import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Icon, Label, Menu } from 'semantic-ui-react'
import { getAppUserAccess } from '../auth/appUserAccessPermissions'
import { FrameworkContext } from '../cnr/contexts/FrameworkContent'
import { ParentContext } from '../cnr/contexts/ParentContext'
import { uniqueKey } from '../common/keys'
import { appIconTypes } from '../enums/appIconTypes'
import { gEnums } from '../enums/globalEnums'
import UiDataModifications, { dataModificationDisplayTypes } from '../pageItem/modification/UiDataModifications'
import { itemActionIcons, itemActionTypes } from '../viewSettings/enums/itemActionTypes'
import { sidebarOptions } from './sidebarOptions'
import { SwiperContext } from '../cnr/contexts/SwiperContext'

export const PageItemOptionsContext = createContext();

const optionTypes = {
  appUserItems: 'appUserItems',
  dataManagement: 'dataManagement',
  other: 'other',
  userItems: 'userItems',
}

const _useIcons = true

export const PageItemOptionsMenu = (props) => {

  const { handleCancel } = props

  const _allowSingleItemPopup = true

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, page_state, paps_state } = states
  const { pageSettings, pageNavs, currentPageNav } = page_state ?? {}
  const { view } = paps_state ?? {}
  const { appUsers } = appUser_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext
  const { additionalNavigationItems: additionalNavigationItems_fw, desktopMode, homePageCaption, swipeItemKey } = framework_state ?? {}
  const { handleShow_appBottomSidebar, handleShow_pageNav } = framework_handlers ?? {}

  console.log('swipeItemKey', swipeItemKey)

  const viewListData = {}
  const vldCount = viewListData ? Object.keys(viewListData).length : 0

  const [activeItem, setActiveItem] = useState()
  const [menuItems, setMenuItems] = useState()
  const [pageNav, setPageNav] = useState()

  console.log('currentPageNav', currentPageNav)

  const { navOptions, navUiItemContext } = pageNav ?? {}
  const { appUserLinkItems: appUserActionItems, pageNavOptions } = navOptions ?? {}
  const { item_state } = navUiItemContext ?? {}
  const { viewItem_key, viewItem, itemData } = item_state ?? {}
  const { dataModifications } = viewItem ?? {}
  const { add, sorting } = dataModifications ?? {}
  const { appFormType } = add ?? {}
  const { createListAs } = sorting ?? {}

  useEffect(() => {
    if (pageNavs) {
      const _pageNav = pageNavs[swipeItemKey]
      setPageNav(_pageNav)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pageNavs, swipeItemKey]);

  useEffect(() => {
    if (pageNav) {
      const _menuItems = getMenuItems()
      setMenuItems(_menuItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [swipeItemKey, pageNav]);

  const handleOpenMenuItem = (groupKey) => setActiveItem(groupKey === activeItem ? null : groupKey)
  const handleDirectClick = (key) => {
    console.log('key', key)
  }

  const handleIconClick = (item, dmiKey, subKey) => {
    handleShow_pageNav(item, dmiKey, subKey)
    // setShownItem(dmiKey)
  }

  const menuItemContent = (items, cn) => {
    const mis = items ? items.map((item, index) => {
      return <Menu.Item key={uniqueKey('smi', item)} className={cn} >
        <div><Icon key={uniqueKey('uias', item, index)} name={'cog'} /></div>
        <div>{_.startCase(item)}</div>
        <div><Icon name={appIconTypes.arrowRight} color={'blue'}></Icon></div>
      </Menu.Item>
    }) : []
    return mis
  }

  const getTitles = (pat, sat) => {

    let icon = itemActionIcons[pat] ? itemActionIcons[pat] : 'question'
    let title = _.startCase(pat)
    let titleExtra = ''
    let disabled = false
    let labeled = null

    switch (pat) {
      case itemActionTypes.signInAs:
        if (itemData && !itemData.email) {
          disabled = true
          titleExtra = ' (Not available - No Email)'
        } else {
          if (itemData) {
            // title = title + ' (' + itemData.email + ')'
          }
        }
        break;

      case itemActionTypes.appUserInfo:
        if (sat) { title = 'View ' + _.startCase(sat) }
        break;

      case itemActionTypes.add:
        icon = appIconTypes.add
        if (appFormType && appFormType !== gEnums.customAddTypes.none) {
          title = 'Add ' + _.startCase(appFormType)
        }
        break;

      case itemActionTypes.imageUpload:
        if (sat) { title = 'Link ' + _.startCase(sat) }
        break;

      case itemActionTypes.itemLinking:
        if (sat) { title = sat }
        icon = appIconTypes.dataLinking
        break;

      case itemActionTypes.createGlobalData:
        if (sat) { title = 'Create Global Data (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.createList:
        if (createListAs) { title = _.startCase(createListAs) }
        break;

      case itemActionTypes.dataLinking:
        title = 'Link ' + _.startCase(viewItem_key) + ' to this page (' + _.startCase(viewItem_key) + ')'
        icon = appIconTypes.dataLinking
        break;

      case itemActionTypes.imageMapping:
        if (sat) { title = 'Image Mapping (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.showItemCount:
        title = 'Item Count'
        labeled = vldCount
        break;

      case itemActionTypes.sorting:
        if (sat) { title = 'Sort (' + _.startCase(sat) + ')' }
        break;

      case itemActionTypes.subAction:
        if (sat) { title = _.startCase(sat) }
        break;

      case itemActionTypes.ticketing:
        title = 'Passes' // 'Ticketing'
        break;

      default:
      // nothing
    }

    return { title, icon, labeled, titleExtra, disabled }
  }

  /** returns a menuItem based on the pat and sat */
  const menuItem = (pat, sat, _arrayIndex, _forData, subItems, directClick) => {

    const { icon, title, titleExtra, disabled, labeled } = getTitles(pat, sat)

    const keyy = sat ? sat : pat

    const active = (activeItem === keyy) ? true : false
    let cn = 'piom'
    const cnn = active ? '' : 'dis-none'
    const caretIcon = active ? 'angle down' : 'angle right'

    if (_useIcons) {
      return <Label key={uniqueKey('uias', keyy, 'i')}     >
        {title}<Icon name={icon} color={'blue'} />
      </Label>
    } else {
      if (disabled) {
        return <Menu.Item key={uniqueKey('uias', keyy)} className={cn}>
          <Icon key={uniqueKey('uias', keyy, 'i')} name={'ban'} />
          {title + titleExtra}
        </Menu.Item>
      } else if (labeled) {
        return <Menu.Item key={uniqueKey('uias', keyy)} className={cn}>
          <Label key={uniqueKey('uias', keyy, 'l')} color={'blue'}>{labeled}</Label>
          {title + titleExtra}
        </Menu.Item>
      } else {
        return <Menu.Item key={uniqueKey('uias', keyy)} className={cn} >
          <Menu.Header onClick={() => directClick ? handleDirectClick(keyy) : handleOpenMenuItem(keyy)}>
            <div><Icon key={uniqueKey('uias', keyy, 'i')} name={icon} /></div>
            <div>{title}</div>
            <div><Icon name={caretIcon} color={'blue'}></Icon></div>
          </Menu.Header>
          <Menu.Menu className={cnn}>
            {menuItemContent(subItems, cnn)}
          </Menu.Menu>
        </Menu.Item>
      }
    }
  }

  const iconItem = (item, dmiKey, subKey) => {
    const { accessLevel } = item ?? {}
    const cc = subKey ? _.startCase(subKey) : _.startCase(dmiKey)
    return <Label key={subKey ? subKey : dmiKey} onClick={() => handleIconClick(item, dmiKey, subKey)} >
      {cc} <Icon name={'cog'} color={'blue'} />
    </Label>
  }

  const getMenuItems = () => {

    const { data, dataManagement, dataModification } = pageNavOptions ? pageNavOptions : {}

    const { mis, misi } = sidebarOptions.appUserDirect(pageNavOptions, viewItem_key)
    const { misu, misui } = sidebarOptions.appUserAction(appUserAccess, appUserActionItems, itemData, appUserCollection)
    const { midm } = sidebarOptions.dataManagement(dataModification)
    const { mido } = sidebarOptions.other(data, dataManagement)

    // combine mis and misu
    const _menuItemItems = [...misi, ...misui, ...midm, ...mido]
    const _menuItems = [...mis, ...misu]

    const iconLabels = []

    _.each(_menuItemItems, (mii) => {
      const { optionType, item, dmiKey, subKey, pat, sat, _arrayIndex, _forData, subItems, directClick } = mii
      switch (optionType) {
        case optionTypes.dataManagement:
          iconLabels.push(iconItem(item, dmiKey, subKey))
          break;
        case optionTypes.appUserItems:
          iconLabels.push(menuItem(pat, sat, _arrayIndex, _forData, subItems, directClick))
          break;

        case optionTypes.other:

        case optionTypes.userItems:

      }
    })

    return <div className='menu-nav'>{iconLabels}</div>

    // if (_useIcons) {
    //   return <div className='menu-nav'>{iconLabels}</div>
    // } else {
    //   // add a menuItem for the dataActions
    //   if (data && Object.keys(data).length > 0) {
    //     _menuItems.push(menuItem(itemActionTypes.dataActions, null, null, true))
    //   }

    //   if (dataManagement && Object.keys(dataManagement).length > 0) {
    //     _menuItems.push(menuItem(itemActionTypes.dataManagement, null, null, true))
    //   }

    //   const _count = _.size(dataModification)
    //   let _allowSingleItem

    //   // add a menuItem for the dataModifications
    //   if (dataModification && _count > 0) {
    //     if (_count === 1 && _allowSingleItemPopup) {
    //       _allowSingleItem = true
    //     }
    //     const dms = <UiDataModifications
    //       dataModificationDisplayType={dataModificationDisplayTypes.menuHeader}
    //       singleItemOnly={_allowSingleItem}
    //       handleCancel={handleCancel}
    //       useIcons={_useIcons}
    //       iconLabels={iconLabels}
    //     />
    //     _menuItems.push(dms)
    //   }
    // }

    // return <PageItemOptionsContext.Provider value={{ pageItemOptions_fns: pageItemOptions_fns() }}>
    //   <Menu className='menu-slim pios' vertical borderless fluid>
    //     {_menuItems}
    //   </Menu>
    // </PageItemOptionsContext.Provider>

  }

  return menuItems ? menuItems : null



}
