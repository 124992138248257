import { _googleSheetsCollectionDataName } from '../cnr/contexts/GoogleDataContext';
import { addDataItemsToCollectionDatabase } from '../firestoreData/updates/subUpdates/addDataItemsToCollection';
import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { fsfn_deleteEventCollection, fsfn_deleteEventCollections } from "./fbDelete";
import { createRefPath_event } from '../firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../firestoreData/appData/fsData';

export const fsfn_updateStaticViews = async (pathViews, viewItem, viewListData, staticName, fixedStaticView, appUserViewTypeProp, dispatch) => {
  const staticProps = ['email', 'startDate', 'endDate', 'startTime', 'endTime', 'parents', 'gender']
  staticProps.push(appUserViewTypeProp)
  try {
    const _refPath = createRefPath_event(pathViews, ['staticViews', staticName])
    fs_set_doc(_refPath, viewListData, false, dispatch)
  } catch (error) {
    dispatch && dispatch({ type: dispatchProps.error, error })
  }
}

export const fsfn_convertToParentKeys = async (aps_viewItems, paps_state) => {
  const data = {}
  try {
    console.log('data', data)
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} pathViews The database path for the event
 * @param {array} dataCollections List of collection items to be updated
 * @param {function} handleUpdating The callback function to notify the UI
 * @param {function} dispatch The callback function when complete
 * @description Loops through the `dataCollections` and deletes them using `fsfn_deleteEventCollection`
 */
export const fsfn_replaceEventCollections = (pathViews, dataCollections, dispatch) => {
  const _addCollections = (items) => addEventCollections(items, dataCollections, pathViews, dispatch)
  fsfn_deleteEventCollection(pathViews, 'staticViews', 100).then(res => {
    fsfn_deleteEventCollection(pathViews, _googleSheetsCollectionDataName, 100).then(res => {
      const dataCollectionKeys = Object.keys(dataCollections)
      fsfn_deleteEventCollections(pathViews, dataCollectionKeys, _addCollections)
    })
  })

}

const addEventCollections = async (items, dataCollections, pathViews, dispatch) => {
  if (items) {
    const _items = {}
    const dataCollectionKeys = Object.keys(items)
    Object.keys(items).forEach(addKey => {
      const item = items[addKey]
      const { success } = item
      if (success) {
        const dataItems = dataCollections[addKey]
        const response_data = addEventCollection(pathViews, addKey, dataItems)
        const { success, error } = response_data ?? {}
        _items[addKey] = { icon: 'check', color: success ? 'green' : 'red', success: success, error }
        if (Object.keys(_items).length === dataCollectionKeys.length) {
          dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, _items })
        }
      }
    })
  }
}

const addEventCollection = async (pathViews, addKey, dataItems) => {
  const response_data = await addDataItemsToCollectionDatabase(pathViews, addKey, dataItems)
  return response_data
}