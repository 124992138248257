import _ from "lodash";
import { createStaticViewFromDataCollections } from "../../common/convert";
import { createRefPath_event } from "../../firestoreData/appData/appRefPaths";
import { doc_set } from "../../firestoreData/appData/fsData";
import { fsUpdate_updateExistingCollection } from "../../firestoreData/updates/subUpdates/fsUpdate_updateExistingCollection";
import { _googleSheetsCollectionDataName } from "../contexts/GoogleDataContext";
import { createResponseTypes } from "./CreateReducer";
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";
import { dataFix } from "../../common/dataFix";

const rts = {
  handleGoogleDataOn: 'handleGoogleDataOn',
  handleGoogleSheetsDataResponse: 'handleGoogleSheetsDataResponse',
  handleReplaceSheetData: 'handleReplaceSheetData',
  handleUpdateSheetData: 'handleUpdateSheetData',
  handleUpdateSheetsData: 'handleUpdateSheetsData',
  createTeamSheets: 'createTeamSheets',
  ...grts,
  ...createResponseTypes
}

export const googleDataInitialState = (initState) => {
  return { ...initState, googleUpdateStatus: {} }
};

export const googleDataReducer = (state, action) => {

  const { type, dispatch } = action

  const { pathViews, googleSheetsImportOptions, aps_viewItems } = state ?? {}

  let _googleSheetsImportOptions = action.googleSheetsImportOptions ? action.googleSheetsImportOptions : googleSheetsImportOptions
  if (!_googleSheetsImportOptions) { _googleSheetsImportOptions = {} }

  switch (type) {
    case rts.handleGoogleDataOn:
      return {
        ...state,
        googleDataOn: !state.googleDataOn,
      }

    case rts.handleGoogleSheetsDataResponse:
      const gsd = ammendGoogleSheetsDataWithIds(action.data)
      return {
        ...state,
        googleSheetsData: gsd,
        staticViews: createStaticViewFromDataCollections(gsd, aps_viewItems)
      }

    case rts.handleReplaceSheetData:
      const ueuProps = { pathViews, collectionName: action.dataCollectionKey, dispatch, updateOnly: action.updateOnly, restrict: false }
      ueuProps.dataList = action.dataCollection
      ueuProps.clearCollection = true
      fsUpdate_updateExistingCollection(ueuProps)
      return { ...state }

    // update google sheets data only
    case rts.handleUpdateSheetData:
      if (action.dataCollection && action.dataCollectionKey) {
        updateGoogleSheetData(pathViews, action).then(res => {
          dispatch({ type: rts.updateSuccess, dispatch })
        }).catch(error => {
          dispatch({ type: rts.updateError, error, dispatch })
        })
      }
      return { ...state, updating: true, showConfirmationQuestion: false }

    case rts.handleUpdateSheetsData:
      if (action.dataCollections) {
        updateGoogleSheetsData(pathViews, action.dataCollections).then(res => {
          dispatch({ type: rts.updateSuccess, dispatch })
        }).catch(error => {
          dispatch({ type: rts.updateError, error, dispatch })
        })
      }
      return { ...state, updating: true, showConfirmationQuestion: false }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmationClick, questionProps: null, updatingCaption: 'Creating' })

    default:
      return state;
  }
}

export const googleDataHandlers = (dispatch) => {
  return {
    handleGoogleDataOn: (data) => { dispatch({ type: rts.handleGoogleDataOn, dispatch, data }) },
    handleGoogleSheetsDataResponse: (data) => { dispatch({ type: rts.handleGoogleSheetsDataResponse, dispatch, data }) },
    handleReplaceSheetData: (dataCollection, dataCollectionKey, updateOnly) => { dispatch({ type: rts.handleReplaceSheetData, dispatch, dataCollection, dataCollectionKey, updateOnly }) },
    handleUpdateSheetData: (dataCollection, dataCollectionKey) => { dispatch({ type: rts.handleUpdateSheetData, dispatch, dataCollection, dataCollectionKey }) },
    handleUpdateSheetsData: (dataCollections) => { dispatch({ type: rts.handleUpdateSheetsData, dispatch, dataCollections }) },
    ...responseHandlers(dispatch)
  }
}

/**
 * Updates the `_googleSheetsData/key` with data from `dataCollections`
 * @param {object} pathViews 
 * @param {object} dataCollections 
 * @returns 
 */
const updateGoogleSheetsData = (pathViews, dataCollections) => {

  const promises = []

  Object.keys(dataCollections).forEach(key => {
    const dc = dataCollections[key]
    dataFix.cleanUpData(dc)
    const _setRef = createRefPath_event(pathViews, [_googleSheetsCollectionDataName, key])
    const r = doc_set(_setRef, dc)
    promises.push(r)
  })

  return Promise.all(promises)
}

const updateGoogleSheetData = (pathViews, action) => {

  const { dataCollection, dataCollectionKey } = action
  const dc = dataCollection
  dataFix.cleanUpData(dc)
  const promises = []
  const _setRef = createRefPath_event(pathViews, [_googleSheetsCollectionDataName, dataCollectionKey])
  const r = doc_set(_setRef, dc)
  promises.push(r)

  return Promise.all(promises)
}

/**
 * adds the `_itemKey` and `id` to each of the `dataItems` in the `googleSheet`
 * @param {object} data 
 * @returns 
 */
const ammendGoogleSheetsDataWithIds = (data) => {
  if (data) {
    Object.keys(data).forEach(key => {
      const dataCollection = data[key]
      delete dataCollection.id
      delete dataCollection._itemKey
      Object.keys(dataCollection).forEach(dcKey => {
        const _dataItem = dataCollection[dcKey]
        if (_dataItem && _.isObject(_dataItem)) {
          _dataItem._itemKey = dcKey
          _dataItem.id = dcKey
        }
      })
    })
  }
  return data
}
