import React, { useContext, useState } from 'react';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { refreshPageWithCacheClear } from '../common/actions';
import { appIconTypes } from '../enums/appIconTypes';
import { gEnums } from '../enums/globalEnums';
import GenericIcon from '../genericControls/GenericIcon';
import { _animateTypes } from '../motions/AnimateComponent';
import { ItemActionContext } from '../pageItem/modification/UiItemAction';
import { H2Topper, H3Topper } from './TopperWrappers';
import Wrapper from './Wrapper';
import { useNavigate } from 'react-router-dom';
import { cnnAdd } from '../common/cns';
import { PageItemOptionsContext } from '../sidebars/PageItemOptionsMenu';

/**
 * 
 * @param {object} props (
 *  content,
    halfModal,
    handleCancel,
    isBack,
    isSub,
    noModal,
    topperCaption,
    topperCaption2,
    topperHeader)
 * @returns 
 */
const FullPageWrapper = (props) => {

  const {
    allowReload,
    allowRefresh,
    css,
    content,
    fullMode,
    fullWebPage,
    halfModal,
    handleCancel,
    cancelOptions,
    handleRefresh,
    ignoreStyle,
    isBack,
    isSub,
    noModal,
    topperCaption,
    topperCaption2,
    topperHeader,
    updating,
    wrapperCss,
  } = props

  const navigate = useNavigate();

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, transition_state } = states ?? {}
  const { pageSettings, pageNav } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}
  const { navUiItemContext } = pageNav ?? {}
  const { dataOptions } = aps_global ?? {}
  const { useModifyRoutes } = dataOptions ?? {}

  // itemActionContext
  const itemActionContext = useContext(ItemActionContext);
  const { uiItemContext: uiItemContext_alt } = itemActionContext ?? {}

  // pageItemOptionsContext
  const pageItemOptionsContext = useContext(PageItemOptionsContext)
  const { pageItemOptions_fns } = pageItemOptionsContext ?? {}
  const { handleCancel: handleCancel_pio } = pageItemOptions_fns ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { frameworkRightType, frameworkLeftType, isMobileDevice } = framework_state ?? {}
  const { handleKill_pageNav } = framework_handlers ?? {}

  const { transitions } = transition_state ?? {}
  const transition_fpw = transitions ? transitions[_animateTypes.fullPageWrapper] : null

  let topSac = aps_styles ? aps_styles[gEnums.projectStyles.topMenu] : {}
  if (ignoreStyle) { topSac = {} }

  const { projectOptions } = aps_global ?? {}
  const { useCloseModalSwipe } = projectOptions ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);

  // LOOK
  let _uiItemContext = navUiItemContext ? navUiItemContext : uiItemContext
  _uiItemContext = uiItemContext_alt ? uiItemContext_alt : _uiItemContext

  const { item_handlers, item_state } = _uiItemContext ?? {}
  const { editableItem } = item_state ?? {}
  const { handleClose_openedItem, handleShow_itemEdit } = item_handlers ?? {}

  const [wideMode, setWideMode] = useState(fullMode)

  const handleWide = () => setWideMode(!wideMode)
  const handleReload = () => {
    if (allowRefresh) {
      window.location.reload()
    } else if (allowReload) {
      refreshPageWithCacheClear()
    }
  }

  const wideModeAvailable = fullMode && !isMobileDevice

  let _handleCancel = handleCancel
  if (!_handleCancel && handleClose_openedItem) { _handleCancel = handleClose_openedItem }
  if (handleCancel_pio) { _handleCancel = handleCancel_pio }

  if (editableItem) { _handleCancel = handleShow_itemEdit }

  const handle_cancel = () => {
    if (isBack && useModifyRoutes) {
      navigate(-1)
    } else {
      _handleCancel()

      handleKill_pageNav && handleKill_pageNav()
    }
  }

  const buttonProps_cancel = { color: 'black', inverted: true }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const infoButton = () => gi('btn-cancel', appIconTypes.check, null, null, { ...buttonProps_cancel, nb: false, nf: true })
  const cancelButton = () => gi('btn-cancel', isBack ? appIconTypes.arrowLeft : appIconTypes.delete, handle_cancel, cancelOptions, { ...buttonProps_cancel, nb: false, nf: true })
  const refreshButton = () => gi('btn-refresh', appIconTypes.refresh, handleRefresh, null, { ...buttonProps_cancel, nb: true, nf: true })
  const wideModeButton = () => gi('btn-cancel', wideMode ? 'square outline' : appIconTypes.wideMode, handleWide, null, { ...buttonProps_cancel, nb: false, nf: true })
  const reloadButton = () => gi('btn-cancel', allowRefresh ? 'redo' : 'sync', handleReload, null, { ...buttonProps_cancel, nb: false, nf: true })

  const toppers = () => <React.Fragment>
    <div>{topperCaption}</div>
    <div style={{ paddingTop: '.5em', wordBreak: 'break-word' }} >{topperCaption2}</div>
  </React.Fragment>

  const topper1 = () => <H2Topper
    leftContent={infoButton()}
    rightContent={topperCaption2 ? toppers() : topperCaption}
  // style={topSac.style}
  ></H2Topper>

  const topper2 = () => <H2Topper
    leftContent={_handleCancel && cancelButton()}
    rightContent={topperCaption2 ? toppers() : topperCaption}
  // style={topSac.style}
  ></H2Topper>

  const topper3 = () => <H3Topper
    leftContent={_handleCancel && cancelButton()}
    middleContent={topperCaption2 ? toppers() : topperCaption}
    rightContent={handleRefresh && refreshButton()}
  // style={topSac.style}
  />

  const topper3_reload = () => <H3Topper
    leftContent={_handleCancel && cancelButton()}
    middleContent={topperCaption2 ? toppers() : topperCaption}
    rightContent={reloadButton()}
  // style={topSac.style}
  />

  const topper3Wide = () => <H3Topper
    leftContent={_handleCancel && cancelButton()}
    middleContent={topperCaption2 ? toppers() : topperCaption}
    rightContent={wideModeButton()}
  // style={topSac.style}
  />

  const topper = () => {
    if (fullWebPage) {
      return topper1()
    } else if (handleRefresh) {
      return topper3()
    } else if (wideModeAvailable) {
      return topper3Wide()
    } else if (allowReload || allowRefresh) {
      return topper3_reload()
    } else {
      return topper2()
    }
  }

  let cn_fpw = 'fpw'
  if (isSub) { cn_fpw += ' sub' }
  if (css) {
    const { container: css_container } = css ?? {}
    cn_fpw = cnnAdd(cn_fpw, css_container)
    if (css.header) {
      topSac.className = css.header
    }
  }

  const wrapper = () => <Wrapper
    topper={topper()}
    handleSwipedRight={useCloseModalSwipe ? _handleCancel : null}
    header={topperHeader}
    content={content}
    css={{ container: cn_fpw, topper: topSac.style }}
    modal={noModal ? false : true}
    halfModal={halfModal}
    fullMode={wideMode}
    fullWebPage={fullWebPage}
    transition_fpw={transition_fpw}
    wrapperCss={wrapperCss}
    updating={updating}
  />

  return (frameworkRightType || frameworkLeftType) ? content : wrapper()

}

export default FullPageWrapper 