import _ from 'lodash';
import { copyObj } from '../../common_web/copy';
import { fsfn_fetchTableData } from '../../functions/fbFetchFunctions';
import { fixLastName } from '../../common/convert';

export const getStatePaSchoolData = async (schools, callback) => {

  const _schools = copyObj(schools)

  _schools.forEach(async school => {
    const { schoolKey } = school
    let _url = 'http://www.piaa.org/schools/directory/details.aspx?ID=' + schoolKey

    if (_url) {
      const urlDataF = { url: _url }

      return await fsfn_fetchTableData(urlDataF).then(response_data => {
        const { result } = response_data ?? {}
        const parser = new DOMParser()
        const doc = parser.parseFromString(result, 'text/html')
        if (doc) {
          getDocElement(doc, school, 'PIAADistrict', 'districtt')
          getDocElement(doc, school, 'phones', 'phoneNumber', 'span', 1)
          getDocElement(doc, school, 'urls', 'webSite', 'span', 0, 'a')
          getDocElement(doc, school, 'EnrollmentTotal', 'enrollmentTotal')
          getDocElement(doc, school, 'EnrollmentMale', 'enrollmentMale')
          getDocElement(doc, school, 'EnrollmentFemale', 'enrollmentFemale')
          getDocElement(doc, school, 'EnrollmentFemale', 'enrollmentFemale')
          getDocElement(doc, school, 'medium-4.columns', 'athleticDirector', null, null, null, true)
          getSportLevels(doc, school)

        }
      }).catch(error => {
        console.log('error', error)
        return {}
      })
    } else {
      return {}
    }
  })

  console.log('_schools', _schools)
  callback(_schools)
}

const getDocElement = (doc, school, id, prop, subElement, subCount, subElement2, byClassName) => {
  if (byClassName) {
    const docItem = doc.querySelectorAll('.' + id)
    if (docItem && docItem.length > 0) {
      docItem.forEach(di => {
        const title = getValue(di, 'title')
        const firstName = getValue(di, 'given-name')
        const lastName = getValue(di, 'family-name')
        if (title) {
          if (!school.contacts) { school.contacts = {} }
          school.contacts[_.camelCase(title)] = {
            firstName: _.startCase(_.camelCase(firstName)),
            lastName: fixLastName(lastName),
            email: getValue(di, 'email'),
          }
        }
      })
    }
  } else {
    const docItem = doc.querySelectorAll('#' + id)
    if (docItem && docItem.length === 1) {
      if (subElement) {
        const subElem = docItem[0].querySelectorAll(subElement)
        if (subElement2) {
          const subElem2 = subElem[subCount].querySelectorAll(subElement2)
          if (subElem2.length && subElem2[0] && subElem2[0].innerText) {
            school[prop] = subElem2[0].innerText
          }
        } else {
          if (subElem.length && subElem[subCount] && subElem[subCount].innerText) {
            school[prop] = subElem[subCount].innerText
          }
        }
      } else {
        if (docItem[0].innerText) {
          school[prop] = docItem[0].innerText
        }
      }
    }
  }
}


const getValue = (di, selector) => {
  try {
    return di.querySelectorAll('.' + selector)[0].innerText.trim()
  } catch (error) {
    return '?'
  }
}

const getSportLevels = (doc, school) => {

  const table = doc.querySelectorAll('table')
  const tableBody = table && table[0] && table[0].querySelectorAll('tbody')
  if (tableBody) {
    tableBody.forEach(tb => {
      const trs = tb.querySelectorAll('tr')
      if (trs) {
        trs.forEach(tr => {
          const tds = tr.querySelectorAll('td')
          if (tds && tds.length === 3) {
            if (!school.sportLevels) { school.sportLevels = { 2024: {} } }

            let _sportName = _.camelCase(tds[0].innerText)
            let _level = tds[1].innerText.trim()
            let _coop = tds[2].innerText

            _sportName = _sportName.replace('girls', 'womens')
            _sportName = _sportName.replace('boys', 'mens')

            _level = _level.length + 'A'

            switch (_sportName) {
              case 'baseball':
                _sportName = 'mensBaseball'
                break;
              case 'fieldHockey':
                _sportName = 'womensFieldHockey'
                break;
              case 'football':
                _sportName = 'mensFootball'
                break;
              case 'softball':
                _sportName = 'womensSoftball'
                break;
              case 'wrestling':
                _sportName = 'mensWrestling'
                break;
            }
            school.sportLevels[2024][_sportName] = { level: _level }
            if (_coop) { school.sportLevels[2024][_sportName].coop = true }
          }
        })
      }
    })
  }
}