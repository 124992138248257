import { serverTimestamp } from "firebase/firestore";
import { dispatchProps } from '../../../cnr/reducers/reducerHelpers/dispatchProps';
import { dataFix } from "../../../common/dataFix";
import { gEnums } from "../../../enums/globalEnums";
import { fsfn_auth } from "../../../functions/fbAuth";
import { createRefPath, createRefPath_client, createRefPath_event } from '../../appData/appRefPaths';
import { fs_get_data } from "../../appData/fsAppData";
import { fs_set_doc, fs_update_doc } from '../../appData/fsData';
import { _profileCollectionName } from "../../profiles/getProfile";
import { updateSettings } from "../../settings/updateSettings";
import { updateDocumentHelpers } from "./fsUpdate_document";
import { fsUpdate_updateRelatedCollections } from "./fsUpdate_updateRelatedCollections";

const _localRestriction = false
const _updateRelations = true

export const fsUpdate_updateDoc = (props) => {

  const {
    appUser,
    baseRef,
    dispatch,
    opts,
    pathViews,
    ufProps,
  } = props

  const { refPath, refPaths, directCollection } = baseRef

  let { vit, itemData, swipedItem, viewKey, appDataSource, dataRestrictions, viDeps, storagePaths, autoUpdateCollectionRelationships, staticViewKeys, useTimestamps } = ufProps ?? {}

  const { appDataSourceType, useAppDataDocuments, updateAppDataDocuments } = appDataSource ?? {}

  let _refPath = [...refPaths]

  if (!viewKey && itemData && itemData.id) { viewKey = itemData.id }
  if (!viewKey && itemData && itemData._itemKey) { viewKey = itemData._itemKey }
  if (!viewKey && swipedItem && swipedItem.key) { viewKey = swipedItem.key }

  const callback_test = (results) => {
    if (opts && opts.settingsProps) {
      updateSettings.createNewViewAndViewItemsSettingsDB(opts.settingsProps, pathViews).then(
        dispatch && dispatch({ type: dispatchProps.success, dispatch })
      )
    }
    dispatch && dispatch({ ...results, dispatch })
  }

  if (viewKey) {

    const coll = directCollection ? directCollection : vit

    let dataToUpdate;
    let mergeData = false
    let _editRef = refPath

    const { documentDependencies } = viDeps
    if (documentDependencies && documentDependencies.length > 0 && Object.keys(pathViews).length > 0) {
      // loop the depenedents 
      documentDependencies.forEach(viewItemDep => {
        const docDepKey = pathViews[viewItemDep]
        if (docDepKey) {
          _editRef = createRefPath([viewItemDep, docDepKey], _editRef)
          dataToUpdate = {
            [coll]: {
              [itemData.id]: { ...itemData }
            }
          }
          mergeData = true
        }
      })
    } else {
      _editRef = createRefPath([coll, viewKey], _editRef)
      dataToUpdate = itemData
    }

    if (_localRestriction || (dataRestrictions && (dataRestrictions.all || dataRestrictions.editData))) {
      // const sheetName = _.startCase('Health And Safety')
      // fsfn_updateSheetRangeValues('17mlSzJXO-EqfjdDdFyzkclmilhjnufDLw_q7qmFNkX4', sheetName, 0, { name: 'Test' })
      callback_test({ type: dispatchProps.successAlt, dispatch })
      return false
    }

    getItemData(pathViews, vit, viewKey, dataToUpdate, autoUpdateCollectionRelationships).then(existingItem => {

      dataToUpdate = dataFix.createDeleteFields(dataToUpdate)

      if (useTimestamps) {
        dataToUpdate._ts = {
          _updateDate: serverTimestamp(),
          _uid: appUser.uid,
          _user: appUser.displayName
        }
      }
      // dataToUpdate._updateRelationships = true

      const callback_edit = (results) => {
        if (opts && opts.settingsProps) {
          updateSettings.createNewViewAndViewItemsSettingsDB(opts.settingsProps, pathViews).then(
            dispatch && dispatch({ type: dispatchProps.success, dispatch })
          )
        }
        const { dataToUpdate } = results ?? {}
        autoUpdateCollectionRelationships && fsUpdate_updateRelatedCollections(pathViews, vit, existingItem, dataToUpdate, viewKey, staticViewKeys)
        dispatch && dispatch({ ...results, dispatch })
      }

      switch (vit) {
        case 'videoConference':
          _refPath = createRefPath_client(pathViews, [_profileCollectionName, viewKey])
          delete itemData.id
          fs_update_doc(_refPath, itemData, callback_edit)
          break;

        default:
          if (mergeData) {
            fs_set_doc(_editRef, dataToUpdate, true).then(res => {
              const editPath = coll + '/' + viewKey
              switch (vit) {
                case 'profiles':
                  fsfn_auth.addAuthCustomToken(dataToUpdate, dispatchProps.success)
                  break;
                default:
                  callback_edit({ type: dispatchProps.success, updatePath: editPath })
              }
            }).catch(err => {
              dispatch && dispatch({ type: dispatchProps.error, err })
            });
          } else {
            try {
              fs_update_doc(_editRef, dataToUpdate, callback_edit, false, appUser, storagePaths, vit)
            } catch (error) {
              dispatch && dispatch({ type: dispatchProps.error, error })
            }
          }
      }
    })

    let _updateAppDataDocuments = updateAppDataDocuments || useAppDataDocuments

    switch (appDataSourceType) {
      case gEnums.dataSourceTypes.firebase:
      case gEnums.dataSourceTypes.firebaseDocument:
        _updateAppDataDocuments = true
        break;
      default:
      // nothing
    }

    if (_updateAppDataDocuments) {
      updateDocumentHelpers.update_itemInDocuments(pathViews, coll, viewKey, itemData)
    }

  } else {
    alert('Item not updated. No viewItemKey')
  }
}

const getItemData = async (pathViews, vit, itemKey, dataToUpdate, autoUpdateCollectionRelationships) => {
  if (_updateRelations && autoUpdateCollectionRelationships) {
    const _refPath_existing = createRefPath_event(pathViews, [vit, itemKey])
    const existingItem = await fs_get_data({ refPath: _refPath_existing, opts: { returnFirstObject: true } })
    return existingItem
  } else {
    return dataToUpdate
  }
}