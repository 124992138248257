import { dataFix } from "../../../global/common/dataFix"
import { callFunction } from "../../../global/functions/fbAll"

const golfGenericTypes = {
  golfGeneric: 'golfGeneric',
}

const golfFunctionTypes = {
  createGolfMatches: 'createGolfMatches',
  createGolfRoundMatches: 'createGolfRoundMatches',
  createGolfRoundMatchesPreview: 'createGolfRoundMatchesPreview',
  createGolfTournament: 'createGolfTournament',
  createGolfTournamentRounds: 'createGolfTournamentRounds',
  settingsGolf: 'settingsGolf',
  updateGolfRoundMatches: 'updateGolfRoundMatches',
}

const callFsFunction = async (callData) => {
  const data = await callFunction(golfGenericTypes.golfGeneric, callData)
  return data
}

export const fsfn_createGolfMatches = async (eventTeams, roundLineups, totalMatches) => {
  const callData = { golfFunctionType: golfFunctionTypes.createGolfMatches, eventTeams, roundLineups, totalMatches }
  try {
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

// paps_state, golfRoundKey, matches, handleDone
export const fsfn_createGolfRoundMatches = async (paps_state, golfRoundKey, matches, callback) => {
  const callData = { golfFunctionType: golfFunctionTypes.createGolfRoundMatches, paps_state, golfRoundKey, matches }
  try {
    const response_data = await callFsFunction(callData)
    callback(matches)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_createGolfTournament = async (pathViews, ufPropsAdd, tournamentDetails, teams_event, previewOnly) => {
  const callData = { golfFunctionType: golfFunctionTypes.createGolfTournament, pathViews, ufPropsAdd, tournamentDetails, teams_event, previewOnly, showLogs: true }
  try {
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_createGolfRoundMatchesPreview = async (tournamentDetails, golfRoundKey) => {
  const d = dataFix.removeEmpties(tournamentDetails)
  const callData = { golfFunctionType: golfFunctionTypes.createGolfRoundMatchesPreview, tournamentDetails: d, golfRoundKey, showLogs: true }
  try {
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}


export const fsfn_createGolfTournamentRounds = async (paps_state) => {
  const callData = { golfFunctionType: golfFunctionTypes.createGolfTournamentRounds, paps_state }
  try {
    const response_data = await callFsFunction(callData)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_settingsGolf = async (vvProps) => {
  const callData = { golfFunctionType: golfFunctionTypes.settingsGolf, vvProps }
  try {
    const response_data = await callFsFunction(callData)
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

export const fsfn_updateGolfRoundMatches = async (paps_state, matches, callback) => {
  const callData = { golfFunctionType: golfFunctionTypes.updateGolfRoundMatches, paps_state, matches }
  try {
    const response_data = await callFsFunction(callData)
    const { matches } = response_data ?? {}
    callback(matches)
    return response_data
  } catch (error) {
    console.error(error)
  }
}