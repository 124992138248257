import _ from 'lodash'
import { golfEnums } from '../enums/golfEnums'
import { getGolfMatchInfo } from '../cnr/reducers/reducerHelpers/golfMatchHelpers'
// import { createMatchScorecards } from './createMatchScorecards'

export const _matchPlayPercs = {
  alterateShot: {
    low: .60,
    high: .40
  },
  bestBall: .90,
  singles: 1.00
}

export const calcMatchStatus = (golfMatch, holes, teams, holeNumber) => {

  const { holeWinners, holeScores, matchNumber } = golfMatch

  const _holeWinners = holeNumber ? _.pickBy(holeWinners, i => i.order < holeNumber) : holeWinners

  const xx = getGolfMatchInfo(golfMatch, holes)
  const { allGolfersStroking } = xx

  const { team1, team2 } = teams ? teams : {}
  const { name: team1Name } = team1 ? team1 : {}
  const { name: team2Name } = team2 ? team2 : {}

  const holesScoredCount = _holeWinners ? getLastHolePlayed(_holeWinners) : 0

  const matchHcps = getMatchLowHcp(golfMatch)

  const holeRemainings = 18 - holesScoredCount

  const matchStatus = {
    dormie: false,
    finalScore: null,
    finished: false,
    tied: false,
    holeRemainings,
    holesScored: holesScoredCount,
    lastHolePlayed: holesScoredCount,
    matchHcps,
    leadingBy: 0,
    leadingTeam: 0,
    matchNumber,
    status: '--',
    winner: null,
    team1: {
      holesWon: 0,
      holesHalved: 0,
      leadingBy: 0,
      leading: true,
      won: false,
      status: '--'
    },
    team2: {
      holesWon: 0,
      holesHalved: 0,
      leadingBy: 0,
      leading: true,
      won: false,
      status: '--'
    },
  }

  const golferScores = {}

  if (holes && holeScores && !holeNumber) {

    Object.keys(golfMatch.team1).forEach((key) => {
      golferScores[key] = { total: 0, toPar: 0, totalNet: 0, toParNet: 0, hcp: golfMatch.team1[key].handicap }
    })

    Object.keys(golfMatch.team2).forEach((key) => {
      golferScores[key] = { total: 0, toPar: 0, totalNet: 0, toParNet: 0, hcp: golfMatch.team2[key].handicap }
    })

    // loop the holes
    Object.keys(holes).forEach(hk => {
      const h = holes[hk]
      const { par, holeNumber, handicap } = h
      // see if the score exists
      if (holeScores[holeNumber]) {
        Object.keys(golferScores).forEach(gskey => {
          if (holeScores[holeNumber][gskey]) {
            const score = holeScores[holeNumber][gskey]
            let netScore = score
            if (golferScores[gskey].hcp <= handicap) (netScore--)
            golferScores[gskey].total = golferScores[gskey].total + score
            golferScores[gskey].toPar = golferScores[gskey].toPar + (score - par)
            golferScores[gskey].totalNet = golferScores[gskey].totalNet + netScore
            golferScores[gskey].toParNet = golferScores[gskey].toParNet + (netScore - par)
          }
        })
      }
    })
  }

  if (_holeWinners) {

    const hs1 = _.filter(_holeWinners, { winningTeam: 1 })
    const hs2 = _.filter(_holeWinners, { winningTeam: 2 })

    const hsw1 = _.pickBy(_holeWinners, { winningTeam: 1 })
    const hsw2 = _.pickBy(_holeWinners, { winningTeam: 2 })

    matchStatus.team1.holesWon = hs1.length
    matchStatus.team2.holesWon = hs2.length

    matchStatus.team1.holeWinners = Object.keys(hsw1)
    matchStatus.team2.holeWinners = Object.keys(hsw2)

    if (matchStatus.team1.holesWon > matchStatus.team2.holesWon) {
      matchStatus.team1.leadingBy = matchStatus.team1.holesWon - matchStatus.team2.holesWon
      matchStatus.team1.status = (matchStatus.team1.leadingBy) + ' Up'
      matchStatus.team1.leading = true
      matchStatus.team1.name = team1Name
      matchStatus.leadingTeam = 1
      matchStatus.leadingBy = matchStatus.team1.leadingBy
      matchStatus.statusLine = 'Match # ' + matchNumber + ' (' + xx.matchLine + '): \n' + team1Name + ' now leads ' + matchStatus.team1.status + ' thru ' + holesScoredCount + '.'
    } else if (matchStatus.team2.holesWon > matchStatus.team1.holesWon) {
      matchStatus.team2.leadingBy = matchStatus.team2.holesWon - matchStatus.team1.holesWon
      matchStatus.team2.status = (matchStatus.team2.leadingBy) + ' Up'
      matchStatus.team2.leading = true
      matchStatus.team2.name = team2Name
      matchStatus.leadingTeam = 2
      matchStatus.leadingBy = matchStatus.team2.leadingBy
      matchStatus.statusLine = 'Match # ' + matchNumber + ' (' + xx.matchLine + '): \n' + team2Name + ' now leads ' + matchStatus.team2.status + ' thru ' + holesScoredCount + '.'
    } else {
      matchStatus.status = 'Tied'
      matchStatus.team1.status = 'Tied'
      matchStatus.team2.status = 'Tied'
      matchStatus.statusLine = 'Match # ' + matchNumber + ' (' + xx.matchLine + '): \n Match is Tied.'
    }

    if (!holeNumber) {
      matchStatus.holeStrokesStatus = getHolesWon(holes, _holeWinners, allGolfersStroking)
    }
  }

  if (matchStatus.leadingBy > holeRemainings) {
    matchStatus.finished = true
    matchStatus.winner = matchStatus.leadingTeam
    if (holeRemainings === 0) {
      matchStatus.finalScore = matchStatus.leadingBy + ' Up'
    } else {
      matchStatus.finalScore = matchStatus.leadingBy + ' & ' + holeRemainings
    }
  } else if (matchStatus.leadingBy === holeRemainings) {
    if (holeRemainings === 0) {
      matchStatus.finished = true
      matchStatus.finalScore = 'Tied'
    } else {
      matchStatus.dormie = true
      if (matchStatus.leadingTeam === 1) {
        matchStatus.dormieTeam = 1
      } else if (matchStatus.leadingTeam === 2) {
        matchStatus.dormieTeam = 2
      }
    }
  } else if (matchStatus.leadingBy === (holeRemainings - 1)) {
    matchStatus.potentialClosingHole = true
  }

  matchStatus.golferScores = golferScores
  matchStatus.holeScores = holeScores
  matchStatus.holeWinners = _holeWinners

  return matchStatus

}

const getMatchLowHcp = (golfMatch) => {

  const team1Hcps = getTeamHcp(golfMatch.team1)
  const team2Hcps = getTeamHcp(golfMatch.team2)

  if (team1Hcps.sum < team2Hcps.sum) {
    team2Hcps.actual = Math.ceil((team2Hcps.sum - team1Hcps.sum) * .40)
  } else if (team2Hcps.sum < team1Hcps.sum) {
    team1Hcps.actual = Math.ceil((team1Hcps.sum - team2Hcps.sum) * .40)
  } else {
    team1Hcps.actual = 0
    team2Hcps.actual = 0
  }

  if (team1Hcps.altShot < team2Hcps.altShot) {
    team2Hcps.actual_alt = Math.ceil((team2Hcps.altShot - team1Hcps.altShot))
  } else if (team2Hcps.altShot < team1Hcps.altShot) {
    team1Hcps.actual_alt = Math.ceil((team1Hcps.altShot - team2Hcps.altShot))
  } else {
    team1Hcps.actual_alt = 0
    team2Hcps.actual_alt = 0
  }

  const hcpLowG = getLowCourseHandicaps(golfMatch)
  // hcpLowG = getLowCourseHandicap(golfMatch.team1, hcpLowG)
  // hcpLowG = getLowCourseHandicap(golfMatch.team2, hcpLowG)

  return ({ team1Handicaps: team1Hcps, team2Handicaps: team2Hcps, lowHandicapG: hcpLowG })

}

const getLowCourseHandicaps = (golfMatch) => {

  let hcpLowG = {
    lowHandicap: 100,
    lowGolfer: null
  }

  const { team1, team2 } = golfMatch
  hcpLowG = getLowCourseHandicap(team1, hcpLowG)
  hcpLowG = getLowCourseHandicap(team2, hcpLowG)

  return hcpLowG
}

function findLowestHandicapAndTeam(golfMatch) {

  const { team1, team2 } = golfMatch

  const lowestHandicapGolfer = _.minBy([...Object.values(team1), ...Object.values(team2)], 'courseHandicap');

  return lowestHandicapGolfer
  // Return the lowest handicap and the team with the lowest handicap
  // return {
  //   lowestHandicapGolfer: lowestHandicapGolfer,
  //   teamWithLowestHandicap: lowestHandicapGolfer === team1 ? 'Team 1' : 'Team 2',
  // };
}

const getLowCourseHandicap = (tg, hcpLowG) => {
  if (tg) {
    Object.keys(tg).forEach(key => {
      const g = tg[key]
      const { courseHandicap, matchHandicap } = g
      const h = matchHandicap
      if (parseInt(h) < hcpLowG.lowHandicap) {
        hcpLowG.lowHandicap = parseInt(h)
        hcpLowG.lowGolfer = g
      }
    })
  }

  return hcpLowG
}

const getTeamHcp = (golfers_team, roundType) => {

  let hcpSum = 0

  if (golfers_team) {
    Object.keys(golfers_team).forEach(key => {
      const g = golfers_team[key]
      const { matchHandicap } = g ? g : {}
      hcpSum += Math.round(matchHandicap);
    })
  }

  const teamHcp = {
    sum: hcpSum,
    ave: Math.ceil(hcpSum / 2),
    actual: 0,
  }

  switch (roundType) {
    case golfEnums.roundTypes.alternateShot:
    case golfEnums.roundTypes.alternateShot1:
    case golfEnums.roundTypes.alternateShot2:
      teamHcp.altShot = golfers_team && getTeamAltShotHandicap(golfers_team)
      break;

    default:
    // nothing
  }

  return teamHcp
}

const getTeamAltShotHandicap = (golfers) => {
  const _golfers = Object.values(golfers)
  const courseHandicaps = _golfers.map(golfer => golfer.courseHandicap);
  const lowerHandicap = Math.min(...courseHandicaps);
  const higherHandicap = Math.max(...courseHandicaps);
  if (lowerHandicap !== higherHandicap) {
    const golfer_low = _.find(golfers, { courseHandicap: lowerHandicap })
    const golfer_high = _.find(golfers, { courseHandicap: higherHandicap })
    if (golfer_low) {
      golfer_low.matchHandicap_alt = Math.round(_matchPlayPercs.alterateShot.low * lowerHandicap)
      golfer_low.matchHandicap_alt_perc = _matchPlayPercs.alterateShot.low
    }
    if (golfer_high) {
      golfer_high.matchHandicap_alt = Math.round(_matchPlayPercs.alterateShot.high * higherHandicap)
      golfer_high.matchHandicap_alt_perc = _matchPlayPercs.alterateShot.high
    }
    const teamCourseHandicap = golfer_low && golfer_high ? golfer_low.matchHandicap_alt + golfer_high.matchHandicap_alt : 0;
    return Math.round(teamCourseHandicap);
  } else {
    const teamCourseHandicap = ((lowerHandicap + higherHandicap) / 2);
    return Math.round(teamCourseHandicap);
  }
}

export const getMatchHandicaps = (golfers, roundType) => {

  let hcpLowG = getLowCourseHandicaps(golfers)

  // const x = findLowestHandicapAndTeam(golfers)

  switch (roundType) {

    case golfEnums.roundTypes.alternateShot:
    case golfEnums.roundTypes.alternateShot1:
    case golfEnums.roundTypes.alternateShot2:

      const team1Hcps = getTeamHcp(golfers.team1, roundType)
      const team2Hcps = getTeamHcp(golfers.team2, roundType)

      if (team1Hcps.altShot < team2Hcps.altShot) {
        team2Hcps.actual_alt = Math.ceil((team2Hcps.altShot - team1Hcps.altShot))
        team1Hcps.actual_alt = 0
        team1Hcps.lowT = true
      } else if (team2Hcps.altShot < team1Hcps.altShot) {
        team1Hcps.actual_alt = Math.ceil((team1Hcps.altShot - team2Hcps.altShot))
        team2Hcps.actual_alt = 0
        team2Hcps.lowT = true
      } else {
        team1Hcps.actual_alt = 0
        team2Hcps.actual_alt = 0
      }

      return { team1Handicaps: team1Hcps, team2Handicaps: team2Hcps, lowHandicapG: hcpLowG }

    default:
      return { lowHandicapG: hcpLowG, team1Handicaps: {}, team2Handicaps: {} }
  }

}

export const getLastHolePlayed = (holeWinners) => {
  const s = holeWinners ? Object.keys(holeWinners).pop() : 0
  return parseInt(s)
}

const getHolesWon = (holes, holeWinners, allGolfersStroking) => {

  const teamStrokes = {
    team1: {
      strokingAdvantage1: [],
      strokingAdvantage2: [],
      strokingDisadvantage1: [],
      strokingDisadvantage2: [],
      strokesNone: [],
      strokesEven: [],
      strokesDiff: [],
    },
    team2: {
      strokingAdvantage1: [],
      strokingAdvantage2: [],
      strokingDisadvantage1: [],
      strokingDisadvantage2: [],
      strokesNone: [],
      strokesEven: [],
      strokesDiff: [],
    }
  }

  const teamHoleStrokes = {
    strokingAdvantage1: {
      team1: [],
      team2: []
    },
    strokingAdvantage2: {
      team1: [],
      team2: []
    },
    strokingDisadvantage1: {
      team1: [],
      team2: []
    },
    strokingDisadvantage2: {
      team1: [],
      team2: []
    },
    strokesNone: {
      team1: [],
      team2: []
    },
    strokesEven: {
      team1: [],
      team2: []
    },
    strokesDiff: {
      team1: [],
      team2: []
    },
  }

  const holeStrokes = {}

  if (holes) {
    Object.keys(holes).forEach(holeNumber => {
      const holeWinnner = holeWinners ? holeWinners[holeNumber] : {}
      const { winningTeam } = holeWinnner ? holeWinnner : {}
      const losingTeam = winningTeam === 1 ? 2 : 1
      const golfersStroking = allGolfersStroking ? allGolfersStroking[holeNumber] : []
      // if there is a winning team AND some golfers are gettings strokes

      if (!holeStrokes[holeNumber]) {
        holeStrokes[holeNumber] = {
          strokingAdvantage1: 0,
          strokingAdvantage2: 0,
          strokingDisadvantage1: 0,
          strokingDisadvantage2: 0,
          oppStrokingOnly: 0,
          strokesNone: 0,
          strokesEven: 0,
          strokesDiff: 0,
        }
      }

      if (winningTeam > 0) {

        const strokes_winner = _.filter(golfersStroking, { teamNumber: winningTeam })
        const strokes_loser = _.filter(golfersStroking, { teamNumber: losingTeam })

        // WINNER strokes, LOSER none
        if (strokes_winner.length > 0 && strokes_loser.length === 0) {
          if (strokes_winner.length === 2) {
            teamHoleStrokes.strokingAdvantage2['team' + winningTeam].push(parseInt(holeNumber))
            teamStrokes['team' + winningTeam].strokingAdvantage2.push(parseInt(holeNumber))
            holeStrokes[holeNumber].strokingAdvantage2++
          } else {
            teamHoleStrokes.strokingAdvantage1['team' + winningTeam].push(parseInt(holeNumber))
            teamStrokes['team' + winningTeam].strokingAdvantage1.push(parseInt(holeNumber))
            holeStrokes[holeNumber].strokingAdvantage1++
          }
        }

        if (strokes_winner.length === 2 && strokes_loser.length === 1) {
          teamHoleStrokes.strokingAdvantage1['team' + winningTeam].push(parseInt(holeNumber))
          teamStrokes['team' + winningTeam].strokingAdvantage1.push(parseInt(holeNumber))
          holeStrokes[holeNumber].strokingAdvantage1++
        }

        if (strokes_loser.length > 0 && strokes_winner.length === 0) {
          if (strokes_loser.length === 2) {
            teamHoleStrokes.strokingDisadvantage2['team' + winningTeam].push(parseInt(holeNumber))
            teamStrokes['team' + winningTeam].strokingDisadvantage2.push(parseInt(holeNumber))
            holeStrokes[holeNumber].strokingDisadvantage2++
          } else {
            teamHoleStrokes.strokingDisadvantage1['team' + winningTeam].push(parseInt(holeNumber))
            teamStrokes['team' + winningTeam].strokingDisadvantage1.push(parseInt(holeNumber))
            holeStrokes[holeNumber].strokingDisadvantage1++
          }
        }

        if (strokes_loser.length === 2 && strokes_winner.length === 1) {
          teamHoleStrokes.strokingDisadvantage1['team' + winningTeam].push(parseInt(holeNumber))
          teamStrokes['team' + winningTeam].strokingDisadvantage1.push(parseInt(holeNumber))
          holeStrokes[holeNumber].strokingDisadvantage1++
        }

        // neither team has strokes
        if (strokes_winner.length === 0 && strokes_loser.length === 0) {
          teamHoleStrokes.strokesNone['team' + winningTeam].push(parseInt(holeNumber))
          teamStrokes['team' + winningTeam].strokesNone.push(parseInt(holeNumber))
          holeStrokes[holeNumber].strokesNone++
        }

        if (strokes_winner.length > 0 && strokes_loser.length > 0 && (strokes_winner.length === strokes_loser.length)) {
          teamHoleStrokes.strokesEven['team' + winningTeam].push(parseInt(holeNumber))
          teamStrokes['team' + winningTeam].strokesEven.push(parseInt(holeNumber))
          holeStrokes[holeNumber].strokesEven++
        }
      }
    })
  }

  return { teamStrokes, teamHoleStrokes, holeStrokes }

}
