import { where } from 'firebase/firestore';
import _ from 'lodash';
import { createItemPropValues } from '../../../../../global/common/convert';
import { createRefPath, createRefPath_event } from '../../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../../global/firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../../../../global/firestoreData/appData/fsData';
import { getSportsYear } from '../../../../../global/firestoreData/helpers/years';
import { seasonalHelpers } from './seasonalSportsData';

const _dpIgnores = ['allSportsYears', 'schools', 'sports', 'sportsTeams', 'staff', 'teams']

/**
 * Get data from the `_collectionName` with some `dataParent` and `additionalDataParent` constraints
 * @param {string} collectionName 
 * @param {object} pathViews 
 * @param {object} dataParents 
 * @param {function} callback 
 * @param {string} altCollectionName 
 * @returns via callback data from a document based on the collectionName WHERE the `parentKeys` constraints are correct
 */
export const getParentKeyData_sports = async (pathViews, dataItem, dataParents, callback, additionalDataParents, sportsKey, latestSeason, cbProps, firestore_fns, _refPaths) => {

  const {
    allDocs,
    collectionDoc,
    collectionName,
    collectionSub,
    collectionSubDoc,
    collectionSubs,
    ignoreDistrict,
    isSubCollection,
    noPrefix,
    noSeasonal,
    noYear,
    specificYear,
    subItemKey,
  } = dataItem ?? {}

  const _latestSeason = specificYear ? specificYear : latestSeason

  let _collectionName = collectionName
  _collectionName = noSeasonal || noPrefix ? collectionName : '_' + _collectionName

  const _callbackSub = (data, sbProps) => {

    let _data = {}
    const { sub } = sbProps

    if (data && sub) {
      Object.keys(data).forEach(dk => {
        const subData = data[dk][sub]
        createItemPropValues(subData, subItemKey, dk)
        _data = { ..._data, ...subData }
      })
    }
    const _subData = {
      [sub]: _data
    }

    const _opts = { ...sbProps, collectionDoc, _latestSeason, cbProps, collectionName, dataItem }

    callback(_subData, _opts)

  }

  /**
   * handles the callback for the fs_get_data call.
   * @param {object} data 
   * @param {object} opts 
   */
  const _callbackLocal = (data, opts) => {
    const { item: _data, _hasMultipleObjects } = data
    if (collectionSubs) {
      const { _itemKey } = _data ? _data : {}
      _.each(collectionSubs, (_collectionSub) => {
        const _refPathSub = createRefPath_event(pathViews, [_collectionName, _itemKey, _collectionSub])
        fs_get_data({ refPath: _refPathSub, callback: _callbackSub, opts: { listen: true, cbProps: { itemKey: _itemKey, sub: _collectionSub } } })
      })

    } else {
      const _opts = { ...opts, collectionDoc, _latestSeason, cbProps, collectionName, dataItem, _hasMultipleObjects }
      callback(_data, _opts)
    }
  }

  let _refPath;

  if (collectionSub && !isSubCollection) {
    if (collectionSubDoc) {
      _refPath = createRefPath_event(pathViews, [_collectionName, collectionSub, collectionSubDoc])
    } else {
      _refPath = createRefPath_event(pathViews, [_collectionName, collectionSub])
    }
  } else {
    _refPath = createRefPath_event(pathViews, [_collectionName])
  }

  const wheres = []
  let _orderBy;
  let dataLimit;
  const wherez = []

  if (dataParents) {
    dataParents.forEach(dp => {
      if (!_dpIgnores.includes(dp)) {
        if (pathViews[dp]) {
          wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
          wherez.push({ pk: dp, value: pathViews[dp] })
        }
      }
    })
  }

  if (additionalDataParents) {
    additionalDataParents.forEach(dp => {
      switch (dp) {
        case 'sports':
          if (sportsKey) {
            wheres.push(where('parentKeys.' + dp, '==', sportsKey))
            wherez.push({ pk: dp, value: sportsKey })
          } else {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            wherez.push({ pk: dp, value: pathViews[dp] })
          }
          break;
        case 'sportsYear':
          if (latestSeason && !noYear) {
            wheres.push(where('parentKeys.sportsYears', '==', getSportsYear(latestSeason)))
            wherez.push({ pk: 'sportsYears', value: getSportsYear(latestSeason) })
          }
          break;
        default:
          if (pathViews[dp]) {
            wheres.push(where('parentKeys.' + dp, '==', pathViews[dp]))
            wherez.push({ pk: dp, value: pathViews[dp] })
          }
      }
    })
  }

  if (!ignoreDistrict) {
    if (pathViews['districts']) {
      wheres.push(where('parentKeys.districts', '==', pathViews['districts']))
      wherez.push({ pk: 'districts', value: pathViews['districts'] })
    }
  }

  if (_refPaths) {
    _refPaths.push(_refPath)
  }

  if (firestore_fns) {
    firestore_fns.handle_getData({ refPath: _refPath, wheres, callback: _callbackLocal, opts: { cbProps: { _refPath, wherez }, _orderBy, dataLimit, listen: true, returnFirstObject: allDocs ? false : true, warnMultipleObjects: true, warnMultipleObjectIndex: 1 } })
  } else {
    fs_get_data({ refPath: _refPath, wheres, callback: _callbackLocal, opts: { cbProps: { _refPath, wherez }, _orderBy, dataLimit, listen: true, returnFirstObject: allDocs ? false : true, warnMultipleObjects: true, warnMultipleObjectIndex: 1 } })
  }
}

export const updateParentKeyData_sports = async (sportPermissions, collectionName, pathViews, fieldName, data, callback, sportsKey) => {

  const _allowUpdate = sportPermissions.any && sportPermissions.parentKeySportsData ? true : false

  const _callback = (parentalItem) => {

    if (parentalItem) {

      const existingParentalItem = parentalItem[Object.keys(parentalItem)[0]]

      const _refPath = createRefPath_event(pathViews, [collectionName])

      const updateData = { [fieldName]: data }

      try {
        if (existingParentalItem) {
          const _updateRef = createRefPath([existingParentalItem.id], _refPath)
          if (_allowUpdate) {
            fs_set_doc(_updateRef, updateData, true, callback)
          } else {
            console.log('_updateRef', _updateRef, updateData)
          }
        } else {
          // ADD ??? 
        }
      } catch (error) {
        console.log('updateParentKeyData - error', error)
      }
    }
  }

  getParentalForUpdate(pathViews, collectionName, sportsKey, _callback)

}

const getParentalForUpdate = (pathViews, collectionName, sportsKey, callback) => {

  const _refPath = createRefPath_event(pathViews, [collectionName])
  const wheres = []

  const _pks = seasonalHelpers.getPks(pathViews)

  Object.keys(_pks).forEach(pkk => {
    switch (pkk) {
      case 'sports':
        wheres.push(where('parentKeys.' + pkk, '==', sportsKey))
        break;
      default:
        wheres.push(where('parentKeys.' + pkk, '==', _pks[pkk]))
    }
  })

  fs_get_data({ refPath: _refPath, callback, wheres })

}
