import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { fbAppConfigs } from '../../../../project/appConfigs';
import { envTypes } from '../../../../project/appConfiguration';
import { CreateContext } from '../../../cnr/contexts/CreateContext';
import GoogleSheetsProvider, { GoogleSheetsContext } from '../../../cnr/contexts/GoogleSheetsContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { googleSheetsTypes } from '../../../cnr/reducers/GoogleSheetsReducer';
import { convertArraysToStrings } from '../../../common/convert';
import { uniqueKey } from '../../../common/keys';
import { removeArrayItem } from '../../../common/sorting';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import AppForm from '../../../components/forms/appForms/AppForm';
import EventsViewer from '../../../components/viewers/EventsViewer';
import { GoogleDashboard } from '../../../components/viewers/GoogleDashboard';
import JsonViewer from '../../../components/viewers/JsonViewer';
import RegDataViewerWithProvider from '../../../components/viewers/RegDataViewer';
import StepWizard from '../../../components/wizard/StepWizard';
import { appFormTypes } from '../../../enums/appFormTypes';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';
import { settingFormTypes } from '../../../enums/settingFormTypes';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import { settingsActionTypes } from '../../enums/itemActionTypes';
import SettingsFormEdit from '../../subComponents/SettingsFormEdit';

const stepTypes_normalEvent = {
  previousEventSettings: 'previousEventSettings',
  parentDefaults: 'parentDefaults',
  themeColors: 'themeColors',
  // destination: 'destination',
  confirmation: 'confirmation',
}

const stepTypes_googleSheetsOnly = {
  googleSheetsData: 'googleSheetsData',
  confirmation: 'confirmation',
}

const stepTypes_google = {
  googleSheetsData: 'googleSheetsData',
  ...stepTypes_normalEvent
}

const stepTypes_googleMerge = {
  googleSheetsData: 'googleSheetsData',
  confirmation: 'confirmation',
}

const stepTypes_reg = {
  registrationData: 'registrationData',
  ...stepTypes_normalEvent
}

const stepTypes_regAndGoogle = {
  registrationData: 'registrationData',
  googleSheetsData: 'googleSheetsData',
  ...stepTypes_normalEvent
}

const stepTypes_previous = {
  googleSheetsData: 'googleSheetsData',
  previousEventSettings: 'previousEventSettings',
  confirmation: 'confirmation',
}

export const createEventTypes = {
  merge: 'merge',
  previous: 'previous',
  reg: 'reg',
  regAndGoogleSheets: 'regAndGoogleSheets',
}

const CreateEvent = (props) => {

  const { createEventType, addModeType } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states, settings } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { appUser } = appUser_state ?? {}
  const { appUserAccess } = appUser ?? {}
  const { isSuperAdminDeveloper } = appUserAccess ?? {}
  const { pathViews } = paps_state ?? {}
  const { events: eventKey } = pathViews ?? {}
  const { clientSettings } = settings ?? {}

  // createContext
  const createContext = useContext(CreateContext)
  const { create_handlers, create_state } = createContext ?? {}
  const { projectResult, updating: updating_cr, updatingCaption: updatingCaption_cr, createType } = create_state ?? {}

  // googleSheetsContext
  const googleSheetsContext = useContext(GoogleSheetsContext);
  const { googleSheets_state, googleSheets_handlers } = googleSheetsContext ?? {}
  const {
    dashboardInfo_merged,
    dashboardInfo_preview,
    updating: updating_gs,
    updatingCaption: updatingCaption_gs,
    confirmation,
    projectOptions,
    themeColors,
  } = googleSheets_state ?? {}

  if (dashboardInfo_preview && dashboardInfo_merged && dashboardInfo_merged.projectSettings) {
    dashboardInfo_preview.projectSettings = dashboardInfo_merged.projectSettings
  }

  const _updating = updating_cr ? updating_cr : updating_gs
  const _updatingCaption = updatingCaption_cr ? updatingCaption_cr : updatingCaption_gs
  const _createData = projectResult ? projectResult : dashboardInfo_preview

  const [regInfo, setRegInfo] = useState({});
  const [appStatus, setAppStatus] = useState({});
  const [dataOptions, setDataOptions] = useState({});
  const [parentDefaults, setParentDefaults] = useState({});
  const [selectedEventKey, setSelectedEventKey] = useState(eventKey)
  const [destination, setDestination] = useState({})
  const [config_alt, setConfig_alt] = useState()

  const { environment, appName } = destination ?? {}
  const _env = environment === envTypes.development ? 'dev' : 'prod'

  const eventOptions = {
    appStatus,
    parentDefaults,
    projectOptions,
    themeColors,
  }

  let steps;

  switch (createEventType) {
    case createEventTypes.reg:
      steps = Object.keys(stepTypes_reg)
      break;
    case createEventTypes.regAndGoogleSheets:
      steps = Object.keys(stepTypes_regAndGoogle)
      break;
    case createEventTypes.merge:
      steps = Object.keys(stepTypes_googleMerge)
      break;
    case createEventTypes.previous:
      steps = Object.keys(stepTypes_previous)
      break;
    default:
      switch (addModeType) {
        case gEnums.addModeTypes.googleSheetsOnly:
          steps = Object.keys(stepTypes_googleSheetsOnly)
          break;
        default:
          switch (createType) {
            case settingsActionTypes.copyEvent:
              steps = Object.keys(stepTypes_previous)
            default:
              steps = Object.keys(stepTypes_google)
          }
      }

      if (!isSuperAdminDeveloper) {
        removeArrayItem(steps, 'destination')
      }
  }
  // eslint-disable-next-line 

  useEffect(() => {
    if (_createData) {
      // update global with the eventOptions
      const _previewInfo = { ..._createData }
      console.log('_previewInfo', _previewInfo)
      if (eventOptions) {
        Object.keys(eventOptions).forEach(key => {
          _previewInfo.projectSettings.global[key] = eventOptions[key]
        })
      }
      switch (createEventType) {
        case createEventTypes.reg:
          create_handlers.handleUpdate_projectResult(_previewInfo)
          break;

        default:
          googleSheets_handlers.handleSet_previewInfo(_previewInfo)
      }
    }

    const { global: global_client } = clientSettings ?? {}
    const { themeColors: themeColors_client } = global_client ?? {}
    if (themeColors_client) { googleSheets_handlers.handleSet_themeColors(themeColors_client) }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [projectOptions, themeColors, appStatus, parentDefaults]);

  useEffect(() => {
    if (appName && environment) {
      const _config_alt = fbAppConfigs[appName][_env]
      setConfig_alt(_config_alt)
    }
    // eslint-disable-next-line
  }, [appName, environment]);

  const handleProjectOptions = (projectOptions) => googleSheets_handlers.handleSet_projectOptions(projectOptions)
  const handleThemeColors = (themeColors) => googleSheets_handlers.handleSet_themeColors(themeColors)
  const handlePreviousPreviewInfo = (previousEventSettings) => googleSheets_handlers.handleGet_previousPreviewInfo(previousEventSettings)
  const handleDestination = (d) => setDestination(d)

  /**
   * creates the event in the database
   */
  const handleCreate = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        create_handlers.handleStartUpdate()
        create_handlers.handleCreate_clientOrEventInDb()
        break;
      default:
        googleSheets_handlers.handleStartUpdate()
        googleSheets_handlers.handleCreate_eventFromGs(null, null, addModeType, config_alt, destination)
    }
  }

  const handleCreate_temp = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        create_handlers.handleStartUpdate()
        create_handlers.handleCreate_clientOrEventInDb()
        break;
      default:
        googleSheets_handlers.handleStartUpdate()
        googleSheets_handlers.handleCreate_eventFromGs(null, null, addModeType, config_alt, destination)
    }
  }

  const handleCreateData = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        // nothing
        break;
      default:
        googleSheets_handlers.handleCreate_eventDataFromGs(eventOptions)
    }
  }

  const handleMerge = () => {
    switch (createEventType) {
      case createEventTypes.reg:
        create_handlers.handleStartUpdate()
        create_handlers.handleCreate_clientOrEventInDb()
        break;
      default:
        googleSheets_handlers.handleStartUpdate()
        googleSheets_handlers.handleCreate_eventFromGs()
    }
  }




  // local state  
  const [step, setStep] = useState(steps ? { index: 0, name: steps[0] } : { index: 0, name: '???' })
  const stepCount = steps ? steps.length : 0

  // triggers the handleCreate_clientProjectSettings
  useEffect(() => {
    switch (step.name) {
      case stepTypes_reg.registrationResponse:
        create_handlers.handleFetch_urlFeed(null, regInfo.eventKey)
        break;

      case stepTypes_reg.themeColors:
        if (dataOptions.convertSingleItemArraysToString) {
          const { projectData } = _createData ?? {}
          const { dataCollections } = projectData ?? {}
          if (dataCollections) {
            Object.keys(dataCollections).forEach(key => {
              const dc = dataCollections[key]
              convertArraysToStrings(dc)
            })
          }
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [step]);

  // const handleShowConfirmation = () => { }
  // const handleCancel = () => props.handleCancel()
  // const handleSaveInfo = () => {    console.log('handle save');  }

  const validateComponent = () => {
    switch (step.name) {
      case stepTypes_google.googleSheetsData:
        return dashboardInfo_preview ? true : false
      case stepTypes_reg.registrationData:
        return projectResult ? true : false
      case stepTypes_previous.previousEventSettings:
        return true
      case stepTypes_normalEvent.googleSheetsDataOptions:
        return true
      case stepTypes_normalEvent.projectOptions:
        return true
      case stepTypes_normalEvent.themeColors:
        return true
      case stepTypes_normalEvent.eventStatus:
        return true
      case stepTypes_normalEvent.parentDefaults:
        return true
      case stepTypes_normalEvent.destination:
        return true
      case stepTypes_normalEvent.confirmation:
        return true
      default:
        break;
    }
  }

  const allowBack = step.index > 0
  const allowForward = (step.index < (stepCount - 1) && validateComponent())

  const settingsForm = (settingsType, formData, updateForm) => <SettingsFormEdit
    key={uniqueKey('ce', settingsType)}
    settingsType={settingsType}
    formData={formData}
    updateForm={updateForm}
    isGlobal={true}
  />

  const appForm_tranfer = () => <AppForm
    appFormType={appFormTypes.createEvent}
    parentData={destination}
    handleUpdateParentData={handleDestination}
  />

  const _googleData = () => <GoogleDashboard noModal={true} fromCreate={true} />
  const _regData = () => <RegDataViewerWithProvider noModal={true} fromCreate={true} />
  const _eventsViewer = () => <EventsViewer
    noModal={true}
    fromCreate={true}
    forCopy={createType === settingsActionTypes.copyEvent ? true : false}
    selectedEventKey={selectedEventKey}
    handleSelectedEvent={setSelectedEventKey}
    handlePreviewInfo={handlePreviousPreviewInfo}
  />

  const buttons = () => {
    const btns = []
    switch (createEventType) {
      case createEventTypes.merge:
        btns.push({ oc: handleMerge, caption: 'Merge Event', color: 'blue', disabled: !_createData, icon: appIconTypes.arrowRight })
        break;
      default:
        btns.push({ oc: handleCreate, caption: 'Create New Event', color: 'green', disabled: !_createData, icon: appIconTypes.save })
        btns.push({ oc: handleCreate_temp, caption: 'Create Temp Event', color: 'blue', disabled: !_createData, icon: appIconTypes.arrowRight, fl: true })
    }
    return btns
  }

  const saveButtons = () => <UiSaveButtons others={buttons()} />

  const confirmation_info = () => <Wrapper
    content={<JsonViewer json={_createData} name={'createInfo'} />}
    footer={saveButtons()}
    wrapperType={wrapperTypes.padded}
  />

  const stepCaption = () => {
    const name = _.startCase(step.name)
    const stepCaption = steps && ' (' + (step.index + 1) + ' of ' + (steps.length) + ')'
    switch (step.name) {
      default:
        return name + stepCaption
    }
  }

  const content = () => {
    switch (step.name) {
      case stepTypes_google.googleSheetsData:
        return _googleData()
      case stepTypes_reg.registrationData:
        return _regData()
      case stepTypes_previous.previousEventSettings:
        return _eventsViewer()
      case stepTypes_normalEvent.googleSheetsDataOptions:
        return settingsForm(settingFormTypes.googleSheetsDataOptions, dataOptions, setDataOptions)
      case stepTypes_normalEvent.projectOptions:
        return settingsForm(settingFormTypes.projectOptions, projectOptions, handleProjectOptions)
      case stepTypes_normalEvent.themeColors:
        return settingsForm(settingFormTypes.themeColors, themeColors, handleThemeColors)
      case stepTypes_normalEvent.eventStatus:
        return settingsForm(settingFormTypes.appStatus, appStatus, setAppStatus)
      case stepTypes_normalEvent.parentDefaults:
        return settingsForm(settingFormTypes.parentDefaults, parentDefaults, setParentDefaults)
      case stepTypes_normalEvent.destination:
        return appForm_tranfer()
      case stepTypes_normalEvent.confirmation:
        return confirmation_info()
      default:
        break;
    }
  }

  const stepWizard = () => <StepWizard
    allowBack={allowBack}
    allowForward={allowForward}
    content={content()}
    darkMode={true}
    noModal={true}
    fullMode={true}
    setStep={setStep}
    step={step}
    stepCaption={stepCaption()}
    steps={steps}
    topperCaption={_.startCase('createEvent')}
    updating={_updating}
    updatingCaption={_updatingCaption}
    confirmation={confirmation}
  ></StepWizard>

  return step ? stepWizard() : <div></div>

}

export const CreateEventWithProvider = (props) => {
  return <GoogleSheetsProvider googleSheetsType={googleSheetsTypes.create}>
    <CreateEvent {...props} />
  </GoogleSheetsProvider>
}