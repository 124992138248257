import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { gEnums } from '../../../../global/enums/globalEnums'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext'
import { matchScheduleTypes } from '../../cnr/contexts/SportsSeasonContext'
import MatchScoreKeypad, { _keypadTypes } from './MatchScoreKeypad'
import { SeasonMatchesContext } from '../../cnr/contexts/SeasonMatchesContext'

const WAIT_INTERVAL = 1000

const MatchScoreButtons = () => {

  const parentContext = useContext(ParentContext)
  const { fns } = parentContext ?? {}
  const { appUser_fns } = fns
  const accessLevel = appUser_fns.get_accessLevel()
  const allowMatchEdit = accessLevel >= gEnums.accessLevels.superAdmin.value

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)
  const { seasonMatches_state } = seasonMatchesContext ?? {}
  const { matchScheduleType } = seasonMatches_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_handlers, sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true, realtimeMatchData, sportPermissions } = sportsMatch_state ?? {}
  const { match_rt: match_rt_match } = realtimeMatchData ?? {}
  const { handleShow_rtScoring } = sportsMatch_handlers ?? {}

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state, sportsRtMatch_handlers } = sportsRtMatchContext ?? {}
  const { match_rt, match_key } = sportsRtMatch_state ?? {}
  const { setNumber, set_complete_possible, match_complete_possible } = match_rt ?? {}

  const [restrictAccess, setRestrictAccess] = useState()
  const [showMatchAmmend, setShowMatchAmmend] = useState()
  const [showMatchKeypad, setShowMatchKeypad] = useState()
  const [showMatchRemove, setShowMatchRemove] = useState()

  const _allowRemoveRts = sportPermissions.any && sportPermissions.rtMatches ? true : false

  useEffect(() => {
    if (restrictAccess) {
      const timer = setTimeout(() => setRestrictAccess(false), WAIT_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictAccess]);

  useEffect(() => {
    sportsRtMatch_handlers.handleSet_rtMatch(match_rt_match)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match_rt_match]);

  const handleScoreUpdate = (e, rtType, add) => {
    e.stopPropagation()
    setRestrictAccess(true)
    !restrictAccess && sportsRtMatch_handlers.handleRt_updateScore(match_rt, rtType, add)
  }

  const handleMatchAmmend = (e) => {
    e.stopPropagation()
    setShowMatchAmmend(!showMatchAmmend)
  }

  const handleShow_confirmRemove = (e) => {
    e.stopPropagation()
    setShowMatchRemove(true)
    setShowMatchAmmend(false)
    setRestrictAccess(true)
  }

  const handleRemoveRtMatch = (e) => {
    e.stopPropagation()
    setShowMatchRemove(false)
    setShowMatchAmmend(false)
    setRestrictAccess(true)
    sportsRtMatch_handlers.handleRtDelete_scores()
  }

  const handleShow_confirmSet = (e) => {
    e.stopPropagation()
    setShowMatchAmmend(false)
    setRestrictAccess(true)
    sportsRtMatch_handlers.handleRtConfirm_set(true)
  }

  const handleShow_confirmMatch = (e) => {
    e.stopPropagation()
    sportsMatch_handlers.handleShow_scoreEdit(match_true, true)
  }

  const handleShow_scoreEdit = (e) => {
    e.stopPropagation()
    sportsMatch_handlers.handleShow_scoreEdit(match_true)
  }

  const handleScoreClose = (e) => {
    e.stopPropagation()
    handleShow_rtScoring(false)
  }

  const rtScores = {
    home: 0,
    away: 0,
    setNumber: 0
  }

  if (match_rt) {
    const { scores } = match_rt

    if (scores) {
      Object.keys(scores).forEach((sk, index) => {
        const _score = scores[sk]
        if (_score.home || _.isNumber(_score.home)) {
          rtScores.home = {
            score: _score.home,
            color: _score.home > _score.away ? 'green' : _score.home < _score.away ? 'red' : 'blue',
            cn: _score.home > _score.away ? 'up' : _score.home < _score.away ? 'down' : 'tie'
          }
          rtScores.setNumber = index
        }
        if (_score.away || _.isNumber(_score.away)) {
          rtScores.away = {
            score: _score.away,
            color: _score.away > _score.home ? 'green' : _score.away < _score.home ? 'red' : 'blue',
            cn: _score.away > _score.home ? 'up' : _score.away < _score.home ? 'down' : 'tie'
          }
          rtScores.setNumber = index
        }
      })
    }
  }

  const content_remove = (e) => <div className='match-score-btns rmv'>
    <div>{match_key}</div>
    <Button.Group size='mini' fluid >
      <Button color={_allowRemoveRts ? 'red' : 'grey'} fluid onClick={(e) => { handleRemoveRtMatch(e) }} >{'Remove RTS'}</Button>
    </Button.Group>
  </div>

  const content_ammend = (e) => <div className='match-score-btns amd' onClick={(e) => { handleMatchAmmend(e) }}>
    <Button.Group size='mini' fluid >
      <Button color={'blue'} onClick={(e) => { handleShow_confirmRemove(e) }} >{'Remove RTS'}</Button>
      <Button color={'blue'} disabled={!set_complete_possible} onClick={(e) => { handleShow_confirmSet(e) }} >{'Confirm Set ' + setNumber}</Button>
      <Button color={'blue'} disabled={!match_complete_possible} onClick={(e) => { handleShow_confirmMatch(e) }} >{'Confirm'}</Button>
      <Button color={'blue'} onClick={(e) => { handleShow_scoreEdit(e) }} >{'Edit'}</Button>
    </Button.Group>
  </div>

  const teamSetScoreButtons = (score, team, t) => {
    let cn = matchScheduleType === matchScheduleTypes.latest ? 'team-score-' + t : t
    return <Button.Group size='mini' >
      <Button icon='arrow left' color={!restrictAccess ? 'red' : 'grey'} onClick={(e) => { handleScoreUpdate(e, team, false) }} />
      <Button content={score.score} className={cn} color='black' onClick={(e) => { handleScoreClose(e) }} />
      <Button icon='arrow right' color={!restrictAccess ? 'green' : 'grey'} onClick={(e) => { handleScoreUpdate(e, team, true) }} />
    </Button.Group>
  }

  const content_scoring = () => <div className='match-score-btns' onClick={(e) => { handleScoreClose(e) }}>
    {teamSetScoreButtons(rtScores.away, 'away', 'away ' + rtScores.away.cn)}
    <div>
      {allowMatchEdit && <Icon bordered name='tasks' color='blue' onClick={(e) => { handleMatchAmmend(e) }} />}
    </div>
    {teamSetScoreButtons(rtScores.home, 'home', 'home ' + rtScores.home.cn)}
  </div>

  const matchKeypad = () => <div className='match-score-btns kpd' >
    <MatchScoreKeypad keypadType={_keypadTypes.realtime} />
  </div>

  if (showMatchKeypad) {
    return matchKeypad()
  } else if (showMatchAmmend) {
    return content_ammend()
  } else if (showMatchRemove) {
    return content_remove()
  } else {
    return content_scoring()
  }

}

export default MatchScoreButtons