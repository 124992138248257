import _ from 'lodash'

export const grts = {
  functionResponse: 'functionResponse',
  handleCloseConfirmation: 'handleCloseConfirmation',
  handleFunctionResponse: 'handleFunctionResponse',
  handleShow_itemSidebar: 'handleShow_itemSidebar',
  handleStartUpdate: 'handleStartUpdate',
  handleStopUpdate: 'handleStopUpdate',
  updateError: 'updateError',
  updateSuccess: 'updateSuccess',
  updateSuccessAlt: 'updateSuccessAlt',
  handleCloseOpenedItem: 'handleCloseOpenedItem',
}

export const dispatchProps = {
  handleCloseOpenedItem: grts.handleCloseOpenedItem,
  closeConfirmation: grts.handleCloseConfirmation,
  handleShow_itemSidebar: grts.handleShow_itemSidebar,
  success: grts.updateSuccess,
  successAlt: grts.updateSuccessAlt,
  error: grts.updateError
}

export const dispatchConfirmationTypes = {
  closeAfterConfirmation: 'closeAfterConfirmation',
  closeAfterConfirmationClick: 'closeAfterConfirmationClick',
  closeAfterUpdate: 'closeItemAfterUpdate'
}

/**
 * Use dispatch(handleStartUpdate, handleFunctionResponse, closeConfirmation, updateSuccess, updateSuccessAlt, updateError ); to access
 * @param {*} state 
 * @param {*} action (type)
 * @param {*} opts 
 * @returns 
 */
export const responseReducers = (state, action, opts, stateOpts) => {

  const { type, contentAlt, contentItems } = action ?? {}
  const { dispatch, dispatchConfirmationType, updatingCaption } = opts ?? {}

  if (contentAlt) { opts.contentAlt = contentAlt }
  if (contentItems) { opts.contentItems = contentItems }

  console.log('type', type)

  switch (type) {
    // this will set the state.updating to TRUE, triggering a `DimmerWrap` in `Wrapper` if the Wrapper's updating props is set
    case grts.handleStartUpdate:
      return {
        ...state,
        showConfirmation: false,
        updating: true,
        updatingCaption: updatingCaption ? updatingCaption : 'Updating',
        showConfirmationQuestion: null,
        confirmation: null,
        ...stateOpts
      }

    // this will set the state.updating to FALSE, triggering a `DimmerWrap` in `Wrapper` if the Wrapper's updating props is set
    case grts.handleStopUpdate:
      return { ...state, updating: false, confirmation: null, showSingleItemEdit: null, ...stateOpts }

    case grts.handleFunctionResponse:
      return { ...state, confirmation: getFunctionConfirmation(state, action, opts) }

    // this is triggered by the DimmerComfirmation/handleClose via dispatch
    case grts.handleCloseConfirmation:
      if (dispatch && dispatchProps && (dispatchConfirmationType === dispatchConfirmationTypes.closeAfterConfirmation)) {
        dispatch({ type: dispatchProps.handleCloseOpenedItem })
      }
      return { ...state, updating: false, confirmation: null, showSingleItemEdit: null }

    case grts.updateSuccess:
    case grts.updateSuccessAlt:
      if (dispatch && (dispatchConfirmationType === dispatchConfirmationTypes.closeAfterUpdate)) {
        // if dispatchConfirmationType =is closeAfterUpdate, the dimmer will be shut down
        dispatch({ type: dispatchProps.handleCloseOpenedItem })
        return { ...state, updating: false }
      } else {
        return { ...state, confirmation: getUpdateConfirmation(action, opts), ...opts }
      }

    case grts.updateError:
      return { ...state, updateSuccess: false, updateError: true, showSingleItemEdit: null, confirmation: getUpdateConfirmation(action) }

    default:
      return { ...state }
  }
}

/**
 * Global handlers for reducers
 * @param {function} dispatch 
 * @returns 
 */
export const responseHandlers = (dispatch) => {
  return {
    handleFunctionResponse: (response_data, dispatch) => { dispatch({ type: grts.handleFunctionResponse, response_data, dispatch }) },
    handleStartUpdate: (hsuProps) => { dispatch({ type: grts.handleStartUpdate, dispatch, hsuProps }) },
    handleStopUpdate: (hsuProps) => { dispatch({ type: grts.handleStopUpdate, dispatch, hsuProps }) },
    handleCloseConfirmation: (hsuProps) => { dispatch({ type: grts.handleCloseConfirmation, dispatch, hsuProps }) },
  }
}

/** Returns the confirmation object */
export const getUpdateConfirmation = (action, opts) => {

  const { type, error, opts: opts_action } = action
  const _opts = { ...opts, ...opts_action }

  switch (type) {

    case dispatchProps.successAlt:
      return {
        title: 'Saved',
        success: true,
        content: 'The data has NOT been updated.',
        contentAlt: 'This action did not update the database as it is not allowed in settings. Check the console for additional information.',
        opts: _opts
      }

    case dispatchProps.success:
      return {
        title: 'Saved',
        success: true,
        content: 'The data has been updated.',
        opts: _opts
      }

    case dispatchProps.error:
      return {
        title: 'Not updated',
        success: false,
        content: 'The data has NOT been updated.',
        error: error,
        opts: _opts
      }

    default:
      return {
        title: 'Not updated',
        success: false,
        content: 'The data has NOT been updated.',
        opts: _opts
      }
  }
}

/** Returns the confirmation object */
const getFunctionConfirmation = (state, action, opts) => {

  const { response_data } = action
  const { success, result, error } = response_data ?? {}
  const { type } = result ?? {}

  if (success) {
    return {
      title: 'Success',
      success: success,
      content: type ? _.startCase(type) : 'Success',
      opts
    }
  } else {
    return {
      title: 'Error',
      success: success,
      content: type ? _.startCase(type) : 'Error',
      contentAlt: error ? error : null,
      opts
    }
  }
}