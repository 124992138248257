import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Menu } from 'semantic-ui-react';
import NotificationSettings from '../../../NotificationSettings';
import { FrameworkContext } from '../../cnr/contexts/FrameworkContent';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { _appSidebarContentTypes } from '../../cnr/reducers/FrameworkReducer';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import { iconColorTypes } from '../../enums/settingsIconTypes';
import SettingsAreaGroupMenuItem from '../../viewSettings/components/menus/SettingsAreaGroupMenuItem';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import GroupActionButton from '../buttons/GroupActionButton';
import MenuItems from './MenuItems';
import NavMenu from './NavMenu';
import NavSideBottomMenu from './NavSideBottomMenu';
import { slideMenuItems } from './slideMenuItems';

export const appClicks = {
  add: 'add',
  appSettings: 'appSettings',
  cancelSignOut: 'cancelSignOut',
  confirmSignOut: 'confirmSignOut',
  desktop: 'desktop',
  signOut: 'signOut',
  viewSettings: 'viewSettings',
}

/** The contents of the sidebar contained in Sidebar
 * @description: This is NOT the settings sidebar.
 */
const AppSidebarMenu = (props) => {

  const { navOnly, sac } = props

  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states
  const { auth_handlers } = handlers
  const { appUser_fns, page_fns } = fns
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles, aps_viewItems: viewItems } = pageSettings ?? {}
  const { appAccess, sideMenu, eventOptions } = aps_global ?? {}
  const { wifiNetwork, wifiPassword } = eventOptions ?? {}
  const { sharePath } = paps_state ?? {}

  // authContext 
  const { appUser, appUsers } = appUser_state ?? {}
  const { appUserAccess } = appUser ?? {}
  const { loggedIn } = appUserAccess ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers, framework_state } = frameworkContext ?? {}
  const { appSidebarContentType, desktopMode, additionalMenuItems } = framework_state ?? {}

  const signOutSnC = aps_styles ? aps_styles[gEnums.projectStyles.signOut] : {}

  // pageContext 
  const { pushSimple } = page_fns ?? {}
  const { sideMenuItems } = sideMenu ?? {}
  const { appAccessType } = appAccess ?? {}

  let _viewPermissionType = appUser_fns.validate_settingsMenuAuth(true)
  const allowSidebarView = appUser_fns.validateAccess_sidebar(appAccessType)

  const handleButtonConfirm_signOut = () => {
    auth_handlers.handleSignOut()
    framework_handlers.handleShow_appSidebar()
  }

  const pushSimpleItem = (clickedItem, itemKey, modifyMode) => {
    !desktopMode && framework_handlers.handleShow_appSidebar && framework_handlers.handleShow_appSidebar()
    pushSimple(clickedItem, itemKey, modifyMode)
  }

  // local state 
  const [showSignOut, setShowSignOut] = useState()
  const [menuItems_side, setSlideMenuItems] = useState()

  const { adminSlideItems, appSlideItems, gotoItems } = menuItems_side ?? {}

  useEffect(() => {
    let _sideViewItems = {}
    if (sideMenuItems) {
      sideMenuItems.forEach(svi => {
        if (viewItems[svi]) {
          _sideViewItems[svi] = viewItems[svi]
          _sideViewItems[svi]._viewPermissionType = gEnums.viewPermissionTypes.allow
        }
      })
    }
    appUser_fns.validateAccess_items(_sideViewItems)
    const _slideMenuItems = slideMenuItems(appUser_fns, appUser, paps_state, _sideViewItems, showSignOut, handleSignOut)
    if (_slideMenuItems.appSlideItems && additionalMenuItems) {
      _slideMenuItems.appSlideItems = [..._slideMenuItems.appSlideItems, ...additionalMenuItems]
    }
    setSlideMenuItems(_slideMenuItems)
    // eslint-disable-next-line
  }, [sideMenuItems, additionalMenuItems]);

  useEffect(() => {
    setShowSignOut(loggedIn)
    // eslint-disable-next-line
  }, [loggedIn]);

  const handleSignOut = () => console.log('TEST')
  const handleShowSignIn = () => framework_handlers.handleShow_signIn && framework_handlers.handleShow_signIn()

  /** handles the click of a menuItem */
  const handleClick = (item, noClick) => {
    if (noClick) {
      framework_handlers.handleShow_appSidebar && framework_handlers.handleShow_appSidebar()
    } else {
      switch (item.key) {
        case appClicks.signOut:
          // setShowSignOut(true)
          break;
        case appClicks.cancelSignOut:
          // setShowSignOut(false)
          break;
        case appClicks.confirmSignOut:
          auth_handlers.handleSignOut()
          framework_handlers.handleShow_appSidebar && framework_handlers.handleShow_appSidebar()
          setShowSignOut(false)
          break;
        case appClicks.add:
          framework_handlers.handleShow_appSidebar && framework_handlers.handleShow_appSidebar()
          pushSimpleItem(null, null, 'add')
          break;

        case appClicks.viewSettings:
          framework_handlers.handleShow_settingSoloSidebar(item.permissionProps.permissionType)
          break;

        case appClicks.appSettings:
          if (item.permissionProps) {
            framework_handlers.handleShow_settingSoloSidebar(item.permissionProps.permissionType)
          }
          break;
        default:
          if (item.permissionProps) {
            framework_handlers.handleShow_settingSoloSidebar(item.permissionProps.permissionType)
          } else {
            pushSimpleItem(item)
          }
        // nothing
      }
    }
  }

  /** returns a set of menu items (admin) */
  const sideItems = (sis, horz, alignRight) => {
    const nmiProps = { items: sis, horz, alignRight, handleClick }
    return <MenuItems {...nmiProps} />
  }

  /** the HEADER portion of the menu. Contains signUp and signOut */
  const signOutButton = () => <GroupActionButton
    key={uniqueKey('gab-so')}
    actionName={'signOut'}
    handleConfirm={handleButtonConfirm_signOut}
    saveIcon={'check'}
    iconColor={iconColorTypes.signOut}
    styleAndClass={signOutSnC}
    defaultIcon={'sign-out'}
  />

  const signInButton = () => <GroupActionButton
    key={uniqueKey('gab-so')}
    actionName={'signIn'}
    handleConfirm={handleShowSignIn}
    saveIcon={'arrow right'}
    styleAndClass={signOutSnC}
    defaultIcon={'sign-in'}
    directAction={true}
  />

  // const signInButton = () => <Button
  //   icon={'sign-in'}
  //   color={iconColorTypes.signOut}
  //   fluid
  //   content={'Sign In'}
  //   onClick={() => { handleShowSignIn() }}
  // >
  // </Button>


  /** the NAVIGATION portion of the menu. */
  const navMenu = () => {
    const nmProps = { items: appSlideItems, handleClick, navOnly }
    const _signInButtons = loggedIn ? { sob: signOutButton() } : { sib: signInButton() }
    return <NavMenu {...nmProps} signInButtons={_signInButtons} />
  }

  /** the BOTTOM portion of the menu */
  const bottomMenu = () => {
    const nvmProps = { gotoItems, allowSidebarView, pushSimpleItem, handleClick }
    return <NavSideBottomMenu {...nvmProps} />
  }

  /** The contents of the app sidebar */
  const contentMenu = () => <React.Fragment>
    {navMenu()}
    {bottomMenu()}
  </React.Fragment>

  /** the ADMIN portion of the menu */
  const adminMenu = () => {
    const smgProps = {
      caption: 'Admin',
      groupIcon: 'settings',
      groupKey: 'admin',
      iconColor: 'red',
      inverse: true,
      menuItemElements: sideItems(adminSlideItems),
    }
    if (allowSidebarView === gEnums.viewPermissionTypes.allow) {
      const x = <SettingsAreaGroupMenuItem key={uniqueKey('smgh', 'adm')} {...smgProps} />
      return <Menu className='adm inh msmi menu-groups three' borderless fluid inverted={true} vertical>{x}</Menu>
    }
  }

  const menuFlexWrap = (menuClassName, content) => <div className={'ct-vs app inh mfw ' + menuClassName}>
    {content}
  </div>

  const copyToClipboard = (text) => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea
    document.body.removeChild(textarea);
  };

  const flexWrap = () => <Wrapper
    content={contentMenu()}
    footer={_viewPermissionType === gEnums.viewPermissionTypes.allow ? menuFlexWrap('adm', adminMenu()) : null}
    css={{ container: 'ct-vs app inh fw app-sidebar-menu' }}
    style={sac ? sac.style : null}
  />

  const wrapper_appLink = () => <Wrapper
    content={<div><QRCode value={sharePath} appSidebarContentType={appSidebarContentType} qrCodeType={gEnums.qrCodeTypes.appLink} /></div>}
    footer={<Button fluid color={'blue'} onClick={() => { copyToClipboard(sharePath) }}>{'Copy'}</Button>}
  />

  const sidebarContent_content = () => {
    switch (appSidebarContentType) {
      case _appSidebarContentTypes.wifi:
        return <div>
          <div>{'Network: ' + wifiNetwork}</div>
          <div>{'Password: ' + wifiPassword}</div>
        </div>
      case _appSidebarContentTypes.appSharing:
        return wrapper_appLink()
      case _appSidebarContentTypes.notificationCheck:
        return <NotificationSettings />
      default:
        return <div>---</div>
    }
  }

  const wrapper_sidebarContent = () => <Wrapper
    header={_.startCase(appSidebarContentType)}
    content={sidebarContent_content()}
    footer={<Button fluid color={'blue'} onClick={() => { framework_handlers.handleShow_appSidebarContent() }}>Close</Button>}
    wrapperType={wrapperTypes.padded}
    css={{ content: 'qr-center', container: 'app-sidebar-menu' }}
  />

  if (navOnly) {
    return navMenu()
  } else {
    if (appSidebarContentType) {
      switch (appSidebarContentType) {
        case _appSidebarContentTypes.appSharing:
        case _appSidebarContentTypes.wifi:
        case _appSidebarContentTypes.notificationCheck:
          return wrapper_sidebarContent()
        default:
          return flexWrap()
      }
    } else {
      return flexWrap()
    }

  }

}

export default AppSidebarMenu