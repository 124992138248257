import _ from 'lodash'

export const _developerAdminEmail = 'philipaokeeffe@gmail.com'
// pokeeffe@meetingevolution.net

/**
 * 
 * @param {string} a 
 * @param {string} b 
 * @param {string} c 
 * @param {string} d 
 * @returns a key based on a,b,c and d
 */
export function uniqueKey(a, b, c, d, e) {
  let key;
  const sep = '-'
  if (a && (b || _.isNumber(b)) && (c || _.isNumber(c)) && (d || _.isNumber(d)) && (e || _.isNumber(e))) {
    key = a + sep + b + sep + c + sep + d + sep + e
  } else if (a && (b || _.isNumber(b)) && (c || _.isNumber(c)) && (d || _.isNumber(d))) {
    key = a + sep + b + sep + c + sep + d
  } else if (a && (b || _.isNumber(b)) && (c || _.isNumber(c))) {
    key = a + sep + b + sep + c
  } else if (a && (b || _.isNumber(b))) {
    key = a + sep + b
  } else if (a) {
    key = a
  } else {
    // console.log('a, b, c, d, e', a, b, c, d, e)
    key = 'uk'
    // return (Math.random().toString(36) + '00000000000000000').slice(2, 10);
  }
  switch (key) {
    case 'smd':
      console.log('smd', key)
      break;
    default:
    // nothing
  }
  return key
}  