import { gEnums } from '../../enums/globalEnums';
import { getBaseRef } from '../helpers/getBaseRef';
import { _profileCollectionName } from '../profiles/getProfile';
import { fsUpdate_addData } from './subUpdates/fsUpdate_addData';
import { fsUpdate_updateCollection } from './subUpdates/fsUpdate_updateCollection';
import { fsUpdate_updateAppUserDoc } from './subUpdates/fsUpdate_updateAppUserDoc';
import { fsUpdate_addListDoc } from './subUpdates/fsUpdate_addListDoc';
import { fsUpdate_deleteCollectionItems } from './subUpdates/fsUpdate_deleteCollectionItems';
import { fsUpdate_updateDoc } from './subUpdates/fsUpdate_updateDoc';
import { fsUpdate_mergeData } from './subUpdates/fsUpdate_mergeData';
import { fsUpdate_updateAccessRequest } from './subUpdates/fsUpdate_updateAccessRequest';
import { fsUpdate_updateData } from './subUpdates/fsUpdate_updateData';
import { fsUpdate_updateRating } from './subUpdates/fsUpdate_updateRating';
import { fsUpdate_updateSortedCollection } from './subUpdates/fsUpdate_updateSortedCollection';
import { fsUpdate_updateImageLinks } from './subUpdates/fsUpdate_updateImageLinks';

/** Funnel function for all firebase data
 * @function activate
 * @function add
 * @function ammendExisting
 * @function copy
 * @function createList
 * @function delete
 * @function edit
 * @function rate
 * @function imageLinks
 * @function updateAccessRequest
 * @function updates
 * @function updateSort
 */
export const updateFirestoreData = (dispatch, paps_state, ufProps, page_fns, appUser, dataRestrictions, opts) => {

  const { getGviDeps } = page_fns ?? {}
  const { dataUpdateType, swipedItem } = ufProps ?? {}
  let { vit } = ufProps ?? {}

  const { pathViews, storagePaths } = paps_state
  const viDeps = getGviDeps(vit)
  const baseRef = getBaseRef(viDeps, pathViews, null, swipedItem, null, null, ufProps)

  ufProps.viDeps = viDeps
  ufProps.dataRestrictions = dataRestrictions
  ufProps.storagePaths = storagePaths

  // special cases
  switch (vit) {
    case 'appProfiles':
      vit = _profileCollectionName
      break;
    default:
    // nothing
  }

  const allProps = {
    appUser,
    baseRef,
    dispatch,
    opts,
    pathViews,
    ufProps,
  }

  console.log('dataUpdateType', dataUpdateType)

  switch (dataUpdateType) {

    // the main functions
    case gEnums.dataUpdateTypes.addData:
      fsUpdate_addData(allProps)
      break;

    case gEnums.dataUpdateTypes.updateDoc:
      fsUpdate_updateDoc(allProps)
      break;

    case gEnums.dataUpdateTypes.updateCollection:
      fsUpdate_updateCollection(allProps)
      break;

    // the other functions
    case gEnums.dataUpdateTypes.addListDoc:
      fsUpdate_addListDoc(allProps)
      break;

    case gEnums.dataUpdateTypes.copy:
      return false;

    case gEnums.dataUpdateTypes.deleteCollectionItems:
      fsUpdate_deleteCollectionItems(allProps)
      break;

    case gEnums.dataUpdateTypes.mergeData:
      fsUpdate_mergeData(allProps)
      break;

    case gEnums.dataUpdateTypes.updateAccessRequest:
      fsUpdate_updateAccessRequest(allProps)
      break;

    case gEnums.dataUpdateTypes.updateAppUserDoc:
      fsUpdate_updateAppUserDoc(allProps)
      break;

    case gEnums.dataUpdateTypes.updateCollection_prop:
      fsUpdate_updateCollection(allProps, true)
      break;

    case gEnums.dataUpdateTypes.updateImageLinks:
      fsUpdate_updateImageLinks(allProps)
      break;

    case gEnums.dataUpdateTypes.updateRating:
      fsUpdate_updateRating(allProps)
      break;

    case gEnums.dataUpdateTypes.updateSortedCollection:
      fsUpdate_updateSortedCollection(allProps)
      break;

    // functions NOT IN USE
    case gEnums.dataUpdateTypes.updateData:
      fsUpdate_updateData(allProps)
      break;

    default:
    // nothing
  }
}