import _ from 'lodash';
import { getRecentAuths } from '../../common/filtering';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath_client, createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { fs_delete_doc, fs_set_doc } from '../../firestoreData/appData/fsData';
import { getAllClientProfiles } from '../../firestoreData/helpers/appProfileData';
import { _profileCollectionName } from '../../firestoreData/profiles/getProfile';
import { fsfn_auth } from '../../functions/fbAuth';
import { _currentAppUserCountsCollection, _currentAppUserCountsCollection_xxx } from './AppUserReducer';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

// export const _appUserCollections = {
//   profiles: 'profiles',
//   clientProfiles: 'profiles', 
// }


const _defaultDays = 7

export const appUserManagementInitialState = (initState) => {
  return { ...initState }
};

export const rts = {
  handleAmmend_auths: 'handleAmmend_auths',
  handleChange_appManagementType: 'handleChange_appManagementType',
  handleChange_profileSearch: 'handleChange_profileSearch',
  handleClose_appUser: 'handleClose_appUser',
  handleGet_allClientProfiles: 'handleGet_allClientProfiles',
  handleGet_currentUsers: 'handleGet_currentUsers',
  handleSearchChange: 'handleSearchChange',
  handleSelect_appUser: 'handleSelect_appUser',
  handleSet_allClientProfiles: 'handleSet_allClientProfiles',
  handleSet_allowModifications: 'handleSet_allowModifications',
  handleSet_auths: 'handleSet_auths',
  handleSet_currentUserCounts: 'handleSet_currentUserCounts',
  handleSet_profileType: 'handleSet_profileType',
  handleSet_staticAppUsers: 'handleSet_staticAppUsers',
  handleUpdate_clientProfiles: 'handleUpdate_clientProfiles',
  ...grts
}

export const showAppManagementTypes = {
  add: 'add',
  auth: 'auth',
  appUserCount: 'appUserCount',
  getClientProfiles: 'getClientProfiles',
  updateAppUserProfiles: 'updateAppUserProfiles',
  updateClientProfiles: 'updateClientProfiles',
  gcNotifications: 'gcNotifications',
  email: 'email',
}

export const userMenuItems = {
  add: 'add',
  checkAuthorizations: 'checkAuthorizations',
  appUserCount: 'appUserCount',
  getClientProfiles: 'getClientProfiles',
  search: 'search',
  sendGCNotifications: 'sendGCNotifications',
  sendSignInEmails: 'sendSignInEmails',
  updateAppUserProfiles: 'updateAppUserProfiles',
  updateClientProfiles: 'updateClientProfiles',
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 * @rts (...rts)
 */
export const appUserManagementReducer = (state, action) => {

  const {
    allClientProfiles,
    appProfileData,
    appUserCollection,
    authsList,
    foundAll,
    pathViews,
    selectedItems,
    staticAppUsers,
  } = state

  const { dispatch } = action

  const { handleSet_currentUserCounts, handleSet_allClientProfiles, handleSet_auths, handleStartUpdate } = appUserManagementHandlers(dispatch)

  switch (action.type) {

    case rts.handleAmmend_auths:
      if (authsList && staticAppUsers) {
        const z = ammendAuths({ found: foundAll }, staticAppUsers, action.dayCount)
        console.log('z', action.dayCount, z)
        return { ...state, ...z }
      }
      return { ...state }

    case rts.handleSet_staticAppUsers:
      return { ...state, staticAppUsers: action.staticAppUsers }

    case rts.handleChange_appManagementType:
      const { item } = action
      let _appManagementType;
      switch (item) {
        case userMenuItems.appUserCount:
          _appManagementType = showAppManagementTypes.appUserCount
          break;
        case userMenuItems.add:
          _appManagementType = showAppManagementTypes.add
          break;
        // case userMenuItems.topicManagement:
        //   _appMangementType = showAppManagementTypes.topicManagement
        //   break;
        case userMenuItems.checkAuthorizations:
          // _appMangementType = showAppManagementTypes.auth 
          handleStartUpdate()
          getAuths(pathViews, staticAppUsers, handleSet_auths)
          break;
        case userMenuItems.sendSignInEmails:
          _appManagementType = showAppManagementTypes.email
          break;
        case userMenuItems.getClientProfiles:
          _appManagementType = showAppManagementTypes.getClientProfiles
          break;
        case userMenuItems.updateAppUserProfiles:
          _appManagementType = showAppManagementTypes.updateAppUserProfiles
          break;
        case userMenuItems.updateClientProfiles:
          _appManagementType = showAppManagementTypes.updateClientProfiles
          break;
        case userMenuItems.sendGCNotifications:
          _appManagementType = showAppManagementTypes.gcNotifications
          break;
        default:
          break;
      }
      return { ...state, appManagementType: _appManagementType }

    case rts.handleGet_allClientProfiles:
      getAllClientProfiles(pathViews, pathViews.events, appUserCollection, handleSet_allClientProfiles)
      return { ...state }

    case rts.handleSet_allClientProfiles:
      const { profiles_combined } = action.results ?? {}
      const _clientProfiles = profiles_combined ? profiles_combined : null
      console.log('_clientProfiles', _clientProfiles)
      return { ...state, updating: false, allClientProfiles: action.results, selectedItems: _clientProfiles, showFooter: true }

    case rts.handleSet_auths:
      const { results } = action
      const x = ammendAuths(results, staticAppUsers, _defaultDays)
      return { ...state, updating: false, ...x }

    case rts.handleSearchChange:
      if (action.searchValue) {
        const f = _.filter(selectedItems, function (i) {
          return (action.searchValue && i.lastName && i.lastName.toLowerCase().startsWith(action.searchValue.toLowerCase())) ||
            (action.searchValue && i.firstName && i.firstName.toLowerCase().startsWith(action.searchValue.toLowerCase()));
        });
        return {
          ...state,
          filteredItems: f,
        }
      } else {
        return {
          ...state,
          filteredItems: null,
          selectedItems: selectedItems
        }
      }

    case rts.handleChange_profileSearch:
      if (action.searchValue) {
        const f = _.filter(appProfileData, function (i) {
          return (i.lastName && i.lastName.toLowerCase().startsWith(action.searchValue)) ||
            (i.firstName && i.firstName.toLowerCase().startsWith(action.searchValue));
        });
        return {
          ...state,
          filteredItems: f,
        }
      } else {
        return {
          ...state,
          filteredItems: null,
        }
      }

    case rts.handleSelect_appUser:
      if (action.appUserKey) {
        const appUser = selectedItems && selectedItems[action.appUserKey] ? selectedItems[action.appUserKey] : action.appUser
        return {
          ...state,
          selectedAppUserKey: action.appUserKey,
          selectedAppUser: appUser
        }
      } else {
        return {
          ...state,
          selectedAppUserKey: null,
          selectedAppUser: null,
        }
      }

    case rts.handleClose_appUser:
      return { ...state, selectedAppUserKey: null, selectedAppUser: null, }

    case rts.handleSet_profileType:
      const { appProfileData: apd } = action
      const _selectedItems = state.vld ? state.vld : apd[action.profileType]
      console.log('staticAppUsers', staticAppUsers)
      return { ...state, profileType: action.profileType, selectedItems: staticAppUsers, appProfileData: apd }

    case rts.handleUpdate_clientProfiles:
      if (allClientProfiles) {
        const { merge, remove } = action
        const { profiles_combined, profiles_delete } = allClientProfiles
        if (remove) {
          removeClientProfiles(profiles_delete, pathViews, merge).then(res => {
            dispatch && dispatch({ type: rts.updateSuccess, dispatch })
          }).catch(error => {
            dispatch && dispatch({ type: rts.updateError, dispatch })
          })
        } else {
          updateClientProfiles(profiles_combined, pathViews, merge).then(res => {
            dispatch && dispatch({ type: rts.updateSuccess, dispatch })
          }).catch(error => {
            dispatch && dispatch({ type: rts.updateError, dispatch })
          })
        }
      }
      return { ...state }

    case rts.handleGet_currentUsers:

      const cbcau = (data) => {
        if (data) { handleSet_currentUserCounts(data) }
      }

      const cbcXXX = (data) => {
        let _xxx = 0
        _.each(data, (value) => {
          _xxx += Object.keys(value).length
        })
        console.log('_xxx', _xxx)
      }

      const _refPathCau = createRefPath_event(pathViews, [_currentAppUserCountsCollection])
      fs_get_data({ refPath: _refPathCau, callback: cbcau, opts: { ignoreId: true } })

      const _refPathXXX = createRefPath_event(pathViews, [_currentAppUserCountsCollection_xxx])
      fs_get_data({ refPath: _refPathXXX, callback: cbcXXX, opts: { ignoreId: true } })
      return { ...state }

    case rts.handleSet_currentUserCounts:
      let _currentUserCount = 0
      const { currentUserCounts } = action
      if (currentUserCounts) {
        _.each(currentUserCounts, (value) => {
          _.each(value, (value2) => {
            _currentUserCount += value2
          })
        })
      }
      return { ...state, currentUserCounts, currentUserCount: _currentUserCount }

    case rts.handleSet_allowModifications:
      return { ...state, allowAppUserModification: action.allowModification }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUserManagementHandlers = (dispatch) => {
  return {
    handleAmmend_auths: (dayCount) => dispatch({ type: rts.handleAmmend_auths, dispatch, dayCount }),
    handleChange_appManagementType: (item) => dispatch({ type: rts.handleChange_appManagementType, dispatch, item }),
    handleChange_profileSearch: (searchValue) => dispatch({ type: rts.handleChange_profileSearch, dispatch, searchValue }),
    handleClose_appUser: () => dispatch({ type: rts.handleClose_appUser, dispatch }),
    handleGet_allClientProfiles: (eventKey) => dispatch({ type: rts.handleGet_allClientProfiles, dispatch, eventKey }),
    handleGet_currentUsers: () => { dispatch({ type: rts.handleGet_currentUsers, dispatch }) },
    handleSearchChange: (searchValue) => dispatch({ type: rts.handleSearchChange, dispatch, searchValue }),
    handleSelect_appUser: (appUser, appUserKey) => dispatch({ type: rts.handleSelect_appUser, dispatch, appUser, appUserKey }),
    handleSet_allClientProfiles: (results) => dispatch({ type: rts.handleSet_allClientProfiles, dispatch, results }),
    handleSet_allowModifications: (allowModification) => dispatch({ type: rts.handleSet_allowModifications, dispatch, allowModification }),
    handleSet_auths: (results) => dispatch({ type: rts.handleSet_auths, dispatch, results }),
    handleSet_currentUserCounts: (currentUserCounts) => { dispatch({ type: rts.handleSet_currentUserCounts, dispatch, currentUserCounts }) },
    handleSet_profileType: (appProfileData, profileType) => dispatch({ type: rts.handleSet_profileType, dispatch, appProfileData, profileType }),
    handleSet_staticAppUsers: (staticAppUsers) => dispatch({ type: rts.handleSet_staticAppUsers, dispatch, staticAppUsers }),
    handleUpdate_clientProfiles: (merge, remove) => dispatch({ type: rts.handleUpdate_clientProfiles, dispatch, merge, remove }),
    ...responseHandlers(dispatch)
  }
}

/**
 * Updates (merge) all of the clientProfiles
 * @param {object} clientProfiles 
 * @param {object} pathViews 
 * @returns a promise of all the fs_set_doc
 */
const updateClientProfiles = async (clientProfiles, pathViews, merge) => {

  const useTemp = false
  const collectionName = useTemp ? '_' + _profileCollectionName : _profileCollectionName

  const promises = []
  Object.keys(clientProfiles).forEach((key, index) => {
    const cp = clientProfiles[key]
    const { email } = cp ?? {}
    delete cp._itemKey
    delete cp.intials
    const _refPath = createRefPath_client(pathViews, [collectionName, email])
    promises.push(fs_set_doc(_refPath, cp, merge, null, true))
  })
  return Promise.all(promises)
}

/**
 * Deletes a document for each of the clientProfiles
 * @param {object} clientProfiles 
 * @param {object} pathViews  
 * @returns 
 */
const removeClientProfiles = async (clientProfiles, pathViews) => {

  const useTemp = false
  const collectionName = useTemp ? '_' + _profileCollectionName : _profileCollectionName

  const promises = []
  Object.keys(clientProfiles).forEach((key, index) => {
    const cp = clientProfiles[key]
    delete cp._itemKey
    delete cp.intials
    const _refPath = createRefPath_client(pathViews, [collectionName, key])
    promises.push(fs_delete_doc(_refPath, null, true))
  })
  return Promise.all(promises)
}


const getAuths = (pathViews, staticAppUsers, callback) => {
  const limit = 100
  if (staticAppUsers) {
    const _staticAppUsers = _.sortBy(staticAppUsers, 'lastName')
    const chunks = _.chunk(_staticAppUsers, limit);
    fsfn_auth.getAuthsByChunkEmail(chunks, pathViews, true, callback)
  }
}

const ammendAuths = (results, staticAppUsers, dayCount) => {
  const { found } = results
  if (found && staticAppUsers) {
    const recent = getRecentAuths(found, dayCount)
    _.each(staticAppUsers, (_staticAppUser, key) => {
      const { email } = _staticAppUser
      const _af = email ? _.find(found, { email: email.toLowerCase() }) : null
      const _ar = email ? _.find(recent, { email: email.toLowerCase() }) : null
      _staticAppUser.foundType = gEnums.authListTypes.notFound
      if (_af) { _staticAppUser.foundType = gEnums.authListTypes.found }
      if (_ar) { _staticAppUser.foundType = gEnums.authListTypes.recent }
      if (_af || _ar) {
        _staticAppUser.metadata = _af.metadata
      }
    })
    const _staticAppUsersEm = _.map(staticAppUsers, (_staticAppUser, key) => ({
      email: _staticAppUser.email,
      foundType: _staticAppUser.foundType
    }))
    const authsList = _.groupBy(staticAppUsers, 'foundType')
    const authsListEm = _.groupBy(_staticAppUsersEm, 'foundType')
    return { authsList, authsListEm, foundAll: found }
  }
  return {}
}

