import _ from 'lodash';
import { ammendSchoolName } from '../../../projectSpecific/sports/cnr/reducers/CreateSportsReducer';
import { fsfn_fetchTableData, fsfn_fetchTableData_multi } from '../../functions/fbFetchFunctions';
import { fixAddress } from '../../common/convert';

const excludes = ['Middle School', 'Junior High School', 'Jr. High', 'Intermediate']

const replacements = [
  'HIGH SCHOOL',
  'High School',
  'Junior Senior',
  'Junior/Senior',
  'Junior-Senior',
  'Jr/Sr',
  'Greater',
  'Senior',
  'INTERMEDIATE CHARTER SCHOOL',
]

const areaSchools = [
  'Ambridge Area',
  'Avella Area',
  'Beaver Area',
  'Belle Vernon Area',
  'Brownsville Area',
  'Butler Area',
  'Burgettstown Area',
  'California Area',
  'Carmichaels Area',
  'Charleroi Area',
  'Connellsville Area',
  'Fox Chapel Area',
  'Freedom Area',
  'Freeport Area',
  'Hempfield Area',
  'Indiana Area',
  'Leechburg Area',
  'Mars Area',
  'McKeesport Area',
  'Mohawk Area',
  'Moon Area',
  'Mt. Pleasant Area',
  'New Brighton Area',
  'New Castle Area',
  'Rochester Area',
  'Uniontown Area',
  'West Mifflin Area',
]

const academySchools = [
  'Eden Christian Academy',
  'Imani Christian Academy',
]

const startCaseSchools = [
  'PROPEL'
]

export const getStateResults = async (districtId, callback) => {

  // const districtCount = 12
  // const url = 'http://www.piaa.org/schools/directory/list.aspx'
  // const alphabet = Array.from({ length: 26 }, (_, index) => String.fromCharCode(65 + index));
  // const numbers = Array.from({ length: districtCount }, (_, index) => index + 1);
  // const urlData = { url, arrayName1: 'district', array1: numbers, arrayName2: 'alpha', array2: alphabet }
  // const _fetchResult = await fsfn_getStateSchools(urlData)
  // console.log('_fetchResult', _fetchResult)

  const _fetchResult = await getPaStateSchools(districtId)

  // const _fetchResult = await getStateSchools()

  if (_fetchResult) {
    const combinedArrays = _.union(...Object.values(_fetchResult));
    const uniqueSortedArrays = _.sortBy(_.uniq(combinedArrays));
    const arrayOfObjects = uniqueSortedArrays.map(school => ({
      fullName: school.name,
      name: reducedName(school.name),
      address: fixAddress(school.address),
      schoolKey: school.schoolKey,
      district: school.district,
    }));
    callback(arrayOfObjects)
    // getStatePiaaSchoolData(arrayOfObjects, callback)
  }
}

const getPaStateSchools = (districtId) => {
  const promises = []
  const districtCount = 12
  const url = 'http://www.piaa.org/schools/directory/list.aspx'
  const numbers = districtId ? [districtId] : Array.from({ length: districtCount }, (_, index) => index + 1);
  const alphabet = Array.from({ length: 26 }, (_, index) => String.fromCharCode(65 + index));
  numbers.forEach(number => {
    // alphabet.forEach(alpha => {
    promises.push(getStatePiaaData(number))
    // })
  })
  return Promise.all(promises)
}

const getStateSchools = async () => {
  const schoolCategories = [
    'Public_high_schools_in_Pennsylvania',
    'Private_high_schools_in_Pennsylvania',
    'Preparatory_schools_in_Pennsylvania',
    'Catholic_secondary_schools_in_Pennsylvania',
  ]
  const schoolKeys = ['A', 'H', 'S']
  const promises = []
  // schoolCategories.forEach(sc => {
  //   schoolKeys.forEach(async letter => {
  //     promises.push(getStateData(sc, letter))
  //   })
  // })

  const districtCount = 12
  const url = 'http://www.piaa.org/schools/directory/list.aspx'
  const alphabet = Array.from({ length: 26 }, (_, index) => String.fromCharCode(65 + index));
  const numbers = Array.from({ length: districtCount }, (_, index) => index + 1);
  console.log('alphabet', alphabet)
  console.log('numbers', numbers)

  // ?alpha=' + letter + '&district=' + dc
  const urlDataF = { url, arrayName1: 'alpha', array1: alphabet, arrayName2: 'district', array2: numbers }
  const xxx = await fsfn_fetchTableData_multi(urlDataF)
  const { result } = xxx ?? {}
  const schoolz = []

  if (result) {

    result.forEach(r => {

      const parser = new DOMParser()

      const doc = parser.parseFromString(r, 'text/html')

      if (doc) {
        const schoolList = doc.querySelectorAll('dl.schoolBlock')

        schoolList.forEach(sl => {
          const dt = sl.querySelectorAll('dt')
          const dd = sl.querySelectorAll('dd')
          if (dt && dt.length === 1) {
            const schoolName = dt[0].innerText
            let allow = true
            excludes.forEach(ex => {
              if (schoolName.toLowerCase().includes(ex.toLowerCase())) {
                allow = false
              }
            })
            if (allow) {
              schoolz.push({
                name: schoolName,
                address: dd && dd.length > 0 ? dd[0].innerText : null,
                schoolKey: dd && dd.length > 0 && dd[1] ? dd[1].id : null,
                // district: dc
              })
            }
          }
        })
      }
    })
  }
  console.log('schoolz', _.sortBy(schoolz, 'name'))

  // for (var dc = 1; dc <= districtCount; dc++) {
  //   'ABCDEFGHIJKLMNOPQRSTUVWY'.split('').map(async letter => {
  //     promises.push(getStatePiaaData(dc, letter))
  //   })
  // }


  // return Promise.all(promises)

}

const reducedName = (name) => {
  let _name = name
  replacements.forEach(repl => {
    _name = _name.replace(repl, '')
  })
  _name = _name.replace('Mount', 'Mt.')
  _name = _name.replace('Saint', 'St.')
  _name = _name.replace('Mc ', 'Mc')
  _name = _name.replace('-', ' ')
  _name = _name.split(' (')[0]
  _name = _name.trim()
  _name = ammendSchoolName(_name)

  if (areaSchools.includes(_name)) { _name = _name.replace(' Area', '') }
  if (academySchools.includes(_name)) { _name = _name.replace(' Academy', '') }

  if (_name === 'PROPEL BRADDOCK HILLS') {
    console.log('first', _name)
  }
  if (startCaseSchools.includes(_name.split(' ')[0].trim())) { _name = _.startCase(_name.toLowerCase()) }
  // _name = _.startCase(_name)
  return _name
}

const getStateData = async (sc, letter) => {

  let _url = 'https://en.wikipedia.org/wiki/Category:' + sc

  if (_url) {
    const urlDataF = { url: _url, params: { from: letter } }

    return await fsfn_fetchTableData(urlDataF).then(response_data => {

      const schoolz = []
      const { result } = response_data ?? {}

      const parser = new DOMParser()
      const doc = parser.parseFromString(result, 'text/html')

      if (doc) {
        const divs = doc.querySelectorAll('div.mw-category-group')
        if (divs) {
          divs.forEach(div => {
            const uls = div.querySelectorAll('ul')
            if (uls) {
              uls.forEach(ul => {
                const lis = ul.querySelectorAll('li')
                if (lis) {
                  lis.forEach(li => {
                    schoolz.push(li.innerText)
                  })
                }
              })
            }
          })
        }
      }
      return schoolz
    }).catch(error => {
      console.log('error', error)
      return {}
    })
  } else {
    return {}
  }
}

const getStatePiaaData = async (dc, letter) => {

  let _url = letter ?
    'http://www.piaa.org/schools/directory/list.aspx?district=' + dc + '&letter=' + letter
    :
    'http://www.piaa.org/schools/directory/list.aspx?district=' + dc


  if (_url) {
    const urlDataF = { url: _url }

    return await fsfn_fetchTableData(urlDataF).then(response_data => {

      const schoolz = []
      const { result } = response_data ?? {}

      const parser = new DOMParser()
      const doc = parser.parseFromString(result, 'text/html')

      if (doc) {
        const schoolList = doc.querySelectorAll('dl.schoolBlock')

        schoolList.forEach(sl => {
          const dt = sl.querySelectorAll('dt')
          const dd = sl.querySelectorAll('dd')
          if (dt && dt.length === 1) {
            const schoolName = dt[0].innerText
            let allow = true
            excludes.forEach(ex => {
              if (schoolName.toLowerCase().includes(ex.toLowerCase())) {
                allow = false
              }
            })
            if (allow) {
              schoolz.push({
                name: schoolName,
                address: dd && dd.length > 0 ? dd[0].innerText : null,
                schoolKey: dd && dd.length > 0 && dd[1] ? dd[1].id : null,
                district: dc
              })
            }
          }
        })
      }

      return schoolz

    }).catch(error => {
      console.log('error', error)
      return {}
    })
  } else {
    return {}
  }
}

