import _ from 'lodash'
import { getArrayIndex } from '../../common/sorting'
import { currentHelpers } from '../../redirection/current'

export const sidebarMenuTypes = {
  one: 'one',
  two: 'two',
  three: 'three',
  four: 'four',
  five: 'five',
  six: 'six',
  menuKeys: 'menuKeys',
  date: 'date',
  week: 'week',
  level: 'level',
  teamCount: 'teamCount',
}

export const sidebarSliderTypes = {
  normal: 'normal',
  normalNoLr: 'normalNoLr',
  labeled: 'labeled',
  tabbed: 'tabbed',
  calendar: 'calendar'
}

const sidebarTypes = {
  handleCloseSidebar: 'handleCloseSidebar',
  handleGenericItemClick: 'handleGenericItemClick',
  handleGenericMenuClick: 'handleGenericMenuClick',
  handleItemClick: 'handleItemClick',
  handleSetContent: 'handleSetContent',
  setInit: 'setInit',
  setInits: 'setInits',
}

export const sidebarReducer = (state, action) => {

  const { handlers, sidebarType, sidebar_items, storageItemPage } = state ?? {}

  const { type, index, key, clickType, directItem } = action

  let sbItemType = null

  const ms_clickType = 'ms_' + clickType
  const _showClick = 'show' + _.startCase(clickType)
  const _sbItemType = '_' + clickType

  const selectedSidebarKey = ms_clickType
  const selectedSidebar = sidebar_items ? sidebar_items[ms_clickType] : {}
  let { items, selected } = selectedSidebar ? selectedSidebar : []

  const _storageKey = storageItemPage ? storageItemPage + '_' + ms_clickType : ms_clickType

  sbItemType = clickType

  const sbis = sidebar_items ?? {}

  const hideAll = () => {
    const si = sidebar_items ? { ...sidebar_items } : {}
    Object.keys(si).forEach(key => {
      si[key].show = false
    })
    return si
  }
  switch (type) {

    case sidebarTypes.setInits:
      const { sidebarItems } = action
      if (sidebarItems) {
        sidebarItems.forEach(sbi => {
          ammendInits(sbis, sbi)
        })
        return { ...state, sidebar_items: sbis }
      }
      return { ...state }

    case sidebarTypes.setInit:
      const { sidebarItem } = action
      if (sidebarItem.testOnly) {
        return { ...state }
      } else {
        ammendInits(sbis, sidebarItem)
        return { ...state, sidebar_items: sbis }
      }

    case sidebarTypes.handleCloseSidebar:
      return {
        ...state,
        sidebarItems: hideAll(),
        dimmed: false,
      }

    case sidebarTypes.handleItemClick:
      if (items) {
        selected.index = getArrayIndex(items, action.value)
        selected.item = action.value
        selected.itemKey = _.camelCase(selected.item)
        return { ...state, [selectedSidebarKey]: selectedSidebar }
      } else {
        return { ...state }
      }

    // Click of the items selections
    case sidebarTypes.handleGenericItemClick:

      sbItemType = state[_sbItemType]

      selectedSidebar.show = false

      if (selected) {
        if (directItem) {
          const direct = getSelectedGenericDirect(directItem, items)
          selected.index = direct.index
          selected.item = direct.item
          selected.itemKey = _.camelCase(selected.item)
        } else {
          selected.index = directItem ? 0 : index
          selected.item = directItem ? directItem : getSelectedGeneric(index, items)
          selected.itemKey = _.camelCase(selected.item)
        }

        selected.visible = false

        if (handlers && handlers.handleSetSidebar) { handlers.handleSetSidebar(clickType, selected, sidebarType, sbItemType) }
        currentHelpers.storageItem_set(_storageKey, selected.item)
        return { ...state, [selectedSidebarKey]: selectedSidebar, [_showClick]: false, dimmed: false }

      } else {
        return {
          ...state,
          sidebarItems: hideAll(),
        }
      }

    case sidebarTypes.handleGenericMenuClick:

      sbItemType = clickType

      if (selected) {
        const maxTypes = items.length

        switch (key) {
          case 'back':
            selected.index--
            if (selected.index < 0) { selected.index = maxTypes - 1 }
            selected.item = getSelectedGeneric(selected.index, items)
            selected.itemKey = _.camelCase(selected.item)
            break;
          case 'forward':
            selected.index++
            if (selected.index >= maxTypes) { selected.index = 0 }
            selected.item = getSelectedGeneric(selected.index, items)
            selected.itemKey = _.camelCase(selected.item)
            break;
          case 'pick':
          case 'user':
            selected.visible = !selected.visible
            break;
          default:
          // nothing
        }

        switch (key) {
          case 'pick':
            const _show = state[_showClick]
            selectedSidebar.show = !selectedSidebar.show
            currentHelpers.storageItem_set(_storageKey, selected.item)
            return { ...state, [_showClick]: !_show, dimmed: !_show, [selectedSidebarKey]: selectedSidebar }

          default:
            currentHelpers.storageItem_set(_storageKey, selected.item)
            if (handlers && handlers.handleSetSidebar) { handlers.handleSetSidebar(clickType, selected, sidebarType, sbItemType) }
            return { ...state, [selectedSidebarKey]: selectedSidebar }

        }
      }

      return { ...state }

    case sidebarTypes.handleSetContent:
      return { ...state, contents: action.contents }

    default:
      return { ...state }
  }
}

export const sidebarInitialState = (initState) => {
  return { ...initState, currents: {} }
};

export const sidebarHandlers = (dispatch) => {
  return {
    setInit: (sidebarItem) => { dispatch({ type: sidebarTypes.setInit, sidebarItem }) },
    setInits: (sidebarItems) => { dispatch({ type: sidebarTypes.setInits, sidebarItems }) },
    handleCloseSidebar: () => { dispatch({ type: sidebarTypes.handleCloseSidebar }) },
    handleGenericItemClick: (index, key, clickType, directItem) => { dispatch({ type: sidebarTypes.handleGenericItemClick, index, key, clickType, directItem }) },
    handleGenericMenuClick: (key, clickType) => { dispatch({ type: sidebarTypes.handleGenericMenuClick, key, clickType }) },
    handleItemClick: (clickType, value) => { dispatch({ type: sidebarTypes.handleItemClick, clickType, value }) },
    handleSetContent: (contents) => { dispatch({ type: sidebarTypes.handleSetContent, contents }) },
  }
}

const ammendInits = (sbis, sidebarItem) => {
  const { as, remove } = sidebarItem
  if (remove) {
    delete sbis['ms_' + as]
  } else {
    const ti = trueInit(sidebarItem)
    sbis['ms_' + as] = ti

  }
}

const trueInit = (sidebarItem) => {

  const { as, smt, items: items_init, itemz: itemz_init, currents, sidebarSliderType, useStartCase, showToday, showMissing, hidden, includeSearch } = sidebarItem

  const _currentKey = as ? as : smt

  const _default = {
    index: 0,
    item: items_init ? items_init[0] : {},
    itemKey: items_init ? _.camelCase(items_init[0]) : {},
    visible: false,
  }

  const _current = currents && currents[_currentKey]
  const _selected = _current ? _current : _default

  return {
    keyAs: as ? as : 'test',
    hidden,
    items: items_init,
    itemz: itemz_init,
    selected: _selected,
    showMissing,
    showToday,
    includeSearch,
    sidebarSliderType,
    useStartCase,
    smt
  }
}

const getSelectedGeneric = (selectedIndex, items) => {
  let selectedItem = null
  items.forEach((key, index) => {
    if (index === selectedIndex) {
      selectedItem = key
    }
  })
  return selectedItem
}

const getSelectedGenericDirect = (selectedKey, items) => {
  let selectedItem = null
  let selectedIndex = 0
  items.forEach((key, index) => {
    if (key === selectedKey) {
      selectedItem = key
      selectedIndex = index
    }
  })
  return {
    item: selectedItem,
    index: selectedIndex
  }
}