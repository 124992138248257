import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PendingFetch from '../../components/alerts/pendings/PendingFetch';
import { gEnums } from '../../enums/globalEnums';
import { settingsAreaFunctions, settingsAreaHandlers, settingsAreaInitialState, settingsAreaReducer } from '../reducers/SettingsAreaReducer';
import GlobalProvider from './GlobalContext';
import { SettingParentContext } from './SettingParentContext';
import { FrameworkContext } from './FrameworkContent';
import { frameworkRightTypes } from '../reducers/FrameworkReducer';

export const firstItems = ['client', 'event', 'quickLinks']

export const menuClassNames = {
  menuHeader: null,
  menu: 'msmi menu-groups',
}

/**
* @description This is the upper most Component/Provider for Settings
* @state settingsArea_state (`saSplits`, `allow`, `appArea`, `appUser_fns`, `dndGroups`, `ddGroupsP`, `groupSettings`, `homeSettings_global`, `isGlobal`, `isSolo`, `nonLandingView`, `pathViews`, `permissionGroupsNamed`, `productionSettings`, `projectOptions`, `selectedGroupItem`, `settingsGroupsByGroupType`, `settingsMenuType`, `settingsOptions`, `settingsOriginType`)
* @handlers settingsArea_handlers (`handleAppAreaChange`, `handleCloseGroupItem`, `handleOpenGroupItem`)
* @functions settingsArea_fns (`getCurrentConsoleSettings`, `getSelectedGroupItem`, `ammendDataGroupItem`, `getSettingsBaseItem`) 
 * 
 */
export const SettingsAreaContext = createContext();

/**
 * 
 * @param {object} props (isGlobal, showLeftSettings, isSolo)
 * @returns SettingsAreaContext (settingsArea_state, settingsArea_handlers, settingsArea_fns)
 */
const SettingsAreaProvider = (props) => {

  const { vssProps } = props

  const { isGlobal, showLeftSettings, isSolo } = vssProps

  // settingsParentContext
  const settingsParentContext = useContext(SettingParentContext)
  const { states, settings, fns } = settingsParentContext ?? {}
  const { baseSettings_state, appSettings_state, help_state, paps_state, page_state, settingsFramework_state } = states ?? {}
  const { goBig, leftSidebarGroupKey, leftSidebarGroupItem, rightSidebarGroupKey, rightSidebarGroupItem, settingsMenuType } = settingsFramework_state ? settingsFramework_state : {}
  const { appArea, view, pathViews, nonLandingView } = paps_state ?? {}
  const { helpDocs, _helpDocs } = help_state ?? {}
  const { appArea: appArea_bs } = baseSettings_state ?? {}
  const { globalSettingsOn, globalSettingsOpts, pageSettingsPage, helpOn } = appSettings_state ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkRightType } = framework_state ?? {}

  const sidebarGroupKey = isGlobal ? leftSidebarGroupKey : rightSidebarGroupKey
  const sidebarGroupItem = isGlobal ? leftSidebarGroupItem : rightSidebarGroupItem

  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { productionSettings, projectOptions, pageOptions, projectModules } = aps_global ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { settingsOptions } = homeSettings_global ?? {}

  const { appUser_fns } = fns

  // constants 
  let selectedGroupItem;
  let allow = isGlobal ? showLeftSettings : true
  if (globalSettingsOn && globalSettingsOpts) { selectedGroupItem = globalSettingsOpts }
  if (frameworkRightType && frameworkRightType === frameworkRightTypes.pageSettingsFull && pageSettingsPage) { selectedGroupItem = pageSettingsPage }
  if (goBig && (leftSidebarGroupKey || rightSidebarGroupKey)) { allow = false }

  allow = true

  const appAreaStates = {
    projectModules,
    projectOptions,
    pageOptions,
    settingsOptions,
    productionSettings
  }

  // IMPORTANT: Settings - this needs to be updated when the pageArea changed 
  const init_state = {
    appUser_fns,
    allow,
    helpDocs,
    _helpDocs,
    helpOn,
    homeSettings_global,
    isGlobal,
    isSolo,
    nonLandingView,
    pathViews,
    selectedGroupItem,
    settingsMenuType,
    settingsOriginType: (isSolo ? gEnums.settingsOriginTypes.normal : gEnums.settingsOriginTypes.normal),
  }

  // settingsArea_state
  const [settingsArea_state, settingsArea_dispatch] = useReducer(settingsAreaReducer, init_state, settingsAreaInitialState)
  const settingsArea_handlers = settingsAreaHandlers(settingsArea_dispatch, settingsArea_state)
  const { appArea: appArea_sa, saSplits } = settingsArea_state ?? {}

  const statesInSync = (appArea === appArea_bs) && (appArea_bs === appArea_sa)

  const settingsArea_fns = () => settingsAreaFunctions(settingsArea_state)

  const pendingCaption = isGlobal ? _.startCase(appArea) + ' Global Settings...' : _.startCase(view) + ' Page Settings...'

  // when the appArea changes, run this
  useEffect(() => {
    if (appArea_bs) {
      settingsArea_handlers.handleAppAreaChange(appArea_bs, isGlobal, baseSettings_state, appAreaStates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appArea_bs, settingsOptions]);

  useEffect(() => {
    settingsArea_handlers.handleOpenGroupItem(sidebarGroupKey, sidebarGroupItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarGroupKey]);

  const settingsContextProvider = () => <GlobalProvider>
    <SettingsAreaContext.Provider value={{ settingsArea_state, settingsArea_handlers, settingsArea_fns: settingsArea_fns() }}>
      {props.children}
    </SettingsAreaContext.Provider>
  </GlobalProvider >

  return statesInSync && saSplits ?
    settingsContextProvider()
    :
    <PendingFetch inverted={true} text={pendingCaption} />

};

export default SettingsAreaProvider