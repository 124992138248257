
import { dataFix } from "../../common/dataFix";
import { createRefPath } from "../appData/appRefPaths";
import { createFsDocKey } from "../appData/fsAppData";
import { doc_add, doc_set } from "../appData/fsData";

/**
 * add the `dataItems` to a `collection`
 * @param {*} refPath 
 * @param {*} vit 
 * @param {*} directCollection 
 * @param {*} dataItems 
 * @param {*} useAutoKey 
 * @returns Promise.all(promises)
 * @description Creates an array of add_ref promises 
 */
export const addPromise = (refPath, vit, directCollection, dataItems, useAdd, useAutoKey) => {
  const promises = []
  Object.keys(dataItems).forEach(itemKey => {
    if (itemKey) {
      try {
        const item = dataItems[itemKey]
        dataFix.deleteObjectItems(item, ['uid', 'id', 'key', '_itemKey', 'checked'])
        dataFix.deleteUTCs(item)
        const coll = directCollection ? directCollection : vit
        if (useAdd) {
          let _addPath = createRefPath([coll], refPath)
          const r = doc_add(_addPath, item)
          promises.push(r)
        } else {
          let _setPath = createRefPath([coll, useAutoKey ? createFsDocKey() : itemKey], refPath)
          const r = doc_set(_setPath, item)
          promises.push(r)
        }
      } catch (error) {
        console.error('addPromise', error)
      }
    }
  })
  return Promise.all(promises)
}