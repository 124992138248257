import _ from 'lodash'
import React from 'react'
import { Label } from 'semantic-ui-react'
import { uniqueKey } from '../../../../global/common/keys'

const MatchPlayoffPending = (props) => {

  const { matches, handleSelectedMatchKeys, selectedMatchKeys } = props ?? {}

  const teamText = (teamName, text) => {
    if (text) {
      const parts = text.split(new RegExp(`(${teamName})`, 'gi'));
      return parts.map((part, index) => {
        if (part.toLowerCase() === teamName.toLowerCase()) {
          return <span key={index} className='ts'>{part}</span>
        }
        return <span key={index}>{part}</span>
      })
    } else {
      return '---'
    }
  }

  const label = (_itemKey, index, value, color, circular) => <Label key={uniqueKey('mpp', _itemKey, index)} size='tiny' circular={circular} color={color}>{value}</Label>

  const content_matchUpdates = () => {

    const lis = []

    if (matches) {

      const _matches = _.orderBy(matches, ['levels', 'matchNumber'], ['desc', 'asc'])

      _matches.forEach((m, index) => {

        const { levels, away, home, _itemKey, poolKey, matchNumber, _homeAlreadyExists, _awayAlreadyExists, isConsolation, startDate } = m ?? {}

        const { name: name_away, seed: seed_away, _existingInfo: _existingInfo_away } = away ?? {}
        const { name: name_home, seed: seed_home, _existingInfo: _existingInfo_home } = home ?? {}

        const { full: _full_away, exists: _exists_away } = _existingInfo_away ?? {}
        const { full: _full_home, exists: _exists_home } = _existingInfo_home ?? {}

        const selected = selectedMatchKeys && selectedMatchKeys.includes(_itemKey)

        let cn = 'matches-pending-match'
        if (selected) { cn += ' selected' }

        lis.push(<div className={cn} onClick={() => { handleSelectedMatchKeys && handleSelectedMatchKeys(_itemKey, levels) }}  >
          <div>
            <div>
              {label(_itemKey, 1, index + 1, selected ? 'green' : 'grey')}
              {label(_itemKey, 2, _itemKey, selected ? 'black' : 'grey')}
              {label(_itemKey, 3, matchNumber, selected ? 'blue' : 'grey')}
            </div>
            <div>
              {label(_itemKey, 4, levels, selected ? 'blue' : 'grey')}
              {label(_itemKey, 5, _.startCase(poolKey), selected ? 'blue' : 'grey')}
              {isConsolation && label(_itemKey, 6, 'Consulation', selected ? 'blue' : 'grey')}
              {label(_itemKey, 7, startDate, selected ? 'black' : 'grey')}
            </div>
          </div>
          <div className='matches-pending-teams'>
            <div>
              <div>
                {label(_itemKey, 8, 'Away', 'black')}
              </div>
              <div>
                {label(_itemKey, 9, seed_away, _awayAlreadyExists ? 'grey' : 'green', true)}{name_away}
              </div>
              {_exists_away && <div>
                {label(_itemKey, 10, _exists_away, 'blue', true)}
              </div>}
            </div>
            <div>{'vs'}</div>
            <div>
              <div>
                {label(_itemKey, 11, 'Home', 'black')}
              </div>
              <div>
                {name_home} {label(_itemKey, 12, seed_home, _homeAlreadyExists ? 'grey' : 'green', true)}
              </div>
              {_exists_home && <div>
                {label(_itemKey, 13, _exists_home, 'blue', true)}
              </div>}
            </div>
          </div>
          <div className='matches-pending-prior'>
            <div>{teamText(name_away, _full_away)}</div>
            <div>{''}</div>
            <div>{teamText(name_home, _full_home)}</div>
          </div>
        </div>)
      })
    }

    return <div className='matches-pending'>
      {lis.length > 0 ? lis : 'No Matches'}
    </div>
  }

  return content_matchUpdates()

}

export default MatchPlayoffPending