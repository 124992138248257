import { realTimeScoring } from '../../dbActions/realtimeScoring';
import { updateCurrentRealtimeMatchScoreToRtDb } from '../../dbActions/updateMatch';
import { matchCalcs } from '../../helpers/match';
import { ammendTeamName } from './SportsMatchReducer';
import { seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';

const rts = {
  handleSet_latestSeason: 'handleSet_latestSeason',
  handleSet_trueMatch: 'handleSet_trueMatch',
  handleShow_rtMatch: 'handleShow_rtMatch',
  handleShowScore: 'handleShowScore',
  handleSet_rtMatch: 'handleSet_rtMatch',

  handleRt_updateScore: 'handleRt_updateScore',
  handleRtChange_score: 'handleRtChange_score',
  handleRtChange_set: 'handleRtChange_set',
  handleRtChange_setScores: 'handleRtChange_setScores',
  handleRtConfirm_match: 'handleRtConfirm_match',
  handleRtConfirm_set: 'handleRtConfirm_set',
  handleRtDelete_scores: 'handleRtDelete_scores',
}

export const sportsRtMatchReducer = (state, action) => {

  const {
    appUserInfo,
    latestSeason,
    match_rt,
    match_true,
    pathViews,
    rtSettings,
    sportPermissions,
  } = state

  const { type, dispatch } = action
  const { rtMatchData: allowRtMatchData, rtMatchUpdates: allowRtMatchUpdates } = rtSettings ?? {}

  const { handleRtChange_score, handleRtChange_set } = sportsRtMatchHandlers(dispatch)
  const _match_rt = match_rt ? { ...match_rt } : null

  switch (type) {

    case rts.handleSet_trueMatch:
      return { ...state, match_true: action.match_true, match_key: action.match_true._itemKey }

    case rts.handleSet_rtMatch:
      const rtm = { ...action.match_rt }
      return { ...state, match_rt: rtm }

    case rts.handleShow_rtMatch:
      console.log('handleShow_rtMatch', action)
      if (action.match_rt) {
        return { ...state, showRealtimeScoringSelected: !state.showRealtimeScoringSelected, match_rt_selected: action.match_rt }
      } else {
        return { ...state, showRealtimeScoring: !state.showRealtimeScoring }
      }

    case rts.handleRt_updateScore:
      handleRealTimeChange(action, handleRtChange_score, handleRtChange_set)
      return { ...state }

    case rts.handleRtChange_score:
      if (_match_rt) {
        if (!_match_rt.scores) { _match_rt.scores = {} }
        if (!_match_rt.setNumber) { _match_rt.setNumber = 1 }
        if (!_match_rt.scores[_match_rt.setNumber]) {
          _match_rt.scores[_match_rt.setNumber] = createNewSet()
        }
        _match_rt.scores[_match_rt.setNumber][action.team] = action.value
      }
      // calculates the match and updates the realtime database
      calcMatchAndUpdateRtDb(allowRtMatchUpdates, { results: _match_rt }, match_true, pathViews, appUserInfo, latestSeason)

      return { ...state, match_rt: _match_rt }

    case rts.handleRtChange_set:
      // changes the set and creates a blank new set
      if (_match_rt) {
        _match_rt.setNumber = action.setNumber
        if (!_match_rt.scores[_match_rt.setNumber]) {
          _match_rt.scores[_match_rt.setNumber] = createNewSet()
        }
      }
      return { ...state, match_rt: _match_rt }

    case rts.handleRtChange_setScores:
      const _rtm = action.match_rt
      calcMatchAndUpdateRtDb(allowRtMatchUpdates, { results: _rtm }, match_true, pathViews, appUserInfo, latestSeason)
      return { ...state, match_rt: _rtm }

    case rts.handleRtConfirm_set:
      if (_match_rt) {
        let _setNumber = _match_rt.setNumber
        _setNumber++
        _match_rt.setNumber = _setNumber
        if (!_match_rt.scores[_setNumber]) {
          _match_rt.scores[_setNumber] = createNewSet()
        }
      }
      calcMatchAndUpdateRtDb(allowRtMatchUpdates, { results: _match_rt }, match_true, pathViews, appUserInfo, latestSeason)
      return { ...state, match_rt: _match_rt }

    case rts.handleRtConfirm_match:
      if (_match_rt) {
        console.log('_match_rt', _match_rt)
      }
      return { ...state }

    case rts.handleRtDelete_scores:
      realTimeScoring.deleteRealtimeDatabaseScores(sportPermissions, allowRtMatchData, seasonalUpdateTypes.rtMatches, pathViews, state.match_key, appUserInfo, latestSeason)
      return { ...state, match_rt: null }

    case rts.handleSet_latestSeason:
      return { ...state, latestSeason: action.latestSeason }

    case rts.handleShowScore:
      action.e && action.e.preventDefault()
      action.e && action.e.stopPropagation()
      return { ...state, showScore: !state.showScore }


    default:
      return { ...state }
  }
}

export const sportsRtMatchHandlers = (dispatch) => {
  return {
    handleRt_updateScore: (match_rt, rtType, add, currentSetNumber) => { dispatch({ dispatch, type: rts.handleRt_updateScore, match_rt, rtType, add, currentSetNumber }) },
    handleRtChange_score: (value, team) => { dispatch({ type: rts.handleRtChange_score, dispatch, value, team }) },
    handleRtChange_set: (setNumber) => { dispatch({ type: rts.handleRtChange_set, dispatch, setNumber }) },
    handleRtChange_setScores: (match_rt) => { dispatch({ type: rts.handleRtChange_setScores, match_rt }) },
    handleRtConfirm_match: (value) => { dispatch({ type: rts.handleRtConfirm_match, dispatch, value }) },
    handleRtConfirm_set: (value) => { dispatch({ type: rts.handleRtConfirm_set, dispatch, value }) },
    handleRtDelete_scores: () => { dispatch({ type: rts.handleRtDelete_scores, dispatch }) },
    handleSet_latestSeason: (latestSeason) => { dispatch({ type: rts.handleSet_latestSeason, latestSeason }) },
    handleSet_rtMatch: (match_rt) => { dispatch({ type: rts.handleSet_rtMatch, match_rt }) },
    handleSet_trueMatch: (match_true) => { dispatch({ type: rts.handleSet_trueMatch, match_true }) },
    handleShow_rtMatch: (match_rt) => { dispatch({ type: rts.handleShow_rtMatch, match_rt }) },
    handleShowScore: (e) => { dispatch({ type: rts.handleShowScore, e }) },
  }
}

export const sportsRtMatchInitialState = (init_state) => {
  return { ...init_state }
};


export const matchNeedsUpdated = (matchResults, results) => {
  if (matchResults && matchResults.score && results.score)
    if (matchResults.score.home !== results.score.home || matchResults.score.away !== results.score.away) {
      return true
    }
}

export const fixTeamNames = (matches) => {
  Object.keys(matches).forEach(mk => {
    const match = matches[mk]
    const { home, away } = match ?? {}
    match.home = ammendTeamName(home)
    match.away = ammendTeamName(away)
  })
}

/**
 * 
 * @param {boolean} allowRtMatchUpdates 
 * @param {object} match 
 * @param {object} match_true 
 * @param {object} pathViews 
 * @param {object} appUserInfo 
 * @param {number} latestSeason 
 * @description calculates the match and updates the realtime database
 */
const calcMatchAndUpdateRtDb = (allowRtMatchUpdates, match, match_true, pathViews, appUserInfo, latestSeason) => {
  matchCalcs.calcVballMatch(match)
  const current_props = { match_rt: match.results, match_true }
  if (allowRtMatchUpdates) {
    updateCurrentRealtimeMatchScoreToRtDb(allowRtMatchUpdates, pathViews, current_props, appUserInfo, latestSeason)
  } else {
    console.log('calcAndUpdate not permitted', allowRtMatchUpdates, current_props)
  }
}

const createNewSet = () => {
  return {
    home: 0,
    away: 0
  }
}

const createTeam = (team) => {
  return {
    id: team._itemKey,
    levels: team.levels,
    sections: team.sections,
    name: team.name
  }
}

/**
 * 
 * @param {object} action 
 * @param {function} handleRtChange_score 
 * @param {function} handleRtChange_set 
 * @description ammends the realtime score, then triggers either handleRtChange_score or handleRtChange_set
 */
const handleRealTimeChange = (action, handleRtChange_score, handleRtChange_set) => {

  const { match_rt, rtType, add, currentSetNumber } = action
  const _realtimeMatch = { ...match_rt }
  const { setNumber } = _realtimeMatch
  let _setNumber = setNumber ? setNumber : 0
  if (currentSetNumber) { _setNumber = currentSetNumber }

  switch (rtType) {
    case 'away':
    case 'home':
      const sn = setNumber
      if (!_realtimeMatch.scores) { _realtimeMatch.scores = {} }
      if (!_realtimeMatch.scores[sn]) {
        _realtimeMatch.scores[sn] = {
          away: 0,
          home: 0
        }
      }
      let teamScore = _realtimeMatch.scores[sn][rtType]
      if (add) {
        teamScore++
      } else {
        teamScore--
      }
      if (teamScore < 0) { teamScore = 0 }
      handleRtChange_score(teamScore, rtType)
      break;

    case 'set':
      if (add) {
        _setNumber++
      } else {
        _setNumber--
      }
      if (_setNumber < 1) { teamScore = 1 }
      if (_setNumber > 5) { teamScore = 5 }
      handleRtChange_set(_setNumber)
      break;

    default:
      break;
  }
}
