import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { fs_set_doc } from '../../firestoreData/appData/fsData';

const rts = {
  handleInit_survey: 'handleInit_survey',
  handleResponse_survey: 'handleResponse_survey',
  handleUpdate_survey: 'handleUpdate_survey',
}

export const _collectionName = '_survey'

export const surverReducer = (state, action) => {

  const {
    allowSurvey,
    pathViews,
  } = state

  const { dispatch, type } = action

  // eslint-disable-next-line
  const { handleResponse_survey } = surveyHandlers(dispatch)

  switch (type) {

    case rts.handleInit_survey:
      if (allowSurvey && pathViews.events) {
        const _refPath = createRefPath_event(pathViews, [_collectionName])
        fs_get_data({ refPath: _refPath, callback: handleResponse_survey, opts: { listen: true } })
      }
      return { ...state, activeSurvey: {} }

    // sets the data after the handleInit_survey call
    case rts.handleResponse_survey:
      const activeSurvey = action.survey && action.survey.activeSurvey ? action.survey.activeSurvey : {}
      return { ...state, activeSurvey: activeSurvey }

    case rts.handleUpdate_survey:
      updateSurvey(pathViews, action.itemKey, action.data, action.callback)
      return { ...state }

    default:
      return { ...state }
  }
}

export const surveyInitialState = (initState) => {
  return { ...initState }
};

export const surveyHandlers = (dispatch) => {
  return {
    handleInit_survey: () => { dispatch({ type: rts.handleInit_survey, dispatch }) },
    handleResponse_survey: (survey) => { dispatch({ type: rts.handleResponse_survey, dispatch, survey }) },
    handleUpdate_survey: (itemKey, data, callback) => { dispatch({ type: rts.handleUpdate_survey, dispatch, itemKey, data, callback }) },
  }
}

const updateSurvey = async (pathViews, item_key, data, callback) => {
  if (pathViews && item_key && data) {
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, item_key])
    fs_set_doc(_updateRef2, data, true, callback)
  }
}
