import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { handleOnMessage } from '../../../project/firebase';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { appNotificationsHandlers, appNotificationsInitialState, appNotificationsReducer } from '../../cnr/reducers/AppNotificationsReducer';
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { _notificationsCollection } from '../../firestoreData/notifications/notificationsData';
import { AppUserContext } from './AppUserContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';

/** Notifications for the event
 * @ParentProvider - NOT AVAILIBLE
 */
export const AppNotificationsContext = createContext();

/** Global Files for the Event */
const AppNotificationsProvider = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUser, appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // papsContext 
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { pathViews, showStatus } = paps_state ?? {}

  // pageContext 
  const pageContext = useContext(PageContext)
  const { page_state, page_fns, navigate } = pageContext ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_appUserSettings } = pageSettings ?? {}
  const { projectModules, appNotifications, logging } = aps_global ?? {}
  const { allowNotifications: allowNotifications_g } = projectModules ?? {}
  const { allowNotifications, allowPushNotifications, pushNotificationsActive, pushActiveToSelf, allowNotificationTopics } = appNotifications ?? {}

  const _allowNotifications = allowNotifications_g && allowNotifications

  // reducer
  const initState = appNotificationsInitialState({ appUser, appUsers, appNotifications, appUserAccess, aps_appUserSettings, pathViews, page_fns, logging, navigate, aps_viewItems, allowNotificationTopics })
  const [appNotifications_state, notifications_dispatch] = useReducer(appNotificationsReducer, initState);
  const appNotifications_handlers = appNotificationsHandlers(notifications_dispatch)

  const { handleAppUserNotifications, handleScheduleNotifications, handleLoadNotifications } = appNotifications_handlers
  const { notifications_app } = appNotifications_state

  if (showStatus && notifications_app) { console.log('appNotifications_state ready', appNotifications_state) }

  handleOnMessage().then(payload => {
    appNotifications_handlers.handleNewForegroundNotification(payload)
  }).catch(err => console.log('failed: ', err));

  useEffect(
    () => {
      if (_allowNotifications && pathViews && pathViews.events) {
        const refPath = createRefPath_event(pathViews, [_notificationsCollection])
        fs_get_data({ refPath: refPath, callback: handleLoadNotifications })
      } else {
        handleLoadNotifications({})
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathViews.events] // does not re-rednder unless uivi has changed
  )

  useEffect(() => {
    if (notifications_app && appUserAccess) {
      handleAppUserNotifications(appUserAccess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserAccess, notifications_app]);

  useEffect(() => {
    if (notifications_app && _allowNotifications && allowPushNotifications && (pushNotificationsActive || pushActiveToSelf)) {
      handleScheduleNotifications(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications_app]);

  const providerContext = () => <AppNotificationsContext.Provider value={{ appNotifications_state, appNotifications_handlers, appUser }}>
    {props.children}
  </AppNotificationsContext.Provider>

  return (
    <React.Fragment>
      {(notifications_app) && providerContext()}
    </React.Fragment>
  )
}

export default AppNotificationsProvider