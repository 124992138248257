import React, { useContext } from 'react';
import { CreatePlayoffContext } from '../../../projectSpecific/sports/create/CreatePlayoff';
import { CreateSportsContext } from '../../../projectSpecific/sports/create/CreateSports';
import { uniqueKey } from '../../common/keys';
import CalMonth from './CalMonth';
import { AnimatePresence, motion } from 'framer-motion';

const CalSchedule = () => {

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state } = createSportsContext ?? {}
  const { crossoverOn, monthStartDates: monthStartDates_season } = createSports_state ?? {}

  // createPlayoffContext
  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state } = createPlayoffContext ?? {}
  const { playoff_info } = createPlayoff_state ?? {}
  const { monthStartDates: monthStartDates_playoffs } = playoff_info ?? {}

  const _monthStartDates = monthStartDates_season ? monthStartDates_season : monthStartDates_playoffs

  function getMonthAndYearFromDate(inputDate) {
    if (!(inputDate instanceof Date)) {
      // If the input is not a valid Date object, you can parse it to create one
      inputDate = new Date(inputDate);
    }
    if (isNaN(inputDate.getTime())) {
      return "Invalid Date";
    }
    const monthName = inputDate.toLocaleString('en-US', { month: 'long' }); //months[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    return { monthName, year }
  }

  const getMonthName = (md) => {
    try {
      const monthAndYear = getMonthAndYearFromDate(md)
      return { ...monthAndYear }
    } catch (error) {
      return { monthName: 'January', year: 2020 }
    }
  }

  const calMonths = () => {
    const cm = []
    if (_monthStartDates) {
      _monthStartDates.forEach((md, index) => {
        const { monthName, year } = getMonthName(md)
        cm.push(<div key={uniqueKey('cm', 'cm', index)} className={'cal-month-container'}>
          <div className={'cal-month'}>{monthName}{' - '}{year}</div>
          <CalMonth
            currentDate={new Date(md)}
            includeMonthHeader={true}
            crossoverOn={crossoverOn}
          />
        </div>)
      })
    }
    return cm
  }

  const content = () => <div className={'cal-months-container sch'}>
    <div className={'cal-months'}>
      <AnimatePresence>
        <motion.div
          key="box"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}>
        </motion.div>
        {calMonths()}
      </AnimatePresence>
    </div>
  </div>

  return content()
}

export default CalSchedule