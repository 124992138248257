import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';

const rts = {
  handleSportsInit: 'handleSportsInit',
  handleSetGlobals: 'handleSetGlobals',
}

export const _sportsOriginTypes = {
  organization: 'organization',
  district: 'district',
  schools: 'schools',
  sportsSeason: 'sportsSeason',
  sportsSeasonMatches: 'sportsSeasonMatches',
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsReducer = (state, action) => {

  const { pathViews } = state
  const { type, dispatch } = action

  const { handleSetGlobals } = sportsHandlers(dispatch)

  switch (type) {

    case rts.handleSportsInit:

      const _refPathG = createRefPath_event(pathViews, ['_globals'])
      fs_get_data({ refPath: _refPathG, callback: handleSetGlobals })

      return { ...state }

    case rts.handleSetGlobals:
      const { globals } = action
      const { sports } = globals ?? {}
      return { ...state, allGlobals: globals, sports }

    default:
      return { ...state }
  }
}

export const sportsHandlers = (dispatch) => {
  return {
    handleSportsInit: () => { dispatch({ type: rts.handleSportsInit, dispatch }) },
    handleSetGlobals: (globals) => { dispatch({ type: rts.handleSetGlobals, dispatch, globals }) },
  }
}

export const sportsInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {} }
};