import { useElementScroll } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { HeaderContext } from "../cnr/contexts/HeaderContext";
import { UiItemContext } from "../cnr/contexts/UiItemContext";
import { g_cns } from '../common/cns';
import { ParentContext } from "../cnr/contexts/ParentContext";
import { gEnums } from "../enums/globalEnums";

/** Wraps item-container */
const ItemWrapper = (props) => {

  const { content, header, itemPopup, wrapper, display, useTitleTransition, key_viewItem, dataCount } = props ?? {}
  const { cardDisplayType } = display ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, storage_state } = states
  const { globalFiles } = storage_state ?? {}
  const { image: image_global } = globalFiles ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles } = pageSettings ?? {}

  const { themedItems } = aps_global ?? {}
  const { background } = themedItems ?? {}
  const { useBackgroundImageOnAll, useBackgroundColorOnAll } = background ?? {}

  const backgroundSaC = aps_styles && useBackgroundColorOnAll ? aps_styles[gEnums.projectStyles.background] : {}
  const { style } = backgroundSaC ?? {}
  const { bgi } = style ?? {}

  let _backgroundImage;
  if (useBackgroundImageOnAll && image_global && bgi && image_global[bgi] && image_global[bgi].urls && image_global[bgi].urls.full) {
    _backgroundImage = image_global[bgi].urls.full
    backgroundSaC.style.backgroundImage = `url(${_backgroundImage})`
    delete backgroundSaC.style.bgi
  }

  // itemContext
  const itemContext = useContext(UiItemContext)
  const { item_state } = itemContext ?? {}
  const { _cnc, propOverrideOn } = item_state ?? {}

  // headerContext
  const headerContext = useContext(HeaderContext)
  const { header_handlers } = headerContext ?? {}
  const { handleTitleProgress } = header_handlers ? header_handlers : {}

  const isSolo = cardDisplayType && cardDisplayType.endsWith('Plus')

  let prefix = ' ' + _cnc + ' ';

  const itemRef = useRef()

  const [progress, setProgress] = useState(0);

  const { scrollYProgress } = useElementScroll(itemRef)
  scrollYProgress.onChange(setProgress);

  useEffect(() => {
    if (useTitleTransition) {
      handleTitleProgress(progress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  if (header) { prefix += 'h' }
  if (content) { prefix += 'c' }
  if (itemPopup) { prefix += 'p' }
  if (key_viewItem) { prefix += ' ' + key_viewItem }
  if (isSolo || (dataCount === 1)) { prefix += ' solo' }

  backgroundSaC.className = g_cns.item_container + prefix
  // const handleTouchHold = () => {
  //   console.log('handleTouchHold')
  // }

  // const contentTouch = (c) => <TouchWrapper handleTouchHold={handleTouchHold}>
  //   {c}
  // </TouchWrapper>

  let cn_content = g_cns.item_content + ' h100_of'
  if (propOverrideOn) { cn_content += ' prp-ovr' }

  const main = <React.Fragment>
    {header && <div className={g_cns.item_header}>{header}</div>}
    {content && <div className={cn_content} ref={itemRef} >{content}</div>}
    {/* {content && <div className={_cn} ref={itemRef} >{content}</div>} */}
    {itemPopup && itemPopup}
  </React.Fragment>

  if (wrapper) {
    return <div {...backgroundSaC} >
      <div className={wrapper}>
        {main}
      </div>
      {main}
    </div>
  } else {
    return <div {...backgroundSaC}>
      {main}
    </div>
  }
}

export default ItemWrapper