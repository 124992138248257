import { gEnums } from "../../enums/globalEnums"
import { responseReducers } from "./reducerHelpers/dispatchProps"
import _ from 'lodash'

const rts = {
  handleInit_element: 'handleInit_element',
}

export const clickToMethods = {
  normal: 'normal',
  page: 'page',
  openAppUrl: 'openAppUrl',
  openAppPdf: 'openAppPdf',
  windowOpen: 'windowOpen',
  noClick: 'noClick'
}

export const elementReducer = (state, action) => {

  const { type } = action

  switch (type) {

    case rts.handleInit_element:
      const { cn, elemProps, itemData, opts } = action
      const { viewItem } = elemProps ?? {}
      const clickTo = getClickTos(viewItem, itemData, opts)
      return {
        ...state,
        itemData,
        cn,
        elemProps,
        opts,
        clickTo
      }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action)

    default:
      return { ...state }
  }
}

export const elementInitialState = (initState) => {
  return { ...initState }
};

export const elementHandlers = (dispatch) => {
  return {
    handleInit_element: (cn, elemProps, itemData, opts) => { dispatch({ type: rts.handleInit_element, cn, elemProps, itemData, opts }) },
  }
}

const getClickTos = (viewItem, itemData, opts) => {

  const { global_pdf, isAdminOrSuper, viewKey, autoLinkPdfs, showAutoLinkIcons } = opts ?? {}
  const { itemPdf, url, website, link } = itemData ?? {}

  let { itemClick, display } = viewItem ?? {}
  let { _adjusted } = display ?? {}
  let { showIcons, clickToType, appType, url: click_url, clickToPage: click_toPage, detectAppProps } = itemClick ?? {}

  const clickTos = {
    app: false,
    detect: false,
    noClick: false,
    page: false,
    pdf: false,
    url: false,
  }

  let clickTo = {
    method: clickToMethods.normal,
  }

  switch (clickToType) {
    case gEnums.clickToTypes.app:
      clickTos.app = true
      break;
    case gEnums.clickToTypes.detectApp:
      clickTos.detect = true
      break;
    case gEnums.clickToTypes.noClick:
      clickTos.noClick = true
      break;
    case gEnums.clickToTypes.normal:
      break;
    case gEnums.clickToTypes.page:
      clickTos.page = true
      break;
    case gEnums.clickToTypes.pdf:
      clickTos.pdf = true
      break;
    case gEnums.clickToTypes.url:
      clickTos.url = true
      break;
    default:
    // nothing
  }

  if (_adjusted) {
    clickTos.noClick = true
  }

  if (autoLinkPdfs) {
    clickTos.detect = true
    detectAppProps = ['itemPdf', 'url']
  }

  if (clickTos.page) {
    clickTo = {
      clickToMethod: clickToMethods.page,
      page: click_toPage
    }
    return clickTo
  }

  if (clickTos.app && appType) {
    clickTo = openApp(appType, opts)
    return clickTo
  }

  if (clickTos.detect && url) {
    clickTo = {
      clickToMethod: clickToMethods.openAppUrl,
      value: url
    }
  }

  if (clickTos.detect && itemData) {
    if (detectAppProps) {
      _.each(detectAppProps, (dap) => {
        if (itemData[dap]) {
          switch (dap) {
            case 'url':
              clickTo = {
                clickToMethod: clickToMethods.openAppUrl,
                value: itemData[dap]
              }
              break;
            case 'itemPdf':
            case 'url':
              clickTo = {
                clickToMethod: clickToMethods.openAppPdf,
                value: itemData[dap]
              }
              if (global_pdf && global_pdf[itemPdf] && global_pdf[itemPdf].urls && global_pdf[itemPdf].urls.full) {
                clickTo.value = global_pdf[itemPdf].urls.full
              }
              break;
          }
          // _exitFn = true
          // return false
        }
      })
    } else {
      if (url || website || link) {
        let openUrl = url ? url : website
        openUrl = link ? link : openUrl
        if (openUrl) {
          clickTo = {
            clickToMethod: clickToMethods.openAppUrl,
            value: openUrl
          }
        }
      }
    }
  }

  if (clickTos.url && click_url) {
    clickTo = {
      clickToMethod: clickToMethods.openAppUrl,
      value: click_url
    }
  }

  if (clickTos.noClick) {
    clickTo = {
      clickToMethod: clickToMethods.noClick,
      value: null
    }
  }

  if (showIcons || showAutoLinkIcons) {
    clickTo.showIcons = true
  }

  if (isAdminOrSuper && !viewKey) {
    clickTo.ignoreClickMethod = true
  }

  return clickTo
}

const openApp = (appType, opts) => {

  let url;

  const { isMobileDevice, homeAppUrls, weatherZip } = opts

  if (appType && homeAppUrls.appUrls) {

    const urlProps = _.find(homeAppUrls.appUrls, { 'appName': appType })

    if (urlProps) {

      if (isMobileDevice) {
        url = urlProps.appUrl
      } else {
        if (urlProps.appDesktopUrl) {
          url = urlProps.appDesktopUrl
        } else {
          url = urlProps.appUrl
        }
      }

      switch (appType) {
        case 'weather':
          if (weatherZip) {
            url = url.replace('ZIPCODE', weatherZip)
          }
          break;
        default:
        // nothing
      }
    }
  }

  const clickTo = {
    method: clickToMethods.windowOpen,
    value: url
  }

  return clickTo

}