import { getDatabase, onValue, ref, set } from "firebase/database";
import { getSportsYear } from '../../../global/firestoreData/helpers/years';

const rtPath = (pathViews, sportsYear, realtimeMatchKey, uid) => {
  const path = 'clients/' + pathViews.clients + '/events/' + pathViews.events + '/realtimeResults/' + sportsYear
  return realtimeMatchKey ? path + '/' + realtimeMatchKey + '/' + uid : path
}

export const realTimeScoring = {
  deleteRealtimeDatabaseScores: (sportPermissions, allowRtMatchData, seasonalUpdateType, pathViews, match_key, appUserInfo, latestSeason, callback) => deleteRealtimeDatabaseScores(sportPermissions, allowRtMatchData, seasonalUpdateType, pathViews, match_key, appUserInfo, latestSeason, callback),
  getRealtimeScores: (allowRtMatchData, pathViews, latestSeason, callback) => getRealtimeScores(allowRtMatchData, pathViews, latestSeason, callback),
  updateRealtimeScoreToDatabase: (sportPermissions, allowRtMatchUpdates, seasonalUpdateType, pathViews, match_key, results, appUserInfo, latestSeason, callback) => updateRealtimeScoreToDatabase(sportPermissions, allowRtMatchUpdates, seasonalUpdateType, pathViews, match_key, results, appUserInfo, latestSeason, callback),
}

/**
 * Updates the scores into the realtime database
 * @param {object} pathViews 
 * @param {object} realtimeMatch 
 * @param {object} results 
 * @param {object} appUserInfo 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
const updateRealtimeScoreToDatabase = async (sportPermissions, allowRtMatchUpdates, seasonalUpdateType, pathViews, match_key, results, appUserInfo, latestSeason, callback) => {

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]

  console.log('-----------')
  console.log('allowUpdate', allowUpdate, seasonalUpdateType)

  const { uid, displayName } = appUserInfo ?? {}
  const sportsYear = getSportsYear(latestSeason)
  const db = getDatabase();
  const refString = rtPath(pathViews, sportsYear, match_key, uid)

  const updateData = {
    displayName,
    results
  }

  if (allowUpdate && allowRtMatchUpdates) {
    set(ref(db, refString), updateData).then(() => {
      callback && callback({ success: true })
    }).catch((error) => {
      console.log('updateRealtimeScoreToDatabase Error', error)
      callback && callback({ success: false, error })
    });
  } else {
    console.log('updateRealtimeScoreToDatabase not permitted', allowRtMatchUpdates, refString, updateData)
  }

}

/**
 * Gets the scores from the realtime database
 * @param {object} pathViews 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
const getRealtimeScores = async (allowRtMatchData, pathViews, latestSeason, callback) => {

  const sportsYear = getSportsYear(latestSeason)
  const refString = rtPath(pathViews, sportsYear)

  const db = getDatabase();
  const _ref = ref(db, refString);

  if (allowRtMatchData) {
    onValue(_ref, (snapshot) => {
      callback(snapshot.val())
    });
  }

}

/**
 * Updates the scores into the realtime database
 * @param {object} pathViews 
 * @param {object} realtimeMatch 
 * @param {object} results 
 * @param {object} appUserInfo 
 * @param {string} latestSeason 
 * @param {function} callback 
 */
const deleteRealtimeDatabaseScores = async (sportPermissions, allowRtMatchData, seasonalUpdateType, pathViews, match_key, appUserInfo, latestSeason, callback) => {

  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateType]

  console.log('-----------')
  console.log('deleteRealtimeDatabaseScores')
  console.log('allowUpdate', allowUpdate, seasonalUpdateType)

  const { uid } = appUserInfo ?? {}
  const sportsYear = getSportsYear(latestSeason)
  const db = getDatabase();
  const refString = rtPath(pathViews, sportsYear, match_key, uid)

  if (allowUpdate && allowRtMatchData) {
    set(ref(db, refString), null).then(() => {
      callback && callback({ success: true })
    }).catch((error) => {
      callback && callback({ success: false, error })
    });
  } else {
    console.log('refString', refString)
    callback && callback({ success: true })
  }

}