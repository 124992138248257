import _ from 'lodash'
import { dataModificationTypes } from "../../../viewSettings/enums/itemActionTypes"
import { getFirstObject } from '../../../common/convert'

const _useGlobalProps = true

/**
 * 
 * @param {object} props (dataModificationType, editableItem, props_viewItem, subEditProps)
 * @returns the props that are allowed to be modified.
 */
export const getModifyProps = (props) => {

  const { appData, dataModificationType, editableItem, props_viewItem, props_viewItem_g, subEditProps, restrictArrayEdits } = props

  let props_modify = {}
  let props_modify_xxx = {}

  switch (dataModificationType) {
    case dataModificationTypes.add:
      if (props_viewItem) {
        _.each(props_viewItem, (pi, key) => {
          const pi_g = props_viewItem_g ? props_viewItem_g[key] : {}
          const { restrictAdd: restrictAdd_p, data, remove } = pi ? pi : []
          const { restrictAdd: ignoreOnAddEdit } = data ?? {}
          const _restrictAdd = restrictAdd_p || ignoreOnAddEdit || remove

          if (!_restrictAdd) {
            props_modify[key] = _useGlobalProps ? pi_g : pi
          } else {
            props_modify_xxx[key] = _useGlobalProps ? pi_g : pi
          }
        })
      }
      break;

    case dataModificationTypes.edit:

      const { item: _itemData } = appData ? getFirstObject(appData) : {}

      if (editableItem) {
        Object.keys(editableItem).forEach(k => {
          props_modify[k] = editableItem[k]
          props_modify[k].propSection = 'name'
        })
      } else {
        if (subEditProps && Object.keys(subEditProps).length > 0 && props_viewItem) {
          Object.keys(props_viewItem).forEach(propKey => {
            if (subEditProps.includes(propKey)) {
              props_modify[propKey] = props_viewItem[propKey]
            }
            addDefaultProp(props_viewItem, props_modify, 'name')
            addDefaultProp(props_viewItem, props_modify, 'firstName')
            addDefaultProp(props_viewItem, props_modify, 'lastName')
          })
        } else {
          if (props_viewItem) {
            _.each(props_viewItem, (pi, key) => {
              let restrictArray;
              const propValue = _itemData ? _itemData[key] : {}
              if (restrictArrayEdits && propValue && _.isArray(propValue) && _.size(propValue) > 1) {
                restrictArray = true
              }
              const pi_g = props_viewItem_g ? props_viewItem_g[key] : {}
              const { restrictAdd: restrictAdd_p, data, remove } = pi ? pi : []``
              const { restrictAdd: ignoreOnAddEdit } = data ?? {}
              const _restrictAdd = restrictAdd_p || ignoreOnAddEdit || remove || restrictArray
              if (!_restrictAdd) {
                props_modify[key] = _useGlobalProps ? pi_g : pi
              } else {
                props_modify_xxx[key] = _useGlobalProps ? pi_g : pi
              }
            })
          }
        }
      }
      break;
    default:
      props_modify = props_viewItem
    // nothing
  }

  const _props_modify = _.pickBy(props_modify, _.isPlainObject);
  return _props_modify
}


const addDefaultProp = (props_viewItem, mps, propKey) => {
  if (props_viewItem[propKey] && !mps[propKey]) {
    mps[propKey] = props_viewItem[propKey]
    mps[propKey].data.readOnly = true
  }
}