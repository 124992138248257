import React, { Suspense, createContext, useContext, useEffect, useRef, useState } from 'react';
import { default as ReactSidebar } from 'react-sidebar';
import { Ref, Sidebar } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import PageComponentProvider from '../cnr/contexts/PageComponentContext';
import { PageContext } from '../cnr/contexts/PageContext';
import PageDimmerProvider from '../cnr/contexts/PageDimmerContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import SplashContextProvider from '../cnr/contexts/SplashContext';
import { startTypes } from '../cnr/reducers/StartReducer';
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import PendingWait from '../components/alerts/pendings/PendingWait';
import Banner from '../components/sem/SemBanner';
import Banners from '../components/sem/SemBanners';
import AppSidebar from '../components/sidebars/AppSidebar';
import Desktop from "../desktop/Desktop";
import { allPageTypes } from "../enums/allPageTypes";
import { gEnums } from '../enums/globalEnums';
import { _animateTypes } from '../motions/AnimateComponent';
import AppWrapper from '../wrappers/AppWrapper';
import DesktopWrapper from '../wrappers/DesktopWrapper';
import Wrapper from "../wrappers/Wrapper";
import AppContentAlt from './AppContentAlt';
import PageAreaContent from './PageAreaContent';
import PageNavigation from './PageNavigation';
import PageTopHeader from './PageTopHeader';
import DataSidebar from '../components/swipers/DataSidebar';

export const AppContentContext = createContext();

const AppAccessPage = React.lazy(() => import("../components/alerts/AppAccessPage"));
const RestrictedPage = React.lazy(() => import("../components/alerts/RestrictedPage"));
const QrCode = React.lazy(() => import("../pageItem/modification/pageItemActions/QrCode"));
const UnderMaintenancePage = React.lazy(() => import("../components/alerts/UnderMaintenancePage"));

/**
 * This is the Main App Content
 * @returns 
 */
const AppContent = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings, navigate } = parentContext ?? {}
  const { appSettings_state, appUser_state, page_state, paps_state, transition_state } = states
  const { appUser_fns } = fns
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_page, aps_styles, aps_appUserSettings } = pageSettings ?? {}
  const { collectProfileInformation } = aps_appUserSettings ?? {}
  const { clientSettings } = settings ?? {}
  const { global: global_client } = clientSettings ?? {}
  const { appSignIn: appSignIn_client } = global_client ?? {}
  const { redirectToCurrentEvent } = appSignIn_client ?? {}

  const { transitions } = transition_state ?? {}
  const transition_page = transitions ? transitions[_animateTypes.page] : null
  const { showTransition: showTransition_page } = transition_page ?? {}

  // frameworkContext - not in parent
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { cnp, desktopMode, isStandAlone, isMobileDevice, showAppQrCode, useShortLink, showSignIn, showAppBottomSidebar, bottomSidebarContent } = framework_state ?? {}

  // pageContext 
  const pageContext = useContext(PageContext);

  // authContext 
  const { appUser, appUsers, homePageResponded, accessConfirmed } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { loggedIn: loggedIn_au, accessLevel } = appUserAccess ?? {}

  // appSettingsContext 
  const { themeSelected } = appSettings_state ?? {}
  let { desktopOn, desktopMobileOn, showHomeScreenPrompt, showHomeScreenHelp } = appSettings_state ?? {}

  // papsContext 
  const { otherView, landingView, view, pathViews, rootPaths } = paps_state ?? {}
  const { events: eventKey } = pathViews ?? {}

  // pageContext 
  const { topMenu, navigation, appStatus, banners: app_banners, appNotifications, appInstallation, projectOptions, appSignIn, appAccess } = aps_global ?? {}
  const { appAccessType, passcode } = appAccess ?? {}
  const { showBannersPages, bannersLocation } = app_banners ?? {}
  const { forceInstall } = appSignIn ?? {}

  const _forceInstall = forceInstall && !isStandAlone && isMobileDevice

  let { useAppDarkMode, allowNewVersionUpdate } = projectOptions ?? {}
  let { menuLocation } = topMenu ?? {}
  let { showNavigation } = navigation ?? {}
  let { eventStatusType } = appStatus ?? {}

  const settingsPermissionType = appUser_fns.validate_trueSettingsAuth(appUserAccess)
  const styleAndClass = aps_styles ? aps_styles[gEnums.projectStyles.pageItemDimmer] : {}

  let allowBannersOnPage = otherView ? false : true
  const isLanding = view === landingView

  // local state
  const [pageAccess, setPageAccess] = useState({ pending: true })
  const [allowAppView, setAllowAppView] = useState()
  const [showAppAccess, setShowAppAccess] = useState(false)

  const ammendAccess = () => {

    if (aps_page && aps_global) {
      // aps_page, paps_state, aps_global, aps_appUserSettings
      const { allow: _allowPageView } = appUser_fns.validateAccess_pageView(paps_state, aps_page, aps_global, aps_viewItems, aps_appUserSettings, appUserAccess)

      let _allowed;
      switch (_allowPageView) {
        case gEnums.viewPermissionTypes.allow:
          _allowed = true
          break;
        case gEnums.viewPermissionTypes.allow_clientLimited:
          _allowed = false
          break;
        default:
          _allowed = false
      }

      const x = { allowed: _allowed, allowPageView: _allowPageView, pending: false }
      setPageAccess(x)

      const _allowAppView = appStatus ? appUser_fns.validateAccess_app(appUser, appStatus) : true
      setAllowAppView(_allowAppView)
    }
  }

  useEffect(() => {
    ammendAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, aps_page, aps_global, appUserAccess]);

  useEffect(() => {
    if (aps_page && aps_global && appUser && collectProfileInformation) {
      if (appUser && appUser.profileData && !appUser.profileData.profileUpdated) {
        // setShowProfileWizard(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess]);

  useEffect(() => {
    if (appAccessType) {
      switch (appAccessType) {
        case gEnums.siteAuthorizationTypes.passcode:
          if (accessConfirmed === passcode) {
            setShowAppAccess(false)
          } else {
            setShowAppAccess(true)
          }
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [accessConfirmed]);

  useEffect(() => {
    // redirect the appUser to the current event
    if (loggedIn_au && redirectToCurrentEvent && !pathViews.events && appUserAccess.activeEvents_appUser && accessLevel < gEnums.authLevels.appDataAdmin.value) {
      if (appUserAccess.activeEvents_appUser.length > 0) {
        const event_appUser = appUserAccess.activeEvents_appUser[0]
        const _cp = rootPaths.clients + 'events/' + event_appUser + '/landing'
        navigate(_cp)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToCurrentEvent]);

  const contextRef = useRef()

  const banner = () => isLanding && <Banner />
  const banners = () => {
    let allow = showBannersPages && showBannersPages.includes(view)
    if (isLanding || allow) { return <Banners /> }
  }

  /**
   * 
   * @returns PageAreaContent wrapped in PageComponentProvider
   */
  const pageAreaContent = () => <PageComponentProvider>
    <PageAreaContent />
  </PageComponentProvider>

  const appContent_state = {
    allowNewVersionUpdate,
    appInstallation,
    appNotifications,
    appSignIn,
    bannersLocation,
    css: { container: themeSelected ? 'theme-on theme-on-' + themeSelected : null, menu: menuLocation },
    desktopMobileOn,
    desktopOn,
    eventKey,
    forceInstall: _forceInstall,
    homePageResponded,
    showHomeScreenHelp,
    showHomeScreenPrompt: showHomeScreenPrompt && !homePageResponded,
    useAppDarkMode,
  }

  const desktopPage = () =>
    <Ref innerRef={contextRef}>
      <DesktopWrapper
        banner={allowBannersOnPage && banner()}
        banners={allowBannersOnPage && banners()}
        menu={menuLocation === gEnums.menuLocations.top && <PageTopHeader />}
        content={pageAreaContent()}
        navigation={showNavigation && !otherView && <PageNavigation />}
        // footer={desktopOn && < DesktopFooter />}
        innerRef={contextRef}
      ></DesktopWrapper>
    </Ref>

  const appPage = () => <AppWrapper
    banner={allowBannersOnPage && banner()}
    banners={allowBannersOnPage && banners()}
    content={pageAreaContent()}
    // footer={desktopOn && <DesktopFooter />}
    menu={menuLocation === gEnums.menuLocations.top && <PageTopHeader />}
    navigation={showNavigation && !otherView && <PageNavigation />}
  ></AppWrapper>

  /**
   * 
   * @returns appPage OR desktopPage depending on desktopOn
   */
  const normalContent = () => desktopOn ? desktopPage() : appPage()

  const otherContent = () => {

    let oc;

    switch (otherView) {
      case allPageTypes.appProfile:
      case allPageTypes.appUserInfo:
      case allPageTypes.help:
      case allPageTypes.signIn:
        let cn;
        if (cnp) { cn += ' ' + cnp }
        oc = <Wrapper
          content={<PageAreaContent />}
          css={{ container: cn, header: 'padd5' }}
        />
        break;
      default:
        oc = <PageAreaContent />
    }

    if (desktopOn || desktopMode) {
      return <Ref innerRef={contextRef}>
        <Desktop
          headerContent={<PageTopHeader />}
          appContent={oc}
        />
      </Ref>
    } else {
      return appPage()
    }
  }

  const mainContent = () => <ReactSidebar {...AppSidebar({ frameworkContext, pageContext })}>
    {!otherView && normalContent()}
    {otherView && otherContent()}
  </ReactSidebar>

  const appContentOther = () => <Suspense fallback={<SuspenseDimmer origin={'App Content'} />}>
    <QrCode qrCodeType={gEnums.qrCodeTypes.appLink} fromSidebar={true} useShortLink={useShortLink} />
  </Suspense>

  const appContent = () => {
    if (showAppQrCode) {
      return appContentOther()
    } else if (showSignIn && !loggedIn_au) {
      // show the sign in page
      return <AppContentAlt view={view} pageAccess={pageAccess} appUser={appUser} loggedIn={loggedIn_au} fromDirect={true} />
    } else if (pageAccess.allowed) {
      return mainContent()
    } else {
      return <AppContentAlt view={view} pageAccess={pageAccess} appUser={appUser} loggedIn={loggedIn_au} />
    }
  }

  const sidebar_bottom = (visible) => <DataSidebar direction={'bottom'} visible={visible} content={visible && bottomSidebarContent} onHide={framework_handlers.handleShow_appBottomSidebar} />

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_bottom(showAppBottomSidebar)}
    <Sidebar.Pusher dimmed={showAppBottomSidebar} className='h100' >
      {appContent()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const pageContent = () => {
    // check to see if something is not allowed  
    if (showAppAccess) {
      return <AppAccessPage />
    } else {
      switch (allowAppView) {
        case gEnums.eventStatusTypes.restricted:
          return <RestrictedPage />
        default:
          switch (eventStatusType) {
            case gEnums.eventStatusTypes.underMaintenance:
              return <UnderMaintenancePage />
            default:
              if (pageAccess.pending) {
                return <PendingWait inverted={true} styleAndClass={styleAndClass} startType={startTypes.pageItem} />
              } else {
                return <AppContentContext.Provider value={{ appContent_state: { ...appContent_state } }}>
                  {sidebar()}
                </AppContentContext.Provider>
              }
          }
      }
    }
  }

  const appPageContent = () => {
    if (showTransition_page) {
      return <PageDimmerProvider>
        {pageContent()}
      </PageDimmerProvider>
    } else {
      return pageContent()
    }
  }

  return <SplashContextProvider>
    {appPageContent()}
  </SplashContextProvider>



}

export default AppContent