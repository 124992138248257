import _ from 'lodash'
import { approvalRoles } from './FieldTripReducer';
import { fsfn_notifications } from "../../../../global/functions/fbNotifications";
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { serverTimestamp } from 'firebase/firestore';
import { fs_update_doc } from '../../../../global/firestoreData/appData/fsData';
import { updateDocumentHelpers } from '../../../../global/firestoreData/updates/subUpdates/fsUpdate_document';
import { _developerAdminEmail } from '../../../../global/common/keys';

const _emailProps = ['busCount', 'milageEstimate', 'studentCount', 'name', 'nurseRequired', 'startDate', 'startTime', 'returnTime']

export const handleUpdateEmailSent = (fieldTrip, role, pathViews, useAppDataDocuments) => {
  if (role) {
    const { status } = fieldTrip ?? {}
    const dataToUpdate = {
      status: {
        ...status,
        [role + 'EmailSentDate']: serverTimestamp()
      }
    }
    if (useAppDataDocuments) {
      updateDocumentHelpers.update_itemInDocuments(pathViews, 'fieldTrips', fieldTrip._itemKey, dataToUpdate)
    } else {
      const refPath = createRefPath_event(pathViews, ['fieldTrips', fieldTrip._itemKey])
      fs_update_doc(refPath, dataToUpdate)
    }
  }
};

export const handle_triggerEmail = async (fieldTrips_state, fieldTrip, role, pathViews, sharePathFull, callback, cb) => {

  const { fieldTripSettings } = fieldTrips_state ?? {}

  const { useMe, allowEmail, useDefaultEmails, secretaryEmail, principalEmail, districtResourceEmail, allowEmailApprovals } = fieldTripSettings ?? {}
  const frRoles = fieldTrip[role]

  _.each(frRoles, (key) => {
    const item = fieldTrips_state[role][key]
    const { email, _itemKey } = item ?? {}
    let _email = email

    if (useDefaultEmails) {
      switch (role) {
        case approvalRoles.secretaries:
          _email = secretaryEmail ? secretaryEmail : email
          break;
        case approvalRoles.principals:
          _email = principalEmail ? principalEmail : email
          break;
        case approvalRoles.districtResources:
          _email = districtResourceEmail ? districtResourceEmail : email
          break;
        default:
          break;
      }
    }

    if (useMe) {
      _email = _developerAdminEmail
    }

    const nd = {
      email: {
        recipient: _email,
        sender: 'support@meetingevolution.net',
        subject: 'Field Trip Approval - ' + fieldTrip.name,
        body: emailBody(fieldTrips_state, fieldTrip, role, _itemKey, pathViews, sharePathFull, allowEmailApprovals),
      }
    }
    if (allowEmail) {
      fsfn_notifications.sendEmail(nd).then(res => {
        callback && callback(fieldTrip, role, cb)
      })
    } else {
      console.log('email to be sent', nd)
      callback && callback(fieldTrip, role, cb)
    }
  })
};

const emailBody = (fieldTrips_state, fieldTrip, role, itemKey, pathViews, sharePathFull, allowEmailApprovals) => {

  const baseHtml = 'https://us-central1-me-mobile-4410b.cloudfunctions.net/updateData?clientKey=' + pathViews.clients + '&eventKey=' + pathViews.events + '&collectionName=fieldTrips&docKey=' + fieldTrip._itemKey + '&role=' + role
  const funct_approve = baseHtml + '&approved=true'
  const funct_deny = baseHtml + '&approved=false'
  const href_view = sharePathFull + '?role=' + role + '&itemKey=' + itemKey

  let _role

  switch (role) {
    case approvalRoles.secretaries:
      _role = 'Secretary'
      break;
    case approvalRoles.principals:
      _role = 'Principal'
      break;

    case approvalRoles.districtResources:
      _role = 'District Resource'
      break;
  }

  let _body = `<p>You have been assigned as a '` + _role + `' for the field trip '` + fieldTrip.name + `' and your approval is requested.</p>`
  _body += emailText(fieldTrips_state, fieldTrip)
  _body += `<p><a href="` + href_view + `">View</p> `
  if (allowEmailApprovals) {
    _body += `<p><a href="` + funct_approve + `">Approve</p>`
    _body += ` <p><a href="` + funct_deny + `">Reject</p>`
  }
  return _body
}

const emailText = (fieldTrips_state, fieldTrip) => {
  let _text = ''
  _.each(fieldTrip, (propValue, propKey) => {
    if (_.isArray(propValue)) {
      if (fieldTrips_state[propKey]) {
        _.each(propValue, (value) => {
          if (fieldTrips_state[propKey][value]) {
            const ftValue = fieldTrips_state[propKey][value]
            const { name } = ftValue ?? {}
            if (name) {
              _text += `<p>` + _.startCase(propKey) + `: ` + name + `</p>`
            }
          }
        })
      }
    } else if (_emailProps.includes(propKey)) {
      _text += `<p>` + _.startCase(propKey) + `: ` + propValue + `</p>`
    }
  })
  return _text
}