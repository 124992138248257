import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Label, Icon, Button } from 'semantic-ui-react';
import { uniqueKey } from '../../../../global/common/keys';
import NoData from '../../../../global/components/alerts/NoData';
import Checker from '../../../../global/components/forms/elements/Checker';
import { PapsContext } from '../../../../global/cnr/contexts/PapsContext';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';
import { CreateSportsContext } from '../../create/CreateSports';

const _cns = {
  details: 'athlete-details',
  icon: 'athlete-icon',
  index: 'athlete-index',
  name: 'athlete-name',
  number: 'athlete-number',
  position: 'athlete-position',
  team: 'athlete-team',
}

const PlayerList = (props) => {

  const { athletes, forList, teamId, saTypes } = props
  const d = new Date()
  const thisYear = d.getFullYear()

  const [currentPlayers, setCurrentPlayers] = useState()
  const [alumniPlayers, setAlumniPlayers] = useState()
  const [playerType, setPlayerType] = useState('current')
  const [sortProp, setSortProp] = useState(props.sortProp)
  const [currentPlayerList, setCurrentPlayerList] = useState()

  useEffect(() => {
    const _cp = _.filter(athletes, a => a.graduationYear >= thisYear);
    const _a = _.filter(athletes, a => a.graduationYear < thisYear);
    const _currentPlayers = _.sortBy(_cp, sortProp ? sortProp : 'lastName')
    const _alumniPlayers = _.sortBy(_a, 'lastName')
    setCurrentPlayers(_currentPlayers)
    setAlumniPlayers(_alumniPlayers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [athletes, teamId, sortProp]);

  useEffect(() => {
    switch (playerType) {
      case 'current':
        setCurrentPlayerList(currentPlayers)
        break;
      case 'alumni':
        setCurrentPlayerList(alumniPlayers)
        break;
      default:
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerType, currentPlayers, alumniPlayers]);

  const button = (key, basic, text, oc) => <Button compact basic={basic} color={'blue'} onClick={(e) => { oc(key) }}>{text}</Button>

  const buttons_playerTypes = () => <div style={{ textAlign: 'center' }}><Button.Group size={'mini'} >
    {button('current', (playerType === 'current' ? false : true), 'Current', setPlayerType)}
    {button('alumni', (playerType === 'alumni' ? false : true), 'Alumni', setPlayerType)}
  </Button.Group></div>

  const buttons_sort = () => <div style={{ textAlign: 'center' }}><Button.Group size={'mini'} >
    {button('name', (sortProp === 'name' ? false : true), 'Name', setSortProp)}
    {button('uniformNumber', (sortProp === 'uniformNumber' ? false : true), 'Uniform Number', setSortProp)}
  </Button.Group></div>

  const sortGroup = () => <Label style={{ padding: 0 }} className={'lbl-btns'}>
    {/* <div style={{ paddingLeft: '1em', paddingRight: '1em' }}>Sort By</div> */}
    {buttons_sort()}
  </Label>

  const playerItem = (player, index) => <PlayerItem key={uniqueKey('pl', index)} {...props} player={player} index={index} />

  const playerList = () => {
    if (currentPlayerList && currentPlayerList.length > 0) {
      return currentPlayerList.map((player, index) => (playerItem(player, index)))
    } else {
      return <NoData fullCaption={'No Roster'} />
    }
  }

  const header = () => saTypes.teamOnly ? buttons_playerTypes() : sortGroup()

  let cn = 'athletes-container'

  const wrapper = () => <Wrapper
    header={header()}
    content={playerList()}
    wrapperType={wrapperTypes.paddedHeader}
    css={{ content: cn }}
  ></Wrapper>

  return forList ? playerList() : wrapper()

}

export const PlayerItem = (props) => {

  const { displayType, player, index, levelAthletes, useChecker, handlePlayerClick, handleClick, handleLabelClick, pageItemKey } = props

  const d = new Date()
  const thisYear = d.getFullYear()

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { currentAthletes } = sportsMatch_state ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state, createSports_handlers } = createSportsContext ?? {}
  const { rosters } = createSports_state ?? {}

  const teamOnly = pathViews && pathViews.teams ? true : false
  let cn = 'athlete'
  cn += ' ' + displayType

  const getGradYear = (graduationYear) => {
    const yearDiff = graduationYear - thisYear
    switch (yearDiff) {
      case 0:
        return 'Senior'
      case 1:
        return 'Junior'
      case 2:
        return 'Sophomore'
      case 3:
        return 'Freshman'
      default:
        return 'Alumni (' + graduationYear + ')'
    }
  }

  const checker_match = () => {
    if (createSports_handlers && rosters) {
      return <Checker
        asLabel={true}
        item={player}
        handle_click={handlePlayerClick}
        checked={levelAthletes && levelAthletes[player.key] ? true : false}
        label={player.uniformNumber}
      />
    } else {
      return <Checker
        asLabel={true}
        item={player}
        handle_click={currentAthletes && handlePlayerClick}
        checked={currentAthletes && currentAthletes[player.key] ? true : false}
        label={player.uniformNumber}
      />
    }
  }

  const playerNumber = (value, player) => useChecker ? checker_match() :
    <Label size={'tiny'} color={'blue'} onClick={(e) => { handleLabelClick && player && handleLabelClick(e, player) }}>{value}
    </Label>


  const playerItem = () => {
    //  verticalAlign='middle' 
    const { lastName, firstName, teams, playerPosition, graduationYear, uniformNumber, id, key } = player
    let keyy = id ? id : key
    if (!keyy) { keyy = uniformNumber }
    return <div className={cn} key={uniqueKey('pi', keyy)} onClick={(e) => { handleClick && handleClick(player) }}>
      <div key={uniqueKey(keyy, 1)} className={_cns.icon}><Icon name='user' size='large' /></div>
      <div key={uniqueKey(keyy, 2)} className={_cns.details}>
        <div key={uniqueKey(keyy, 3)} className={_cns.name}>{firstName}{' '}{lastName}</div>
        <div key={uniqueKey(keyy, 4)} className={_cns.team}>{!teamOnly && teams ? teams + ' - ' : ''}{getGradYear(graduationYear)}</div>
        <div key={uniqueKey(keyy, 5)} className={_cns.position}>{playerPosition}</div>
      </div>
      <div key={uniqueKey(keyy, 6)} className={_cns.number}>{playerNumber(uniformNumber, player)}</div>
    </div >
  }

  const golferItem = () => {
    const { lastName, firstName, handicap, handicapIndex, id, key } = player
    const _handicap = handicapIndex ? handicapIndex : handicap
    const keyy = id ? id : key
    return <div className={cn} key={uniqueKey('gi', keyy)} onClick={(e) => { handleClick && handleClick(player) }}>
      <div key={uniqueKey(keyy, 1)} className={_cns.index}><Label>{index + 1}</Label></div>
      <div key={uniqueKey(keyy, 2)} className={_cns.icon}><Icon name='user' size='large' /></div>
      <div key={uniqueKey(keyy, 3)} className={_cns.details}>
        <div key={uniqueKey(keyy, 4)} className={_cns.name}>{lastName}{', '}{firstName}</div>
      </div>
      <div key={uniqueKey(keyy, 5)} className={_cns.number}>{playerNumber(_handicap ? _handicap : '-', player)}</div>
    </div >
  }

  switch (pageItemKey) {
    case 'golfers':
    case 'tournamentGolfers':
      return golferItem()
    default:
      return playerItem()
  }
}

export default PlayerList