import { getDatabase, onValue, ref } from "firebase/database"
import { ammendDataWithStaticViewValues } from "../../common/convert"
// import { getPageData } from "../../firestoreData/appData/getPageData"
import { getRefProps } from "../../firestoreData/helpers/getRefProps"
import { pageDataValues } from "../../firestoreData/helpers/pageDataValues"
import { storateStatusTypes } from "../../storage/removeStorage"
import { fs_get_data } from "../../firestoreData/appData/fsAppData"
import { gEnums } from "../../enums/globalEnums"

const rts = {
  handleGetPageData: 'handleGetPageData',
  handleSetPageData: 'handleSetPageData',
  handleItemDataCounts: 'handleItemDataCounts',
}

export const pageDataReducer = (state, action) => {

  const { itemDataCounts, firestore_fns } = state
  const { dispatch, type } = action

  const { handleSetPageData } = pageDataHandlers(dispatch)

  switch (type) {

    case rts.handleGetPageData:
      getThePageData(action.pageDataProps, state, handleSetPageData, firestore_fns)
      return { ...state }

    case rts.handleSetPageData:
      const { pageDataResults } = action
      return { ...state, pageDateRetrieved: true, ...pageDataResults }

    case rts.handleItemDataCounts:
      itemDataCounts[action.uivi] = action.dataCount
      return { ...state, itemDataCounts }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return { ...state }

    default:
      return { ...state }
  }
}

export const pageDataInitialState = (initState) => {
  return { ...initState, itemDataCounts: {} }
};

export const pageDataHandlers = (dispatch) => {
  return {
    handleGetPageData: (pageDataProps) => { dispatch({ type: rts.handleGetPageData, dispatch, pageDataProps }) },
    handleSetPageData: (pageDataResults) => { dispatch({ type: rts.handleSetPageData, dispatch, pageDataResults }) },
    handleItemDataCounts: (uivi, itemKey, dataCount) => { dispatch({ type: rts.handleItemDataCounts, dispatch, uivi, itemKey, dataCount }) },
  }
}

/** 
 * Gets the data for the page
 * @param {object} pageDataProps 
 * @param {object} state 
 * @param {function} callback 
 */
const getThePageData = (pageDataProps, state, callback, firestore_fns) => {

  const {
    pathName,
    captionProps,
    subCaptionProps,
    caption_page
  } = state

  const {
    data_state: pageData_state,
    staticViews,
    view,
    viewKey,
  } = pageDataProps

  const _dataResultProps = { isPageData: true, view, pageData_state, captionProps, subCaptionProps, caption_page, staticViews }

  if (pathName && viewKey) {
    try {
      getPageData(pageDataProps, callback, firestore_fns)
    } catch (error) {
      console.error(error)
      callback(storateStatusTypes({}, _dataResultProps))
    }
  } else {
    callback(pageDataValues({}, _dataResultProps))
  }
}

const getPageData = async (pageDataProps, callback, firestore_fns) => {

  const {
    appUserAccess,
    data_state,
    googleData_state,
    isPageData,
    page_state,
    paps_state,
    page_fns,
    staticViews,
    view,
    viewKey,
  } = pageDataProps

  const { pageSettings } = page_state
  const { aps_page, aps_global, aps_viewItems } = pageSettings
  const { menu: menu_page, viewItems: viewItems_page } = aps_page ?? {}
  const { captionProps, caption: caption_page, subCaptionProps } = menu_page ?? {}

  const { googleDataOn, googleSheetsData } = googleData_state ?? {}

  let viewItem_g;
  let viewItem_page = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  if (aps_viewItems && aps_viewItems[view]) { viewItem_g = aps_viewItems[view] }

  const { nonLandingView, pathViews, pathName } = paps_state ?? {}
  const { dataOptions, googleSheets } = aps_global ?? {}

  const { dataLimit, limitDevelopmentData, hideInactiveItems: hideInactiveItems_do } = dataOptions ?? {}
  const { googleSheetsId } = googleSheets ?? {}

  const grpp = {
    stateProps: {
      appUserAccess,
      aps_viewItems,
      dataLimit,
      googleSheetsId,
      hideInactiveItems_do,
      limitDevelopmentData,
      nonLandingView,
      pathViews,
      page_fns,
      staticViews,
    },
    actionProps: {
      pathName,
      uivi: view,
      view,
      viewItem_g,
      viewItem: viewItem_page,
      viewKey,
    }

  }

  const refProps = getRefProps(grpp)

  // firestoreRefs
  let { refPath, refPaths, wheres } = refProps
  const { dataConnection } = viewItem_g ?? {}

  const { firebaseType, useGoogleSheetRealtime, documentDependencies } = dataConnection ?? {}
  const trueDbType = useGoogleSheetRealtime ? gEnums.firebaseTypes.realtimeDatabase : firebaseType

  const _dataResultProps = { isPageData, view, viewItem_page, data_state, captionProps, subCaptionProps, caption_page, staticViews }

  const { dataSource } = viewItem_page ?? {}
  const { dataSourceType } = dataSource ?? {}

  const dataCallback = (res, a, fsr) => {
    ammendDataWithStaticViewValues(staticViews, res)
    callback(pageDataValues(res, _dataResultProps))
  }

  switch (dataSourceType) {
    case gEnums.dataSourceTypes.appComponent:
    case gEnums.dataSourceTypes.component:
      callback(pageDataValues({}, _dataResultProps))
      break;
    default:
      switch (view) {
        case 'home':
          callback(pageDataValues({}, _dataResultProps))
          break;

        default:
          switch (trueDbType) {
            case gEnums.firebaseTypes.realtimeDatabase:
              const db = getDatabase();
              const _ref = ref(db, refPath);
              onValue(_ref, (snapshot) => {
                let resultData = snapshot
                callback(pageDataValues(resultData, _dataResultProps))
              })
              break;
            default:
              if (googleDataOn && googleSheetsData && googleSheetsData[view] && googleSheetsData[view][viewKey]) {
                const d = googleSheetsData[view][viewKey]
                callback(d, _dataResultProps)
              } else {
                if (documentDependencies && documentDependencies.length > 0) {
                  callback(pageDataValues(data_state[documentDependencies[0]], _dataResultProps))
                } else {
                  const gdProps = { refPath: refPaths, wheres, callback: dataCallback, opts: { listen: true } }
                  if (firestore_fns) {
                    firestore_fns.handle_getData(gdProps)
                  } else {
                    fs_get_data(gdProps)
                  }
                }
              }
          }
      }
  }


}
