import { arrayRemove } from 'firebase/firestore';
import { createRefPath, createRefPath_event } from '../appData/appRefPaths';
import { fs_update_doc } from '../appData/fsData';

export const updateAppUserProfile = (docId, dataToUpdate, callback) => {
  const _updateRef = createRefPath(['profiles', docId])
  fs_update_doc(_updateRef, dataToUpdate, callback)
}

export const removeFcmTokenFromTokens = async (dbTopics, fcmToken, pathViews, topicCollection) => {

  const promises = []

  // eslint-disable-next-line
  if (dbTopics) {
    Object.keys(dbTopics).map((topicKey) => {
      const _ref = createRefPath_event(pathViews, [topicCollection, topicKey])
      const _dataToUpdate = { fcmTokens: arrayRemove(fcmToken) }
      promises.push(fs_update_doc(_ref, _dataToUpdate, false, true))
    });
  }

  return Promise.all(promises)

};