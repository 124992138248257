import { createRefPath_client, createRefPath_event } from "../../firestoreData/appData/appRefPaths";
import { fs_get_data } from "../../firestoreData/appData/fsAppData";
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

export const rts = {
  handleInit_appUsers: 'handleInit_appUsers',
  handleSet_profiles: 'handhandleSet_profilesleSetProfiles',
  handleSet_eventAppUsers: 'handleSet_eventAppUsers',
  ...grts
}

export const appUsersReducerInitialState = (initState) => {
  return { ...initState }
};

export const appUsersReducer = (state, action) => {

  const { pathViews } = state
  const { type, dispatch } = action

  const { handleSetProfiles } = appUsersHandlers(dispatch)

  switch (type) {

    case rts.handleInit_appUsers:
      // getProfileData(pathViews, action.appUserCollections, handleSetProfiles)
      return { ...state }

    case rts.handleSet_eventAppUsers:
      return { ...state, eventAppUsers: action.eventAppUsers }

    case rts.handleSet_profiles:
      const { profileData } = action
      const _stateData = {}
      if (profileData) {
        Object.keys(profileData).forEach(k => {
          _stateData[k] = profileData[k]
        })
      }
      console.log('_stateData', _stateData)
      return { ...state, ..._stateData, profilesRetrieved: true }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const appUsersHandlers = (dispatch) => {
  return {
    handleInit_appUsers: (appUserCollections) => { dispatch({ type: rts.handleInit_appUsers, dispatch, appUserCollections }) },
    handleSet_eventAppUsers: (eventAppUsers) => { dispatch({ type: rts.handleSet_eventAppUsers, dispatch, eventAppUsers }) },
    handleSetProfiles: (profileData) => { dispatch({ type: rts.handleSet_profiles, dispatch, profileData }) },
    ...responseHandlers(dispatch)
  }
}

/**
 * Promises client/profiles AND client/event/appUserCollection
 * @param {object} pathViews 
 * @param {string} appUsersCollections 
 * @param {function} callback 
 */
const getProfileData = async (pathViews, appUsersCollections, callback) => {

  const getTheProfileData = async () => {
    const promises = []
    const refPath_profiles = createRefPath_client(pathViews, ['profiles'])
    promises.push(fs_get_data({ refPath: refPath_profiles }))
    if (appUsersCollections) {
      appUsersCollections.forEach(appUsersCollection => {
        const refPath_appUsers = createRefPath_event(pathViews, [appUsersCollection])
        promises.push(fs_get_data({ refPath: refPath_appUsers }))
      })
    }
    return Promise.all(promises)
  }

  const d = await getTheProfileData()
  const data = {}

  if (d[0]) {
    data['appUserProfiles'] = d[0]
    if (appUsersCollections) {
      appUsersCollections.forEach((appUsersCollection, index) => {
        if (d[index + 1]) { data[appUsersCollection] = d[index + 1] }
      })
    }
  }

  callback(data)

}