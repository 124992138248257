import _ from 'lodash';
import { hashmark, useHash } from '../../../App';
import { gEnums } from '../../enums/globalEnums';
import { allPageTypes } from '../../enums/allPageTypes';
import { getBaseRef } from '../../firestoreData/helpers/getBaseRef';
import { currentHelpers } from '../../redirection/current';
import { getAreaSettingsProps } from '../../viewSettings/helpers/pageSettings';
import { clickToMethods } from './ElementReducer';

export const pageInitialState = (page_init) => {
  const { homeAppUrls } = page_init
  return {
    homeAppUrls,
    pageNavs: {}
  }
};

const rts = {
  getGviDeps: 'getGviDeps',
  goBackTo: 'goBackTo',
  handleAmmend_pageSettingsFromGoogleSheets: 'handleAmmend_pageSettingsFromGoogleSheets',
  handleGetPageSettings: 'handleGetPageSettings',
  handlePageNav: 'handlePageNav',
  handleUpdatePageDataCaption: 'handleUpdatePageDataCaption',
  pushDrillDown: 'pushDrillDown',
  pushSimple: 'pushSimple',
  pushUrl: 'pushUrl',
  syncViewItems: 'syncViewItems',
}

export const pageReducer = (state, action) => {

  switch (action.type) {
    case rts.handleGetPageSettings:
      const _pss = getAreaSettingsProps(action.aspProps)
      return { ...state, pageSettings: _pss, aspProps: action.aspProps }

    case rts.handleAmmend_pageSettingsFromGoogleSheets:
      const { appSettings_google } = action
      const { aps_viewItems, aps_views } = appSettings_google ?? {}
      const _aspProps = { ...state.aspProps }
      const { pss } = _aspProps ?? {}
      const { area_settings } = pss ?? {}
      const { viewItems, views } = area_settings ?? {}

      function customMerge(objValue, srcValue, key) {
        if (key === 'show') {
          if (srcValue === false && objValue === true) {
            return srcValue
          } else {
            return objValue
          }
        }
      }

      const asvi = _.mergeWith({}, viewItems, aps_viewItems, customMerge);
      const asv = _.mergeWith({}, views, aps_views, customMerge);

      area_settings.viewItems = asvi
      area_settings.views = asv

      const _pss2 = getAreaSettingsProps(_aspProps)
      console.log('_pss2', _pss2)
      return { ...state, pageSettings: _pss2 }

    case rts.handleUpdatePageDataCaption:
      return { ...state, pageDataCaption: action.pageDataCaption, pageDataSubCaption: action.pageDataSubCaption }

    case rts.handlePageNav:
      const { navigationOptions, uiItemContext } = action
      const { item_state } = uiItemContext ?? {}
      const { viewItem_key } = item_state ?? {}
      const _pageNavs = state.pageNavs
      const _pageNav = {
        navOptions: navigationOptions,
        navUiItemContext: uiItemContext
      }
      if (viewItem_key) {
        _pageNavs[viewItem_key] = _pageNav
      }
      return { ...state, pageNav: _pageNav, pageNavs: _pageNavs, currentPageNav: viewItem_key }

    case rts.getGviDeps:
      return { ...state }

    default:
      return { ...state }
  }
}

export const pageHandlers = (dispatch) => {
  return {
    handleAmmend_pageSettingsFromGoogleSheets: (appSettings_google) => dispatch({ type: rts.handleAmmend_pageSettingsFromGoogleSheets, appSettings_google }),
    handlePageNav: (navigationOptions, uiItemContext) => dispatch({ type: rts.handlePageNav, navigationOptions, uiItemContext }),
    handleGetPageSettings: (aspProps) => dispatch({ type: rts.handleGetPageSettings, aspProps }),
    handleUpdatePageDataCaption: (pageDataCaption, pageDataSubCaption) => dispatch({ type: rts.handleUpdatePageDataCaption, pageDataCaption, pageDataSubCaption }),
  }
}

/** returns a set of function for the page */
export const pageFunctions = (page_state, isAppDeveloper) => {
  return {
    getGviDeps: (aps_viewItems, uivi, viewKey, aps_page) => getGviDeps(aps_viewItems, uivi, viewKey, aps_page),
    getViRef: (aps_viewItems, uivi, viewKey, pathViews, aps_page) => getViRef(aps_viewItems, uivi, viewKey, pathViews, aps_page),
    goBackTo: (navigate, count) => goBackTo(navigate, count),
    pushDrillDown: (e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps) => pushDrillDown(e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps),
    pushSimple: (psProps) => truePushSimple(psProps, isAppDeveloper),
    pushUrl: (e, url) => pushUrl(e, url),
    syncViewItems: (gvs, vis, vs) => syncViewItems(gvs, vis, vs),
    getVi: (vi, isGlobal) => getVi(vi, isGlobal, page_state),
  }
}

const getViRef = (aps_viewItems, uivi, viewKey, pathViews, aps_page) => {
  const viDeps = getGviDeps(aps_viewItems, uivi, viewKey, aps_page)
  const baseRef = getBaseRef(viDeps, pathViews)
  return baseRef
}

const getVi = (vi, isGlobal, page_state) => {
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_viewItems } = pageSettings ?? {}
  const { viewItems } = aps_page ?? {}
  return isGlobal ? aps_viewItems[vi] : viewItems[vi]
}

const getGviDeps = (aps_viewItems, uivi, viewKey, aps_page) => {

  const viewItem_page = aps_page && aps_page.viewItems && aps_page.viewItems[uivi] ? aps_page && aps_page.viewItems && aps_page.viewItems[uivi] : null;
  const viewItem_app = aps_viewItems && aps_viewItems[uivi] && aps_viewItems[uivi] ? aps_viewItems[uivi] : null

  // dataSource 
  const { dataConstraints, dataSource } = viewItem_page ?? {}
  const _dataConstraints = dataConstraints ? dataConstraints : dataSource
  const { dataParents: dataParents_page, useSeasonals, dataLinkType, useSubDataCollection, subDataCollectionName } = _dataConstraints ?? {}
  const { dependencies, documentDependencies, dataParents } = viewItem_app && viewItem_app.dataConnection ? viewItem_app.dataConnection : {}

  if (dependencies || dataParents || dataParents_page) {
    const dpp = dataParents_page ? dataParents_page : dataParents
    return { dependencies, documentDependencies, dataParents: dpp, useSeasonals, dataLinkType, useSubDataCollection, subDataCollectionName }
  } else {
    let deps = null
    switch (uivi) {
      case allPageTypes.home:
        break;

      case allPageTypes.clients:
        if (viewKey) {
          deps = [allPageTypes.clients]
        }
        break;

      case allPageTypes.events:
        deps = [allPageTypes.clients]
        break;

      default:
        // deps = null
        deps = [allPageTypes.clients, allPageTypes.events]
        break;
    }

    return { dependencies: deps, documentDependencies, dataParents: null, useSeasonals, dataLinkType, useSubDataCollection, subDataCollectionName }
  }
}

/** Returns the new path for a clicked item */
const getNewPath = (aps_viewItems, aps_page, paps_state, itemView, itemKey, modifyMode, landingView, pathNameH) => {

  const { mainViews, lastRootPath, lastPathName } = paps_state ?? {}

  const pathViews = currentHelpers.getPathViews(pathNameH)

  let basePath = '';
  let newPath = '';

  switch (itemView) {

    case 'home':
      newPath = '/'
      break;

    case 'allClients':
      newPath = '/clients'
      break;

    case 'client':
      newPath = '/clients/' + pathViews['clients']
      break;

    default:

      if (modifyMode) {

        switch (lastPathName) {
          case landingView:
            newPath = lastRootPath
            break;
          default:
            newPath = pathNameH
        }

        switch (modifyMode) {
          case 'add':
            newPath += '/' + modifyMode
            break;
          case 'edit':
            newPath += '/' + modifyMode
            break;
          case 'viewSettings':
            newPath += '/' + modifyMode
            break;
          case 'qrCode':
            newPath += '/qrCode'
            break;
          case 'notifications':
            newPath += '/notifications'
            break;
          default:
          // nothing
        }

      } else {

        const x = getGviDeps(aps_viewItems, itemView, null, aps_page)
        const { dependencies, documentDependencies, dataParents } = x
        const pvKeys = Object.keys(pathViews)

        let _dataParents = dataParents

        switch (itemView) {
          case 'media':
            _dataParents = ['organizations', 'districts', 'sports']
            break;
          default:
          // nothing
        }

        if (dependencies && pathViews) {
          Object.keys(dependencies).forEach(key => {
            const dep = dependencies[key]
            if (pathViews[dep]) {
              basePath += '/' + dep + '/' + pathViews[dep]
            }
          })
        }

        if (documentDependencies) {
          documentDependencies.forEach(dd => {
            if (pvKeys.includes(dd)) {
              basePath += '/' + dd + '/' + pathViews[dd]
            }
          })
        }

        // IMPORTANT: add path for dataParents
        if (_dataParents && pathViews) {
          Object.keys(_dataParents).forEach(key => {
            const dep = _dataParents[key]
            if (pathViews[dep]) {
              basePath += '/' + dep + '/' + pathViews[dep]
            }
          })
        }

        newPath = basePath

        if (itemView) {
          newPath += '/' + itemView
          if (itemKey) {
            newPath += '/' + itemKey
            if (mainViews.includes(itemView)) {
              newPath += '/' + landingView
            }
          }
        }
      }
  }

  return newPath

}

const goBackTo = (navigate, count) => {
  if (count) {
    navigate(-(count))
  } else {
    navigate(-1)
  }
}

// const pushSignIn = (navigate) => navigate('/signIn', '')

// IMPORTANT: truePushSimple
const truePushSimple = (psProps, isAppDeveloper) => {
  const {
    appUserAccess,
    aps_viewItems,
    aps_page,
    homeSettingsContext,
    papsContext,
    clickedItem,
    itemKey,
    modifyMode,
    opts,
    ddn,
    ddProps,
    ddGroupIndex,
    // weatherZip,
  } = psProps
  // item edits and add handled from `dataReducer`     

  let { key: itemView, drillDown, itemKey: itemKey_clicked } = clickedItem ?? {}

  const clickTos = {
    app: false,
    detect: false,
    noClick: false,
    page: false,
    pdf: false,
    url: false,
  }

  if (clickTos.noClick && appUserAccess && (appUserAccess.accessLevel <= gEnums.accessLevels.admin)) { return false }

  const { homeSettings_state } = homeSettingsContext ?? {}
  const { homeSettings } = homeSettings_state ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { appUrls: homeAppUrls, appLanding, appShortUrls } = global_home ?? {}
  const { useAppLanding, appLandingUrls } = appLanding ?? {}
  const { useAppShortUrls, appLandingShortUrls } = appShortUrls ?? {}

  const { hash, pathname } = window.location
  const pathNameH = useHash ? hash.replace(hashmark + '/', '/') : pathname

  const { paps_state, navigate } = papsContext
  const { pathName, pathViews, landingView } = paps_state ?? {}

  const _isClient = pathViews.clients
  const _isEvent = pathViews.events

  let { clickTo } = opts ?? {}
  let { clickToMethod, page: click_toPage, ignoreClickMethod } = clickTo ?? {}

  if (clickTo) {
    switch (clickToMethod) {
      case clickToMethods.noClick:
        if (!ignoreClickMethod) {
          console.log('NO CLICK')
          return false
        }
      case clickToMethods.openAppUrl:
      case clickToMethods.openAppPdf:
        if (!ignoreClickMethod) {
          openAppUrl(clickTo.value)
          return false
        }
        break;
      case clickToMethods.page:
        itemView = click_toPage
        break;
      case clickToMethods.normal:
        break;
      default:
      // nothing
    }
  }

  const { itemData, directPath } = opts ?? {}
  if (itemData && itemData.as && itemData.as !== itemView) { itemView = itemData.as }

  const _itemKey = itemKey_clicked ? itemKey_clicked : itemKey

  let newPath = ddProps ? pathName : getNewPath(aps_viewItems, aps_page, paps_state, itemView, _itemKey, modifyMode, landingView, pathNameH)

  if (opts && opts.pageExt) { newPath += '/' + opts.pageExt }

  window.localStorage.removeItem('isBack')

  const _host = document.location.host

  switch (newPath) {
    case '/landing':
      newPath = '/'
      break;
    default:
    // nothing
  }

  // IMPORTANT: Push
  if (directPath) {
    navigate(directPath)
  } else {
    if (drillDown && drillDown.useDrillDown && opts) {
      let newDDGroupIndex = ddGroupIndex ? ddGroupIndex + 1 : 2
      navigate(newPath + '/ddg/' + newDDGroupIndex)
    } else if (ddn) {
      navigate(currentHelpers.getSearchPath(newPath, ddProps, ddn))
    } else {
      if (pathNameH !== newPath) {
        if (useAppLanding && appLandingUrls && !isAppDeveloper && !_isClient && !_isEvent) {
          appLandingUrls.forEach(appLandingUrl => {
            const { host, hostUrl, baseUrl } = appLandingUrl
            if ((_host === host) && newPath.indexOf(baseUrl) < 0) {
              newPath = hostUrl
            }
          })
        }
        if (useAppShortUrls && appLandingShortUrls && !isAppDeveloper && !_isClient && !_isEvent) {
          const svs = currentHelpers.getQueryParamsObject()
          appLandingShortUrls.forEach(appLandingShortUrl => {
            const { shortUrl, redirctUrl } = appLandingShortUrl
            if ((svs === '/' + shortUrl)) {
              newPath = redirctUrl
            }
          })
        }
        currentHelpers.storageItem_set('lastPath', pathName);
        currentHelpers.storageItem_set('routeDepth', Object.keys(pathViews).length);
        navigate(newPath)
      } else {
      }
    }
  }
}

/** Opens an appUrl in another window */
const openAppUrl = (appUrl) => { window.open(appUrl, '_blank') }

const pushDrillDown = (aps_viewItems, e, homeSettingsContext, papsContext, viewItem, itemData, pvKey, propItem, ddProps) => {
  e.stopPropagation()
  const ddn = {
    key: propItem.key,
    value: pvKey
  }
  truePushSimple(aps_viewItems, homeSettingsContext, papsContext, viewItem, itemData.id, null, null, ddn, ddProps)
}

const pushUrl = (e, url) => {
  if (url.indexOf('https://') < 0) { url = 'https://' + url }
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
  window.open(url, '_blank');
}

const syncViewItems = (gvs, vis, vs) => {
  const { viewItems } = vs ?? {}
  if (vis && viewItems) {
    Object.keys(viewItems).forEach(key => {
      const vi = viewItems[key]
      const gvi = vis[key]
      if (gvi) {
        if (!vi.key) { vi.key = key }
        if (!gvi.key) { gvi.key = key }
        if (vi && gvi) {
          Object.keys(vi).forEach(k => {
            const vii = vi[k]
            const gii = gvi[k]
            if (vii && gii && _.isObject(vii) && _.isObject(gii) && Object.keys(vii).length > 0 && Object.keys(gii).length > 0) {
              switch (k) {
                case 'display':
                  vii.caption = vii.caption ? vii.caption : gii.caption
                  vii.defaultIcon = gii.defaultIcon
                  break;
                // case 'ui':
                //   vii.caption = vii.caption ? vii.caption : gii.caption
                //   vii.defaultIcon = gii.defaultIcon
                //   break;
                default:
                // nothing
              }
            }
          })
        }
      }

    })
  }
}