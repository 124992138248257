import React, { useContext, useEffect, useReducer } from "react";
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sportsDistrictHandlers, sportsDistrictInitialState, sportsDistrictReducer } from "../reducers/SportsDistrictReducer";
import { FirestoreContext } from "../../../../global/cnr/contexts/FirestoreContext";

export const SportsDistrictContext = React.createContext()

export default function SportsDistrictProvider(props) {

  const { directReturn } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states ?? {}
  const { appUser_handlers } = handlers ?? {}

  // appUserContext
  const { appUser } = appUser_state ?? {}
  const { uid } = appUser ?? {}

  // papsContext 
  const { view, pathViews } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_global } = pageSettings ?? {}
  const { sportPermissions } = aps_global ?? {}

  const { viewItems: viewItems_page } = aps_page ?? {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ?? {}
  const { dataParents, useSeasonals } = dataSource ?? {}

  // organizations/districts/sports
  // organizations/sports

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)
  const { firestore_fns } = firestoreContext ?? {}

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const init_state = { sportPermissions, firestore_fns, pathViews, viewItem, dataParents, useSeasonals, uid, ownerPath, inverted: false, cssOpts: { name: 'ssc-normal' } }
  const [sportsDistrict_state, sportsDistrict_dispatch] = useReducer(sportsDistrictReducer, init_state, sportsDistrictInitialState)
  const sportsDistrict_handlers = sportsDistrictHandlers(sportsDistrict_dispatch, sportsDistrict_state)
  const { sports } = sportsDistrict_state ?? {}

  useEffect(() => {
    if (pathViews.districts) {
      sportsDistrict_handlers.handleInit_sportsDistrict(pathViews.districts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.districts])

  useEffect(() => {
    if (sports) {
      appUser_handlers.handleAmmend_favs(sports)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sports])

  if (sportsDistrict_state) {
    if (directReturn) {
      return { sportsDistrict_handlers, sportsDistrict_state }
    } else {
      return <SportsDistrictContext.Provider
        value={{ sportsDistrict_state, sportsDistrict_handlers, appComponent: 'sportsDistrict' }}>
        {props.children}
      </SportsDistrictContext.Provider>
    }
  } else {
    return <SportsDistrictContext.Provider
      value={{}}>
      {props.children}
    </SportsDistrictContext.Provider>
  }
}