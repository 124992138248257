import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import { List, Segment } from 'semantic-ui-react'
import { AppComponentContext } from '../../../global/cnr/contexts/AppComponentContext'
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper'
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons'

const CreateWeeklyLabRun = (props) => {

  // labContext
  const labContext = useContext(AppComponentContext)
  const { lab_state, lab_handlers } = labContext ?? {}
  const { updating, runs, weeklyLabSamples, weeklyLabRuns_grouped, currentYear, currentWeek } = lab_state ?? {}

  console.log('lab_state', lab_state)

  const hasCurrent = false // weeklyLabSamples && currentWeek ? true : false

  useEffect(() => {
    lab_handlers.handleCreate_weeklyLabRun()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleUpdate = () => {
    lab_handlers.handleStartUpdate()
    lab_handlers.handleUpdate_weeklyLabRun()
  }

  const content_runs = (_itemKey) => {
    if (weeklyLabRuns_grouped && weeklyLabRuns_grouped[_itemKey]) {
      const wlr = weeklyLabRuns_grouped[_itemKey]
      const listItems = wlr.map(lr => (
        <List.Item>{lr.name}</List.Item>
      ))
      return <List divided relaxed>
        {listItems}
      </List>
    } else {
      return 'No Runs'
    }
  }
  const content = () => {
    const _runs = _.orderBy(runs, 'name')
    return _runs.map(run => {
      const { name, _itemKey } = run ?? {}
      return <Segment.Group>
        <Segment>{name}</Segment>
        <Segment>{content_runs(_itemKey)}</Segment>
      </Segment.Group>
    })
  }

  const footer = () => {
    const btns = []
    btns.push({ oc: handleUpdate, caption: 'Update', icon: 'save', color: 'green', disabled: hasCurrent })
    return <UiSaveButtons others={btns} />
  }

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
    updating={updating}
  ></Wrapper>

  const fpw = () => <FullPageWrapper
    handleCancel={props.handleClose}
    content={wrapper()}
    topperCaption={'Create Weekly Lab Runs'}
    topperCaption2={currentYear + ' / Week Number: ' + currentWeek}
  ></FullPageWrapper>

  return fpw()

}

export default CreateWeeklyLabRun
