import React, { createContext, useContext, useReducer } from 'react';
import { clientInfoHandlers, clientInfoInitialState, clientInfoReducer } from '../reducers/ClientInfoReducer';
import { PapsContext } from './PapsContext';


/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const ClientInfoContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const ClientInfoProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext
  const { pathViews } = paps_state ?? {}

  // clientInfoInitialState
  const initState = clientInfoInitialState({ pathViews })
  const [clientInfo_state, dispatch] = useReducer(clientInfoReducer, initState);
  const clientInfo_handlers = clientInfoHandlers(dispatch)

  const context = () => {
    return <ClientInfoContext.Provider value={{ clientInfo_state, clientInfo_handlers }}>
      {props.children}
    </ClientInfoContext.Provider>
  }

  return context()
}

export default ClientInfoProvider