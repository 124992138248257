import React from 'react'
import { Label, Segment } from 'semantic-ui-react'

const StandingsLegend = () => {
  return (
    <Segment basic className='standings-legend'>
      <Label>Playoff</Label>
      <Label>Playoff Position</Label>
      <Label>Non Playoff Position</Label>
      <Label>Non Playoff</Label>
    </Segment>
  )
}

export default StandingsLegend
