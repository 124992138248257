import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { List, Segment } from 'semantic-ui-react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import { uniqueKey } from '../../common/keys';
import { gEnums } from '../../enums/globalEnums';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';

const UserEvents = () => {

  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ?? {}
  const { appUser_state, paps_state, clientInfo_state } = states
  const { clientInfo_handlers } = handlers
  const { clientEvents } = clientInfo_state
  const { page_fns } = fns

  // authContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { displayName, events: events_appUser, clientAccessKey } = appUserAccess ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}
  const { events: eventKey } = pathViews ?? {}

  // pageContext 
  const { pushSimple } = page_fns ?? {}

  let noEventsCaption = !pathViews.clients ? 'You do not have any events. The Client is unknown.' : 'You do not have any events.'
  if (!events_appUser) { noEventsCaption = 'Searching Events' }

  const [allowedEvents, setAllowedEvents] = useState({})

  useEffect(() => {
    clientInfo_handlers.handleGet_clientEvents(pathViews)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    const _allowedEvents = {}
    if (clientEvents && events_appUser) {
      const _clientEvents = _.filter(clientEvents, { eventStatusType: gEnums.eventStatusTypes.active })
      _.each(_clientEvents, (clientEvent) => {
        const { _itemKey } = clientEvent
        if (events_appUser[_itemKey]) {
          _allowedEvents[_itemKey] = events_appUser[_itemKey]
        }
      })
    }
    setAllowedEvents(_allowedEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [clientEvents]);

  const handleGoToEvent = (e, key) => {
    // clickedItem, itemKey, modifyMode, opts
    if (clientAccessKey) {
      const opts = { directPath: '/clients/' + clientAccessKey + '/events/' + key }
      if (eventKey !== key) {
        pushSimple({ key: 'events' }, key, null, opts)
      }
    } else {
      if (eventKey !== key) {
        pushSimple({ key: 'events' }, key)
      }
    }
  }

  const eventList = () => {
    const lis = []
    if (allowedEvents && Object.keys(allowedEvents).length > 0) {
      _.each(allowedEvents, (event, key) => {
        let { name } = event
        if (!name && clientEvents && clientEvents[key]) {
          name = clientEvents[key].name
        }
        lis.push(
          <List.Item key={uniqueKey('ue', 'li', key)} onClick={(e) => { handleGoToEvent(e, key) }}>
            <List.Icon name='calendar check outline' size='large' verticalAlign='middle' color={eventKey === key ? 'green' : 'grey'} />
            <List.Content>
              <List.Header >{name ? name : key}</List.Header>
              <List.Description as='a'>{event.startDate}</List.Description>
            </List.Content>
          </List.Item>
        )
      })
      return <div key={uniqueKey('ue', 'd')} className={'c-container'} >
        <div className={'c-content'} >
          < List divided relaxed >
            {lis}
          </List ></div>
      </div>
    } else {
      return <div key={uniqueKey('ue', 'd')} className={'c-container'} >
        <div className={'c-content padd'}>{noEventsCaption}</div></div>
    }
  }

  const header = () => displayName ? 'Active Events for ' + displayName : 'Events'

  return <Wrapper
    header={!eventKey && header()}
    content={<Segment basic>{eventList()}</Segment>}
    wrapperType={wrapperTypes.padded}
  />

}

export default UserEvents