import { parse, set } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import _ from "lodash";

export const formatTypes = {
  allHours: 'allHours',
  ampm: 'ampm',
  ampmShort: 'ampmShort',
  date: 'date',
  dateAndTime: 'dateAndTime',
  dateDayMonth: 'dateDayMonth',
  dateMenu: 'dateMenu',
  dateOnly: 'dateOnly',
  dayMonth: 'dayMonth',
  dayOfWeek: 'dayOfWeek',
  fullDate: 'fullDate',
  fullDateTimeZone: 'fullDateTimeZone',
  monthYear: 'monthYear',
  shortDate: 'shortDate',
  shorterDate: 'shorterDate',
  shortestDate: 'shortestDate',
  time: 'time',
  time24: 'time24',
  timeAmPm: 'timeAmPm',
  timeAmPmShort: 'timeAmPmShort',
}


export const dateOptions_fns = {
  basic: 'yyyy-MM-dd',  // 2022-03-07
  dateAndTime: 'yyyy-MM-dd HH:mm',  // 2022-03-07 15:30
  dateDayMonth: 'EEE, MM/dd',  // Mon, 03/07
  dateMenu: 'MM/dd',  // 03/07
  dateOnly: 'LLLL d',  // March 7
  dayMonth: 'MM/dd',  // 03/07
  dayOfWeek: 'EEEE',  // Monday
  fullDate: 'EEEE, LLLL d, yyyy',  // Monday, March 7, 2022
  fullDateTimeZone: 'EEE, MMM d, yyyy HH:mm:ss XXXX',  // Mon, Mar 7, 2022 15:30:00 -07:00 
  monthYear: 'LLLL yyyy',  // March 2022
  shortDate: 'EEE, MMM d, yyyy',  // Mon, Mar 7
  shorterDate: 'MMM d, yyyy',  // Mar 7
  shortestDate: 'MM/dd/yyyy',  // 03/07/2022
  time: 'HH:mm',  // 15:30
  timeAmPm: 'hh:mm a',  // 3:30 PM
  timeAmPmShort: 'h:mm a',  // 3:30 PM
  time24: 'HH:mm',  // 15:30
};

function addZeroBefore(n) {
  return (n < 10 ? '0' : '') + n;
}

export const dateHelpers = {
  ammendAllDates: (allItems) => ammendAllDates(allItems),
  ammendDates: (items) => ammendDates(items),
}

export function getISOWeek(date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const pastDaysOfYear = (date - firstDayOfYear) / millisecondsPerDay;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export const getNow = () => {
  const currentdate = new Date();
  return addZeroBefore((currentdate.getMonth() + 1)) + "/"
    + addZeroBefore(currentdate.getDate()) + "/"
    + currentdate.getFullYear() + " @ "
    + addZeroBefore(currentdate.getHours()) + ":"
    + addZeroBefore(currentdate.getMinutes()) + ":"
    + addZeroBefore(currentdate.getSeconds());
}

export const getDateFromMDY = (dateString) => {
  const dateParts = dateString.split('/');
  const month = parseInt(dateParts[0], 10);
  const day = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);
  const d = new Date(year, month - 1, day); // Subtract 1 from month since months are zero-indexed
  return d
}

export const getFormattedDate = (date) => {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

/**
 * 
 * @param {*} secs 
 * @returns seconds
 */
export const toDateTime = (secs) => {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

// export const toDateDate = (date) => {
//   var t = new Date(date); // Epoch
//   const secs = t.getSeconds()
//   t.setSeconds(secs);
//   return t;
// }

/**
 * 
 * @param {date} date 
 * @returns dow, monthDay, year, month, day
 */
export const dateTimeSplits = (date) => {
  let df = { month: 0, day: 0, year: 0, dow: '?', monthDay: 0 }
  try {
    const _date = new Date(date)
    const _dateFormatted = formatItem(formatTypes.shortDate, _date)
    const dates = _dateFormatted.split(',')
    df = {
      dow: dates ? dates[0] : null,
      monthDay: dates ? dates[1] : null,
      year: dates ? dates[2] : null,
      month: 0,
      day: 0
    }
    if (df.monthDay) {
      const mdSplit = df.monthDay ? df.monthDay.trim().split(' ') : []
      if (mdSplit.length === 2) {
        df.month = mdSplit[0]
        df.day = mdSplit[1]
      }
    }
  } catch (error) {
    console.error(error)
  }
  return df
}

const convertDateString = (dateValue, dateOption) => {
  if (_.isDate(dateValue)) {
    // If it's already a Date object, format it directly
    try {
      return format(dateValue, dateOption);
    } catch (error) {
      console.error('convertDateString Error:', dateValue, dateOption, error);
    }
  }
  if (_.isString(dateValue)) {
    // If it's a string, attempt to parse it and then format
    try {
      const nd = new Date(dateValue)
      return format(nd, dateOption);
    } catch (error) {
      console.error('convertDateString:', dateValue, dateOption, error);
    }
  }
}

const formatDate = (vatz, dateOption, formatType) => {

  const { value, timeZone } = vatz
  let _value;

  try {
    _value = value && _.isString(value) && value.indexOf('-') >= 0 ? value + ' 12:00 UTC' : value
  } catch (error) {
    _value = value
  }

  if (value && value.length === 4) {
    return value
  }

  const _value_s = convertDateString(value, dateOption)
  // const parsedDate = isDate(_value_s) ? value : parse(_value_s, dateOption, new Date());
  // const _dateFormatted = format(parsedDate, dateOption);

  return _value_s
}

const dateAndTime = (vatz) => { return formatDate(vatz, dateOptions_fns.dateAndTime) }
const dateMenu = (vatz) => { return formatDate(vatz, dateOptions_fns.dateMenu) }
const dayMonth = (vatz) => { return formatDate(vatz, dateOptions_fns.dayMonth) }
const dateDayMonth = (vatz) => { return formatDate(vatz, dateOptions_fns.dateDayMonth) }
const dateOnly = (vatz) => { return formatDate(vatz, dateOptions_fns.dateOnly) }
const dayOfWeek = (vatz) => { return formatDate(vatz, dateOptions_fns.dayOfWeek) }
const fullDate = (vatz) => { return formatDate(vatz, dateOptions_fns.fullDate) }
const monthYear = (vatz) => { return formatDate(vatz, dateOptions_fns.monthYear) }
const shortDate = (vatz) => { return formatDate(vatz, dateOptions_fns.shortDate) }
const shorterDate = (vatz) => { return formatDate(vatz, dateOptions_fns.shorterDate) }
const shortestDate = (vatz) => { return formatDate(vatz, dateOptions_fns.shortestDate, formatTypes.shortestDate) }

const time = (vatz) => { return formatTime(vatz.value, dateOptions_fns.time) }
const timeAmPm = (vatz) => { return formatTime(vatz.value, dateOptions_fns.timeAmPm) }
const timeAmPmShort = (vatz) => { return formatTime(vatz.value, dateOptions_fns.timeAmPmShort) }
const time24Hour = (vatz) => { return formatTime(vatz.value, dateOptions_fns.time24) }

/**
 * 
 * @param {string} formattingType 
 * @param {value} value 
 * @returns athe formatted `value` based on the `formattingType`
 */
export const formatItem = (formattingType, value, timeZone) => {

  try {
    let dateValue;
    if (value && value.seconds) {
      const x = toDateTime(value.seconds)
      dateValue = x
    } else {
      dateValue = value
    }
    if (!dateValue) { return dateValue }

    const vatz = { value: dateValue, timeZone }

    switch (formattingType) {
      case formatTypes.dateAndTime:
        return dateAndTime(vatz)
      case formatTypes.date:
      case formatTypes.fullDate:
        return fullDate(vatz)
      case formatTypes.dateOnly:
        return dateOnly(vatz)
      case formatTypes.dateMenu:
        return dateMenu(vatz)
      case formatTypes.dayOfWeek:
        return dayOfWeek(vatz)
      case formatTypes.monthYear:
        return monthYear(vatz)
      case formatTypes.shortDate:
        return shortDate(vatz)
      case formatTypes.shorterDate:
        return shorterDate(vatz)
      case formatTypes.shortestDate:
        return shortestDate(vatz)
      case formatTypes.dayMonth:
        return dayMonth(vatz)
      case formatTypes.dateDayMonth:
        return dateDayMonth(vatz)

      case formatTypes.time:
        return time(vatz)
      case formatTypes.ampm:
        return timeAmPm(vatz)
      case formatTypes.ampmShort:
        return timeAmPmShort(vatz)
      case formatTypes.allHours:
        return time24Hour(vatz)

      default:
        return formatDate(vatz, dateOptions_fns[formattingType])
    }
  } catch (error) {
    console.error(formattingType, value, error);
    return value
  }
}


const formatTime = (value, dateOption) => {
  return format(value, dateOption)
}

/**
 * 
 * @param {*} time 
 * @returns time, hour, min
 */
export const timeConvertor = (time) => {
  try {
    let _time = time
    let _pm;
    // see if AM or PM exists. Take note and remove
    if (time.match('PM') || time.match('AM')) {
      if (time.match('PM')) { _pm = true }
      _time = _time.replace('AM', '')
      _time = _time.replace('PM', '')
    }

    const timeSplit = _time.split(':')
    let hours = parseInt(timeSplit[0])
    let minutes = parseInt(timeSplit[1])
    if (_pm) { hours += 12 }
    if (hours < 10) { hours = '0' + hours }
    if (minutes < 10) { minutes = '0' + minutes }
    const t = hours + ':' + minutes
    return { time: t.trim(), hour: hours, min: minutes }
  } catch (error) {
    return time
  }
}

/**
 * 
 * @param {*} day 
 * @param {*} time 
 * @returns the `data` using Date(n + "T" + time)
 */
export function getStartTimeUtc(day, time) {
  const combinedDateTime = `${day} ${time}`;
  const localDate = new Date(combinedDateTime);
  const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);

  // Parse the combined date and time in the local time zone
  // const localDate = parse(combinedDateTime, 'MM/dd/yyyy HH:mm', new Date());
  // const localDate = parse(combinedDateTime, 'yyyy-MM-dd HH:mm', new Date());

  // Format the local date in UTC
  // const utcDate = format(localDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'UTC' });
  // const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
  return utcDate
}

/**
 *  
 * @param {*} time 
 * @returns the `time` using `formatItem` with `ampm` as the `formatType`
 */
export const formatTimeAmPm = (time) => {
  const { hour, min } = time ? timeConvertor(time) : {}
  const dt = new Date()
  dt.setHours(hour ? hour : 12)
  dt.setMinutes(min ? min : 0)
  return formatItem(formatTypes.ampm, dt)
}

export const formatTime24 = (time) => {
  const { hour, min } = time ? timeConvertor(time) : {}
  const dt = new Date()
  dt.setHours(hour ? hour : 12)
  dt.setMinutes(min ? min : 0)
  return formatItem(formatTypes.allHours, dt)
}

/**
 * 
 * @param {*} items 
 * @param {*} dateFormat 
 * @description Creates UTC dates and times for each of the items
 */
export const ammendDateItemsWithUTC = (items, dateFormat) => {
  if (items) {
    Object.keys(items).forEach(key => {
      const item = items[key]
      Object.keys(item).forEach(propKey => {
        if (propKey.toLowerCase().endsWith('date')) {
          let value = item[propKey]
          if (_.isDate(value)) {
            if (dateFormat) {
              value = formatItem(dateFormat, value)
              item[propKey] = value
            }
            if (value) {
              if (propKey.toLowerCase().endsWith('date')) { item[propKey + 'UTC'] = new Date(value) }
            }
          }
        }
      })
      if (item.startDate && item.startTime) { item['startTimeUTC'] = new Date(item.startDate + ' ' + item.startTime) }
    })
  }
}

export function isDateInPast(dateString) {
  const d = new Date(dateString)
  // Check if the parsed date is a valid date
  if (isNaN(d.getTime())) {
    return false; // Invalid date
  }

  // Get the current date
  const currentDate = new Date();

  // Check if the parsed date is in the past or today
  return d < currentDate || d.toDateString() === currentDate.toDateString();
}

export const daysSinceTimestamp = (timestamp) => {
  if (!timestamp) {
    return false; // No timestamp available
  }
  const currentTimestamp = Date.now();
  const firebaseDate = timestamp.toDate();
  const timeDifference = currentTimestamp - firebaseDate.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference
};

/**
 * updates all the dates within the `allItems' object
 * @param {object} allItems 
 */
export const ammendAllDates = (allItems) => {
  if (allItems) {
    Object.keys(allItems).forEach(key => {
      const items = allItems[key]
      ammendDates(items)
    })
  }
}

/** 
 * updates all the dates within the `items' object
 * @param {object} items 
 */
const ammendDates = (items) => {
  if (items) {
    Object.keys(items).forEach(key => {
      ammendDate(items[key])
    })
  }
}

/**
 * 
 * @param {string} d 
 * @param {string} timeZone 
 */
export const ammendDate = (d, timeZone) => {

  try {
    if (d.startDate) {
      try {
        d.startDate = formatItem(formatTypes.shortestDate, d.startDate)
      } catch (error) {
        console.log('error', error)
      }
      let sd;
      if (d.seconds) {
        sd = d.startDate && d.startDate.seconds ? toDateTime(d.startDate.seconds) : d.startDate
        d.startDate = sd
        if (d.startTime) {
          try {
            d.startTimeUTC = getStartTimeUtc(d.startDate, d.startTime)
          } catch (error) {
            console.error('time format error', error)
          }
        }
      } else {
        const dd = getUtcTime(d.startDate, d.startTime, timeZone)
        d._dateInfo = getTimeZones(d, timeZone)
        d.startTimeUTC = dd
      }

      if (d.endTime && sd) {
        try {
          d.endTimeUTC = sd
          d.endTimeAMPM = formatItem(formatTypes.shortDate, sd)
        } catch (error) {
          console.error('tme')
          console.error('time format error', error)
        }
      }
    } else if (d.startDateUTC) {
      d.startDate = formatItem(formatTypes.date, d.startDateUTC)
      d.startTime = '7:00 PM'
    }
  } catch (error) {
    console.log('error', error)
  }

}

function getTimeZones(itemData, actualTimeZone) {

  const { startDate, startTime, endTime } = itemData

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  let _actualTimeZone = itemData.timeZone ? itemData.timeZone : actualTimeZone
  if (!_actualTimeZone) { _actualTimeZone = userTimeZone }

  const utcStartTime = startDate && startTime ? getUtcTime(startDate, startTime, _actualTimeZone) : null
  const itemStartDate = utcStartTime ? zonedTimeToUtc(utcStartTime, _actualTimeZone) : null

  const utcEndTime = startDate ? getUtcTime(startDate, endTime, _actualTimeZone) : null
  const itemEndDate = utcEndTime ? zonedTimeToUtc(utcEndTime, _actualTimeZone) : null

  const _tzs = {
    item: {
      startDate,
      startTime,
      endTime,
    },
    local: {
      start: getDateInfo(itemStartDate, utcStartTime, userTimeZone),
      end: getDateInfo(itemEndDate, utcEndTime, userTimeZone)
    },
    actual: {
      start: getDateInfo(itemStartDate, utcStartTime, _actualTimeZone),
      end: getDateInfo(itemEndDate, utcEndTime, _actualTimeZone)
    },
    tzsDifferent: userTimeZone !== _actualTimeZone
  }

  ammendDateFormats(_tzs.local.start)
  ammendDateFormats(_tzs.local.end)

  ammendDateFormats(_tzs.actual.start)
  ammendDateFormats(_tzs.actual.end)

  return _tzs

}

const getUtcTime = (date, time, timeZone) => {
  try {
    if (date) {
      const parsedDate = parse(date, dateOptions_fns.shortestDate, new Date());
      if (time) {
        const combinedDateTime = set(parsedDate, { hours: parseInt(time.split(':')[0]), minutes: parseInt(time.split(':')[1]) });
        return timeZone ? utcToZonedTime(combinedDateTime, timeZone) : combinedDateTime;
      } else {
        return parsedDate;
      }
    }
  } catch (error) {
    return new Date();
  }
};

const getDateInfo = (itemDate, utcTime, timeZone) => {

  const zonedDate = utcToZonedTime(itemDate, timeZone);

  return {
    full: utcTime ? format(zonedDate, dateOptions_fns.shortDate, { timeZone }) : null,
    utc: itemDate ? new Date(itemDate.toISOString()) : null,
    timeZone: timeZone,
  };
}


const ammendDateFormats = (item) => {
  const { timeZone, utc } = item ?? {}
  item.formatted = {
    date: utc ? formatItem(formatTypes.shortDate, utc) : null,
    time24: utc ? formatItem(formatTypes.time24, utc) : null,
    timeAmPm: utc ? formatItem(formatTypes.timeAmPm, utc) : null,
    timeAmPmShort: utc ? formatItem(formatTypes.timeAmPmShort, utc) : null,
    tzs: timeZone
  }
}


export const _timeZones = {
  america_newYork: 'America/New_York',      // GMT-5
  america_losAngeles: 'America/Los_Angeles', // GMT-8
  america_chicago: 'America/Chicago',        // GMT-6
  america_denver: 'America/Denver',          // GMT-7 
  america_anchorage: 'America/Anchorage',    // GMT-9
  america_honolulu: 'America/Honolulu',      // GMT-10
  europe_london: 'Europe/London',            // GMT+0 
  europe_berlin: 'Europe/Berlin',            // GMT+1
  asia_tokyo: 'Asia/Tokyo',                  // GMT+9
  asia_hongKong: 'Asia/Hong_Kong',          // GMT+8
  asia_seoul: 'Asia/Seoul',                  // GMT+9
  australia_sydney: 'Australia/Sydney',      // GMT+11
  australia_melbourne: 'Australia/Melbourne',// GMT+11 
  africa_johannesburg: 'Africa/Johannesburg',// GMT+2
  africa_lagos: 'Africa/Lagos',              // GMT+1
  africa_nairobi: 'Africa/Nairobi',          // GMT+3 
  africa_casablanca: 'Africa/Casablanca ',    // GMT+0  
  africa_gaborone: 'Africa/Gaborone',        // GMT+2   
  africa_mogadishu: 'Africa/Mogadishu',      // GMT+3  
  asia_dubai: 'Asia/Dubai',                  // GMT+4 
  asia_baghdad: 'Asia/Baghdad',              // GMT+3
  europe_moscow: 'Europe/Moscow',            // GMT+3
  europe_istanbul: 'Europe/Istanbul',        // GMT+3
  pacific_auckland: 'Pacific/Auckland',      // GMT+13
};
