import React, { createContext, useContext, useReducer } from 'react';
import { transferHandlers, transferReducer, transferReducerInitialState } from '../reducers/TransferReducer';
import { ParentContext } from './ParentContext';
import { _developerAdminEmail } from '../../common/keys';

/** AppContext */
export const TransferContext = createContext();

/** Global OtherDatas for the Event */
const TransferProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state } = states
  const { pathViews, settingsDocName } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { productionSettings } = aps_global ?? {}
  const { clients: clientKey, events: eventKey } = pathViews ?? {}
  const { appName, clientKey: clientKey_ps, eventKey: eventKey_ps } = productionSettings ?? {}

  const _clientKey = clientKey_ps ? clientKey_ps : clientKey
  const _eventKey = eventKey_ps ? eventKey_ps : eventKey

  const initState = transferReducerInitialState({ currentFirestores: {}, formData: { userName: _developerAdminEmail, clientKey: _clientKey, eventKey: _eventKey, appName }, pathViews, settingsDocName })
  const [transfer_state, transfer_dispatch] = useReducer(transferReducer, initState);
  const transfer_handlers = transferHandlers(transfer_dispatch, transfer_state)

  const providerContext = () => <TransferContext.Provider
    value={{ transfer_state, transfer_handlers }}>
    {props.children}
  </TransferContext.Provider>

  return providerContext()
}

export default TransferProvider