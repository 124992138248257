import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { getFbConfig } from '../../../../project/appConfiguration';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from '../../../cnr/contexts/SettingsDataContext';
import TransferProvider, { TransferContext } from '../../../cnr/contexts/TransferContext';
import { transferDirectionTypes } from '../../../cnr/reducers/TransferReducer';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import AppForm from '../../../components/forms/appForms/AppForm';
import { DataManagementContext } from '../../../components/viewers/DataManagementViewer';
import DcUpdatesList from '../../../components/viewers/DcUpdatesList';
import { appFormTypes } from '../../../enums/appFormTypes';
import { appIconTypes } from '../../../enums/appIconTypes';
import { listList } from '../../../lists/lists';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';
import UiConfirmation from '../UiConfirmation';
import { clickOriginTypes } from '../../../enums/globalEnums';

const _tranferPathsXXX = [
  '_districts',
  '_gls_scores',
  '_history',
  '_matches_seasonal',
  '_schools',
  '_teams',
  '_tournaments',
  'organizations',
  'schools',
  'schools',
  'sports',
  'teams',
]

const _transferCollections = [
  '_districts',
  '_globals',
  '_history',
  '_matches_seasonal',
  '_rankings',
  '_rosters',
  '_schools',
  '_gls_scores',
  '_gls_teams',
  '_scores_seasonal',
  '_seasonals',
  '_sports',
  '_sportsAccess_district',
  '_sportsAccess_schools',
  '_sportsAccess_sports',
  '_teams',
  'athletes',
  'districts',
  'globals',
  'history',
  'organizations',
  'schools',
  'schools',
  'schoolsByState',
  'sports',
  'teams',
]

const _transferCollections_settings = [
  'settings',
]

const _formData = {
  appName: 'thumbstat',
  environment: 'development',
  sourceRootPath: 'clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports',
  destinationRootPath: 'clients/pojo/events/thumbstat'
}

const _formData_settings = {
  appName: 'thumbstat',
  environment: 'development',
  sourceRootPath: 'settings/pojoSports',
  destinationRootPath: 'settings/thumbstat'
}

const _collectionOptions = {
  true: 'true',
  false: 'devfalse'
}

const _forSettings = true

/** Component for Ammending Data
 * @item pageItemDataActionTypes.convertArrays
  @description updates using (dataManagement_handlers.handleUpdate_collection)
 */
const AppDataTransferDirect = (props) => {

  const { clickOriginType, handleCancel } = props ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
      _fromDataViewer = true
    default:
    //nothing
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states
  const { pathViews, appArea } = paps_state ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ?? {}
  const { updating, confirmation } = dataManagement_state ?? {}
  const inViewer = dataManagement_state ? true : false

  const homeSettingsContext = useContext(HomeSettingsContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const eventSettingsContext = useContext(EventSettingsContext);

  // transferContext
  const transferContext = useContext(TransferContext)
  const { transfer_state, transfer_handlers } = transferContext ?? {}
  const { dcUpdates, formData, showConfirmation, currentSetting, currentDirection } = transfer_state ?? {}
  const { environment, appName, sourceRootPath, destinationRootPath } = formData ?? {}

  const allowTransfer = currentDirection ? true : false

  const [selectedCollections, setSelectedCollections] = useState()

  useEffect(() => {
    if (appName && environment) {
      const currentConfig = getFbConfig()
      transfer_handlers.handleSet_firestores(currentConfig)
    }
    transfer_handlers.handleUpdateFormData(_forSettings ? _formData_settings : _formData)
    // eslint-disable-next-line
  }, [appName, environment]);

  useEffect(() => {

    let _settings;
    let _settingsKey;

    const isEvent = pathViews.events
    const isClient = pathViews.clients && !isEvent

    if (isEvent) {
      const { eventSettings_state } = eventSettingsContext ?? {}
      const { eventSettings } = eventSettings_state ?? {}
      _settings = eventSettings
      _settingsKey = pathViews.events
    } else if (isClient) {
      const { clientSettings_state } = clientSettingsContext ?? {}
      const { clientSettings } = clientSettings_state ?? {}
      _settings = clientSettings
      _settingsKey = 'clients'
    } else {
      if (appArea === 'clients') {
        const { clientSettings_state } = clientSettingsContext ?? {}
        const { clientSettings } = clientSettings_state ?? {}
        _settings = clientSettings
        _settingsKey = 'clients'
      } else {
        const { homeSettings_state } = homeSettingsContext ?? {}
        const { homeSettings } = homeSettings_state ?? {}
        _settings = homeSettings
        _settingsKey = 'home'
      }
    }
    transfer_handlers.handleSet_settings(_settings, _settingsKey)

    // eslint-disable-next-line
  }, []);

  const handleShowCommit = (co) => transfer_handlers.handleShowConfirmation(co.transferType)
  const handleKillCommit = () => transfer_handlers.handleShowConfirmation()
  const handleUpdateParentData = (d) => transfer_handlers.handleUpdateFormData(d)
  const handleDirectionChange = (e, data) => transfer_handlers.handleSelect_direction(data.value)
  const handleClearChange = (e, data) => transfer_handlers.handleSelect_direction(data.value)

  const handleCommit = () => transfer_handlers.handleCommit_dataTransferDirect({ sourceRootPath, destinationRootPath }, selectedCollections)
  const handleDdMultiple = (e, d) => setSelectedCollections(d.value)

  //  const handleSettingsChange = (e, data) => transfer_handlers.handleSelect_setting(data.value)
  // const handleCommit_settings = () => transfer_handlers.handleCommit_dataTransferDirect({ sourceRootPath, destinationRootPath }, selectedCollections)

  const appForm_appLogin = () => <AppForm
    appFormType={appFormTypes.appEnvironment}
    parentData={formData}
    handleUpdateParentData={handleUpdateParentData}
  />

  const appForm_tranfer = () => <AppForm
    appFormType={appFormTypes.transferDataDirect}
    parentData={formData}
    handleUpdateParentData={handleUpdateParentData}
  />

  const optsList = (list) => listList(list)

  const dropdown = (options, oc, value, placeholder, iconName) => <Dropdown
    button
    className='icon thin'
    color={'blue'}
    inverted
    icon={{ name: iconName }}
    labeled
    options={options}
    placeholder={placeholder}
    scrolling
    onChange={oc}
    text={_.startCase(value)}
    style={{ float: 'left' }}
  />

  const dropdown_multiple = () => <Form>
    <Form.Dropdown
      value={selectedCollections}
      options={listList(_forSettings ? _transferCollections_settings : _transferCollections, true, null, null, null, true)}
      fluid
      search
      selection
      multiple={true}
      label={'Collections'}
      onChange={handleDdMultiple}
    />
  </Form>

  const buttons = () => {
    const btns = [];
    const color = currentDirection === transferDirectionTypes.import ? 'orange' : 'blue'
    const icon = currentDirection === transferDirectionTypes.import ? appIconTypes.arrowLeft : appIconTypes.arrowRight

    let transferType;

    if (currentDirection && currentSetting) {
      transferType = currentDirection + _.startCase(currentSetting)
      transferType = transferType.replace(' ', '')
    }

    let caption = _.startCase(appFormTypes.transferData)

    btns.push({ oc: handleShowCommit, color, caption, icon, disabled: !allowTransfer, clickOptions: { transferType } })

    return <UiSaveButtons
      others={btns}
      dropdowns={dropdowns()}
      color={'blue'}
      rows={2}
    />
  }

  const dropdowns = () => {
    return [
      dropdown(optsList(transferDirectionTypes), handleDirectionChange, currentDirection, 'Transfer Direction', 'arrows alternate horizontal'),
      dropdown(optsList(_collectionOptions), handleClearChange, currentDirection, 'Clear Collections', 'question')
    ]
  }

  const dcUpdateList = () => <DcUpdatesList dcUpdates={dcUpdates} />

  const formContent = () => <React.Fragment>
    {appForm_appLogin()}
    {appForm_tranfer()}
    {dropdown_multiple(listList(_tranferPathsXXX, true))}
  </React.Fragment>

  const container = () => <Wrapper
    content={formContent()}
    footer={buttons()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
    confirmation={confirmation}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={container()}
    handleCancel={handleCancel}
    topperCaption={'Transfer Data'}
  />

  if (showConfirmation && !dcUpdates) {
    const confirmationProps = {
      caption: 'Confirmation',
      text: 'Are you sure you want to transfer the data ?',
      updateCaption: 'Transfer Data'
    }
    return <UiConfirmation {...confirmationProps} handleUpdate={handleCommit} handleCancel={handleKillCommit} useFull={true} />
  } else {
    if (dcUpdates) {
      return dcUpdateList()
    } else {
      return inViewer || _fromDataViewer ? container() : fullPageWrapper()
    }
  }
}

const AppDataTransferDirectWithProvider = (props) => {
  return <TransferProvider >
    <AppDataTransferDirect {...props} />
  </TransferProvider>
}

export default AppDataTransferDirectWithProvider