import _ from 'lodash';
import { getTempSettingsState, ammendTempSettingsState } from '../../firestoreData/settings/ammendSettings';
import { fsfn_settings } from '../../functions/fbSettingsFunctions';
import { commitViewSettingsAction } from '../../viewSettings/actions/commitViewSettingsAction';
import { vsComponentTypes } from '../../viewSettings/enums/vsComponentTypes';
import { ammendViewSettings } from './reducerHelpers/ammendViewSettings';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from './reducerHelpers/dispatchProps';

export const updateSettingsTypes = {
  clientData: 'clientData',
  dataCollections: 'dataCollections',
  dataLists: 'dataLists',
  eventData: 'eventData',
  global: 'global',
  viewItems: 'viewItems',
  viewItems_add: 'viewItems_add',
  views: 'views',
  views_add: 'views_add',
}

const rts = {
  handleAddViewAndViews: 'handleAddViewAndViews',
  handleCloseCreate: 'handleCloseCreate',
  handleCopySettingsItem: 'handleCopySettingsItem',
  handleCopyView: 'handleCopyView',
  handleCopyViewItem: 'handleCopyViewItem',
  handleCreate: 'handleCreate',
  handleDuplicateView: 'handleDuplicateView',
  handlePasteSettingsItem: 'handlePasteSettingsItem',
  handlePasteUpdate: 'handlePasteUpdate',
  handleRestoreDefaults: 'handleRestoreDefaults',
  handleReorderByPosition: 'handleReorderByPosition',
  handleRestoreFromGlobal: 'handleRestoreFromGlobal',
  handleSetInit: 'handleSetInit',
  handleUpdate_dbSettings: 'handleUpdate_dbSettings',
  handleShowCopyData: 'handleShowCopyData',
  handleShowCopyViewSettings: 'handleShowCopyViewSettings',
  handleShowHelp: 'handleShowHelp',
  handleShowModifyPropData: 'handleShowModifyPropData',
  handleShowPermissions: 'handleShowPermissions',
  handleShowPushData: 'handleShowPushData',
  handleShowViewLinking: 'handleShowViewLinking',
  handleSortItems: 'handleSortItems',
  handleSortOk: 'handleSortOk',
  handleTestItem: 'handleTestItem',
  handleUpdateDataCollections: 'handleUpdateDataCollections',
  handleUpdateFromAdd: 'handleUpdateFromAdd',
  handleUpdateNewProps: 'handleUpdateNewProps',
  handleUpdateViewPage: 'handleUpdateViewPage',
  handleUpdate_settings: 'handleUpdate_settings',
  handleViewSettingsUpdate: 'handleViewSettingsUpdate',
  setDataRoot: 'setDataRoot',
  upd: 'upd',
  updateSort: 'updateSort',
  handleAmmend_tempViewSettings: 'handleAmmend_tempViewSettings',
  ...grts
}

export const viewSettingsReducer = (state, action) => {

  const {
    isGlobal,
    isGlobalPageItem,
    helpKey,
    settingsParentContext,
    showPermissions,
    showCopyData,
    showModifyPropData,
    showPushData,
    showCopyViewSettings,
    showDuplicateView,
    showViewLinking,
  } = state

  const { states, handlers, settings } = settingsParentContext ?? {}
  const { global_state, settingsArea_state, appUser_state, paps_state, page_state, appSettings_state, framework_state } = states ?? {}
  const { firestore_handlers } = handlers ?? {}

  const { homeSettings: homeSettings_global, eventSettings } = settings ?? {}
  const { views } = eventSettings ?? {}
  const { logging } = homeSettings_global ?? {}

  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_views, aps_page } = pageSettings ?? {}
  const { viewItems: viewItems_page } = aps_page ?? {}

  const { appUser } = appUser_state ?? {}
  const { settingsPreviewOn } = appSettings_state ?? {}

  const { settingsOriginType } = settingsArea_state ?? {}
  const { settings_globalPageItems } = global_state ?? {}

  const { desktopMode } = framework_state ?? {}

  let {
    newViews: snewViews,
    settings_temp_global,
    settings_temp,
    selectedGroupItem,
  } = state

  const { gvvi, page } = settings_temp ?? {}
  const { global } = gvvi ?? {}

  const { key: selectedKey } = selectedGroupItem ?? {}

  let { _global, _viewItems, _views } = getTempSettingsState(settings_temp, isGlobal)

  const {
    dataRoot,
    dispatch,
    formData,
    isSolo,
    newViews,
    propSections,
    sortedItems,
    sortedPropItems,
    sortedPropSections,
    settings_temp: settings_temp_action, //as
    svsGv,
    swipedItem,
    type,
    updateProps,
    viType,
    view_dup,
    copyValue,
  } = action

  const { handlePasteUpdate } = viewSettingsHandlers(dispatch, state)

  const { name } = copyValue ?? {}

  const { sortedGroups, menuType } = action

  switch (type) {

    case rts.handleSetInit:
      return { ...state, settings_temp: _.cloneDeep(action.settings_init) }

    case rts.handleTestItem:
      return { ...state, testItem: true }

    case rts.handleRestoreFromGlobal:
      const { view: key_view } = state
      try {
        page.viewItems[key_view].propSections = aps_viewItems[key_view].propSections
        page.viewItems[key_view].props = aps_viewItems[key_view].props
      } catch (error) {

      }
      return {
        ...state,
        settings_temp,
        settings_preview: settings_temp,
        settings_temp_global: settings_temp,
        viewSettingsHaveChanged: true,
      }

    case rts.handleRestoreDefaults:
      // const _page = reorderPageViewItems(page)
      // settings_temp.page = _page
      // console.log('handleRestoreDefaults', isGlobal, _page)
      return {
        ...state,
        // settings_temp: settings_temp,
        viewSettingsHaveChanged: true,
      }

    case rts.handleReorderByPosition:
      const _page = reorderPageViewItems(page)
      settings_temp.page = _page
      console.log('handleReorderByPosition', isGlobal, _page)
      return {
        ...state,
        settings_temp: settings_temp,
        viewSettingsHaveChanged: true,
      }


    // IMPORTANT: Settings - Update ViewSettings
    // this updates the temp settings
    case rts.handleAmmend_tempViewSettings:

      const ammendVsProps = {
        desktopMode,
        formData,
        isGlobal,
        isGlobalPageItem,
        logging,
        menuType,
        newViews,
        propSections,
        settings_globalPageItems,
        settings_temp_action,
        settings_temp,
        settingsOriginType,
        settingsPreviewOn,
        sortedGroups,
        sortedItems,
        sortedPropItems,
        sortedPropSections,
        svsGv,
        updateProps,
      }

      const appSettingsChanges = checkAppChanges(state, settings_temp)

      const settings_temp_update = ammendViewSettings(ammendVsProps)

      return {
        ...state,
        settings_temp: isGlobalPageItem ? settings_temp : settings_temp_update,
        settings_temp_global: isGlobalPageItem ? settings_temp_global : settings_temp_update,
        settings_preview: settings_temp_update,
        settings_preview_changed: !state.settings_preview_changed,
        newViews,
        viewSettingsHaveChanged: true, // allows the save icon on the top right to enable
        appSettingsChanges,
        settingsStatus: state.settingsStatus >= 100 ? 0 : state.settingsStatus + 1,
        isGlobalPageItem,
        currentFormData: formData
      }

    case rts.handleSortItems:
      return { ...state }

    case rts.handleUpdateFromAdd:
      return { ...state }

    case rts.setDataRoot:
      return { ...state, dataRoot }

    // IMPORTANT: Settings - DB Update
    case rts.handleUpdate_dbSettings:

      if (isGlobalPageItem) {
        firestore_handlers.handleUpdate_firestoreSettings_globalViews(dispatch, paps_state, settings_temp_global)
      } else {
        if (isGlobal && snewViews) {
          const fullDbSettings = {
            global: _global,
            viewItems: _viewItems,
            views: _views,
          }
          firestore_handlers.handleUpdate_firestoreSettings_globalAndViewsDb(dispatch, paps_state, true, fullDbSettings)
        } else {
          fsfn_settings.updateSettingsDb(dispatch, settings_temp, paps_state, isGlobal, action.sandboxOn, appUser, state.appSettingsChanges)
        }
      }

      return { ...state, updating: true }

    case rts.handleUpdate_settings:

      const { updateType, updateJson } = action

      switch (updateType) {
        case updateSettingsTypes.global:
          _global = updateJson
          break;

        case updateSettingsTypes.viewItems:
          _viewItems = updateJson
          break;

        case updateSettingsTypes.views:
          break;

        default:
        // nothing
      }

      const commitProps = {
        actionName: updateType,
        aps_global,
        paps_state,
        previewInfo: updateJson,
        swipedItem,
        updateProps,
        appUser,
      }

      commitViewSettingsAction(dispatch, firestore_handlers, commitProps, logging)

      return {
        ...state, settings_temp: settings_temp,
      }

    case rts.handleShowCopyViewSettings:
      return { ...state, showCopyViewSettings: !showCopyViewSettings }

    case rts.handleDuplicateView:
      return { ...state, showDuplicateView: !showDuplicateView }

    case rts.handleShowCopyData:
      return { ...state, showCopyData: !showCopyData }

    case rts.handleShowPushData:
      return { ...state, showPushData: !showPushData }

    case rts.handleAddViewAndViews:
      addViewAndViews(action, aps_viewItems, aps_views)
      ammendTempSettingsState(settings_temp, isGlobal, null, aps_viewItems, aps_views)
      action.callback && action.callback(settings_temp)
      return { ...state, settings_temp: settings_temp }

    case rts.handleCopyView:
      if (name && viewItems_page && viewItems_page[name]) {
        aps_viewItems[viType] = viewItems_page[name]
        aps_viewItems[viType].key = viType
        aps_viewItems[viType].display = { caption: _.startCase(viType) }
        return {
          ...state,
          settings_temp: {
            settings_global: aps_global,
            settings_viewItems: aps_viewItems
          },
          viewSettingsHaveChanged: true
        }
      }
      return { ...state }

    case rts.handleCopyViewItem:
      const pageView = aps_views && aps_views[view_dup] ? { ...aps_views[view_dup] } : null
      if (name && pageView && pageView.viewItems && pageView.viewItems[name]) {
        const pageViewItems = aps_views[view_dup].viewItems
        const viewItemToCopy = { ...pageView.viewItems[name] }
        pageViewItems[viType] = viewItemToCopy
        pageViewItems[viType].key = viType
        pageViewItems[viType].display.caption = _.startCase(viType)
        return {
          ...state,
          settings_temp: {
            settings_global: aps_global,
            settings_viewItems: pageViewItems
          },
          viewSettingsHaveChanged: true,
        }
      }
      return { ...state }

    case rts.handleUpdateViewPage:
      // const { vit } = action.viewItem
      // if (_views && settings_temp.viewItems[vit] && settings_temp.viewItems[vit]) {
      //   console.log('settings_temp.viewItems[viKeyy]', settings_temp.viewItems[vit])
      // }
      return { ...state }

    case rts.handleUpdateNewProps:
      const { key: viKey } = action.viewItem
      if (settings_temp && settings_temp.viewItems && settings_temp.viewItems[viKey] && settings_temp.viewItems[viKey].props) {
        const allProps = { ...action.newItemProps, ...settings_temp.viewItems[viKey].props }
        settings_temp.viewItems[viKey].props = allProps
        firestore_handlers.handleUpdate_firestoreSettings_db(dispatch, paps_state, true, settings_temp)
      }
      return { ...state }

    case rts.handleUpdateDataCollections:
      return { ...state, dataCollections: action.dataCollections, staticViews: action.staticViews }

    case rts.handleCopySettingsItem:
      if (isGlobal) {
        if (global[selectedKey]) {
          const csk = {
            copiedSettingsItem: {
              [selectedKey]: global[selectedKey]
            }
          }
          navigator.clipboard.writeText(JSON.stringify(csk))
        }
      }
      return { ...state }

    case rts.handlePasteSettingsItem:
      if (isGlobal) {
        if (global) {
          navigator.clipboard.readText().then(item => {
            try {
              const _item = item ? JSON.parse(item) : {}
              const { copiedSettingsItem } = _item ?? {}
              if (copiedSettingsItem[selectedKey]) {
                const newValue = copiedSettingsItem[selectedKey]
                global[selectedKey] = newValue
                handlePasteUpdate(settings_temp)
              }
            } catch (error) {
              console.log('error', error)
            }
          })
        }
      }
      return { ...state }

    case rts.handlePasteUpdate:
      return { ...state, settings_temp: action.settings_pasted, viewSettingsHaveChanged: true }

    case rts.handleShowPermissions:
      return { ...state, showPermissions: showPermissions === action.a ? null : action.a }

    case rts.handleShowModifyPropData:
      return { ...state, showModifyPropData: showModifyPropData === action.a ? null : action.a }

    case rts.handleShowHelp:
      return { ...state, helpKey: helpKey === action.viType ? null : action.viType }

    case rts.handleShowViewLinking:
      return { ...state, showViewLinking: !showViewLinking, }

    case rts.handleCreate:
      return { ...state, createMode: !state.createMode }

    case rts.handleCloseCreate:
      return { ...state, createMode: false }

    case rts.handleSortOk:
      return { ...state, settings_temp: settings_temp, createMode: false }

    case rts.favsUpdated:
      return {
        ...state,
      }

    case rts.updateSort:

      if (swipedItem) {

        const { handleSortOk, handlePropSortOk, handlePropSectionSortOk } = viewSettingsHandlers(dispatch, state)

        switch (swipedItem.key) {
          case vsComponentTypes.viewItems:
            handleSortOk(sortedItems)
            break;
          case vsComponentTypes.props:
            if (updateProps && updateProps.vit) {
              handlePropSortOk(updateProps.vit, sortedItems, propSections)
            }
            break;
          case vsComponentTypes.propSections:
            if (updateProps && updateProps.vit) {
              handlePropSectionSortOk(updateProps.vit, sortedItems)
            }
            break;
          default:
          // nothing
        }
      }

      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { updating: false, dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, isSolo, viewSettingsHaveChanged: false })

    default:
      alert('no viewSettingsReducerTypes defined: ' + type)
      return state
  }
}

const getVis = (settings_temp) => {
  if (settings_temp) {
    const { viewItems } = settings_temp ?? {}
    return viewItems
  }
}

export const viewSettingsHandlers = (dispatch, state) => {

  const { isGlobal, parentContext, componentContexts } = state ?? {}
  const { states } = parentContext ?? {}
  const { appSettings_state } = states ?? {}

  // componentContexts
  const { globalContext } = componentContexts ?? {}

  // pageContext
  const { settings_globalPageItems } = globalContext ?? {}
  const settings_temp_global = settings_globalPageItems ? _.cloneDeep(settings_globalPageItems) : null

  // papsContext 
  const { sandboxOn } = appSettings_state ?? {}

  const { settings_temp } = state ?? {}

  const { _global } = getTempSettingsState(settings_temp, isGlobal)

  return {
    // handlers for handleAmmend_tempViewSettings
    handleSetInit: (settings_init) => { dispatch({ type: rts.handleSetInit, dispatch, settings_init }) },
    handleTestItem: () => { dispatch({ type: rts.handleTestItem, dispatch }) },
    handleGroupSort: (updateProps, sortedGroups, menuType) => { dispatch({ type: rts.handleAmmend_tempViewSettings, dispatch, updateProps, sortedGroups, menuType }) },
    handlePropSectionSortOk: (sortVit, sortedPropSections) => { dispatch({ type: rts.handleAmmend_tempViewSettings, dispatch, sortVit, sortedPropSections }) },
    handlePropSortOk: (sortVit, sortedPropItems, propSections) => { dispatch({ type: rts.handleAmmend_tempViewSettings, dispatch, sortVit, sortedPropItems, propSections }) },
    handleFormDataChange_settings: (formData, updateProps) => { dispatch({ type: rts.handleAmmend_tempViewSettings, dispatch, updateProps, formData }) },
    handleSortOk: (sortedItems) => { dispatch({ type: rts.handleAmmend_tempViewSettings, dispatch, sortedItems }) },

    updateViewSettingsTemp: (settings_temp, svsGv, viType, newViews) => { dispatch({ type: rts.handleAmmend_tempViewSettings, settings_temp, svsGv, viType, newViews }) },

    // update the settings
    handleUpdate_dbSettings: (isSolo) => { dispatch({ type: rts.handleUpdate_dbSettings, dispatch, isSolo, sandboxOn }) },

    // other
    handleCloseCreate: () => { dispatch({ type: rts.handleCloseCreate, dispatch }) },
    handleRestoreFromGlobal: (updateProps) => { dispatch({ type: rts.handleRestoreFromGlobal, dispatch, updateProps }) },
    handleRestoreDefaults: (resetKey) => { dispatch({ type: rts.handleRestoreDefaults, dispatch, resetKey }) },
    handleReorderByPosition: (resetKey) => { dispatch({ type: rts.handleReorderByPosition, dispatch, resetKey }) },


    handleAddViewAndViews: (name, names, copyFrom, callback) => { dispatch({ type: rts.handleAddViewAndViews, dispatch, name, names, copyFrom, callback }) },
    handleCopyView: (viType, view_dup, copyValue) => { dispatch({ type: rts.handleCopyView, dispatch, viType, view_dup, copyValue }) },
    handleCopyViewItem: (viType, view_dup, copyValue) => { dispatch({ type: rts.handleCopyViewItem, dispatch, viType, view_dup, copyValue }) },
    handleCreate: () => { dispatch({ type: rts.handleCreate, dispatch }) },
    handleOkCreate: () => { dispatch({ type: rts.handleCloseCreate, dispatch }) },
    handleSaveAs: () => { alert('save as') },
    handleShowCopyData: () => { dispatch({ type: rts.handleShowCopyData, dispatch }) },
    handleShowCopyViewSettings: () => { dispatch({ type: rts.handleShowCopyViewSettings, dispatch }) },
    handleDuplicateView: () => { dispatch({ type: rts.handleDuplicateView, dispatch }) },
    handleShowPushData: () => { dispatch({ type: rts.handleShowPushData, dispatch }) },
    handleShowViewLinking: (currentVit) => { dispatch({ type: rts.handleShowViewLinking, dispatch, currentVit }) },
    handleUpdateDataCollections: (value, staticViews) => { dispatch({ type: rts.handleUpdateDataCollections, dispatch, dataCollections: value, staticViews }) },
    handleUpdateFromAdd: (modifyData) => { dispatch({ type: rts.handleUpdateFromAdd, modifyData, dispatch }) },
    handleUpdateNewProps: (viewItem, newItemProps) => { dispatch({ type: rts.handleUpdateNewProps, dispatch, viewItem, newItemProps }) },
    handleUpdateViewPage: (viewItem) => { dispatch({ type: rts.handleUpdateViewPage, dispatch, viewItem }) },
    handleUpdate_settings: (updateType, updateJson, setButtonConfirmation) => { dispatch({ type: rts.handleUpdate_settings, updateType, updateJson, setButtonConfirmation, dispatch }) },
    setDataRoot: (dataRoot) => { dispatch({ type: rts.setDataRoot, dispatch, dataRoot }) },
    updateSort: (swipedItem, updateProps) => { dispatch({ type: rts.updateSort, dispatch, swipedItem, updateProps }) },

    handleSortItems: (swipedItem, updateProps) => { dispatch({ type: rts.handleSortItems, dispatch, swipedItem, updateProps }) },

    handleCopySettingsItem: () => { dispatch({ type: rts.handleCopySettingsItem, dispatch }) },
    handlePasteSettingsItem: () => { dispatch({ type: rts.handlePasteSettingsItem, dispatch }) },
    handlePasteUpdate: (settings_pasted) => { dispatch({ type: rts.handlePasteUpdate, dispatch, settings_pasted }) },
    handleShowPermissions: (a) => { dispatch({ type: rts.handleShowPermissions, dispatch, a }) },
    handleShowModifyPropData: (a) => { dispatch({ type: rts.handleShowModifyPropData, dispatch, a }) },
    handleShowHelp: (viType) => { dispatch({ type: rts.handleShowHelp, dispatch, viType }) },

    ...responseHandlers(dispatch),

    /** gets a value within the settings_temp value */
    // This does NOT change the state
    getSettingsValue: (updateProps, isGlobalPageItem, currentViewItems) => {

      let cd = {}
      let viewItems = isGlobalPageItem && settings_temp_global ? getVis(settings_temp_global) : currentViewItems //_viewItems
      const { formKey, subKey, vit, pit, dataListType } = updateProps ?? {}

      if (formKey) {
        if (vit) {
          const _viewItem = viewItems && viewItems[vit] ? viewItems[vit] : {}
          if (pit) {
            const pi = _viewItem.props && _viewItem.props[pit] ? _viewItem.props[pit] : {}
            cd = pi[formKey] ? pi[formKey] : {}
            cd = subKey ? cd[subKey] : cd
          } else {
            cd = _viewItem[formKey] ? _viewItem[formKey] : {}
            cd = subKey ? cd[subKey] : cd
          }
        } else {
          if (_global) {
            if (dataListType) {
              cd = _global[formKey] ? _global[formKey] : {}
              cd = dataListType ? cd[dataListType] : cd
            } else {
              cd = _global[formKey] ? _global[formKey] : {}
              cd = subKey ? cd[subKey] : cd
            }
          }
        }
      }
      return cd
    },
  }
}

export const viewSettingsInitialState = (props) => {

  const {
    isGlobal,
    settingsParentContext,
    selectedGroupItem,
    startSwipeItems,
    settings_init,
    view,
  } = props

  const initState = {
    createMode: null,
    currentVit: null,
    helpKey: null,
    isGlobal,
    isGlobalPageItem: selectedGroupItem && selectedGroupItem.key === vsComponentTypes.globalPageItems ? true : false,
    rawData: null,
    selectedGroupItem,
    settings_temp: _.cloneDeep(settings_init),
    settingsParentContext,
    settingsStatus: 0,
    showViewLinking: false,
    startSwipeItems,
    view,
    viewSettingsHaveChanged: false,
  }

  return initState
};

const ammendItem = (item, name, copyFrom) => {
  item.menu.caption = _.startCase(name)
  item.viewItems[name] = item.viewItems[copyFrom]
  item.viewItems[name].key = name
  item.viewItems[name].display.caption = _.startCase(name)
  if (!item.viewItems[name].dataSource) { item.viewItems[name].dataSource = {} }
  item.viewItems[name].dataSource.altDataCollectionName = copyFrom
}

const addViewAndViews = (action, viewItems, views) => {

  const { name, names, copyFrom } = action

  if (names) {
    Object.keys(names).forEach(name => {

    })
  } else if (name) {
    const _name = _.camelCase(name)
    const _viewItem = { ...viewItems[copyFrom] }

    const _view = { ...views[copyFrom] }
    const _viewList = { ...views[copyFrom + '_list'] }

    _viewItem.key = _name

    ammendItem(_view, _name, copyFrom)
    ammendItem(_viewList, _name, copyFrom)

    viewItems[name] = _viewItem
    views[name] = _view
    views[name + '_list'] = _viewList
  }
}

const checkAppChanges = (state, settings_temp) => {

  const { gvvi: gvvi_current } = state.settings_temp ?? {}
  const { global: global_current } = gvvi_current ?? {}
  const { appStatus: appStatus_current, scheduledNotifications: scheduledNotifications_current } = global_current ? global_current : {}

  const { gvvi: gvvi_new } = settings_temp ?? {}
  const { global: global_new } = gvvi_new ?? {}
  const { appStatus: appStatus_new, scheduledNotifications: scheduledNotifications_new } = global_new ?? {}

  return {
    statusChanged: appStatus_current !== appStatus_new,
    notificationsChanged: scheduledNotifications_current !== scheduledNotifications_new,
  }
}

const reorderPageViewItems = (page) => {
  const updatedViewItems = {}
  const { viewItems } = page
  const _viewItemsShow = _.groupBy(viewItems, 'show')
  const { true: visTrue, false: visFalse } = _viewItemsShow
  const _viewItemsTrue = _.sortBy(visTrue, 'position')
  const _viewItemsFalse = _.sortBy(visFalse, 'position')
  let lastIndex = 0
  _.each(_viewItemsTrue, (vit, index) => {
    vit.position = index
    lastIndex = index
  })
  _.each(_viewItemsFalse, (vit, index) => {
    vit.position = index + lastIndex + 1
  })
  _.each(_viewItemsTrue, (vit) => {
    updatedViewItems[vit.key] = vit
  })
  _.each(_viewItemsFalse, (vit) => {
    updatedViewItems[vit.key] = vit
  })
  page.viewItems = updatedViewItems
  return page
}