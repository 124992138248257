import _ from 'lodash'
import { ammendDataWithStaticViewValues, convertHelpers, getStaticItems, removeItemKeys } from "../../common/convert"
import { gEnums } from "../../enums/globalEnums"
import { getCollectionData } from "../../firestoreData/appData/collectionData"
import { getStartEnd } from "../../firestoreData/helpers/getRefProps"
import { postDataConstraits, postKeyConstraits } from "../contexts/contextHelpers/postDataConstraits"
import { getGoogleSheetsData } from "../contexts/contextHelpers/googleSheetData"
import { responseReducers } from "./reducerHelpers/dispatchProps"
import { createRefPath_event } from '../../firestoreData/appData/appRefPaths'
import { fs_get_doc } from '../../firestoreData/appData/fsData'
import { saveToStaticViews } from '../../firestoreData/staticViews/updateStaticViews'

export const _secretDataName = 'secret'
const _fetchExistingData_temp = false
const _showFetchType = false
const _shoNewFetchType = false

const rts = {
  handleInit: 'handleInit',
  handleLoad_data: 'handleLoad_data',
  handleSecretData: 'handleSecretData',
  handleSetFetchData: 'handleSetFetchData',
  handleSetAlphaValue: 'handleSetAlphaValue',
  handleSetAlphabetValue: 'handleSetAlphabetValue',
  handleUpdateData: 'handleUpdateData',
  handleGet_data: 'handleGet_data',
  handleSet_dataResults: 'handleSet_dataResults',
  handleUpdate_staticView: 'handleUpdate_staticView',
}

const getSecretData = async (dr, propSectionKey) => {
  const ref = dr.collection(propSectionKey).doc(_secretDataName)
  const res = await ref.get()
  return res
}

export const dataReducer = (state, action) => {

  const { combinedListData, firestore_fns, autoUpdateStaticViews } = state

  const { dispatch, dataValue, isCombinedData, type, dataResultProps } = action
  const { name, singleDataItem, dataRef, dataId } = dataResultProps ?? {}

  const { handleUpdateData, handleSet_dataResults } = dataHandlers(dispatch)

  switch (type) {

    case rts.handleInit:

      return {
        ...state,
        isLoading: true,
        fetched: false,
      }

    case rts.handleGet_data:
      getDataData(action, handleSet_dataResults, firestore_fns)
      return { ...state }

    case rts.handleSet_dataResults:
      return { ...state, dataResults: action.dataResults }

    case rts.handleLoad_data:

      const sing = { ...state.singles }
      let cd = { ...combinedListData }
      if (isCombinedData) { cd = { ...cd, ...dataValue } }

      if (singleDataItem && dataValue && Object.keys(dataValue).length === 1) { sing[name] = dataValue[Object.keys(dataValue)[0]] }

      return {
        ...state,
        [name]: dataValue,
        combinedListData: cd,
        dataId: dataId,
        dataName: name,
        dataRef,
        excludedData: action.excludedData,
        fetched: true,
        fsr: action.fsr,
        isLoading: false,
        singleDataItem,
        singles: sing,
        time_stamp: Date.now(),
        viewListData: dataValue,
        viewListDataCount: dataValue ? Object.keys(dataValue).length : 0,
        missingSvData: action.missingSvData
      }

    case rts.handleSecretData:

      const { dataRef: dr, dataName } = state
      const { secretProps } = action
      const { propSectionKey, itemData } = secretProps ?? {}
      const { key: itemKey } = itemData ?? {}

      getSecretData(dr, propSectionKey).then(res => {
        const secretItemData = res.data()
        const existingItemData = state[dataName][itemKey]
        const combinedItemData = { ...existingItemData, ...secretItemData }
        handleUpdateData(combinedItemData)
      })

      return { ...state }

    case rts.handleUpdateData:
      const { updateData } = action
      const vld = { ...state.viewListData }
      vld[updateData.key] = updateData
      return {
        ...state,
        [state.dataName]: action.updateData,
        viewListData: vld,
        viewListDataCount: vld ? Object.keys(vld).length : 0,
        time_stamp: Date.now()
      }

    case rts.handleSetAlphaValue:
      return {
        ...state,
        alphaValue: action.alphaValue
      }

    case rts.handleSetAlphabetValue:
      return {
        ...state,
        alphabetValue: action.alphabetValue
      }

    case rts.handleSetFetchData:
      return {
        ...state,
        fetchData: action.fetchData
      }

    case rts.handleUpdate_staticView:
      updateStaticView(action.actionItem)
      return { ...state }

    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { [name]: dataValue, fetched: true })

    default:
      return { ...state }
  }
}

export const dataInitialState = (initState) => {
  const { firestore_fns, diKey, isCombinedData, isSettingData, itemListData, uivi, updateProps, viewItem, pageImages } = initState ?? {}
  return {
    // alphabetValue: 65,
    firestore_fns,
    combinedListData: {},
    confirmation: null,
    diKey,
    isCombinedData,
    isSettingData,
    itemListData,
    pageImages,
    singleDataItem: false,
    singles: {},
    uivi,
    updateProps,
    viewItem,
  }
};

export const dataHandlers = (dispatch) => {
  return {
    handleInit: () => { dispatch({ type: rts.handleInit }) },
    handleGet_data: (getProps) => { dispatch({ type: rts.handleGet_data, dispatch, getProps }) },
    handleSet_dataResults: (dataResults) => { dispatch({ type: rts.handleSet_dataResults, dispatch, dataResults }) },
    handleLoad_data: (dataValue, dataResultProps, fsr, excludedData, missingSvData) => { dispatch({ type: rts.handleLoad_data, dataValue, dataResultProps, fsr, excludedData, missingSvData }) },
    handleSecretData: (secretProps) => { dispatch({ type: rts.handleSecretData, dispatch, secretProps }) },
    handleSetAlphaValue: (alphaValue) => { dispatch({ type: rts.handleSetAlphaValue, alphaValue }) },
    handleSetAlphabetValue: (alphabetValue) => { dispatch({ type: rts.handleSetAlphabetValue, alphabetValue }) },
    handleSetFetchData: (fetchData) => { dispatch({ type: rts.handleSetFetchData, fetchData }) },
    handleUpdateData: (updateData) => { dispatch({ type: rts.handleUpdateData, updateData }) },
    handleUpdate_staticView: (actionItem) => { dispatch({ type: rts.handleUpdate_staticView, dispatch, actionItem }) },
  }
}

const getDataData = (action, callback, firestore_fns) => {

  const cb = (dataValue, dataResultProps, refProps) => {
    filterTheData(action, dataValue, dataResultProps, refProps, callback)
  }

  getTheData(action, cb, firestore_fns)

}

const getTheData = async (action, callback, firestore_fns) => {

  const { getProps } = action

  const {
    _dataResultProps,
    _dataSourceType,
    alphabetValue,
    appComponent_name,
    appComponent,
    appComponentStateCollection,
    appContext,
    appDataDocuments,
    currentPageData,
    dataCaptionProps,
    dataCollection,
    dataCollectionName,
    delayData,
    documentDependencies,
    dProps,
    eventCollectionsData,
    eventItemData,
    fetchByAlphabet,
    fetchData,
    fetchExistingData,
    globalAppData,
    googleModeType,
    googleSheetsData,
    isAppData,
    otherView,
    pageData_state,
    pageDataCollection,
    pathViews,
    refProps,
    returnFsr,
    staticViews,
    trueViewKey,
    uivi,
    view,
    viewKey,
    appDataSource,
  } = getProps ?? {}

  const altProps = {
    appDataDocuments,
    callback,
    currentPageData,
    dataCaptionProps,
    eventItemData,
    firestore_fns,
    returnFsr,
    appDataSource,
  }

  const { useAppDataDocumentsOnly } = appDataSource ?? {}

  // pick the dataSourceType  
  switch (_dataSourceType) {

    case gEnums.dataSourceTypes.component:
      callback({}, _dataResultProps)
      break;

    case gEnums.dataSourceTypes.firebaseDocument:
      const cb = (r) => {
        const ddd = r[uivi]
        convertHelpers.createItemKeys(ddd)
        if (viewKey) {
          callback({ [viewKey]: ddd[viewKey] }, _dataResultProps)
        } else {
          callback(ddd, _dataResultProps)
        }
      }
      const _refPath = createRefPath_event(pathViews, ['_' + uivi, uivi])
      fs_get_doc(_refPath, cb)
      break;

    case gEnums.dataSourceTypes.appComponent:
      let _componentData = {};
      if (appComponent_name === appComponent) {
        const app_state = appComponent + '_state'
        const _state = appContext && appContext[app_state] ? appContext[app_state] : null
        if (_state) {
          if (_state && _state[appComponentStateCollection]) {
            _componentData = _state[appComponentStateCollection]
            convertHelpers.createItemKeys(_componentData)
          }
        }
      }
      callback(_componentData, _dataResultProps)
      break;

    case gEnums.dataSourceTypes.static:
      const staticViewItems = ammendStatic(staticViews, dataCollection)
      if (fetchByAlphabet) {
        let av = alphabetValue
        if (!av) { av = 65 }
        const se = getStartEnd(av)
        const filteredItems = _.filter(staticViewItems, function (o) { return o.name && o.name.startsWith(se.start); });
        callback(filteredItems, _dataResultProps)
      } else {
        callback(staticViewItems, _dataResultProps)
      }
      break;

    case gEnums.dataSourceTypes.googleSpreadSheet:
    case gEnums.dataSourceTypes.googleSheetsData:

      let { googleItemData, noGoogleSheet } = getGoogleSheetsData({ uivi, googleSheetsData, googleModeType, dataSourceType: _dataSourceType, view, viewKey, refProps })

      if (noGoogleSheet) {
        getCollectionData(dProps, altProps)
      } else {
        if (googleItemData) {
          if (trueViewKey && googleItemData[trueViewKey]) {
            callback({ [trueViewKey]: googleItemData[trueViewKey] }, _dataResultProps, refProps)
          } else {
            callback(googleItemData, _dataResultProps, refProps)
          }
        }
      }

      break;

    case gEnums.dataSourceTypes.pageDataCollection:
      const coll = pageDataCollection ? pageDataCollection : uivi
      if (currentPageData && coll && currentPageData[coll]) {
        const _cpd = currentPageData[coll]
        callback(_cpd, _dataResultProps)
      } else {
        callback({}, _dataResultProps)
      }
      break;

    default:
      if (dataCollection) {
        if (isAppData) { // APP DATA
          const staticViewItems = ammendStatic(staticViews, dataCollection)
          callback(staticViewItems, _dataResultProps)
        } else {
          if ((!delayData || fetchData) && otherView !== 'add') {
            const { eventPageKey } = refProps ?? {}

            const { hasExisting: hasExistingAppData, existingData: _existingAppData } = getExistingData(globalAppData, view, viewKey, uivi, eventPageKey, dataCollectionName)
            const { hasExisting: hasExistingEventData, existingData: _existingEventData } = getExistingData(eventCollectionsData, view, viewKey, uivi, null, dataCollectionName)

            if (documentDependencies && documentDependencies.includes(view)) {
              const { singles } = pageData_state
              const dd = getDependencyData(documentDependencies, singles, dataCollection, trueViewKey)
              callback(dd, _dataResultProps)
            } else {
              if (fetchExistingData || _fetchExistingData_temp) {
                if (hasExistingAppData && _existingAppData) {
                  _showFetchType && console.log('fetchType', 'hasExistingAppData')
                  callback(_existingAppData, _dataResultProps, refProps)
                } else if ((hasExistingEventData) && _existingEventData) {
                  _showFetchType && console.log('fetchType', 'hasExistingEventData', _existingEventData)
                  _showFetchType && console.log('eventCollectionsData', eventCollectionsData)
                  callback(_existingEventData, _dataResultProps, refProps)
                } else {
                  _showFetchType && console.log('fetchType', 'getCollectionData NON')
                  getCollectionData(dProps, altProps)
                }
              } else {
                _showFetchType && console.log('fetchType', 'getCollectionData')
                _shoNewFetchType && console.log('NEW', 'getCollectionData')
                getCollectionData(dProps, altProps)
              }
            }
          } else {
            callback({}, _dataResultProps)
          }
        }
      }
      break;
  }
}

const getExistingData = (data, view, viewKey, uivi, eventPageKey, dataCollectionName) => {

  let hasExisting;
  let _existingData;
  let existingData;

  if (data) {
    if (eventPageKey) {
      if (data && uivi && data[eventPageKey] && data[eventPageKey][uivi]) {
        _existingData = data[eventPageKey][uivi]
        if (viewKey) {
          if (_existingData && _existingData[viewKey]) {
            existingData = {
              [viewKey]: _existingData[viewKey]
            }
            hasExisting = true
          } else if (view !== uivi) {
            existingData = _existingData
            hasExisting = true
          }
        } else {
          existingData = _existingData
          hasExisting = true
        }
      }
    } else {
      const _uivi = dataCollectionName ? dataCollectionName : uivi
      if (data && data[_uivi]) {
        _existingData = data[_uivi]
        if (!viewKey) {
          existingData = _existingData
          hasExisting = true
        } else {
          if (view === uivi && _existingData && _existingData[viewKey]) {
            existingData = { [viewKey]: _existingData[viewKey] }
          } else {
            existingData = _existingData
            hasExisting = true
          }
        }
      }
    }
  }

  return { hasExisting, existingData }

}

const filterTheData = (action, dataValue, dataResultProps, refProps, callback) => {

  const { getProps } = action

  const {
    _appDataSourceType,
    _dataSourceType,
    _usePostConstraint,
    appComponentStateCollection,
    appDataLinks,
    dataCollection,
    dataCollectionName,
    dataOverrideOn,
    dProps,
    fsr,
    hideInactiveItems_do,
    isSuperAdmin,
    staticViews_gs,
    staticViews,
    uivi,
    useSingleCollectionDocuments,
    viewItem_page,
    appDataSource,
  } = getProps

  const { useAppDataLinks, useAppDataLinksOnly, useAppDataDocuments } = appDataSource ?? {}

  let _dataValue = dataValue

  const _uivi = dataCollectionName ? dataCollectionName : uivi

  if (useSingleCollectionDocuments && dataValue && dataValue[_uivi] && dataValue[_uivi][_uivi]) {
    _dataValue = dataValue[_uivi][_uivi]
  }

  const { refProps: refProps_dp } = dProps ?? {}
  const _refProps = refProps ? refProps : refProps_dp
  const { whereOpts } = _refProps ?? {}

  if (viewItem_page) {
    if (_appDataSourceType === gEnums.dataSourceTypes.googleSpreadSheet) {
      ammendDataWithStaticViewValues(staticViews_gs, _dataValue)
    } else {
      ammendDataWithStaticViewValues(staticViews, _dataValue)
    }
  }

  if (useAppDataLinks && useAppDataLinksOnly && appDataLinks && appDataLinks[uivi]) {
    const appDataLink = appDataLinks[uivi] // all attendees
    const _dataItems = _dataValue
    _.each(_dataItems, (dataItem, dataItemKey) => {
      if (appDataLink[dataItemKey]) {
        const appDataLinkItem = appDataLink[dataItemKey]
        _.each(appDataLinkItem, (linkItems, linkItemKey) => {
          if (dataItem[linkItemKey]) {
            dataItem[linkItemKey] = linkItems
          }
        })
      }
    })
  }

  // const { whereOpts } = fsr ?? {}
  const { posts: wherez, dataKeys } = whereOpts ?? {}

  // the postDataConstraits are only filtered in certain cases 
  const _usePostConstraints = !dataOverrideOn && (_usePostConstraint || hideInactiveItems_do || useAppDataDocuments) && (wherez && wherez.length > 0)

  let { postData: _postData, excludedData } = _usePostConstraints ? postDataConstraits(whereOpts, _dataValue) : { postData: _dataValue }
  if (dataKeys && dataKeys.length > 0) { _postData = postKeyConstraits(fsr, _postData) }

  let missingSvData = {}
  if (isSuperAdmin) {
    const _staticView = staticViews && staticViews[dataCollection] ? staticViews[dataCollection] : {}
    missingSvData = _.difference(Object.keys(_dataValue), Object.keys(_staticView));
  }

  callback({ _postData, _dataSourceType, dataValue: _dataValue, refProps: _refProps, dataResultProps, dataCollection, missingSvData, excludedData, appComponentStateCollection })

}


const ammendStatic = (staticViews, uivi) => {
  const vitStaticData = staticViews[uivi]
  if (vitStaticData) {
    Object.keys(vitStaticData).forEach(key => {
      if (_.isObject(vitStaticData[key])) {
        vitStaticData[key].id = key
        vitStaticData[key]._itemKey = key
      }
    })
    delete vitStaticData.id
    delete vitStaticData.uid
  }

  return vitStaticData
}

const getDependencyData = (documentDependencies, singles, uivi, viewKey) => {
  let d;
  documentDependencies.forEach(dd => {
    if (singles[dd]) {
      d = singles[dd]
    } else if (d && d[dd] && d[dd][viewKey]) {
      d = d[dd][viewKey]
    }
  })
  d = d ? d[uivi] : null
  return d
}

const updateStaticView = (actionItem) => {

  const { pathViews, viewListData, staticView, uniqueProps, viewItem } = actionItem
  const staticItems = getStaticItems(Object.values(viewListData), 'name', uniqueProps)

  convertHelpers.createItemKeys(staticView)
  convertHelpers.createItemKeys(staticItems)

  const sorted = {
    staticView: _.sortBy(staticView, 'name'),
    staticItems: _.sortBy(staticItems, 'name')
  }

  const areEqual = _.isEqualWith(sorted.staticItems, sorted.staticView, (obj1, obj2) => {
    return _.isEqual(_.sortBy(_.toPairs(obj1)), _.sortBy(_.toPairs(obj2)));
  });

  const missing = {
    staticView: _.differenceWith(sorted.staticItems, sorted.staticView, _.isEqual),
    staticItems: _.differenceWith(sorted.staticView, sorted.staticItems, _.isEqual)
  }

  // console.log('staticItems', staticItems)
  // console.log('viewListData', viewListData)
  // console.log('areEqual', areEqual)
  // console.log('sorted', sorted)

  if (!areEqual && missing.staticView.length > 0) {
    console.log('Updating the static views', viewItem.key, actionItem)
    removeItemKeys(staticItems)
    saveToStaticViews(pathViews, viewItem, staticItems, viewItem.key)
  }
}