import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Segment, Sidebar } from 'semantic-ui-react';
import CreateProvider, { CreateContext } from '../../cnr/contexts/CreateContext';
import FetchProvider, { FetchContext } from '../../cnr/contexts/FetchContext';
import { ParentContext } from '../../cnr/contexts/ParentContext';
import RegDataProvider, { RegDataContext } from '../../cnr/contexts/RegDataContext';
import { createTypes } from '../../cnr/reducers/CreateReducer';
import { viewDataModes } from '../../cnr/reducers/DataManagmentReducer';
import UiSaveButtons from '../../components/buttons/UiSaveButtons';
import { appIconTypes } from '../../enums/appIconTypes';
import { updateSettings } from '../../firestoreData/settings/updateSettings';
import UiConfirmation from '../../pageItem/modification/UiConfirmation';
import SettingsViewer, { settingsViewerTypes } from '../../viewSettings/actions/SettingsViewer';
import { openExternal } from '../../viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../wrappers/Wrapper';
import NoData from '../alerts/NoData';
import GoogleSheetsTabs from './GoogleSheetsTabs';
import SearchInputForm from './SearchInputForm';
import { clickOriginTypes } from '../../enums/globalEnums';

// https://bostonmutual.events.meetingevolution.net/index.php/attendeeregistration-event-sessions.html?eventId=6549374316412
// https://bostonmutual.events.meetingevolution.net/index.php/attendeeregistration-event-create-mobile-app.html?eventId=6549374316412

export const RegDataViewer = (props) => {

  const { noModal, noWizard, fromCreate, clickOriginType, handleCancel } = props ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
      _fromDataViewer = true
    default:
    //nothing
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // createContext
  const createContext = useContext(CreateContext)
  const { create_handlers, create_state } = createContext ?? {}
  const { updating, fetchResult, projectResult } = create_state ?? {}

  // regDataContext
  const regDataContext = useContext(RegDataContext)
  const { regData_state, regData_handlers } = regDataContext ?? {}
  const { projectData, projectSettings, showCommitRegData, noRegUrl } = regData_state ?? {}

  // fetchContext
  const fetchContext = useContext(FetchContext)
  const { fetch_handlers } = fetchContext ?? {}

  const settingsCreateType = regDataContext ? settingsViewerTypes.settingsOnly : createTypes.createEvent

  const previewInfo = {
    projectData: {
      dataCollections: projectData
    }
  }

  const _projectResult = projectResult ? projectResult : previewInfo

  const callback_settings = () => setUpdating_s()

  useEffect(() => {
    if (fetchResult) {
      create_handlers.handleCreateProject()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [fetchResult]);


  const [regEventKey, setRegEventKey] = useState()
  const [activeTab, handleActiveTab] = useState()
  const [viewDataMode, setViewDataMode] = useState()
  const [updating_s, setUpdating_s] = useState()

  const _updating = updating || updating_s

  const { name } = activeTab ?? {}

  const handleGetRegData = () => fetch_handlers.handleFetch_urlFeed(null, regEventKey)
  const handleRegEventKeyChange = (e, data) => setRegEventKey(data.value)
  const handleViewDataMode = (opts) => setViewDataMode(opts)
  const handleCommit = (name) => regData_handlers.handleUpdate_collectionWithClear(name)
  const handleDatabaseOpen = () => openExternal.db(pathViews, null, name)

  const handleSettingsDbUpdate = () => {
    setUpdating_s(true)
    updateSettings.createNewViewsAndViewItemsSettingsToDb(pathViews, projectSettings, callback_settings)
  }

  const handleShowCommitRegData = () => regData_handlers.handleShowCommitRegData()

  /**
   * 
   * @returns a small from with the input for the google sheets id
   */
  const inputForm = () => <SearchInputForm
    disabled={!regEventKey}
    label='Google Sheets Key'
    noSeg={true}
    onChange={handleRegEventKeyChange}
    onClick={handleGetRegData}
    value={regEventKey ? regEventKey : ''}
  />

  const topButtons = () => {
    const btns = [
      { caption: _.startCase(viewDataModes.viewSettings), oc: handleViewDataMode, icon: 'settings', opts: { viewDataMode: viewDataModes.viewJson } },
    ]
    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const bottomButtons = () => {
    const btns = [
      { caption: 'Open Database', oc: handleDatabaseOpen, icon: 'database' },
    ]
    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const saveButtons = () => {
    const btns = [
      { caption: 'Replace Data in Database', oc: handleShowCommitRegData, icon: appIconTypes.arrowRight, opts: { mergeWith: 'regData' } }
    ]
    return <UiSaveButtons
      others={btns}
      color={'blue'}
      rows={2}
    />
  }

  const tab = () => <GoogleSheetsTabs previewInfo={_projectResult} handleActiveTab={handleActiveTab} />

  const settingsViewer = () => <SettingsViewer
    createInfo={_projectResult}
    createType={settingsCreateType}
    ignoreSingleUpdates={true}
    handleUpdateClick={handleSettingsDbUpdate}
  />

  const content = () => <React.Fragment>
    {inputForm()}
    {_projectResult && settingsViewer()}
  </React.Fragment>

  const header = () => <div className={'header-flex'}>
    {inputForm()}
    {topButtons()}
  </div>

  const footer = () => <div className={'header-flex'}>
    {bottomButtons()}
    {saveButtons()}
  </div>


  let cn = 'wrp-sub'

  const VerticalSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'right'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='vsr-container reg'
      onHide={() => handleViewDataMode()}
    >
      {settingsViewer()}
    </Sidebar>
  )

  const wrapper = () => <Wrapper
    header={header()}
    content={tab()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={_updating}
    css={{ container: cn, content: 'google-data-container' }}
  />

  const sidebar = () => {
    return <Sidebar.Pushable style={{ overflow: 'hidden' }}>
      <VerticalSidebar visible={viewDataMode ? true : false} />
      <Sidebar.Pusher dimmed={viewDataMode ? true : false} className={'h100'}>
        {wrapper()}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  }

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    handleCancel={handleCancel}
    topperCaption={'Reg'}
    noModal={noModal}
  />

  if (showCommitRegData) {
    return <UiConfirmation {...showCommitRegData} handleUpdate={handleCommit} handleCancel={showCommitRegData} useFull={true} />
  } else {
    if (noRegUrl) {
      return <NoData altCaption={'Registration Url'} />
    } else {
      return noWizard || fromCreate ?
        content()
        :
        _fromDataViewer ? sidebar() : fullPageWrapper()
    }
  }
}

const RegDataViewerWithProvider = (props) => {
  return <CreateProvider>
    <RegDataProvider>
      <FetchProvider>
        <RegDataViewer {...props} />
      </FetchProvider>
    </RegDataProvider>
  </CreateProvider>
}

export default RegDataViewerWithProvider