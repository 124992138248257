import React, { createContext, useContext } from 'react';
import '../../../cssAlt/AppM.css';
import { UiItemContext } from '../../../global/cnr/contexts/UiItemContext';
import { gEnums } from '../../../global/enums/globalEnums';
import CreateWeeklyLabRun from './CreateWeeklyLabRun';
import LabSample from '../elements/LabSample';
import { AppComponentContext } from '../../../global/cnr/contexts/AppComponentContext';

export const CreateLabContext = createContext();

const CreateLab = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const labContext = useContext(AppComponentContext)
  const { lab_state, lab_handlers } = labContext ?? {}
  const { updating, runs, weeklyLabSamples, weeklyLabRuns_grouped, currentYear, currentWeek } = lab_state ?? {}

  console.log('lab_state', lab_state)

  const { subActionType } = props

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}

  const content = () => {

    switch (subActionType) {
      case _asat.createWeeklyLabRun:
        return <CreateWeeklyLabRun handleClose={props.handleClose} />

      case _asat.updateLabResult:
      case _asat.findLabResult:
        return <LabSample handleClose={props.handleClose} subActionType={subActionType} />

      default:
        return <div>{'subActionType is not recognized'}</div>
    }
  }

  return content()
}

export default CreateLab