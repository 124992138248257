import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { getBaseRef } from '../../firestoreData/helpers/getBaseRef';

export const copyViewItemSettingsFromDatabase = (dispatch, paps_state, aps_global, views, swipedItem, updateProps, page_fns) => {

  const { pathViews, view } = paps_state
  const { vit } = updateProps

  const { getGviDeps } = page_fns ?? {}

  const viDeps = getGviDeps(view)
  const baseRef = getBaseRef(viDeps, pathViews)
  const { refPath } = baseRef
  // LOOK 
  const _refPath = createRefPath([vit], refPath)

  fs_get_data({ refPath: _refPath }).then(propData => {
    delete propData.id
    const settings_temp = { ...aps_global }
    const { viewItems: aps_viewItems } = settings_temp
    if (aps_viewItems && vit) {
      if (aps_viewItems[vit]) {
        copyViewItemSettingsToViews(dispatch, settings_temp, views, swipedItem)
      }
    }
  })
}

/** Copies the global view settings to all the viewItems */
export const copyViewItemSettingsToViews = (dispatch, aps_global, aps_views, aps_viewItems, updateProps) => {

  const { vit } = updateProps ?? {}
  const vitList = vit + '_list'
  const settings_temp = { ...aps_global }
  const new_views = {}

  // after, view will have menu, ui and viewItems
  Object.keys(aps_views).forEach(key => { new_views[key] = aps_views[key] })

  if (aps_viewItems && vit) {

    if (aps_viewItems[vit]) {

      const gvi = aps_viewItems[vit]

      if (!new_views[vit]) { new_views[vit] = {} }
      if (!new_views[vitList]) { new_views[vitList] = {} }

      // clean up
      delete new_views[vit].props
      delete new_views[vit].propSections

      delete new_views[vitList].props
      delete new_views[vitList].propSections

      // add if needed
      if (!new_views[vit].viewItems) { new_views[vit].viewItems = {} }
      if (!new_views[vitList].viewItems) { new_views[vitList].viewItems = {} }

      new_views[vit].viewItems[vit] = gvi
      new_views[vitList].viewItems[vit] = gvi

      // certain settings should not be included, so remove them
      delete new_views[vit].viewItems[vit].data
      delete new_views[vitList].viewItems[vit].dataConnection

      if (new_views) {
        Object.keys(new_views).forEach(nvKey => {
          const nv = new_views[nvKey]
          const { viewItems } = nv ?? {}
          if (viewItems && viewItems[vit]) {
            viewItems[vit] = gvi
            delete viewItems[vit].data
            delete viewItems[vit].dataConnection
          }
        })
      }
    }
  }

  const createInfo = { settings_temp, views: new_views }
  dispatch({ type: 'showPreview', previewInfo: createInfo });
}

export const addViewItemPropViews = async (_viewItems, views, viewItem_vit, propsToAdd, vit) => {
  if (_viewItems && vit) {
    console.log('propsToAdd', propsToAdd)
    const vitList = vit + '_list'
    Object.keys(propsToAdd).forEach(async key => {
      const prop = propsToAdd[key]
      if (viewItem_vit && viewItem_vit.props && viewItem_vit.props[prop.key]) {
        if (_viewItems[vit] && _viewItems[vit].props && !_viewItems[vit].props[prop.key]) {
          _viewItems[vit].props[prop.key] = viewItem_vit.props[prop.key]
        }
        if (views[vit] && views[vit].viewItems[vit] && views[vit].viewItems[vit].props && !views[vit].viewItems[vit].props[prop.key]) {
          views[vit].viewItems[vit].props[prop.key] = viewItem_vit.props[prop.key]
        }
        if (views[vitList] && views[vitList].viewItems[vit] && views[vitList].viewItems[vit].props && !views[vitList].viewItems[vit].props[prop.key]) {
          views[vitList].viewItems[vit].props[prop.key] = viewItem_vit.props[prop.key]
        }
      }
    })
  }
}