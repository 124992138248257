import { getDownloadURL, getStorage, list, listAll, ref } from "firebase/storage";
import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { createRefPath } from '../firestoreData/appData/appRefPaths';
import { getGalleryMetadata_global } from "./storageItems";
import { _projectAppNames, getAppName, getFbConfig } from "../../project/appConfiguration";
import { initializeApp } from "firebase/app";
import { fbAppConfigs } from "../../project/appConfigs";

export const _storageSettings = {
  galleryPaths: {
    galleryDirect: '_gallery_direct',
    galleryEvent: '_gallery_event',
    galleryPage: '_gallery_page',
    pagePdf: '_page_pdf',
    profile_all: '_gallery_profile_all',
    profiles: 'profiles'
  },
  galleryTypes: {
    event: 'event',
    direct: 'direct',
    pdf: 'pdf',
    page: 'page',
    profiles: 'profiles',
  },
  maxResults: 15,
  thumbSuffix: '-thumb',
  allowUpload: true,
  logResult: false
}

export const getFirebaseStorage = () => {

  const { appName } = getAppName(true)
  let _altDb;

  switch (appName) {
    case _projectAppNames.pojo:
      _altDb = _projectAppNames.meetingevolution
      break;
    case _projectAppNames.fcasd_ft:
      _altDb = _projectAppNames.fcasd_ft
      break;
    default:
      _altDb = _projectAppNames.meetingevolution
  }
  // const environment = 'dev'

  const config = getFbConfig(true)
  // const _fsKey = _altDb && environment ? _altDb + '_' + environment : null
  // const _config_alt = fbAppConfigs[_altDb][environment] 

  let _init_alt;
  let storage;

  try {
    _init_alt = initializeApp(config)
    storage = getStorage(_init_alt)
  } catch (error) {
    // console.log('getFirebaseStorage error', error)
    storage = getStorage()
  }
  return storage
}

/** gets an item for each of the items in the globalFileGroup and assigns the an object
 * urls: full: xxx, thumbnail: yyy}
 */

const getItemUrls = (globalFileGroup, urlz) => {
  const { items } = globalFileGroup ?? {}
  const res = {}
  if (items) {
    items.forEach(item => {
      const { name, fullPath } = item
      // full only
      if (name.indexOf(_storageSettings.thumbSuffix) < 0) {
        if (!res[name]) { res[name] = { urls: {} } }
        const y = findUrls(urlz, name)
        res[name] = {
          urls: {
            full: y.full ? y.full : null,
            thumbnail: y.thumbnail ? y.thumbnail : null,
          },
          fullPaths: {
            full: fullPath,
            thumbnail: fullPath + _storageSettings.thumbSuffix,
          }
        }
      }
    })
  }
  return res
}

const findUrls = (urls, name) => {
  let _name = name.replace(/\s/g, '%20')
  _name = _name.replace(/@/g, '%40')
  const u = { thumbnail: null, full: null }
  if (urls) {
    urls.forEach(url => {
      if (url && url.indexOf(_name) >= 0) {
        if ((url && url.indexOf(_storageSettings.thumbSuffix) >= 0)) {
          u.thumbnail = url
        } else {
          u.full = url
        }
      }
    })
  }
  return u
}


/** Gets all files within a storage folder */
export const getAllImagesInPath = async (storagePath, callback) => {
  const storage = getFirebaseStorage()
  const storageRef = ref(storage, storagePath)
  try {
    const globalFileGroup = await list(storageRef, { maxResults: _storageSettings.maxResults });
    const urlz = thumbnailsDownloadUrlPromise(globalFileGroup)
    if (globalFileGroup && urlz) {
      const res = getItemUrls(globalFileGroup, urlz);
      callback(res);
    } else {
      callback({});
    }
  } catch {
    callback({});
  }
}

/** Gets the getDownloadURL files within a storage folder */
const thumbnailsDownloadUrlPromise = async (res) => {

  const { items } = res ?? {}

  const promises = []
  items.forEach(function (itemRef) {
    const { _location } = itemRef
    const { path_ } = _location
    const storage = getFirebaseStorage()
    const iconRef = ref(storage, path_)
    promises.push(iconRef.getDownloadURL())
  });
  return Promise.all(promises)
}

/**
 * Gets the files from the floormap, icon, image and pdf folders
 * @param {string} storageRootPath 
 * @param {boolean} useUnderscorePrefix 
 * @param {function} callback 
 * @returns {floormap, icon, image, pdf}
 */
export const getGlobalStorageFiles = async (storageRootPath, useUnderscorePrefix, callback) => {
  const globalFileGroups = await globalFilesPromise(storageRootPath, useUnderscorePrefix)
  const res = await fileGroupsDownloadUrlPromise(globalFileGroups)
  ammendGlobalFiles(globalFileGroups, res, callback)
}

/**
 * Gets the files from the floormap, icon, image and pdf folders
 * @param {string} storageRootPath 
 * @param {boolean} useUnderscorePrefix 
 * @param {function} callback 
 * @returns {floormap, icon, image, pdf}
 */
export const getClientStorageFiles = async (storageRootPath, clientStorageImageCollections, appUsers, staticViews, callback) => {
  const galleries_client = await clientFilesPromise(storageRootPath, clientStorageImageCollections, staticViews)
  callback(galleries_client, appUsers)
}

/** Gets the files from the floormap, icon, image and pdf folders
 * @returns floormap
 * @returns icon
 * @returns image
 * @returns pdf
 */
const globalFilesPromise = async (storageRootPath, useUnderscorePrefix) => {

  const storage = getFirebaseStorage()

  const refs = {
    floormap: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.floormap),
    icon: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.icon),
    image: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.image),
    pdf: ref(storage, storageRootPath + '/' + (useUnderscorePrefix ? '_' : '') + gEnums.storageTypes.pdf),
  }

  const x = await getListAllPromises(refs)
  return x
}

async function listFilesAndGenerateURLs(folderRef) {

  const folderResult = await listAll(folderRef);

  const filePromises = folderResult.items.map(async (fileRef) => {
    const downloadURL = await getDownloadURL(fileRef);
    const { name, parent } = fileRef
    const { name: name_parent } = parent
    return {
      collectionKey: name_parent,
      fileName: name,
      downloadURL: downloadURL,
    };
  });

  const files = await Promise.all(filePromises);

  const subfolderPromises = folderResult.prefixes.map((subfolderRef) => listFilesAndGenerateURLs(subfolderRef));

  const subfolderFiles = await Promise.all(subfolderPromises);

  // Flatten the array of arrays of files
  const allFiles = files.concat(...subfolderFiles);

  return allFiles;
}

//https://thumbstat.com/#/clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc

async function processFolderPath(folderItem) {

  const { collectionName, folderPath } = folderItem
  const storage = getFirebaseStorage()
  const folderRef = ref(storage, folderPath);
  const files = await listFilesAndGenerateURLs(folderRef);

  return {
    collectionName,
    folderPath: folderItem,
    files: files,
  };
}

// clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc
// clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc

// https://thumbstat.com/#/clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc
// https://thumbstat.com/#/clients/thumbstat/events/thumbstatSports/organizations/BjY3VqQ3lUBQ9PXDyXCl/districts/qJOYke2iCxxT8RppMcsc

const clientFilesPromise = async (storageRootPath, clientStorageImageCollections, staticViews) => {

  const folderPaths = []

  if (storageRootPath && clientStorageImageCollections) {
    clientStorageImageCollections.forEach(csic => {
      folderPaths.push({ collectionName: csic, folderPath: storageRootPath + csic })
    })
  }

  const promises = folderPaths.map(processFolderPath);

  // Use Promise.all to wait for all promises to resolve
  return Promise.all(promises)
    .then((clientResults) => {
      if (clientResults) {
        const _collectionGroups = {}
        const collectionGroups = {}
        clientResults.forEach(result => {
          const { collectionName, files } = result
          collectionGroups[collectionName] = _.groupBy(files, 'collectionKey')
        })
        Object.keys(collectionGroups).forEach(collectionKey => {
          const collectionGroup = collectionGroups[collectionKey]
          _collectionGroups[collectionKey] = {}
          if (collectionGroup) {
            Object.keys(collectionGroup).forEach(collectionItemKey => {
              const collectionItemFiles = collectionGroup[collectionItemKey]
              if (collectionItemFiles) {
                _collectionGroups[collectionKey][collectionItemKey] = { urls: {} }
                collectionItemFiles.forEach(cif => {
                  const { fileName, downloadURL } = cif
                  _collectionGroups[collectionKey][collectionItemKey].fileName = fileName
                  _collectionGroups[collectionKey][collectionItemKey].itemName = fileName.split('.')[0]
                  if (fileName.indexOf('-thumb') >= 0) {
                    _collectionGroups[collectionKey][collectionItemKey].urls.thumbnail = downloadURL
                  } else {
                    _collectionGroups[collectionKey][collectionItemKey].urls.full = downloadURL
                  }
                })
              }
            })
          }
        })
        return _collectionGroups
      }
      // Do something with the results
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle the error
    });

  // const y = await getListAllFilesInFolders(refs)

  // clientStorageImageCollections.forEach((csic, index) => {
  //   results[csic].items = y[index]
  // })

  // const promisesR = []

  // Object.keys(results).forEach(key => {
  //   const result = results[key]
  //   promisesR.push(getDownloadedUrlFiles(result))
  // })

  // return Promise.all(promisesR)

}

async function getListAllFilesInFolders(folderRefs) {

  try {
    // Function to list files in a folder and its subfolders
    const listFilesInFolder = async (folderRef) => {
      const folderResult = await listAll(folderRef);
      const { prefixes } = folderResult ?? {}
      return prefixes
    };

    const promises = []

    Object.keys(folderRefs).forEach(async key => {
      const folderRef = folderRefs[key]
      const prefixes = await listFilesInFolder(folderRef)
      promises.push(prefixes)
    })

    // Use Promise.all to wait for all promises to resolve
    const res = await Promise.all(promises);
    return res;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}

async function getListAllPromises(storageRefs) {
  try {
    // Use map to create an array of promises for each reference
    const promises = Object.keys(storageRefs).map(async (key) => {
      const ref = storageRefs[key]
      // Use listAll for each reference
      const result = await listAll(ref);
      return result;
    });

    // Use Promise.all to wait for all promises to resolve
    const results = await Promise.all(promises);

    return results;
  } catch (error) {
    console.error('Error fetching data:', error);
    return {}
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
}


/** Promise that returns the url of the lists' items */
const fileGroupsDownloadUrlPromise = (globalFileGroups) => {
  const promises = []
  if (globalFileGroups && _.isArray(globalFileGroups)) {
    globalFileGroups.forEach(async (globalFileGroup) => {
      promises.push(getDownloadedUrlFiles(globalFileGroup))
    })
  }
  return Promise.all(promises)
}

/**
 * 
 * @param {object} globalFileGroup 
 * @returns the urls (full and thumbnail) from the globalFileGroup
 */
export const getDownloadedUrlFiles = async (globalFileGroup) => {

  const storage = getFirebaseStorage()

  const { items } = globalFileGroup ? globalFileGroup : {}

  const promises = []
  if (items) {
    items.forEach(function (itemRef) {
      const { fullPath } = itemRef
      const iconRef = ref(storage, fullPath)
      promises.push(getDownloadURL(iconRef))
    });
  }

  return Promise.all(promises)
}

/** Loops through each of the globalFiles and assigns each item a url.full and url.thumbnail */
const ammendGlobalFiles = (globalFileGroups, urlResults, callback) => {

  const storage = getFirebaseStorage();

  const gfs = {
    [gEnums.storageTypes.floormap]: [],
    [gEnums.storageTypes.icon]: [],
    [gEnums.storageTypes.image]: [],
    [gEnums.storageTypes.pdf]: [],
  }

  if (globalFileGroups && _.isArray(globalFileGroups)) {
    globalFileGroups.forEach((globalFileGroup, index) => {
      const urlz = urlResults[index]
      const results = getItemUrls(globalFileGroup, urlz)
      switch (index) {
        case 0:
          gfs[gEnums.storageTypes.floormap] = results
          break;
        case 1:
          gfs[gEnums.storageTypes.icon] = results
          break;
        case 2:
          gfs[gEnums.storageTypes.image] = results
          break;
        case 3:
          gfs[gEnums.storageTypes.pdf] = results
          break;
        default:
        // nothing
      }
    })
  }

  getGalleryMetadata_global(gfs, storage, callback)
}

export const profileImagePromises = (currentUser) => {

  const { email } = currentUser ?? {}

  const storage = getFirebaseStorage()

  // set the file name to `profile` so there is only one of them
  const sr1 = ref(storage, createRefPath([_storageSettings.galleryPaths.profiles, email, 'profile']))
  const sr2 = ref(storage, createRefPath([_storageSettings.galleryPaths.profiles, email, 'profile' + _storageSettings.thumbSuffix]))

  const promises = []
  promises.push(sp(sr1))
  promises.push(sp(sr2))
  return Promise.all(promises)
}

const getProfileUrls = (appUsers, valid) => {
  const _profileUrls = {}
  Object.keys(appUsers).forEach((key, ai) => {
    const appUser = appUsers[key]
    const { email } = appUser
    const profileUrls = email ? findUrls(valid, email) : null
    if (profileUrls && (profileUrls.full && profileUrls.thumbnail)) {
      appUser.profileUrls = profileUrls
      _profileUrls[email] = profileUrls
    }
  })
}

/** Returns a promise of all the profile storage items */
export const getAllAppUserUrls = async (appUsers, callback) => {
  const imgResult = await profileImagesPromises(appUsers)
  const valid = getValidUrls(imgResult)
  const _profileUrls = getProfileUrls(appUsers, valid)
  callback(_profileUrls)
  // profileImagesPromises(appUsers).then(imgResult => {
  //   const valid = getValidUrls(imgResult)
  //   const _profileUrls = {}
  //   Object.keys(appUsers).forEach((key, ai) => {
  //     const appUser = appUsers[key]
  //     const { email } = appUser
  //     const profileUrls = email ? findUrls(valid, email) : null
  //     if (profileUrls && (profileUrls.full && profileUrls.thumbnail)) {
  //       appUser.profileUrls = profileUrls
  //       _profileUrls[email] = profileUrls
  //     }
  //   })
  //   callback(_profileUrls)
  // })
}

/** Returns a promise of all the profile storage items */
const profileImagesPromises = async (data) => {
  const promises = []
  if (data) {
    Object.keys(data).forEach(key => {
      const _dataItem = data[key]
      const { email } = _dataItem ? _dataItem : {}
      if (email) {
        const storage = getFirebaseStorage()
        const sr1 = ref(storage, _storageSettings.galleryPaths.profiles + '/' + email + '/profile')
        const sr2 = ref(storage, _storageSettings.galleryPaths.profiles + '/' + email + '/profile' + _storageSettings.thumbSuffix)
        promises.push(sp(sr1))
        promises.push(sp(sr2))
      }
    })
  }
  return Promise.all(promises)
}

const getValidUrls = (imgResult) => {
  const valid = []
  imgResult.forEach(ir => {
    if (ir) {
      valid.push(ir)
    }
  })
  return valid
}

const sp = (sr) => {
  let storagePromise = getDownloadURL(sr).then(function (url) {
    return url
  }).catch(function (error) {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        // console.log('profiles/email', 'File does not exist')
        break;
      case 'storage/unauthorized':
        // console.log('profiles/email', 'User does not have permission to access the object')
        break;
      case 'storage/canceled':
        // console.log('profiles/email', 'User canceled the upload')
        break;
      case 'storage/unknown':
        // console.log('profiles/email', 'Unknown error occurred, inspect the server response')
        break;
      default:
      // console.log('profiles/email', 'Unknown error occurred, inspect the server response')
    }
  });
  return storagePromise
}

export const fetchFiles = async () => {

  try {
    const storage = getFirebaseStorage()

    const refs = {
      pdfs: ref(storage, 'clients/nplGL5xAI6N2uWX2TBsw/events/rLT40U6OTHiaCeYL0GCh/_page_pdf/sessions'),
    }

    // List all files in the root folder
    // const rootFiles = await listAll(rootRef);
    const x = await getListAllPromises(refs)

    // Iterate over the subfolders
    // const subfoldersPromises = rootFiles.prefixes.map(async (subfolder) => {
    //   try {
    //     const subfolderFiles = await listAll(subfolder.fullPath);
    //     return subfolderFiles.items.map((file) => file.fullPath);
    //   } catch (error) {
    //     console.error('Error fetching files:', error);
    //   }

    // });

    // // Flatten the array of file paths
    // const allFiles = await Promise.all(subfoldersPromises);
    // const flattenedFiles = allFiles.flat();

    // console.log('flattenedFiles', flattenedFiles)

  } catch (error) {
    console.error('Error fetching files:', error);
  }
}
