import { getParentKeyData_sports } from '../cnr/reducers/reducerHelpers/parentKeySportsData';
// import { updateSchoolSports } from './schoolSports';
import { seasonalHelpers, seasonalUpdateTypes } from '../cnr/reducers/reducerHelpers/seasonalSportsData';

export const updateTeamAlignment = async (sportPermissions, paps_state, latestSeason, actionProps, handleDone) => {

  const { pathViews } = paps_state
  const { data } = actionProps
  const pks = seasonalHelpers.getPks(pathViews, latestSeason)
  // updates `_seasonals`

  const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, handleDone, null, 'teams', data)
  seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teams, _updateProps)

}

/** Returns a list a team with `levels` and `sections` already attached */
export const getTeamAlignment = (paps_state, latestSeason, sportsKey, viewItem, setTeamAlignment) => {

  const { pathViews } = paps_state
  const { dataSource } = viewItem ?? {}
  const { dataParents } = dataSource ?? {}

  const handleTeamAlignment = (seasonalItem) => {
    const si = seasonalItem[Object.keys(seasonalItem)[0]]
    setTeamAlignment(si)
  }

  const handleTeams = (teams) => {
    // console.log('teams', teams)
  }

  seasonalHelpers.getSeasonalData(pathViews, sportsKey, latestSeason, handleTeamAlignment)
  getParentKeyData_sports(pathViews, { collectionName: viewItem.key }, dataParents, handleTeams)
}

