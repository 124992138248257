import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext';

const MatchOptions = () => {

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state, sportsMatch_handlers } = sportsMatchContext ?? {}
  const { match_true } = sportsMatch_state ?? {}

  const handleShow_matchOptions = (e) => {
    e && e.stopPropagation()
    sportsMatch_handlers.handleShow_matchOptions(match_true)
  }

  const handleShow_matchScoreTable = (e) => {
    e && e.stopPropagation()
    sportsMatch_handlers.handleShow_scoreEdit(match_true)
  }

  const match_options = () => <div className='match-score-btns opts' onClick={(e) => { handleShow_matchOptions(e) }}>
    <Button.Group size='mini' fluid >
      <Button color={'blue'} onClick={(e) => { handleShow_matchScoreTable(e) }} >{'Start RTS'}</Button>
      <Button color={'blue'} onClick={(e) => { handleShow_matchScoreTable(e) }} >{'Update Results'}</Button>
    </Button.Group>
  </div>

  return match_options()

}

export default MatchOptions