import { arrayUnion, where } from "firebase/firestore";
import { userCollectionTypes, _conversationsCollection } from '../../cnr/reducers/AppUserDataReducer';
import { fs_get_data, _dataOptions } from '../appData/fsAppData';
import { createRefPath, createRefPath_client } from '../appData/appRefPaths';
import { fs_add_doc, fs_update_doc } from "../appData/fsData";
import { getBaseRef } from '../helpers/getBaseRef';

export const getUserCollectionData = (appUserAccess, userCollectionType, paps_state, callback) => {

  const { pathViews } = paps_state ?? {}
  const { uid, appUserSessionKey, email } = appUserAccess ?? {}
  const _uid = uid ? uid : appUserSessionKey

  let _refPath = createRefPath_client(pathViews, ['profiles', email, userCollectionType])

  if (_uid) {
    const wheres = []
    switch (userCollectionType) {
      case userCollectionTypes.conversations:
        wheres.push(where("recipients", "array-contains", _uid))
        break;
      case userCollectionTypes.notes:
        // _refPath = createRefPath([_uid], _refPath)
        break;
      default:
        break;
    }

    fs_get_data({ refPath: _refPath, wheres, callback, opts: { [_dataOptions.ignoreId]: true } })

  }

}

export const updateUserCollectionData = (appUserAccess, userCollectionType, paps_state, message, dispatch) => {

  const allowUpdates = true

  const { appUserSession } = appUserAccess ?? {}
  const { email } = appUserSession ?? {}

  if (email && message) {
    const { pathViews } = paps_state
    if (message._itemKey) {
      const _refPathUpdate = createRefPath_client(pathViews, ['profiles', email, userCollectionType, message._itemKey])
      if (allowUpdates) {
        fs_update_doc(_refPathUpdate, message, dispatch)
      } else {
        console.log('updateUserCollectionData', _refPathUpdate)
      }
    } else {
      const _refPathAdd = createRefPath_client(pathViews, ['profiles', email, userCollectionType])
      if (allowUpdates) {
        fs_add_doc(_refPathAdd, message, dispatch)
      } else {
        console.log('updateUserCollectionData', _refPathAdd)
      }
    }
  }
}

export const updateMarkAsRead = (appUser, paps_state, noteId, pageContext) => {

  const { page_fns } = pageContext ?? {}
  const { getGviDeps } = page_fns ?? {}

  if (noteId) {
    const { uid } = appUser ?? {}
    const { pathViews } = paps_state
    const viDeps = getGviDeps(_conversationsCollection)
    const baseRef = getBaseRef(viDeps, pathViews)
    const { refPath } = baseRef
    const data = { readBy: arrayUnion(uid) }
    const _refPath = createRefPath([_conversationsCollection, noteId], refPath)
    fs_update_doc(_refPath, data)
  }
}