import React, { createContext, useContext, useState } from 'react';
import { separateContexts } from '../../common/filtering';
import { AppAccessContext } from './AppAccessContext';
import { QuestionsAndAnswersContext, RatingContext, SurveyContext } from './AppAdminContext';
import { AppDataContext } from './AppDataContext';
import { AppFormsContext } from './AppFormsContext';
import { AppNotificationsContext } from './AppNotificationsContext';
import { AppSettingsContext } from './AppSettingsContext';
import { AppUserAuthContext } from './AppUserAuthContext';
import { AppUserContext } from './AppUserContext';
import { AuthContext } from './AuthContext';
import { ClientInfoContext } from './ClientInfoContext';
import { DataWrapperContext } from './DataWrapperContext';
import { EventInfoContext } from './EventInfoContext';
import { ManifestContext } from './ManifestContext';
import { PageContext } from './PageContext';
import { PapsContext } from './PapsContext';
import { PreviewContext } from './PreviewContext';
import { SearchParamContext } from './SearchParamContext';
import { ClientSettingsContext, EventSettingsContext, HomeSettingsContext } from './SettingsDataContext';
import { StartContext } from './StartContext';
import { StartDataContext } from './StartDataContext';
import { StorageContext } from './StorageContext';
import { TransitionContext } from './TransitionContext';
import { ValidationContext } from './ValidationContext';
import { VideoConferenceContext } from './VideoConferenceContext';
import { ServiceWorkerContext } from './ServiceWorkerContext';


/**
 * Provider providing the following Contexts
  appAccessContext
  , `appFormsContext`
  , `appNotificationsContext`
  , `appSettingsContext` 
  , `authContext` 
  , `clientSettingsContext` 
  , `appUserDataContext` 
  , `dataWrapperContext` 
  , `eventSettingsContext` 
  , `homeSettingsContext`
  , `manifestContext`
  , `pageContext`
  , `papsContext` 
  , `startContext`
  , `startDataContext`
  , `eventInfoContext`
  , `storageContext`
  , `videoConferenceContext`
 */
export const ParentContext = createContext();

const ParentProvider = (props) => {

  const appAccessContext = useContext(AppAccessContext);
  const appDataContext = useContext(AppDataContext);
  const appFormsContext = useContext(AppFormsContext);
  const appNotificationsContext = useContext(AppNotificationsContext);
  const appSettingsContext = useContext(AppSettingsContext)
  const appUserAuthContext = useContext(AppUserAuthContext)
  const appUserContext = useContext(AppUserContext)
  const authContext = useContext(AuthContext);
  const clientInfoContext = useContext(ClientInfoContext);
  const clientSettingsContext = useContext(ClientSettingsContext);
  const dataWrapperContext = useContext(DataWrapperContext)
  const eventInfoContext = useContext(EventInfoContext);
  const eventSettingsContext = useContext(EventSettingsContext);
  const homeSettingsContext = useContext(HomeSettingsContext);
  const manifestContext = useContext(ManifestContext);
  const pageContext = useContext(PageContext);
  const papsContext = useContext(PapsContext);
  const previewContext = useContext(PreviewContext)
  const questionsAndAnswersContext = useContext(QuestionsAndAnswersContext);
  const ratingContext = useContext(RatingContext);
  const searchParamContext = useContext(SearchParamContext);
  const serviceWorkerContext = useContext(ServiceWorkerContext);
  const startContext = useContext(StartContext);
  const startDataContext = useContext(StartDataContext);
  const storageContext = useContext(StorageContext);
  const surveyContext = useContext(SurveyContext);
  const transitionContext = useContext(TransitionContext)
  const validationContext = useContext(ValidationContext)
  const videoConferenceContext = useContext(VideoConferenceContext);

  const contexts = {
    appAccessContext,
    appDataContext,
    appFormsContext,
    appNotificationsContext,
    appSettingsContext,
    appUserAuthContext,
    appUserContext,
    authContext,
    clientInfoContext,
    clientSettingsContext,
    dataWrapperContext,
    eventInfoContext,
    eventSettingsContext,
    homeSettingsContext,
    manifestContext,
    pageContext,
    papsContext,
    previewContext,
    questionsAndAnswersContext,
    ratingContext,
    searchParamContext,
    serviceWorkerContext,
    startContext,
    startDataContext,
    storageContext,
    surveyContext,
    transitionContext,
    validationContext,
    videoConferenceContext
    // appUsersContext,
  }

  const { navigate } = papsContext ?? {}

  const parent_contexts = separateContexts(contexts)

  const { states, settings } = parent_contexts
  const { eventSettings, clientSettings, homeSettings } = settings ?? {}

  const { global: global_h } = homeSettings ?? {}
  const { global: global_c } = clientSettings ?? {}
  const { global: global_e } = eventSettings ?? {}

  if (eventSettings && eventSettings.global && eventSettings.global.parentDefaults) {
    const { useClientThemes, useClientUiElements, useClientUiSections } = global_e.parentDefaults
    if (useClientThemes) {
      if (global_c && global_c.themeColors) { global_e.themeColors = global_c.themeColors }
      if (global_c && global_c.themedItems) { global_e.themedItems = global_c.themedItems }
    }
    if (useClientUiElements) {
      if (global_c && global_h.uiElements) { global_e.uiElements = global_c.uiElements }
    }
    if (useClientUiSections) {
      if (global_c && global_c.uiSections) { global_e.uiSections = global_c.uiSections }
    }
  }

  const [parent_contextz, setParent_contextz] = useState()

  const validateApp = () => {
    const { page_state, manifest_state } = states
    const { pageSettings } = page_state ?? {}
    const { aps_global, aps_appUserSettings } = pageSettings ?? {}
    const { manifest, topMenu } = aps_global ?? {}
    const { appUserCollection } = aps_appUserSettings ?? {}
    const { startIcon, manifestIcons } = manifest_state ?? {}
    const { mainFixedCaption } = topMenu ?? {}
    const { appleStartUpImage, appleTouchIcon72, appleTouchIcon120, appleTouchIcon152, name: name_manifest, shortName } = manifest ?? {}

    const appUserOk = appUserCollection ? true : false
    const menuOk = mainFixedCaption ? true : false
    const manifestOk = manifestIcons && Object.keys(manifestIcons).length === 8 && startIcon ? true : false
    const manifestIOk = (appleStartUpImage && appleTouchIcon72 && appleTouchIcon120 && appleTouchIcon152 && name_manifest && shortName) ? true : false

    if (appUserOk && menuOk && manifestOk && manifestIOk) {
      return true
    }
  }

  const parent_fns = {
    addToContexts: (c) => {
      let { handlers } = parent_contexts
      handlers = { ...handlers, ...c }
      setParent_contextz(parent_contexts)
    }
  }

  return <ParentContext.Provider value={{ ...props, validApp: validateApp(), ...parent_contexts, navigate, parent_fns }}>
    {props.children}
  </ParentContext.Provider>
};

export default ParentProvider