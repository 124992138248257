import _, { over } from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { allPageTypes } from '../enums/allPageTypes';
import { validateUiActionAccess } from './actionAccessPermissions';
import { _currentLocalHostAppName, _projectAppNames } from '../../project/appConfiguration';
import { combineObjectsWithSelectedProps } from '../common/convert';
import { _matchAccessLevels } from '../../projectSpecific/sports/cnr/reducers/SportsMatchReducer';
import { menuItemItems, menuItemItems_show } from '../viewSettings/components/menus/menuItemHelpers';

const publicViews = [allPageTypes.signIn, allPageTypes.videoConference, allPageTypes.signUp, allPageTypes.appProfile, allPageTypes.appUserInfo, allPageTypes.help]

const accessAreaTypes = {
  page: 'page',
  pageItem: 'pageItem',
  propSection: 'propSection',
  prop: 'props'
}

export const getAppUser = (appUsers, appUser) => {
  if (appUsers) {
    const { current, cloned } = appUsers
    if (cloned) {
      return cloned
    } else {
      return current
    }
  } else {
    return appUser
  }
}

/**
 * 
 * @param {object} appUser 
 * @returns appUserAccess OR appUserAccess_cloned
 */
export const getAppUserAccess = (appUsers, appUser) => {
  if (appUsers) {
    const { current, cloned } = appUsers
    if (cloned) {
      const { appUserAccess } = cloned ?? {}
      return appUserAccess
    } else {
      const { appUserAccess } = current ?? {}
      return appUserAccess
    }
  } else {
    const { appUserAccess } = appUser ?? {}
    return appUserAccess
  }
}

/**
 * 
 * @param {object} appUserAccess 
 * @param {string} appUserCollection 
 * @returns accessPermission functions for the current appUser
 */
export const appUserAccessPermissions = (appUsers, appUser, appUserCollection, eventAppUsers) => {

  const appUserAccess = getAppUserAccess(appUsers)
  const { isAdminOrSuper, appUserSessionKey, loggedIn } = appUserAccess ?? {}

  /**
 * Returns true if the appUser's accessLevel is >= the itemAppUserLevel
 * @param {number} itemAppUserLevel 
 * @param {boolean} meOnly 
 * @param {array} userArray 
 * @returns allow (boolean) 
 */
  const validateAccess_item = (itemAppUserLevel, meOnly, userArray) => {
    const { accessLevel, uid, email, phoneNumber } = appUserAccess ?? {}
    if (meOnly) {
      return uid === 'isOUmqKoJGOrd6ncgWMxjClA1Cd2'
    } else {
      let { value } = itemAppUserLevel ?? {}
      if (!value && _.isNumber(itemAppUserLevel)) { value = itemAppUserLevel }
      let allowed = accessLevel >= value
      if (!allowed && userArray && (email || phoneNumber)) {
        if (email && userArray.includes(email)) { allowed = true }
        if (phoneNumber && userArray.includes(phoneNumber)) { allowed = true }
      }
      return allowed
    }
  }

  const validateAccess_items = (items) => {

    const { accessLevel: accessLevel_appUser, appUserSession } = appUserAccess ?? {}

    if (items) {
      Object.keys(items).forEach(k => {
        const item = items[k]

        if (!item._viewPermissionType) { item._viewPermissionType = gEnums.viewPermissionTypes.allow }

        const { appUserPagePermissions, dataSource, dataConstraints } = item ?? {}

        const _dataConstraints = dataConstraints ? dataConstraints : dataSource
        const { accessLevel: accessLevel_item } = appUserPagePermissions ?? {}
        const { useAppUserDataConstraints, appUserDataConstraintItems } = _dataConstraints ?? {}

        if (accessLevel_item) {
          if ((accessLevel_item > accessLevel_appUser || !accessLevel_appUser)) {
            item._viewPermissionType = isAdminOrSuper ? gEnums.viewPermissionTypes.allowToAppUserAdmin : gEnums.viewPermissionTypes.deny
          }
        }

        if (appUserSession && useAppUserDataConstraints && appUserDataConstraintItems && appUserDataConstraintItems.length > 0) {
          appUserDataConstraintItems.forEach(audci => {
            const { dataPropName, propValue } = audci
            const appUserSessionValue = appUserSession[dataPropName]
            if (appUserSessionValue && propValue) {
              if (!appUserSessionValue.includes(propValue)) {
                // item._viewPermissionType = isAdminOrSuper ? gEnums.viewPermissionTypes.allowToAppUserAdmin : gEnums.viewPermissionTypes.deny
              }
            }
          })
        }
      })
      // }
    }

  }

  /**
   * Returns true if the appUser has the event in listed in their profile AND they are on the page that is theirs
   * @param {object} pathViews 
   * @returns allow (boolean)
   */
  const validateAccess_eventUserPage = (pathViews) => {
    const allow = validateAccess_event(pathViews)
    if (allow) {
      return pathViews[appUserCollection] === appUserSessionKey
    }
    return false
  }

  /**
 * Returns true if the appUser has the event in listed in their profile
 * @param {object} pathViews 
 * @returns allow (boolean)
 */
  const validateAccess_appUser = (props) => {
    const { pathViews, appUserCollection, view, viewKey, appUserAccess } = props
    const { events: eventKey, clients: clientKey } = pathViews ?? {}
    const { appUserSessionKey } = appUserAccess ?? {}
    if (clientKey && eventKey && appUserCollection === view && appUserSessionKey === viewKey) {
      return true
    }
    return false
  }

  /**
   * Returns true if the appUser has the event in listed in their profile
   * @param {object} pathViews 
   * @returns allow (boolean)
   */
  const validateAccess_event = (pathViews) => {
    const { events: eventKey, clients: clientKey } = pathViews ?? {}
    const { clients: clients_appUser, events: events_appUser } = appUserAccess ?? {}
    let allow = events_appUser && events_appUser[eventKey] ? true : false
    if (!allow) {
      allow = clients_appUser && clients_appUser[clientKey] && clients_appUser[clientKey].events && clients_appUser[clientKey].events[eventKey] ? true : false
    }
    // if (!allow) { allow = isSuperAdmin } 
    return allow
  }

  const validateAccess_action = (validationType, viPermissions, view, viewKey, viewItemKey) => {

    const { accessLevel } = appUserAccess ?? {}
    let { allowAdd, allowEdit } = viPermissions ?? {}

    if (loggedIn) {
      switch (validationType) {
        case 'adminMenuItems':
        case 'adminDDItems':
          if (accessLevel >= gEnums.accessLevels.admin.value) {
            return true
          }
          break;

        case 'allowAdd':
          if (accessLevel >= gEnums.accessLevels.admin.value) {
            if (!viewKey && allowAdd && (accessLevel >= allowAdd)) {
              return true
            }
          }
          break;
        case 'allowEdit':
          if (accessLevel >= gEnums.accessLevels.admin.value) {
            if (viewItemKey === view && allowEdit && (accessLevel >= allowEdit)) {
              return true
            }
          }
          break;
        default:
        // nothing
      }
    }

    return false

  }

  const validateAccess_rating = (currentRating, appRating, key_viewItem) => {
    const { appUserRatings, ratingActive } = currentRating ?? {}
    const { accessLevel, appUserSessionKey, uid } = appUserAccess ?? {}
    const { allowRating, ratingCollections, allowGlobalRating } = appRating ?? {}
    const _allow = allowRating && (ratingActive || allowGlobalRating)
    if (_allow && ratingCollections) {
      const allow = ratingCollections.includes(key_viewItem)
      if (allow) {
        const _appUserKey = appUserSessionKey ? appUserSessionKey : uid
        const appUserRating = appUserRatings ? appUserRatings[_appUserKey] : '-'
        const x = combineObjectsWithSelectedProps(currentRating, appRating, ['ratingRateAuthLevel', 'ratingViewAuthLevel'], true)
        const { ratingRateAuthLevel, ratingViewAuthLevel } = x ?? {}
        return {
          allow: _allow,
          allowAppUserRate: _allow && ratingRateAuthLevel && (accessLevel >= ratingRateAuthLevel) ? true : false,
          allowAppUserView: _allow && ratingViewAuthLevel && (accessLevel >= ratingViewAuthLevel) ? true : false,
          appUserRating,
        }
      }
    }
    return {
      allow: false,
      allowAppUserRate: false,
      allowAppUserView: false,
      appUserRating: '-',
    }
  }

  const validateAccess_developer = () => {
    const { isSuperAdminDeveloper } = appUserAccess ?? {}
    return isSuperAdminDeveloper
  }

  const validateAccess_adminOrSuper = () => {
    const { isAdminOrSuper } = appUserAccess ?? {}
    return isAdminOrSuper
  }

  const validateAccess_app = (appStatus) => {
    const { eventStatusType, restrictedAuthLevel } = appStatus
    switch (eventStatusType) {
      case gEnums.eventStatusTypes.restricted:
        const accessLevel = get_accessLevel()
        if (accessLevel >= restrictedAuthLevel) {
          return gEnums.eventStatusTypes.active
        } else {
          return gEnums.eventStatusTypes.restricted
        }
      default:
        return gEnums.eventStatusTypes.active
    }
  }

  /**
   * 
   * @param {object} aps_page 
   * @param {object} paps_state 
   * @param {object} aps_global 
   * @param {object} aps_appUserSettings 
   * @returns page access for the appUser
   */
  const validateAccess_pageView = (paps_state, aps_page, aps_global, aps_viewItems, aps_appUserSettings, _appUserAccess) => {

    const { viewKey, otherView, pathViews } = paps_state ?? {}
    const { viewItems: viewItems_page } = aps_page ?? {}
    const { appConversations, appNotifications, appNotes, appAccess } = aps_global ?? {}
    const { allowTempAuthEmail, appAccessType } = appAccess ?? {}
    const { allowConversations } = appConversations ?? {}
    const { allowNotifications } = appNotifications ?? {}
    const { allowNotes } = appNotes ?? {}

    const a = gEnums.viewPermissionTypes.allow
    const d = gEnums.viewPermissionTypes.deny

    const _appUser_access = _appUserAccess ? _appUserAccess : appUserAccess

    const isPublicSite = allowPublicAccess(appAccessType)

    let allowPageView = isPublicSite ? a : d

    if (publicViews.includes(otherView)) {
      return { allow: allowPageView, viewItems_page }
    } else {
      // first check to see if the appUserAccess is logged in
      //  
      if (isPublicSite || (_appUser_access && _appUser_access.loggedIn)) {

        allowPageView = a

        if (viewItems_page) {
          // check the view's auth 
          allowPageView = isPublicSite ? a : validateAccess(accessAreaTypes.page, _appUser_access, pathViews, aps_page, appAccessType, viewKey, aps_appUserSettings)

          if (allowPageView === a) {

            _.each(viewItems_page, (viewItem_page, vipKey) => {

              let aps_viewItem;

              if (aps_viewItems && aps_viewItems[vipKey]) {
                aps_viewItem = aps_viewItems[vipKey]
              }

              if (_.isObject(viewItem_page)) {
                viewItem_page._viewPermissionType = allowPageView ? a : d

                // check the viewItem_page's auth  
                viewItem_page._viewPermissionType = isPublicSite ? a : validateAccess(accessAreaTypes.pageItem, _appUser_access, pathViews, viewItem_page, appAccessType, viewKey, aps_appUserSettings, aps_viewItem)

                switch (viewItem_page.key) {
                  case allPageTypes.conversations:
                    if (!allowConversations) { viewItem_page._viewPermissionType = d }
                    break;
                  case allPageTypes.notifications:
                    if (!allowNotifications) { viewItem_page._viewPermissionType = d }
                    break;
                  case allPageTypes.notes:
                    if (!allowNotes) { viewItem_page._viewPermissionType = d }
                    break;
                  default:
                  // nothing
                }

              }
            })
          }

          if (publicViews.includes(otherView)) {
            // nothing
            // return { allow: allowPageView, viewItems_page }
          } else {
            switch (allowPageView) {
              case gEnums.viewPermissionTypes.allow:
              case gEnums.viewPermissionTypes.allowToAppUserAdmin:
                break;
              default:
                if (allowTempAuthEmail) {
                  allowPageView = gEnums.viewPermissionTypes.allowTemp
                }
            }
          }
        }
      }
    }

    switch (allowPageView) {
      case gEnums.viewPermissionTypes.allow:
      case gEnums.viewPermissionTypes.allowToAppUserAdmin:
        break;
      default:
        if (allowTempAuthEmail) {
          allowPageView = gEnums.viewPermissionTypes.allowTemp
        }
    }

    return { allow: allowPageView, vip: viewItems_page }
  }

  const validateAccess_sections = (pathViews, propSections, drillDown, displayContext, display_viewItem, appAccessType, viewKey, propOverrideOn) => {

    if (propOverrideOn) {
      _.each(propSections, (propSection) => {
        const overrides = {}
        _.each(menuItemItems_show, (mii) => {
          overrides[mii] = propSection[mii]
        })
        propSection._overrides = overrides
      })
    }

    _.each(propSections, (propSection, propSectionKey) => {
      const ass = validateAccess_section(pathViews, propSectionKey, propSection, drillDown, displayContext, display_viewItem, appAccessType, viewKey)
      switch (propSectionKey) {
        case 'hidden':
          propSection._allowSection = gEnums.viewPermissionTypes.deny
          break;
        default:
          propSection._allowSection = ass
      }
    })

  }

  /** 
  * @param {object} appUser 
  * @param {object} pathViews 
  * @param {string} propSection 
  * @param {object} drillDown 
  * @param {object} displayContext 
  * @param {object} display_viewItem 
  * @param {boolean} appAccessType 
  * @param {string} viewKey 
  * @returns section access for the appUser
  */


  const validateAccess_section = (pathViews, propSectionKey, propSection, drillDown, displayContext, display_viewItem, appAccessType, viewKey) => {

    const { displayType, cardDisplayType } = display_viewItem ?? {}

    const { display_state } = displayContext ?? {}
    const { ddGroupIndex, ddGroupKey, ddProps } = display_state ?? {}

    const isPublicSite = allowPublicAccess(appAccessType)

    let allowSection = isPublicSite ? gEnums.viewPermissionTypes.allow : validateAccess(accessAreaTypes.propSection, appUserAccess, pathViews, propSection, appAccessType, viewKey)

    const { useDrillDown, section1: ddSection1, drillDownType } = drillDown ?? {}

    if (propSectionKey !== 'name' && cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
      return gEnums.viewPermissionTypes.deny
    }

    if (allowSection === gEnums.viewPermissionTypes.allow) {

      if (useDrillDown && drillDownType === gEnums.drillDownTypes.sections && ddSection1) {
        let ddg = 'section1'
        if (ddGroupIndex) { ddg = 'section' + ddGroupIndex }
        if (!drillDown[ddg].includes(propSectionKey)) {
          return gEnums.viewPermissionTypes.deny
        }
      }

      if (ddGroupKey) {
        if (propSectionKey !== ddGroupKey) {
          return gEnums.viewPermissionTypes.deny
        }
      }

      if (ddProps) {
        if (!ddProps[propSectionKey]) {
          return gEnums.viewPermissionTypes.deny
        }
      }

      switch (displayType) {
        case gEnums.displayTypes.card:
          if (cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
            switch (propSectionKey) {
              case 'name':
                // nothing
                // return gEnums.viewPermissionTypes.deny
                break;
              default:
                return gEnums.viewPermissionTypes.deny
            }
            // if (propSectionKey !== 'name') { return gEnums.viewPermissionTypes.deny }
          }
          break;
        default:
        // nothing
      }
    }

    return allowSection

  }

  /** 
  * @param {string} appAccessType 
  * @param {object} pathViews 
  * @param {object} props_viewItem 
  * @param {object} display 
  * @param {string} viewKey 
  * @returns prop access for the appUser
  */
  const validateAccess_props = (appAccessType, pathViews, props_viewItem, display, viewKey, propOverrideOn, _propsToggled) => {

    const { cardDisplayType, taggedPropsOnly } = display ?? {}

    if (propOverrideOn) {
      _.each(props_viewItem, (prop, propKey) => {
        const overrides = {}
        _.each(menuItemItems, (mii, key) => {
          overrides[mii] = prop[mii]
        })
        prop._overrides = overrides
      })
    }

    if (props_viewItem) {

      _.each(props_viewItem, (_prop, key) => {
        try {
          _prop._allowProp = allowPublicAccess(appAccessType) ? gEnums.viewPermissionTypes.allow : validateAccess(accessAreaTypes.prop, appUserAccess, pathViews, _prop, appAccessType, viewKey)

          if (!_prop.show) {
            if (key.startsWith('_')) {
              _prop._allowProp = gEnums.viewPermissionTypes.deny
            } else {
              _prop._allowProp = propOverrideOn ? gEnums.viewPermissionTypes.allowOverride : gEnums.viewPermissionTypes.deny
            }
          }

          if (propOverrideOn && _propsToggled && _propsToggled[key]) {
            const propToggled = _propsToggled[key]
            const { _show } = propToggled ?? {}
            _prop._allowProp = _show ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.allowOverride
            _.each(propToggled, (pt, key) => {
              _prop[key] = pt
            })
          }

          switch (cardDisplayType) {
            case gEnums.cardDisplayTypes.profile:
            case gEnums.cardDisplayTypes.schedule:
              _prop._allowProp = _prop.tagged ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.deny
              break;
            default:
              if (taggedPropsOnly) {
                if (!_prop.tagged) {
                  _prop._allowProp = allowPublicAccess ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.deny
                } else {
                  _prop._allowProp = gEnums.viewPermissionTypes.allow
                }
              }
          }
        } catch (error) {

        }
      })
    }
  }

  const validateAccess_sidebar = (appAccessType) => {
    const { loggedIn } = appUserAccess ?? {}
    let allow = allowPublicAccess(appAccessType) ? gEnums.viewPermissionTypes.allow : gEnums.viewPermissionTypes.deny
    if (loggedIn) { allow = gEnums.viewPermissionTypes.allow }
    return allow
  }

  const validate_settingsAuth = (appUserAccess, forPageArea) => {
    const { accessLevel } = appUserAccess ?? {}
    if (accessLevel >= gEnums.authLevels.appSubAdmin.value) { return gEnums.viewPermissionTypes.allow }
    if (forPageArea && accessLevel >= gEnums.authLevels.admin.value) { return gEnums.viewPermissionTypes.allow }
    return gEnums.viewPermissionTypes.deny
  }

  const validate_dataAuth = () => {
    const { accessLevel } = appUserAccess ?? {}
    if (accessLevel >= gEnums.authLevels.appDataAdmin.value) { return gEnums.viewPermissionTypes.allow }
    return gEnums.viewPermissionTypes.deny
  }

  const validate_trueSettingsAuth = (appUserAccess, forPageArea) => {
    const { accessLevel } = appUserAccess ?? {}
    if (accessLevel >= gEnums.authLevels.appSubAdmin.value) { return gEnums.viewPermissionTypes.allow }
    if (forPageArea && accessLevel >= gEnums.authLevels.admin.value) { return gEnums.viewPermissionTypes.allow }
    return gEnums.viewPermissionTypes.deny
  }

  const validate_settingsMenuAuth = (fromSidebar) => {
    const { accessLevel } = appUserAccess ?? {}
    if (fromSidebar) {
      if (accessLevel >= gEnums.authLevels.admin.value) { return gEnums.viewPermissionTypes.allow }
    } else {
      if (accessLevel >= gEnums.authLevels.appSubAdmin.value) { return gEnums.viewPermissionTypes.allow }
    }
    return gEnums.viewPermissionTypes.deny
  }

  const get_accessLevel = () => {
    const { accessLevel } = appUserAccess ?? {}
    return accessLevel
  }

  const get_authColor = () => {
    const { accessLevel } = appUserAccess ?? {}
    return accessLevel
  }

  const get_profileData = () => {
    const _appUser = getAppUser(appUsers, appUser)
    const { profileData } = _appUser ?? {}
    return profileData
  }

  const validateAllowScoring = (props) => {

    const { sportsKey, match_true, accessClone } = props ?? {}
    const { teams } = match_true ?? {}
    const { eventAccess, isSuperAdminDeveloper } = appUserAccess ?? {}
    const { schoolsAccess, sportsAccess, teamsAccess } = eventAccess ?? {}

    const accessKeys = {
      team: teamsAccess ? Object.keys(teamsAccess) : [],
      sport: sportsAccess ? Object.keys(sportsAccess) : [],
      school: schoolsAccess ? Object.keys(schoolsAccess) : [],
    }

    const access = {
      team: _.intersection(accessKeys.team, teams).length > 0,
      sport: accessKeys.sport.includes(sportsKey),
    }

    let matchAccessLevel;

    if (access.sport) {
      matchAccessLevel = _matchAccessLevels.sport
    } else if (access.team) {
      matchAccessLevel = _matchAccessLevels.team
    }

    const _accessClone = !_.isEmpty(accessClone) ? accessClone : null

    if (!_accessClone && isSuperAdminDeveloper) {
      return { scores: true, edit: true, full: true, matchAccessLevel: _matchAccessLevels.admin }
    } else {
      if (access.team || access.sport) {
        return { scores: true, matchAccessLevel }
      }
    }
    return { scores: false }
  }

  /**
   * Called from (validateAccess_pageView, validateAccess_section, validateAccess_props)
   * @param {*} accessAreaTypes 
   * @param {*} appUserAccess 
   * @param {*} pathViews 
   * @param {*} auth_setting 
   * @param {*} appAccessType 
   * @param {*} viewKey 
   * @param {*} aps_appUserSettings 
   * @returns 
   */
  const validateAccess = (accessAreaTypes, appUserAccess, pathViews, auth_setting, appAccessType, viewKey, aps_appUserSettings, aps_viewItem) => {

    const { pageItemAccessManagement, pageAccessManagement, propAccessManagement, propSectionAccessManagement } = auth_setting ?? {}

    const accessManagement = pageItemAccessManagement || pageAccessManagement || propAccessManagement || propSectionAccessManagement

    const { accessLevel: accessLevel_appUser, clientAccessKey, appUserAccessType, appUserSessionKey, email, phoneNumber, appUserViewTypeValues } = appUserAccess ?? {}
    // console.log('appUserAccess', appUserAccess)

    const { clients: clientKey } = pathViews ?? {}

    const { appUserCollection, allowConnections, connectWith } = aps_appUserSettings ?? {}

    // the auth for the authAreaType
    // eslint-disable-next-line
    const { publicView, accessLevel, restrictToAppUserOnly, allowAccessUsers, accessUsers } = accessManagement ?? {}
    const isPublicSite = allowPublicAccess(appAccessType)

    // if publicView or isPublicSite, allow
    switch (accessAreaTypes) {
      case accessAreaTypes.page:
      case accessAreaTypes.pageItem:
        if (publicView || isPublicSite) { return gEnums.viewPermissionTypes.allow }
        break;
      default:
      // nothing
    }
    switch (appUserAccessType) {
      // this are ME
      case gEnums.appUserAccessTypes.admin:
      case gEnums.appUserAccessTypes.superAdmin:
        break;

      // if a appAdmin or appProfile, validate the path
      case gEnums.appUserAccessTypes.appDataAdmin:
      case gEnums.appUserAccessTypes.appDataAdminLimited:
      case gEnums.appUserAccessTypes.appAdmin:
      case gEnums.appUserAccessTypes.appSubAdmin:
        if (clientKey !== clientAccessKey) {
          return gEnums.viewPermissionTypes.deny_noClientKey
        }
        break;

      case gEnums.appUserAccessTypes.appProfile:
        if (clientKey) {
          if (clientKey !== clientAccessKey) {
            return gEnums.viewPermissionTypes.deny_noClientKey
          }
          if (validateAccess_event(pathViews)) {
            // if (validateAppAccess_event(appUserAccess, pathViews)) {
            // do nothing
          } else {
            return gEnums.viewPermissionTypes.allow_clientLimited
          }
        } else {
          return gEnums.viewPermissionTypes.allow_clientLimited
        }
        break;

      case gEnums.appUserAccessTypes.appUser:
        if (validateAccess_event(pathViews)) {
          // if (validateAppAccess_event(appUserAccess, pathViews)) {
          // do nothing
        } else {
          return gEnums.viewPermissionTypes.allow_clientLimited
          // return gEnums.viewPermissionTypes.deny_noEventKey
        }
        break;

      default:
        return gEnums.viewPermissionTypes.deny
    }

    // if any below fail, return deny
    if (accessLevel) {
      if (accessLevel_appUser < accessLevel) {
        if (allowAccessUsers && accessUsers && (email || phoneNumber)) {
          if (email && accessUsers.includes(email)) { return gEnums.viewPermissionTypes.allow }
          if (phoneNumber && accessUsers.includes(phoneNumber)) { return gEnums.viewPermissionTypes.allow }
        }
        return gEnums.viewPermissionTypes.deny
      }
    }

    if (restrictToAppUserOnly) {
      if (appUserSessionKey === viewKey) {
        return gEnums.viewPermissionTypes.allow
      } else {
        // allow if admin
        if (accessLevel_appUser >= gEnums.accessLevels.appAdmin.value) {
          return gEnums.viewPermissionTypes.allowToAppUserAdmin
        } else {
          if (allowConnections && connectWith && connectWith.includes(appUserCollection)) {
            return gEnums.viewPermissionTypes.allow
          } else {
            return gEnums.viewPermissionTypes.deny
          }
        }
      }
    }

    switch (appUserAccessType) {
      case gEnums.appUserAccessTypes.appDataAdminLimited:
      case gEnums.appUserAccessTypes.appProfile:
      case gEnums.appUserAccessTypes.appUser:
        if (appUserViewTypeValues) {
          const { pageItemAccessManagement: pageItemAccessManagement_pvi } = aps_viewItem ?? {}
          const { appUserViewTypes, denyAppUserViewTypes } = pageItemAccessManagement_pvi ?? {}
          if (!_.isEmpty(appUserViewTypes)) {
            const commonKeys = _.intersection(appUserViewTypes, appUserViewTypeValues);
            if (commonKeys.length === 0) {
              console.log('allow', aps_viewItem)
              return gEnums.viewPermissionTypes.deny
            }
          }
          if (!_.isEmpty(denyAppUserViewTypes)) {
            const commonKeys = _.intersection(denyAppUserViewTypes, appUserViewTypeValues);
            if (commonKeys.length > 0) {
              console.log('deny', aps_viewItem)
              return gEnums.viewPermissionTypes.deny
            }
          }
        }
        break;
      default:
      // nothing
    }

    return gEnums.viewPermissionTypes.allow

  }

  const allowPublicAccess = (appAccessType) => {

    let _isPublicSite = false

    switch (_currentLocalHostAppName) {
      case _projectAppNames.thumbstat:
      case _projectAppNames.pojo:
      case _projectAppNames.fcasd_ft:
        _isPublicSite = true
        break;
      default:
        switch (appAccessType) {
          case gEnums.siteAuthorizationTypes.public:
          case gEnums.siteAuthorizationTypes.passcode:
            _isPublicSite = true
            break;
          default:
          // nothing
        }
    }

    return _isPublicSite

  }

  return {
    get_accessLevel: () => get_accessLevel(),
    get_authColor: () => get_authColor(),
    get_profileData: () => get_profileData(),
    validate_dataAuth: () => validate_dataAuth(),
    validate_settingsAuth: (appUserAccess, forPageArea) => validate_settingsAuth(appUserAccess, forPageArea),
    validate_settingsMenuAuth: (fromSidebar) => validate_settingsMenuAuth(fromSidebar),
    validate_trueSettingsAuth: (appUserAccess, forPageArea) => validate_trueSettingsAuth(appUserAccess, forPageArea),
    validateAccess_action: (validationType, viPermissions, view, viewKey, viewItemKey) => validateAccess_action(validationType, viPermissions, view, viewKey, viewItemKey),
    validateAccess_adminOrSuper: () => validateAccess_adminOrSuper(),
    validateAccess_allowScoring: (props) => validateAllowScoring({ ...props, appUserAccess }),
    validateAccess_app: (appStatus) => validateAccess_app(appStatus),
    validateAccess_appUser: (pathViews) => validateAccess_appUser(pathViews),
    validateAccess_developer: () => validateAccess_developer(),
    validateAccess_event: (pathViews) => validateAccess_event(pathViews),
    validateAccess_eventUserPage: (pathViews) => validateAccess_eventUserPage(pathViews),
    validateAccess_item: (itemAppUserLevel, meOnly, userArray) => validateAccess_item(itemAppUserLevel, meOnly, userArray),
    validateAccess_items: (itemAppUserLevel, meOnly, userArray) => validateAccess_items(itemAppUserLevel, meOnly, userArray),
    validateAccess_pageView: (paps_state, aps_page, aps_global, aps_viewItems, aps_appUserSettings, appUserAccess) => validateAccess_pageView(paps_state, aps_page, aps_global, aps_viewItems, aps_appUserSettings, appUserAccess),
    validateAccess_props: (appAccessType, pathViews, props_viewItem, display, viewKey, propOverrideOn, _propsToggled) => validateAccess_props(appAccessType, pathViews, props_viewItem, display, viewKey, propOverrideOn, _propsToggled),
    validateAccess_rating: (currentRating, appRating, key_viewItem) => validateAccess_rating(currentRating, appRating, key_viewItem),
    validateAccess_section: (pathViews, propSectionKey, propSection, drillDown, displayContext, display_viewItem, appAccessType, viewKey) => validateAccess_section(pathViews, propSectionKey, propSection, drillDown, displayContext, display_viewItem, appAccessType, viewKey),
    validateAccess_sections: (pathViews, propSection, drillDown, displayContext, display_viewItem, appAccessType, viewKey, propOverrideOn, _propsToggled) => validateAccess_sections(pathViews, propSection, drillDown, displayContext, display_viewItem, appAccessType, viewKey, propOverrideOn, _propsToggled),
    validateAccess_sidebar: (appAccessType) => validateAccess_sidebar(appAccessType),
    validateAccess_uiAction: (props) => validateUiActionAccess({ ...props, appUserAccess, eventAppUsers }),
  }
}