import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'semantic-ui-react';
import DragNDropProvider, { DragNDropContext } from '../cnr/contexts/DragNDropContext';
import { uniqueKey } from '../common/keys';
import { createNameItemList } from '../optionLists/options';
import DragContext from './DragContext';
import { DropGroupWrapper, DroppableWrapper } from './DragDropWrappers';

export const dragDropperTypes = {
  label: 'label',
  menuItemSelect: 'menuItemSelect',
  playerList: 'playerList',
  settingsGroups: 'settingsGroups',
  simple: 'simple',
  viewSettingMenu: 'viewSettingMenu',
}

/** Container for dragging and dropping
 @param dndGroups (dataItems and elements)
 */
const DragDropperSimple = (props) => {

  const {
    ddType,
    dndGroups,
    dndType,
    display_propItem,
    handleSelected,
    handleSorted,
    headerColor,
    noStartCase,
    positionProp,
    selectionItems,
  } = props

  const { sortFixedCount, sortGroupName } = display_propItem ?? {}

  const _positionProp = positionProp ? positionProp : 'position'

  // dragNDropContext
  const dragNDropContext = useContext(DragNDropContext);
  const { dragNDrop_state, dragNDrop_handlers } = dragNDropContext ?? {}
  const { dndProps } = dragNDrop_state ?? {}
  const { dest_props } = dndProps ?? {}

  const groupCount = dndGroups ? Object.keys(dndGroups).length : 0

  const [portal, setPortal] = useState()

  let showHeader = true

  switch (dndType) {
    case dragDropperTypes.settingsGroups:
      showHeader = true
      break;
    default:
    // nothing
  }

  useEffect(() => {
    dragNDrop_handlers.handleSet_dndGroups(dndGroups)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dndGroups])

  useEffect(() => {
    if (dest_props) {
      if (ddType) {
        dndGroups[ddType] = dest_props.dndGroup
      }
      console.log('dndGroups', dndGroups)
      handleSorted(dndGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dest_props])

  useEffect(() => {
    const p = document.createElement('div');
    p.classList.add('ddp');
    dndType && p.classList.add(dndType);
    setPortal(p)
    return () => {
      // document.body.removeChild(portal);
      // Anything in here is fired on component unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const handleChange = (e, data) => handleSelected(e, data)
  const select = (ukh, groupKey) => selectionItems ? <Select key={uniqueKey(ukh, 's', groupKey)} groupkey={groupKey} scrolling placeholder='Select' options={createNameItemList(selectionItems)} onChange={handleChange} /> : null

  const header = (ukh, index, groupKey, group) => {

    const { menuGroup } = group ? group : {}
    const { menu_header } = menuGroup ? menuGroup : {}

    const { hdd } = group ? group : {}
    const c = sortGroupName ? groupKey.replace('group', sortGroupName) : groupKey
    return menu_header ? menu_header : <div key={uniqueKey(ukh, index)} className={'drop-group-header drd'} style={headerColor ? { backgroundColor: headerColor } : {}}>
      <div key={uniqueKey(ukh, 'cc', index)}>{noStartCase ? c : _.startCase(c)} </div>
      <div key={uniqueKey(ukh, 'hdd', index)}> {hdd}</div>
      {select(ukh, groupKey)}
    </div>
  }

  const droppableRow = (index, groupKey, group, uks) => {
    const dgwProps = { ...props, group, groupKey, header: header(uks.ukh, index, groupKey, group), index, portal, showHeader, sortFixedCount, uks }
    return <DroppableWrapper key={uniqueKey('ddr', groupKey)} dgwProps={dgwProps} />
  }

  const content = () => {

    const drps = []
    Object.keys(dndGroups).forEach((groupKey, index) => {

      const uks = {
        uk1: uniqueKey('dd', 'dg', 'd', groupKey),
        uk2: uniqueKey('dd', 'dg', 'dd', groupKey),
        ukh: uniqueKey('dd', 'dr', 'h'),
      }

      const group = dndGroups[groupKey]

      if (!group) {
        console.log('groupKey', groupKey, dndGroups)
      }

      if (groupCount > 1) {
        if (group.horizontal) {
          drps.push(droppableRow(index, groupKey, group, uks))
        } else {
          const dgwProps = { ...props, group, groupKey, header: header(uks.ukh, index, groupKey, group), index, portal, showHeader, sortFixedCount, uks }
          drps.push(<DropGroupWrapper key={uniqueKey('ddw', groupKey)} dgwProps={dgwProps} />)
        }
      } else {
        drps.push(droppableRow(index, groupKey, group, uks))
      }
    })
    return drps
  }

  if (portal) {
    document.body.appendChild(portal);
    return <DragContext
      groupKey={dndType}
      content={groupCount > 0 ? content() : <div>No Groups Selected</div>}
    ></DragContext>
  }

  return <div></div>

}

const DragDropper = (props) => {
  return <DragNDropProvider {...props}>
    <DragDropperSimple {...props} />
  </DragNDropProvider>
}

export default DragDropper 