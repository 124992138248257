import { where } from 'firebase/firestore';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Message, Step } from 'semantic-ui-react';
import { AppComponentContext } from '../../../global/cnr/contexts/AppComponentContext';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { UiItemContext } from '../../../global/cnr/contexts/UiItemContext';
import { getGoogleSheetDataValues } from '../../../global/common/convert';
import { uniqueKey } from '../../../global/common/keys';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import TextAreaInput from '../../../global/components/forms/elements/TextAreaInput';
import { getHeaders } from '../../../global/components/headers/getHeaders';
import { appIconTypes } from '../../../global/enums/appIconTypes';
import { gEnums } from '../../../global/enums/globalEnums';
import { createRefPath_event } from '../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../global/firestoreData/appData/fsAppData';
import { fs_update_doc } from '../../../global/firestoreData/appData/fsData';
import { fsfn_sheets } from '../../../global/functions/fbSheetsFuntions';
import QrScan from '../../../global/pageItem/modification/pageItemActions/QrScan';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';

const _labSteps = {
  qrCode: 'qrCode',
  ph: 'ph',
  chlorine: 'chlorine',
  turbidity: 'turbidity',
  note: 'note',
}

const _labFind = {
  qrCode: 'qrCode',
}

const LabSample = (props) => {

  const _asat = gEnums.availableSubActionTypes

  const { subActionType } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states, navigate } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}
  const { pathViews, rootPaths } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { googleSheets } = aps_global ?? {}
  const { googleSheetsId } = googleSheets ?? {}

  const labContext = useContext(AppComponentContext)
  const { lab_state, lab_handlers } = labContext ?? {}
  const { updating, runs, weeklyLabSamples, weeklyLabRuns_grouped, currentYear, currentWeek } = lab_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { itemData } = item_state ?? {}
  const { name, _itemKey } = itemData ?? {}

  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [steps, setSteps] = useState();
  const [step, setStep] = useState(1);
  const [currentItemData, setPoolSample] = useState(itemData);
  const [showScanner, setShowScanner] = useState()
  const [scannedData, setScannedData] = useState()

  const { qrCode, ph, chlorine, turbidity } = currentItemData ?? {}

  useEffect(() => {

    const _steps = []
    let _selectedSteps = {}

    switch (subActionType) {
      case _asat.findLabResult:
        _selectedSteps = _labFind
        break;
      default:
        _selectedSteps = _labSteps
    }

    Object.keys(_selectedSteps).forEach(stepKey => {
      _steps.push(
        {
          key: stepKey,
          title: _.startCase(stepKey),
        })
    })
    setSteps(_steps)
  }, []);

  const ready = qrCode !== '' && ph > 0 && chlorine > 0 && turbidity > 0

  const handleShowScanner = () => setShowScanner(!showScanner)

  const handleScannedData = (data) => {
    if (data && data.text) {
      switch (subActionType) {
        case _asat.findLabResult:
          const cb = (res) => {
            const { _itemKey } = res ?? {}
            if (_itemKey) {
              navigate(rootPaths.events + 'labSamples/' + _itemKey)
            }
          }
          const _refPath = createRefPath_event(pathViews, ['labSamples'])
          const _wheres = [where('qrCode', '==', data.text)]
          fs_get_data({ refPath: _refPath, wheres: _wheres, callback: cb, opts: { returnFirstObject: true } })
          break;
        default:
          handlePoolSample(data.text)
      }

    } else {
      setScannedData(data)
    }
    setShowScanner()
  }

  const handleChange = (e, v) => handlePoolSample(v.value, true)

  const handlePoolSample = (value, isTa) => {
    const _step = steps[step - 1]
    const _poolSample = { ...currentItemData }
    const { key } = _step ?? {}
    _poolSample[key] = value
    setPoolSample(_poolSample)
    !isTa && setStep(step + 1)
  }

  const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);
  const handleSave = () => {
    const cb = async (res) => {
      try {
        const appData = { currentItemData }
        const { headers } = getHeaders(appData)
        const { values, tabName } = getGoogleSheetDataValues(Object.values(appData), headers, _.startCase('labSamples'))
        console.log('value', values)
        fsfn_sheets.updateGoogleSheetValues(googleSheetsId, tabName, values)
      } catch (error) {

      }
      // await fsfn_sheets.updateGoogleSheetValues(googleSheetsId, _.startCase('labSamples') + ' Update', values)
      props.handleClose()
    }
    const _refPath = createRefPath_event(pathViews, ['labSamples', _itemKey])
    fs_update_doc(_refPath, currentItemData, cb)
    console.log('poolSample', _refPath, currentItemData)
  }

  const numericOptions = Array.from({ length: 11 }, (_, index) => ({
    key: index,
    text: index.toFixed(1),
    value: index.toFixed(1),
  }));

  const renderDropdown = (title, value, key, onChange) => <Form.Field key={uniqueKey('ls', 'ff', key)}>
    <label>{title}</label>
    <Dropdown
      placeholder={`Select ${title}`}
      fluid
      selection
      options={numericOptions}
      value={value}
      onChange={(_, { value }) => onChange(value)}
    />
  </Form.Field>

  const renderStep = () => {
    const _step = steps[step - 1]
    const { title, key } = _step ?? {}
    const value = currentItemData[key]
    return <Form key={uniqueKey('ls', key)}>
      {renderDropdown(title, value, key, handlePoolSample)}
    </Form>
  };

  const content = () => {
    const _step = steps[step - 1]
    const { key } = _step ?? {}
    switch (key) {
      case _labSteps.qrCode:
      case _labFind.qrCode:
        return <div>
          <Button onClick={(e) => { handleShowScanner() }}>Scan Code</Button>
          <div>{JSON.stringify(scannedData)}</div>
        </div>
      case _labSteps.note:
        return <TextAreaInput formItemType={gEnums.formItemTypes.textAreaSimple} fip={{ onChange: handleChange, key: 'note', style: { width: '100%' } }} />
      default:
        return renderStep()
    }
  }

  const labSampleWizard = () => <div className='step-vert'>
    <Step.Group fluid vertical>
      {steps.map((s, index) => (
        <Step key={index} active={step === index + 1} completed={step > index + 1} onClick={(e) => { setStep(index + 1) }}>
          <Step.Content>
            <Step.Title>{s.title}</Step.Title>
          </Step.Content>
          <Step.Content>
            {currentItemData[s.key]}
          </Step.Content>
        </Step>
      ))}
    </Step.Group>

    <div>
      {content()}
    </div>

    {dataSubmitted && (
      <Message success>
        <Message.Header>Data submitted successfully!</Message.Header>
      </Message>
    )}
  </div>

  const footer = () => {
    const btns = []
    btns.push({ disabled: step === 1, oc: handleBack, icon: appIconTypes.arrowLeft, caption: 'Back', fl: true })

    if (ready && step === steps.length) {
      btns.push({ oc: handleSave, icon: appIconTypes.save, caption: 'Save', color: 'green' })
    } else {
      btns.push({ disabled: step === steps.length, oc: handleNext, icon: appIconTypes.arrowRight, caption: 'Next' })
    }
    return <UiSaveButtons others={btns} />
  }

  const wrapper = () => <Wrapper
    content={labSampleWizard()}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const fpw = () => <FullPageWrapper
    content={steps && wrapper()}
    topperCaption={'Update Lab Sample'}
    topperCaption2={name}
    handleCancel={props.handleClose}
  ></FullPageWrapper>

  return showScanner ? <QrScan handleCancel={handleShowScanner} handleScannedData={handleScannedData} /> : fpw()

}

export default LabSample