import { formatDistance } from 'date-fns';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Icon, Label } from 'semantic-ui-react';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { uniqueKey } from '../common/keys';
import { appIconTypes } from '../enums/appIconTypes';
import FcmList from '../messages/FcmList';
import { AppUserManagementContext } from '../cnr/contexts/AppUserManagementContext';
import { listHelpers } from '../lists/lists';
import { gEnums } from '../enums/globalEnums';

const AppUserCard = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, eventInfo_state } = states
  const { staticViews } = eventInfo_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { projectOptions } = aps_global ?? {}
  const { showAppUserTimeDifferences } = projectOptions ?? {}

  // appUserManagementContext
  const appUserManagementContext = useContext(AppUserManagementContext)
  const { appUserManagement_state } = appUserManagementContext ?? {}
  const { allowAppUserModification } = appUserManagement_state ?? {}

  const { appUser, index, handleClick, handleGoToEvent, handleSignInAs, viewOnly, uniqueProps } = props
  const { displayName, firstName, lastName, email, _itemKey, events, clients, aboutMe, bio, metadata } = appUser
  const { lastSignInTime, creationTime, lastRefreshTime, found, recent } = metadata ?? {}
  const name = displayName ? displayName : lastName + ', ' + firstName

  const [showFcms, setShowFcms] = useState()
  const [modLevel, setModLevel] = useState()

  useEffect(() => {
    setShowFcms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser]);

  const handleShowFcms = (e) => {
    e.stopPropagation()
    setShowFcms(!showFcms)
  }

  const handleModChange = (e, a) => {
    console.log('a', a)
    setModLevel()
  }

  const eventLabels = (_itemKey, events) => {
    const lbls = []
    if (events) {
      Object.keys(events).forEach(key => {
        const event = events[key]
        const { name } = event ?? {}
        lbls.push(<Label key={uniqueKey('aulel', 'c', key, _itemKey)} onClick={(e) => { !viewOnly && handleGoToEvent(e) }}>
          <Icon name='calendar check outline' color={'green'} />{name ? name : key}
        </Label>)
      })
    }
    return lbls
  }

  const icon = (name, color, oc, itemData, _itemKey, title) => <Icon key={uniqueKey('aul', 'i', name)} name={name} color={color} title={title} onClick={(e) => { !viewOnly && oc && oc(e, itemData, _itemKey) }} />

  const iconn = (caption, name, color, oc, itemData, _itemKey, title) => <Label size='mini' key={uniqueKey('aul', 'i', name)} color={color} title={title} onClick={(e) => { oc && oc(e, itemData, _itemKey) }}>
    <Icon name={name} />
    {caption}
  </Label>

  const signInIcon = (itemData, _itemKey) => {
    return <div style={{ float: 'right' }}>
      {icon('sign in', 'green', handleSignInAs, itemData, _itemKey, 'Sign In As')}
    </div>
  }

  const cardIcons = (itemData, _itemKey) => {
    const { email, fcmTokens, phoneNumber } = itemData ?? {}
    const _hasTokens = fcmTokens && Object.keys(fcmTokens).length > 0
    return [
      icon(appIconTypes.mail, email ? 'blue' : 'grey', null, null, null, 'Email'),
      iconn(fcmTokens ? Object.keys(fcmTokens).length : 0, appIconTypes.pushNotifications, fcmTokens ? 'orange' : 'grey', _hasTokens ? handleShowFcms : null, null, null, 'Push Tokens'),
      icon(appIconTypes.phone, phoneNumber ? 'blue' : 'grey', null, null, null, 'Phone')
    ]
  }

  const clientLabels = (_itemKey, clients) => {
    const lbls = []
    if (clients) {
      Object.keys(clients).forEach(clientKey => {
        const client = clients[clientKey]
        const { events } = client ?? {}
        if (events) {
          Object.keys(events).forEach(eventKey => {
            const event = events[eventKey]
            const { name } = event ?? {}
            lbls.push(<Label key={uniqueKey('aulcl', 'c', _itemKey, eventKey)}>{clientKey}</Label>)
            lbls.push(<Label key={uniqueKey('aulcl', 'e', _itemKey, eventKey)}>{name ? name : eventKey}</Label>)
          })
        }
      })
    }
    return lbls
  }

  const modsContent = () => <Dropdown
    placeholder={_.startCase('Modification')}
    label={'TEST'}
    fluid
    selection
    options={listHelpers.listListO(gEnums.accessLevels)}
    value={modLevel}
    onChange={handleModChange} />

  const metaContent = () => {
    if (found || recent) {
      const _lastSignInTime = lastRefreshTime ? formatDistance(new Date(lastSignInTime), new Date(), { addSuffix: true }) : '?'
      const _creationTime = creationTime ? formatDistance(new Date(creationTime), new Date(), { addSuffix: true }) : '?'
      const _lastRefreshTime = lastRefreshTime ? formatDistance(new Date(lastRefreshTime), new Date(), { addSuffix: true }) : '?'
      return <Card.Content className='app-user-meta'>
        <Card.Meta><Icon color='green' name='refresh' />{showAppUserTimeDifferences ? _lastRefreshTime : lastRefreshTime}</Card.Meta>
        <Card.Meta><Icon color='blue' name='sign-in' />{showAppUserTimeDifferences ? _lastSignInTime : lastSignInTime}</Card.Meta>
        <Card.Meta><Icon color='yellow' name='user' />{showAppUserTimeDifferences ? _creationTime : creationTime}</Card.Meta>
      </Card.Content>
    } else {
      return <Card.Content className='app-user-meta'>
        <Card.Meta><Icon color='red' name='ban' />{'Not Found'}</Card.Meta>
      </Card.Content>
    }
  }

  const uniqMetas = () => {
    if (uniqueProps) {
      const metas = []
      _.each(uniqueProps, (uniqueProp) => {
        if (staticViews && staticViews[uniqueProp] && appUser[uniqueProp] && _.isArray(appUser[uniqueProp])) {
          const sv = staticViews[uniqueProp]
          const _array = appUser[uniqueProp]
          _.each(_array, (a) => {
            const name = sv[a].name
            metas.push(<Card.Meta>{name}</Card.Meta>)
          })
        }
      })
      return metas
    }
  }


  const appUserCard = () => <Card as='div' fluid key={uniqueKey('aul', index)} className={'card-normal'} onClick={() => { !viewOnly && handleClick && handleClick(appUser, _itemKey) }}>
    <Card.Content>
      <Card.Header>{name}{!viewOnly && signInIcon(appUser, _itemKey)}</Card.Header>
      <Card.Meta>{lastName}{', '}{firstName}</Card.Meta>
      <Card.Meta>{email}</Card.Meta>
      {uniqMetas()}
    </Card.Content>
    <Card.Content extra className={'aul-events crdi'}>
      {cardIcons(appUser, _itemKey)}
    </Card.Content>
    {showFcms && <Card.Content extra className={'aul-events'}>
      <FcmList selectedAppUser={appUser} />
    </Card.Content>}
    {!viewOnly && events && <Card.Content extra className={'aul-events'}>
      {eventLabels(_itemKey, events)}
    </Card.Content>}
    {!viewOnly && events && aboutMe && <Card.Content extra >
      {aboutMe}
    </Card.Content>}
    {!viewOnly && events && bio && <Card.Content extra >
      {bio}
    </Card.Content>}
    {!viewOnly && clients && <Card.Content extra className={'aul-events'}>
      {clientLabels(_itemKey, clients)}
    </Card.Content>}
    {allowAppUserModification && <Card.Content extra className={'aul-mods'}>
      {modsContent(_itemKey, clients)}
    </Card.Content>}
    {metadata && metaContent()}
  </Card>

  return appUserCard()

}

export default AppUserCard