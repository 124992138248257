import { deleteObject, listAll, ref } from 'firebase/storage';
import _ from 'lodash';
import { createRefPath } from '../firestoreData/appData/appRefPaths';
import { doc_delete } from '../firestoreData/appData/fsData';
import { getBaseRef } from '../firestoreData/helpers/getBaseRef';
import { _storageSettings, getFirebaseStorage } from './storageHelpers';

export const storateStatusTypes = {
  empty: 'empty',
  deleted: 'deleted',
  test: 'test',
  error: 'error'
}

export const deleteStorageProfileFolder = async (path) => {

  const storage = getFirebaseStorage()
  const _ref = ref(storage, path)

  try {
    // List all items (files and subfolders) in the folder
    const { items } = await listAll(_ref);

    // Array to hold all delete promises
    const deletePromises = [];

    // Recursively delete each item in the folder
    items.forEach((itemRef) => {
      if (itemRef._location.path.startsWith(path)) {
        // Delete file or subfolder
        const deletePromise = deleteObject(itemRef);
        deletePromises.push(deletePromise);
      }
    });

    // Wait for all delete operations to complete
    await Promise.all(deletePromises);
    return true;
  } catch (error) {
    return false;
  }
}

export const deleteStorageContents = (path) => {

  const storage = getFirebaseStorage()
  const _ref = ref(storage, path)

  _ref.listAll().then(dir => {
    dir.items.forEach(fileRef => {
      deleteFile(_ref.fullPath, fileRef.name);
    });
    dir.prefixes.forEach(folderRef => {
      deleteStorageContents(folderRef.fullPath);
    })
    if (dir.items.length === 0 && dir.prefixes.length === 0) {
      return { storage: storateStatusTypes.empty }
    }
  })
    .catch(error => {
      return { storage: storateStatusTypes.empty }
    });
}


/**
 * Delete files (full and thumbnail) in storage by their path names
 * @param {array} paths  
 * @returns 
 */
export const deleteStorageFiles = async (allFiles, selectedFiles) => {

  const storage = getFirebaseStorage()

  const promises = []

  if (_.isArray(selectedFiles)) {
    selectedFiles.forEach(path => {
      const _ref1 = ref(storage, path)
      promises.push(deleteObject(_ref1))
      const _ref2 = ref(storage, path + _storageSettings.thumbSuffix)
      promises.push(deleteObject(_ref2))
    })
  } else {
    if (allFiles) {
      Object.keys(selectedFiles).forEach(key => {
        const selectedFile = allFiles[key]
        const { fullPaths } = selectedFile ?? {}
        const { full, thumbnail } = fullPaths ?? {}
        const _ref1 = ref(storage, full)
        promises.push(deleteObject(_ref1))
        const _ref2 = ref(storage, thumbnail)
        promises.push(deleteObject(_ref2))
      })
    }
  }
  return Promise.all(promises)
}

/** Removes storage files
 * removeProps: storageRootPath, selectedFiles, allFiles, paps_state, page_fns
 */
export const removeFilesFromStorage = (removeProps) => {

  const { storageRootPath, selectedFiles, allFiles, paps_state, page_fns } = removeProps

  const { getGviDeps } = page_fns ?? {}
  const { view, pathViews } = paps_state
  const viDeps = getGviDeps(view)
  const baseRef = getBaseRef(viDeps, pathViews)
  const { refPath } = baseRef

  removePromise(storageRootPath, refPath, selectedFiles, allFiles).then(() => {
    console.log('Files Removed')
  }).catch(err => {
    console.error(err)
  })
}

const removePromise = async (storageRootPath, refPath, selectedFiles, allFiles) => {

  const storage = getFirebaseStorage()

  const promises = []

  selectedFiles.forEach(fileKey => {
    const imd = allFiles[fileKey]
    try {
      const { fileName } = imd
      if (fileName) {
        const _childRef = ref(storage, storageRootPath + '/' + fileName)
        promises.push(deleteObject(_childRef))

        const _childThumbRef = ref(storage, storageRootPath + '/' + + _storageSettings.thumbSuffix)
        promises.push(deleteObject(_childThumbRef))

        const _deleteRef = createRefPath(['files', fileKey], refPath)
        const r3 = doc_delete(_deleteRef)
        promises.push(r3)
      }
    } catch (error) {
      console.error(error)
    }
  });
  return Promise.all(promises)

}

const deleteFile = (pathToFile, fileName) => {
  const storage = getFirebaseStorage()
  const _ref = ref(storage, pathToFile + '/' + fileName)
  deleteObject(_ref).then(res => {
    return { 'files': storateStatusTypes.deleted }
  }).catch(function (error) {
    return { 'files': storateStatusTypes.error }
  });
}