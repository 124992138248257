import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Form, Icon, Label, List, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { getObjectLength } from '../../../../global/common/convert';
import { uniqueKey } from '../../../../global/common/keys';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import { appViewerColors } from '../../../../global/components/viewers/AppDashboard';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { _districtDataIssueTypes } from '../../cnr/reducers/SportsDistrictReducer';

const _schoolContentTypes = {
  accessTeam: 'accessTeam',
}

const SportsDataIssues = (props) => {

  const { fromDashboard, handleCancel } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { districtData } = sportsDistrict_state ?? {}

  const {
    groupedData,
    schools,
    mergeData,
  } = districtData ?? {}

  const {
    matches_issues,
  } = groupedData ?? {}

  const {
    modifiedTeams,
    missingData,
    notFoundTeams,
    missingTeamObjects,
    badSectionMatches,
  } = matches_issues ?? {}


  const sportsMode = {
    organization: pathViews && pathViews.organizations && !pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    district: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    school: pathViews && pathViews.schools ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: false,
  }

  const [allItems, setAllItems] = useState({})
  const [showItemOptions, setShowItemOptions] = useState()
  const [currentItemKey, setCurrentItemKey] = useState(_districtDataIssueTypes.menu)
  const [selectedSport, setSelectedSport] = useState()
  const [selectedYear, setSelectedYear] = useState()
  const [mergeItems, setMergeItems] = useState({})
  const [teamSearchName, setTeamSearchName] = useState()
  const [teamChangeName, setTeamChangeName] = useState()
  const [useBackup, setUseBackup] = useState()

  let _wrapperType;

  switch (currentItemKey) {
    case _districtDataIssueTypes.sportAccess:
    case _districtDataIssueTypes.schoolAccess:
    case _schoolContentTypes.accessTeam:
      _wrapperType = wrapperTypes.paddedHeaderAndFooter
      break;
    default:
      _wrapperType = wrapperTypes.padded
  }

  const itemValue = (index, header, value) => {
    switch (header) {
      case 'id':
        return index + 1
      default:
        const hv = value && !_.isObject(value) && !_.isArray(value) ? value : ''
        switch (header) {
          case 'section':
            return hv.replace('section', '')
          default:
            return hv
        }
    }
  }

  const handleSelectedSchool = (e, item, mergeIndex) => {
    e.stopPropagation()
    const _mergeItems = { ...mergeItems }
    _mergeItems[mergeIndex] = item
    setMergeItems(_mergeItems)
  }

  const handleSelectedSport = (e, value) => {
    e.stopPropagation()
    value === selectedSport ? setSelectedSport() : setSelectedSport(value)
  }

  const handleSelectedYear = (e, value) => {
    e.stopPropagation()
    value === selectedYear ? setSelectedYear() : setSelectedYear(value)
  }

  useEffect(() => {
    sportsDistrict_handlers.handleGet_districtData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mergeItems && getObjectLength(mergeItems) === 2) {
      sportsDistrict_handlers.handleGet_alternateTeamsAndMatches(mergeItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeItems]);

  useEffect(() => {
    let _contentTypes = {}
    const items = {}
    if (sportsMode.district) {
      _contentTypes = _districtDataIssueTypes
    } else if (sportsMode.school) {
      _contentTypes = _schoolContentTypes
    }
    Object.keys(_contentTypes).forEach(ict => {
      items[ict] = { name: _.startCase(ict), _itemKey: ict }
    })
    const _allItems = { ...items }
    setAllItems(_allItems)
    if (_allItems && Object.keys(_allItems).length === 1) {
      setCurrentItemKey(Object.keys(_allItems)[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleItemSelect = (key) => {
    setShowItemOptions()
    setCurrentItemKey(key)
  }

  const handleBackup = () => sportsDistrict_handlers.handleBackup(currentItemKey, selectedSport, selectedYear)
  const handleFix = () => sportsDistrict_handlers.handleFixData(currentItemKey)
  const handleShowItemOptions = () => setShowItemOptions(!showItemOptions)

  const handleTeamSearchName = (e, data) => setTeamSearchName(data.value)
  const handleTeamChangeName = (e, data) => setTeamChangeName(data.value)

  const handleTeamSearch = () => {
    const _mergeItems = {
      1: { name: teamSearchName },
      2: { name: teamChangeName },
    }
    sportsDistrict_handlers.handleGet_alternateTeamsAndMatches(_mergeItems)
  }

  const optionsMenu = () => {
    const mis = []
    const _items = _.sortBy(allItems, 'name')
    _items.forEach(item => {
      let count = -1
      switch (item._itemKey) {
        case _districtDataIssueTypes.matchTeamsNotFound:
          count = getObjectLength(notFoundTeams)
          break;
        case _districtDataIssueTypes.modifiedMatchTeams:
          count = getObjectLength(modifiedTeams)
          break;
        case _districtDataIssueTypes.mismatchedMatchData:
          count = getObjectLength(missingData)
          break;
        case _districtDataIssueTypes.missingTeamObjects:
          count = getObjectLength(missingTeamObjects)
          break;
        case _districtDataIssueTypes.badSectionMatches:
          count = getObjectLength(badSectionMatches)
          break;
        default:
        // nothing
      }
      mis.push(<Menu.Item
        key={uniqueKey('dv', 'mi', item._itemKey)}
        onClick={() => { handleItemSelect(item._itemKey) }}
      >
        {_.startCase(item.name)}
        {count >= 0 && <Label>{count}</Label>}
      </Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'av-menu sdi'}>{mis}</Menu></Segment>
  }

  const OptionsSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='vsl-container'
      onHide={() => handleShowItemOptions()}
    >
      {allItems && optionsMenu()}
    </Sidebar>
  )

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <OptionsSidebar visible={showItemOptions ? true : false} />
    <Sidebar.Pusher dimmed={showItemOptions ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>


  const itemOptionsButton = () => {
    const btns = [
      { caption: _.startCase(currentItemKey), oc: handleShowItemOptions, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.dmv}
      rows={1}
    />
  }
  const listTeams = (listData) => {
    const listItems = []
    Object.keys(listData).forEach(key => {
      const dataItem = listData[key]
      const { name, name_season } = dataItem
      listItems.push(card(name, name_season))
    })
    return <div>
      <Label>{listItems.length}</Label>
      <Card.Group>{listItems}</Card.Group>
    </div>
  }

  const listMerge = (listData) => <div className='list-merge'>
    <div>{schoolList(listData, 1)}</div>
    <div>{schoolList(listData, 2)}</div>
    <div>{schoolMerge()}</div>
  </div>

  const card = (header, meta, descriptions) => <Card>
    <Card.Content>
      {header && <Card.Header>{header}</Card.Header>}
      {meta && <Card.Meta>{meta}</Card.Meta>}
      {descriptions.map(d => <Card.Meta>{d}</Card.Meta>)}
    </Card.Content>
  </Card >

  const listMatches = (listData) => {
    const listItems = []
    listData.forEach(dataItem => {
      const { away, home, _matchSeasonKey, _sportName, _itemKey } = dataItem
      if (away && away.name_season) {
        listItems.push(card(away.name, away.name_season, [_sportName, _matchSeasonKey, _itemKey]))
      } else if (home && home.name_season) {
        listItems.push(card(home.name, home.name_season, [_sportName, _matchSeasonKey, _itemKey]))
      }
    })
    return <div>
      <Label>{listItems.length}</Label>
      <Card.Group>{listItems}</Card.Group>
    </div>
  }

  const listMatches_noObject = (listData) => {
    const listItems = []
    listData.forEach(dataItem => {
      const { away, home, _matchSeasonKey, _itemKey, _sportName } = dataItem
      listItems.push(card(away + ' @ ' + home, _sportName, [_matchSeasonKey, _itemKey]))
    })

    return <div>
      <Label>{listItems.length}</Label>
      <Card.Group>{listItems}</Card.Group>
    </div>
  }

  const listMatches_missingData = (listData) => {
    const listItems = []
    listData.forEach(dataItem => {
      const { _itemKey, _matchSeasonKey } = dataItem
      listItems.push(card(_itemKey, _matchSeasonKey))
    })

    return <div>
      <Label>{listItems.length}</Label>
      <Card.Group>{listItems}</Card.Group>
    </div>
  }

  const input_search = () => <Form.Input
    value={teamSearchName}
    label='Team Name'
    placeholder={'teamName'}
    onChange={handleTeamSearchName}
  />

  const input_change = () => <Form.Input
    icon={<Icon name='search' inverted circular link onClick={handleTeamSearch} />}
    value={teamChangeName}
    label='Team Change Name'
    placeholder={'teamChangeName'}
    onChange={handleTeamChangeName}
  />

  const schoolMerge = () => {

    const { schoolsSelected, matches_seasonal, teams, teams_seasonal } = mergeData ?? {}
    const { source } = schoolsSelected ? schoolsSelected : {}

    const matchCards = []
    if (matches_seasonal) {
      Object.keys(matches_seasonal).forEach(k => {
        const m = matches_seasonal[k]
        matchCards.push(card(m.away.name + ' @ ' + m.home.name, m.startDate))
      })
    }

    const _teamCards = []
    if (teams) {
      Object.keys(teams).forEach(k => {
        const t = teams[k]
        _teamCards.push(card(t.name, t.old.name))
      })
    }

    const teamCards_seasonal = []
    if (teams_seasonal) {
      teams_seasonal.forEach(t => {
        teamCards_seasonal.push(card(t.name, t.old.name))
      })
    }
    return <div>
      {input_search()}
      {input_change()}
      <Segment.Group>
        <Segment>{'Matches'}</Segment>
        {source && source.name && <Segment><Card.Group>{matchCards}</Card.Group></Segment>}
      </Segment.Group>
      <Segment.Group>
        <Segment>{'Teams'}</Segment>
        {source && source.name && <Segment><Card.Group>{_teamCards}</Card.Group></Segment>}
      </Segment.Group>
      <Segment.Group>
        <Segment>{'Teams Seasonal'}</Segment>
        {source && source.name && <Segment><Card.Group>{teamCards_seasonal}</Card.Group></Segment>}
      </Segment.Group>
    </div>
  }

  const schoolList = (listData, mergeIndex) => {
    const listItems = []
    const dataItems = _.sortBy(listData, 'name')
    dataItems.forEach(item => {
      const { name, teams } = item
      const selected = mergeItems && mergeIndex && mergeItems[mergeIndex] && (mergeItems[mergeIndex].name === name)
      listItems.push(<List.Item onClick={(e) => { handleSelectedSchool(e, item, mergeIndex) }}>
        <List.Icon name='list' color={selected ? 'blue' : 'grey'} />
        <List.Content>
          <List.Header>{_.startCase(name)}</List.Header>
        </List.Content>
      </List.Item>)
    })
    return <List>{listItems}</List>
  }

  const matchItems = (matches) => {
    const matchItems = []
    if (matches) {
      Object.keys(matches).forEach(mk => {
        const match = matches[mk]
        const { _itemKey, _matchSeasonKey, away, home, levels, sections, startDate, _matchLevelKey, _sportName } = match ?? {}
        if (home && away) {
          matchItems.push(card(away.name + ' @ ' + home.name, startDate, [_itemKey, _matchSeasonKey, _sportName, _matchLevelKey, levels, sections]))
        }
      })
    }
    return <div>
      <Label>{matchItems.length}</Label>
      <Card.Group>{matchItems}</Card.Group>
    </div>
  }

  const content = () => {
    switch (currentItemKey) {
      case _districtDataIssueTypes.menu:
        return <Segment basic>Please select a menu item</Segment>
      case _districtDataIssueTypes.schoolMerge:
        return listMerge(schools)
      case _districtDataIssueTypes.matchTeamsNotFound:
        return notFoundTeams && listTeams(notFoundTeams)
      case _districtDataIssueTypes.modifiedMatchTeams:
        return modifiedTeams && listMatches(modifiedTeams)
      case _districtDataIssueTypes.mismatchedMatchData:
        return missingData && listMatches_missingData(missingData)
      case _districtDataIssueTypes.missingTeamObjects:
        return missingData && listMatches_noObject(missingTeamObjects)
      case _districtDataIssueTypes.badSectionMatches:
        return badSectionMatches && matchItems(badSectionMatches)
      default:
        return <div>Menu</div>
    }
  }

  const header = () => <div className={'header-flex'}>
    {itemOptionsButton()}
  </div>

  const saveButtons = () => {
    const btns = []
    switch (currentItemKey) {
      case _districtDataIssueTypes.matchTeamsNotFound:
        btns.push({ oc: handleFix, caption: 'Fix ' + _.startCase(currentItemKey), icon: 'save' })
        btns.push({ oc: handleBackup, caption: 'Backup Seasonal Teams', icon: 'save' })
        break;

      case _districtDataIssueTypes.modifiedMatchTeams:
        btns.push({ oc: handleFix, caption: 'Fix ' + _.startCase(currentItemKey), icon: 'save' })
        break;

      case _districtDataIssueTypes.mismatchedMatchData:
        btns.push({ oc: handleFix, caption: 'Fix ' + _.startCase(currentItemKey), icon: 'save' })
        break;

      case _districtDataIssueTypes.missingTeamObjects:
        btns.push({ oc: handleFix, caption: 'Fix ' + _.startCase(currentItemKey), icon: 'save' })
        break;

      case _districtDataIssueTypes.badSectionMatches:
        btns.push({ oc: handleFix, caption: 'Fix ' + _.startCase(currentItemKey), icon: 'save' })

        break;
      default:
    }
    return btns
  }

  const footer = () => {
    const btns = saveButtons()
    return btns.length > 0 ? <UiSaveButtons
      others={saveButtons()}
    /> : null
  }

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    footer={footer()}
    wrapperType={_wrapperType}
    css={{ header: 'bcg' }}
    updating={!districtData}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={'Google Link'}
    handleCancel={handleCancel}
  />

  return fromDashboard ? sidebar() : fullPageWrapper()

}

export default SportsDataIssues