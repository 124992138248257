import React, { useContext, useEffect, useReducer } from "react";
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sportsOrganizationHandlers, sportsOrganizationInitialState, sportsOrganizationReducer } from "../reducers/SportsOrganizationReducer";
import { FirestoreContext } from "../../../../global/cnr/contexts/FirestoreContext";

export const SportsOrganizationContext = React.createContext()

export default function SportsOrganizationProvider(props) {

  const { directReturn } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states ?? {}

  // firestoreContext
  const firestoreContext = useContext(FirestoreContext)
  const { firestore_fns } = firestoreContext ?? {}

  // appUserContext
  const { appUser } = appUser_state ?? {}
  const { uid } = appUser ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}
  const view = 'organizations'

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_page, aps_global } = pageSettings ?? {}
  const { projectOptions } = aps_global ?? {}
  let { useAppDarkMode } = projectOptions ?? {}

  const { viewItems: viewItems_page } = aps_page ?? {}

  let viewItem = viewItems_page && viewItems_page[view] ? viewItems_page[view] : {}
  const { dataSource } = viewItem ?? {}
  let { dataParents, useSeasonals } = dataSource ?? {}

  if (!dataParents) {
    dataParents = ['orgnaizations', 'districts']
  }

  let ownerPath;

  if (pathViews) {
    if (pathViews.schools) { ownerPath = 'schools' }
    if (pathViews.districts) { ownerPath = 'districts' }
    if (pathViews.organizations) { ownerPath = 'organizations' }
  }

  const init_state = { firestore_fns, useAppDarkMode, viewItem, useSeasonals, uid, ownerPath, inverted: false, cssOpts: { name: 'ssc-normal' } }
  const [sportsOrganization_state, sportsOrganization_dispatch] = useReducer(sportsOrganizationReducer, init_state, sportsOrganizationInitialState)
  const sportsOrganization_handlers = sportsOrganizationHandlers(sportsOrganization_dispatch, sportsOrganization_state)

  useEffect(() => {
    if (pathViews.organizations && dataParents) {
      sportsOrganization_handlers.handleInit_sportsOrganization(pathViews, dataParents)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.organizations])

  if (sportsOrganization_state) {
    if (directReturn) {
      return { sportsOrganization_handlers, sportsOrganization_state }
    } else {
      return <SportsOrganizationContext.Provider
        value={{ sportsOrganization_state, sportsOrganization_handlers, appComponent: 'sportsOrganization' }}>
        {props.children}
      </SportsOrganizationContext.Provider>
    }
  } else {
    return <SportsOrganizationContext.Provider
      value={{}}>
      {props.children}
    </SportsOrganizationContext.Provider>
  }
}