import React, { createContext, useReducer } from 'react';
import { appDataHandlers, appDataInitialState, appDataReducer } from '../reducers/AppDataReducer';

/** gets the data for the uiItem.
 * @returns viewListData, confirmation, globalFiles */
export const AppDataContext = createContext();

/** gets the data for the uiItem.
 * @returns viewListData,  
 * @returns confirmation, 
 * @returns globalFiles */
const AppDataProvider = (props) => {

  // eventInfoInitialState
  const initState = appDataInitialState()
  const [appData_state, dispatch] = useReducer(appDataReducer, initState);
  const appData_handlers = appDataHandlers(dispatch)

  return <AppDataContext.Provider value={{ appData_state, appData_handlers }}>
    {props.children}
  </AppDataContext.Provider>
}

export default AppDataProvider