import tr from "date-fns/esm/locale/tr/index.js";
import { ref } from "firebase/storage";
import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button, Icon, Message } from 'semantic-ui-react';
import { getAppUserAccess } from "../../auth/appUserAccessPermissions";
import { DataModifyContext } from "../../cnr/contexts/DataModifyContext";
import { FrameworkContext } from "../../cnr/contexts/FrameworkContent";
import ItemStorageProvider from "../../cnr/contexts/ItemStorageContext";
import { ParentContext } from "../../cnr/contexts/ParentContext";
import { UiItemContext } from "../../cnr/contexts/UiItemContext";
import UploadProvider from "../../cnr/contexts/UploadContext";
import { uploadHandlers, uploadInitialState, uploadReducer } from '../../cnr/reducers/UploadReducer';
import UiSaveButtons from "../../components/buttons/UiSaveButtons";
import { appIconTypes } from "../../enums/appIconTypes";
import { gEnums } from '../../enums/globalEnums';
import GenericIcon from "../../genericControls/GenericIcon";
import { DataModificationsContext } from "../../pageItem/modification/UiDataModifications";
import { getFirebaseStorage } from "../../storage/storageHelpers";
import { dataModificationTypes } from "../../viewSettings/enums/itemActionTypes";
import { openExternal } from "../../viewSettings/helpers/settingsLinks";
import FullPageWrapper from "../../wrappers/FullPageWrapper";
import Wrapper, { wrapperTypes } from "../../wrappers/Wrapper";
import { DataManagementContext } from "../viewers/DataManagementViewer";
import BulkUploader from "./BulkUploader";
import SingleImageUploader from "./SingleImageUploader";
import { UploadFileButton, UploadSelectButton } from "./UploadButton";

/**
 * This is a single item uploader with the select button that opens up a file directory
 * @param {*} props (storageLocationType, fileViewer_handlers, itemData: altItemData, forBulk)
 * @returns BulkUploader OR SingleImageUploader
 */
const Uploader = (props) => {

  const {
    fileViewer_handlers,
    handleCancel,
    itemData: altItemData,
    storageLocationType,
    useFull,
    fullMode,
    origin,
  } = props

  let { appStorageCollection, storageType } = props

  if (!storageType) { storageType = gEnums.storageTypes.image }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { appUser_state, paps_state, page_state, storage_state, eventInfo_state } = states
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { productionSettings, storageOptions } = aps_global ?? {}
  const { appUserCollection, useClientProfileImage } = aps_appUserSettings ?? {}
  const { galleries } = storage_state ?? {}
  const { staticViews } = eventInfo_state ?? {}

  // authContext 
  const { appUser, appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel, isAdminOrSuper } = appUserAccess ?? {}

  // dataManagementContext
  const frameworkContext = useContext(FrameworkContext)
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { selectedCollectionKey } = framework_state ?? {}
  const { handleShow_appDashboard } = framework_handlers ?? {}

  // dataManagementContext
  const dataModificationContext = useContext(DataModificationsContext)
  const { dataModifications_state } = dataModificationContext ?? {}
  const { appData: appData_dmod, dataModification } = dataModifications_state ?? {}
  const { images } = dataModification ?? {}
  const { allow } = images ?? {}

  // dataManagementContext
  const dataManagementContext = useContext(DataManagementContext)
  const { dataManagement_state } = dataManagementContext ?? {}
  const { viewItem, appData: appData_dman, dataManagementType } = dataManagement_state ?? {}
  const { key: viewItemKey_dm } = viewItem ?? {}

  // dataModifyContext
  const dataModifyContext = useContext(DataModifyContext)
  const { dataModify_handlers, dataModify_state } = dataModifyContext ?? {}
  const { dataModificationType } = dataModify_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_state } = uiItemContext ?? {}
  const { viewItem: viewItem_item, dataItem } = item_state ?? {}
  const { key: viewItemKey } = viewItem_item ?? {}

  // papsContext 
  const { storagePaths, pathViews, storageRootPath, view, viewKey, lastView, lastViewKey } = paps_state

  const allowShowRefPath = allow || isAdminOrSuper
  let _appData = appData_dmod ? appData_dmod : appData_dman

  let _noMid = false

  switch (origin) {
    case 'uiUpload':
      _noMid = false
      break;
    case 'manifestViewer':
      _noMid = tr
      break;
    default:
    // nothing
  }

  let _showBulkUploader = false;

  switch (dataManagementType) {
    case dataModificationTypes.storageImages:
    case dataModificationTypes.bulkImageUpload:
      _showBulkUploader = true
      break;
    default:
      break;
  }

  switch (dataModificationType) {
    case dataModificationTypes.bulkImageUpload:
      _showBulkUploader = true
      break;
    default:
      // nothing
      break;
  }

  let _viewItemKey = viewItemKey_dm ? viewItemKey_dm : viewItemKey
  if (props.viewItemKey) { _viewItemKey = props.viewItemKey }
  if (selectedCollectionKey) { _viewItemKey = selectedCollectionKey }

  // if (staticViews && staticViews[_viewItemKey]) {
  //   _appData = staticViews[_viewItemKey]
  // }

  const _handleCancel = handleShow_appDashboard ? handleShow_appDashboard : handleCancel

  const viewUploadProps = { view: lastView, viewKey: lastViewKey }
  if (storageLocationType === gEnums.storageLocationTypes.profiles) { viewUploadProps.imageUrlOnly = true }

  // local state
  const init_state = {
    xxx: {
      appUser,
      appUsers,
      pageSettings,
      galleries,
    },
    altItemData,
    appStorageCollection,
    appUserAccess,
    appUserCollection,
    dataItem,
    dataModificationType,
    forBulk: _showBulkUploader,
    handleCancel: _handleCancel,
    handlers,
    lastView,
    pathViews,
    productionSettings,
    storageLocationType,
    storageOptions,
    storagePaths,
    storageRootPath,
    storageType,
    useClientProfileImage,
    view,
    viewItemKey: _viewItemKey,
    viewKey,
    viewListData: _appData,
    viewUploadProps,
  }

  const [upload_state, uploadDispatch] = useReducer(uploadReducer, uploadInitialState(init_state));
  const upload_handlers = uploadHandlers(uploadDispatch, upload_state)
  const { useDarkMode, storageRef, storageLocationType: storageLocationType_us, formData, avatarEditor, selectedFolderFiles, manifestFiles, updating, imageImage, confirmation } = upload_state ?? {}

  const _storageLocationType = storageLocationType_us !== storageLocationType ? storageLocationType_us : storageLocationType
  const _uploadButtonCaption = 'Upload (' + _.startCase(_storageLocationType) + '/' + _.startCase(storageType) + ')'

  const _storage = getFirebaseStorage()
  const _storageRef = ref(_storage, storageRef)

  const inverted = _storageLocationType === gEnums.storageLocationTypes.event ? true : false

  let _selectedFiles;

  if (manifestFiles && manifestFiles.length > 0) {
    _selectedFiles = manifestFiles
  } else if (selectedFolderFiles && selectedFolderFiles.length > 0) {
    _selectedFiles = selectedFolderFiles
  }

  const accept = '*'

  let isCroppable = false

  const [useAvatar, setAvatar] = useState(isCroppable)
  const [showPath, setShowPath] = useState()
  const [uploadReady, setUploadReady] = useState()

  useEffect(() => {
    upload_handlers.handleInit_upload(init_state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_appData]);

  useEffect(() => {
    let _uploadReady = (_selectedFiles && _selectedFiles.length > 0) ? true : false
    if (imageImage) { _uploadReady = true }
    switch (storageType) {
      case gEnums.storageTypes.pdf:
        const { name } = formData ?? {}
        if (!name) {
          _uploadReady = false
        }
        break;
      default:
      // nothing
    }
    setUploadReady(_uploadReady)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageImage, _selectedFiles, formData]);

  /** Starts the process of uploading the file to Firebase Storage using the upload_handlers */
  const handleImageUpload = () => {
    upload_handlers.handleStartUpdate()
    if (useAvatar) {
      const canvasScaled = avatarEditor.getImageScaledToCanvas()
      upload_handlers.handleUpload_manual(canvasScaled, useAvatar)
    } else {
      upload_handlers.handleUpload_manual()
    }
  }

  const handleCloseUploader = () => {
    handleCancel && handleCancel()
    switch (storageLocationType) {
      case gEnums.storageLocationTypes.profileGallery:
        break;
      default:
        handleShow_appDashboard && handleShow_appDashboard()
      // nothing 
    }
  }

  const handleImageDecline = () => {
    setShowPath()
    upload_handlers.handleDecline()
  }

  const handleOpenStorage = () => openExternal.db(pathViews, true)

  const btnProps = { inverted: useDarkMode }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const killIcon = () => gi('icn-kill', appIconTypes.arrowLeft, fileViewer_handlers.handleShowUpload, null, { ...btnProps, fl: true })
  const backIcon = () => gi('icn-back', appIconTypes.arrowLeft, dataModify_handlers.handleCloseImageUploader, null, { ...btnProps, fl: true })

  /** buttons that commit or reject the current image */
  const upload_buttons = () => <UiSaveButtons
    cancel={{ oc: handleImageDecline }}
    save={{ oc: handleImageUpload, caption: _uploadButtonCaption, disabled: !uploadReady }}
  ></UiSaveButtons>

  const avatarButton = () => <Button icon basic color={useAvatar ? 'blue' : 'grey'} onClick={() => setAvatar(!useAvatar)} style={{ float: 'left' }} size={'small'}>
    <Icon name={'crop'} />
  </Button>

  /** Fragment containing an upload button AND the FileUploader */
  const uploadButtons = () => <React.Fragment>
    {upload_buttons()}
    <UploadFileButton accept={accept} upload_handlers={upload_handlers} _storageRef={_storageRef} storageType={storageType} />
  </React.Fragment>

  const selectButtons = () => (
    <div className={'uploader-header'}>
      {fileViewer_handlers && killIcon()}
      {dataModify_handlers && backIcon()}
      {isCroppable && avatarButton()}
      {!uploadReady && <UploadSelectButton accept={accept} upload_handlers={upload_handlers} _storageRef={_storageRef} storageType={storageType} />}
      {!_showBulkUploader && uploadReady && uploadButtons()}
    </div>
  )

  /**
   * 
   * @returns BulkUploader OR SingleImageUploader
   */
  const contents = () => {
    if (_showBulkUploader) {
      return <ItemStorageProvider showItemImage={true} viewItemKey={_viewItemKey}>
        <BulkUploader handleCancel={handleCloseUploader} />
      </ItemStorageProvider>
    } else {
      return <SingleImageUploader
        showPath={showPath}
        storageRef={storageRef}
        useAvatar={useAvatar}
        viewItemKey={_viewItemKey}
        storageType={storageType}
        storageLocationType={_storageLocationType}
        inverted={inverted}
      />
    }
  }

  const message = () => <Message
    info={true}
    size='tiny'
    icon='database'
    header='Storage Path'
    content={storageRef}
    onClick={() => handleOpenStorage()}
  />

  const wrapper = () => <Wrapper
    header={allowShowRefPath && storageRef && message()}
    content={contents()}
    footer={!_showBulkUploader && uploadReady && selectButtons()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
    confirmation={confirmation}
    noMid={_noMid}
    css={{ header: 'wbbw', container: 'upl-' + _storageLocationType, container: 'cnt-uploader' }}
  />

  const uploadWrapper_withProvider = () => <UploadProvider upload_state={upload_state} upload_handlers={upload_handlers}>
    {wrapper()}
  </UploadProvider>

  const fullPageUploader = () => <FullPageWrapper
    content={uploadWrapper_withProvider()}
    handleCancel={handleCloseUploader}
    topperCaption={'Upload Image'}
    topperCaption2={_.startCase(_storageLocationType)}
    fullMode={fullMode}
  />

  if (useFull) {
    return fullPageUploader()
  } else {
    return uploadWrapper_withProvider()
  }

}

export default Uploader  