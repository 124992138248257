import { createRefPath, createRefPath_client } from '../appData/appRefPaths';
import { fs_get_data } from "../appData/fsAppData";
import { fs_update_doc } from "../appData/fsData";
import { _profileCollectionName } from "./getProfile";

export const updateAppUserFcmTokenToDatabase = async (fcmTokens, appUser, pathViews, callback) => {
  const { profileData } = appUser ?? {}
  const { email } = profileData ?? {}
  if (email) {
    return updateProfileToken(fcmTokens, appUser, pathViews, callback)
  }
}

/** Updates a profile token for the user */
const updateProfileToken = async (fcmTokens, appUser, pathViews, callback) => {

  const { profileData } = appUser ?? {}
  const { email, _itemKey } = profileData ?? {}

  const allowStandAlone = true
  let isSmartPhone;
  let result;

  if (window.matchMedia('(display-mode: standalone)').matches) {
    isSmartPhone = true
  } else {
    isSmartPhone = false
  }

  if (email && (isSmartPhone || allowStandAlone)) {
    // get the profile
    // const wheres = [where('email', '==', email)]
    const profile = await fs_get_data({ refPath: [_profileCollectionName, _itemKey], opts: { returnFirstObject: true } })
    if (profile) {
      const data = { fcmTokens: fcmTokens }
      const _refPath = createRefPath([_profileCollectionName, profile.id])
      result = await fs_update_doc(_refPath, data)
    } else {
      result = await updateClientProfileToken(fcmTokens, appUser, pathViews)
      return result
    }

  }
}

const updateClientProfileToken = async (fcmTokens, appUser, pathViews) => {

  const { profileData } = appUser ?? {}
  const { email } = profileData ?? {}

  if (email) {
    const _refPath = createRefPath_client(pathViews, [_profileCollectionName, email])
    try {
      const result = await fs_update_doc(_refPath, { fcmTokens: fcmTokens })
      return result
    } catch (error) {
      console.log('Client token NOT updated ', error)
    }
  }
} 