import _ from 'lodash'
import { formatItem, formatTypes } from "../../../global/common/dateFormatting"
import { _matchLsTypes } from "../cnr/reducers/SportsDataReducer"
import { gameScoringTypes } from "../cnr/reducers/SportsSeasonReducer"
import { copyObj } from '../../../global/common_web/copy'
import { _resultsProp } from '../cnr/contexts/SportsSeasonContext'

export const matchCalcs = {
  calcAllVballMatches: (matches, gameScoringType) => calcAllVballMatches(matches, gameScoringType),
  calcVballMatch: (match, gameScoringType) => calcVballMatch(match, gameScoringType),
}

/**
 * 
 * @param {object} matches 
 * @param {object} updatedMatch 
 */
const calcAllVballMatches = (matches, gameScoringType) => {
  Object.keys(matches).forEach((key, i) => {
    const match = matches[key]
    match && calcVballMatch(match, gameScoringType)
  })
}

/**
 * 
 * @param {object} match 
 * @param {object} updatedMatch 
 */
const calcVballMatch = (match, gameScoringType) => {

  const { bestOf, setCount, isPlayoff, away, home } = match ?? {}
  const results = match ? match[_resultsProp] : undefined;
  const { complete } = results ?? {}

  const thisMatch = match

  if (!match._status) { match._status = {} }

  if (isPlayoff && away && home && away.seed && home.seed && (home.seed > away.seed)) { match._flipHa = true }

  if (!match.roundInfo && thisMatch.roundInfo) { match.roundInfo = thisMatch.roundInfo }

  const _scores_flat = ammendResults_matchFromFlat(match)

  ammendLevelSection(match)
  ammendResults_match(match, gameScoringType, _scores_flat)

  if (match && match.startDate) { match.startDate = formatItem(formatTypes.shortestDate, match.startDate) }
  ammendMatchDate(match)

  // match.setsToWin = bestOf ? Math.round(setCount / 2) : setCount
  match._status.setsToWin = bestOf ? Math.round(setCount / 2) : setCount

  if (match?._status.isPast && !complete) {
    match._status.isMissing = true
  }

}

const ammendLevelSection = (match) => {

  const { sections, levels, isPlayoff, poolKey, _matchCollectionKey } = match

  if (_matchCollectionKey === _matchLsTypes.exhibition) {
    match.ls = _.startCase(_matchLsTypes.exhibition)
  } else if (_matchCollectionKey === _matchLsTypes.nonDistrict) {
    match.ls = _.startCase(_matchCollectionKey)
  } else if (_matchCollectionKey === _matchLsTypes.tournament) {
    match.ls = _.startCase(_matchLsTypes.tournament)
  } else {
    if (sections === _matchLsTypes.non) {
      match.ls = sections + ' Section'
    } else if (sections && levels) {
      match.ls = levels + ' - ' + _.startCase(sections)
    } else if (isPlayoff) {
      match.ls = levels + ' - ' + _.startCase(poolKey)
    }
  }
}


const ammendResults_matchFromFlat = (match) => {
  let scores;
  for (var i = 1; i <= 5; i++) {
    if (match['away' + i] && match['home' + i]) {
      if (!scores) { scores = {} }
      scores[i] = { away: 0, home: 0 }
      if (match['away' + i]) { scores[i].away = match['away' + i] }
      if (match['home' + i]) { scores[i].home = match['home' + i] }
    }
  }
  return scores
}

const ammendResults_match = (match, gameScoringType, _scores_flat) => {

  const { results: results_match, _results, results_edit, results_realtime, tempScores } = match
  if (!_results && results_match) { match._results = results_match }

  const results = match ? match[_resultsProp] : undefined;

  // const results = match ? match[_resultsProp] : undefined;

  let _isEditResults = results_edit ? true : false
  let _rstz = results_edit ? copyObj(results_edit) : copyObj(results)
  if (results_realtime) { _rstz = results_realtime }

  if (!_rstz) { _rstz = {} }

  setResultsBase(_rstz)

  if (_isEditResults) {
    match.results_edit = _rstz
  } else {
    match[_resultsProp] = _rstz
  }

  switch (gameScoringType) {
    case gameScoringTypes.match:
    case gameScoringTypes.matchPoints:
      ammendScores_match(match, _scores_flat)
      ammendScore_match(match)
      break;
    default:
      ammendScore_game(match)
  }

  if (!_rstz.winner) {
    if ((_rstz.setsCompleted + 1) > match.setsToWin) {
      _rstz.setsDisplayed = _rstz.setsCompleted + 1
    }
  }

}

const setResultsBase = (results, tempScores) => {

  results.setsCompleted = 0
  results.scoresCompleted = 0
  results.complete = false
  results.winner = null
  results.loser = null
  results.tied = null
  results.setWinners = {}
  results.tempScoreCount = (tempScores) ? Object.keys(tempScores).length : 0

  results.scoreDisplays = {
    normal: '---',
    home: '',
    full: '',
    scores: '',
    set: ''
  }

  results.scoreDisplays_flip = {
    normal: '---',
    home: '',
    full: '',
    scores: '',
    set: ''
  }

}

const ammendScoreDisplays = (match, results, score, scores, set_number) => {

  if (!results.scoreDisplays) { results.scoreDisplays = {} }

  results.scoreDisplays.normal = score.away + ' - ' + score.home
  results.scoreDisplays.home = score.home + ' - ' + score.away

  if (match.away && match.home) {
    if (_.isObject(match.away) && _.isObject(match.home)) {
      results.scoreDisplays.full = match.away.name + ' ' + score.away + ' - ' + score.home + ' ' + match.home.name
    } else {
      results.scoreDisplays.full = match.away + ' ' + score.away + ' - ' + score.home + ' ' + match.home
    }
  }

  if (scores && set_number) {
    results.setScore = scores && scores[set_number] ? scores[set_number] : { home: 0, away: 0 }

    if (results.setScore) {
      results.scoreDisplays.set = results.setScore.away + ' - ' + results.setScore.home
    }
  }

  if (match._flipHa) {
    results.scoreDisplays_flip.normal = score.home + ' - ' + score.away

    if (match.away && match.home) {
      if (_.isObject(match.away) && _.isObject(match.home)) {
        results.scoreDisplays_flip.full = match.home.name + ' ' + score.home + ' - ' + score.away + ' ' + match.away.name
      } else {
        results.scoreDisplays_flip.full = match.home + ' ' + score.home + ' - ' + score.away + ' ' + match.away
      }
    }

    if (scores && set_number) {

      if (results.setScore) {
        results.scoreDisplays_flip.set = results.setScore.home + ' - ' + results.setScore.away
      }
    }
  }
}

/**
 * 
 * @param {object} match 
 */
const ammendScore_match = (match) => {

  let { results_edit } = match
  const results = match ? match[_resultsProp] : undefined;
  let _res = results_edit ? copyObj(results_edit) : copyObj(results)

  const { bestOf, setCount, _status } = match
  const { setsToWin } = _status
  const stw = setsToWin ? setsToWin : 3
  let { score } = _res ? _res : {}

  let _isCurrent = results_edit ? true : false

  // do we have a score?
  if (score) {

    let winnerDeclared = false

    // did a team win?
    if (bestOf) {
      if ((score.home === stw || score.away === stw) && (score.home >= 0 && score.away >= 0)) {
        winnerDeclared = true
      }
    } else {
      if ((score.home + score.away === setCount)) {
        winnerDeclared = true
      }
    }

    if (winnerDeclared) {
      _res.complete = true
      _res.inProgress = false
      // which team won
      if (score.home === stw) {
        _res.winner = match.home
        _res.loser = match.away
      } else {
        _res.winner = match.away
        _res.loser = match.home
      }
    }

    if (match.away && match.home && score) {
      if ((score.home === stw || score.away === stw) && (score.home >= 0 && score.away >= 0)) {
        _res.complete = true
        delete _res.inProgress
        if (score.home === stw) {
          _res.winner = match.home
          _res.loser = match.away
        } else if (score.away === stw) {
          _res.winner = match.away
          _res.loser = match.home
        }
      }

      ammendScoreDisplays(match, _res, score)

      _res.setsCompleted = score.home + score.away
      _res.officialResult = true
    }
  }

  if (_isCurrent) {
    match.results_edit = _res
  } else {
    match[_resultsProp] = _res
  }

}


/**
 * 
 * @param {object} match 
 */
const ammendScore_game = (match) => {

  let { results_edit } = match
  const results = match ? match[_resultsProp] : undefined;
  let _res = results_edit ? copyObj(results_edit) : copyObj(results)

  let { score } = _res ?? {}

  // do we have a score?
  if (score) {

    let winnerDeclared = true

    if (winnerDeclared) {
      _res.complete = true
      _res.inProgress = false
      // which team won
      if (score.home > score.away) {
        _res.winner = match.home
        _res.loser = match.away
      } else if (score.away > score.home) {
        _res.winner = match.away
        _res.loser = match.home
      } else {
        if (match.away && match.home) {
          _res.tied = [match.away.id, match.home.id]
        }
      }
    }

    if (match.away && match.home && score) {
      ammendScoreDisplays(match, _res, score)
      _res.setsCompleted = score.home + score.away
      _res.officialResult = true
    }
  }

  match[_resultsProp] = _res
}

const fixScores = (scores) => {
  if (scores) {
    Object.keys(scores).forEach(setNumber => {
      if (scores[setNumber]) {
        if (scores[setNumber].away) { scores[setNumber].away = parseInt(scores[setNumber].away) }
        if (scores[setNumber].home) { scores[setNumber].home = parseInt(scores[setNumber].home) }
      }
    })
  }
}

/**
 * ammends the scores
 * @param {object} match 
 */
const ammendScores_match = (match, _scores_flat) => {

  let { results_edit } = match
  const results = match ? match[_resultsProp] : undefined;

  let _res = results_edit ? copyObj(results_edit) : copyObj(results)

  let { score, scores, _status } = _res ?? {}
  let { setsToWin } = _status ?? {}
  const stw = setsToWin ? setsToWin : 3

  if (!_res) { _res = {} }

  if (!scores && _scores_flat) {
    scores = _scores_flat
    _res.scores = _scores_flat
  }

  if (scores) {
    if (!score) {
      score = {
        home: 0,
        away: 0
      }
    }

    score.home = 0
    score.away = 0

    fixScores(scores)

    const maxPoints = 25
    const maxPoints5 = 15

    _.each(scores, (score, setIndex) => {

      let set_index = parseInt(setIndex) + 1

      if (set_index <= 4) {
        if (score.home > maxPoints && score.away < score.home && score.away !== 0) { score.away = score.home - 2 }
        if (score.away > maxPoints && score.home < score.away && score.home !== 0) { score.home = score.away - 2 }
      } else {
        if (score.home > maxPoints5 && score.away < score.home && score.away !== 0) { score.away = score.home - 2 }
        if (score.away > maxPoints && score.home < score.away && score.home !== 0) { score.home = score.away - 2 }
      }
    })

    let set_number = 0

    // loop the scores
    _.each(scores, (scores_set, setIndex) => {

      set_number++

      const h = parseInt(scores_set.home, 10)
      const a = parseInt(scores_set.away, 10)

      if ((h > 0) || (a > 0)) { set_number = parseInt(setIndex) + 1 }

      if (!_res.setWinners) { _res.setWinners = {} }

      // a team has more than 25 points and the diff > 2
      if (setIndex <= 4) {
        if (((h >= maxPoints) || (a >= maxPoints)) && Math.abs(h - a)) {
          if (h > a) { score.home++ }
          if (h < a) { score.away++ }
          _res.setWinners[setIndex] = (h > a) ? 'home' : 'away'
        }
      } else {
        if (((h >= maxPoints5) || (a >= maxPoints5)) && Math.abs(h - a)) {
          if (h > a) { score.home++ }
          if (h < a) { score.away++ }
          _res.setWinners[setIndex] = (h > a) ? 'home' : 'away'
        }
      }
    })

    _res.setsCompleted = score.home + score.away

    ammendScoreDisplays(match, _res, score, scores, set_number)

    _res.score = score
    _res.hasScores = true
    _res.setNumber = set_number

    _res.scoresCompleted = Object.keys(scores).length

    const pointsToWinSet = set_number === maxPoints5 ? set_number : maxPoints

    try {
      _res.set_complete_possible = (_res.scores && _res.scores[set_number] && _res.scores[set_number].home >= pointsToWinSet) || (_res.scores[set_number].away >= pointsToWinSet) ? true : false
    } catch (error) {

    }
    _res.match_complete_possible = (_res.score.home >= stw) || (_res.score.away >= stw) ? true : false

    if (_res.inProgress && !_res.complete) { _res.scoreDisplays.normal = _res.scoreDisplays.set }

    if (_res.scores) {
      let _scoreDisplayScores = ''
      let _scoreDisplayScores_flip = ''
      Object.keys(_res.scores).forEach((setNumber, index) => {
        const rs = _res.scores[setNumber]
        _scoreDisplayScores += rs.away + '-' + rs.home
        _scoreDisplayScores_flip += rs.home + '-' + rs.away
        if (index < (Object.keys(_res.scores).length - 1)) {
          _scoreDisplayScores += ', '
        }
        _res.scoreDisplays.scores = _scoreDisplayScores
        if (match._flipHa) {
          _res.scoreDisplays_flip.scores = _scoreDisplayScores
        }
      })
    }

    _res.officialResult = true

  }

  match[_resultsProp] = _res

}


/**
 * Ammends the match
 * @param {object} match 
 */
const ammendMatchDate = (match) => {

  // update match date and time
  if (match.startDate && match.startTime) {

    const matchDate = new Date(match.startDate);
    const todaysDate = new Date()
    const matchTime = parseInt(match.startTime.substring(0, 1), 10) + 12

    const realMatchDate = new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate())
    const realTodaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate())

    const realMatchDateAndTime = new Date(matchDate.getFullYear(), matchDate.getMonth(), matchDate.getDate(), matchTime, 0, 0)
    const realTodaysDateAndTime = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds())

    match.startDate = formatItem(formatTypes.shortestDate, matchDate)

    match.startTimeUTC = realMatchDateAndTime
    match.startDateUTC = realMatchDate

    const today = new Date()
    const yesterday = today.setDate(today.getDate() - 1)

    match._status.isPast = new Date(matchDate) <= yesterday

    if (realMatchDate <= realTodaysDate) {
      match._status.isToday = true
    }

    if (match.home && match.away) {
      match._status.matchName = match.away.name + ' @ ' + match.home.name
    }

    if (match.away && match.away.name === 'BYE') {
      match._status.isBye = true
    }
  } else if (match.startDateUTC && match.startTimeUTC) {
    match.startDate = formatItem(formatTypes.shortestDate, match.startDateUTC)
  }
}

// export const matchCountdown = (match) => {

//   const matchTime = match.realDateAndTime
//   const todaysDate = new Date()
//   const realTodaysDateAndTime = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds())

//   let diff = differenceInSeconds(matchTime, realTodaysDateAndTime)
//   diff = Math.floor(diff);

//   let days = Math.floor(diff / (24 * 60 * 60))
//   let leftSec = diff - days * 24 * 60 * 60

//   let hrs = Math.floor(leftSec / (60 * 60));
//   leftSec = leftSec - hrs * 60 * 60;

//   let min = Math.floor(leftSec / (60));
//   leftSec = leftSec - min * 60;

//   if (hrs < 10) { hrs = '0' + hrs }
//   if (min < 10) { min = '0' + min }
//   if (leftSec < 10) { leftSec = '0' + leftSec }

//   return hrs + ':' + min + ':' + leftSec

// }

