import React, { useContext, useEffect, useState } from 'react';
import { Button, Label, Segment } from 'semantic-ui-react';
import { AppAccessContext } from '../../../../global/cnr/contexts/AppAccessContext';
import { uniqueKey } from '../../../../global/common/keys';
import { NumberPad, numberPadTypes } from '../../../../global/components/forms/elements/NumberPad';
import UiRequestAccess from '../../../../global/pageItem/modification/userActions/UiRequestAccess';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { SportsRtMatchContext } from '../../cnr/contexts/SportsRtMatchContext';
import { SportsSeasonContext, _resultsProp } from '../../cnr/contexts/SportsSeasonContext';
import { gameScoringTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { matchCalcs } from '../../helpers/match';
import GameScoring from './GameScoring';
import MatchScoreKeypad, { _keypadTypes } from './MatchScoreKeypad';
import MatchScoreTable from './MatchScoreTable';

const MatchItemScoring = (props) => {

  const { setScoring } = props

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext
  const { gameScoringType } = sportsSeason_state ?? {}

  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { match_edit, matchAllows } = sportsEditMatch_state ?? {}
  const { scores: allowScoreEdits } = matchAllows ?? {}

  // sportsMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state } = sportsRtMatchContext ?? {}
  const { match_rt: rtm } = sportsRtMatch_state ?? {}

  // appAccessContext
  const appAccessContext = useContext(AppAccessContext)
  const { appAccess_fns } = appAccessContext ?? {}

  const [showRequest, setShowRequest] = useState()
  const [currentScoringSet, setCurrentScoringSet] = useState(1)

  const { results_edit, _flipHa } = match_edit ?? {}
  const results = match_edit ? match_edit[_resultsProp] : undefined;
  const rez = results_edit && (results_edit.scores || results_edit.score) ? results_edit : results
  const { score: score_match, complete } = rez ?? {}

  useEffect(() => {
    if (!results_edit) {
      sportsEditMatch_handlers.handleAmmend_editMatch(match_edit, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const handleScoreChange = (e, data) => {
    const { value: score, opts } = data
    const { team, setNumber } = opts
    const cm = { ...match_edit }
    if (!cm.results_edit) { cm.results_edit = { setNumber: 1, score: { home: 0, away: 0 } } }
    if (!cm.results_edit.score) { cm.results_edit.score = { home: 0, away: 0 } }
    cm.results_edit.score[team] = score
    matchCalcs.calcVballMatch(cm, gameScoringType)
    sportsEditMatch_handlers.handleChange_score(score, team, setNumber)
  }

  const handleScorePad = (e, team, score) => {
    const cm = { ...match_edit }
    e.preventDefault()
    e.stopPropagation()
    console.log('match_edit', match_edit)
    if (!cm.results_edit) { cm.results_edit = { setNumber: 1, score: { home: 0, away: 0 } } }
    if (!cm.results_edit.score) { cm.results_edit.score = { home: 0, away: 0 } }
    cm.results_edit.score[team] = score
    matchCalcs.calcVballMatch(cm, gameScoringType)
    sportsEditMatch_handlers.handleAmmend_editMatch(cm)
  }

  const scoreLabel = (score, team) => <Label key={uniqueKey('mis', 'sc', 'sl', team)} color={(complete ? 'green' : 'grey')}>{score}</Label>

  const teamScoreLabel = (team, score, allowSet, teamSetScore) => {
    const teamScore = score_match && score_match[team] ? score_match[team] : 0
    let color = teamScore === score ? 'blue' : 'black'
    let disabled = false
    if (score === 3) {
      if (team === 'home') {
        if (score_match && score_match.away && score_match.away === 3) {
          disabled = true
        }
      } else {
        if (score_match && score_match.home && score_match.home === 3) {
          disabled = true
        }
      }
    }

    const lineScoring = () => <NumberPad value={teamSetScore} onChange={handleScoreChange} numberPadType={numberPadTypes.grid} opts={{ team, setNumber: score, disabled: !allowSet, noSize: true }} />

    return setScoring ?
      lineScoring()
      :
      <Label
        key={uniqueKey('mis', 'l', team, score)}
        color={(disabled ? 'grey' : color)}
        onClick={(e) => { !disabled && handleScorePad(e, team, score) }}>
        {score}
      </Label>
  }

  // 0 - 1 - 2 - 3
  const teamScoreColumn = (team, name) => {

    const max = 4
    const divs = []
    const teamScore = score_match && score_match[team] ? score_match[team] : 0

    divs.push(<div key={uniqueKey('mis', team, 'n')}>{name}</div>)
    divs.push(scoreLabel(teamScore, team))

    switch (gameScoringType) {
      case gameScoringTypes.match:
      case gameScoringTypes.matchPoints:

        divs.push(<div key={uniqueKey('mis', team, 's')}>{'Sets'}</div>)

        const divvs = Array.from({ length: max }, (_, index) => teamScoreLabel(team, index));
        const _divs = [...divs, ...divvs]

        return <div key={uniqueKey('mis', team, 'ts')} className={'team-score'}>{_divs}</div>

      case gameScoringTypes.half:
        return <div key={uniqueKey('mis', team, 'ts')} className={'team-score'}>{divs}</div>

      case gameScoringTypes.quarter:
        return <div key={uniqueKey('mis', team, 'ts')} className={'team-score'}>{divs}</div>

      default:
      // nothing
    }
  }

  const teamScoringColumns = () => currentScoringSet ?
    <MatchScoreKeypad keypadType={_keypadTypes.normal} /> :
    <MatchScoreKeypad keypadType={setScoring ? _keypadTypes.realtime : _keypadTypes.normal} />

  // scores - score - scores
  const teamScoring = () => {
    switch (gameScoringType) {
      case gameScoringTypes.match:
      case gameScoringTypes.matchPoints:
        return <React.Fragment>
          <MatchScoreTable match_edit={match_edit} />
          {teamScoringColumns()}
        </React.Fragment>
      case gameScoringTypes.half:
      case gameScoringTypes.quarter:
        return <GameScoring match_edit={match_edit} />
      default:
      // nothing
    }
  }

  // scores - score - scores
  const teamScores = () => {
    if (!_flipHa) {
      return <div className={'team-scores'}>
        {teamScoreColumn('away', match_edit.away.name)}
        {teamScoreColumn('home', match_edit.home.name)}
      </div>
    } else {
      return <div className={'team-scores'}>
        {teamScoreColumn('home', match_edit.home.name)}
        {teamScoreColumn('away', match_edit.away.name)}
      </div>
    }
  }

  const request = () => <Segment.Group>
    <Segment>{'You do not have permission to update this match'}{'?'}</Segment>
    <Segment textAlign={'right'}>
      <Button size={'small'} color={'blue'} onClick={(e) => { setShowRequest(true) }}>Request Access</Button>
      <Button size={'small'} onClick={(e) => { setShowRequest() }}>Close</Button>
    </Segment>
  </Segment.Group>

  const matchScoringUpdate = () => {
    if (!allowScoreEdits) {
      return showRequest ? requestAccess() : request()
    } else {
      return <div className={'scoring-update'}>
        {setScoring ? teamScoring() : teamScores()}
      </div>
    }
  }

  const requestAccess = () => <UiRequestAccess direct={true} directCancel={setShowRequest} />

  return matchScoringUpdate()
}

export default MatchItemScoring

