import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { sportsEditMatchHandlers, sportsEditMatchInitialState, sportsEditMatchReducer } from "../reducers/SportsEditMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsSeasonContext, _resultsProp } from "./SportsSeasonContext";
import { SportsMatchContext } from "./SportsMatchContext";

export const SportsEditMatchContext = React.createContext()

export default function SportsEditMatchProvider(props) {

  const { sportsMatchViewType, fromLevelDates } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { appUsers } = appUser_state ?? {}
  const { pathViews, pathName, viewKey } = paps_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const appUserInfo = appUserAccess ? { displayName: appUserAccess.displayName, uid: appUserAccess.uid } : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { gameScoringType, latestSeason, rtSettings, sportsKey, viewItem, matches_info, parentDataKeys, sportPermissions } = sportsSeason_state ?? {}
  const { playoffMatches } = matches_info ?? {}
  const { matches_seasonal: pk_matches_seasonal } = parentDataKeys ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)

  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true } = sportsMatch_state ?? {}

  // const init_statee = {
  //   appUserAccess,
  //   fromLevelDates,
  //   gameScoringType,
  //   latestSeason,
  //   pathName,
  //   pathViews,
  //   pk_matches_seasonal,
  //   playoffMatches,
  //   rtSettings,
  //   sportsKey,
  //   sportsMatchViewType,
  //   sportsSeason_handlers,
  //   viewItem,
  //   viewKey,
  // }

  console.log('sportsKey', sportsKey)

  const init_state = {
    appUserInfo,
    gameScoringType,
    latestSeason,
    pathViews,
    playoffMatches,
    rtSettings,
    sportsKey,
    sportPermissions
  }
  const [sportsEditMatch_state, sportsMatchRealtime_dispatch] = useReducer(sportsEditMatchReducer, init_state, sportsEditMatchInitialState)
  const sportsEditMatch_handlers = sportsEditMatchHandlers(sportsMatchRealtime_dispatch, sportsEditMatch_state)

  useEffect(() => {
    if (match_true) {
      sportsEditMatch_handlers.handleSet_editMatch(match_true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_true]);

  return <SportsEditMatchContext.Provider value={{ sportsEditMatch_state, sportsEditMatch_handlers, componentContexts }}>
    {props.children}
  </SportsEditMatchContext.Provider>
}