import { gEnums } from "../../../enums/globalEnums";
import { fsfn_notifications } from "../../../functions/fbNotifications";
import { signInResponseTypes } from "../SignInReducer";

export const sendPushToAdmin = (profileData, creds, adminNotifications, signInResponseType, validationStatus) => {

  const { alertAdminOnUpdates, alertAdminOnSignIn, alertAdminOnAuthError } = adminNotifications ?? {}
  const { email } = creds ?? {}
  const { displayName } = profileData ?? {}

  const _displayName = displayName ? displayName : email

  let nd;

  if (_displayName && alertAdminOnUpdates) {

    switch (signInResponseType) {

      case signInResponseTypes.signInSuccess:
        if (alertAdminOnSignIn) {
          nd = {
            title: _displayName ? _displayName + ' - logged in' : 'An app user has logged in',
            body: 'An app user with the email ' + email + ' has logged in'
          }
        }
        break;

      case signInResponseTypes.tooManyAttempts:
        if (alertAdminOnAuthError) {
          nd = {
            title: _displayName ? _displayName + ' - too many attempts' : 'An app user too many attempts',
            body: 'An app user with the email ' + email + ' has made too many attempts to log in.'
          }
        }
        break;

      case signInResponseTypes.signInError:
        if (alertAdminOnAuthError) {
          nd = {
            title: 'Sign in error',
            body: 'An app user with the email ' + email + ' had a sign-in error.'
          }
        }
        break;

      default:

        if (validationStatus) {
          if (alertAdminOnSignIn) {
            nd = {
              title: _displayName ? _displayName + ' - logged in' : 'An app user has logged in',
              body: 'An app user with the email ' + email + ' has logged in using email authentications'
            }
          }
        }
      // nothing
    }

    console.log('nd', nd)
    if (nd) {
      fsfn_notifications.sendNotificationByData(nd, {}, null, gEnums.adminTopicTypes.appUserLogin)
    }
  }
}