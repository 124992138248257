import { where } from 'firebase/firestore';
import _ from 'lodash';
import { _currentAppUserCountsCollection } from '../../../../global/cnr/reducers/AppUserReducer';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { convertHelpers } from '../../../../global/common/convert';
import { createRefPath, createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_update_doc } from '../../../../global/firestoreData/appData/fsData';
import { _projectAppNames } from '../../../../project/appConfiguration';
import { matchStatusTypes } from './SportsDataReducer';
import { _sportsOriginTypes } from './SportsReducer';
import { _matchSeasonalCollection } from './SportsSeasonReducer';
import { getSportsData_parentKeyCollections } from './reducerHelpers/seasonSportsData';
import { seasonalHelpers, seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';
import { backupData_district, updateFixedData_district, updateFixedData_teamAndMatches } from './reducerHelpers/sportsDataFix';
import { createSeasonalMatches, getDistrictData, getTeamsAndMatches, updateAllMatchesByLevel } from './reducerHelpers/sportsDistrictData';

const dataItems_district = {
  schools: { collectionName: 'schools' },
  // schoolsByState: { collectionName: 'schoolsByState', noPrefix: true },
  gls_access: { collectionName: 'gls_access', noYear: true },
  gls_district: { collectionName: 'gls_district', noYear: true },
  gls_scores: { collectionName: 'gls_scores', noYear: true },
  sportsAccess_district: { collectionName: 'sportsAccess_district', noYear: true },
  sportsAccess_schools: { collectionName: 'sportsAccess_schools', noYear: true },
  // sportsAccess_sports: { collectionName: 'sportsAccess_sports', noYear: true },
  sports: { collectionName: 'sports', noYear: true },
}

export const _district_glsCollections = {
  gls_access: 'gls_access',
  gls_district: 'gls_district',
  gls_teams: 'gls_teams',
}

export const _districtDashboardTypes = {
  menu: 'menu',
  matchesBySport: 'matchesBySport',
  schools: 'schools',
  sports: 'sports',
  teams_bySchool: 'teams_bySchool',
  teams_bySport: 'teams_bySport',
}

export const _districtDataIssueTypes = {
  menu: 'menu',
  badSectionMatches: 'badSectionMatches',
  matchTeamsNotFound: 'matchTeamsNotFound',
  mismatchedMatchData: 'mismatchedMatchData',
  modifiedMatchTeams: 'modifiedMatchTeams',
  missingTeamObjects: 'missingTeamObjects',
  schoolMerge: 'schoolMerge',
}

export const _districtCollections = {
  _districts: '_districts',
  _globals: '_globals',
  _gls_access: '_gls_access',
  _gls_district: '_gls_district',
  _gls_scores: '_gls_scores',
  _history: '_history',
  _matches_seasonal: '_matches_seasonal',
  _rankings: '_rankings',
  _rosters: '_rosters',
  _schools: '_schools',
  _scores_seasonal: '_scores_seasonal',
  _seasonals: '_seasonals',
  _sports: '_sports',
  _sportsAccess_district: '_sportsAccess_district',
  _sportsAccess_schools: '_sportsAccess_schools',
  _sportsAccess_sports: '_sportsAccess_sports',
  _teams: '_teams',
  _tournaments: '_tournaments',
  athletes: 'athletes',
  districts: 'districts',
  globals: 'globals',
  history: 'history',
  organizations: 'organizations',
  schools: 'schools',
  schoolsByState: 'schoolsByState',
  sports: 'sports',
  teams: 'teams',
}

export const _useSportsKey = true

const _districtProjectPaths = {
  meSports: 'clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports/',
  tsSports: 'clients/thumbstat/events/thumbstatSports/',
}

const rts = {
  handleAmmend_schoolsAccess: 'handleAmmend_schoolsAccess',
  handleAmmend_sportsAccess: 'handleAmmend_sportsAccess',
  handleBackup: 'handleBackup',
  handleCreate_seasonal: 'handleCreate_seasonal',
  handleFixData: 'handleFixData',
  handleFixTeamAndMatches: 'handleFixTeamAndMatches',
  handleGet_alternateTeamsAndMatches: 'handleGet_alternateTeamsAndMatches',
  handleGet_currentUsers: 'handleGet_currentUsers',
  handleGet_districtData: 'handleGet_districtData',
  handleGet_districtSeasonalData_alt: 'handleGet_districtSeasonalData_alt',
  handleGet_districtSeasonalData: 'handleGet_districtSeasonalData',
  handleInit_sportsDistrict: 'handleInit_sportsDistrict',
  handleResults_sportsDistrict: 'handleResults_sportsDistrict',
  handleSet_currentUsers: 'handleSet_currentUsers',
  handleSet_districtData: 'handleSet_districtData',
  handleSet_districtSeasonalData: 'handleSet_districtSeasonalData',
  handleSet_districtSeasonalDataExisting: 'handleSet_districtSeasonalDataExisting',
  handleSet_sportsDistrict: 'handleSet_sportsDistrict',
  handleSet_sportSeasonData: 'handleSet_sportSeasonData',
  handleSet_sportSeasonMatches: 'handleSet_sportSeasonMatches',
  handleSet_sportsProps: 'handleSet_sportsProps',
  handleSplitMatchesByLevel: 'handleSplitMatchesByLevel',
  handleUpdate_gsMatchesDistrict: 'handleUpdate_gsMatchesDistrict',
  ...grts,
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsDistrictReducer = (state, action) => {

  const { firestore_fns, pathViews, dataParents, districtData, sportsAccess_district } = state
  const { type, dispatch } = action
  let _sportsAccess_district = sportsAccess_district

  const sportsDistrict_handlers = sportsDistrictHandlers(dispatch)

  const { handleFunctionResponse, handleResults_sportsDistrict, handleSet_districtData, handleSet_sportSeasonMatches, handleSet_currentUsers, handleSet_sportsDistrict, handleSet_districtSeasonalData } = sportsDistrict_handlers

  switch (type) {

    case rts.handleInit_sportsDistrict:
      const _refPathM = createRefPath_event(pathViews, ['districts', pathViews.districts])
      firestore_fns.handle_getDoc(_refPathM, handleSet_sportsDistrict)

      const { selectedSeason } = action
      const additionalDataParents = []
      const pkcProps = { pathViews, dataItems: dataItems_district, dataParents, additionalDataParents, selectedSeason, handleSetDataResults: handleResults_sportsDistrict }
      getSportsData_parentKeyCollections(_sportsOriginTypes.district, pkcProps)
      return { ...state }

    // handles the result when all the data from the parentKeys are collected
    case rts.handleResults_sportsDistrict:

      const { dataResult, parentDataKeys } = action

      const _rstz = {}

      Object.keys(dataItems_district).forEach(diKey => {
        _rstz[diKey] = dataResult[diKey]
        switch (diKey) {
          case 'schools':
            convertHelpers.createItemKeys(_rstz[diKey])
            break;
          default:
          // nothing
        }
      })

      const _sports = {}
      if (!_useSportsKey && _rstz && _rstz.sports) {
        if (_rstz.sports) {
          Object.keys(_rstz.sports).forEach(key => {
            const _sport = _rstz.sports[key]
            const { name } = _sport ?? {}
            _sports[_.camelCase(name)] = _rstz.sports[key]
          })
        }
        _rstz.sports = _sports
      }

      // const accessAndSheets = ammendDistrictAccess(_results)

      return {
        ...state,
        ..._rstz,
        parentDataKeys,
      }

    case rts.handleSet_sportsProps:
      return { ...state, sports_all: action.sports_all, sportsKey: action.sportsKey, subCollection: action.subCollection }

    case rts.handleGet_currentUsers:

      const cbcau = (data) => {
        if (data) {
          handleSet_currentUsers(data)
        }
      }
      const _refPathCau = createRefPath_event(pathViews, [_currentAppUserCountsCollection])
      fs_get_data({ refPath: _refPathCau, callback: cbcau, opts: { ignoreId: true } })
      return { ...state }

    case rts.handleSet_currentUsers:
      let _currentUserCount = 0
      const { currentUserCounts } = action
      if (currentUserCounts) {
        _.each(currentUserCounts, (value, index) => {
          _currentUserCount += _.sum(Object.values(value));
        })
      }


      console.log('_currentUserCount', _currentUserCount)
      return { ...state, currentUserCounts: action.currentUserCounts, currentUserCount: _currentUserCount }

    case rts.handleSet_districtSeasonalDataExisting:
      return { ...state, seasonalDataType: action.seasonalDataType }

    case rts.handleGet_districtSeasonalData:
      getDistrictSeasonalData(action, pathViews, handleSet_districtSeasonalData)
      return { ...state, seasonalDataType: action.seasonalDataType }

    case rts.handleGet_districtSeasonalData_alt:
      getDistrictSeasonalData(action, pathViews, handleSet_districtSeasonalData, true)
      return { ...state, seasonalDataType: action.seasonalDataType }

    case rts.handleSet_districtSeasonalData:
      const { seasonalDataType: seasonalDataType_state } = state
      const { isAlt } = action
      const _seasonalDatas = { ...state.seasonalDatas }
      const _nonSeasonalDatas = { ...state.nonSeasonalDatas }
      switch (seasonalDataType_state) {
        case _districtCollections._matches_seasonal:
        case _districtCollections._rankings:
        case _districtCollections._rosters:
        case _districtCollections._scores_seasonal:
        case _districtCollections._seasonals:
        case _districtCollections._teams:
        case _districtCollections._tournaments:
          const syd = getSeasonSportYearsData(action.seasonalData, state.sports_all, state.sportsKey)
          _seasonalDatas[seasonalDataType_state] = syd
          if (isAlt) {
            return { ...state, seasonalDatas_alt: _seasonalDatas }
          } else {
            return { ...state, seasonalDatas: _seasonalDatas }
          }
        default:
          _nonSeasonalDatas[seasonalDataType_state] = action.seasonalData
          if (isAlt) {
            return { ...state, nonSeasonalDatas_alt: _nonSeasonalDatas }
          } else {
            return { ...state, nonSeasonalDatas: _nonSeasonalDatas }
          }
      }

    case rts.handleSet_sportSeasonData:
      const { seasonalDataType, subCollection } = state
      const { _itemKey } = action.sportSeasonData ?? {}
      const _refPath = subCollection ? createRefPath_event(pathViews, [seasonalDataType, _itemKey, subCollection]) : createRefPath_event(pathViews, [seasonalDataType, _itemKey])
      fs_get_data({ refPath: _refPath, callback: handleSet_sportSeasonMatches })
      return { ...state, sportSeasonData: action.sportSeasonData }

    case rts.handleSet_sportSeasonMatches:
      return { ...state, sportSeasonMatches: action.sportSeasonMatches, updating: false }

    case rts.handleSet_sportsDistrict:
      const { district } = action
      const { sports } = district ? district : {}
      return { ...state, district, sports }

    case rts.handleUpdate_gsMatchesDistrict:

      const { matchDataUpdates } = state
      const { filterType, latestSeason, sportsKey, forPlayoff } = action

      const _cb = (res) => {
        const response_data = { success: true }
        handleFunctionResponse(response_data, dispatch)
      }

      const _updateProps = {
        callback: _cb,
        collectionName: _matchSeasonalCollection,
        forPlayoffs: false,
        matchDataUpdates: matchDataUpdates,
        pathViews,
        pks: seasonalHelpers.getPks(pathViews, latestSeason, sportsKey),
        seasonalUpdateType: seasonalUpdateTypes.matchesList,
      }

      if (matchDataUpdates) {
        if (filterType) {
          switch (filterType) {
            case matchStatusTypes.allUpdates:
              seasonalHelpers.updateAllSeasonalMatchesByLevel(_updateProps)
              break;
            default:
              const _matchDataUpdates = filterType ? matchDataUpdates[filterType] : {}
              if (_matchDataUpdates && Object.keys(_matchDataUpdates).length > 0) {
                _updateProps.dataToUpdate = _matchDataUpdates
                seasonalHelpers.updateSeasonalMatchesByLevel(_updateProps)
              }
          }
        } else {
          seasonalHelpers.updateAllSeasonalMatchesByLevel(_updateProps)
        }
      }

      return { ...state }

    case rts.handleGet_districtData:
      getDistrictData(pathViews, handleSet_districtData)
      return { ...state }

    case rts.handleSet_districtData:
      return { ...state, districtData: action.data }

    case rts.handleBackup:
      backupData_district(pathViews, action, districtData)
      return { ...state }

    case rts.handleFixData:
      updateFixedData_district(pathViews, action, districtData)
      return { ...state }

    case rts.handleFixTeamAndMatches:
      updateFixedData_teamAndMatches(pathViews, districtData)
      return { ...state }

    case rts.handleSplitMatchesByLevel:
      const cb = (r) => {
        console.log('r', r)
      }
      updateAllMatchesByLevel(pathViews, districtData, cb)
      return { ...state }

    case rts.handleCreate_seasonal:
      const cbs = (r) => {
        console.log('r', r)
      }
      createSeasonalMatches(pathViews, districtData, cbs)
      return { ...state }

    case rts.handleAmmend_schoolsAccess:
      if (!_sportsAccess_district) { _sportsAccess_district = {} }
      if (!_sportsAccess_district.schools) { _sportsAccess_district.schools = {} }
      const { access_schools } = action ?? {}
      if (access_schools) { _sportsAccess_district.schools = access_schools }
      return { ...state, sportsAccess_district: _sportsAccess_district, updating: false }

    case rts.handleAmmend_sportsAccess:
      if (!_sportsAccess_district) { _sportsAccess_district = {} }
      if (!_sportsAccess_district.sports) { _sportsAccess_district.sports = {} }
      const { access_sports } = action ?? {}
      if (access_sports) { _sportsAccess_district.sports = access_sports }
      return { ...state, sportsAccess_district: _sportsAccess_district, updating: false }

    case rts.handleGet_alternateTeamsAndMatches:
      const x = getTeamsAndMatches(action.mergeItems, districtData)
      const _districtData = { ...districtData }
      _districtData.mergeData = x
      return { ...state, districtData: _districtData }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation }, { gsMatchedRefreshed: false })

    default:
      return { ...state }
  }
}

export const sportsDistrictHandlers = (dispatch) => {
  return {
    handleAmmend_schoolsAccess: (access_schools) => { dispatch({ type: rts.handleAmmend_schoolsAccess, dispatch, access_schools }) },
    handleAmmend_sportsAccess: (access_sports) => { dispatch({ type: rts.handleAmmend_sportsAccess, dispatch, access_sports }) },
    handleBackup: (currentItemKey, selectedSport, selectedYear, forPlayoff) => { dispatch({ type: rts.handleBackup, dispatch, currentItemKey, selectedSport, selectedYear, forPlayoff }) },
    handleCreate_seasonal: () => { dispatch({ type: rts.handleCreate_seasonal, dispatch }) },
    handleFixData: (currentItemKey) => { dispatch({ type: rts.handleFixData, dispatch, currentItemKey }) },
    handleFixTeamAndMatches: () => { dispatch({ type: rts.handleFixTeamAndMatches, dispatch }) },
    handleGet_alternateTeamsAndMatches: (mergeItems) => { dispatch({ type: rts.handleGet_alternateTeamsAndMatches, mergeItems }) },
    handleGet_currentUsers: () => { dispatch({ type: rts.handleGet_currentUsers, dispatch }) },
    handleGet_districtData: () => { dispatch({ type: rts.handleGet_districtData, dispatch }) },
    handleGet_districtSeasonalData: (seasonalDataType, itemKey) => { dispatch({ type: rts.handleGet_districtSeasonalData, dispatch, seasonalDataType, itemKey }) },
    handleGet_districtSeasonalData_alt: (seasonalDataType, itemKey) => { dispatch({ type: rts.handleGet_districtSeasonalData_alt, dispatch, seasonalDataType, itemKey }) },
    handleInit_sportsDistrict: () => { dispatch({ type: rts.handleInit_sportsDistrict, dispatch }) },
    handleResults_sportsDistrict: (dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys) => { dispatch({ type: rts.handleResults_sportsDistrict, dispatch, dataResult, pts, selectedSeason, matchDateGroups, parentDataKeys }) },
    handleSet_currentUsers: (currentUserCounts) => { dispatch({ type: rts.handleSet_currentUsers, dispatch, currentUserCounts }) },
    handleSet_districtData: (data) => { dispatch({ type: rts.handleSet_districtData, dispatch, data }) },
    handleSet_districtSeasonalData: (seasonalData, seasonalDataType, isAlt) => { dispatch({ type: rts.handleSet_districtSeasonalData, dispatch, seasonalData, seasonalDataType, isAlt }) },
    handleSet_districtSeasonalDataExisting: (seasonalDataType) => { dispatch({ type: rts.handleSet_districtSeasonalDataExisting, dispatch, seasonalDataType }) },
    handleSet_sportsDistrict: (district) => { dispatch({ type: rts.handleSet_sportsDistrict, district }) },
    handleSet_sportSeasonData: (sportSeasonData) => { dispatch({ type: rts.handleSet_sportSeasonData, dispatch, sportSeasonData }) },
    handleSet_sportSeasonMatches: (sportSeasonMatches) => { dispatch({ type: rts.handleSet_sportSeasonMatches, dispatch, sportSeasonMatches }) },
    handleSet_sportsProps: (sports_all, sportsKey, subCollection) => { dispatch({ type: rts.handleSet_sportsProps, dispatch, sports_all, sportsKey, subCollection }) },
    handleSplitMatchesByLevel: () => { dispatch({ type: rts.handleSplitMatchesByLevel, dispatch }) },
    handleUpdate_gsMatchesDistrict: (filterType, latestSeason, sportsKey) => { dispatch({ type: rts.handleUpdate_gsMatchesDistrict, dispatch, filterType, latestSeason, sportsKey }) },
    ...responseHandlers(dispatch),
  }
}

export const sportsDistrictInitialState = (init_state) => {
  return { ...init_state, currents: {}, currentSidebars: {}, seasonalDatas: {}, nonSeasonalDatas: {} }
};

const ammendDistrictAccess = (_results) => {

  const { gls_access, gls_district, gls_scores, sportsAccess_district, sportsAccess_schools } = _results ?? {}
  const { schools: schools_district, sports } = sportsAccess_district ?? {}
  const { schools: schools_schools } = sportsAccess_schools ?? {}

  const gls = {
    access: gls_access,
    district: gls_district,
    scores: gls_scores,
  }

  const access = {
    district: {
      bySchool: schools_district,
      bySport: sports,
    },
    schools: {
      ...schools_schools,
    }
  }

  return { gls, access }
}

const getDistrictSeasonalData = (action, pathViews, callback, isAlt) => {

  const { seasonalDataType: seasonalDataType_action, itemKey } = action

  const cbdss = (data) => callback(data, seasonalDataType_action, isAlt)

  if (isAlt) {
    const _refPath_ts = itemKey ? createRefPath([seasonalDataType_action, itemKey], _districtProjectPaths.tsSports.substring(0, _districtProjectPaths.tsSports.length - 1)) : createRefPath([seasonalDataType_action], _districtProjectPaths.tsSports.substring(0, _districtProjectPaths.tsSports.length - 1))
    if (seasonalDataType_action.startsWith('_')) {
      const wheres_d = getWheres(pathViews)
      fs_get_data({ refPath: _refPath_ts, wheres: wheres_d, callback: cbdss, opts: { altDb: _projectAppNames.pojo } })
    } else {
      fs_get_data({ refPath: _refPath_ts, callback: cbdss, opts: { altDb: _projectAppNames.pojo } })
    }
  } else {
    const _refPath = itemKey ? createRefPath_event(pathViews, [seasonalDataType_action, itemKey]) : createRefPath_event(pathViews, [seasonalDataType_action])
    if (seasonalDataType_action.startsWith('_')) {
      const wheres_d = getWheres(pathViews)
      fs_get_data({ refPath: _refPath, wheres: wheres_d, callback: cbdss })
    } else {
      fs_get_data({ refPath: _refPath, callback: cbdss })
    }
  }

}

const getWheres = (pathViews) => {
  const wheres = [
    where('parentKeys.organizations', '==', pathViews.organizations),
    where('parentKeys.districts', '==', pathViews.districts),
  ]
  return wheres
}

const getDateValue = (dateValue) => {

  const seconds = dateValue.seconds;
  const nanoseconds = dateValue.nanoseconds / 1e9; // Convert nanoseconds to seconds
  const milliseconds = seconds * 1000 + nanoseconds;
  const utcDate = new Date(milliseconds);

  // Format the date as "mm/dd/yyyy"
  return utcDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

const getSeasonSportYearsData = (seasonalMatches, sports_all, sportsKey) => {
  let _sportsSeasons = null
  const groupedSeasons = _.groupBy(seasonalMatches, 'parentKeys.sports');
  if (groupedSeasons) {
    Object.keys(groupedSeasons).forEach(gsKey => {
      const groupedSeason = groupedSeasons[gsKey] // array
      if (groupedSeason) {
        groupedSeason.forEach(gs => {
          const { parentKeys, _itemKey } = gs ?? {}
          const { sports, sportsYears } = parentKeys ?? {}
          let allow = true
          if (sportsKey) {
            if (sportsKey !== sports) {
              allow = false
            }
          }
          if (allow) {
            const sport = sports_all[sports]
            const { name } = sport ?? {}
            if (!_sportsSeasons) { _sportsSeasons = {} }
            if (!_sportsSeasons[name]) { _sportsSeasons[name] = {} }
            if (!_sportsSeasons[name][sportsYears]) { _sportsSeasons[name][sportsYears] = {} }
            _sportsSeasons[name][sportsYears] = {
              sport: name,
              year: sportsYears,
              _itemKey
            }
          }
        })
      }
    })
  }
  return _sportsSeasons
}

const getSeasonMatches = (seasonalMatches, sports_all, sportsKey) => {
  let _sportsSeasons = null
  const groupedSeasons = _.groupBy(seasonalMatches, 'parentKeys.sports');
  if (groupedSeasons) {
    Object.keys(groupedSeasons).forEach(gsKey => {
      const groupedSeason = groupedSeasons[gsKey] // array
      if (groupedSeason) {
        groupedSeason.forEach(gs => {
          const { parentKeys, _itemKey } = gs ?? {}
          const { sports, sportsYears } = parentKeys ?? {}
          let allow = true
          if (sportsKey) {
            if (sportsKey !== sports) {
              allow = false
            }
          }
          if (allow) {
            const sport = sports_all[sports]
            const { name } = sport ?? {}
            if (!_sportsSeasons) { _sportsSeasons = {} }
            if (!_sportsSeasons[name]) { _sportsSeasons[name] = {} }
            if (!_sportsSeasons[name][sportsYears]) { _sportsSeasons[name][sportsYears] = {} }
            _sportsSeasons[name][sportsYears] = {
              sport: name,
              year: sportsYears,
              _itemKey
            }
          }
        })
      }
    })
  }
  return _sportsSeasons
}

const groupedAndCounted = (items, pathViews, updateDb) => {

  convertHelpers.createItemKeys(items)

  const _items = {}

  Object.keys(items).forEach(userKey => {
    const timeStamp = items[userKey]
    const dateValue = getDateValue(timeStamp)
    _items[userKey] = {
      userKey,
      timeStamp: timeStamp,
      createdDate: dateValue,
      updatedDate: dateValue
    }
  })

  const values = _.chain(_items)
    .groupBy((item) => {
      // Convert the seconds and nanoseconds components to a JavaScript Date 
      return getDateValue(item.timeStamp)
    })
    .map((items, date) => ({
      date,
      itemCount: items.length,
      items,
    }))
    .value();

  if (updateDb) {
    if (values) {
      values.forEach(v => {
        const { items } = v
        const _items = {}
        items.forEach(item => {
          const { userKey, createdDate, updatedDate, timeStamp } = item
          _items[userKey] = { createdDate, updatedDate, timeStamp }
        })
        v._items = _items
      })

      values.forEach(v => {
        const { date, _items } = v
        const dateS = date.replace(/\//g, "_")
        if (dateS !== 'Invalid Date') {
          const _refPathCu = createRefPath_event(pathViews, [_currentAppUserCountsCollection, dateS])
          const dataToUpdate = {
            currentUsers: _items
          }
          fs_update_doc(_refPathCu, _items)
        }
      })
    }
  }

  return values

}



function sumObjectValues(obj) {
  return _.sumBy(obj, value => {
    if (_.isPlainObject(value)) {
      return sumObjectValues(value);
    } else {
      return _.isNumber(value) ? value : 0;
    }
  });
}