import React, { createContext, useContext, useEffect, useState } from 'react';
import { gEnums } from '../../enums/globalEnums';
import { fs_get_data_all } from '../../firestoreData/appData/fsAppData';
import { currentHelpers } from '../../redirection/current';
import { PapsContext } from './PapsContext';

export const AppAccessContext = createContext();

const AppAccessProvider = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { pathViews } = paps_state ?? {}

  const [accessRequests, setAccessRequests] = useState({})

  useEffect(() => {
    fs_get_data_all(pathViews, '_accessRequests', setAccessRequests, { listen: true })
    // eslint-disable-next-line
  }, [])

  const appAccess_fns = () => {
    return {
      filterAccessRequests: filterAccessRequests,
      allowRequest: allowRequest,
    }
  }

  const filterAccessRequests = (view, viewKey) => {
    const arr = {}
    if (accessRequests) {
      // eslint-disable-next-line 
      Object.keys(accessRequests).map(arKey => {
        const accessRequest = accessRequests[arKey]
        delete accessRequest.id
        Object.keys(accessRequest).forEach(arItemKey => {
          const ar = accessRequest[arItemKey]
          if (ar.pathName) {
            const alp = currentHelpers.getLastPathView(ar.pathName)
            const { path, key } = alp ?? {}
            if (view === path && viewKey === key) {
              if (!arr[arItemKey]) { arr[arKey] = {} }
              arr[arKey][arItemKey] = ar
            }
          }
        })
      })
    }
    return arr
  }

  const allowRequest = (view, viewKey) => {
    let accessResponse = gEnums.accessStatusTypes.denied
    if (accessRequests) {
      // eslint-disable-next-line 
      Object.keys(accessRequests).map(arKey => {
        const accessRequest = accessRequests[arKey]
        delete accessRequest.id
        Object.keys(accessRequest).forEach(arItemKey => {
          const ar = accessRequest[arItemKey]
          if (ar.pathName) {
            const alp = currentHelpers.getLastPathView(ar.pathName)
            const { path, key } = alp ?? {}
            if (view === path && viewKey === key) {
              accessResponse = ar.status
            }
          }
        })
      })
    }
    return accessResponse
  }

  const provider = () => <AppAccessContext.Provider value={{ appAccess_state: { accessRequests }, appAccess_fns: appAccess_fns() }} >
    {props.children}
  </AppAccessContext.Provider >

  return accessRequests ? provider() : <div></div>

}

export default AppAccessProvider