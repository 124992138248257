import { where } from "firebase/firestore";
import _ from 'lodash';
import { copyObj } from "../../../global/common_web/copy";
import { createRefPath_event } from "../../../global/firestoreData/appData/appRefPaths";
import { fs_get_data } from "../../../global/firestoreData/appData/fsAppData";
import { doc_add, doc_delete, doc_update } from "../../../global/firestoreData/appData/fsData";
import { golfEnums } from "../enums/golfEnums";
import { _matchPlayPercs } from "../golfCalcs/calcMatchStatus";
import { geGCGHandicapIndex } from "../golfCalcs/courseHandicap";
import { createMatchScorecardsWithResults } from "../golfCalcs/createMatchScorecards";

// recharts
// https://www.usga.org/content/usga/home-page/handicapping/roh/Content/rules/Appendix%20C%20Handicap%20Allowances.htm

export const createGolfRoundMatches = async (paps_state, golfRoundKey, matches, handleDone) => {

  const { pathViews } = paps_state

  let _refPath = createRefPath_event(pathViews, ['golfTournaments', pathViews.golfTournaments, 'golfMatches'])
  const _where = [where('tournamentRounds', '==', golfRoundKey)]

  // get the existing matches
  fs_get_data({ refPath: _refPath, wheres: _where }).then(existingMatches => {
    if (existingMatches && Object.keys(existingMatches).length > 0) {
      deleteExistingGolfMatchPromise(_refPath, existingMatches).then(dRes => {
        matchesAddPromise(_refPath, matches).then(rss => {
          // console.log('rss', rss)
          handleDone()
        })
      })
    } else {
      matchesAddPromise(_refPath, matches).then(rss => {
        // console.log('rss', rss)
        handleDone()
      })
    }
  })

  // getExistingGolfMatchPromise(gtRef, golfRoundKey).then(res => {
  //   const existingMatches = res[0] ? convertSnapshot(res[0]) : null

  //   if (existingMatches && Object.keys(existingMatches).length > 0) {
  //     deleteExistingGolfMatchPromise(gtRef, existingMatches).then(dRes => {
  //       matchesAddPromise(golfMatchRef, matches).then(rss => {
  //         // console.log('rss', rss)
  //         handleDone()
  //       })
  //     })
  //   } else {
  //     matchesAddPromise(golfMatchRef, matches).then(rss => {
  //       // console.log('rss', rss)
  //       handleDone()
  //     })
  //   }
  // })
}

export const updateGolfRoundMatches = async (paps_state, matches, handleDone) => {

  const { pathViews } = paps_state

  let _refPath = createRefPath_event(pathViews,
    ['golfTournaments',
      pathViews.golfTournaments,
      'golfMatches'
    ])

  matchesUpdatePromise(_refPath, matches).then(rss => {
    handleDone()
  })
}

export const createGolfRoundMatchesPreview = (golfTournament_state, golfRoundKey, addResults, callback) => {

  const { details, tournamentGolfCourses } = golfTournament_state
  const { golfers: golfers_tournament, lineups, rounds, teams } = details

  const roundData = rounds && rounds[golfRoundKey] ? rounds[golfRoundKey] : null

  const wins = {}

  if (roundData) {

    const { numberOfMatches, lineups: roundLineups, golfersPerTeam, golfCourse: golfCourseKey } = roundData ?? {}

    const team1LineupKey = roundLineups ? roundLineups.team1 : null
    const team2LineupKey = roundLineups ? roundLineups.team2 : null

    if (team1LineupKey && team2LineupKey) {
      const lineup_team1 = lineups && lineups.team1 && lineups.team1[team1LineupKey] ? lineups.team1[team1LineupKey] : null
      const lineup_team2 = lineups && lineups.team2 && lineups.team2[team2LineupKey] ? lineups.team2[team2LineupKey] : null

      if (lineup_team1 && lineup_team2) {

        if (golfers_tournament && numberOfMatches && lineup_team1 && lineup_team2) {

          const golfers_team1 = _.orderBy(lineup_team1, 'position')
          const golfers_team2 = _.orderBy(lineup_team2, 'position')

          const golferGroups_team1 = golfers_team1 && golfersPerTeam > 1 ? _.groupBy(golfers_team1, 'groupNumber') : _.groupBy(golfers_team1, 'position')
          const golferGroups_team2 = golfers_team2 && golfersPerTeam > 1 ? _.groupBy(golfers_team2, 'groupNumber') : _.groupBy(golfers_team2, 'position')

          if (golferGroups_team1 && golferGroups_team2 && !golferGroups_team1.undefined && !golferGroups_team2.undefined) {
            const matches = {}

            // loop: create a mathch for each of the match numbers
            for (var matchNumber = 0; matchNumber < numberOfMatches; matchNumber++) {

              const matchGolfers_team1 = golferGroups_team1[matchNumber]
              const matchGolfers_team2 = golferGroups_team2[matchNumber]

              const team1_matchGolfers = updateTeamGolfersMatchInfo(golfers_tournament, matchGolfers_team1, golfCourseKey, tournamentGolfCourses, golfRoundKey)
              const team2_matchGolfers = updateTeamGolfersMatchInfo(golfers_tournament, matchGolfers_team2, golfCourseKey, tournamentGolfCourses, golfRoundKey)

              const tgc = tournamentGolfCourses ? Object.values(tournamentGolfCourses)[0] : {}
              const { holes } = tgc ? tgc : {}

              let hcpCouseLow = 100

              hcpCouseLow = getLowCourseHcp(team1_matchGolfers, hcpCouseLow)
              hcpCouseLow = getLowCourseHcp(team2_matchGolfers, hcpCouseLow)

              const _match = {
                matchNumber: matchNumber + 1,
                round: golfRoundKey,
                team1: team1_matchGolfers,
                team2: team2_matchGolfers,
                lowCourseHandicap: hcpCouseLow
              }

              if (addResults) {
                const x = createMatchScorecardsWithResults(wins, _match, holes, matchNumber, teams)
                const { holeScores } = x ? x : {}
                if (holeScores) {
                  _match.holeScores = holeScores
                }
              }

              matches[matchNumber] = _match
            }

            callback({ previewSuccess: true, roundMatches: matches })

          }
        }
      }
    }
  }

  return false

}

const getLowCourseHcp = (tg, hcpCouseLow) => {
  Object.keys(tg).forEach(key => {
    const g = tg[key]
    const { courseHandicap, matchHandicap } = g
    if (parseInt(matchHandicap) < hcpCouseLow) {
      hcpCouseLow = parseInt(matchHandicap)
    }
  })
  return hcpCouseLow
}

export const getMatchHandicap = (courseHandicap, golfRoundKey) => {

  let _matchHandicap;

  switch (golfRoundKey) {
    case golfEnums.roundTypes.singles:
      _matchHandicap = courseHandicap ? Math.round(courseHandicap * _matchPlayPercs.singles) : '-'
      break;
    case golfEnums.roundTypes.bestBall:
    case golfEnums.roundTypes.bestBall1:
    case golfEnums.roundTypes.bestBall2:
      _matchHandicap = courseHandicap ? Math.round(courseHandicap * _matchPlayPercs.bestBall) : '-'
      break;
    case golfEnums.roundTypes.alternateShot:
    case golfEnums.roundTypes.alternateShot1:
    case golfEnums.roundTypes.alternateShot2:
      // this will be figured out after
      _matchHandicap = courseHandicap
      break;
    default:
      _matchHandicap = courseHandicap ? courseHandicap : '-'
  }

  return _matchHandicap

}

/**
 * 
 * @param {object} golfers_tournament 
 * @param {object} golfers_match_team 
 * @param {string} golfCourseKey 
 * @param {object} tournamentGolfCourses 
 * @param {string} golfRoundKey 
 * @returns 
 */
const updateTeamGolfersMatchInfo = (golfers_tournament, golfers_match_team, golfCourseKey, tournamentGolfCourses, golfRoundKey) => {

  const gfs = {}

  // loog through the match golfers for the team
  golfers_match_team.forEach(golfer => {

    const { key: key_golfer } = golfer

    let fullGolfer = golfers_tournament[key_golfer]

    const _fullGolfer = copyObj(fullGolfer)

    const _courseHandicap = geGCGHandicapIndex(false, _fullGolfer, golfCourseKey, tournamentGolfCourses)

    if (_fullGolfer) {
      gfs[key_golfer] = _fullGolfer
      gfs[key_golfer].courseHandicap = _courseHandicap ? _courseHandicap : '-'
      gfs[key_golfer].position = golfer.position

      gfs[key_golfer].matchHandicap = getMatchHandicap(_courseHandicap, golfRoundKey)

      delete gfs[key_golfer].teams
      delete gfs[key_golfer].team
      delete gfs[key_golfer].id
    }
  })
  return gfs
}

const deleteExistingGolfMatchPromise = (paths, existingMatches) => {
  const promises = []
  Object.keys(existingMatches).forEach(mKey => {
    const pr = doc_delete(paths + '/' + mKey)
    promises.push(pr)
    // const dRef = roundRef.collection('golfMatches').doc(mKey)
    // promises.push(dRef.delete())
  })
  return Promise.all(promises)
}

const matchesAddPromise = (paths, matches) => {
  const promises = []
  Object.keys(matches).forEach(key => {
    const pr = doc_add(paths + '/' + matches[key])
    promises.push(pr)
    // promises.push(golfMatchRef.add(matches[key]))
  })
  return Promise.all(promises)
}

const matchesUpdatePromise = (paths, matches) => {
  const promises = []
  if (_.isArray(matches)) {
    matches.forEach(match => {
      const pr = doc_update(paths + '/' + match.id, match)
      promises.push(pr)
    })
  } else {
    Object.keys(matches).forEach(key => {
      const pr = doc_update(paths + '/' + key, matches[key])
      promises.push(pr)
    })
  }

  return Promise.all(promises)
}


// export const createGolfMatches = (eventTeams, roundLineups, totalMatches) => {

//   console.log('roundLineups', roundLineups)

//   const matches = {}

//   if (totalMatches && roundLineups && eventTeams) {
//     // loop total matches
//     for (var matchNumber = 1; matchNumber <= totalMatches; matchNumber++) {

//       const match = {
//         matchNumber: matchNumber,
//         team1: null,
//         team2: null,
//       }

//       let hcpLow = 100

//       // loop lineups
//       Object.keys(roundLineups).forEach((teamKey, teamIndex) => {
//         const teamLineup = roundLineups[teamKey]

//         const golfers = createMatchTeamGolfers(match, teamLineup)

//         golfers.forEach(golfer => {
//           if (golfer.courseHandicap < hcpLow) {
//             hcpLow = golfer.courseHandicap
//           }
//         })

//         if (teamIndex === 0) {
//           match.team1 = matchTeam(eventTeams, teamKey)
//           match.team1.golfers = golfers
//         } else {
//           match.team2 = matchTeam(eventTeams, teamKey)
//           match.team2.golfers = golfers
//         }
//       })
//       match.lowCourseHandicap = hcpLow
//       matches['match' + matchNumber] = match
//     }
//   }
//   return matches
// }

// const matchTeam = (eventTeams, teamKey) => {
//   return {
//     teamKey: eventTeams[teamKey].itemKey,
//     name: eventTeams[teamKey].name,
//     color: eventTeams[teamKey].color,
//     leadsBy: 0,
//     holesWon: 0,
//     holesLost: 0,
//     holesHalved: 0
//   }
// }


// const tournamentLineupsGetPromise = (tournamentRoundRef, team1Lineup, team2Lineup) => {
//   const promises = []
//   let ref1 = tournamentRoundRef.collection('tournamentLineups').doc(team1Lineup)
//   let ref2 = tournamentRoundRef.collection('tournamentLineups').doc(team2Lineup)
//   let ref3 = tournamentRoundRef.collection('tournamentGolfers')
//   promises.push(ref1.get())
//   promises.push(ref2.get())
//   promises.push(ref3.get())
//   return Promise.all(promises)
// }
