import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from "../../auth/appUserAccessPermissions";
import { baseSettingsHandlers, baseSettingsInitialState, baseSettingsReducer } from "../reducers/BaseSettingsReducer";
import { AppUserContext } from "./AppUserContext";
import { PapsContext } from './PapsContext';
import { HomeSettingsContext } from './SettingsDataContext';

export const useAllSettingsFunctions = false
export const useSettingsCollection_get = true
export const useSettingsCollection_save = true

/** BaseSettingsProvider returns the default settings (from fsfn_functions) for the settings */
export const BaseSettingsContext = createContext();

/**
 *  BaseSettingsProvider returns the default settings (from fsfn_functions) for the settings
 * @param {object} props 
 * @returns - {hcceSettings, _baseSettings}
 */
const BaseSettingsProvider = (props) => {

  // appUserContext
  const appUserContext = useContext(AppUserContext);
  const { appUser_state } = appUserContext ?? {}
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel, isDataAdmin } = appUserAccess ?? {}

  const aua = appUserAccess ? { uid: appUserAccess.uid, displayName: appUserAccess.displayName } : { uid: 0, displayName: 'Unknown' }

  // papsContext 
  const papsContext = useContext(PapsContext)
  const { paps_state } = papsContext ?? {}
  const { appArea, pathViews } = paps_state ?? {}

  // homeSettingsContext
  const homeSettingsContext = useContext(HomeSettingsContext);
  const { homeSettings_state } = homeSettingsContext ?? {}
  const { homeSettings } = homeSettings_state ?? {}
  const { global } = homeSettings ?? {}
  const { settingsConsole: settingsConsole_home_global } = global ?? {}

  const init_state = { accessLevel, settingsConsole_home_global, aua, pathViews }
  const [baseSettings_state, baseSettingsDispatch] = useReducer(baseSettingsReducer, baseSettingsInitialState(init_state));
  const baseSettings_handlers = baseSettingsHandlers(baseSettingsDispatch)
  const { baseSettings } = baseSettings_state ?? {}

  // only get allSettingsApp if the user has admin rights
  useEffect(() => {
    if (isDataAdmin) {
      baseSettings_handlers.handleBaseSettings_get()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess, isDataAdmin]);

  useEffect(() => {
    if (isDataAdmin && baseSettings && appArea) {
      baseSettings_handlers.handleGetBaseAppAreaSettings(appArea)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserAccess, baseSettings, appArea, isDataAdmin]);

  const providerContext = () => <BaseSettingsContext.Provider value={{ baseSettings_state, baseSettings_handlers }}>
    {props.children}
  </BaseSettingsContext.Provider>

  // if (providerNeeded) {
  //   return baseSettings ? providerContext() : <div></div>
  // } else {
  return providerContext()
  // }

}

export default BaseSettingsProvider