import { createRefPath, createRefPath_event } from '../../../global/firestoreData/appData/appRefPaths';
import { fs_set_doc } from '../../../global/firestoreData/appData/fsData';
import { dispatchProps } from '../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { _settingsFs } from '../../../global/viewSettings/actions/getSettings';
import { getViewSettingsBaseName } from '../../../global/viewSettings/helpers/settingsHelpers';

export const _globalCollectionName = '_globals'
export const _linkedDataCollectionName = '_dataLinks'

export const updatePageItemDataFilter = (isFilter, pathViews, viewItemKey, filterName, selectedFilters, dataRestrictions, dispatch) => {

  const settingsRoot = _settingsFs.root
  const baseSettingsName = getViewSettingsBaseName('events', pathViews)
  const _refPath = createRefPath([settingsRoot, baseSettingsName])

  const data = isFilter ?
    { viewItems: { [viewItemKey]: { dataFilters: { [filterName]: { filters: selectedFilters } } } } }
    :
    { viewItems: { [viewItemKey]: { dataFilters: { [filterName]: { filters: selectedFilters } } } } }

  if (dataRestrictions && (dataRestrictions.all || dataRestrictions.pageDataFilter)) {
    dispatch && dispatch({ type: dispatchProps.successAlt, dispatch })
    return true
  }

  console.log('_refPath', _refPath, data)
  // fs_set_doc(_refPath, data, true, dispatch)

}

/** adds (set) data to the `_globals` collection in the `globalName` doc */
export const updateGlobalDirect = (pathViews, globalName, itemKey, itemData, callback) => {

  const subGlobal = globalName.substring(1)
  const _refPath = createRefPath_event(pathViews, [globalName, subGlobal])

  const dataToUpdate = {
    [subGlobal]: {
      [itemKey]: { ...itemData }
    }
  }

  fs_set_doc(_refPath, dataToUpdate, true, callback)

}

/** adds (set) data to the `_globals` collection in the `globalName` doc */
export const updateGlobal = (pathViews, globalName, dataList, callback) => {
  const _refPath = createRefPath_event(pathViews, [_globalCollectionName, globalName])
  fs_set_doc(_refPath, dataList, true, callback)
}

/** adds (set) data to the `_globals` collection for each item in the `data` */
export const updateGlobals = (pathViews, data) => {
  Object.keys(data).forEach(key => {
    const _refPath = createRefPath_event(pathViews, [_globalCollectionName, key])
    fs_set_doc(_refPath, data[key], true)
  })
} 