import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { getFirstObject } from "../../../../global/common/convert";
import { sportsMatchHandlers, sportsMatchInitialState, sportsMatchReducer } from "../reducers/SportsMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsSeasonContext } from "./SportsSeasonContext";

export const SportsMatchContext = React.createContext()

export default function SportsMatchProvider(props) {

  const { sportsMatchViewType, fromLevelDates } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { appUser_fns } = fns ?? {}
  const { appUsers, accessClone } = appUser_state ?? {}
  const { pathViews, pathName, viewKey } = paps_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { matches_info, viewItem, latestSeason, sportsKey, gameScoringType, parentDataKeys, rtSettings, sportPermissions } = sportsSeason_state ?? {}
  const { playoffMatches, matches_realtime } = matches_info ?? {}
  const { matches_seasonal: pk_matches_seasonal } = parentDataKeys ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)

  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  const init_state = { sportPermissions, sportsMatchViewType, fromLevelDates, rtSettings, latestSeason, sportsSeason_handlers, appUserAccess, pathViews, pathName, viewItem, viewKey, pk_matches_seasonal, playoffMatches, sportsKey, gameScoringType }
  const [sportsMatch_state, sportsMatch_dispatch] = useReducer(sportsMatchReducer, init_state, sportsMatchInitialState)
  const sportsMatch_handlers = sportsMatchHandlers(sportsMatch_dispatch, sportsMatch_state)
  const { match_true } = sportsMatch_state ?? {}
  const { _itemKey } = match_true ?? {}

  const getMatchEditAllowance = () => {
    const vaProps = { match_true, sportsKey, accessClone }
    const _matchAllows = appUser_fns.validateAccess_allowScoring(vaProps)
    sportsMatch_handlers.handleSet_matchAllowances(_matchAllows)
  }

  useEffect(() => {
    getMatchEditAllowance()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [match_true]);

  useEffect(() => {
    // these are all of the realtimeMatches
    if (matches_realtime) {
      const rtm_currentMatch = matches_realtime ? matches_realtime[_itemKey] : {}
      if (rtm_currentMatch) {
        const rtm = getFirstObject(rtm_currentMatch)
        const { item } = rtm ?? {}
        const { displayName, results } = item ?? {}
        if (results) {
          sportsMatch_handlers.handleSet_realtimeMatchData({ match_rt: results, match_rt_scorer: displayName })
        } else {
          sportsMatch_handlers.handleSet_realtimeMatchData({ match_rt: null, match_rt_scorer: null })
        }
      } else {
        sportsMatch_handlers.handleSet_realtimeMatchData({ match_rt: null, match_rt_scorer: null })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, _itemKey]);

  return <SportsMatchContext.Provider value={{ sportsMatch_state, sportsMatch_handlers, componentContexts }}>
    {props.children}
  </SportsMatchContext.Provider>

}