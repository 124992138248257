import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { _showStates, consoleStart } from '../../../App';
import { isAppDeveloper } from "../../../project/appConfiguration";
import { gEnums } from "../../enums/globalEnums";
import { authHandlers, authPendingTypes, authReducer, authReducerInitialState } from '../reducers/AuthReducer';
import { startTypes } from "../reducers/StartReducer";
import { StartContext } from "./StartContext";

/**
@state auth_state(`authValidated`, `loggedIn`, `currentUser`, `noAuth`)
@handlers auth_handlers (`'handleSetAuth`, `handleSignOut`, `handleSignedOut`)
@contexts [StartContext]
 */
export const AuthContext = createContext();
/** Top level provider the App
@componentContexts [StartContext]
@provides auth_state, auth_handlers 
@description Returns the Auth object for the App. Will return a `PageDimmer` until the appUser && appUser.profileValidated is set  
*/
const AuthProvider = (props) => {

  // startContext
  const startContext = useContext(StartContext)
  const { start_handlers, start_fns } = startContext ?? {}
  const { handleAmmend_startDimmer } = start_handlers ?? {}

  // AuthReducer
  const initState = authReducerInitialState({ authValidated: false })
  const [auth_state, auth_dispatch] = useReducer(authReducer, initState);
  const auth_handlers = authHandlers(auth_dispatch, auth_state)

  const { authPendingType, authValidated, authIndex, signInResponse } = auth_state;
  const { emailValidationStatus } = signInResponse ?? {};

  if (_showStates.any && _showStates.auth) {
    console.log(Object.keys(auth_state))
    console.log(Object.keys(auth_handlers))
  }

  if (start_fns && start_fns.useTimeMeasurement) {
    start_fns.useTimeMeasurement('AuthProvider', authValidated)
  }

  // runs when `auth_state` changes 
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (!authValidated) {
        handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.authorizing)
        if (user) {
          const ua = {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            loggedIn: true,
            photoURL: user.photoURL,
            providerData: user.providerData,
            senderKey: user.uid,
            uid: user.uid,
            isAppDeveloper: isAppDeveloper(user.email),
            presenceStatus: 'online'
          }
          auth_handlers.handleSetAuth(ua) // sets authValidated 
          consoleStart && console.log('ua', ua)
        } else {
          auth_handlers.handleSetAuth() // sets authValidated
          handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
        }
      } else {
        auth_handlers.handleSetAuth() // sets authValidated
        handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
      }
    })
    // return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {
    if (authValidated) {
      handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authIndex]);

  useEffect(() => {
    if (emailValidationStatus === gEnums.emailValidationTypes.accepted) {
      handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [emailValidationStatus]);

  useEffect(() => {
    if (emailValidationStatus === gEnums.emailValidationTypes.accepted) {
      handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (authPendingType) {
      switch (authPendingType) {
        case authPendingTypes.authConfirmed:
          handleAmmend_startDimmer && handleAmmend_startDimmer(startTypes.finish)
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPendingType]);

  const provider = () => <AuthContext.Provider
    value={{
      auth_handlers,
      auth_state,
    }} >
    {props.children}
  </AuthContext.Provider >

  return provider() // (authValidated || ignoreWait) ? provider() : <div></div>

}

export default AuthProvider

