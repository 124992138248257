import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { SportsStandingsContext } from '../standings/LeagueStandings'

const StandingsWrapper = (props) => {

  const { sectionStandings } = props ?? {}

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_fns } = sportsSeasonContext ?? {}
  const { teams_info, matches_info } = sportsSeason_state ?? {}

  const sportsStandingsContext = useContext(SportsStandingsContext)
  const { sportsStandings_state, sportsStandings_handlers } = sportsStandingsContext ?? {}
  const { selected_teams, showSelected, showCommon, commonOpponents, selectedLevelKey } = sportsStandings_state ?? {}

  useEffect(() => {
    const { matchesAll } = matches_info ?? {}
    const { teams } = teams_info ?? {}
    if (teams && matchesAll) {
      const _teamRecords = sportsSeason_fns ? sportsSeason_fns.getTeamRecords(teams, matchesAll, selectedLevelKey) : {}
      const _trs = _.sortBy(_teamRecords, 'name')
      console.log('_trs', _trs)
      sportsStandings_handlers.handleSet_teamRecords(_trs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [teams_info, matches_info, selectedLevelKey]);

  const handleCancel = () => sportsStandings_handlers.handleShow_playoffPicture()
  const handleShowSeleted = () => sportsStandings_handlers.handleShow_selected()
  const handleShowCommon = () => sportsStandings_handlers.handleShow_common()
  const handleClearSelected = () => sportsStandings_handlers.handleSet_selected_teams([])

  const content = () => <Wrapper
    content={sectionStandings}
    footer={footer()}
    wrapperType={wrapperTypes.paddedFooter}
    css={{ container: 'app-dark-mode' }}
  />

  const footer = () => {
    const btns = []
    btns.push({ oc: handleShowSeleted, caption: handleShowSeleted ? 'Selected (' + selected_teams.length + ')' : '', icon: showSelected ? 'delete' : 'arrow right', color: showSelected ? 'red' : 'blue', disabled: selected_teams.length < 2 })
    !showSelected && selected_teams && selected_teams.length > 0 && btns.push({ oc: handleClearSelected, caption: 'Clear Selected', icon: 'delete', color: 'red', fl: true })
    showSelected && btns.push({ oc: handleShowCommon, caption: 'Common Opponents (' + commonOpponents.length + ')', icon: showCommon ? 'delete' : 'arrow right', disabled: ((selected_teams && selected_teams.length < 2) || commonOpponents && commonOpponents < 1), fl: true })
    return <UiSaveButtons others={btns} />
  }

  const fpw = () => <FullPageWrapper
    content={content()}
    handleCancel={handleCancel}
    topperCaption={'Playoff Picture'}
  />

  return fpw()
}

export default StandingsWrapper