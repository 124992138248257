import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { signInResponseTypes } from '../../cnr/reducers/SignInReducer';
import { validateEmail } from '../../validation/validation';
import { gEnums } from '../../enums/globalEnums';
import { createRefPath, createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_add_doc, fs_set_doc } from '../../firestoreData/appData/fsData';
import { createInitials } from '../../common/dataAdjust';

/** Sign In using signInWithEmailAndPassword */
export const createAccount = async (caProps, pathViews, callback) => {

  // testingonly@testing.com
  // Test@123!

  const { creds, appSignIn } = caProps
  const { email, password, firstName, lastName } = creds
  const { allowCreateAccount, createProfileType, allowedProfileEmails } = appSignIn ?? {}

  // validate the email
  if (!validateEmail(creds.email)) {
    return ({ success: false, signInResponseType: signInResponseTypes.invalidEmail, email: creds.email });
  }

  const _callback_profile = () => {
    callback({ signInResponseType: signInResponseTypes.signInSuccess })
  }

  const _callback_signIn = (resp, currentUser) => {
    const { email: email_current } = currentUser ?? {}
    if (email_current === email) {
      const dataToUpdate = {
        email: email,
        firstName,
        lastName,
        displayName: firstName + ' ' + lastName,
        initials: createInitials(firstName + ' ' + lastName)
      }
      switch (createProfileType) {
        case gEnums.createProfileTypes.clientProfiles:
          const _refPath_c = createRefPath_client(pathViews, ['profiles', email])
          fs_set_doc(_refPath_c, dataToUpdate, null, _callback_profile)
          break;
        case gEnums.createProfileTypes.profiles:
          const _refPath_p = createRefPath('users')
          fs_add_doc(_refPath_p, dataToUpdate, _callback_profile)
          break;
        default:
        // nothing
      }
    }

  }

  let _allowCreateAccount = false

  if (allowedProfileEmails) {
    allowedProfileEmails.forEach(ape => {
      if (creds.email.endsWith(ape)) {
        _allowCreateAccount = true
      }
    })
  } else {
    _allowCreateAccount = allowCreateAccount
  }

  const err = { message: 'Log-in Error' }

  if (_allowCreateAccount) {
    if (email && password) {
      const auth = getAuth()
      return createUserWithEmailAndPassword(
        auth,
        creds.email.toLowerCase(),
        creds.password
      ).then(async (resp) => {
        _callback_signIn(resp, auth.currentUser)
      }).catch((err) => {
        callback({ signInResponseType: signInResponseTypes.signUpError, err, success: false })
      })
    } else {
      callback({ signInResponseType: signInResponseTypes.incorrectPassword, err, success: false })
    }
  } else {
    callback({ signInResponseType: signInResponseTypes.incorrectPassword, err, success: false })
  }
}