import React, { useContext, useEffect, useState } from 'react'
import { Keypad } from './Keypad'
import { SportsMatchContext } from '../../cnr/contexts/SportsMatchContext'
import { Icon, Label } from 'semantic-ui-react'
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext'
import { gameScoringTypes } from '../../cnr/reducers/SportsSeasonReducer'

const GameScoring = () => {

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext
  const { gameScoringType } = sportsSeason_state ?? {}

  // sportsMatchContext
  const sportsEditMatchContext = useContext(sportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { match_edit } = sportsEditMatch_state ?? {}
  const { results, _flipHa } = match_edit ? match_edit : {}
  const { scores, score } = results ?? {}

  const [showScorepad, setShowScorepad] = useState()
  const [scoreCount, setScoreCount] = useState()
  const [currentScore, setCurrentScore] = useState()
  const [currentScores, setCurrentScores] = useState()

  useEffect(() => {
    let scoringCount = 2
    switch (gameScoringType) {
      case gameScoringTypes.half:
        scoringCount = 2
        break;
      case gameScoringTypes.quarter:
        scoringCount = 4
        break;
      default:
      // nothing
    }
    const _score = score ? score : { home: 0, away: 0 }
    const _scores = {}
    if (scores) {
      for (var i = 1; i <= scoringCount; i++) {
        _scores[i] = { home: 0, away: 0 }
      }
    }
    setScoreCount(scoringCount)
    setCurrentScore(_score)
    setCurrentScores(_scores)
    sportsEditMatch_handlers.handleChange_gameScore(_score, _scores)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);


  const handleShowScorepad = (type, index) => setShowScorepad({ type, index })

  const setScoreFromScores = (cs) => {
    const _cs = {}
    Object.keys(cs).forEach(csk => {
      _cs[csk] = {}
      const _csi = cs[csk]
      if (_csi) {
        Object.keys(_csi).forEach(haKey => {
          if (!_cs[haKey]) { _cs[haKey] = 0 }
          _cs[haKey] = _cs[haKey] + _csi[haKey]
        })
      }
    })
    setCurrentScore(_cs)
  }

  const handleSetScoreChange = (add, type, scoreCount, team) => {
    let _currentScore = { ...currentScore }
    let _currentScores = { ...currentScores }
    switch (type) {
      case 'score':
        _currentScore[team] = add ? _currentScore[team] + 1 : _currentScore[team] - 1
        if (_currentScore[team] < 0) { _currentScore[team] = 0 }
        setCurrentScore(_currentScore)
        break;
      case 'scores':
        if (!_currentScores[scoreCount]) { _currentScores[scoreCount] = {} }
        if (!_currentScores[scoreCount][team]) { _currentScores[scoreCount][team] = 0 }
        _currentScores[scoreCount][team] = add ? _currentScores[scoreCount][team] + 1 : _currentScores[scoreCount][team] - 1
        if (_currentScores[scoreCount][team] < 0) { _currentScores[scoreCount][team] = 0 }
        setCurrentScores(_currentScores)
        setScoreFromScores(_currentScores)
        break;
      default:
      // notihng
    }
    sportsEditMatch_handlers.handleChange_gameScore(_currentScore, _currentScores)
  }

  const handleScorepadChange = (score) => {
    let _currentScore = { ...currentScore }
    let _currentScores = { ...currentScores }
    const _scores = score ? score.split('-') : []
    const { type, index } = showScorepad ?? {}
    switch (type) {
      case 'score':
        _currentScore['away'] = parseInt(_scores[0])
        _currentScore['home'] = parseInt(_scores[1])
        setCurrentScore(_currentScore)
        break;
      case 'scores':
        if (!_currentScores[index]) { _currentScores[index] = {} }
        _currentScores[index]['away'] = parseInt(_scores[0])
        _currentScores[index]['home'] = parseInt(_scores[1])
        setCurrentScores(_currentScores)
        setScoreFromScores(_currentScores)
        break;
      default:
      // nothing
    }
    console.log('_currentScore', _currentScore, _currentScores)
    sportsEditMatch_handlers.handleChange_gameScore(_currentScore, _currentScores)
    setShowScorepad()
  }

  const scoreDiv = (type, scoreCount, team, score) => <div className='score-updown'>
    <div onClick={(e) => { handleSetScoreChange(false, type, scoreCount, team) }}><Icon size='small' color='red' inverted circular name={'minus'} disabled={score === 0 ? true : false} /></div>
    <div><Label size='small' color={'blue'} >{score}</Label></div>
    <div onClick={(e) => { handleSetScoreChange(true, type, scoreCount, team) }}><Icon size='small' color='green' inverted circular name={'add'} /></div>
  </div>

  const scoringCaption = (type) => {
    let caption;
    switch (type) {
      case 1:
        caption = type + 'st'
        break;
      case 2:
        caption = type + 'nd'
        break;
      case 3:
        caption = type + 'rd'
        break;
      case 4:
        caption = type + 'th'
        break;
      default:
      // nothing
    }
    return <Label onClick={(e) => { handleShowScorepad('scores', type) }}>{caption}</Label>
  }

  const div_scores = () => {
    const divs = []
    for (var i = 1; i <= scoreCount; i++) {
      if (!_flipHa) {
        divs.push(<div>
          {scoreDiv('scores', i, 'away', currentScores && currentScores[i] && currentScores[i].away ? currentScores[i].away : 0)}
          {scoringCaption(i)}
          {scoreDiv('scores', i, 'home', currentScores && currentScores[i] && currentScores[i].home ? currentScores[i].home : 0)}
        </div>)
      } else {
        divs.push(<div>
          {scoreDiv('scores', i, 'home', currentScores && currentScores[i] && currentScores[i].home ? currentScores[i].home : 0)}
          {scoringCaption(i)}
          {scoreDiv('scores', i, 'away', currentScores && currentScores[i] && currentScores[i].away ? currentScores[i].away : 0)}
        </div>)
      }

    }
    return divs
  }

  const div_score = () => {

    if (!_flipHa) {
      return <div className='scoring-half'>
        <div>
          <div>{match_edit && match_edit.away.name}</div>
          <div>{match_edit && match_edit.home.name}</div>
        </div>
        <div>
          {scoreDiv('score', null, 'away', currentScore.away)}
          <Label onClick={(e) => { handleShowScorepad('score') }}>{'Final'}</Label>
          {scoreDiv('score', null, 'home', currentScore.home)}
        </div>
        {currentScores && div_scores()}
      </div>
    } else {
      return <div className='scoring-half'>
        <div>
          <div>{match_edit && match_edit.home.name}</div>
          <div>{match_edit && match_edit.away.name}</div>
        </div>
        <div>
          {scoreDiv('score', null, 'home', currentScore.home)}
          <Label onClick={(e) => { handleShowScorepad('score') }}>{'Final'}</Label>
          {scoreDiv('score', null, 'away', currentScore.away)}
        </div>
        {currentScores && div_scores()}
      </div>
    }
  }

  return showScorepad ? <Keypad
    setScoresOn={handleShowScorepad}
    maxValues={2}
    maxDigits={2}
    handleUpdate={handleScorepadChange}
  /> :
    currentScore ? div_score() : <div></div>
}

export default GameScoring