import React from 'react';
import NonAuthPage from '../components/alerts/NonAuthPage';
import PendingAuth from '../components/alerts/pendings/PendingAuth';
import SignIn from '../components/auth/SignIn';
import { SignInTemp } from '../components/auth/SignInTemp';
import { gEnums } from '../enums/globalEnums';

/**
 * 
 * @param {*} props (view, appUser, loggedIn, pageAccess)
 * @returns SignIn, SignInTemp, NonAuthPage or PendingAuth
 */
const AppContentAlt = (props) => {

  const { view, appUser, loggedIn, pageAccess, fromDirect } = props
  const { allowPageView } = pageAccess ? pageAccess : {}

  console.log('loggedIn', loggedIn)

  const notAllowedContent = () => {
    if (!loggedIn) {
      return <SignIn origin={'AppContentAlt'} fromDirect={fromDirect} />
    } else {
      switch (allowPageView) {
        case gEnums.viewPermissionTypes.allowTemp:
          return <SignInTemp />

        case gEnums.viewPermissionTypes.deny:
        case gEnums.viewPermissionTypes.deny_noClientKey:
        case gEnums.viewPermissionTypes.deny_noEventKey:
        case gEnums.viewPermissionTypes.allow_clientLimited:
          if (loggedIn) {
            switch (view) {
              // case 'home':
              //   return <AltPage type={altPageTypes.welcome} />
              default:
                if (appUser.profileValidated) {
                  return <NonAuthPage itemType={'page'} allowPageView={allowPageView} wrapWithAc={true} />
                } else {
                  return <PendingAuth />
                }
            }
          } else {
            return <SignIn origin={'AppContentAlt'} fromDirect={fromDirect} />
          }
        default:
          return <div></div>
        // nothing
      }
    }
  }

  return notAllowedContent()

}

export default AppContentAlt