import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Icon, Label, List, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../global/common/keys';
import { getFirstObjectKey } from '../../../global/common/convert';
import { NumberPad } from '../../../global/components/forms/elements/NumberPad';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { fs_get_data_all } from '../../../global/firestoreData/appData/fsAppData';
import { fsfn_auth } from '../../../global/functions/fbAuth';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer } from '../../../global/cnr/reducers/SidebarReducer';
import MenuSidebars from '../../../global/sidebars/MenuSidebars';
import TicketingProvider, { TicketingContext } from '../cnr/contexts/TicketingContext';
import { FullTicketStatus, ticketStatusTypes } from './FullTicketStatus';
import TicketingLookup from './TicketingLookup';
import UserTicketStatus from './UserTicketStatus';
import '../../../cssMain/AppT.css'
import { FrameworkContext } from '../../../global/cnr/contexts/FrameworkContent';
import UiHeader from '../../../global/pageItem/UiHeader';

const sidebarType = 'ticketing'
const limit = 100

export const tickColors = {
  allotment: 'black',
  max: 'black',
  given: 'grey',
  remaining: 'brown',
  assignedTo: 'blue',
  entered: 'green'
}

const AppTicketing = (props) => {

  const parentContext = useContext(ParentContext);
  const { states, handlers, fns } = parentContext ?? {}
  const { page_state, paps_state } = states
  const { page_fns } = fns
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings } = pageSettings ?? {}
  const { navigate } = handlers

  const { pushSimple } = page_fns ?? {}

  // pageContext  
  const { appUserCollection } = aps_appUserSettings ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkRightType, frameworkLeftType } = framework_state ?? {}
  const inFramework = frameworkRightType || frameworkLeftType

  // ticketingContext
  const ticketingContext = useContext(TicketingContext)
  const { ticketing_state, ticketing_handlers } = ticketingContext ?? {}
  const { tickets, assignedTickets, appUserTickets, ticketKey, appTicketing, updating, showLookup } = ticketing_state ?? {}
  const { ticketingCaption, allotmentCaption, assignedToCaption, enteredCaption, remainingCaption } = appTicketing ?? {}

  const captions = {
    tickets: ticketingCaption,
    allotment: allotmentCaption ? allotmentCaption : 'Allotment',
    assignedTo: assignedToCaption ? assignedToCaption : 'Assigned To',
    entered: enteredCaption ? enteredCaption : 'Entered',
    remaining: remainingCaption ? remainingCaption : 'Remaining'
  }

  const [appUsers, setAppUsers] = useState()
  const [ticketHolders, setTicketHolders] = useState(0)
  const [showFilter, setShowFilter] = useState()
  const [ticketStatus, setTicketStatus] = useState()
  const [userTicketStatus, setUserTicketStatus] = useState()
  const [checkingAuth, setCheckingAuth] = useState()
  const [checkAuth, setCheckAuth] = useState()
  const [authsFound, setAuthsFound] = useState([])

  const authCount = authsFound ? Object.keys(authsFound).length : 0

  const handleAuthsFound = (data) => {
    setCheckingAuth()
    setAuthsFound(data)
  }

  const handleTicketStatus = (e, ticket, ticketType) => {
    e.stopPropagation()
    setTicketStatus({ ticket, ticketType })
  }

  const handleUserTicketStatus = (e, appUser, appUserTicket) => {
    e.stopPropagation()
    setUserTicketStatus({ appUser, appUserTicket })
  }

  const handleCancel = () => props.handleCancel ? props.handleCancel() : navigate(-1)

  // sidebar_state 
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ?? {}
  const { setInits } = sidebar_handlers ?? {}

  const { ms_tickets } = sidebar_items ?? {}
  const { selected: selected_ticket } = ms_tickets ?? {}
  const { item: item_ticket } = selected_ticket ?? {}
  const _ticket = tickets ? _.pickBy(tickets, { name: item_ticket }) : null
  const _ticketKey = _ticket ? getFirstObjectKey(_ticket) : null

  useEffect(() => {
    if (tickets) {
      let ticketKeys = []
      Object.keys(tickets).forEach(key => {
        ticketKeys.push(tickets[key].name)
      })
      ticketKeys = ticketKeys.sort()
      setInits([{ smt: sidebarMenuTypes.one, items: ticketKeys, currents, as: 'tickets', useStartCase: true }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [tickets]);

  useEffect(() => {
    if (_ticketKey) {
      ticketing_handlers.handleTicketChange(_ticketKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_ticketKey, appUserTickets]);

  // get the appUsers
  useEffect(() => {
    fs_get_data_all(pathViews, appUserCollection, setAppUsers)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // get the appUsers
  useEffect(() => {
    if (assignedTickets && appUserTickets) {
      let _ticketHolders = 0
      Object.keys(appUserTickets).forEach(key => {
        if (appUserTickets[key] && appUserTickets[key].allotment) {
          _ticketHolders++
        }
      })
      setTicketHolders(_ticketHolders)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [assignedTickets, appUserTickets]);

  useEffect(() => {
    if (checkAuth && appUsers) {
      const x = _.orderBy(appUsers, ['lastName'])
      const chunks = _.chunk(x, limit);
      fsfn_auth.getAuthsByChunkEmail(chunks, pathViews, false, 4, handleAuthsFound)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [checkAuth]);

  // const handleMaxChange = (e, data) => ticketing_handlers.handleMaxChange(data.value)
  const handleAppUserChange = (e, data) => { ticketing_handlers.handleAppUserChange(data) }

  // const handleSave = () => {
  //   const itemKey = assignedTickets._itemKey
  //   delete assignedTickets._itemKey
  //   ticketing_handlers.handleUpdateTicketAssignments(itemKey, assignedTickets)
  // }

  const handleCheckAuth = () => {
    setCheckingAuth(!checkAuth)
    setCheckAuth(!checkAuth)
  }

  const handleFilter = () => setShowFilter(!showFilter)
  // const handleTest = () => ticketing_handlers.handleUpdateRegistration(pathViews)
  // const handleConvert = () => {
  //   ticketing_handlers.createUserTickets()
  //   ticketing_handlers.groupTicketsByPhoneNumber()
  // }

  const mainHeaderLabel = (text, color, assignedTicket, ticketType) => {
    const lProps = { size: 'tiny' }
    return <div><Label {...lProps} color={color} onClick={(e) => { assignedTicket && handleTicketStatus(e, assignedTicket, ticketType) }}>{text}</Label></div>
  }

  const userHeaderLabel = (text, color, appUser, appUserTicket) => {
    const lProps = { size: 'tiny' }
    return <div><Label {...lProps} color={color} onClick={(e) => { handleUserTicketStatus(e, appUser, appUserTicket) }}>{text}</Label></div>
  }

  const appUserTicketInfo = (userTicket) => {
    const { allotment, subTickets } = userTicket
    let _allotment = allotment ? allotment : 0
    let entered = 0
    let assignedTo = 0
    if (subTickets && _.isArray(subTickets)) {
      subTickets.forEach(subTicket => {
        const { entered: u, assignedTo: at } = subTicket
        if (u) { entered++ }
        if (at) { assignedTo++ }
      })
    }
    return { allotment: _allotment, assignedTo, entered }
  }

  /** Returns a List.Item with the name of the user 
   * and a dropdown with the value of the number of tickets the user has
   */
  const appUserListItem = (appUser) => {
    const { firstName, lastName, _itemKey, email, phoneNumber } = appUser
    const autProps = appUserTickets && appUserTickets[_itemKey] ? appUserTicketInfo(appUserTickets[_itemKey]) : { allotment: 0, entered: 0, assignedTo: 0 }
    const { allotment, entered, assignedTo } = autProps ?? {}

    let appUserTicket;
    let remaining = 0

    console.log('appUserTickets', appUserTickets)

    if (appUserTickets && appUserTickets[_itemKey]) {
      appUserTicket = appUserTickets[_itemKey]
      remaining = allotment - entered
    }

    let allow = true
    if (showFilter && (!allotment || allotment === 0)) { allow = false }

    let _found;
    if (authsFound && checkAuth) {
      const _aufs = _.find(authsFound, function (a) { return ((a.email === email) || (a.phoneNumber === phoneNumber)); })
      if (_aufs) { _found = true }
    }

    if (allow) {
      return <List.Item as='div' key={uniqueKey('atk', 'li', _itemKey)} >
        <div onClick={() => { pushSimple({ key: appUserCollection }, _itemKey) }}>{lastName}{', '}{firstName}{_found && <Icon name={'check circle'} color={'green'} />}</div>
        <div><NumberPad value={allotment} onChange={handleAppUserChange} itemKey={_itemKey} startNull={true} /></div>
        {userHeaderLabel(assignedTo, tickColors.assignedTo, appUser, appUserTicket)}
        {userHeaderLabel(entered, tickColors.entered, appUser, appUserTicket)}
        {userHeaderLabel(remaining, tickColors.remaining, appUser, appUserTicket)}
      </List.Item>
    }
  }

  const allListItems = () => {
    const _items = appUsers ? _.sortBy(appUsers, 'lastName') : []
    const listItems = appUsers ? _items.map(item => (
      appUserListItem(item)
    )) : []

    return <Segment basic className={'app-ticketing-container'}>
      <List divided relaxed>
        {listItems}
      </List>
    </Segment>
  }

  const mainHeader = () => {
    const count = appUsers ? Object.keys(appUsers).length : 0
    let status = { max: 0, allotment: 0, assignedTo: 0, entered: 0 }
    let remaining = 0
    let assignedTicket;
    if (assignedTickets && assignedTickets[ticketKey] && assignedTickets[ticketKey].status) {
      assignedTicket = assignedTickets[ticketKey]
      status = assignedTickets[ticketKey].status
      remaining = status.allotment - status.entered
    }
    const lProps = { size: 'tiny' }
    return <div className={'ticketing-header'}>
      <div>{_.startCase(appUserCollection)}</div>
      <div>{captions.allotment}</div>
      <div>{captions.assignedTo}</div>
      <div>{captions.entered}</div>
      <div>{captions.remaining}</div>
      <div><Label {...lProps} color={tickColors.allotment}>{ticketHolders}{'/'}{count}</Label></div>
      {mainHeaderLabel(status.allotment, tickColors.allotment)}
      {mainHeaderLabel(status.assignedTo, tickColors.assignedTo, assignedTicket, ticketStatusTypes.assignedTo)}
      {mainHeaderLabel(status.entered, tickColors.entered, assignedTicket, ticketStatusTypes.entered)}
      {mainHeaderLabel(remaining, tickColors.remaining, assignedTicket, ticketStatusTypes.entered)}
    </div>
  }

  const content = () => allListItems()

  const listWrapper = () => <Wrapper
    header={mainHeader()}
    content={content()}
    wrapperType={wrapperTypes.paddedHeader}
    updating={updating}
  />

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={listWrapper()}
    dimmed={dimmed}
  />

  const wrapper = () => <Wrapper
    header={inFramework && <UiHeader caption={'Ticketing Manager'} />}
    content={tickets && sidebar_items ? menuSidebars() : <div>---</div>}
    footer={<UiSaveButtons
      cn={'atb'}
      save={{ oc: ticketing_handlers.handleShowLookup, caption: 'Lookup', icon: 'search plus' }}
      preview={{ oc: handleCheckAuth, caption: checkAuth ? 'Auth: ' + authCount : 'Auth', icon: 'user', color: 'red' }}
      delete={{ oc: handleFilter, caption: 'Filter', icon: 'filter', color: showFilter ? 'green' : 'blue' }}
    />}
    wrapperType={wrapperTypes.padded}
    updating={checkingAuth}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    handleCancel={handleCancel}
    topperCaption={ticketingCaption ? ticketingCaption : 'Ticketing'}
  />

  console.log('showLookup', showLookup)

  if (showLookup) {
    return <TicketingLookup ticketing_state={ticketing_state} ticketing_handlers={ticketing_handlers} />
  } else if (userTicketStatus) {
    return <UserTicketStatus userTicketStatus={userTicketStatus} handleCancel={setUserTicketStatus} />
  } else if (ticketStatus) {
    return <FullTicketStatus ticketStatus={ticketStatus} handleCancel={setTicketStatus} />
  } else {
    return fullPageWrapper()
  }
}

export const AppTicketingWithProvider = (props) => <TicketingProvider>
  <AppTicketing {...props} />
</TicketingProvider>

export default AppTicketingWithProvider