import _ from 'lodash';
import React, { useContext } from 'react';
import { Divider, Icon, Menu, Popup } from 'semantic-ui-react';
import { SettingsMenuContext } from '../../../cnr/contexts/SettingsMenuContext';
import { uniqueKey } from '../../../common/keys';
import { gEnums } from '../../../enums/globalEnums';
import { iconColorTypes } from '../../../enums/settingsIconTypes';
import ProfileSelect from '../../../page/ProfileSelect';
import { filterHelpers } from '../../../common/filtering';

const allowSort = false

/**
 * 
 * @param {object} props (groupItemKey: key, groupItem, index, groupItemSettingz)
 * @returns a SINGLE `Menu.Item` element.
 * This is where the `click` event occurs.
 * The `click` event triggers `settingsMenu_fns.handleMenuGroupItemClick` in the `SettingsMenuProvider`
 */
const SettingsAreaGroupMenuItemElement = (props) => {

  const { groupItemKey: key, groupItem, index } = props

  const { caption, restrict, icon, cornerIcon, dividedText, dividedTextAfter, gotoKey, settingsAuthLevel } = groupItem ?? {}

  const authLevel = filterHelpers.find(gEnums.authLevels, 'value', settingsAuthLevel)

  const iconColor = authLevel ? authLevel.color : iconColorTypes.menuItem

  // settingsMenuContext
  const settingsMenuContext = useContext(SettingsMenuContext);
  const { settingsMenu_handlers, settingsMenu_state, settingsMenu_fns } = settingsMenuContext ?? {}
  const { allow, helpDisplayType, helpDocuments, _helpDocs, helpOn, helpTopics } = settingsMenu_state ?? {}
  const { allowProjectModule } = settingsMenu_fns ?? {}

  const _allow = allowProjectModule(key)

  const smis = []
  const selectedItem = { ...groupItem, key }

  const handleItemClick = () => {
    if (gotoKey) {
      settingsMenu_fns.handleGroupItemClick(selectedItem, groupItem)
    } else {
      settingsMenu_handlers.handleMenuGroupItemClick(selectedItem, groupItem)
    }
  }

  /**
   *
   * @param {string} key 
   * @param {string} caption 
   * @returns the content (divs) for the Menu.Item
   */
  const menuItemContent = (key, caption) => {
    const _helpDocumentz = _helpDocs ? _helpDocs[key] : null
    const _helpDocument = helpDocuments ? helpDocuments[key] : null
    if (helpOn && _helpDocument && _helpDocument.groupLine) {
      return <div className={'mic'}>
        <div>{caption}</div>
        <div><p>{_helpDocument.groupLine}</p></div>
      </div>
    } else {
      return caption
    }
  }

  const popupHelp = (key) => {
    if (helpOn && helpTopics && helpTopics[key] && helpDisplayType !== gEnums.helpDisplayTypes.belowCaption) {
      return <Popup content={helpTopics[key]} position='top right' trigger={<Icon color={'green'} name='cog' />} />
    }
  }

  const divider = (text, index, key) => <Divider key={uniqueKey('smd', index, key)} horizontal fitted>{text}</Divider>

  const menuItem = (key, index, allow, contents) => {
    if (allowSort) {
      return contents
    } else {
      // IMPORTANT: Settings - Menu.Item for the group items
      return <Menu.Item key={uniqueKey('smsmi', key, index)} disabled={!allow} onClick={() => handleItemClick()}>
        {contents}
      </Menu.Item>
    }
  }

  const menuIcon = (allow) => {
    if (cornerIcon) {
      return <Icon.Group>
        <Icon name={icon ? icon : 'options'} color={'red'} />
        <Icon corner name={cornerIcon} />
      </Icon.Group>
    } else {
      return <Icon disabled={!allow} color={iconColor} name={icon ? icon : 'options'} />
    }
  }

  switch (key) {
    case 'mask':
      smis.push(<Menu.Item key={uniqueKey('smsmi', key, index)} disabled={!allow} onClick={() => handleItemClick()}>
        <ProfileSelect
          profileSelectType={gEnums.profileSelectTypes.accessLevels}
          selectOnly={true}
        />
      </Menu.Item>)
      break;
    default:
      let mii;

      // IMPORTANT: Settings - Sub Group Menu Item

      if (_allow) {
        if (!restrict) {
          mii = menuItem(key, index, allow,
            <React.Fragment>
              {menuIcon(allow)}
              {menuItemContent(key, caption ? caption : _.startCase(key) + ' m**')}
              {popupHelp(key)}
            </React.Fragment>
          )
        } else {
          mii = <Menu.Item key={uniqueKey('smsmi', key, index)} className={'restricted'} disabled={!allow} onClick={() => handleItemClick()}>
            <Icon disabled={!allow} color={iconColorTypes.menuItem} name={icon ? icon : 'options'} />
            {caption}
            {popupHelp(key)}
          </Menu.Item>
        }
        dividedText && smis.push(divider(_.startCase(dividedText), index, 'dt' + key))
        if (mii) { smis.push(mii) }
        dividedTextAfter && smis.push(divider(_.startCase(dividedTextAfter), index, 'dta' + key))
      }
  }

  return smis.length > 0 ? smis : null

}

export default SettingsAreaGroupMenuItemElement

// const helpLine = (settingsMenu_state, key) => {
//   const { helpDocuments } = settingsMenu_state
//   const _helpDocument = helpDocuments ? helpDocuments[key] : null
//   if (_helpDocument && _helpDocument.groupLine) {
//     return <div>{_helpDocument.groupLine}</div>
//   }
// }