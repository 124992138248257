import _ from 'lodash'
import skewnorm from 'skew-normal-random'
import { calcMatchStatus } from './calcMatchStatus'

export const createMatchScorecardsWithResults = (wins, golfMatch, holes, matchCount, teams) => {

  const numberOrRounds = matchCount ? matchCount : 1
  const alphas = 1
  const scales = 2

  const golfers = {
    team1: {},
    team2: {}
  }

  // create the golfers
  Object.keys(golfMatch.team1).forEach((key) => {
    golfers.team1[key] = { ...golfMatch.team1[key] }
    golfers.team1[key].scores = {}
  })

  Object.keys(golfMatch.team2).forEach((key) => {
    golfers.team2[key] = { ...golfMatch.team2[key] }
    golfers.team2[key].scores = {}
  })

  // create scores for each of the golfers
  for (let s = scales; s <= scales; s++) {
    const scale = s
    for (let a = alphas; a <= alphas; a++) {
      const alpha = a
      for (let i = 0; i < numberOrRounds; i++) {
        Object.keys(golfers.team1).forEach(key => {
          const golfer = golfers.team1[key]
          golfer.scores['roundNumber' + (i + 1)] = createGolferScores(golfer, holes, alpha, scale)
        })
        Object.keys(golfers.team2).forEach(key => {
          const golfer = golfers.team2[key]
          golfer.scores['roundNumber' + (i + 1)] = createGolferScores(golfer, holes, alpha, scale)
        })
      }
    }
  }

  // let matchScores;
  let matchStatus = {}

  // compare the scores
  for (let rn = 0; rn < numberOrRounds; rn++) {

    const holeWinners = {}
    const holeScores = {}

    // eslint-disable-next-line
    Object.keys(holes).forEach(key => {

      if (!matchStatus.finished) {
        const h = holes[key]
        const { holeNumber } = h

        holeScores[holeNumber] = {}

        let lowScore1 = 100
        let lowScore2 = 100

        Object.keys(golfers.team1).forEach(key => {
          const golfer = golfers.team1[key]
          const gs = golfer.scores['roundNumber' + (rn + 1)][holeNumber]
          holeScores[holeNumber][key] = gs.gross
          if (gs.net <= lowScore1) {
            lowScore1 = gs.net
          }
        })

        Object.keys(golfers.team2).forEach(key => {
          const golfer = golfers.team2[key]
          const gs = golfer.scores['roundNumber' + (rn + 1)][holeNumber]
          holeScores[holeNumber][key] = gs.gross
          if (gs.net <= lowScore2) {
            lowScore2 = gs.net
          }
        })

        let winningTeam = 0

        if (lowScore1 < lowScore2) {
          winningTeam = 1
        } else if (lowScore2 < lowScore1) {
          winningTeam = 2
        }

        holeWinners[holeNumber] = {
          winningTeam: winningTeam
        }

        golfMatch.holeWinners = holeWinners
        golfMatch.holeScores = holeScores

        matchStatus = calcMatchStatus(golfMatch, holes, teams)
      }
    })

    // matchScores = holeWinners

    const hs1 = _.filter(holeWinners, { winningTeam: 1 })
    const hs2 = _.filter(holeWinners, { winningTeam: 2 })

    if (hs1.length > hs2.length) {
      wins.team1++
    } else if (hs2.length > hs1.length) {
      wins.team2++
    } else {
      wins.allSquare++
    }
  }

  return matchStatus
}

// const rnn = (min, max) => {
//   return Math.random() * (max - min) + min;
// }

const createGolferScores = (golfer, holes, alpha, scale) => {
  const scores = {}
  const { matchHandicap } = golfer
  Object.keys(holes).forEach(key => {
    const h = holes[key]
    const { par, handicap: handicap_hole } = h
    const parInt = parseInt(par)
    let gs = parInt
    let maxAdd = 0
    let min = gs - 1 + (maxAdd * 3)
    let max = gs + 2 + maxAdd
    const score = skewnorm.rSkewNorm(alpha, gs, scale, min, max)
    const gScore = Math.round(score)
    let nScore = gScore
    if (matchHandicap <= handicap_hole) {
      nScore = nScore - 1
    }
    const s = {
      net: nScore,
      gross: gScore
    }
    scores[h.holeNumber] = s
  })
  return scores
}

export const createScorecards = (golfMatch, holes) => {

  const results = []
  const numberOrRounds = 100
  const alphas = 4
  const scales = 3

  for (let s = 0; s < scales; s++) {
    const scale = s
    for (let a = 0; a < alphas; a++) {
      const alpha = a
      let allTotals = 0
      for (let i = 0; i < numberOrRounds; i++) {
        const scores = []
        let total = 0
        Object.keys(holes).forEach(key => {
          const h = holes[key]
          const { par } = h
          const parInt = parseInt(par)
          let min = parInt - 1
          let max = parInt + 2
          const score = skewnorm.rSkewNorm(alpha, parInt, scale, min, max)
          scores.push(Math.round(score))
          total += Math.round(score)
        })
        allTotals += total
      }
      const res = {
        numberOrRounds: numberOrRounds,
        alpha: alpha,
        scale: scale,
        ave: allTotals / numberOrRounds,
      }
      console.log(res)
      results.push(res)
    }
  }
}