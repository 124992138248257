// AnimatedWrapper.js
import React from 'react';
import { motion } from 'framer-motion';

const transitionWrapperTypes = {
  fade: 'fade',
  slide: 'slide',
  scale: 'scale',
  rotate: 'rotate',
  scaleAndFade: 'scaleAndFade',
  whiteToBlack: 'whiteToBlack',
  default: 'default', // Add more transition types as needed
}

const TransitionWrapper = ({ children, transitionWrapperType = transitionWrapperTypes.fade, useStyle = true }) => {

  // <div className='parent'>
  //   <div className='scroller'>
  //     <div className='scroller'>
  //       Scroll This
  //     </div>
  //     <div className='scroller'>
  //       Scroll This
  //     </div>
  //   </div>
  // </div>

  const _defaults = {
    duration: 0.2,
    scale: .9,
    opacity: .9,
    animate: '2%'
  }

  const getTransition = () => {
    switch (transitionWrapperType) {
      case transitionWrapperTypes.slide:
        return { initial: { x: '2%' }, animate: { x: 0 }, exit: { x: '2%' } }; // Adjust the x values as needed
      case transitionWrapperTypes.scale:
        return { initial: { scale: _defaults.scale }, animate: { scale: 1 }, exit: { scale: 0 } };
      case transitionWrapperTypes.rotate:
        return { initial: { rotate: -180 }, animate: { rotate: 0 }, exit: { rotate: 180 } };
      case transitionWrapperTypes.scaleAndFade:
        return { initial: { scale: _defaults.scale, opacity: 0 }, animate: { scale: 1, opacity: 1 }, exit: { scale: 0, opacity: 0 } };
      case transitionWrapperTypes.whiteToBlack:
        return { initial: { backgroundColor: 'white' }, animate: { backgroundColor: 'black' }, exit: { backgroundColor: 'white' } };
      default:
        return { initial: { opacity: _defaults.opacity }, animate: { opacity: 1 }, exit: { opacity: 0 } };
    }
  };

  const transition = getTransition();

  return (
    <motion.div
      initial={transition.initial}
      animate={transition.animate}
      exit={transition.exit}
      transition={{ duration: _defaults.duration }}
      className={'transition-wrapper ova'}
    >
      {children}
    </motion.div>
  );
};

export { transitionWrapperTypes };
export default TransitionWrapper;
