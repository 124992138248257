import { getDownloadURL, listAll, ref } from "firebase/storage";
import _ from 'lodash';
import { callFunction } from "./fbAll";
import { getFirebaseStorage } from "../storage/storageHelpers";

const storageGenericTypes = {
  storageGeneric: 'storageGeneric',
}

export const storageFunctionTypes = {
  transferClientProfilesStorage: 'transferClientProfilesStorage',
  transferEventStorage: 'transferEventStorage',
  testStorageFile: 'testStorageFile',
  moveFolder_galleryDirectToClient: 'moveFolder_galleryDirectToClient',
  moveFolder_clientToClient: 'moveFolder_clientToClient',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(storageGenericTypes.storageGeneric, callData)
  return data
}

export const fsfn_storage = {
  moveAttendeeFolders: (fss, pvKeys, transfers, showLogs, callback, fileName) => fsfn_moveAttendeeFolders(fss, pvKeys, transfers, showLogs, callback, fileName),
  moveStorageFiles: (data) => moveStorageFiles(data),
  transferStorage: (fss, pvKeys, transfers, callback, viewItemKey, fileName, formData) => fsfn_transferStorage(fss, pvKeys, transfers, callback, viewItemKey, fileName, formData),
}

/**
 * Gets the data from a googleSheet using the firebase function `getSheets`
 * @param {object} sheetProps 
 * @param {string} appArea 
 * @param {object} googleSheetsImportOptions 
 * @param {object} logging 
 * @returns data
 */
const fsfn_moveAttendeeFolders = async (fss, pvKeys, transfers, showLogs, callback, fileName) => {

  const projectPaths = {
    source: pvKeys && pvKeys.source.events ? 'clients/' + pvKeys.source.clients + '/events/' + pvKeys.source.events : 'clients/' + pvKeys.source.clients,
    destination: pvKeys && pvKeys.destination.events ? 'clients/' + pvKeys.destination.clients + '/events/' + pvKeys.destination.events : 'clients/' + pvKeys.source.clients,
  }

  const projectIds = {
    source: fss && fss.source && fss.source._databaseId && fss.source._databaseId.projectId ? fss.source._databaseId.projectId : null,
    destination: fss && fss.destination && fss.destination._databaseId && fss.destination._databaseId.projectId ? fss.destination._databaseId.projectId : null
  }

  const folderNames = {
    source: 'attendees',
    destination: 'profiles',
  }

  try {
    const callData = {
      storageFunctionType: storageFunctionTypes.transferClientProfilesStorage,
      transfers,
      projectIds,
      projectPaths,
      folderNames,
      showLogs,
      fileName,
      transferData: true,
      ignoreExisting: false
    }

    console.log('callData', callData)
    const response_data = await callFsFunction(callData) // OK 
    console.log('response_data', response_data)
    // console.log('rd', response_data)
    // each item contains a _gsid, which is the key of the data item

    const { success, result } = response_data
    const { result: trueResult } = result ?? {}

    if (success && trueResult) {
      const ret = { success, result: trueResult }
      callback(ret)
    } else {
      const ret = { success: false, result: {} }
      callback(ret)
    }
  } catch (error) {
    callback({ success: false })
  }
}

/**
 * Gets the data from a googleSheet using the firebase function `getSheets`
 * @param {object} sheetProps 
 * @param {string} appArea 
 * @param {object} googleSheetsImportOptions 
 * @param {object} logging 
 * @returns data
 */
const fsfn_transferStorage = async (fss, pvKeys, transfers, callback, viewItemKey, fileName, formData) => {

  const projectPaths = {
    source: pvKeys && pvKeys.source.events ? 'clients/' + pvKeys.source.clients + '/events/' + pvKeys.source.events : 'clients/' + pvKeys.source.clients,
    destination: pvKeys && pvKeys.destination.events ? 'clients/' + pvKeys.destination.clients + '/events/' + pvKeys.destination.events : 'clients/' + pvKeys.source.clients,
  }

  const projectIds = {
    source: fss && fss.source && fss.source._databaseId && fss.source._databaseId.projectId ? fss.source._databaseId.projectId : null,
    destination: fss && fss.destination && fss.destination._databaseId && fss.destination._databaseId.projectId ? fss.destination._databaseId.projectId : null
  }

  // lbieberly@bcinsourcing.com
  // gs://me-mobile-4410b.appspot.com/clients/w4wsbHrmO5Uw5GL14M1t/events/61BCB775A03EC/_gallery_direct/attendees/62262DFA0FD90
  // clients/w4wsbHrmO5Uw5GL14M1t/events/61BCB775A03EC/_gallery_direct/attendees/62262DFA0FD90

  try {
    const callData = {
      transfers,
      projectIds,
      projectPaths,
      pvKeys,
      showLogs: true,
      fileName,
      transferData: true,
      ignoreExisting: false
    }

    if (transfers.eventStorage) {
      callData.storageFunctionType = storageFunctionTypes.transferEventStorage
    } else if (transfers.clientProfileStorage) {
      callData.storageFunctionType = storageFunctionTypes.transferClientProfilesStorage
      callData.folderNames = { source: 'attendees', destination: 'profiles' }
    } else if (transfers.eventFolderToClient && viewItemKey) {
      callData.storageFunctionType = storageFunctionTypes.moveFolder_galleryDirectToClient
      callData.folderNames = { source: viewItemKey, destination: viewItemKey }
    } else if (transfers.clientToClient && formData && formData.eventKey) {
      callData.storageFunctionType = storageFunctionTypes.moveFolder_clientToClient
      callData.folderNames = { source: formData.eventKey, destination: formData.eventKey }
    } else if (transfers.clientProfilesToClientProfiles && formData && formData.eventKey) {
      callData.storageFunctionType = storageFunctionTypes.moveFolder_clientToClient
      callData.folderNames = { source: 'profiles', destination: 'profiles' }
    }

    let response_data = {};

    if (callData.storageFunctionType) {
      console.log('callData', callData)
      response_data = await callFsFunction(callData) // OK 
      console.log('response_data', response_data)
    }

    // console.log('rd', response_data)
    // each item contains a _gsid, which is the key of the data item

    const { success, result } = response_data ?? {}
    const { result: trueResult } = result ?? {}

    if (success && trueResult) {
      const ret = { success, result: trueResult }
      callback(ret)
    } else {
      const ret = { success: false, result: {} }
      callback(ret)
    }
  } catch (error) {
    callback({ success: false })
  }
}

function hasDownloadUrl(files, obj) {
  if (_.has(obj, 'downloadURL')) {
    return true;
  }

  for (const key in obj) {
    if (_.isObject(obj[key])) {
      if (hasDownloadUrl(files, obj[key])) {
        files.push(obj[key].downloadURL)
        return true;
      }
    }
  }

  return false;
}

const moveStorageFiles = async (data) => {

  // @ts-ignore
  const { storageFileType, projectIds, projectPaths, showLogs } = data ?? {}
  const { source: sourceFolderPath, destination: destinationFolderPath } = projectPaths ?? {}

  const storage = getFirebaseStorage();
  const folderRef = ref(storage, sourceFolderPath);
  const sis = await fetchStorageItems(folderRef)
  console.log('sis', sis)

  const files = []
  hasDownloadUrl(files, sis)
  console.log('files', files)

}

const fetchStorageItems = async (folderRef) => {

  const items = [];

  const { prefixes, items: files } = await listAll(folderRef);

  for (const fileRef of files) {
    const downloadURL = await getDownloadURL(fileRef);
    items.push({ type: 'file', downloadURL });
  }

  for (const prefixRef of prefixes) {
    const subItems = await fetchStorageItems(prefixRef);
    items.push({ type: 'folder', name: prefixRef.name, subItems });
  }

  return items;
};