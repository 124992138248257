import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../../global/common/keys';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sidebarHandlers, sidebarInitialState, sidebarMenuTypes, sidebarReducer, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import MenuSidebars from '../../../../global/sidebars/MenuSidebars';
import SeasonMatchesProvider from '../../cnr/contexts/SeasonMatchesContext';
import { SportsSeasonContext } from '../../cnr/contexts/SportsSeasonContext';
import MatchListSelected from '../../elements/matches/MatchListSelected';
import PlayoffBracket, { playoffBracketTypes } from '../../elements/matches/PlayoffBracket';
import { CreateSportsContext } from '../CreateSports';
import NoData from '../../../../global/components/alerts/NoData';
import PlayoffBrackets from '../../elements/matches/PlayoffBrackets';
import { CreatePlayoffContext } from '../CreatePlayoff';

const sidebarType = 'createTournament'

export const tournamentTypes = {
  existingPlayoff: 'existingPlayoff',
  newPlayoff: 'newPlayoff',
  tournament: 'tournament'
}

/**
 * 
 * @param {object} props 
 * @returns 
 */
const CreateTournamentSchedule = (props) => {

  const { tournamentType, componentContexts, asGrid, tournament: pageTournament } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { fns } = parentContext ?? {}
  const { page_fns } = fns
  const { pushSimple } = page_fns ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext ?? {}
  const { playoff_info: playoff_info_season, inverted, usePlayoffBrackets } = sportsSeason_state ?? {}
  const { playoffTournaments } = playoff_info_season ?? {}

  // createSportsContext
  const createSportsContext = useContext(CreateSportsContext);
  const { createSports_state, createSports_handlers } = createSportsContext ?? {}
  const { playoff_info: playoff_info_create } = createSports_state ?? {}

  // createSportsContext
  const createPlayoffContext = useContext(CreatePlayoffContext);
  const { createPlayoff_state, createPlayoff_handlers } = createPlayoffContext ?? {}
  const { playoff_info: playoff_info_playoff } = createPlayoff_state ?? {}
  const { tournaments: tournaments_playoff } = playoff_info_playoff ?? {}

  const playoffInfo = playoff_info_playoff ? playoff_info_playoff : playoff_info_create
  const { playoffLevelTeams, playoffLevel } = playoffInfo ?? {}

  // sidebar
  const [sidebar_state, sidebar_dispatch] = useReducer(sidebarReducer, sidebarInitialState({ sidebarType, handlers: sportsSeason_handlers }));
  const sidebar_handlers = sidebarHandlers(sidebar_dispatch)
  const { sidebar_items, currents, dimmed } = sidebar_state ?? {}
  const { setInit, setInits } = sidebar_handlers ?? {}

  const { ms_tournaments, ms_pools } = sidebar_items ?? {}
  let { ms_rounds } = sidebar_items ?? {}

  const { selected: selected_tournament } = ms_tournaments ?? {}
  const { item: item_tournament } = selected_tournament ?? {}

  if (tournamentType === tournamentTypes.existingPlayoff) { ms_rounds = { selected: { item: 'playoffs' } } }

  const { selected: selected_round } = ms_rounds ?? {}
  const { item: item_round } = selected_round ?? {}

  const [tournaments, set_tournaments] = useState()
  const [noData, setNoData] = useState()

  const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }

  // componentContexts
  const { uiItemContext } = componentContexts ?? {}
  const { item_state } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}

  // tournaments - one: 4A/3A/2A/1A
  // rounds - two: playoffs
  // pools - three: Round of 16, Round of 8
  // schedule

  const getInits_tournaments = (rounds, pools) => {
    let _ins = []
    switch (tournamentType) {
      case tournamentTypes.existingPlayoff:
        _ins = [{ smt: sidebarMenuTypes.one, items: Object.keys(tournaments).sort().reverse(), currents, as: 'tournaments', ..._initProps }]
        if (!usePlayoffBrackets) {
          _ins.push({ smt: sidebarMenuTypes.two, items: pools ? Object.values(pools) : [], currents, as: 'pools', useStartCase: true, ..._initProps })
        }
        break;
      default:
        // rounds - playoffs
        // tournaments - 4A, 3A, 2A, 1A
        // pools - R32, R16, QF, SF, F
        // schedule - schedule, results 
        _ins = [
          { smt: sidebarMenuTypes.one, items: rounds ? Object.keys(rounds) : [], currents, as: 'rounds', useStartCase: true, ..._initProps, hidden: true },
          { smt: sidebarMenuTypes.two, items: playoffLevel ? [playoffLevel] : Object.keys(tournaments).sort().reverse(), currents, as: 'tournaments', ..._initProps },
        ]
        if (!usePlayoffBrackets) {
          _ins.push({ smt: sidebarMenuTypes.three, items: pools ? Object.values(pools) : [], currents, as: 'pools', useStartCase: true, ..._initProps })
        }
    }
    return _ins
  }

  useEffect(() => {
    if (tournamentType === tournamentTypes.newPlayoff) {
      createPlayoff_handlers.handleCreate_playoff()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [playoffLevelTeams]);

  // sets the tournaments
  useEffect(() => {
    let _tournaments = {};
    switch (tournamentType) {
      case tournamentTypes.tournament:
        const { name: tournamentName } = pageTournament ?? {}
        _tournaments[tournamentName] = pageTournament
        break;
      case tournamentTypes.newPlayoff:
        _tournaments = tournaments_playoff
        break;
      default:
        _tournaments = playoffTournaments
        break;
    }
    if (_tournaments && Object.keys(_tournaments).length > 0) {
      set_tournaments(_tournaments)
      setNoData(false)
    } else {
      setNoData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [playoffTournaments, pageTournament, tournaments_playoff, playoffLevel]);

  // gets the inits when tournaments have been set
  useEffect(() => {
    if (tournaments) {
      const { rounds, poolz } = getPoolsAndRounds()
      const _inits = getInits_tournaments(rounds, poolz)
      setInits(_inits)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [tournaments]);

  useEffect(() => {
    if (item_tournament && item_round) {
      const { poolz } = getPoolsAndRounds(item_tournament)
      switch (tournamentType) {
        case tournamentTypes.existingPlayoff:
          setInit({ smt: sidebarMenuTypes.two, items: poolz ? Object.values(poolz) : [], currents, as: 'pools', useStartCase: true, ..._initProps, hidden: usePlayoffBrackets })
          break;
        default:
          setInit({ smt: sidebarMenuTypes.three, items: poolz ? Object.values(poolz) : [], currents, as: 'pools', useStartCase: true, ..._initProps, hidden: usePlayoffBrackets })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [item_tournament, item_round]);

  const handleMatchClick = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    if (itemKey) pushSimple(clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex)
  }

  const getPoolz = (pools) => {
    const poolz = []
    if (pools && pools.roundOf32) { poolz.push(playoffBracketTypes.roundOf32) }
    if (pools && pools.roundOf16) { poolz.push(playoffBracketTypes.roundOf16) }
    if (pools && pools.quarterFinals) { poolz.push(playoffBracketTypes.quarterFinals) }
    if (pools && pools.semiFinals) { poolz.push(playoffBracketTypes.semiFinals) }
    if (pools && pools.finals) { poolz.push(playoffBracketTypes.finals) }
    return poolz
  }

  const getPoolsAndRounds = (tournamentItem) => {
    switch (tournamentType) {
      case tournamentTypes.existingPlayoff:
        const tournament = tournamentItem ? tournaments[tournamentItem] : Object.values(tournaments)[0]
        const { rounds: rounds_t } = tournament ?? {}
        const round_t = rounds_t && item_round ? rounds_t[item_round] : null
        const { pools: pools_t } = round_t ?? {}
        const poolz1 = getPoolz(pools_t)
        return { rounds: rounds_t, poolz: poolz1 }

      default:
        const t = tournamentItem ? tournaments[tournamentItem] : Object.values(tournaments)[0]
        const { rounds } = t ?? {}
        const round = rounds ? Object.values(rounds)[0] : {}
        const { pools } = round ?? {}
        const poolz2 = getPoolz(pools)
        return { rounds, poolz: poolz2 }
    }
  }

  const selectedMatches = () => {

    const tournament = tournaments[ms_tournaments.selected.item]
    const { rounds } = tournament ?? {}
    const round = rounds && ms_rounds ? rounds[ms_rounds.selected.item] : null

    if (usePlayoffBrackets) {
      return <PlayoffBrackets
        round={round}
        roundItem={ms_rounds.selected.item}
        componentContexts={componentContexts}
        tournamentType={tournamentType}
        asGrid={asGrid}
        item_tournament={item_tournament}
      />
    } else {
      const { pools } = round ?? {}
      const pool = pools ? pools[ms_pools.selected.item] : null
      const { matches } = pool ?? {}
      const mss = <SeasonMatchesProvider
        poolMatches={matches}
        pools={pools}
        isTournament={true}
        viewItem={viewItem}
        handleMatchClick={handleMatchClick}
      >
        {!asGrid && <MatchListSelected key={uniqueKey('cts', 'mls')} tournamentType={tournamentType} />}
        {asGrid && <PlayoffBracket pool={pool} tournamentType={tournamentType} item_tournament={item_tournament} />}
      </SeasonMatchesProvider>
      return mss
    }
  }

  const menuSidebars = () => <MenuSidebars
    sidebarType={sidebarType}
    sidebar_items={sidebar_items}
    sidebar_handlers={sidebar_handlers}
    content={selectedMatches()}
    dimmed={dimmed}
    inverted={inverted}
    key={uniqueKey('ms', 'cts')}
  />

  if (noData) {
    return <NoData fullCaption={'No Playoff Matches'} />
  } else {
    switch (tournamentType) {
      case tournamentTypes.existingPlayoff:
        return tournaments && ms_tournaments && ms_pools ? menuSidebars() : <Segment basic>---</Segment>
      default:
        return tournaments && ms_tournaments && ms_rounds && ms_pools ? menuSidebars() : <Segment basic>---</Segment>
    }
  }
}

export default CreateTournamentSchedule