import _ from 'lodash';
import { formatItem, formatTypes } from '../../../global/common/dateFormatting';
import { sortObject } from '../../../global/common/sorting';
import { calcMatchStatus } from './calcMatchStatus';
import { copyObj } from '../../../global/common_web/copy';

export const calcGolfRounds = (ts, golfTournament, rounds, matches, holes, teams) => {

  const roundsStatus = {}
  const roundsStatusByHole = {}

  // sort the rounds
  const roundsSorted = sortObject(rounds, 'roundNumber')

  // create roundsStatus
  Object.keys(roundsSorted).forEach(key => {

    const round = roundsSorted[key]

    const matches_round = matches && matches[key] ? matches[key] : {}

    // const matches_round = matches ? _.filter(matches, { 'round': key }) : {}

    const { roundStatus, roundStatusByHole } = matches_round ? calcRound(matches_round, holes, teams) : {}

    roundsStatus[key] = roundStatus
    roundsStatus[key].name = round.name
    roundsStatus[key].roundType = round.roundType
    roundsStatus[key].handicapPercentage = round.handicapPercentage
    roundsStatus[key].lowHandicapPercentage = round.lowHandicapPercentage
    roundsStatus[key].golfersPerTeam = round.golfersPerTeam
    roundsStatus[key].startDate = round.startDate ? formatItem(formatTypes.fullDate, round.startDate) : formatItem(formatTypes.shortDate, golfTournament.startDate)
    roundsStatus[key].startTime = round.startTime ? round.startTime : 'Time'
    roundsStatus[key].roundIsActive = round.roundIsActive
    roundsStatus[key].allowOpenScoring = round.allowOpenScoring

    roundsStatusByHole[key] = roundStatusByHole
  })

  if (roundsStatus) {

    Object.keys(roundsStatus).forEach(key => {

      const rs = roundsStatus[key]

      ts.team1Wins += rs.team1Wins
      ts.team1Dormies += rs.team1Dormies
      ts.team1Leading += rs.team1Leading
      ts.team1Virtual += (rs.team1Wins + rs.team1Leading)
      ts.team1Square += rs.matchesSquare
      ts.team1Halved += rs.matchesHalved

      ts.team2Wins += rs.team2Wins
      ts.team2Dormies += rs.team2Dormies
      ts.team2Leading += rs.team2Leading
      ts.team2Virtual += (rs.team2Wins + rs.team2Leading)
      ts.team2Square += rs.matchesSquare
      ts.team2Halved += rs.matchesHalved

      ts.matchesHalved += rs.matchesHalved
    })

    ts.matchesSquare = ts.team1Square
    ts.team1Points = ts.team1Wins + (ts.team1Halved * 0.5) + (ts.team1Dormies * 0.5)
    ts.team2Points = ts.team2Wins + (ts.team2Halved * 0.5) + (ts.team2Dormies * 0.5)

    const { playersPerTeam, previousWinningTeam } = golfTournament ? golfTournament : 14
    const pointsToWin = playersPerTeam ? parseInt(playersPerTeam) : 14

    if (parseInt(previousWinningTeam) === 1) {
      ts.team1PointsToWin = pointsToWin
      ts.team2PointsToWin = pointsToWin + .5
    } else {
      ts.team1PointsToWin = pointsToWin + .5
      ts.team2PointsToWin = pointsToWin
    }

    ts.team1PointsToNeededWin = ts.team1PointsToWin - ts.team1Points
    ts.team2PointsToNeededWin = ts.team2PointsToWin - ts.team2Points

    if (ts.team1PointsToNeededWin < 0) { ts.team1PointsToNeededWin = 0 }
    if (ts.team2PointsToNeededWin < 0) { ts.team2PointsToNeededWin = 0 }
  }

  return { roundsStatus, roundsStatusByHole }

}

const calcRound = (golfMatches, holes, teams) => {

  const roundMatchStatuses = {}
  const roundMatchStatusesByHole = {}

  let roundStatus;
  const roundStatusByHole = {};

  Object.keys(golfMatches).forEach(key => {
    const gm = golfMatches[key]
    roundMatchStatuses[gm.id] = calcMatchStatus(gm, holes, teams)
  })

  Object.keys(golfMatches).forEach(key => {
    const gm = golfMatches[key]
    for (var hn = 1; hn <= 18; hn++) {
      if (!roundMatchStatusesByHole[hn]) { roundMatchStatusesByHole[hn] = {} }
      roundMatchStatusesByHole[hn][gm.id] = calcMatchStatus(gm, holes, teams, hn)
    }
  })

  if (roundMatchStatuses) {
    roundStatus = ammendRoundStatus(roundMatchStatuses)
  } else {
    roundStatus = {}
  }

  if (roundMatchStatusesByHole) {
    for (var hn = 1; hn <= 18; hn++) {
      if (roundMatchStatusesByHole[hn]) {
        roundStatusByHole[hn] = ammendRoundStatus(roundMatchStatusesByHole[hn])
      }
    }
  }

  return { roundStatus, roundStatusByHole }
}

const ammendRoundStatus = (roundMatchStatuses) => {

  const matchesFinished = _.filter(roundMatchStatuses, { finished: true })
  const matchesInProgress = _.filter(roundMatchStatuses, { finished: false })

  const team0Wins = _.filter(matchesFinished, { leadingTeam: 0 })
  const team1Wins = _.filter(matchesFinished, { leadingTeam: 1 })
  const team2Wins = _.filter(matchesFinished, { leadingTeam: 2 })

  const team0Leading = _.filter(matchesInProgress, { leadingTeam: 0 })
  const team1Leading = _.filter(matchesInProgress, { leadingTeam: 1 })
  const team2Leading = _.filter(matchesInProgress, { leadingTeam: 2 })

  const team1Dormies = _.filter(matchesInProgress, { dormieTeam: 1 })
  const team2Dormies = _.filter(matchesInProgress, { dormieTeam: 2 })

  const roundStatus = {
    matchesTotal: Object.keys(roundMatchStatuses).length,
    matchesComplete: matchesFinished.length,
    matchesRemaining: matchesInProgress.length,
    team1Wins: team1Wins.length,
    team2Wins: team2Wins.length,
    team1Dormies: team1Dormies.length,
    team2Dormies: team2Dormies.length,
    team1Leading: team1Leading.length,
    team2Leading: team2Leading.length,
    matchesHalved: team0Wins.length,
    matchesSquare: team0Leading.length,
    matchStatuses: roundMatchStatuses,
    team1Points: 0,
    team2Points: 0
  }

  roundStatus.team1Points = roundStatus.team1Wins + (roundStatus.matchesHalved * 0.5) + (roundStatus.team1Dormies * 0.5)
  roundStatus.team1Halved = roundStatus.matchesHalved
  roundStatus.team2Points = roundStatus.team2Wins + (roundStatus.matchesHalved * 0.5) + (roundStatus.team2Dormies * 0.5)
  roundStatus.team2Halved = roundStatus.matchesHalved
  roundStatus.roundComplete = roundStatus.matchesTotal > 0 && (roundStatus.matchesTotal === roundStatus.matchesComplete) ? true : false
  roundStatus.roundStrokeInfo = calcStrokeInfo(roundStatus)

  return roundStatus
}

const calcStrokeInfo = (roundStatus) => {

  if (roundStatus) {
    const round_ss = {
      strokingAdvantage1: 0,
      strokingAdvantage2: 0,
      strokingDisadvantage1: 0,
      strokingDisadvantage2: 0,
      strokesNone: 0,
      strokesEven: 0,
      strokingDiff: 0,
    }

    const hole_ss = {}

    const { matchStatuses } = roundStatus ?? {}

    if (matchStatuses) {
      // loop the matchStatuses
      Object.keys(matchStatuses).forEach(msk => {
        const matchStatus = matchStatuses[msk]
        const { holeStrokesStatus } = matchStatus ? matchStatus : {}
        const { holeStrokes } = holeStrokesStatus ? holeStrokesStatus : {}
        if (holeStrokes) {
          for (var holeNumber = 1; holeNumber <= 18; holeNumber++) {
            const holeStroke = holeStrokes[holeNumber]
            const { strokingAdvantage1, strokingAdvantage2, strokingDisadvantage1, strokingDisadvantage2, strokesNone, strokesEven } = holeStroke ? holeStroke : {}
            round_ss.strokingAdvantage1 += strokingAdvantage1
            round_ss.strokingAdvantage2 += strokingAdvantage2
            round_ss.strokingDisadvantage1 += strokingDisadvantage1
            round_ss.strokingDisadvantage2 += strokingDisadvantage2
            round_ss.strokesNone += strokesNone
            round_ss.strokesEven += strokesEven
            round_ss.strokingDiff += (strokingAdvantage1 + strokingAdvantage1) - (strokingDisadvantage1 + strokingDisadvantage2)

            if (!hole_ss[holeNumber]) { hole_ss[holeNumber] = copyObj(round_ss) }
            hole_ss[holeNumber].strokingAdvantage1 += strokingAdvantage1
            hole_ss[holeNumber].strokingAdvantage2 += strokingAdvantage2
            hole_ss[holeNumber].strokingDisadvantage1 += strokingDisadvantage1
            hole_ss[holeNumber].strokingDisadvantage2 += strokingDisadvantage2
            hole_ss[holeNumber].strokesNone += strokesNone
            hole_ss[holeNumber].strokesEven += strokesEven
            hole_ss[holeNumber].strokingDiff += (strokingAdvantage1 + strokingAdvantage1) - (strokingDisadvantage1 + strokingDisadvantage2)

          }
        }
      })

      return { round_ss, hole_ss }
    }


  }


}