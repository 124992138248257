import { createRefPath_event } from '../../firestoreData/appData/appRefPaths';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { fs_update_doc } from '../../firestoreData/appData/fsData';

const rts = {
  handleInit_rating: 'handleInit_rating',
  handleUpdate_rating: 'handleUpdate_rating',
  handleResponse_rating: 'handleResponse_rating',
  handleUpdate_ratingManager: 'handleUpdate_ratingManager',
}

export const _collectionName = '_ratings'

export const ratingReducer = (state, action) => {

  const {
    allowRating,
    pathViews,
  } = state

  const { dispatch, type } = action

  // eslint-disable-next-line
  const { handleResponse_rating } = ratingHandlers(dispatch)

  switch (type) {

    case rts.handleInit_rating:
      if (allowRating && pathViews.events) {
        const _refPath = createRefPath_event(pathViews, [_collectionName])
        fs_get_data({ refPath: _refPath, callback: handleResponse_rating, opts: { listen: true } })
      }
      return { ...state, ratings: {} }

    // // sets the data after the handleInit_rating call
    case rts.handleResponse_rating:
      return { ...state, ratings: action.data }

    case rts.handleUpdate_ratingManager:
      updateRatingManager(pathViews, action.itemKey, action.data, action.callback)
      return { ...state }

    case rts.handleUpdate_rating:
      updateRate(pathViews, action)
      return { ...state }
    default:
      return { ...state }
  }
}

export const ratingInitialState = (initState) => {
  return { ...initState }
};

export const ratingHandlers = (dispatch) => {
  return {
    handleUpdate_rating: (uid, currentRating, r, itemKey) => { dispatch({ type: rts.handleUpdate_rating, dispatch, uid, currentRating, r, itemKey }) },
    handleInit_rating: () => { dispatch({ type: rts.handleInit_rating, dispatch }) },
    handleResponse_rating: (data) => { dispatch({ type: rts.handleResponse_rating, dispatch, data }) },
    handleUpdate_ratingManager: (itemKey, data, callback) => { dispatch({ type: rts.handleUpdate_ratingManager, dispatch, itemKey, data, callback }) },
  }
}

const updateRatingManager = async (pathViews, item_key, data, callback) => {
  if (pathViews && item_key && data) {
    const _updateRef2 = createRefPath_event(pathViews, [_collectionName, item_key])
    fs_update_doc(_updateRef2, data, callback)
  }
}

const updateRate = async (pathViews, action, callback) => {
  const { uid, currentRating, r, itemKey } = action ?? {}
  const { _itemKey, appUserRatings } = currentRating ?? {}
  let _appUserRatings = appUserRatings ?? {}
  if (pathViews && r) {
    _appUserRatings[uid] = r
    const dataToUpdate = { appUserRatings: _appUserRatings }
    if (_itemKey) {
      const _updateRef2 = createRefPath_event(pathViews, [_collectionName, _itemKey])
      fs_update_doc(_updateRef2, dataToUpdate, callback)
    } else {
      const _updateRef2 = createRefPath_event(pathViews, [_collectionName, itemKey])
      dataToUpdate.ratingActive = true
      fs_update_doc(_updateRef2, dataToUpdate, false, callback)
    }
  }
} 