export const addIdsToDataItem = (firstData) => {
  if (firstData) {
    Object.keys(firstData).forEach(key => {
      const _dataItem = firstData[key]
      _dataItem.id = key
      _dataItem._itemKey = key
    })
  }
}

export const addParentKeysToDataItem = (parentKeys, firstData) => {
  if (firstData && parentKeys) {
    Object.keys(firstData).forEach(key => {
      const _dataItem = firstData[key]
      Object.keys(parentKeys).forEach(pk => {
        _dataItem[pk] = parentKeys[pk]
      })
    })
  }
}

export const updateStyleSettings = (styleAndClass, styleType, styleValue) => {
  const { style } = styleAndClass ?? {}
  const _style = { ...style, [styleType]: styleValue }
  return { ...styleAndClass, style: _style }
};