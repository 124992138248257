import _ from 'lodash'
import { envTypes, getAppName, getFbConfig } from "../../project/appConfiguration"
import { frameworkDashboardTypes, frameworkLeftTypes } from "../cnr/reducers/FrameworkReducer"
import { leftMenuTriggerTypes, leftMenuTypes } from '../cnr/reducers/LeftMenuReducer'
import { allDataModes } from "../components/viewers/AppDashboard"
import { mobileDeviceTypes } from "../devices"
import { appIconTypes } from "../enums/appIconTypes"
import { gEnums } from "../enums/globalEnums"
import { settingsIconTypes } from "../enums/settingsIconTypes"
import { getDataSourceItem } from "../page/DataSourceSelect"
import { _appVersion } from '../cnr/contexts/ServiceWorkerContext'

export const appOptions_left = {
  action: (handlers) => options_action(handlers),
  actions: (handlers, settings) => options_actions(handlers, settings),
  admin: (handlers, settings) => options_admin(handlers, settings),
  admins: (handlers, settings) => options_admins(handlers, settings),
  appAdmin: (handlers, settings) => options_appAdmin(handlers, settings),
  appAdmins: (handlers, settings) => options_appAdmins(handlers, settings,),
  appInfos: (handlers, settings) => options_appInfos(handlers, settings),
  appUser: (handlers, settings) => options_appUser(handlers, settings),
  appUsers: (handlers) => options_appUsers(handlers),
  confirmAction: (handlers) => options_confirmation(handlers),
  confirmation: (handlers) => options_confirmation(handlers),
  dashboard: (handlers, settings) => options_dashboard(handlers, settings),
  dashboards: (handlers, settings) => options_dashboards(handlers, settings),
  dataSource: (handlers, settings) => options_dataSource(handlers, settings),
  dataSources: (handlers) => options_dataSources(handlers),
  device: (handlers) => options_device(handlers),
  devices: (handlers) => options_devices(handlers),
  external: (handlers) => options_external(handlers),
  externals: (handlers, settings) => options_externals(handlers, settings),
  home: (handlers, settings) => options_home(handlers, settings),
  input: (handlers, settings) => options_input(handlers, settings),
  overrides: (handlers, settings) => options_overrides(handlers, settings),
}

const getOptions = (oc, currentIcon, cornerIcon, item, updating, iconColor, opts) => ({
  default: {
    text: item && item.text,
    caption: item && item.text,
    icon: item && item.icon,
    color: item && item.color,
    lmt: item && item.lmt,
    frt: item && item.frt,
    oc: oc,
    dividerBefore: true,
  },
  action: {
    text: 'Action',
    icon: 'wizard',
    lmt: leftMenuTypes.actions,
    oc: oc,
    dividerBefore: true
  },
  admin: {
    text: 'Admin',
    icon: 'options',
    lmt: leftMenuTypes.admins,
    oc: oc,
    dividerBefore: true,
    iconCorner: 'user',
    showIconCorner: true,
    iconCornerColor: gEnums.accessLevels.admin.color,
  },
  appAdmin: {
    text: 'App Admin',
    icon: 'options',
    lmt: leftMenuTypes.appAdmins,
    oc: oc,
    iconCorner: 'user',
    showIconCorner: true,
    iconCornerColor: gEnums.accessLevels.appAdmin.color,
  },
  appInfo: {
    text: 'App Info',
    icon: 'info circle',
    color: 'blue',
    lmt: leftMenuTypes.appInfos,
    oc: oc,
    dividerBefore: true
  },
  appUser: {
    text: 'App User',
    icon: currentIcon && currentIcon.name,
    color: currentIcon && currentIcon.color,
    lmt: leftMenuTypes.appUsers,
    oc: oc,
    dividerBefore: true,
    opts,
  },
  confirmation: {
    text: 'Confirm Action',
    icon: 'check',
    color: 'green',
    lmt: leftMenuTypes.confirmAction,
    frt: 'confirmAction',
    oc: oc,
    dividerBefore: true,
    showCaption: true
  },
  dashboard: {
    text: 'App Dashboard',
    icon: appIconTypes.appDashboard,
    lmt: leftMenuTypes.dashboards,
    oc: oc,
    dividerBefore: true
  },
  dataSource: {
    text: 'Data Source',
    icon: 'server',
    color: iconColor,
    lmt: leftMenuTypes.dataSources,
    oc: oc,
    iconCorner: cornerIcon && cornerIcon.icon,
    iconCornerColor: cornerIcon && cornerIcon.color,
    showIconCorner: true,
    updating,
  },
  device: {
    text: 'Device',
    icon: settingsIconTypes.tv,
    color: 'grey',
    lmt: leftMenuTypes.devices,
    oc: oc,
    dividerBefore: true
  },
  external: {
    text: 'Open External',
    icon: 'external alternate',
    color: 'orange',
    lmt: leftMenuTypes.externals,
    oc: oc,
    dividerBefore: true
  },
  gotoExternal: {
    text: item && item.text,
    showCaption: true,
    icon: item && item.icon,
    color: item && item.color,
    lmt: item && item.lmt,
    oc: oc
  },
  input: {
    text: 'GS Key',
    icon: 'check',
    color: 'green',
    // lmt: leftMenuTypes.confirmInput,
    // frt: 'confirmAction',
    oc: oc,
    dividerBefore: true,
    showCaption: true,
    showInput: true,
  },
});

const options_appInfos = (handlers, settings) => {

  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  const { aps_global, deferredPrompt, appName } = settings ?? {}
  const showCaption = true

  const trueConfig = getFbConfig()
  const { projectId } = trueConfig ? trueConfig : {}

  const { topMenu, display, appAccess } = aps_global ?? {}

  const { mainFixedCaption } = topMenu ?? {}
  const { appAccessType } = appAccess ?? {}
  const { timeZone } = display ?? {}

  const opts = []
  appName && opts.push({ text: 'App Name: ', description: appName, icon: 'sitemap', showCaption })
  projectId && opts.push({ text: 'App Database: ', description: projectId, icon: 'database', showCaption })
  mainFixedCaption && opts.push({ text: 'Event: ', description: mainFixedCaption, icon: 'info circle', showCaption })
  timeZone && opts.push({ text: 'Time Zone: ', description: timeZone, icon: 'clock', showCaption })
  opts.push({ text: 'App Access: ', description: appAccessType ? _.startCase(appAccessType) : 'None', icon: 'key', dividerBefore: true, showCaption })
  opts.push({ text: 'Installable: ', description: deferredPrompt ? 'Yes' : 'No', icon: 'cloud download', dividerBefore: true, showCaption })
  _appVersion && opts.push({ text: 'Version: ', description: _appVersion, icon: 'hashtag', showCaption })
  opts.unshift(...closers(getOptions(hs_cmt).appInfo, hs_cmt))
  return opts
}

const options_input = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const opts = [{ ...getOptions(h_ta).input }]
  opts.unshift(...closers(getOptions(hs_cmt, { name: 'users' }).input, hs_cmt))
  return opts
}

const options_confirmation = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const opts = [{ ...getOptions(h_ta).confirmation }]
  opts.unshift(...closers(getOptions(hs_cmt, { name: 'users' }).confirmation, hs_cmt))
  return opts
}

const options_admin = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  const { currentIcon } = settings ?? {}
  return [{ ...getOptions(hs_cmt, currentIcon).admin }]
}

const options_appAdmin = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  const { currentIcon } = settings ?? {}
  return [{ ...getOptions(hs_cmt, currentIcon).appAdmin }]
}

const options_appUser = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  const { currentIcon, isClone } = settings ?? {}
  return [{ ...getOptions(hs_cmt, currentIcon, null, null, null, null, { ocn: isClone ? 'blink_me_2' : null }).appUser }]
}

const options_device = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  return [{ ...getOptions(hs_cmt).device }]
}

const options_external = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  return [{ ...getOptions(hs_cmt).external }]
}

const options_action = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  return [{ ...getOptions(hs_cmt).action }]
}

const options_dataSource = (handlers, settings) => {
  const { updating, _appDataSourceType, appDataSource } = settings ?? {}
  const { useAppDataDocuments, useAppDataDocumentsOnly } = appDataSource ?? {}
  const { handleSet_currentMenuType: hs_cmt, handleDatabaseRefresh } = handlers ?? {}
  const opts = []
  let iconColor = useAppDataDocuments ? 'blue' : 'grey'
  if (useAppDataDocumentsOnly) { iconColor = 'green' }
  const cornerIcon = { icon: getDataSourceItem(_appDataSourceType ? _appDataSourceType : gEnums.dataSourceTypes.firebase), color: iconColor }
  opts.push({ ...getOptions(hs_cmt, null, cornerIcon, null, updating, 'grey').dataSource })
  switch (_appDataSourceType) {
    case gEnums.dataSourceTypes.googleSpreadSheet:
      opts.push({ text: 'Refresh Google Sheets', icon: 'refresh', color: 'grey', oc: handleDatabaseRefresh })
      break;
    default:
    // nothing
  }
  return opts
}

const options_devices = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const mds = mobileDeviceTypes()
  const opts = mds.map(item => (
    { key: item.key, value: item.value, oc: h_ta, lmt: leftMenuTriggerTypes.device, frt: item.value, caption: _.startCase(item.text), text: _.startCase(item.text), icon: item.key === 'desktop' ? settingsIconTypes.tv : 'mobile alternate', color: 'blue' }
  ))
  opts.unshift(...closers(getOptions(hs_cmt).device, hs_cmt))
  return opts
}

const options_appUsers = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const opts = []
  _.each(gEnums.accessLevels, (accessLevel, key) => {
    const { caption, color, icon } = accessLevel
    opts.push({ text: caption, icon, oc: h_ta, color, dividerBefore: true, caption: caption, frt: accessLevel, lmt: leftMenuTriggerTypes.appUserAccess })
  })
  opts.push({ text: 'Reset', icon: 'refresh', oc: h_ta, color: 'blue', caption: 'Reset', frt: { value: 'r' }, lmt: leftMenuTriggerTypes.appUserAccess })
  opts.unshift(...closers(getOptions(hs_cmt, { name: 'users' }).appUser, hs_cmt))
  return opts
}

const options_dataSources = (handlers) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const mds = gEnums.dataSourceTypes
  const opts = Object.keys(mds).map(item => {
    const icon = getDataSourceItem(item)
    const _item = { text: _.startCase(item), icon, color: 'blue', lmt: leftMenuTriggerTypes.dataSource, frt: item }
    return { ...getOptions(h_ta, null, null, _item).default }
  })
  opts.unshift(...closers(getOptions(hs_cmt).dataSource, hs_cmt))
  return opts
}

const options_dashboard = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const { isEvent } = settings ?? {}

  const opts = []
  opts.push({ text: 'Settings', icon: appIconTypes.settings, oc: h_ta, lmt: leftMenuTriggerTypes.settings, frt: frameworkLeftTypes.settings, color: 'blue' })

  opts.push(getOptions(hs_cmt).dashboard)

  isEvent && opts.push({ text: 'App Data', icon: appIconTypes.list, oc: h_ta, lmt: leftMenuTriggerTypes.frameworkLeft, frt: frameworkLeftTypes.appData, dividerBefore: true })

  opts.push(getOptions(hs_cmt).admin)
  opts.push(getOptions(hs_cmt).appAdmin)

  return opts
}

const options_admins = (handlers, settings) => {

  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const { permissionGroups } = settings ?? {}
  const { admin } = permissionGroups ?? {}

  const showCaption = true
  const opts = []

  if (admin) {
    _.each(admin, (item) => {
      const { viewItem, segProps } = item
      const { key } = viewItem
      const { caption, icon } = segProps ?? {}
      const color = gEnums.accessLevels.admin.color
      let frt;
      let dividerBefore;
      switch (key) {
        case 'appProfilesManager':
          frt = frameworkLeftTypes.appProfilesManager
          dividerBefore = true
          break;
        case 'appUsersManager':
          frt = frameworkLeftTypes.appUsersManager
          break;
        default:
        // nothing
      }
      opts.push({ text: caption, icon: icon, showCaption, color, oc: h_ta, lmt: leftMenuTriggerTypes.frameworkLeft, frt: frt, dividerBefore })
    })
  }

  opts.unshift(...closers(getOptions(hs_cmt, { name: 'users' }).admin, hs_cmt))
  return opts
}

const options_appAdmins = (handlers, settings) => {
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const { permissionGroups } = settings ?? {}
  const { appAdmin, ticketing } = permissionGroups ?? {}

  const showCaption = true
  const opts = []

  if (appAdmin) {
    let dividerBefore = true
    const color = gEnums.accessLevels.appAdmin.color
    _.each(appAdmin, (item) => {
      const { itemKey, segProps } = item
      const { caption, icon } = segProps ?? {}
      opts.push({ text: caption, icon, icon: icon, showCaption, color, oc: h_ta, lmt: leftMenuTriggerTypes.frameworkLeft, frt: itemKey, dividerBefore })
      dividerBefore = false
    })
  }

  if (ticketing) {
    let dividerBefore = true
    const color = gEnums.accessLevels.appAdmin.color
    _.each(ticketing, (item) => {
      const { itemKey, segProps } = item
      const { caption, icon } = segProps ?? {}
      opts.push({ text: caption, icon, icon: icon, showCaption, color, oc: h_ta, lmt: leftMenuTriggerTypes.frameworkLeft, frt: itemKey, dividerBefore })
      dividerBefore = false
    })
  }

  opts.unshift(...closers(getOptions(hs_cmt, { name: 'users' }).appAdmin, hs_cmt))
  return opts
}

const options_home = (handlers, settings) => {

  const { handle_triggerAction: h_ta, handleSet_currentMenuType: hs_cmt } = handlers ?? {}

  const { showLeftMenuCaptions, online, offline, appArea, pathViews, appUsersPresense } = settings ?? {}

  const opts = []

  if (appUsersPresense) {
    opts.push({ text: 'Online', icon: 'users', color: 'green', count: online ? online.length : '0', oc: handlers.handleShow_frameworkLeft, frt: frameworkLeftTypes.appUsersPresense })
    opts.push({ text: 'Offline', icon: 'users', color: 'yellow', count: offline ? offline.length : '0' })
  }

  pathViews && pathViews.events && opts.push({ text: 'Goto Client', icon: 'handshake', oc: h_ta, lmt: leftMenuTriggerTypes.gotoClient, iconCorner: 'arrow left', dividerBefore: true })
  appArea !== 'home' && opts.push({ text: 'Goto Home', icon: appIconTypes.home, oc: h_ta, lmt: leftMenuTriggerTypes.gotoHome, iconCorner: 'arrow left' })

  opts.push(getOptions(hs_cmt).appInfo)
  opts.push({ text: showLeftMenuCaptions ? 'Close Menu' : 'Show Menu', icon: showLeftMenuCaptions ? 'arrow left' : 'arrow right', oc: h_ta, lmt: leftMenuTriggerTypes.toggle, dividerBefore: true, color: 'grey' })

  return opts
}

const options_overrides = (handlers, settings) => {

  const { handle_triggerAction: h_ta } = handlers ?? {}

  const { authOn, helpOn, dataOverrideOn, altDatabaseOn, settingsPreviewOn, isEvent, appArea, appEnv } = settings ?? {}

  const opts = []

  isEvent && opts.push({ text: 'Data Override', icon: 'unhide', color: dataOverrideOn ? 'green' : 'grey', oc: h_ta, lmt: leftMenuTriggerTypes.dataOverride, dividerBefore: true, iconCorner: dataOverrideOn ? 'check' : null, iconCornerColor: 'grey' })
  opts.push({ text: 'Help', icon: 'help circle', color: helpOn ? 'green' : 'grey', oc: h_ta, lmt: leftMenuTriggerTypes.help, iconCorner: helpOn ? 'check' : null, iconCornerColor: 'grey', dividerBefore: !isEvent })
  appArea === 'event' && opts.push({ text: 'Auth', icon: 'users', color: authOn ? 'green' : 'grey', oc: h_ta, lmt: leftMenuTriggerTypes.auth, iconCorner: authOn ? 'check' : null, iconCornerColor: 'grey' })
  opts.push({ text: 'Live Settings', icon: 'settings', color: settingsPreviewOn ? 'green' : 'grey', oc: h_ta, lmt: leftMenuTriggerTypes.settingsPreview, iconCorner: helpOn ? 'check' : null, iconCornerColor: 'grey', })
  opts.push({ text: appEnv === envTypes.development ? 'Prod Settings' : 'Dev Settings', icon: 'setting', color: altDatabaseOn ? 'green' : 'grey', oc: h_ta, lmt: leftMenuTriggerTypes.altDatabase, iconCorner: helpOn ? 'check' : null, iconCornerColor: 'grey', })
  // opts.push({ ...getOptions(h_ta, null, 'external alternate', { text: appEnv === envTypes.development ? 'Prod' : 'Dev', icon: 'sitemap', color: 'orange', lmt: leftMenuTriggers.gotoSite }).gotoExternal })

  return opts
}

const options_externals = (handlers, settings) => {

  const { handle_triggerAction: h_ta, handleSet_currentMenuType: hs_cmt } = handlers ?? {}

  const { _isSuperAdminDeveloper, isLocal, appEnv, allowGoogleSheets, googleSheetsId } = settings ?? {}

  const opts = []

  _isSuperAdminDeveloper && opts.push({ ...getOptions(h_ta, null, 'external alternate', { text: 'Open Database', icon: 'database', color: 'orange', lmt: leftMenuTriggerTypes.openDb }).gotoExternal })
  _isSuperAdminDeveloper && opts.push({ ...getOptions(h_ta, null, 'external alternate', { text: 'Open Storage', icon: 'server', color: 'orange', lmt: leftMenuTriggerTypes.openStorage }).gotoExternal })
  allowGoogleSheets && googleSheetsId && opts.push({ ...getOptions(h_ta, null, 'external alternate', { text: 'Open Google Sheets', icon: 'google drive', color: 'orange', lmt: leftMenuTriggerTypes.openGs }).gotoExternal })

  isLocal && opts.push({ ...getOptions(h_ta, null, 'external alternate', { text: 'Dev', icon: 'sitemap', color: 'grey', lmt: leftMenuTriggerTypes.gotoDev }).gotoExternal })
  opts.push({ ...getOptions(h_ta, null, 'external alternate', { text: appEnv === envTypes.development ? 'Prod' : 'Dev', icon: 'sitemap', color: 'orange', lmt: leftMenuTriggerTypes.gotoSite }).gotoExternal })

  opts.unshift(...closers(getOptions(hs_cmt).external, hs_cmt))

  return opts
}

const options_dashboards = (handlers, settings) => {
  const { handle_triggerAction: h_ta, handleSet_currentMenuType: hs_cmt } = handlers ?? {}
  const { isEvent, allowSd, dashboardType } = settings ?? {}
  const opts = []
  const showCaption = true

  const altOpts = { showCaption, oc: h_ta, lmt: leftMenuTriggerTypes.dashboard }

  opts.push({ text: 'App Dashboard', ...altOpts, icon: appIconTypes.appDashboard, color: 'blue', dividerBefore: true })

  opts.push({ text: 'App Check', ...altOpts, icon: 'check circle outline', frt: allDataModes.appCheck, iconCorner: 'arrow right', iconCornerColor: 'blue', dividerBefore: true })
  opts.push({ text: 'Files', ...altOpts, icon: 'file alternate outline', frt: allDataModes.fileManagement, iconCorner: 'arrow right', iconCornerColor: 'blue' })
  opts.push({ text: 'Google Dashboard', ...altOpts, icon: appIconTypes.googleDashboard, frt: allDataModes.googleDashboard, iconCorner: 'arrow right', iconCornerColor: 'blue' })
  opts.push({ text: 'Location Mapping', ...altOpts, icon: 'map outline', frt: allDataModes.locationMapping, iconCorner: 'arrow right', iconCornerColor: 'blue' })

  allowSd && opts.push({ text: dashboardType + ' Dashboard', ...altOpts, icon: appIconTypes.sports, frt: frameworkDashboardTypes.sportsViewer, dividerBefore: true })

  opts.unshift(...closers(getOptions(hs_cmt).dashboard, hs_cmt))
  return opts
}

const options_actions = (handlers, settings) => {
  const { allowAppDataDocumentsUpdate, pathViews } = settings ?? {}
  const { clients, events } = pathViews ?? {}
  const _isClient = clients && !events
  const _isEvent = clients && events
  const { handleSet_currentMenuType: hs_cmt, handle_triggerAction: h_ta } = handlers ?? {}
  const showCaption = true
  const altOpts = { showCaption, oc: h_ta }

  const _noOptions = !_isClient && !allowAppDataDocumentsUpdate
  const opts = []
  _noOptions && opts.push({ text: 'No Actions', showCaption })
  allowAppDataDocumentsUpdate && opts.push({ text: 'Create Documents', ...altOpts, icon: 'arrow right', color: 'blue', dividerBefore: true, lmt: leftMenuTriggerTypes.appDataDocuments, useConfirm: true })
  allowAppDataDocumentsUpdate && opts.push({ text: 'Create App Links', ...altOpts, icon: 'arrow right', color: 'blue', lmt: leftMenuTriggerTypes.appDataLinks, useConfirm: true })
  allowAppDataDocumentsUpdate && opts.push({ text: 'Create App Profiles', ...altOpts, icon: 'arrow right', color: 'blue', lmt: leftMenuTriggerTypes.appProfiles, useConfirm: true })
  allowAppDataDocumentsUpdate && opts.push({ text: 'Demo App From Google Sheet', ...altOpts, icon: 'arrow right', color: 'blue', lmt: leftMenuTriggerTypes.demoApp, useInput: true })
  _isClient && opts.push({ text: 'Create New Event', ...altOpts, icon: 'arrow right', color: 'blue', lmt: leftMenuTriggerTypes.frameworkLeft, frt: frameworkLeftTypes.createNewEvent })
  _isEvent && opts.push({ text: 'Duplicate Event', ...altOpts, icon: 'arrow right', color: 'blue', lmt: leftMenuTriggerTypes.frameworkLeft, frt: frameworkLeftTypes.recreateNewEvent })
  opts.unshift(...closers(getOptions(hs_cmt).action, hs_cmt))
  return opts
}

const closers = (options, hs_cmt) => {
  const { text } = options ?? {}
  const opts = []
  opts.push({ text: text, icon: appIconTypes.delete, oc: hs_cmt, color: 'red', showCaption: true })
  return opts
}