// import { updateSchoolSports } from './schoolSports';
import { seasonalUpdateTypes, seasonalHelpers } from '../cnr/reducers/reducerHelpers/seasonalSportsData';

export const updateRankings = async (pathViews, pks, actionProps, handleDone) => {

  const { selectedLevel, selectedWeek, sortedLevels } = actionProps
  const sortedLevel = sortedLevels[selectedLevel]
  const teams = []
  sortedLevel.forEach((sl, index) => {
    if (index < 10) { teams.push(sl.name) }
  })
  const data = {
    [selectedWeek]: {
      [selectedLevel]: teams
    }
  }

  const _updateProps = seasonalHelpers.getUpdateProps(pathViews, pks, handleDone, 'rankings', 'rankings', data)
  seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.rankings, _updateProps, 'rankings', 'rankings', data, handleDone)
}