import React, { useContext, useEffect, useReducer } from "react";
import { getAppUserAccess } from "../../../../global/auth/appUserAccessPermissions";
import { ParentContext } from "../../../../global/cnr/contexts/ParentContext";
import { sportsRtMatchHandlers, sportsRtMatchInitialState, sportsRtMatchReducer } from "../reducers/SportsRtMatchReducer";
import { SeasonMatchesContext } from "./SeasonMatchesContext";
import { SportsSeasonContext, _resultsProp } from "./SportsSeasonContext";
import { SportsMatchContext } from "./SportsMatchContext";

export const SportsRtMatchContext = React.createContext()

export default function SportsRtMatchProvider(props) {

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state } = states ?? {}
  const { appUsers } = appUser_state ?? {}
  const { pathViews } = paps_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const appUserInfo = appUserAccess ? { displayName: appUserAccess.displayName, uid: appUserAccess.uid } : {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { matches_info, latestSeason, rtSettings, sportPermissions } = sportsSeason_state ?? {}
  const { matches_realtime } = matches_info ?? {}

  // seasonMatchesContext
  const seasonMatchesContext = useContext(SeasonMatchesContext)

  const componentContexts = { sportsSeasonContext, seasonMatchesContext }

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true } = sportsMatch_state ?? {}

  const init_state = { sportPermissions, rtSettings, latestSeason, appUserInfo, pathViews }
  const [sportsRtMatch_state, sportsMatchRealtime_dispatch] = useReducer(sportsRtMatchReducer, init_state, sportsRtMatchInitialState)
  const sportsRtMatch_handlers = sportsRtMatchHandlers(sportsMatchRealtime_dispatch, sportsRtMatch_state)

  useEffect(() => {
    if (match_true) {
      sportsRtMatch_handlers.handleSet_trueMatch(match_true)
      const realtimeMatches = matches_realtime ? matches_realtime[match_true._itemKey] : {}
      const _allResults = []
      if (realtimeMatches) {
        Object.keys(realtimeMatches).forEach(rmKey => {
          const realtimeMatch = realtimeMatches[rmKey]
          const results = realtimeMatch ? realtimeMatch[_resultsProp] : undefined;
          _allResults.push(results)
        })
        if (_allResults && _allResults.length > 0) {
          sportsRtMatch_handlers.handleSet_rtMatch({ ..._allResults[0] })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matches_realtime, match_true]);

  return <SportsRtMatchContext.Provider value={{ sportsRtMatch_state, sportsRtMatch_handlers, componentContexts }}>
    {props.children}
  </SportsRtMatchContext.Provider>
}