import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { appSettingsHandlers, appSettingsInitialState, appSettingsReducer } from '../reducers/AppSettingsReducer';
import { _showStates } from '../../../App';
import { ServiceWorkerContext, _appVersion } from './ServiceWorkerContext';
import { AuthContext } from './AuthContext';
import { getAppConfigSettings } from '../../../project/appConfiguration';

export const AppSettingsContext = createContext();

/**
 * Simple wrapper to handle app settings changes
 */
const AppSettingsProvider = (props) => {

  const { hp } = props ?? {}

  const serviceWorkerContext = useContext(ServiceWorkerContext)
  const { serviceWorker_handlers } = serviceWorkerContext ?? {}

  const authContext = useContext(AuthContext)
  const { auth_state } = authContext ?? {}
  const { currentAuthUser } = auth_state ?? {}
  const { uid: authUid } = currentAuthUser ?? {}

  const init_state = { hp, authUid }
  const [appSettings_state, appSettings_dispatch] = useReducer(appSettingsReducer, appSettingsInitialState(init_state))
  const appSettings_handlers = appSettingsHandlers(appSettings_dispatch)
  const { allAppSettings } = appSettings_state ?? {}
  const { home } = allAppSettings ?? {}
  const { global } = home ?? {}
  const { appVersion } = global ?? {}
  const { currentAppVersion } = appVersion ?? {}

  useEffect(() => {
    if (currentAppVersion) {
      serviceWorker_handlers.handleSet_currentAppVersion(currentAppVersion)
      serviceWorker_handlers.handleSend_messageToServiceWorker({ type: 'test' })
    }
  }, [currentAppVersion]);

  if (_showStates.any && _showStates.appSettings) {
    console.log(Object.keys(appSettings_state))
    console.log(Object.keys(appSettings_handlers))
  }

  const checkAppSetting = () => {
    console.log('checkSetting', appSettings_state)
  }

  const fns = { checkAppSetting }

  return <AppSettingsContext.Provider value={{ appSettings_state, appSettings_handlers, appSettings_fns: fns, appVersion: _appVersion }}>
    {props.children}
  </AppSettingsContext.Provider>
};

export default AppSettingsProvider