import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { deleteField } from 'firebase/firestore';
import { _authProjectName } from '../../components/user/UserAuth';
import { appFormTypes } from '../../enums/appFormTypes';
import { createRefPath_client } from '../../firestoreData/appData/appRefPaths';
import { fs_update_doc } from '../../firestoreData/appData/fsData';
import { fsfn_auth } from "../../functions/fbAuth";
import { fsfn_notifications } from "../../functions/fbNotifications";
import { dispatchProps, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";

export const appUserManagerInitialState = (initState) => {
  return { ...initState }
};

export const rts = {
  handleCancel: 'handleCancel',
  handleGet_appUser: 'handleGet_appUser',
  handleReset_disclaimerResponses: 'handleReset_disclaimerResponses',
  handleSend_notificationSmsOrEmail: 'handleSend_notificationSmsOrEmail',
  handleSend_passwordResetEmail: 'handleSend_passwordResetEmail',
  handleSet_authData: 'handleSet_authData',
  handleSet_selectedAppUser: 'handleSet_selectedAppUser',
  ...grts
}

export const userMenuItems = {
  editAuthorization: 'editAuthorization',
  editProfile: 'editProfile',
  openAuthorization: 'openAuthorization',
  openDatabaseProfile: 'openDatabaseProfile',
  resetDisclaimerResponses: 'resetDisclaimerResponses',
  sendEmail: 'sendEmail',
  sendPasswordResetEmail: 'sendPasswordResetEmail',
  sendPushNotification: 'sendPushNotification',
  sendSignInLinkEmail: 'sendSignInLinkEmail',
  sendSms: 'sendSms',
}

export const showAppManagerTypes = {
  auth: 'auth',
  email: 'email',
  emailSignInLink: 'emailSignInLink',
  emailPasswordReset: 'emailPasswordReset',
  notification: 'notification',
  profile: 'profile',
  sms: 'sms',
}

export const appUserManagerReducer = (state, action) => {

  const { selectedAppUser, selectedAppUserAuth, logging, paps_state } = state
  const { pathViews, sharePath } = paps_state ?? {}
  const { dispatch, formData } = action
  const { title, body, text, subject } = formData ?? {}

  const { handleSet_authData, handleCancel } = appUserManagerHandlers(dispatch)

  switch (action.type) {

    case rts.handleGet_appUser:
      fsfn_auth.getAuthByEmail(selectedAppUser, handleSet_authData, _authProjectName)
      return { ...state }

    case rts.handleSet_authData:
      const { uid } = action.result ?? {}
      if (uid) {
        return { ...state, authRetrieved: true, selectedAppUserAuth: { ...action.result, ...selectedAppUser } }
      } else {
        return { ...state, authRetrieved: true, selectedAppUserAuth: { ...selectedAppUser } }
      }

    case rts.handleSet_selectedAppUser:
      switch (action.item) {
        case userMenuItems.editAuthorization:
          return { ...state, showAppManagerType: showAppManagerTypes.auth }
        case userMenuItems.sendEmail:
          return { ...state, showAppManagerType: showAppManagerTypes.email, appFormType: appFormTypes.emailDirect }
        case userMenuItems.sendSignInLinkEmail:
          return { ...state, showAppManagerType: showAppManagerTypes.emailSignInLink, appFormType: appFormTypes.emailDirect }
        case userMenuItems.sendPasswordResetEmail:
          return { ...state, showAppManagerType: showAppManagerTypes.emailPasswordReset, appFormType: appFormTypes.emailDirect }
        case userMenuItems.sendPushNotification:
          return { ...state, showAppManagerType: showAppManagerTypes.notification, appFormType: appFormTypes.notificationsDirect }
        case userMenuItems.editProfile:
          return { ...state, showAppManagerType: showAppManagerTypes.profile }
        case userMenuItems.sendSms:
          return { ...state, showAppManagerType: showAppManagerTypes.sms, appFormType: appFormTypes.smsDirect }
        default:
          return { ...state, showAppManagerType: null }
      }

    case rts.handleCancel:
      return { ...state, showAppManagerType: null, confirmation: null, updating: null }

    case rts.handleReset_disclaimerResponses:
      const _refPath = createRefPath_client(pathViews, ['profiles', action.email])
      const dataToUpdate = {
        disclaimerResponses: deleteField()
      }
      fs_update_doc(_refPath, dataToUpdate)
      return { ...state }

    case rts.handleSend_notificationSmsOrEmail:

      switch (state.showAppManagerType) {

        case showAppManagerTypes.email:
          if (selectedAppUserAuth && selectedAppUserAuth.email) {
            const nd = {
              email: {
                recipient: selectedAppUserAuth.email,
                sender: 'support@meetingevolution.net',
                subject,
                body,
              }
            }
            fsfn_notifications.sendEmail(nd).then(response_data => {
              const { success, error } = response_data ?? {}
              dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The Email has been sent' })
              // handleCancel()
            }).catch(error => {

            })
          }
          break;

        case showAppManagerTypes.notification:
          if (selectedAppUserAuth && selectedAppUserAuth.fcmTokens) {
            const notificationData = {
              title,
              body,
              sharePath,
            }

            if (pathViews.clients) { notificationData.clientKey = pathViews.clients }
            if (pathViews.events) { notificationData.eventKey = pathViews.events }

            fsfn_notifications.sendNotificationToSelf({ notificationData, appUserAccess: selectedAppUserAuth, paps_state, logging }).then(response_data => {
              const { success, error } = response_data ?? {}
              console.log('response_data', response_data)
              dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The message has been sent' })
            }).catch(error => {
              handleCancel()
              console.error(error);
            })
          }
          break;

        case showAppManagerTypes.sms:
          if (selectedAppUserAuth && selectedAppUserAuth.phoneNumber) {
            const data = {
              to: selectedAppUserAuth.phoneNumber,
              text,
            }
            fsfn_notifications.sendSms(data, logging).then(response_data => {
              const { success, error } = response_data ?? {}
              dispatch && dispatch({ type: success ? dispatchProps.success : dispatchProps.error, error, dispatch, content: 'The message has been sent' })
            }).catch(error => {
              handleCancel()
              console.error(error);
            })
          }
          break;
        default:
      }
      return { ...state, updating: true, updateCaption: 'Sending' }

    case rts.handleSend_passwordResetEmail:
      const auth = getAuth()
      sendPasswordResetEmail(auth, action.email).then(() => {
        dispatch && dispatch({ type: dispatchProps.success, dispatch, content: 'The email has been sent' })
      }).catch(error => {
        console.log('error', error)
        dispatch && dispatch({ type: dispatchProps.error, error, dispatch, content: 'The email has NOT been sent' })
      })
      return { ...state }

    case rts.handleStartUpdate:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { onClose: handleCancel, dispatch, content: action.content })

    default:
      return { ...state }
  }
}

export const appUserManagerHandlers = (dispatch) => {
  return {
    handleCancel: () => dispatch({ type: rts.handleCancel, dispatch }),
    handleGet_appUser: () => dispatch({ type: rts.handleGet_appUser, dispatch }),
    handleReset_disclaimerResponses: (email) => dispatch({ type: rts.handleReset_disclaimerResponses, dispatch, email }),
    handleSend_notificationSmsOrEmail: (formData, _itemKey) => dispatch({ type: rts.handleSend_notificationSmsOrEmail, dispatch, formData, _itemKey }),
    handleSend_passwordResetEmail: (email) => dispatch({ type: rts.handleSend_passwordResetEmail, dispatch, email }),
    handleSet_authData: (result) => dispatch({ type: rts.handleSet_authData, dispatch, result }),
    handleSet_selectedAppUser: (item) => dispatch({ type: rts.handleSet_selectedAppUser, dispatch, item }),
    ...responseHandlers(dispatch)
  }
}