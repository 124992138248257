import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { sidebarMenuTypes, sidebarSliderTypes } from '../../../../global/cnr/reducers/SidebarReducer';
import { uniqueKey } from '../../../../global/common/keys';
import SwiperWrapper from '../../../../global/components/swipers/SwiperWrapper';
import { currentHelpers } from '../../../../global/redirection/current';
import SeasonMatchesProvider from '../../cnr/contexts/SeasonMatchesContext';
import SectionsLegend from '../standings/SectionsLegend';
import MatchListSelected from './MatchListSelected';
import PlayoffBracket, { playoffBracketTypes } from './PlayoffBracket';

const PlayoffBrackets = (props) => {

  const { componentContexts, round, roundItem, tournamentType, asGrid, item_tournament } = props
  const { pools: pools_init } = round ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { page_fns } = fns
  const { pushSimple } = page_fns ?? {}

  const { storageItemPage } = paps_state ?? {}

  // componentContexts
  const { uiItemContext } = componentContexts ?? {}
  const { item_state } = uiItemContext ?? {}
  const { viewItem } = item_state ?? {}

  const [swiper, setSwiper] = useState()

  const getSlides = (pls) => pls.map((p, index) => (
    {
      key: p,
      content: selectedMatches(p),
      caption: getItemCaption(p)
    })
  )

  const getPoolz = () => {
    const _pools = []
    if (pools_init) {
      if (pools_init.roundOf32) { _pools.push(playoffBracketTypes.roundOf32) }
      if (pools_init.roundOf16) { _pools.push(playoffBracketTypes.roundOf16) }
      if (pools_init.quarterFinals) { _pools.push(playoffBracketTypes.quarterFinals) }
      if (pools_init.semiFinals) { _pools.push(playoffBracketTypes.semiFinals) }
      if (pools_init.finals) { _pools.push(playoffBracketTypes.finals) }
    }
    const _swipeItems = getSlides(_pools)
    const _keyAs = 'pools' // + item_tournament
    const _storageKey = 'ms_' + _keyAs
    const si = currentHelpers.getStorageItem(_storageKey, _pools, storageItemPage)
    const _currents = { pools: { index: si.index, item: si } }
    const _initProps = { sidebarSliderType: sidebarSliderTypes.labeled }
    const _sidebarItems = { smt: sidebarMenuTypes.two, items: _pools, selected: _currents.pools, keyAs: _keyAs, ..._initProps }
    setSwiper({ swipeItems: _swipeItems, sidebarItems: _sidebarItems })
  }

  useEffect(() => {
    if (pools_init) {
      getPoolz()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [round, roundItem, pools_init]);

  const handleMatchClick = (clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex) => {
    if (itemKey) pushSimple(clickedItem, itemKey, modifyMode, opts, ddn, ddProps, ddGroupIndex)
  }

  const getItemCaption = (p => {
    let _caption = p
    switch (p) {
      case 'roundOf32':
        _caption = 'R32'
        break;
      case 'roundOf16':
        _caption = 'R16'
        break;
      case 'quarterFinals':
        _caption = 'quarters'
        break;
      case 'semiFinals':
        _caption = 'semis'
        break;
      default:
      // nothing
    }
    return _.startCase(_caption)
  })

  const selectedMatches = (p) => {

    const pool = pools_init ? pools_init[p] : null
    const { matches } = pool ?? {}

    const mss = <SeasonMatchesProvider
      poolMatches={matches}
      pools={pools_init}
      isTournament={true}
      viewItem={viewItem}
      handleMatchClick={handleMatchClick}
    >
      {!asGrid && <MatchListSelected key={uniqueKey('cts', 'mls')} tournamentType={tournamentType} forPlayoff={true} />}
      {asGrid && <PlayoffBracket pool={pool} tournamentType={tournamentType} />}
      <SectionsLegend />
    </SeasonMatchesProvider>

    return mss
  }

  return swiper ? <SwiperWrapper swType={'pb'} swiper={swiper} key={uniqueKey('sw', 'pb')} /> : <div></div>

}

export default PlayoffBrackets