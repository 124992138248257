import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Icon, List, Menu, Segment } from 'semantic-ui-react';
import { DisplayContext } from '../../../global/cnr/contexts/DisplayContext';
import { ParentContext } from '../../../global/cnr/contexts/ParentContext';
import { uniqueKey } from '../../../global/common/keys';
import UiSaveButtons from '../../../global/components/buttons/UiSaveButtons';
import Popuper from '../../../global/components/popups/Popuper';
import { SimpleDnD } from '../../../global/dragNDrops/SimpleDnD';
import { appIconTypes } from '../../../global/enums/appIconTypes';
import GenericIcon from '../../../global/genericControls/GenericIcon';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import { H3Wrapper } from '../../../global/wrappers/TopperWrappers';
import Wrapper, { wrapperTypes } from '../../../global/wrappers/Wrapper';
import SportsEditMatchProvider, { SportsEditMatchContext } from '../cnr/contexts/SportsEditMatchContext';
import { SportsMatchContext } from '../cnr/contexts/SportsMatchContext';
import { SportsRtMatchContext } from '../cnr/contexts/SportsRtMatchContext';
import { SportsSeasonContext, _resultsProp } from '../cnr/contexts/SportsSeasonContext';
import { gameScoringTypes, matchUpdateTypes } from '../cnr/reducers/SportsSeasonReducer';
import SportsAthletes from '../elements/athletes/SportsAthletes';
import { _sdm } from '../elements/dashboard/SportsDashboard';
import MatchDataUpdate from '../elements/matches/MatchDataUpdate';
import MatchItemScoring from '../elements/matches/MatchItemScoring';
import MatchPendingUpdate from '../elements/matches/MatchPendingUpdate';
import MatchScoreTable from '../elements/matches/MatchScoreTable';
import { matchCalcs } from '../helpers/match';
import MatchHeader from './MatchHeader';

const sidebarType = 'createMatchResult'

const stepTypes = {
  resultExists: 'resultExists',
  resultType: 'resultType',
  enterScores: 'enterScores',
  pickTopPlayers: 'pickTopPlayers',
  sortTopPlayers: 'sortTopPlayers',
  confirmResult: 'confirmResult',
}

const _scoreTypes = {
  gameScore: 'gameScore',
  matchScore: 'matchScore',
  setScores: 'setScores',
  halfScores: 'halfScores',
  quarterScores: 'quarterScores',
}

const CreateMatchResult = (props) => {

  const { realTimeOnly, includePlayers, matchKey } = props ?? {}

  const parentContext = useContext(ParentContext)
  const { states, fns } = parentContext ?? {}
  const { appUser_state } = states ?? {}
  const { appUser_fns } = fns ?? {}
  const { accessClone } = appUser_state ?? {}

  // displayContext
  const displayContext = useContext(DisplayContext);

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state, sportsSeason_handlers } = sportsSeasonContext
  const { matches_info, gameScoringType, sportsKey, sportsShowOptions, sportPermissions } = sportsSeason_state ?? {}
  const { matchKey: matchKey_sso } = sportsShowOptions ?? {}

  // sportsMatchContext
  const sportsMatchContext = useContext(SportsMatchContext)
  const { sportsMatch_state } = sportsMatchContext ?? {}
  const { match_true, editFromRt } = sportsMatch_state ?? {}

  // sportsRtMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state, sportsEditMatch_handlers } = sportsEditMatchContext ?? {}
  const { match_edit, matchPendingUpdate, sortedAthletes, matchPopupOpen, matchAllows } = sportsEditMatch_state ?? {}
  const { handleShow_matchPending, handleSet_matchPopupOpen } = sportsEditMatch_handlers ?? {}
  const { matchUpdateType } = matchPendingUpdate ?? {}

  console.log('sportsEditMatchContext', sportsEditMatchContext)

  // sportsRtMatchContext
  const sportsRtMatchContext = useContext(SportsRtMatchContext)
  const { sportsRtMatch_state } = sportsRtMatchContext ?? {}
  const { match_rt } = sportsRtMatch_state ?? {}

  const { match_complete_possible: mcp_rt } = match_rt ?? {}
  const componentContexts = { displayContext }

  const _updateStatus = sportPermissions.any && sportPermissions.matchesList ? true : false

  const [steps, setSteps] = useState()
  const [matchResults, setMatchResults] = useState()
  const [matchIsComplete, setMatchIsComplete] = useState()
  const [currentScoreType, setCurrentScoreType] = useState(editFromRt ? _scoreTypes.setScores : null)
  const [step, setStep] = useState()

  const { complete, match_complete_possible: mcp_edit } = matchResults ?? {}

  const stepCount = steps ? steps.length : 0

  useEffect(() => {
    const { matches: matches_mi, playoffMatches } = matches_info
    let currentMatch;
    if (matches_mi && matches_mi[matchKey_sso]) {
      currentMatch = matches_mi[matchKey_sso]
    }
    if (playoffMatches && playoffMatches[matchKey_sso]) {
      currentMatch = playoffMatches[matchKey_sso]
    }
    if (currentMatch) {
      const vaProps = { currentMatch, sportsKey, accessClone }
      const _matchAllows = appUser_fns.validateAccess_allowScoring(vaProps)
      matchCalcs.calcVballMatch(currentMatch, gameScoringType)
      sportsEditMatch_handlers.handleSet_editMatch(currentMatch, _matchAllows)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (match_edit) {
      const _match = match_edit ? match_edit : match_true
      const results = _match[_resultsProp]
      setMatchResults(results)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match_edit]);

  useEffect(() => {
    let _steps;

    if (editFromRt) {
      _steps = [stepTypes.enterScores, stepTypes.confirmResult]
    } else {
      switch (gameScoringType) {
        case gameScoringTypes.match:
        case gameScoringTypes.matchPoints:
          _steps = includePlayers ?
            [stepTypes.resultType, stepTypes.enterScores, stepTypes.pickTopPlayers, stepTypes.sortTopPlayers, stepTypes.confirmResult]
            :
            [stepTypes.resultType, stepTypes.enterScores, stepTypes.confirmResult]
          break;

        case gameScoringTypes.half:
          _steps = includePlayers ?
            [stepTypes.enterScores, stepTypes.pickTopPlayers, stepTypes.sortTopPlayers, stepTypes.confirmResult]
            :
            [stepTypes.enterScores, stepTypes.confirmResult]
          break;

        case gameScoringTypes.quarter:
          _steps = includePlayers ?
            [stepTypes.enterScores, stepTypes.pickTopPlayers, stepTypes.sortTopPlayers, stepTypes.confirmResult]
            :
            [stepTypes.enterScores, stepTypes.confirmResult]
          break;

        default:
          _steps = includePlayers ?
            [stepTypes.resultType, stepTypes.enterScores, stepTypes.pickTopPlayers, stepTypes.sortTopPlayers, stepTypes.confirmResult]
            :
            [stepTypes.resultType, stepTypes.enterScores, stepTypes.confirmResult]
          break;
      }
    }
    if (matchIsComplete) {
      _steps.unshift(stepTypes.resultExists)
    }
    setSteps(_steps)
    setStep({ index: 0, name: _steps[0] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchIsComplete]);


  const handlePopupOpen = () => handleSet_matchPopupOpen(!matchPopupOpen)

  useEffect(() => {
    setMatchIsComplete(complete)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  useEffect(() => {
    if (editFromRt && match_rt) {
      const res = { score: match_rt.score, scores: match_rt.scores }
      sportsEditMatch_handlers.handleAmmend_resultsCurrent(res)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateComponent = () => {
    switch (gameScoringType) {
      case gameScoringTypes.match:
      case gameScoringTypes.matchPoints:
        switch (step.name) {
          case stepTypes.resultExists:
            return true
          case stepTypes.resultType:
            return currentScoreType ? true : false
          case stepTypes.enterScores:
            return complete || mcp_edit || mcp_rt
          case stepTypes.pickTopPlayers:
            return true
          case stepTypes.sortTopPlayers:
            return true
          case 3:
          case stepTypes.confirmResult:
            break;
          default:
            break;
        }
        break;

      case gameScoringTypes.half:
      case gameScoringTypes.quarter:
        return true

      default:
      // nothing
    }
  }

  const allowBack = step && step.index > 0
  const allowForward = step && (step.index < (stepCount - 1) && validateComponent())

  const handleUpdatedList = (l) => sportsEditMatch_handlers.handleSelect_athletes(l.data)

  const handleCancel = () => sportsSeason_handlers.handleShow_sportsElem && sportsSeason_handlers.handleShow_sportsElem()
  const handleUpdate = () => sportsEditMatch_handlers.handleUpdate_matchResults(sportsSeason_handlers.handleShow_sportsElem)

  const footer = () => <UiSaveButtons
    save={{ oc: handleUpdate, caption: 'Update Match Result', icon: appIconTypes.check, color: _updateStatus ? 'green' : 'olive' }}
  ></UiSaveButtons>

  const playerItem = (player) => {
    const { lastName, firstName, teams, uniformNumber, playerPosition } = player
    return <List.Item>
      <List.Content>
        <List.Header>{firstName}{' '}{lastName}</List.Header>
        <List.Description>{'#'}{uniformNumber}{' - '}{teams}</List.Description>
        <List.Description>{playerPosition}</List.Description>
      </List.Content>
    </List.Item>
  }

  const playerList = () => {
    if (sortedAthletes) {
      const list = sortedAthletes.map(sa => (playerItem(sa)))
      return <List divided relaxed ordered>
        {list}
      </List>
    } else {
      return 'None'
    }
  }

  const confirmContent = () => <React.Fragment>
    <Segment.Group>
      <Segment color={'blue'}>{'Result'}</Segment>
      <Segment><MatchScoreTable match_edit={match_edit} /></Segment>
    </Segment.Group>
    {includePlayers && <Segment.Group>
      <Segment color={'blue'}>{'Top Players'}</Segment>
      <Segment>{playerList()}</Segment>
    </Segment.Group>}
  </React.Fragment>

  const match_score = () => <MatchItemScoring key={uniqueKey('crm', 'ms', match_edit.key)} />
  const match_scores = () => <MatchItemScoring key={uniqueKey('crm', 'mss', match_edit.key)} setScoring={true} />
  const match_players = () => <SportsAthletes componentContexts={componentContexts} />
  const match_sortPlayers = () => sortedAthletes ? <SimpleDnD dataItems={sortedAthletes} handleUpdatedList={handleUpdatedList} /> : <Segment basic>{'No players selected'}</Segment>

  const match_confirm = () => <Wrapper
    content={confirmContent()}
    footer={footer()}
    wrapperType={wrapperTypes.padded}
  />

  const scoringContainer = () => {
    switch (gameScoringType) {
      case gameScoringTypes.match:
        if (currentScoreType === _scoreTypes.setScores) {
          return match_scores()
        } else {
          return match_score()
        }
      case gameScoringTypes.half:
        return match_scores()
      case gameScoringTypes.quarter:
        return match_scores()
      default:
        break;
    }
  }

  const handleStep = (co) => {
    const { count } = co
    let newStep = step.index + count
    if (newStep < 0) { newStep = 0 }
    if (newStep > (stepCount - 1)) { newStep = stepCount - 1 }
    const name = steps[newStep];
    setStep({ index: newStep, name })
  }

  const gi = (key, iconName, onClick, clickOptions, opts, corner) => <GenericIcon giProps={{ key, iconName, onClick, clickOptions, opts, corner }} />

  const buttonProps = { color: 'black', inverted: true }

  const leftButton = () => gi('btn-back', appIconTypes.arrowLeft, handleStep, { count: -1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowBack })
  const rightButton = () => gi('btn-forward', appIconTypes.arrowRight, handleStep, { count: 1 }, { ...buttonProps, nb: false, nf: true, disabled: !allowForward })

  const scoreTypeButtons = () => <Segment basic style={{ padding: '3em', textAlign: 'center' }}><Button.Group>
    <Button color={currentScoreType === _scoreTypes.matchScore ? 'blue' : null} onClick={() => { setCurrentScoreType(_scoreTypes.matchScore) }} >{'Match Score'}</Button>
    <Button.Or />
    <Button color={currentScoreType === _scoreTypes.setScores ? 'blue' : null} onClick={() => { setCurrentScoreType(_scoreTypes.setScores) }} >{'Set Scores'}</Button>
  </Button.Group></Segment>

  const existsDiv = () => <Wrapper
    content={<div className='no-data cntr'>
      <div className='rf'>{'The match results have already been updated'}</div>
    </div>}
    css={{ container: 'ct-no-data' }}
    wrapperType={wrapperTypes.noContent}
  />

  const wizardHeader = () => <H3Wrapper
    leftContent={leftButton()}
    middleContent={_.startCase(step.name)}
    rightContent={rightButton()}
    widths={['22%', '56%', '22%']}
  />

  const scoresContent = () => {
    switch (step.name) {
      case stepTypes.resultExists:
        return existsDiv()
      case stepTypes.resultType:
        return scoreTypeButtons()
      case stepTypes.enterScores:
        return scoringContainer()
      case stepTypes.pickTopPlayers:
        return match_players()
      case stepTypes.sortTopPlayers:
        return match_sortPlayers()
      case stepTypes.confirmResult:
        return match_confirm()
      default:
        break;
    }
  }

  const wizard = () => <Wrapper
    header={wizardHeader()}
    content={scoresContent()}
  />

  const content = () => {
    if (matchPendingUpdate) {
      switch (matchUpdateType) {
        case matchUpdateTypes.updateMatchListResults:
        case matchUpdateTypes.updateMatchListResultsFromGs:
          return <MatchDataUpdate sportsDataMode={_sdm.sports.seasonResults} handleCancel={handleShow_matchPending} matchUpdateType={matchUpdateType} fromApp={true} />
        default:
          return <MatchPendingUpdate handleCancel={handleShow_matchPending} />
      }
    } else if (realTimeOnly) {
      return match_scores()
    } else {
      return wizard()
    }
  }

  const menuItem = (matchUpdateType, iconName, disabled) => <Menu.Item
    disabled={disabled}
    onClick={() => { handleShow_matchPending({ matchUpdateType }) }} >
    <div><Icon color="blue" name={iconName} /></div>
    <div>{_.startCase(matchUpdateType)}</div>
  </Menu.Item>

  const menuItems_popup = () => {
    const mis = []
    mis.push(menuItem(matchUpdateTypes.addConsolationMatch, 'add'))
    mis.push(menuItem(matchUpdateTypes.addToHistory, 'history'))
    mis.push(menuItem(matchUpdateTypes.cancelMatch, 'delete calendar'))
    mis.push(menuItem(matchUpdateTypes.deleteMatch, 'delete'))
    mis.push(menuItem(matchUpdateTypes.deleteRealtimeMatch, 'delete',))
    mis.push(menuItem(matchUpdateTypes.deleteScore, 'ban',))
    mis.push(menuItem(matchUpdateTypes.notifyMatch, 'bell outline'))
    mis.push(menuItem(matchUpdateTypes.openDatabase, 'external square alternate'))
    mis.push(menuItem(matchUpdateTypes.postponeMatch, 'calendar minus outline'))
    mis.push(menuItem(matchUpdateTypes.resetByeMatch, 'edit'))
    mis.push(menuItem(matchUpdateTypes.updateGsScore, 'add',))
    mis.push(menuItem(matchUpdateTypes.updateMatchDetails, 'edit'))
    mis.push(menuItem(matchUpdateTypes.updateFullMatchDetails, 'edit',))
    mis.push(menuItem(matchUpdateTypes.updateMatchListResults, 'add',))
    mis.push(menuItem(matchUpdateTypes.updateMatchListResultsFromGs, 'add',))
    mis.push(menuItem(matchUpdateTypes.updatePendingMatch, 'edit',))
    mis.push(menuItem(matchUpdateTypes.updatePrePendingMatch, 'edit',))
    return mis
  }

  const popuper = () => <Popuper
    cn={'mu-pop'}
    content={content()}
    handlePopupOpen={handlePopupOpen}
    menuItems={menuItems_popup()}
    showPopupIcon={true}
    sidebarHandler={handlePopupOpen}
  />

  const content_results = () => {
    if (matchAllows) {
      if (matchAllows.edit) {
        return step.index === 0 ? popuper() : content()
      } else if (matchAllows.scores) {
        return content()
      }
    }

  }

  const header = () => <MatchHeader />

  const wrapper = () => <Wrapper
    content={content_results()}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={wrapper()}
    topperCaption={header()}
    handleCancel={handleCancel}
  />

  return step && match_edit ? fullPageWrapper() : <div></div>

}

export default CreateMatchResult

export const CreateMatchResult_withProvider = (props) => {
  return <SportsEditMatchProvider {...props}>
    <CreateMatchResult {...props} />
  </SportsEditMatchProvider>
} 