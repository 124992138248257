export const getCourseHandicapIndex = (hcpIndex, rating, slope, par) => {
  let _courseHandicap = (hcpIndex * (slope / 113)) + (rating - par)
  _courseHandicap = Math.round(_courseHandicap)
  console.log('hcpIndex', hcpIndex, rating, slope, par)
  console.log('_courseHandicap', _courseHandicap)
  return _courseHandicap
}

/**
 * 
 * @param {object} golfer 
 * @param {string} golfCourseKey 
 * @param {object} tournamentGolfCourses 
 * @returns the Course Handicap for the golfer
 */
export const geGCGHandicapIndex = (calculateHcp, golfer, golfCourseKey, tournamentGolfCourses, _hci, _ctb) => {
  const { courseHandicap, courseHandicaps, courseTeeboxes } = golfer ?? {}
  const _golfCourse = tournamentGolfCourses ? tournamentGolfCourses[golfCourseKey] : {}
  let hcpIndex;
  if (_hci) {
    hcpIndex = _hci
  } else {
    if (courseHandicaps && golfCourseKey && courseHandicaps[golfCourseKey]) {
      hcpIndex = courseHandicaps[golfCourseKey]
    } else {
      hcpIndex = courseHandicap
    }
  }

  if (calculateHcp) {
    let _courseTeebox;

    if (_ctb) {
      _courseTeebox = _ctb
    } else {
      if (courseTeeboxes && golfCourseKey && courseTeeboxes[golfCourseKey]) {
        _courseTeebox = courseTeeboxes[golfCourseKey]
      } else {
        _courseTeebox = courseHandicap
      }
    }

    const { teeBoxes, holes } = _golfCourse ? _golfCourse : {}
    const _teebox = teeBoxes ? teeBoxes[_courseTeebox] : {}
    const { slope, rating } = _teebox ? _teebox : {}
    const par = getPar(holes)
    let _hcp = (hcpIndex * (slope / 113)) + (rating - par)
    _hcp = Math.round(_hcp)
    return _hcp
  } else {
    return hcpIndex
  }
}

export const getHandicapIndexFromCourse = (courseHandicap, rating, slope, par) => {
  let hcpIndex = (courseHandicap / (slope / 113)) - (rating - par)
  hcpIndex = Math.round(hcpIndex)
  return hcpIndex
}


export const getPar = (holes) => {
  let _par = 0
  if (holes) {
    Object.keys(holes).forEach(hKey => {
      _par += parseInt(holes[hKey].par)
    })
  }
  return _par
}