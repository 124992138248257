import _ from 'lodash';
import { createFsDocKey } from '../../../global/firestoreData/appData/fsAppData';
import { sgs } from '../helpers/scheduleGrids';
import { seasonalHelpers, seasonalUpdateTypes } from '../cnr/reducers/reducerHelpers/seasonalSportsData';
import { gameScoringTypes } from '../cnr/reducers/SportsSeasonReducer';

export const updateScheduledMatches = async (paps_state, latestSeason, scheduleProps, callback) => {

  const { pathViews } = paps_state
  const pks = seasonalHelpers.getPks(pathViews, latestSeason)
  // updates `_seasonals`

  const _updateProps = seasonalHelpers.getUpdateProps(pathViews, pks, callback, null, 'matches', scheduleProps.allMatches)
  seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matches, _updateProps)

}

export const createScheduledMatches = (teams, season_info, homeAway, gridMatches, gameScoringType, sectionCrossovers) => {

  const { sectionDates, crossSectionDates, matches: seasonMatches } = season_info ?? {}

  const allM = {}
  const allCM = {}

  const levels = _.groupBy(teams, 'levels')
  const sportInfo = { startTime: '7:00 PM' }
  const { homeAways } = season_info ? season_info : {}

  if (gridMatches) {
    gridMatches.forEach(gm => {
      const _key = createFsDocKey()
      allM[_key] = {
        startDate: gm.startDate ? gm.startDate : '???',
        startTime: '7:00 PM',
        home: gm.home,
        away: gm.away,
        levels: gm.levels,
        sections: gm.sections,
        teams: [gm.home.id, gm.away.id],
        key: gm.away.name + '-' + gm.home.name
      }
    })
  } else {

    const crossoverKeys = []

    _.each(levels, (level, levelKey) => {

      const sections = _.groupBy(level, 'sections')

      _.each(sections, (sectionTeams, sectionKey) => {

        const _levelSectionKey = levelKey + '_' + sectionKey
        const teamCount = Object.keys(sectionTeams).length
        const selectedDates = sectionDates && sectionDates[teamCount] ? sectionDates[teamCount] : []

        if (selectedDates) {
          const playGrids = sgs.wpial_home_away[teamCount]
          if (playGrids) {
            for (var allezRetour = 1; allezRetour <= 2; allezRetour++) {
              const _homeAway = homeAways && homeAways[_levelSectionKey] ? homeAways[_levelSectionKey] : homeAway
              createMatches(sectionTeams, allezRetour, selectedDates, sportInfo, playGrids, allM, _homeAway, gameScoringType)
            }
          }
        }

        if (sectionCrossovers) {

          const sectionCrossoverKey = sectionCrossovers[_levelSectionKey]

          if (!crossoverKeys.includes(_levelSectionKey)) {

            const _sectionCrossover = sectionCrossoverKey.split('_')
            const _levelKey = _sectionCrossover[0]
            const _sectionKey = _sectionCrossover[1]
            const _crossoverSectionTeams = sections[_sectionKey]
            const teamCount = Object.keys(_crossoverSectionTeams).length
            const selectedCrossoverDates = crossSectionDates && crossSectionDates[teamCount] ? crossSectionDates[teamCount] : []
            const crossoverGrids = sgs.crossover[_crossoverSectionTeams.length]
            // const playGrids = sgs.wpial_home_away[teamCount]
            if (crossoverGrids) {
              const _homeAway = homeAways && homeAways[_levelSectionKey] ? homeAways[_levelSectionKey] : homeAway
              createCrossoverMatches(sectionTeams, _crossoverSectionTeams, allezRetour, selectedCrossoverDates, sportInfo, crossoverGrids, allCM, _homeAway, gameScoringType)
              crossoverKeys.push(sectionCrossoverKey)
            }
          }
        }
      })
    })
  }

  console.log('allCM', allCM)

  return {
    allMatches: allM,
    crossoverMatches: allCM,
  }
}

const createMatches = (teams, allezRetour, selectedDates, sportInfo, playGrids, allM, homeAway, gameScoringType) => {

  // loop the grids
  let pdi = (allezRetour === 2) ? Object.keys(playGrids).length : 0

  if (playGrids) {
    _.each(playGrids, (gridMatches) => {
      if (gridMatches) {
        _.each(gridMatches, (gridMatch) => { // gridMatch = {h: 1, a: 6}
          const _match = getMatch(teams, gridMatch, homeAway, allezRetour)
          const newMatch = createMatch(_match, selectedDates[pdi], sportInfo.startTime, gameScoringType)
          const _key = createFsDocKey()
          allM[_key] = newMatch
        })
      }
      pdi++
    })
  }

}

const createCrossoverMatches = (teams, crossoverTeams, allezRetour, selectedDates, sportInfo, playGrids, allM, homeAway, gameScoringType) => {

  let _isHome = true

  if (playGrids) {
    let pdi = (allezRetour === 2) ? Object.keys(playGrids).length : 0
    _.each(teams, (team) => {
      const { position, levels } = team ?? {}
      _.each(playGrids, (gridMatches, playDateNumber) => {
        if (gridMatches) {
          const gridMatch = gridMatches[position + 1]
          const { o } = gridMatch ?? {}
          const opponent = getTeamByPosition(crossoverTeams, o)
          const newMatch = {
            home: _isHome ? team : opponent,
            away: _isHome ? opponent : team,
            startDate: selectedDates[playDateNumber - 1],
            levels: levels,
            sections: 'Non',
            isCrossover: true,
            teams: [team.id, opponent.id],
          }
          const _key = createFsDocKey()
          allM[_key] = newMatch
          _isHome = !_isHome
        }
        pdi++
      })
    })
  }
}

const getMatch = (teams, gridMatch, homeAway, allezRetour) => {
  const newMatch = {
    home: { ...getHomeAway(homeAway, teams, gridMatch, allezRetour, true) },
    away: { ...getHomeAway(homeAway, teams, gridMatch, allezRetour, false) },
  }
  return newMatch
}

const createMatch = (newMatch, startDate, startTime, gameScoringType) => {

  newMatch.startDate = startDate ? startDate : '???'
  newMatch.startTime = startTime

  switch (gameScoringType) {
    case gameScoringTypes.match:
    case gameScoringTypes.matchPoints:
      newMatch.bestOf = true
      newMatch.setCount = 5
      break;
    default:
    // nothing
  }

  newMatch.key = newMatch.away.name + '-' + newMatch.home.name
  newMatch.teams = [newMatch.home.id, newMatch.away.id]
  if (newMatch.away.levels === newMatch.home.levels) {
    newMatch.levels = newMatch.home.levels
  } else {
    newMatch.levels = 'Non'
  }
  if (newMatch.away.sections === newMatch.home.sections) {
    newMatch.sections = newMatch.home.sections
  } else {
    newMatch.sections = 'Non'
  }
  return newMatch
}

const getHomeAway = (homeAway, teams, gridMatch, allezRetour, isHome) => {
  if (homeAway) {
    if (isHome) {
      if (allezRetour === 1) {
        return getTeamByPosition(teams, gridMatch.a)
      } else {
        return getTeamByPosition(teams, gridMatch.h)
      }
    } else {
      if (allezRetour === 1) {
        return getTeamByPosition(teams, gridMatch.h)
      } else {
        return getTeamByPosition(teams, gridMatch.a)
      }
    }
  } else {
    if (isHome) {
      if (allezRetour === 1) {
        return getTeamByPosition(teams, gridMatch.h)
      } else {
        return getTeamByPosition(teams, gridMatch.a)
      }
    } else {
      if (allezRetour === 1) {
        return getTeamByPosition(teams, gridMatch.a)
      } else {
        return getTeamByPosition(teams, gridMatch.h)
      }
    }
  }
}


const getTeamByPosition = (teams, teamNumber, withParents) => {

  const rTeam = {}

  const team = _.find(teams, (team) => team.position + 1 === teamNumber);
  if (team) {
    rTeam.id = team.id
    rTeam.name = team.name
    rTeam.sections = team.sections
    rTeam.levels = team.levels
    if (withParents) {
      rTeam.organizations = team.parentKeys.organizations
      rTeam.districts = team.parentKeys.districts
    }
  }

  return rTeam
}
