import _ from 'lodash';
import React from 'react';
import { Icon, Image, Label, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../../common/keys';
import { appIconTypes } from '../../../enums/appIconTypes';
import { gEnums } from '../../../enums/globalEnums';

export const gridIconElements = (props) => {

  const { gridItemGroup, handleIconClick, textLimit } = props

  const gridItem = (viewItem, segProps, gcProps, hidden) => gridItemIconGroup(viewItem, segProps, gcProps, hidden, handleIconClick, textLimit)

  const items = []

  if (gridItemGroup) {
    _.each(gridItemGroup, (item) => {
      const { viewItem, segProps, gcProps, hidden } = item
      items.push(gridItem(viewItem, segProps, gcProps, hidden))
    })
  }

  return items

}

export const gridItemIconGroup = (viewItem, segProps, gcProps, hidden, handleIconClick, textLimit) => {
  const gig = {
    viewItem,
    element: <SegIconGroup
      item={viewItem}
      segProps={segProps}
      gcProps={gcProps}
      defaultSnC={gcProps ? gcProps.defaultSnC : {}}
      handleIconClick={handleIconClick}
      hidden={hidden}
      textLimit={textLimit}
    />
  }
  return gig
}

/** Return a Segment with and Icon or Image
 * @segProps caption, cloned, cn, icon, iconImageUrl, cornerIcon
 */
const SegIconGroup = (props) => {

  const { item, segProps, gcProps, defaultSnC, handleIconClick, hidden, textLimit } = props ?? {}
  const { _viewPermissionType, key: key_viewItem } = item ?? {}
  // const { textLimit } = gcProps ?? {}

  const segIcon = () => {

    const { icon, iconImageUrls, cornerIcon } = segProps ?? {}
    const { urls } = iconImageUrls ?? {}
    const { full, thumbnail } = urls ?? {}
    const src = thumbnail ? thumbnail : full

    let _icon = _viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin ? 'user secret' : icon

    if ((!_icon || _icon === 'file alternate') && appIconTypes[key_viewItem]) {
      _icon = appIconTypes[key_viewItem]
    }

    if (src) {
      return <Image style={{ margin: 'auto' }} vit={key_viewItem} name={_icon} src={src} />
    } else {
      if (cornerIcon) {
        return <Icon.Group size='huge'>
          <Icon name={_icon} />
          <Icon size='small' corner='bottom right' name={cornerIcon} />
        </Icon.Group>
      } else {
        return <Icon key={uniqueKey('sig', key_viewItem)} name={_icon} />
      }
    }
  }

  const segLabel = (notification) => <Label size='tiny' attached='top right' style={notification.color ? { backgroundColor: notification.color } : {}}  >
    {notification.text}
  </Label>

  // title, icon
  const segGroup = () => {
    let { caption, cloned, cn, notification } = segProps ?? {}

    if (!caption) { caption = _.startCase(key_viewItem) + '*' }

    let cnt = 'grid-text'
    let limitText;

    if (caption && caption.length > textLimit) {
      cnt += ' eight'
      limitText = true
    }

    let itemKey = cloned ? item.itemKey : null
    if (hidden) { cn += ' hid' }

    const seg = () => <Segment
      style={defaultSnC.style}
      basic
      key={uniqueKey('ssig', key_viewItem)}
      onClick={() => { handleIconClick && handleIconClick(item, itemKey, cloned) }}
      className={cn ? cn : null}
    >
      {notification && segLabel(notification)}
      {segIcon(item, segProps)}
      <div key={uniqueKey('sigd', key_viewItem)} className={cnt} style={limitText ? { fontSize: '8rem' } : null}>{caption}</div>
    </Segment>

    return seg()
  }

  return segGroup()

}

export default SegIconGroup