import { serverTimestamp } from "firebase/firestore";
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppUserDataContext } from '../cnr/contexts/AppUserDataContext';
import { AppUserManagerContext } from "../cnr/contexts/AppUserManagerContext";
import { ParentContext } from '../cnr/contexts/ParentContext';
import { _conversationsCollection, _notesCollection } from '../cnr/reducers/AppUserDataReducer';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import AppForm from '../components/forms/appForms/AppForm';
import { PopupConfirm } from '../components/popups/PopupConfim';
import { appFormTypes } from '../enums/appFormTypes';
import { updateAppUserProfile } from '../firestoreData/profiles/updateProfiles';
import FullPageWrapper from "../wrappers/FullPageWrapper";
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import FcmList from "./FcmList";

const DirectMessage = (props) => {

  const { appFormType: appFormType_props } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state, page_state } = states

  // authContext 
  const { appUser } = appUser_state ?? {}

  // papsContext 
  const { view, viewKey } = paps_state ?? {}

  const { pageDataCaption } = page_state ?? {}

  // appUserDataContext
  const appUserDataContext = useContext(AppUserDataContext);
  const { appUserData_state, appUserData_handlers } = appUserDataContext ?? {}
  const { conversations } = appUserData_state ?? {}

  // appUserManagerContext - this may not exist if coming from userNotes or userConversations
  const appUserManagerContext = useContext(AppUserManagerContext)
  const { appUserManager_state, appUserManager_handlers } = appUserManagerContext ?? {}
  const { selectedAppUserAuth, appFormType, selectedAppUser, currentFromData, confirmation, updating } = appUserManager_state ?? {}

  const _selectedAppUser = { ...selectedAppUser, ...selectedAppUserAuth }
  const { lastName, firstName, email: selectedEmail, fcmTokens, phoneNumber } = _selectedAppUser ?? {}
  const fullName = lastName && firstName ? firstName + ' ' + lastName : null

  const _appFormType = appFormType_props ? appFormType_props : appFormType

  const _currentFromData = props && props.currentFromData ? props.currentFromData : currentFromData

  // local state
  const [formReady, setFormReady] = useState(_currentFromData ? true : false)
  const [formData, setFormData] = useState(_currentFromData)
  const [popupOpen, setPopupOpen] = useState()
  const [caption, setCaption] = useState()
  const [caption2, setCaption2] = useState()
  const [fcmCaption, setFcmCaption] = useState()
  const [buttonCaption, setButtonCaption] = useState()
  const [buttonIcon, setButtonIcon] = useState()
  const [showFcms, setShowFcms] = useState()
  const [updatingFcm, setUpdatingFcm] = useState()
  const [sendingFcm, setSendingFcm] = useState()
  const [confirmationFcm, setConfirmationFcm] = useState()

  const allowFcms = _appFormType === appFormTypes.notificationsDirect

  useEffect(() => {
    switch (_appFormType) {
      case appFormTypes.emailDirect:
        setCaption('Email ' + fullName)
        setCaption2(selectedEmail)
        break;
      case appFormTypes.notificationsDirect:
        setCaption('Notification')
        setCaption2(fullName)
        setFcmCaption('FCMs (' + Object.keys(fcmTokens).length + ')')
        break;
      case appFormTypes.smsDirect:
        setCaption('SMS')
        setCaption2(fullName + ' (' + phoneNumber + ')')
        break;
      case appFormTypes.messageDirect:
        setCaption('Message')
        setCaption2(fullName)
        break;
      case appFormTypes.note:
        setCaption('Note')
        setCaption2(fullName)
        break;
      default:
        setCaption('???')
    }
    switch (_appFormType) {
      case appFormTypes.emailDirect:
      case appFormTypes.notificationsDirect:
      case appFormTypes.smsDirect:
      case appFormTypes.messageDirect:
        setButtonCaption('Send')
        setButtonIcon('send')
        break;
      case appFormTypes.note:
        setButtonCaption(_currentFromData ? 'Update' : 'Save')
        setButtonIcon('save')
        break;
      default:
        setButtonCaption('???')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleShowFcms = () => setShowFcms(!showFcms)

  const updateFormData = (fd, iir) => {
    setFormData(fd)
    setFormReady(iir)
    if (fd.recipients && conversations) {
      Object.keys(conversations).forEach(key => {
        if (_.isEqual(conversations[key].recipients, fd.recipients)) {
          console.log('Arrays are equal!');
        }
      })
    }
  }

  const handleUpdateData = () => {

    let dataCollection;
    let itemData;

    switch (_appFormType) {
      case appFormTypes.messageDirect:
        dataCollection = _conversationsCollection
        formData.recipients.push(appUser.senderKey)
        itemData = {
          recipients: formData.recipients,
          messages: {
            1: {
              message: formData.message,
              senderId: appUser.senderKey,
              timestamp: new Date()
            }
          }
        }
        appUserData_handlers.handleSaveNote(itemData, handleCancel)
        break;

      case appFormTypes.note:
        dataCollection = _notesCollection
        itemData = {
          note: formData.note,
          _itemKey: formData._itemKey,
          timestamp: serverTimestamp(),
          view,
          viewKey,
          pageDataCaption,
        }
        appUserData_handlers.handleSaveNote(itemData, handleCancel)
        break;
      default:
      // nothing
    }

  }

  const fcmCallback = (result) => setUpdatingFcm(false)

  const handleClearFcm = (tk) => {
    setUpdatingFcm(true)
    const { fcmTokens } = _selectedAppUser ?? {}
    const _fcmTokens = fcmTokens ? { ...fcmTokens } : {}
    delete _fcmTokens[tk]
    const dataToUpdate = {
      fcmTokens: _fcmTokens
    }
    updateAppUserProfile(_selectedAppUser.uid, dataToUpdate, fcmCallback)
  }

  const handleClearAllFcms = () => {
    setUpdatingFcm(true)
    const dataToUpdate = { fcmTokens: {} }
    updateAppUserProfile(_selectedAppUser.uid, dataToUpdate, fcmCallback)
  }

  // this may be handled by parent
  const handleSave = () => {
    if (appUserManager_handlers && appUserManager_handlers.handleSend_notificationSmsOrEmail) {
      appUserManager_handlers.handleStartUpdate()
      appUserManager_handlers.handleSend_notificationSmsOrEmail(formData)
    } else {
      handleUpdateData()
    }
  }

  const handleCancel = () => {
    if (appUserManager_handlers && appUserManager_handlers.handleCancel) { appUserManager_handlers.handleCancel() }
    if (props && props.handleCancel) { props.handleCancel() }
  }

  const fcmList = () => <FcmList selectedAppUser={_selectedAppUser} />

  const form = () => <AppForm parentData={formData} handleUpdateParentData={updateFormData} appFormType={_appFormType} />

  const buttons = () => <UiSaveButtons
    save={{ oc: handleSave, disabled: !formReady, caption: buttonCaption, icon: buttonIcon }}
  ></UiSaveButtons>

  const buttonsNotification = () => <UiSaveButtons
    save={{ oc: handleSave, disabled: !formReady, caption: buttonCaption, icon: buttonIcon }}
    preview={{ oc: handleShowFcms, caption: fcmCaption }}
  ></UiSaveButtons>

  const buttonsFcms = () => <UiSaveButtons
    save={{ oc: handleClearAllFcms, caption: 'Clear All', icon: 'delete' }}
  ></UiSaveButtons>

  const fcmWrapper = () => <Wrapper
    content={fcmList()}
    footer={buttonsFcms()}
    wrapperType={wrapperTypes.padded}
    updating={sendingFcm}
    confirmation={confirmationFcm}
  />

  const wrapper = () => <Wrapper
    content={form()}
    footer={allowFcms ? buttonsNotification() : buttons()}
    wrapperType={wrapperTypes.padded}
    updatingCaption={appUserManager_state && appUserManager_state.updateCaption}
    updating={updating}
    confirmation={confirmation}
  />

  const popuper = () => <PopupConfirm
    content={wrapper()}
    sidebarOpen={popupOpen}
    sidebarHandler={setPopupOpen}
    handleConfirm={handleClearFcm}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={popuper()}
    handleCancel={handleCancel}
    topperCaption={caption}
    topperCaption2={caption2}
    isBack={true}
  />

  const fcmWizard = () => <FullPageWrapper
    content={fcmWrapper()}
    handleCancel={handleShowFcms}
    topperCaption={'FCMs (' + Object.keys(fcmTokens).length + ')'}
    isBack={true}
    updating={updatingFcm}
  />

  if (showFcms) {
    return fcmWizard()
  } else {
    return _currentFromData ? wrapper() : fullPageWrapper()
  }
}

export default DirectMessage