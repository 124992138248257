import { copyObj } from "../../common_web/copy"
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps"

const rts = {
  handleSet_appData: 'handleSet_appData',
  ...grts
}

export const appDataReducer = (state, action) => {

  const { globalAppData, appCollectionsData, missingSvDatas } = state
  const { type, dispatch } = action

  const _globalAppData = globalAppData ? copyObj(globalAppData) : {}
  const _appCollectionsData = appCollectionsData ? copyObj(appCollectionsData) : {}
  const _missingSvDatas = missingSvDatas ? copyObj(missingSvDatas) : {}

  switch (type) {

    case rts.handleSet_appData:
      if (action.eventPageKey && action.view) {
        if (!action.viewKey) {
          if (!_globalAppData[action.eventPageKey]) { _globalAppData[action.eventPageKey] = {} }
          if (!_globalAppData[action.eventPageKey][action.view]) { _globalAppData[action.eventPageKey][action.view] = {} }
          _globalAppData[action.eventPageKey][action.view] = action.data
        }
      }
      // _globalAppData[action.refKey] = action.data
      _missingSvDatas[action.dataCollection] = action.missingSvData
      return { ...state, staticViews_gs: action.staticViews_gs, globalAppData: _globalAppData, missingSvDatas: _missingSvDatas }

    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
    case rts.handleCloseConfirmation:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation, questionProps: null })

    default:
      return { ...state }
  }
}

export const appDataInitialState = (initState) => {
  return { ...initState, globalAppData: {}, appCollectionsData: {}, missingSvDatas: {} }
};

export const appDataHandlers = (dispatch) => {
  return {
    handleSet_appData: (pathViews, dataCollection, view, viewKey, eventPageKey, refKey, data, missingSvData) => { dispatch({ type: rts.handleSet_appData, dispatch, pathViews, dataCollection, view, viewKey, eventPageKey, refKey, data, missingSvData }) },
    ...responseHandlers(dispatch)
  }
} 