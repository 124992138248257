import _ from 'lodash';
import { gEnums } from '../enums/globalEnums';
import { creatingHelpers } from './creating';

export const filterHelpers = {
  filter: (items, prop, value, inverse, createObjectProp) => _filter(items, prop, value, inverse, createObjectProp),
  filterAlphaData: (viewListData, alphaValue, alphaProp) => filterAlphaData(viewListData, alphaValue, alphaProp),
  filterFilteredData: (viewListData, filterProp, filterValues, f) => filterFilteredData(viewListData, filterProp, filterValues, f),
  filterGroupFilters: (data, currentConstraints) => filterGroupFilters(data, currentConstraints),
  filterObject: (items, filterBy, filterValue) => filterObject(items, filterBy, filterValue),
  filterSearchValue: (viewListData, searchProps, searchValue, f) => filterSearchValue(viewListData, searchProps, searchValue, f),
  filterTabData: (viewListData, selectedTabItem, groupByProp, _sortProp, _f) => filterTabData(viewListData, selectedTabItem, groupByProp, _sortProp, _f),
  find: (items, prop, value) => _find(items, prop, value),
}


const _find = (items, prop, value) => {
  const _items = items ? Array.isArray(items) ? items : Object.values(items) : []
  return _items.find(i => i[prop] === value)
}

const _filter = (items, prop, value, inverse, createObjectProp) => {
  const _items = items ? Array.isArray(items) ? items : Object.values(items) : []
  const _filtered = inverse ? _items.filter(i => i[prop] !== value) : _items.filter(i => i[prop] === value)
  return createObjectProp ? creatingHelpers.createObject(_filtered, createObjectProp) : _filtered
}

// export const _filterObject = (list, name) => _.pickBy(list, obj =>
//   _.some(obj, { name: name })
// );

export function getLastItem(obj) {
  const sortedArray = Object.keys(obj).sort();
  const li = sortedArray.pop()
  return obj[li]
}

export function getLastSplit(value, splitBy) {
  const _splits = value.split(splitBy)
  return _splits[_splits.length - 1]
}

export function getLastItemSortedByKey(obj) {
  const sortedArray = Object.keys(obj).sort();
  const li = sortedArray.pop()
  return {
    item: li,
    itemKey: _.camelCase(li),
    index: Object.keys(obj).length - 1
  }
}

export function _isBoolean(val) {
  return val === false || val === true;
}

export function findClosestDate(selectedDate, datesArray) {
  // Convert selectedDate and datesArray elements to timestamps
  const _selectedDate = new Date(selectedDate)
  const selectedTimestamp = _selectedDate.getTime();
  const timestamps = datesArray.map(date => new Date(date).getTime());

  // Find the index of the closest timestamp
  const closestIndex = timestamps.reduce((prevIndex, timestamp, currentIndex) => {
    const prevDiff = Math.abs(timestamps[prevIndex] - selectedTimestamp);
    const currentDiff = Math.abs(timestamp - selectedTimestamp);
    return (currentDiff < prevDiff) ? currentIndex : prevIndex;
  }, 0);

  // Return the closest date
  return datesArray[closestIndex];
}

export const separateContexts = (contexts, parentFilters, createPropStates, replaceItems) => {

  const { state: stateFilters, props: propFilters, functions: functionFilters, handlers: handlerFilters } = parentFilters ?? {}

  const c = {
    states: {},
    handlers: {},
    fns: {},
    settings: {},
    props: {}
  }

  const f = {
    states: {},
    handlers: {},
    fns: {},
  }

  Object.keys(contexts).forEach(key => {
    switch (key) {
      case 'parentContext':
        const { states, handlers, fns, navigate } = contexts[key]
        if (states) c.states = { ...c.states, ...states }
        if (handlers) c.handlers = { ...c.handlers, ...handlers }
        if (fns) c.fns = { ...c.fns, ...fns }
        if (navigate) { c.handlers['navigate'] = navigate }
        break;
      default:
        const pc = contexts[key]
        try {
          if (pc) {
            Object.keys(pc).forEach(pck => {
              const _pc = pc[pck]
              switch (pck) {
                case 'homeSettings_state':
                case 'clientSettings_state':
                case 'eventSettings_state':
                  const k = pck.replace('_state', '')
                  c.settings[k] = _pc[k]
                  break;
                default:
                  if (pck.endsWith('_state')) {
                    c.states[pck] = _pc
                  } else if (pck.endsWith('_handlers')) {
                    c.handlers[pck] = _pc
                  } else if (pck.endsWith('_fns')) {
                    c.fns[pck] = _pc
                  } else if (pck.endsWith('Settings')) {
                    c.settings[pck] = _pc
                  }
              }
            })
          }
        } catch (error) {
          // nothing
          console.log('error', error)
          console.error('separateContexts', key)
        }
    }
  })

  if (stateFilters && c.states) {
    Object.keys(c.states).forEach(k => {
      if (stateFilters.includes(k)) {
        f.states[k] = c.states[k]
      }
    })
  }

  if (functionFilters && c.fns) {
    Object.keys(c.fns).forEach(k => {
      if (functionFilters.includes(k)) {
        f.fns[k] = c.fns[k]
      }
    })
  }

  if (handlerFilters && c.handlers) {
    Object.keys(c.handlers).forEach(k => {
      if (handlerFilters.includes(k)) {
        f.handlers[k] = c.handlers[k]
      }
    })
  }

  if (propFilters && c.states) {
    Object.keys(c.states).forEach(k => {
      const cs = c.states[k]
      if (cs) {
        Object.keys(cs).forEach(csk => {
          if (propFilters.includes(csk)) {
            let allow = true
            switch (csk) {
              case 'pathViews':
                if (k !== 'paps_state') { allow = false }
                break;
              default:
                allow = true
            }
            if (allow) {
              if (createPropStates) {
                if (!f.states[k]) { f.states[k] = {} }
                f.states[k][csk] = cs[csk]
              } else {
                c.props[csk] = cs[csk]
              }
            }
          }
        })
      }
    })
  }

  if (replaceItems) {
    if (f.states) { c.states = f.states }
    if (f.fns) { c.fns = f.fns }
    if (f.handlers) { c.handlers = f.handlers }
  }

  return c

}

// export const filterMaskData = (viewListData, maskValue, maskProp, f) => {
//   if (maskValue) {
//     f.push({ name: 'filterMaskData', value: maskValue })
//     return _.filter(viewListData, [maskProp, [maskValue]])
//   }
//   return viewListData
// }

const filterFilteredData = (viewListData, filterProp, filterValues, f) => {
  if (filterProp && filterValues && filterValues.length > 0) {
    f.push({ name: 'filterFilteredData', value: filterValues })
    return filterObject(viewListData, filterProp, filterValues)
  }
  return viewListData
}

const filterTabData = (viewListData, selectedTabItem, groupByProp, _sortProp, _f) => {
  if (groupByProp && selectedTabItem) {
    return filterObject(viewListData, groupByProp, selectedTabItem.value)
  }
  return viewListData
}

const filterAlphaData = (viewListData, alphaValue, alphaProp) => {
  if (!alphaProp) { alphaProp = 'name' }
  const vld = Object.values(viewListData).filter(function (item) {
    return item[alphaProp] && 0 === item[alphaProp].indexOf(String.fromCharCode(alphaValue));
  });
  return vld
}

const filterSearchValue = (viewListData, searchProps, searchValue, f) => {
  if (searchValue) {
    f.push({ name: 'filterSearchValue', value: searchValue })
    return filterAll(searchValue, viewListData, searchProps)
  }
  return viewListData
}

const filterGroupFilters = (data, currentConstraints) => {
  const xxx = filterItems(data, currentConstraints)

  return xxx
}

const filterItems = (data, currentConstraints) => {

  let filteredData = Object.values(data);

  _.each(currentConstraints, (currentConstraint, sfKey) => {
    const { dataPropName, propValues, queryType } = currentConstraint;
    let _d;
    switch (queryType) {
      case gEnums.queryTypes.arrayContains:
      case gEnums.queryTypes.arrayContainsAny:
        _d = getItemsArrayInArray(filteredData, dataPropName, propValues)
        break;
      case gEnums.queryTypes.notIn:
        _d = getItemsArrayNotInArray(filteredData, dataPropName, propValues)
        break;
      default:
        _d = filteredData
    }
    filteredData = _d
  })

  const combinedFilteredData = _.flatten(filteredData);
  return combinedFilteredData

}

export const pickAll = (searchValue, listData, searchProps) => {
  const re = new RegExp(_.escapeRegExp(searchValue), 'i')
  if (!_.isArray(searchProps)) { searchProps = [searchProps] }
  let allRes = []
  for (let x = 0; x < searchProps.length; x++) {
    const prop = searchProps[x];
    const isMatch = result => re.test(result[prop])
    const res = _.pickBy(listData, isMatch)
    if (res && Object.keys(res).length > 0) {
      allRes = allRes.concat(res)
    }
  }
  return uniqueify(allRes)
}

export const filterAll = (searchValue, listData, searchProps) => {
  const re = new RegExp(_.escapeRegExp(searchValue), 'i')
  if (!_.isArray(searchProps)) { searchProps = [searchProps] }
  let allRes = []
  for (let x = 0; x < searchProps.length; x++) {
    const prop = searchProps[x];
    const isMatch = result => re.test(result[prop])
    const res = _.filter(listData, isMatch)
    allRes = allRes.concat(res)
  }
  return uniqueify(allRes)
}

export const filterDropdown = (filterValue, listData, groupByProp) => {
  return _.filter(listData, [groupByProp, [filterValue]])
}

const uniqueify = (arr) => {
  var a = arr.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
}

export const getMaskedGroupTags = (data, viewListData) => {

  const { maskActive, maskProp } = data
  let maskGroupTags = null

  if (maskActive && maskProp) {
    const maskPropGroups = _.groupBy(viewListData, maskProp)
    const x = {}
    Object.keys(maskPropGroups).forEach(mgp => {
      const spx = mgp.split(',')
      spx.forEach(sp => {
        x[sp] = {}
      })
    })
    maskGroupTags = Object.keys(x)
    if (maskGroupTags.length > 1) {
      return maskGroupTags
    }
  }
  return null
}

// LOOK
/**
 * 
 * @param {object} viewItem 
 * @param {object} viewListData  
 * @returns 
 */
export const getAllFilterTags = (viewItem, viewListData) => {

  const { filter } = viewItem
  const { filterProp } = filter ?? {}
  const allFilterValues = []
  const fitlerItemz = {}

  if (filterProp) {
    let dataList;
    const tagData = _.groupBy(viewListData, filterProp)
    Object.keys(tagData).forEach(key => {
      if (key !== undefined && key !== 'undefined') {
        const keySplit = key.split(',')
        keySplit.forEach(splitKey => {
          if (!fitlerItemz[splitKey]) {
            fitlerItemz[splitKey] = {}
            if (dataList && dataList[splitKey] && dataList[splitKey].text) {
              allFilterValues.push({ key: splitKey, value: splitKey, text: dataList[splitKey].text })
            } else {
              allFilterValues.push({ key: splitKey, value: splitKey, text: splitKey })
            }
          }
        })
      }
    })
  }
  return _.sortBy(allFilterValues)
}

export const filterAllLocations = (locationsOf, dataAction, settingsAction, ownProps) => {

  const filterOn = 'attendeeTypes'
  const filterAdded = 'Public'

  const { paps } = ownProps
  const { authz } = paps
  const { profile, tempAccessLevel } = authz ?? {}
  const matchProp = 'name'
  const locationProp = 'locations'
  let allLocationData = []

  const pr = Object.assign({}, profile);

  let { attendeeTypes } = pr ?? {}
  if (!attendeeTypes) { attendeeTypes = [] }

  if (attendeeTypes && !_.includes(attendeeTypes, filterAdded)) { attendeeTypes.push(filterAdded) }

  // restrict to public
  if (tempAccessLevel) { attendeeTypes = [filterAdded] }

  // loop through the locations
  locationsOf.forEach(locationOf => {
    // does the location exist in lists??? 
    if (settingsAction && settingsAction.lists && settingsAction.lists[locationOf]) {
      const el = settingsAction.lists[locationOf]
      Object.keys(el).forEach(e => {
        const item = el[e]
        if (!item[filterOn]) {
          item[filterOn] = [filterAdded]
          item.parentClick = {
            name: item.name,
            vit: locationOf,
            id: item.id,
            itemKey: item.itemKey
          }
        }
        allLocationData.push(item)
      })
    } else {
      // did we get it from the db??? 
      const locationOfList = settingsAction && settingsAction.lists && settingsAction.lists[locationProp]
      const locationDataAction = dataAction[locationOf]
      if (locationOfList && locationDataAction) {
        locationDataAction.forEach(lldItem => {
          const mv = lldItem[locationProp]
          if (mv && mv.length === 1) {
            const item = mv[0]
            const z = _.find(Object.values(locationOfList), function (o) { return o[matchProp] === item; })
            if (z) {
              if (lldItem[filterOn]) {
                z[filterOn] = lldItem[filterOn]
                z.parentClick = {
                  name: z.name,
                  vit: 'locations',
                  id: z.id,
                  itemKey: z.itemKey
                }
              }
              allLocationData.push(z)
            }
          }
        })
      }
    }
  })
  allLocationData = _.sortBy(allLocationData, matchProp)
  allLocationData = _.uniqBy(allLocationData, matchProp);
  const filteredData = _.filter(allLocationData, d => _.intersection(d[filterOn], attendeeTypes).length > 0)
  return filteredData
}

export const getItemsArrayInArray = (dataItems, arrayName, arrayValues) => {
  return _.filter(dataItems, d => _.intersection(d[arrayName], arrayValues).length > 0)
}

export const getItemsArrayNotInArray = (dataItems, arrayName, arrayValues) => {
  return _.filter(dataItems, d => _.intersection(d[arrayName], arrayValues).length === 0)
}

export const getItemsValueInArray = (dataItems, arrayName, arrayValue) => {
  return _.filter(Object.values(dataItems), d => _.intersection(d[arrayName], [arrayValue]).length > 0)
}

export const getItemsValueNotInArray = (dataItems, arrayName, arrayValue) => {
  return _.filter(dataItems, d => _.intersection(d[arrayName], [arrayValue]).length === 0)
}

export const getItemsInArrayOrDirect = (dataItems, filterOn, filterValue, listFilteredOnProp) => {

  if (_.isArray(filterValue)) {
    return _.filter(dataItems, [filterOn, filterValue])
  } else {
    return _.pickBy(dataItems, function (d) {
      if (d && d[filterOn]) {
        return d[filterOn].includes(filterValue) || d[filterOn] === filterValue
      }
      if (d && d[listFilteredOnProp]) {
        return d[listFilteredOnProp].includes(filterValue) || d[listFilteredOnProp] === filterValue
      }
    })
  }
}

export const getUniquePropKeys = (list) => {
  const pis = {}
  Object.values(list).forEach(item => {
    Object.keys(item).forEach(itemKey => {
      const i = item[itemKey]
      if (typeof i !== "object") {
        pis[itemKey] = {
          name: itemKey,
          caption: itemKey,
        }
      }
    })
  })
  return Object.keys(pis)
}


export const filterObjectBoolean = (items, filterBy, filterValue) => {

}

const filterObject = (items, filterBy, filterValue) => {
  if (items && filterBy) {
    if (filterValue || filterValue === false) {
      let isValueAnArray = false
      const fv = _.isArray(filterValue) ? filterValue : [filterValue]
      Object.keys(items).forEach(key => {
        if (_.isObject(items[key])) {
          items[key].key = key
          if (items[key][filterBy] && _.isArray(items[key][filterBy])) {
            isValueAnArray = true
          }
        }
      })
      if (fv.length > 0) {
        let fis;
        if (isValueAnArray) {
          // LOOK - filterObject
          fis = _.filter(items, [filterBy, [filterValue]])
          const fiss = getItemsInArrayOrDirect(items, filterBy, filterValue)
          fis = fiss
        } else {
          fis = _.filter(items, (v) => _.indexOf(fv, v[filterBy]) >= 0)
        }
        const fiso = creatingHelpers.createObject(fis, 'key')
        return fiso
      }
    } else {
      Object.keys(items).forEach(key => {
        items[key].key = key
      })
      const filteredItems = _.filter(items, filterBy)
      const filtered = {}
      filteredItems.forEach(vi => { filtered[vi.key] = { ...vi } })
      return filtered
    }
  }
  return items
}

export const filterDataObject = (items, value) => {
  return _.pickBy(items, function (item) {
    return _.some(item, function (k) {
      return _.some(Object.keys(item), function (k) {
        return _.startsWith(item[k].toLowerCase(), value.toLowerCase());
      })
    });
  });
}

export const filterArrayStartsWith = (items, value) => _.filter(items, function (i) { return i.startsWith(value); });

export function isOdd(num) { return num % 2; }

export const getCalStartDates = (items, monthCount) => {

  const months = []
  const monthDates = []
  const monthStartDates = []

  if (items) {
    if (_.isArray(items)) {
      items.forEach(dateKey => {
        if (dateKey.toLowerCase() !== 'missing') {
          const d = new Date(dateKey)
          const m = d.getMonth()
          if (!months.includes(m)) {
            months.push(m)
            monthDates.push(d)
            monthStartDates.push(new Date(d.getFullYear(), d.getMonth(), 1))
          }
        }
      })
    } else {
      Object.keys(items).forEach(dateKey => {
        if (dateKey.toLowerCase() !== 'missing') {
          const d = new Date(dateKey)
          const m = d.getMonth()
          if (!months.includes(m)) {
            months.push(m)
            monthDates.push(d)
            monthStartDates.push(new Date(d.getFullYear(), d.getMonth(), 1))
          }
        }
      })
    }
  }

  if (monthCount && monthStartDates.length === 1) {
    const msd = monthStartDates[0]
    for (var i = 1; i <= monthCount; i++) {
      const d = new Date(msd)
      const dd = new Date(d.setMonth(d.getMonth() + i))
      monthStartDates.push(dd)
    }
  }

  return { months, monthDates, monthStartDates }
}

export const getUniqueListData = (data, staticViews) => {
  const _propKeys = {}
  if (data) {
    // loop the data
    Object.keys(data).forEach(key => {
      const _dataItem = data[key]
      if (_dataItem) {
        // the the fields in the data item
        Object.keys(_dataItem).forEach(propKey => {
          if (!_propKeys[propKey]) { _propKeys[propKey] = [] }
          const staticView = staticViews[propKey]
          const dataPropValue = _dataItem[propKey]
          if (_.isArray(dataPropValue)) {
            dataPropValue.forEach(dpv => {
              if (!_propKeys[propKey].includes(dpv)) {
                if (staticView && staticView[dpv]) {
                  if (!_propKeys[propKey].includes(staticView[dpv])) {
                    _propKeys[propKey].push(staticView[dpv])
                  }
                } else {
                  _propKeys[propKey].push(dpv)
                }
              }
            })
          } else {
            if (!_propKeys[propKey].includes(dataPropValue)) {
              _propKeys[propKey].push(dataPropValue)
            }
          }
        })
      }
    })
  }
  return _propKeys
}

export const filteredDates = (dateList, before) => dateList.filter(function (dateStr) {
  var today = new Date();
  // Parse the date string into a Date object
  var dateParts = dateStr.split('/');
  var date = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]); // Month is 0-based in JavaScript 
  // Compare the parsed date with today
  return before ? date <= today : date >= today;
});

export const getUniqueData = (data) => {

  const excludedKeys = ['id', 'key', 'itemKey', '_itemKey', '_dateInfo', '_sv', 'startTimeUTC', 'position']

  const getPairs = (data) => {
    const keyValuePairs = {};
    // Iterate over each item in the complex object
    _.forEach(data, (item, key1) => {
      // Iterate over each key-value pair in the item
      _.forEach(item, (value, key) => {
        if (!key.startsWith('_') && !key.endsWith('UTC') && !excludedKeys.includes(key)) {
          // If the key already exists in keyValuePairs, push the value to its array
          // Otherwise, create a new array for the key and push the value

          if (keyValuePairs[key]) {
            if (_.isArray(value)) {
              keyValuePairs[key] = _.uniq([...keyValuePairs[key], ...value])
            } else {
              keyValuePairs[key].push(value);
              keyValuePairs[key] = _.uniq(keyValuePairs[key])
            }
          } else {
            if (_.isArray(value)) {
              keyValuePairs[key] = [...value];
            } else {
              keyValuePairs[key] = [value];
            }
          }
        }
      });
    });

    return keyValuePairs
  }

  const keysToExclude = _.flatMap(data, item => _.keys(item));
  const uniqueKeys = _.uniq(keysToExclude.filter(key => !excludedKeys.includes(key)));
  const uniqueValues = getPairs(data)
  return { uniqueKeys, uniqueValues }
}


export const getRecentAuths = (found, dayCount) => {

  const nowDate = new Date()
  const nowTime = nowDate.getTime()
  const dis = 1000 * 3600 * (24 * dayCount)

  return _.filter(found, function (f) {
    if (f.metadata && f.metadata.lastRefreshTime) {
      const time = f.metadata.lastRefreshTime
      const lrtDate = new Date(time)
      const lrtTime = lrtDate.getTime()
      const diffTime = nowTime - lrtTime
      const lrtDays = diffTime / dis
      if (lrtDays < dayCount) {
        return found
      }
    }
  })
} 