import _ from 'lodash';
import { dispatchProps } from "../cnr/reducers/reducerHelpers/dispatchProps";
import { callFunction } from "./fbAll";

const authGenericTypes = {
  authGeneric: 'authGeneric',
}

export const authFunctionTypes = {
  addAuthToken: 'addAuthToken',
  addProfileTopic: 'addProfileTopic',
  createAuth: 'createAuth',
  createCustomToken: 'createCustomToken',
  createOrUpdateAuth: 'createOrUpdateAuth',
  deleteAuth: 'deleteAuth',
  deleteAuthByEmail: 'deleteAuthByEmail',
  disableAuth: 'disableAuth',
  getAuthByEmail: 'getAuthByEmail',
  getAuthByEmailOrPhoneNumber: 'getAuthByEmailOrPhoneNumber',
  getAuthByPhoneNumber: 'getAuthByPhoneNumber',
  getAuthsByEmail: 'getAuthsByEmail',
  getClientProfiles: 'getClientProfiles',
  getProfile: 'getProfile',
  removeProfileTopic: 'removeProfileTopic',
  updateAuth: 'updateAuth',
  updateAuthByUid: 'updateAuthByUid',
  updateAuthPassword: 'updateAuthPassword',
}


/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(authGenericTypes.authGeneric, callData)
  return data
}

export const fsfn_auth = {
  addAuthCustomToken: (itemData, callback) => fsfn_addAuthCustomToken(itemData, callback),
  addProfileTopic: (itemData, dispatch, dispatchType) => fsfn_addProfileTopic(itemData, dispatch, dispatchType),
  createCustomToken: (creds, dispatch) => fsfn_createCustomToken(creds, dispatch),
  createOrUpdateAuth: (itemData, dispatch) => fnfs_createOrUpdateAuth(itemData, dispatch),
  createOrUpdateAuthAndSignIn: (data) => fsfn_createOrUpdateAuthAndSignIn(data),
  deleteAuthByEmail: (itemData, dispatch, callback) => fsfn_deleteAuthByEmail(itemData, dispatch, callback),
  deleteAuthByUid: (uid, dispatch, callback) => fsfn_deleteAuthByUid(uid, dispatch, callback),
  disableAuth: (uid, itemData, callback) => fsfn_disableAuth(uid, itemData, callback),
  getAuthByEmail: (itemData, callback, projectName) => fsfn_getAuthByEmail(itemData, callback, projectName),
  getAuthByEmailOrPhoneNumber: (itemData, callback) => fsfn_getAuthByEmailOrPhoneNumber(itemData, callback),
  getAuthByPhoneNumber: (itemData, callback, projectName) => fsfn_getAuthByPhoneNumber(itemData, callback, projectName),
  getAuthsByChunkEmail: (chunks, pathViews, updateDatabase, callback, staticAppUsers) => fsfn_getAuthsByChunkEmail(chunks, pathViews, updateDatabase, callback, staticAppUsers),
  getAuthsByEmail: (appUsers, callback) => fsfn_getAuthsByEmail(appUsers, callback),
  getClientProfiles: (pathViews, emails, callback) => fsfn_getClientProfiles(pathViews, emails, callback),
  getProfileAuth: (profileData, callback) => fsfn_getProfileAuth(profileData, callback),
  removeFcm: (authUid, authData, callback) => fsfn_removeFcm(authUid, authData, callback),
  removeProfileTopic: (itemData, dispatch, dispatchType) => fsfn_removeProfileTopic(itemData, dispatch, dispatchType),
  updateAuth: (itemData, dispatch) => fsfn_updateAuth(itemData, dispatch),
  updateAuthByUid: (authUid, authData, callback, projectName) => fsfn_updateAuthByUid(authUid, authData, callback, projectName),
  updateAuthPassword: (itemData) => fsfn_updateAuthPassword(itemData),
}

/**
 * 
 * @param {*} chunks 
 * @param {function} callback 
 */
const fsfn_getAuthsByChunkEmail = async (chunks, pathViews, updateDatabase, callback, staticAppUsers) => {
  try {
    const response = await chunkPromise(chunks, pathViews, updateDatabase)
    let _found = []
    if (response) {
      response.forEach(item => {
        const { result, success } = item ?? {}
        if (success) {
          const { found } = result ?? {}
          if (found) {
            _found = [..._found, ...found]
          }
        }
      })
    }
    if (callback) {
      callback({ found: _found, staticAppUsers })
    } else {
      return { found: _found }
    }
  } catch (error) {
    console.error(error)
  }
}

const fsfn_getClientProfiles = async (pathViews, emails, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.getClientProfiles, pathViews, emails, showLogs: true }
    const response_data = await callFsFunction(callData) // OK 
    const { result } = response_data ?? {}
    if (callback) {
      callback(result)
    } else {
      return result
    }
  } catch (error) {
    console.error(error)
  }
}

const fsfn_getAuthsByEmail = async (appUsers, callback, index) => {
  try {
    const _appUsers = []
    Object.keys(appUsers).forEach(key => {
      const appUser = appUsers[key]
      const { email, phoneNumber } = appUser
      if (email || phoneNumber) {
        const _appUser = { email, phoneNumber }
        _appUsers.push(_appUser)
      }
    })
    const callData = { authFunctionType: authFunctionTypes.getAuthsByEmail, appUsers: _appUsers }
    const { result } = await callFsFunction(callData) // OK
    if (callback) {
      callback(result)
    } else {
      return result
    }
  } catch (error) {
    console.error(error)
  }
}

const fsfn_getProfileAuth = async (profileData, callback) => {
  const { email } = profileData
  try {
    const callData = { authFunctionType: authFunctionTypes.getAuthByEmail, email }
    const response_data = await callFsFunction(callData) // OK  
    const { result: authData } = response_data ?? {}
    callback(profileData, authData)
  } catch (error) {
    console.error(error)
    callback({})
  }
}

/**
 * 
 * @param {object} itemData 
 * @param {function} callback
 * @description - adds a token (using setCustomUserClaims) to auth where email. No return needed 
 */
const fsfn_addAuthCustomToken = async (itemData, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.addAuthToken, ...itemData }
    const response_data = await callFsFunction(callData) // OK
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}

/** Deletes an auth user by uid */
const fsfn_deleteAuthByUid = async (uid, dispatch, callback) => {
  try {
    const allowDelete = true
    if (allowDelete) {
      const callData = { authFunctionType: authFunctionTypes.deleteAuth, uid }
      const { success } = await callFsFunction(callData) // OK
      if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
      if (callback) { callback(success) }
    } else {
      console.log('fsfn_deleteAuthByUid', uid)
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} itemData 
 * @param {function} dispatch 
 * @param {function} callback 
 * @deprecated
 */
const fsfn_deleteAuthByEmail = async (itemData, dispatch, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.deleteAuthByEmail }
    const { success } = await callFsFunction(callData) // OK
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
    if (callback) { callback(success) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} uid 
 * @param {object} itemData 
 * @param {function} callback 
 * @deprecated
 */
const fsfn_disableAuth = async (uid, itemData, callback) => {
  try {
    const { disabled } = itemData
    const data = { authFunctionType: authFunctionTypes.disableAuth, uid, disable: disabled }
    const { success } = await callFsFunction(data) // OK
    if (callback) { callback(success) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {string} authUid 
 * @param {object} authData 
 * @param {function} callback 
 * @callback response_data
 */
const fsfn_updateAuthByUid = async (authUid, authData, callback, projectName) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuthByUid, uid: authUid, authData, projectName }
    const response_data = await callFsFunction(callData) // OK 
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} authUid 
 * @param {*} authData 
 * @param {*} callback 
 * @deprecated
 */
const fsfn_removeFcm = async (authUid, authData, callback) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuthByUid, uid: authUid, authData }
    const response_data = await callFsFunction(callData) // OK
    if (callback) { callback(response_data) }
  } catch (error) {
    console.error(error)
  }
}

// {
//   "uid": "QhPT1iEs55g7RiMgYC0KRcEwaJk2",
//   "email": "email",
//   "emailVerified": false,
//   "displayName": null,
//   "photoURL": null,
//   "phoneNumber": null,
//   "disabled": false,
//   "metadata": {
//       "creationTime": "Mon, 16 Oct 2023 16:58:20 GMT",
//       "lastSignInTime": "Mon, 16 Oct 2023 17:25:48 GMT",
//       "lastRefreshTime": "Thu, 09 Nov 2023 20:36:36 GMT"
//   },
//   "providerData": [
//       {
//           "uid": "email",
//           "displayName": null,
//           "email": "email",
//           "photoURL": null,
//           "providerId": "password",
//           "phoneNumber": null
//       }
//   ],
//   "passwordHash": null,
//   "passwordSalt": null,
//   "tokensValidAfterTime": "Mon, 16 Oct 2023 16:58:20 GMT",
//   "tenantId": null
// }

// {
//   "uid": "isOUmqKoJGOrd6ncgWMxjClA1Cd2",
//   "email": "email",
//   "emailVerified": true,
//   "displayName": "Phil O'Keeffe",
//   "photoURL": "https://lh3.googleusercontent.com/a/AGNmyxZoPfkJKNGztSxByuHEKIrYGvjNiwzSq3lhj7fjXQ=s96-c",
//   "phoneNumber": "+14126002081",
//   "disabled": false,
//   "metadata": {
//       "creationTime": "Thu, 04 Feb 2021 06:33:12 GMT",
//       "lastSignInTime": "Thu, 09 Nov 2023 16:57:13 GMT",
//       "lastRefreshTime": "Thu, 09 Nov 2023 20:43:55 GMT"
//   },
//   "providerData": [
//       {
//           "uid": "102084495362107748230",
//           "displayName": "Phil O'Keeffe",
//           "email": "email",
//           "photoURL": "https://lh3.googleusercontent.com/a/AGNmyxZoPfkJKNGztSxByuHEKIrYGvjNiwzSq3lhj7fjXQ=s96-c",
//           "providerId": "google.com",
//           "phoneNumber": null
//       },
//       {
//           "uid": "+14126002081",
//           "displayName": null,
//           "email": null,
//           "photoURL": null,
//           "providerId": "phone",
//           "phoneNumber": "+14126002081"
//       },
//       {
//           "uid": "email",
//           "displayName": "Phil O'Keeffe",
//           "email": "email",
//           "photoURL": "https://lh3.googleusercontent.com/a/AGNmyxZoPfkJKNGztSxByuHEKIrYGvjNiwzSq3lhj7fjXQ=s96-c",
//           "providerId": "password",
//           "phoneNumber": null
//       }
//   ],
//   "passwordHash": null,
//   "passwordSalt": null,
//   "customClaims": {
//       "profileLevel": 11,
//       "authLevel": 12
//   },
//   "tokensValidAfterTime": "Thu, 04 Feb 2021 06:33:12 GMT",
//   "tenantId": null
// }

/**
 * 
 * @param {*} itemData 
 * @param {*} callback  
 * @param {*} returnResult 
 * @returns - the `result` object
 */
const fsfn_getAuthByEmail = async (itemData, callback, projectName) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.getAuthByEmail, ...itemData, projectName }
    const { result } = await callFsFunction(callData) // OK  
    if (callback) { callback(result) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @param {*} callback  
 * @param {*} returnResult 
 * @returns - the `result` object
 */
const fsfn_getAuthByPhoneNumber = async (itemData, callback, projectName) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.getAuthByPhoneNumber, ...itemData, projectName }
    const { result } = await callFsFunction(callData) // OK 
    if (callback) { callback(result) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @param {*} callback  
 * @param {*} returnResult 
 * @returns - the `result` object
 */
const fsfn_getAuthByEmailOrPhoneNumber = async (itemData, callback) => {
  try {
    itemData.showLogs = true
    const callData = { authFunctionType: authFunctionTypes.getAuthByEmailOrPhoneNumber, ...itemData }
    const { result } = await callFsFunction(callData) // OK 
    if (callback) { callback(result) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} itemData (uid, topic)
 * @param {function} dispatch 
 * @param {enum} dispatchType 
 * @returns Subscribes a user (via email or uid) to a topic using admin.messaging().subscribeToTopic
 */
const fsfn_addProfileTopic = async (itemData, dispatch, dispatchType) => {
  // data: { email: any, topic: any; uid: any }
  try {
    const callData = { authFunctionType: authFunctionTypes.addProfileTopic, ...itemData }
    const response_data = await callFsFunction(callData, true) // OK
    const { success } = response_data ?? {}
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
    return response_data

  } catch (error) {
    console.error(error)
  }
}

const fsfn_removeProfileTopic = async (itemData, dispatch, dispatchType) => {
  // data: { email: any, topic: any; }
  try {

  } catch (error) {
    console.error(error)
  }
  const callData = { authFunctionType: authFunctionTypes.removeProfileTopic, ...itemData }
  const response_data = await callFsFunction(callData) // OK
  const { success } = response_data ?? {}
  if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
}

const fnfs_createOrUpdateAuth = async (itemData, dispatch) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.createOrUpdateAuth, ...itemData }
    const response_data = await callFsFunction(callData) // OK 
    const { success } = response_data ?? {}
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
  } catch (error) {
    console.error(error)
  }
}

/** Updates the auth based on the email address  */
const fsfn_updateAuth = async (itemData, dispatch) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuth, ...itemData }
    const response_data = await callFsFunction(callData) // OK
    const { success } = response_data ?? {}
    if (dispatch) { dispatch({ type: success ? dispatchProps.success : dispatchProps.error }) }
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {*} itemData 
 * @returns  Updates the password of in auth based on the email address 
 * @deprecated
 */
const fsfn_updateAuthPassword = async (itemData) => {
  try {
    const callData = { authFunctionType: authFunctionTypes.updateAuthPassword, ...itemData }
    const response_data = await callFsFunction(callData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/** Gets the auth by email and then updates the auth password */
const fsfn_createOrUpdateAuthAndSignIn = async (data) => {
  try {
    data.authFunctionType = authFunctionTypes.createOrUpdateAuth
    const response = await callFsFunction(data) // OK
    return response
  } catch (error) {
    console.error(error)
  }
}

const fsfn_createCustomToken = async (creds, dispatch) => {
  try {
    const { email: uid } = creds ?? {}
    const callData = { authFunctionType: authFunctionTypes.createCustomToken, uid }
    const response = await callFsFunction(callData) // OK  
    if (dispatch) { dispatch(response) }
  } catch (error) {
    console.error(error)
  }
}


const chunkPromise = async (chunks, pathViews, updateDatabase) => {
  const promises = []
  chunks.forEach(chunk => {
    const appUsers = []
    Object.keys(chunk).forEach(key => {
      const chunkItem = chunk[key]
      let { email, phoneNumber } = chunkItem ? chunkItem : {}
      email = email ? email.replace(/ /g, '') : null
      phoneNumber = phoneNumber ? phoneNumber.replace(/ /g, '') : null
      if ((email && !_.isEmpty(email)) || (phoneNumber && !_.isEmpty(phoneNumber))) {
        const item = {}
        if (email) { item.email = email.toLowerCase() }
        if (phoneNumber) { item.phoneNumber = phoneNumber }
        appUsers.push(item)
      }
    })
    const callData = { authFunctionType: authFunctionTypes.getAuthsByEmail, appUsers, pathViews, updateDatabase: false }
    promises.push(callFsFunction(callData))
  })
  return Promise.all(promises)
}



// export const fsfn_subscribeToTopic = async (itemData, dispatch, dispatchType) => {
//   try {
//     itemData.authFunctionType = authFunctionTypes.subscribeToTopic
//     const response_data = (await httpsCallable(functions, authFunctionTypes.subscribeToTopic)(itemData))
//     if (dispatch) { dispatch({ type: dispatchType, response_data, ast: 'Subscribed to Topic', aft: 'Not subscribed to Topic' }) }
//   } catch (error) {
//     console.error(error)
//   }
// }

// export const fsfn_unsubscribeFromTopic = async (itemData, dispatch, dispatchType) => {
//   try {
//     itemData.authFunctionType = authFunctionTypes.unsubscribeFromTopic
//     const response_data = (await httpsCallable(functions, authFunctionTypes.unsubscribeFromTopic)(itemData))
//     if (dispatch) { dispatch({ type: dispatchType, response_data, ast: 'Unsubscribed from Topic', aft: 'Not unsubscribed to Topic' }) }
//   } catch (error) {
//     console.error(error)
//   }
// }


/** adds a token to auth where email */
// export const fsfn_addAdminToken = (tokenType, tokenId, itemData, dispatch, dispatchType) => {
//    data : { authFunctionType:authFunctionTypes.addAuthToken, email: null, token: null }
//   try {
//     var fn = httpsCallable(functions, authGenericTypes.authGeneric);
//     if (fn) {
//       const data = {
//         email: itemData.email,
//         token: {
//           [tokenType]: tokenType === 'clients' ? tokenId : [tokenId],
//         }
//       }
//       if (itemData.adminLevel) { data.token.adminLevel = itemData.adminLevel }
//       fn(data).then(function (result) {
//         dispatch({ type: dispatchType })
//       })
//     }
//   } catch (error) {
//     console.error(error)
//   }
// }

/**
 * itemData {email: any; firstName: any; lastName: any; password: any}
 * @param {*} itemData (email)
 * @param {*} dispatch
 * @param {*} dispatchType
 */
// export const fsfn_createAuth = async (itemData, dispatch, dispatchType, callback) => {
//   //  { email: any; firstName: any; lastName: any; password: any; }
//   const response_data = (await httpsCallable(functions, authFunctionTypes.createAuth)(itemData))
//   const result = { type: dispatchType, response_data, ast: 'Auth Created', aft: 'Auth Not Created' }
//   if (dispatch) { dispatch(result) }
//   if (callback) { callback(result) }
//   return result
// }