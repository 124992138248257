import { createFsDocKey } from '../../appData/fsAppData';
import { dispatchProps } from "../../../cnr/reducers/reducerHelpers/dispatchProps";
import { createRefPath } from "../../appData/appRefPaths";
import { fs_set_doc } from "../../appData/fsData";

export const fsUpdate_updateImageLinks = (props) => {

  const {
    appUser,
    baseRef,
    dispatch,
    ufProps,
  } = props

  const { refPath } = baseRef
  const { uid } = appUser ?? {}
  let { viewKey, view, urlLink, dataRestrictions } = ufProps ?? {}

  if (view && viewKey && urlLink) {

    const _setRef = createRefPath(['imageLinks', createFsDocKey('imageLink')], refPath)

    const updateData = {
      urlLink,
      uid,
      parentKeys: {
        view,
        viewKey
      }
    }

    if (dataRestrictions && (dataRestrictions.all || dataRestrictions.updateImageLinks)) {
      console.log('updateData', updateData)
      dispatch({ type: dispatchProps.successAlt, dispatch })
      return false
    }
    fs_set_doc(_setRef, updateData, false, dispatch)
  } else {
    alert('Item not rated. No viewItemKey')
  }
}