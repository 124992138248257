import _ from "lodash";
import { gEnums } from "../../../enums/globalEnums";
import { iconColorTypes, settingsIconTypes } from "../../../enums/settingsIconTypes"

export const menuItemItems = {
  adminOnly: 'adminOnly',
  remove: 'remove',
  show: 'show',
  header: 'header',
  tagged: 'tagged',
  schedule: 'schedule',
  meta: 'meta',
  cssed: 'cssed',
  secret: 'secret',
  pin: 'pin',
  restrictAdd: 'restrictAdd',
}

export const menuItemItems_limited = {
  show: menuItemItems.show,
  tagged: menuItemItems.tagged,
  schedule: menuItemItems.schedule,
  pin: menuItemItems.pin,
}

export const menuItemItems_show = {
  show: menuItemItems.show
}

export const updateMenuItemSettings = (updateProps, menuItemMod, svss, viewItems, desktopMode) => {

  const { vit, checkedGridType, subProp } = updateProps ?? {}
  const { clickOptionType, menuItem } = menuItemMod ?? {}
  const { key, subKey } = menuItem ?? {}

  let optionPropKey;

  switch (clickOptionType) {
    case gEnums.menuItemOptionTypes.check:
      optionPropKey = desktopMode ? 'showDesktop' : 'show'
      break;
    default:
      optionPropKey = clickOptionType
  }

  switch (checkedGridType) {
    case gEnums.menuTypes.propItem:
      if (viewItems[vit] && viewItems[vit].props && viewItems[vit].props[key]) {

        switch (optionPropKey) {

          case gEnums.menuItemOptionTypes.remove:
            const tfValue = !viewItems[vit].props[key][optionPropKey]
            viewItems[vit].props[key][optionPropKey] = tfValue
            if (tfValue) {
              viewItems[vit].props[key]._deleteMe = true
            } else {
              delete viewItems[vit].props[key]._deleteMe
            }
            break;

          case gEnums.menuItemOptionTypes.fix:
            if (key.indexOf(' ') >= 0) {
              const cc = _.camelCase(key)
              viewItems[vit].props[cc] = viewItems[vit].props[key]
              viewItems[vit].props[cc].key = cc
              viewItems[vit].props[key]._deleteMe = true
            }
            break;
          default:
            viewItems[vit].props[key][optionPropKey] = !viewItems[vit].props[key][optionPropKey]
        }
      }
      break;

    case gEnums.menuTypes.propSections:
      if (viewItems[vit] && viewItems[vit].propSections && viewItems[vit].propSections[key]) {
        if (optionPropKey === gEnums.menuItemOptionTypes.remove) {
          const tfValue = !viewItems[vit].propSections[key][optionPropKey]
          viewItems[vit].propSections[key][optionPropKey] = tfValue
          if (tfValue) {
            viewItems[vit].propSections[key]._deleteMe = true
          } else {
            delete viewItems[vit].propSections[key]._deleteMe
          }
          // delete viewItems[vit].propSections[key]
        } else {
          viewItems[vit].propSections[key][optionPropKey] = !viewItems[vit].propSections[key][optionPropKey]
        }
      }
      break;

    case gEnums.menuTypes.viewItem:
      if (viewItems[key]) {
        if (optionPropKey === gEnums.menuItemOptionTypes.remove) {
          const tfValue = !viewItems[key][optionPropKey]
          viewItems[key][optionPropKey] = tfValue
          if (tfValue) {
            viewItems[key]._deleteMe = true
          } else {
            delete viewItems[key]._deleteMe
          }
        } else {
          viewItems[key][optionPropKey] = !viewItems[key][optionPropKey]
        }
      }
      break;

    case gEnums.menuTypes.apply:
      if (!svss[key]) { svss[key] = {} }
      if (subKey) {
        if (!svss[key][subKey]) { svss[key][subKey] = {} }
        svss[key][subKey].apply = !svss[key][subKey].apply
      } else {
        svss[key].apply = !svss[key].apply
      }
      break;

    case gEnums.menuTypes.applyColor:
      if (subProp) {
        if (!svss[key]) { svss[key] = {} }
        if (subKey) {
          if (!svss[key][subKey]) { svss[key][subKey] = {} }
          if (!svss[key][subKey].colors) { svss[key][subKey].colors = {} }
          svss[key][subKey].colors.apply = !svss[key][subKey].colors.apply
        }
      } else {
        if (!svss[key]) { svss[key] = {} }
        if (subKey) {
          if (!svss[key][subKey]) { svss[key][subKey] = {} }
          svss[key][subKey].apply = !svss[key][subKey].apply
        } else {
          svss[key].apply = !svss[key].apply
        }
      }
      break;
    default:
    // nothing
  }
}

export const updateMenuItem = (item, menuType) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
      item.pit = item.key
      break;
    case gEnums.menuTypes.propSections:
      item.psg = item.key
      break;
    case gEnums.menuTypes.viewItem:
      item.vit = item.key
      break;
    case gEnums.menuTypes.viewItemData:
      item.dKey = item.key
      break;
    default:
    // nothing 
  }
}

export const getMenuHeaderItems = (props) => {

  const {
    menuItemAllows,
    menuItem,
    hddButtons,
  } = props

  const ddis = []

  const { iconProps } = menuItem ?? {}

  if (menuItemItems) {
    Object.keys(menuItemItems).forEach(mii => {
      if (menuItemAllows && menuItemAllows[mii]) {
        const prs = {
          key: mii,
          icon: settingsIconTypes[mii],
          color: iconProps[mii] ? iconColorTypes[mii] : iconColorTypes.default
        }
        if (prs) {
          ddis.push(hddButtons.menuSettingsItem(prs))
        }
      }
    })
  }

  return ddis

}
/** 
 * @returns group of settings indicating which items are available for change
 */
export const menuItemAllowances = (menuType, isGlobal) => {

  const a = {
    adminOnly: allowAdminOnly(menuType, isGlobal),
    check: allowShow(menuType, isGlobal),
    show: allowShow(menuType, isGlobal),
    color: allowColor(menuType, isGlobal),
    css: allowCss(menuType, isGlobal),
    drill: allowDrill(menuType, isGlobal),
    fix: allowFix(menuType, isGlobal),
    header: allowPropItemAndSections(menuType, isGlobal),
    pin: allowPropItemAndSections(menuType, isGlobal),
    remove: allowRemove(menuType, isGlobal),
    schedule: allowPropItemAndSections(menuType, isGlobal),
    secret: allowSecret(menuType, isGlobal),
    select: allowPropItemAndSections(menuType, isGlobal),
    tagged: allowPropItemAndSections(menuType, isGlobal),
    restrictAdd: allowRestrictAdd(menuType, isGlobal)
  }
  // a.color ||
  // a.select || 
  if (a.adminOnly || a.tagged || a.check || a.css || a.drill || a.fix || a.secret || a.remove) { a.useAllow = true }
  return a
}

export const allowApply = (menuType) => {
  switch (menuType) {
    case gEnums.menuTypes.apply:
    case gEnums.menuTypes.applyColor:
      return true
    default:
      return false
  }
}

const allowSecret = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
    case gEnums.menuTypes.propSections:
      return isGlobal
    default:
      return false
  }
}


/** Return allowSelect based on menuType */
const allowSelect = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.viewItem:
    case gEnums.menuTypes.propItem:
    case gEnums.menuTypes.propSections:
      return true
    default:
      return false
  }
}
/** Return allow based on menuType */
const allowAdminOnly = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.viewItem:
      return !isGlobal
    case gEnums.menuTypes.propItem:
      return !isGlobal
    case gEnums.menuTypes.propSections:
      return true // !isGlobal
    default:
      return false
  }
}

const allowRestrictAdd = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
      return !isGlobal
    default:
      return false
  }
}

/** Return allow based on menuType */
export const allowShow = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.viewItem:
      return !isGlobal
    case gEnums.menuTypes.propItem:
      return !isGlobal
    case gEnums.menuTypes.propSections:
      return true // !isGlobal
    default:
      return false
  }
}

/** Return allowCss based on menuType */
const allowCss = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
      return false //!isGlobal
    default:
      return false
  }
}

/** Return allowName based on menuType allowHeader */
const allowPropItemAndSections = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
    case gEnums.menuTypes.propSections:
      return !isGlobal
    default:
      return false
  }
}

/** Return allowRemove based on menuType */
const allowRemove = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.viewItem:
    case gEnums.menuTypes.propItem:
    case gEnums.menuTypes.propSections:
      return true
    default:
      return false
  }
}

/** Return allowColor based on menuType */
export const allowColor = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.applyColor:
      return true
    default:
      return false
  }
}

const allowDrill = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
      return false //true
    default:
      return false
  }
}

const allowFix = (menuType, isGlobal) => {
  switch (menuType) {
    case gEnums.menuTypes.propItem:
      return true
    default:
      return false
  }
}

export const assignColors = (data_current, themeColors, menuType, getColors, item, fromArray) => {
  const cls = {}
  let itemColors;
  if (themeColors && data_current) {
    switch (menuType) {
      case gEnums.menuTypes.apply:
        if (data_current[item]) {
          cls.bc = themeColors[data_current[item].backgroundColor]
          cls.c = themeColors[data_current[item].color]
        }
        break;
      case gEnums.menuTypes.applyColor:
        if (fromArray) {
          if (data_current[item] && data_current[item].colors) {
            itemColors = data_current[item].colors
            cls.cla = itemColors.apply
            cls.bcl = itemColors.backgroundColor && themeColors[itemColors.backgroundColor] ? themeColors[itemColors.backgroundColor] : null
            cls.cl = itemColors.color && themeColors[itemColors.color] ? themeColors[itemColors.color] : null
          }
        } else {
          if (data_current[item.key] && data_current[item.key][item.subKey]) {
            cls.cla = data_current[data_current[item.key][item.subKey]].apply
            cls.bc = themeColors[data_current[data_current[item.key][item.subKey]].backgroundColor]
            cls.c = themeColors[data_current[data_current[item.key][item.subKey]].color]
          }
        }
        break;
      default:
        if (getColors && data_current[item]) {
          cls.bc = data_current[item].backgroundColor
          cls.c = data_current[item].color
        }
    }
  }
  return cls
}

export const setUpdateProps = (updateProps, menuItem, menuType, subProp, clickOptionType) => {

  let ups = {}

  if (!updateProps) {
    ups = { checkedGridType: menuType, subProp }
  } else {
    ups = updateProps
    ups.checkedGridType = menuType
    ups.subProp = subProp
  }

  ups.menuItemMod = { clickOptionType, menuItem }

  return ups
}

export const getMenuItemMessage = (key, props) => {
  const { useClientSignIn, useClientThemes, useHomeTransitions, useGlobalGroupSettings, useGlobalAlphabetSettings } = props
  let message = 'The SETTINGS_TYPE settings are being controlled by the SETTINGS_AREA. The settings will be saved, but changing the settings here will have no effect.'
  const captionReplace = '`' + _.startCase(key) + '`'
  switch (key) {
    case 'appSignIn':
      if (useClientSignIn) {
        return message.replace(/SETTINGS_TYPE/g, captionReplace).replace(/SETTINGS_AREA/g, '`client settings`')
      }
      break;
    case 'themeColors':
    case 'themedItems':
      if (useClientThemes) {
        return message.replace(/SETTINGS_TYPE/g, captionReplace).replace(/SETTINGS_AREA/g, '`client settings`')
      }
      break;
    case 'transitionItems':
      if (useHomeTransitions) {
        return message.replace(/SETTINGS_TYPE/g, captionReplace).replace(/SETTINGS_AREA/g, '`home settings`')
      }
      break;
    case 'grouping':
      if (useGlobalGroupSettings || useGlobalAlphabetSettings) {
        return message.replace(/SETTINGS_TYPE/g, captionReplace).replace(/SETTINGS_AREA/g, '`Grouping Options` in `Page Management')
      }
      break;
    default:
    // nothing
  }
}