import { seasonalHelpers } from '../cnr/reducers/reducerHelpers/seasonalSportsData';

export const updateScheduleDates = async (paps_state, latestSeason, scheduleProps, callback) => {

  const { pathViews } = paps_state
  const pks = seasonalHelpers.getPks(pathViews, latestSeason)

  const data = {
    [scheduleProps.sectionCount]: scheduleProps.selectedDates
  }

  // updates `_seasonals`
  // updateSeasonalOrGlobal(seasonalUpdateTypes.history, pathViews, pks, 'scheduleDates', data, callback)

} 