import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { gEnums } from '../../enums/globalEnums';
import { currentHelpers } from '../../redirection/current';
import { createRefPath_client, createRefPath } from '../appData/appRefPaths';
import { fs_update_doc } from '../appData/fsData';

/**
 * Updates the `profiles` collection (viewKey) with the `name` and `path` of the selected item.
 * @param {function} dispatch 
 * @param {object} appUser 
 * @param {object} paps_state 
 * @param {string} pageDataCaption 
 */
export const updateProfilePageFavorite = (dispatch, appUser_state, paps_state, pageDataCaption, favItem) => {

  const { appUser, appUsers } = appUser_state ?? {}
  const { pathViews, view, viewKey, pathName } = paps_state ?? {}
  const { key: key_fav, name: name_fav, pathName: pathName_fav } = favItem ? favItem : {}
  const favCollectionKey = key_fav ? favItem[key_fav] : null

  const _view = key_fav ? key_fav : view
  const _viewKey = favCollectionKey ? favCollectionKey : viewKey
  const _pageDataCaption = name_fav ? name_fav : pageDataCaption
  const _pathName = pathName_fav ? pathName_fav : pathName

  const appUserAccess = getAppUserAccess(appUsers)

  let { isAppUser, appUserAccessType, email, pageFavs } = appUserAccess ?? {}
  const { profileData } = appUser ?? {}

  const { districts, sports } = pathViews ?? {}

  if (!pageFavs) { pageFavs = {} }
  if (!pageFavs[_view]) { pageFavs[_view] = {} }

  if (pageFavs[_view][_viewKey]) {
    delete pageFavs[_view][_viewKey]
  } else {
    pageFavs[_view][_viewKey] = {
      name: _pageDataCaption,
      pathName: _pathName
    }
    if (districts) { pageFavs[_view][_viewKey].districts = districts }
    if (sports) { pageFavs[_view][_viewKey].sports = sports }
  }

  const d = { pageFavs }

  let _refPath;

  if (isAppUser) {
    switch (appUserAccessType) {
      case gEnums.appUserAccessTypes.admin:
      case gEnums.appUserAccessTypes.superAdmin:
        _refPath = createRefPath(['profiles', profileData.id])
        break;
      default:
        _refPath = createRefPath_client(pathViews, ['profiles', email])
    }
    fs_update_doc(_refPath, d, dispatch)
  } else {
    currentHelpers.storageItem_set('pageFavs', JSON.stringify(pageFavs))
  }
}