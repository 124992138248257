import React, { createContext, useContext, useEffect } from 'react'
import ReactGA from 'react-ga';
import { ParentContext } from './ParentContext';
import { getFbConfig } from '../../../project/appConfiguration';

export const GaContext = createContext();

const GaProvider = (props) => {

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state } = states ?? {}
  const { pathName, pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { projectOptions } = aps_global ?? {}
  const { allowGoogleAnalytics } = projectOptions ?? {}
  const trueConfig = getFbConfig()
  const { measurementId } = trueConfig ?? {}

  // useEffect(() => {
  //   if (allowGoogleAnalytics && measurementId && pathViews && pathViews.events) {
  //     try {
  //       ReactGA.initialize(measurementId);
  //       ReactGA.set({ event: pathViews.events }); // Set custom dimension for the client ID
  //       console.log('ReactGA initialize', measurementId, pathViews.events)
  //     } catch (error) {
  //       console.log('ReactGA error 1', error)
  //     }
  //     // https://analytics.google.com/
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (ReactGA && allowGoogleAnalytics && measurementId && pathViews && pathViews.events) {
      try {
        ReactGA.pageview(pathName)
        console.log('ReactGA pageview', pathName)
      } catch (error) {
        console.error('ReactGA pageview errir', error)
      }
    }
    // eslint-disable-next-line
  }, [pathName]);

  return <GaContext.Provider value={{}}>
    {props.children}
  </GaContext.Provider>
}

export default GaProvider