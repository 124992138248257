import _ from 'lodash';
import { addAdditionalProps } from './additionalProps';
import { addLabelProps } from './labelProps';
import { dynListTypes, gEnums, listTypes } from '../enums/globalEnums';
import { listHelpers } from '../lists/lists';
import { addDynamicListOptions } from './dynamicOptions';
import { formOptionsList } from './formOptionsList';
import { convertHelpers } from '../common/convert';

/**
 * 
 * @param {object} props 
 */
export const getFormLists = (props) => {

  const {
    appUrls_home,
    aps_global,
    aps_viewItems,
    currentCollectionPropList,
    currentViPropList,
    data_combined,
    formProps,
    handleOptionsList,
    isDeveloper,
    rssFeeds_home,
    staticViews,
    swipedItem,
    updateProps,
    viewProps,
    formParent_contexts,
  } = props

  const { dataOptions } = aps_global ?? {}
  const { formKey } = updateProps ?? {}

  const _viewProps = _.pickBy(viewProps, _.isPlainObject);

  if (_viewProps && staticViews) {
    _.each(_viewProps, (viewProp, key) => {
      if (staticViews[key]) {
        if (!viewProp.data) { viewProp.data = {} }
        const { data } = viewProp
        const { formItemType, staticList } = data ?? {}
        if (formItemType !== gEnums.formItemTypes.dropdown && formItemType !== gEnums.formItemTypes.component) {
          // LOOK
          switch (formKey) {
            case 'sportPermissions':
              // do not convert
              break;
            default:
              viewProp.data.formItemType = gEnums.formItemTypes.select
              viewProp.data.dataListType = gEnums.dataListTypes.staticViews
              viewProp.data.staticList = staticList ? staticList : key
          }
        }
      }
    })
  }


  // returns _viewProps where the data.listType has a value  
  const currentListProps = _.filter(_viewProps, function (vp) {
    const { key, data } = vp ?? {}
    const { listType, formItemType } = data ?? {}
    const keyz = key + 's'
    if (listType && listTypes[listType]) {
      return listType
    }
    if (data && listTypes[keyz] && formItemType !== gEnums.formItemTypes.component) {
      // vp.data.formItemType = gEnums.formItemTypes.select
      vp.data.listType = keyz
      return listTypes[keyz]
    }
  })

  const labelPropsList = addLabelProps(_viewProps);
  const _currentListProps = [...currentListProps, ...labelPropsList]

  const ol = formOptionsList({ currentListProps: _currentListProps, aps_global, aps_viewItems, staticViews, data_combined, updateProps, isDeveloper })

  // returns the list of props that have a list type in dynListTypes
  const currentDynListProps = _.filter(_viewProps, function (vp) {
    if (vp.data && vp.data.listType && dynListTypes[vp.data.listType]) {
      return vp.data.listType
    }
  });

  const ols = {}

  if (_viewProps) {
    _.each(_viewProps, (viewProp, key) => {
      const { data } = viewProp
      const { dataListType, staticList, staticPropFilter, staticPropFilterValue } = data ?? {}
      if (dataListType === 'staticViews' && staticList && staticViews[staticList]) {
        // the static view for the staticList

        const sv = staticViews[staticList] // staff 

        if (staticPropFilter && staticPropFilterValue) {
          // the static view for the staticPropFilter
          const svf = staticViews[staticPropFilter] // staffTypes
          if (svf) { // staff
            convertHelpers.createItemKeys(sv)
            const svff = _.findKey(svf, { name: staticPropFilterValue })
            const xxx = _.filter(sv, svItem => _.includes(svItem[staticPropFilter], svff));
            ols[key] = listHelpers.listItemKey(xxx)
          } else {
            ols[key] = listHelpers.listStatic(sv)
          }
        } else {
          ols[key] = listHelpers.listStatic(sv)
        }
      }
      switch (key) {
        case 'active':
          ols[key] = listHelpers.listListO(gEnums.trueFalse)
          break;
        case 'year':
        case 'latestSeason':
          ols[key] = listHelpers.listYears()
          break;
        case 'state':
          ols[key] = listHelpers.listStates()
          break;
        default:
          break;
      }
    })
  }

  const dynProps = {
    currentCollectionPropList,
    currentViPropList,
    data_combined,
    formProps,
    formParent_contexts,
  }

  const ola = addDynamicListOptions({ currentDynListProps, dynProps })

  addAdditionalProps(swipedItem, _viewProps, null, dataOptions).then(res => {
    const _ol = { ...ol, ...ola, ...ols }
    if (appUrls_home && appUrls_home.appUrls) {
      const aul = {}
      appUrls_home.appUrls.forEach(u => { aul[u.appName] = u })
      _ol[dynListTypes.appUrls] = aul ? listHelpers.listListO(aul) : null
    }
    if (rssFeeds_home) { _ol[dynListTypes.rssFeeds] = listHelpers.listRss(rssFeeds_home) }
    handleOptionsList(_ol)
  })
}

