import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import AppUserManagementProvider from '../cnr/contexts/AppUserManagementContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import TopicProvider from '../cnr/contexts/TopicContext';
import { UiItemContext } from '../cnr/contexts/UiItemContext';
import { appUserManagementHandlers, appUserManagementInitialState, appUserManagementReducer, showAppManagementTypes, userMenuItems } from '../cnr/reducers/AppUserManagementReducer';
import { frameworkLeftTypes } from '../cnr/reducers/FrameworkReducer';
import { uniqueKey } from '../common/keys';
import NoData from '../components/alerts/NoData';
import UiSaveButtons from '../components/buttons/UiSaveButtons';
import DropdownOptionsMenu from '../components/forms/elements/DropdownOptionsMenu';
import Popuper from "../components/popups/Popuper";
import { allPageTypes } from '../enums/allPageTypes';
import { clickOriginTypes, gEnums } from '../enums/globalEnums';
import GenericPopupMenuItem from '../genericControls/GenericMenuItem';
import UiHeader from '../pageItem/UiHeader';
import UiSearchFilter from '../pageItem/UiSearchFilter';
import FullPageWrapper from '../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import AppUserList from './AppUserList';
import AppUserManager from './AppUserManager';
import AppUsersPresence from './AppUsersPresence';
import AppUserSubManager from './AppUserSubManager';

/**
 * F
 * @param {*} props - (handleCancel)
 * @returns a component listing all the appUsers
 */
const AppUserManagement = (props) => {

  const { fullPageType, handleCancel, fromNotifications, clickOriginType } = props ?? {}

  let _fromDataViewer;

  switch (clickOriginType) {
    case clickOriginTypes.dataViewer:
    case clickOriginTypes.framework:
      _fromDataViewer = true
    default:
    //nothing
  }

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, settings } = parentContext ?? {}
  const { page_state, eventInfo_state, paps_state } = states
  const { eventInfo_handlers } = handlers
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_appUserSettings, aps_global } = pageSettings ?? {}
  const { appSignIn, dataModifications: dataModifications_global } = aps_global ?? {}
  const { homeSettings } = settings ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { globalAppSettings } = global_home ?? {}
  const { databaseProjectId } = globalAppSettings ?? {}
  const { modification } = dataModifications_global ?? {}
  const { allowAction, accessCollections } = modification ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frameworkRightType, frameworkLeftType } = framework_state ?? {}
  const inFramework = frameworkRightType || frameworkLeftType

  // pageContext  
  const { appUserCollection, appUserCollections, appUserViewTypeProp, allowCurrentUserTracking } = aps_appUserSettings ?? {}
  const { appProfileData, staticViews } = eventInfo_state ?? {}

  const _appUserCollections = appUserCollections ? appUserCollections : []
  if (_appUserCollections.length === 0 && appUserCollection) { _appUserCollections.push(appUserCollection) }

  // uiItemContext
  const uiItemContext = useContext(UiItemContext)
  const { item_state } = uiItemContext ?? {}
  const { vld } = item_state ?? {}

  const initState = appUserManagementInitialState({ vld, appProfileData, appUserCollection, appUserViewTypeProp, pathViews, appSignIn })
  const [appUserManagement_state, dispatch] = useReducer(appUserManagementReducer, initState);
  const appUserManagement_handlers = appUserManagementHandlers(dispatch)
  const { handleChange_appManagementType: hc_amt } = appUserManagement_handlers ?? {}

  const isEvent = pathViews.events
  const isClient = pathViews.clients && !isEvent

  const {
    appManagementType,
    confirmation,
    currentUserCount,
    filteredItems,
    profileType,
    selectedAppUser,
    selectedAppUserKey,
    selectedItems,
    showFooter,
    updating,
  } = appUserManagement_state ?? {}

  const _selectedItems = filteredItems ? filteredItems : selectedItems

  const [showSearch, handleShowSearch] = useState()
  const [showFcmOnly, setShowFcmOnly] = useState()
  const [closePopup] = useState()

  const _updating = updating || !appProfileData || !profileType

  useEffect(() => {
    eventInfo_handlers.handleClear_appProfileData()
    eventInfo_handlers.handleGet_appProfileData()
    if (allowCurrentUserTracking) {
      appUserManagement_handlers.handleGet_currentUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews.events, allowCurrentUserTracking]);

  useEffect(() => {

    let _staticAppUsers;
    let _staticView;

    if (appProfileData) {
      if (pathViews.events) {
        if (appUserCollection && appProfileData[appUserCollection]) {
          _staticAppUsers = appProfileData[appUserCollection]
        }
        if (appUserCollection && staticViews && staticViews[appUserCollection]) {
          _staticView = staticViews[appUserCollection]
        }
      } else if (pathViews.clients) {
        if (appUserCollection && appProfileData.clientProfiles) {
          _staticAppUsers = appProfileData.clientProfiles
        }
      } else {
        _staticAppUsers = appProfileData.adminProfiles
      }
    }
    const _merged = {}
    if (_staticAppUsers) {
      _.each(_staticAppUsers, (_staticAppUser, key) => {
        if (_staticView && _staticView[key]) {
          _merged[key] = { ..._staticAppUser, ..._staticView[key] }
        } else {
          _merged[key] = _staticAppUser
        }
      })
    }
    console.log('_staticAppUsers', _staticAppUsers)
    appUserManagement_handlers.handleSet_staticAppUsers(_merged)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, appProfileData]);

  useEffect(() => {
    console.log('appManagementType', appManagementType)
    switch (appManagementType) {
      case showAppManagementTypes.getClientProfiles:
        appUserManagement_handlers.handleStartUpdate()
        appUserManagement_handlers.handleGet_allClientProfiles()
        handleShowSearch(false)
        break;
      default:
        handleShowSearch(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appManagementType]);

  useEffect(() => {
    if (appProfileData) {
      let _profileType;
      switch (fullPageType) {
        case allPageTypes.appProfilesManager:
          _profileType = gEnums.profileTypes.adminProfiles
          break;
        default:
          if (isEvent) {
            _profileType = appUserCollection
          } else if (isClient) {
            _profileType = gEnums.profileTypes.clientProfiles
          } else {
            _profileType = gEnums.profileTypes.adminProfiles
          }
      }
      appUserManagement_handlers.handleSet_profileType(appProfileData, _profileType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pathViews, appProfileData]);


  useEffect(() => {
    if (allowAction && appUserCollection && accessCollections && accessCollections.includes(appUserCollection)) {
      appUserManagement_handlers.handleSet_allowModifications(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserCollection, accessCollections]);

  const setAppManagementType = () => hc_amt(userMenuItems.updateClientProfiles)

  const handleShowFcmOnly = () => setShowFcmOnly(!showFcmOnly)
  const handleShowAppUserCount = () => hc_amt(showAppManagementTypes.appUserCount)


  const searchHeader = () => <div className={'search-page'}><UiSearchFilter handleSearchChange={appUserManagement_handlers.handleSearchChange} /></div>
  const appUserList = (items) => <AppUserList dataItems={items} showIcons={true} showCount={true} handleCancel={handleCancel} handleClick={appUserManagement_handlers.handleSelect_appUser} />

  const header = () => {
    if (_fromDataViewer) {
      return <div>
        <UiHeader caption={'App Users (' + _.startCase(appUserCollection) + ')'} />
        {searchHeader()}
      </div>
    } else if (showSearch) {
      return searchHeader()
    }
  }

  const selectedContent = () => {
    if (_updating) {
      return <div></div>
    } else {
      return _selectedItems ? appUserList(_selectedItems) : <NoData altCaption={_.startCase(profileType)} />
    }
  }

  const buttons_dd = () => {
    const btns = [];
    btns.push({ caption: _.startCase(userMenuItems.checkAuthorizations), oc: hc_amt, icon: 'setting', opts: userMenuItems.checkAuthorizations })
    btns.push({ caption: _.startCase(userMenuItems.getClientProfiles), oc: hc_amt, icon: 'setting', opts: userMenuItems.getClientProfiles })
    return btns
  }

  const buttons = () => {
    const btns_dd = buttons_dd()
    return <DropdownOptionsMenu menuButtons={btns_dd} caption={'Options'} icon={'unordered list'} color={'blue'} direction={'left'} />
  }

  const gpmi = (item, oc) => <GenericPopupMenuItem key={uniqueKey('aum', 'gmpi', item)} item={item} oc={oc} />

  const popupMenuItems = () => {
    const mis = []
    if (isEvent) {
      mis.push(gpmi(userMenuItems.checkAuthorizations, hc_amt))
      mis.push(gpmi(userMenuItems.getClientProfiles, hc_amt))
      mis.push(gpmi(userMenuItems.sendSignInEmails, hc_amt))
      databaseProjectId === 'card-nudge' && mis.push(gpmi(userMenuItems.sendGCNotifications, hc_amt))
    } else if (isClient) {
      mis.push(gpmi(userMenuItems.checkAuthorizations, hc_amt))
      mis.push(gpmi(userMenuItems.getClientProfiles, hc_amt))
      databaseProjectId === 'card-nudge' && mis.push(gpmi(userMenuItems.sendGCNotifications, hc_amt))
    } else {
      mis.push(gpmi(userMenuItems.add, hc_amt))
    }
    return mis
  }

  const showPopupIcon = () => { return true }

  const topper = () => <Label color='green' style={{ float: 'right' }} onClick={() => { handleShowAppUserCount() }}>
    <Icon name='users' />{currentUserCount}
  </Label>

  const wrapper_full = () => <Wrapper
    topper={currentUserCount > 0 && topper()}
    header={header()}
    content={selectedContent()}
    footer={_fromDataViewer && buttons()}
    wrapperType={wrapperTypes.paddedHeaderAndFooter}
    updating={_updating}
    confirmation={confirmation}
    css={{ container: _fromDataViewer ? 'app-dark-mode' : null }}
  />

  const popuper = () => <Popuper
    menuItems={popupMenuItems()}
    content={wrapper_full()}
    showPopupIcon={showPopupIcon()}
    closePopup={closePopup}
  />

  const popuper_wrapper = () => <Wrapper
    header={inFramework && <UiHeader caption={'App Users'} />}
    content={popuper()}
    footer={<UiSaveButtons save={{ caption: 'Update Client Profiles', oc: setAppManagementType, color: 'blue', icon: 'arrow right' }} />}
    wrapperType={wrapperTypes.paddedFooter}
    updating={updating}
    confirmation={confirmation}
  />

  const content_popup = () => {
    if (showFooter) {
      return popuper_wrapper()
    } else {
      return popuper()
    }
  }

  const fpw_popupper = () => <FullPageWrapper
    content={content_popup()}
    handleCancel={handleCancel}
    fullMode={_fromDataViewer ? true : false}
    topperCaption={'App User Management'}
    topperCaption2={_.startCase(profileType)}
  />

  const content_appUser = () => {
    if (fromNotifications) {
      return profileType ? fpw_popupper() : <div>---</div>
    } else {
      return fpw_popupper()
    }
  }

  const content = () => {
    switch (frameworkLeftType) {
      case frameworkLeftTypes.appUsersPresense:
        return <AppUsersPresence />
      default:
        if (appManagementType) {
          switch (appManagementType) {
            case showAppManagementTypes.getClientProfiles:
              break;
            default:
              return <AppUserSubManager />
          }
        }
        if (selectedAppUserKey) {
          return <AppUserManager
            selectedAppUser={selectedAppUser}
            selectedAppUserKey={selectedAppUserKey}
            handleCancel={appUserManagement_handlers.handleClose_appUser}
            clickOriginType={clickOriginType}
          />
        } else {
          return _fromDataViewer ? wrapper_full() : content_appUser()
        }
    }
  }

  return <AppUserManagementProvider
    appUserManagement_state={appUserManagement_state}
    appUserManagement_handlers={appUserManagement_handlers}
  >
    <TopicProvider>
      {content()}
    </TopicProvider>
  </AppUserManagementProvider>

}

export default AppUserManagement