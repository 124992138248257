import _ from 'lodash'
import { updateSettings } from '../../firestoreData/settings/updateSettings'
import { dataFix } from '../../common/dataFix'
import { allowedItemsMain } from '../../components/layout/gridItems/gridItemsMain'
import { allowedItemsAppAdmin } from '../../components/layout/gridItems/gridItemsAppAdmin'
import { allowedItemsAppUser } from '../../components/layout/gridItems/gridItemsAppUser'
import { allowedItemsAdmin } from '../../components/layout/gridItems/gridItemsAdmin'
import { allowedItemsTickets } from '../../components/layout/gridItems/gridItemsTickets'
import { getAppUserAccess } from '../../auth/appUserAccessPermissions'
import { gEnums } from '../../enums/globalEnums'
import { filterHelpers } from '../../common/filtering'
import { sortObject } from '../../common/sorting'

export const rts = {
  handle_goto: 'handle_goto',
  handleAdd_navigationItem: 'handleAdd_navigationItem',
  handleAdd_navigationItems: 'handleAdd_navigationItems',
  handleAdd_sidebarItems: 'handleAdd_sidebarItems',
  handleAmmend_frameworkRight_uiItem: 'handleAmmend_frameworkRight_uiItem',
  handleAmmend_frameworkRightCurrent: 'handleAmmend_frameworkRightCurrent',
  handleAmmend_homePageCaption: 'handleAmmend_homePageCaption',
  handleAmmend_tempSettings: 'handleAmmend_tempSettings',
  handleChanged_override: 'handleChanged_override',
  handleClear_navigationItems: 'handleClear_navigationItems',
  handleClick_contentItem: 'handleClick_contentItem',
  handleHide_appSidebar: 'handleHide_appSidebar',
  handleInit_framework: 'handleInit_framework',
  handleKill_pageNav: 'handleKill_pageNav',
  handlePropMode: 'handlePropMode',
  handleShow_appBottomSidebar: 'handleShow_appBottomSidebar',
  handleShow_appDashboard: 'handleShow_appDashboard',
  handleShow_appSidebar: 'handleShow_appSidebar',
  handleShow_appSidebarContent: 'handleShow_appSidebarContent',
  handleShow_frameworkLeft: 'handleShow_frameworkLeft',
  handleShow_frameworkRight: 'handleShow_frameworkRight',
  handleShow_frameworkRightData: 'handleShow_frameworkRightData',
  handleShow_fullPage: 'handleShow_fullPage',
  handleShow_pageNav: 'handleShow_pageNav',
  handleShow_settingSoloSidebar: 'handleShow_settingSoloSidebar',
  handleShow_signIn: 'handleShow_signIn',
  handleToggle_leftMenu: 'handleToggle_leftMenu',
  handleToggle_rightMenu: 'handleToggle_rightMenu',
  handleUiItem: 'handleUiItem',
  handleUpdate_propsFromToggled: 'handleUpdate_propsFromToggled',
}

export const _appSidebarContentTypes = {
  appSharing: 'appSharing',
  wifi: 'wifi',
  link: 'link',
  linkFull: 'linkFull',
  notificationCheck: 'notificationCheck',
}

export const frameworkLeftTypes = {
  appProfilesManager: 'appProfilesManager',
  appData: 'appData',
  appUsersManager: 'appUsersManager',
  appUsersPresense: 'appUsersPresense',
  bulkImageUpload: 'bulkImageUpload',
  createNewEvent: 'createNewEvent',
  notificationsManager: 'notificationsManager',
  recreateNewEvent: 'recreateNewEvent',
  settings: 'settings',
}

export const frameworkRightTypes = {
  dataConstraints: 'dataConstraints',
  dataModification: 'dataModification',
  display: 'display',
  filters: 'filters',
  google: 'google',
  pageDataAdd: 'pageDataAdd',
  pageDataEdit: 'pageDataEdit',
  pageLinkData: 'pageLinkData',
  pageMedia: 'pageMedia',
  pageSettings: 'pageSettings',
  pageSettingsFull: 'pageSettingsFull',
  props: 'props',
  settings: 'settings',
  sorting: 'sorting',
}

export const frameworkDashboardTypes = {
  sportsViewer: 'sportsViewer',
}

export const frameworkReducer = (state, action) => {

  const { appSidebarContentType, appUser } = state

  switch (action.type) {

    case rts.handleInit_framework:
      const permissionGroups = getPermissionGroups(action.parentContext, action.pageIcons, state)
      return { ...state, permissionGroups }

    case rts.handle_goto:
      return { ...state }

    case rts.handleClick_contentItem:
      const { item, itemKey, isAppUser } = action
      return { ...state, iconClickProps: { item, itemKey, isAppUser } }

    case rts.handleToggle_leftMenu:
      return { ...state, showLeftMenuCaptions: !state.showLeftMenuCaptions }

    case rts.handleToggle_rightMenu:
      return { ...state, showRightMenuCaptions: !state.showRightMenuCaptions }

    case rts.handleChanged_override:
      return { ...state, overridesChanged: action.overridesChanged }

    case rts.handleUpdate_propsFromToggled:
      const callback = (rs) => {
        console.log('rs', rs)
      }
      const { frInfo: frInfo_state, settingsDocName: settingsDocName_state } = state
      const { pageSettings, view: view_fri, uivi: uivi_fri } = frInfo_state ?? {}
      const { aps_views } = pageSettings ?? {}
      const aps_view = aps_views && view_fri && aps_views[view_fri] ? aps_views[view_fri] : {}

      const { viewItems } = aps_view ?? {}

      const _viewItem = viewItems[uivi_fri]

      updateOverrides(appUser, aps_views, _viewItem, view_fri, settingsDocName_state, callback)
      return { ...state }

    case rts.handleShow_fullPage:
      const _showFullPage = !state.showFullPage
      return { ...state, fullPageType: _showFullPage ? action.fullPageType : null, showFullPage: _showFullPage }

    case rts.handleShow_pageNav:
      return { ...state, modifyActionType: action.dmiKey }

    case rts.handleKill_pageNav:
      return { ...state, modifyActionType: null }

    case rts.handleUiItem:
      const { results } = action
      const { uivi, viewKey, _uiItem } = results ?? {}
      const _uiItems = { ...state.uiItems }
      const _pageItems = { ...state.pageItems }
      if (viewKey) {
        if (!_uiItems[uivi]) { _uiItems[uivi] = {} }
        _uiItems[uivi][viewKey] = _uiItem
      } else {
        _pageItems[uivi] = _uiItem
      }
      return { ...state, uiItems: _uiItems, pageItems: _pageItems }

    case rts.handleShow_appDashboard:
      return { ...state, showAppDashboard: !state.showAppDashboard, selectedDataMode: action.selectedDataMode, selectedCollectionKey: action.collectionKey }

    case rts.handleShow_appSidebar:
      return {
        ...state,
        appSidebarOpen: !state.appSidebarOpen,
        settingsSidebarSoloOpen: false,
        appSidebarContentType: null
      }

    case rts.handleShow_appBottomSidebar:
      const _show = !state.showAppBottomSidebar
      return { ...state, showAppBottomSidebar: _show, bottomSidebarContent: _show ? action.content : null }

    case rts.handleHide_appSidebar:
      return {
        ...state,
        appSidebarHide: !action.hide,
        appSidebarContentType: null
      }

    case rts.handleShow_signIn:
      return {
        ...state,
        appSidebarOpen: false,
        settingsSidebarSoloOpen: false,
        showSignIn: !state.showSignIn,
        appSidebarContentType: null
      }

    case rts.handleShow_settingSoloSidebar:
      return {
        ...state,
        settingsSidebarSoloOpen: !state.settingsSidebarSoloOpen,
        soloPermissionType: action.soloPermissionType,
        appSidebarContentType: null
      }

    case rts.handlePropMode:
      return {
        ...state,
        propMode: !state.propMode,
      }

    case rts.handleShow_appSidebarContent:

      if (appSidebarContentType === action.appSidebarContentType) {
        return { ...state, appSidebarContentType: action.null }
      } else {
        return { ...state, appSidebarContentType: action.appSidebarContentType }
      }

    case rts.handleShow_frameworkLeft:
      const _pageLeftType = state.frameworkLeftType === action.frameworkLeftType ? null : action.frameworkLeftType
      return {
        ...state,
        frameworkLeftType: _pageLeftType
      }

    case rts.handleAmmend_frameworkRight_uiItem:
      const _frInfos = { ...state.frInfos }
      const { frInfo } = action
      const { viewItem, uivi: uivi_fr, settingsDocName, navigationOptions } = frInfo ?? {}
      if (viewItem) {
        const _frKey = uivi_fr ? uivi_fr : view
        _frInfos[_frKey] = frInfo
        const _frInfo = _frInfos[_frKey]
        const { view, uivi, viewItemStatus, currentPageData } = _frInfo ?? {}
        const { isViewItemSubList } = viewItemStatus ?? {}
        const { _itemKey } = currentPageData ?? {}
        _frInfo.captions = {
          add: getAddCaption(view, uivi, _itemKey, isViewItemSubList),
          edit: 'Edit ' + _.startCase(uivi) + ' (' + _itemKey + ')',
        }
        const _frAllows = getFrAllows(_frInfo)
        return { ...state, propsToggled: null, frAllows: _frAllows, frInfo: _frInfo, frInfos: _frInfos, settingsDocName, navigationOptions }
      } else {
        return { ...state, propsToggled: null }
      }

    case rts.handleAmmend_frameworkRightCurrent:
      const _frInfoz = state.frInfos
      const _frInfoS = _frInfoz && _frInfoz[action.viewItemKey] ? _frInfoz[action.viewItemKey] : null
      if (_frInfoS) {
        const { navigationOptions: navigationOptions_s } = _frInfoS
        const _frAllows = getFrAllows(_frInfoS)
        return { ...state, frAllows: _frAllows, frInfo: _frInfoS, swipeItemKey: action.viewItemKey, navigationOptions: navigationOptions_s }
      } else {

        return { ...state, swipeItemKey: action.viewItemKey }
      }

    case rts.handleShow_frameworkRightData:
      return {
        ...state,
        frInfo: action.frInfo,
      }

    case rts.handleShow_frameworkRight:
      const _pageRightType = state.frameworkRightType === action.frameworkRightType ? null : action.frameworkRightType
      const _page = state.frameworkRightType === action.frameworkRightType ? null : action.page
      return {
        ...state,
        frameworkRightType: _pageRightType,
        dataModifyType: action.dataModifyType,
        pageSettingsPage: _page,
        tempSettings: !_pageRightType ? null : state.tempSettings
      }

    case rts.handleAdd_navigationItem:
      const { additionalNavigationItems } = state
      additionalNavigationItems[action.navKey] = action.navigationItem
      return { ...state, additionalNavigationItems: additionalNavigationItems }

    case rts.handleClear_navigationItems:
      return { ...state, additionalNavigationItems: null }

    case rts.handleAdd_navigationItems:
      return { ...state, additionalNavigationItems: action.navItems }

    case rts.handleAdd_navigationItems:
      return { ...state, additionalNavigationItems: action.navItems }

    case rts.handleAdd_sidebarItems:
      return { ...state, additionalMenuItems: action.sidebarItems }

    case rts.handleAmmend_tempSettings:
      return { ...state, tempSettings: action.tempSettings }

    case rts.handleAmmend_homePageCaption:
      return { ...state, homePageCaption: action.homePageCaption }

    default:
      return { ...state }
  }
}

export const frameworkHandlers = (dispatch) => {
  return {
    handle_goto: (item) => { dispatch({ type: rts.handle_goto, item }) },
    handleAdd_navigationItem: (navKey, navigationItem) => { dispatch({ type: rts.handleAdd_navigationItem, navKey, navigationItem }) },
    handleAdd_navigationItems: (navItems) => { dispatch({ type: rts.handleAdd_navigationItems, navItems }) },
    handleAdd_sidebarItems: (sidebarItems) => { dispatch({ type: rts.handleAdd_sidebarItems, sidebarItems }) },
    handleAmmend_frameworkRight_uiItem: (frInfo, origin) => { dispatch({ type: rts.handleAmmend_frameworkRight_uiItem, frInfo, origin }) },
    handleAmmend_frameworkRightCurrent: (viewItemKey, origin) => { dispatch({ type: rts.handleAmmend_frameworkRightCurrent, viewItemKey, origin }) },
    handleAmmend_homePageCaption: (homePageCaption) => { dispatch({ type: rts.handleAmmend_homePageCaption, homePageCaption }) },
    handleAmmend_tempSettings: (tempSettings) => { dispatch({ type: rts.handleAmmend_tempSettings, tempSettings }) },
    handleChanged_override: (overridesChanged) => { dispatch({ type: rts.handleChanged_override, overridesChanged }) },
    handleClear_navigationItems: () => { dispatch({ type: rts.handleClear_navigationItems }) },
    handleClick_contentItem: (item, itemKey, isAppUser) => { dispatch({ type: rts.handleClick_contentItem, item, itemKey, isAppUser }) },
    handleHide_appSidebar: (hide) => { dispatch({ type: rts.handleHide_appSidebar, hide }) },
    handleInit_framework: (parentContext, pageIcons) => { dispatch({ type: rts.handleInit_framework, parentContext, pageIcons }) },
    handleKill_pageNav: () => { dispatch({ type: rts.handleKill_pageNav }) },
    handlePropMode: () => { dispatch({ type: rts.handlePropMode }) },
    handleShow_appBottomSidebar: (content) => { dispatch({ type: rts.handleShow_appBottomSidebar, content }) },
    handleShow_appDashboard: (selectedDataMode, collectionKey) => { dispatch({ type: rts.handleShow_appDashboard, dispatch, selectedDataMode, collectionKey }) },
    handleShow_appSidebar: () => { dispatch({ type: rts.handleShow_appSidebar }) },
    handleShow_appSidebarContent: (appSidebarContentType) => { dispatch({ type: rts.handleShow_appSidebarContent, appSidebarContentType }) },
    handleShow_frameworkLeft: (page, frameworkLeftType) => { dispatch({ type: rts.handleShow_frameworkLeft, dispatch, frameworkLeftType, page }) },
    handleShow_frameworkRight: (page, frameworkRightType, dataModifyType) => { dispatch({ type: rts.handleShow_frameworkRight, dispatch, frameworkRightType, dataModifyType, page }) },
    handleShow_frameworkRightData: (frInfo) => { dispatch({ type: rts.handleShow_frameworkRightData, dispatch, frInfo }) },
    handleShow_fullPage: (fullPageType) => { dispatch({ type: rts.handleShow_fullPage, fullPageType }) },
    handleShow_pageNav: (item, dmiKey, subKey) => { dispatch({ type: rts.handleShow_pageNav, item, dmiKey, subKey }) },
    handleShow_settingSoloSidebar: (soloPermissionType) => { dispatch({ type: rts.handleShow_settingSoloSidebar, soloPermissionType }) },
    handleShow_signIn: () => { dispatch({ type: rts.handleShow_signIn }) },
    handleToggle_leftMenu: () => { dispatch({ type: rts.handleToggle_leftMenu }) },
    handleToggle_rightMenu: () => { dispatch({ type: rts.handleToggle_rightMenu }) },
    handleUiItem: (results) => { dispatch({ type: rts.handleUiItem, results }) },
    handleUpdate_propsFromToggled: () => { dispatch({ type: rts.handleUpdate_propsFromToggled }) },
  }
}

export const frameworkReducerInitialState = (initState) => {
  const { addSettings, mediaDeviceType, isMobileDevice, view, appViewMode, pathViews, appUser } = initState
  return {
    additionalNavigationItems: {},
    addSettings,
    appUser,
    frAllows: {},
    frInfo: {},
    frInfos: {},
    appDimensions: {
      width: 360,
      height: 640
    },
    appSidebarOpen: false,
    appViewMode,
    isMobileDevice,
    mediaDeviceType,
    pageItems: {},
    pathViews,
    settingsSidebarSoloOpen: false,
    standalone: navigator.standalone,
    uiItems: {},
    view,
  }
};

const getFrAllows = (frInfo) => {
  const { view, viewItem, viewKey } = frInfo
  const { dataConstraints, key: key_viewItem } = viewItem ?? {}
  const _viewItemIsView = view === key_viewItem && viewKey
  const { useDataConstraints, useDataMatchConstraints, usePageConstraint, useAppUserPageConstraint, useAppUserDataConstraint } = dataConstraints ?? {}
  const _hasConstraints = (useDataConstraints || useDataMatchConstraints || usePageConstraint || useAppUserPageConstraint || useAppUserDataConstraint)
  const frAllows = {
    add: viewKey ? false : true,
    edit: viewKey && _viewItemIsView,
    linking: _viewItemIsView,
    constraints: _hasConstraints,
    isTab: !_viewItemIsView && viewKey ? true : false,
    sorting: viewKey ? false : true,
    google: viewKey ? false : true,
  }
  return frAllows
}

const updateOverrides = (appUser, aps_views, viewItem, view_fri, settingsDocName_state, callback) => {

  const { props, propSections } = viewItem ?? {}

  _.each(props, (prop) => {
    const { _overrides } = prop ?? {}
    _.each(_overrides, (_override, _overrideKey) => {
      if (!_.isNull(_override)) {
        prop[_overrideKey] = _override
      } else {
        if (prop[_overrideKey]) {
          delete prop[_overrideKey]
        }
      }
    })
  })

  _.each(propSections, (propSection) => {
    const { _overrides } = propSection ?? {}
    _.each(_overrides, (_override, _overrideKey) => {
      if (!_.isNull(_override)) {
        propSection[_overrideKey] = _override
      } else {
        if (propSection[_overrideKey]) {
          delete propSection[_overrideKey]
        }
      }
    })
  })

  const _viewItem = dataFix.cleanObject(viewItem)
  const _dataToUpdate = aps_views[view_fri]
  _dataToUpdate.viewItems[view_fri] = _viewItem
  updateSettings.updateFromSidebar(settingsDocName_state, view_fri, _dataToUpdate, appUser, callback)
}

const getPermissionGroups = (parentContext, pageIcons, framework_state) => {

  const { desktopMode } = framework_state ?? {}

  const _defaultAdminLevels = {
    adminOnly: gEnums.accessLevels.admin.value,
    appAdminOnly: gEnums.accessLevels.appDataAdmin.value,
  }

  const { states, fns, settings } = parentContext ?? {}
  const { homeSettings } = settings
  const { appUser_state, page_state } = states
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel } = appUserAccess ?? {}

  const { appUser_fns } = fns

  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_styles, aps_views } = pageSettings ?? {}
  const { events: aps_events } = aps_views ?? {}

  const { global: global_home } = homeSettings ?? {}
  const { settingsPermissions } = global_home ?? {}
  const { allowHiddenIcons } = settingsPermissions ?? {}
  const defaultSnC = aps_styles ? aps_styles[gEnums.projectStyles.gridIcons] : {}
  let { viewItems: viewItems_page } = aps_events ?? {}

  const { appTicketing } = aps_global ?? {}

  const { ticketingManagers, scanningManagers } = appTicketing ?? {}

  const gcProps = { defaultSnC }

  const getAllowed_main = (items, hidden) => allowedItemsMain({ parentContext, framework_state, gridPageItems: items, defaultSnC, pageIcons, gcProps, hidden })
  const getAllowed_appAdmin = (a) => allowedItemsAppAdmin({ parentContext, pageIcons, defaultSnC, gcProps, allows: a, accessLevel })
  const getAllowed_appUser = (a) => allowedItemsAppUser({ parentContext, pageIcons, defaultSnC, gcProps, allows: a })
  const getAllowed_admin = () => allowedItemsAdmin({ parentContext, pageIcons, defaultSnC, gcProps })
  const getAllowed_tickets = (a) => allowedItemsTickets({ parentContext, pageIcons, allows: a, gcProps })

  const _allows = {
    ao: appUserAccess && (appUserAccess.accessLevel >= _defaultAdminLevels.adminOnly),
    aao: appUserAccess && (appUserAccess.accessLevel >= _defaultAdminLevels.appAdminOnly),
    atm: false,
    ats: false,
    avt: true,
    asr: true,
    aqa: true,
    au: false,
  }

  _allows.atm = _allows.ao || appUser_fns.validateAccess_item(null, null, ticketingManagers)
  _allows.ats = _allows.ao || appUser_fns.validateAccess_item(null, null, scanningManagers)

  let gpis = viewItems_page
  appUser_fns.validateAccess_items(gpis)

  const showProp = desktopMode ? gEnums.showTypes.showDesktop : gEnums.showTypes.show

  gpis = !pageIcons && gpis ? filterHelpers.filterObject(gpis, showProp) : gpis
  gpis = !pageIcons && gpis ? filterHelpers.filterObject(gpis, '_viewPermissionType', [gEnums.viewPermissionTypes.allow, gEnums.viewPermissionTypes.allowToAppUserAdmin]) : gpis
  gpis = !pageIcons && gpis ? sortObject(gpis, 'position') : gpis

  let gpish = !pageIcons && viewItems_page ? filterHelpers.filter(viewItems_page, 'show', true, true) : gpis

  if (gpish) { gpish = _.sortBy(gpish, 'key') }

  const _permissionGroups = {
    page: getAllowed_main(gpis),
    pageHidden: _allows.ao && allowHiddenIcons ? getAllowed_main(gpish, true) : [],
    admin: _allows.ao ? getAllowed_admin(_allows) : [],
    appAdmin: _allows.aao ? getAllowed_appAdmin(_allows) : [],
    appUser: getAllowed_appUser(_allows),
    ticketing: _allows.atm ? getAllowed_tickets(_allows) : [],
    ready: true,
  }

  return _permissionGroups

}

const getAddCaption = (view, uivi, _itemKey, isViewItemSubList) => {
  if (isViewItemSubList || view === 'landing' || (uivi === view)) {
    return 'Add ' + _.startCase(uivi)
  } else {
    return 'Add ' + _.startCase(uivi) + ' to ' + _.startCase(view) + ' (' + _itemKey + ')'
  }
}