import _ from 'lodash';
import React from 'react';
import { formatItem } from "../../../common/dateFormatting";
import { uniqueKey } from '../../../common/keys';
import PendingData from '../../../components/alerts/pendings/PendingData';
import { gEnums } from '../../../enums/globalEnums';
import { currentHelpers } from '../../../redirection/current';

export const getSwiperContentInit = async (state, gscProps, handleSet_swiperContent) => {

  const {
    desktopMode,
    formatting,
    projectOptions,
    groupType,
  } = state

  const { swipeContentType, tabIndex, currentSwipeItems, additionalItems, profileItems, showTabCount, _viewItem, isAppUserPage } = gscProps

  let id;
  let _swiperTabs;
  let _swiperItems;

  const usePositionSort = true

  switch (swipeContentType) {

    case gEnums.swipeContentTypes.normalItems:
      id = 'tabContainerNormal'
      _swiperTabs = swipeTabs_groupItem(currentSwipeItems)
      _swiperItems = pageContentInitState(tabIndex, currentSwipeItems)
      break;

    case gEnums.swipeContentTypes.pageAreaContent:
      id = 'tabContainer'
      _swiperTabs = swipeTabs_pageItems(currentSwipeItems, profileItems, additionalItems, showTabCount, desktopMode, usePositionSort, isAppUserPage)
      _swiperItems = pageContentInitState(tabIndex, currentSwipeItems, additionalItems, profileItems, usePositionSort)
      break;

    case gEnums.swipeContentTypes.groupedItems:
      id = 'tabGroupContainer'
      _swiperTabs = groupedSwipeTabs(currentSwipeItems, formatting, projectOptions, showTabCount)
      _swiperItems = groupInitState(tabIndex, currentSwipeItems, groupType)
      break;

    default:
    //nothing
  }

  id += '-' + _viewItem

  const swiperContent = { tabContainerId: id, swiperTabs: _swiperTabs, swiperItems: _swiperItems }
  handleSet_swiperContent(swiperContent, gscProps)
}

/**
 * Gets the htcProps and then triggers 
 * @param {object} state 
 * @param {object} swiper_handlers 
 * @param {number} index 
 */
export const handleSwipeChange = (state, swiper_handlers, index) => {

  const {
    tabView,
    swiperContent,
    groupDataProps,
    swipeContentType,
    _viewItem,
  } = state

  // note > swiperItems: {content, loaded}
  const { swiperItems, tabContainerId } = swiperContent ?? {}

  let htcProps;
  switch (swipeContentType) {
    case gEnums.swipeContentTypes.normalItems:
      htcProps = { swiper_handlers, uiSwipeItem: swipeItem_pending, swiperItems, tabView, tabContainerId, index, localStorageKey: _viewItem, _viewItem }
      break;
    case gEnums.swipeContentTypes.pageAreaContent:
      htcProps = { swiper_handlers, uiSwipeItem: swipeItem_pending, swiperItems, tabView, tabContainerId, index, localStorageKey: _viewItem, _viewItem }
      break;
    case gEnums.swipeContentTypes.groupedItems:
      htcProps = { swiper_handlers, groupDataProps, uiSwipeItem: swipeItem_pending, swiperItems, tabView, tabContainerId, index, localStorageKey: _viewItem, _viewItem }
      break;
    default:
    //nothing
  }

  if (htcProps) {
    handleTabChange(htcProps)
  } else {
    console.log('swipeContentType is not set')
  }
}

export const pageContentInitState = (initTabIndex, viewItems, additionalItems, profileItems, usePositionSort) => {
  const allItems = {}
  getPageItem(allItems, viewItems, initTabIndex, usePositionSort)
  if (additionalItems && Object.keys(additionalItems).length > 0) { getPageItem(allItems, additionalItems, initTabIndex) }
  if (profileItems && Object.keys(profileItems).length > 0) { getPageItem(allItems, profileItems, initTabIndex) }
  return allItems
}

/** returns a swipeitem for a pageItem
 * @returns loaded
 * @returns content
 */
const swipeItem_pending = (key) => ({ loaded: false, content: <PendingData key={uniqueKey('dp', key)} text={key + ' Data'} /> })
/** returns a swipeitem for a groupItem
 * @returns loaded
 * @returns content (from SemUi)
 */

/**
 * Returns a group of item. Each item contains `content`, `key` and 'loaded'
 * @param {number} initTabIndex 
 * @param {object} groupDataProps 
 * @param {string} groupType 
 * @returns items
 */
export const groupInitState = (initTabIndex, groupDataProps, groupType) => {

  const { groups } = groupDataProps ?? {}
  const items = {}
  const firstGroup = groups ? groups[initTabIndex] : null

  if (groups) {
    groups.forEach((group) => {
      let item = null
      if (firstGroup && group.key === firstGroup.key) {
        item = swipeItem_pending(group.key)
      } else {
        item = swipeItem_pending(group.key)
      }
      items[group.key] = item
    })
  }

  return items

}

const swipeTabs_groupItem = (items, sts) => {
  const lists = []
  Object.keys(items).forEach((key, index) => {
    const item = items[key]
    const { caption, adminOnly, userOnly, visible } = item ?? {}
    if (sts) {
      sts.push({ key, caption: caption, adminOnly, userOnly, visible })
    } else {
      lists.push({ key, caption: caption, adminOnly, userOnly, visible })
    }
  })
  return lists
}

const swipeTabs_pageItems = (allowedViewItems, profileItems, additionalItems, showTabCount, desktopMode, usePositionSort, isAppUserPage) => {
  const swipe_tabs = []
  addSwipeTabs(swipe_tabs, allowedViewItems, showTabCount, desktopMode, usePositionSort, isAppUserPage)
  if (additionalItems && Object.keys(additionalItems).length > 0) { swipeTabs_groupItem(additionalItems, swipe_tabs) }
  if (profileItems && Object.keys(profileItems).length > 0) { swipeTabs_groupItem(profileItems, swipe_tabs) }
  return swipe_tabs
}

/**
 * Returns the tabs for the group
 * @param {object} groupDataProps 
 * @param {object} formatting 
 * @param {boolean} showTabCount 
 * @returns 
 */
const groupedSwipeTabs = (groupDataProps, formatting, projectOptions, showTabCount) => {

  const { timeZone, groupDatesByTimeZone } = projectOptions ?? {}
  const { groups, groupByProp } = groupDataProps ?? {}
  const sts = []

  if (groups) {
    groups.forEach(group => {
      const { key, namedKey, data } = group
      const _key = namedKey ? namedKey : key
      let caption = _key ? _key : group
      if (groupByProp.toLowerCase().indexOf('date') >= 0 && groupByProp.toLowerCase().endsWith('date')) {
        if (formatting && formatting.dateFormat) { caption = formatItem(formatting.dateFormat, caption, groupDatesByTimeZone && timeZone ? timeZone : null) }
      }
      const _dataCount = data ? Object.keys(data).length : 0
      sts.push({ key: key, caption, showTabItemCount: showTabCount, dataCount: _dataCount })
    })
  }

  return sts
}

export const getTabView = (tabContainerId, tabItemPrefix) => {
  return {
    midPoint: 0,
    itemWidths: [],
    itemWidth: [],
    setTab: function (tabCount) {
      const tabElement = document.getElementById(tabContainerId)
      if (tabElement) {
        let viewWidth = tabElement.offsetWidth
        this.midPoint = (viewWidth / 2)
        let tw = 0
        for (var i = 0; i < tabCount; i++) {
          let docElem = tabElement.querySelector('#' + tabItemPrefix + '_' + i);
          if (docElem) {
            let thisWidth = docElem.offsetWidth
            tw += thisWidth
            this.itemWidth.push(thisWidth)
            this.itemWidths.push(tw)
          }
        }
      }
    }
  }
}

/**
 * handles the tab change of menu item
 * @param {object} props (swiper_handlers, uiSwipeItem, swiperItems, tabView, tabContainerId, index, localStorageKey)
 */
const handleTabChange = (props) => {

  // uiSwipeItem is a function!!! 
  const { swiper_handlers, swiperItems, tabContainerId, index, localStorageKey, _viewItem } = props

  // eslint-disable-next-line
  const { handleSet_tabIndex } = swiper_handlers
  const indexKey = Object.keys(swiperItems)[index]

  if (tabContainerId === 'tabGroupContainer-' + _viewItem) {
    localStorageKey && currentHelpers.storageItem_set('tab-container-' + localStorageKey, index)
  } else {
    localStorageKey && currentHelpers.storageItem_set('tab-' + localStorageKey, index)
  }

  if (!swiperItems) {
    handleSet_tabIndex(index)
  } else {
    if (swiperItems[indexKey] && swiperItems[indexKey].loaded) {
      handleSet_tabIndex(index)
    } else {
      handleSet_tabIndex(index)
    }
  }
}

/**
 * 
 * @param {string} keyy 
 * @param {object} content 
 * @param {boolean} adminOnly 
 * @param {boolean} userOnly 
 * @returns an object with the `caption` and `content` for the `swipe item`
 */
export const swipePropNew = (keyy, content, adminOnly, userOnly) => {
  return {
    key: keyy,
    caption: _.startCase(keyy),
    content: content,
    adminOnly,
    userOnly
  }
}

export const setHorizontalScroll = (tabView, tabContainerId, tabIndex) => {
  const tabElement = document.getElementById(tabContainerId)
  if (tabElement) {
    if (tabIndex >= 0) {
      let leftWidths = tabView.itemWidths[tabIndex]
      let leftScroll = leftWidths - (tabView.itemWidth[tabIndex] + (tabView.midPoint - (tabView.itemWidth[tabIndex] / 2)))
      tabElement.scrollLeft = leftScroll
    } else {
      tabElement.scrollLeft = 0
    }
  }
}

/**
 * Adds an `item` to the `allItems` array 
 * @param {array} allItems 
 * @param {object} items 
 * @param {number} initTabIndex  
 */
const getPageItem = (allItems, items, initTabIndex, usePositionSort) => {

  const firstKey = items && Object.values(items)[initTabIndex] ? Object.values(items)[initTabIndex].key : null

  if (usePositionSort) {
    const _items = _.sortBy(items, 'position')
    _.each(_items, (item, index) => {
      const { key } = item
      let _item = null
      if (item && item.content) {
        _item = {
          loaded: true,
          content: item.content
        }
      } else {
        if (key === firstKey) {
          _item = swipeItem_pending(key, items, true, false, index)
        } else {
          _item = swipeItem_pending(key, items, true, true, index)
        }
      }
      allItems[key] = _item
    })
  } else {
    Object.keys(items).forEach((key, index) => {
      const item = items[key]
      let _item = null
      if (item && item.content) {
        _item = {
          loaded: true,
          content: item.content
        }
      } else {
        if (key === firstKey) {
          _item = swipeItem_pending(key, items, true, false, index)
        } else {
          _item = swipeItem_pending(key, items, true, true, index)
        }
      }
      allItems[key] = _item
    })
  }

}

/** Adds the props for the swipe tabs into the swipe_tabs array 
 * @note if the _viewPermissionType === allowToAppUserAdmin, adminOnly will be set to true indicatating the only the user will be allowed to see this
*/
const addSwipeTabs = (swipe_tabs, items, showTabCount, desktopMode, usePositionSort, isAppUserPage) => {

  if (usePositionSort) {
    const _items = _.sortBy(items, 'position')
    _.each(_items, (viewItem, index) => {
      const { key, _viewPermissionType, display, desktopDisplay, adminOnly: adminOnly_vi } = viewItem ?? {}
      switch (_viewPermissionType) {
        case gEnums.viewPermissionTypes.allow:
        case gEnums.viewPermissionTypes.allowToAppUserAdmin:
          const adminOnly = _viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin
          const _adminOnly = adminOnly_vi || adminOnly
          const _display = desktopMode && desktopDisplay ? desktopDisplay : display
          const { appUserCaption, caption, altCaption } = _display ?? {}
          const _caption = isAppUserPage && appUserCaption ? appUserCaption : caption
          swipe_tabs.push({ key, caption: _caption ? _caption : altCaption, showTabItemCount: index === 0 ? false : showTabCount, adminOnly: _adminOnly })
          break;
        default:
        // nothing
      }
    })
  } else {
    Object.keys(items).forEach((key, index) => {
      const viewItem = items[key]
      const { _viewPermissionType, display, desktopDisplay, adminOnly: adminOnly_vi } = viewItem ?? {}
      switch (_viewPermissionType) {
        case gEnums.viewPermissionTypes.allow:
        case gEnums.viewPermissionTypes.allowToAppUserAdmin:
          const adminOnly = _viewPermissionType === gEnums.viewPermissionTypes.allowToAppUserAdmin
          const _adminOnly = adminOnly_vi || adminOnly
          const _display = desktopMode && desktopDisplay ? desktopDisplay : display
          const { appUserCaption, caption, altCaption } = _display ?? {}
          const _caption = isAppUserPage && appUserCaption ? appUserCaption : caption
          swipe_tabs.push({ key, caption: _caption ? _caption : altCaption, showTabItemCount: index === 0 ? false : showTabCount, adminOnly: _adminOnly })
          break;
        default:
        // nothing
      }
    })
  }

} 