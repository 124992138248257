import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { fsfn_sheets } from '../../../../global/functions/fbSheetsFuntions';
import { getMediaResults } from '../../../../global/viewSettings/importing/mediaResults';
import { getFbConfig } from '../../../../project/appConfiguration';
import { _matchSeasonalCollection } from './SportsSeasonReducer';
import { ammendMatchesFromGsOrMedia } from './reducerHelpers/seasonSportsMatchData';
import { ammendRankingsFromGs } from './reducerHelpers/seasonSportsRankingsData';
import { seasonalHelpers, seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';
import { sportsHelpers } from './reducerHelpers/sportsHelpers';

export const matchStatusTypes = {
  allUpdates: 'allUpdates',
  cancelled: 'cancelled',
  dateChanged: 'dateChanged',
  duplicates: 'duplicates',
  duplicated: 'duplicated',
  exhibition: 'exhibition',
  existing: 'existing',
  inversed: 'inversed',
  locationChanged: 'locationChanged',
  new: 'new',
  nonDistrict: 'nonDistrict',
  notFound: 'notFound',
  playoff: 'playoff',
  postponed: 'postponed',
  sameTeams: 'sameTeams',
  scoreChanged: 'scoreChanged',
  scoresChanged: 'scoresChanged',
  scoreNew: 'scoreNew',
  scoresNew: 'scoresNew',
  tournament: 'tournament',
}

export const _matchLsTypes = {
  exhibition: 'exhibition',
  non: 'Non',
  nonDistrict: 'nonDistrict',
  playoffs: 'playoffs',
  tournament: 'tournament',
}

export const _mediaHeaders = {
  nonDistrict: 'nonDistrict',
  playoff: 'playoff',
  tournament: 'tournament',
}

export const sportsDataSearchTypes = {
  media_results: 'mediaResults',
  media_playoffResults: 'mediaPlayoffResults',
  googleSheets_results: 'googleSheetsResults',
  googleSheets_playoffResults: 'googleSheetsPlayoffResults',
}

const rts = {
  handleAmmend_gsMatches: 'handleAmmend_gsMatches',
  handleGet_gsMatches: 'handleGet_gsMatches',
  handleGet_gsMatchesTest: 'handleGet_gsMatchesTest',
  handleGet_gsRankings: 'handleGet_gsRankings',
  handleGet_gsRosters: 'handleGet_gsRosters',
  handleGet_matchesData: 'handleGet_matchesData',
  handleGet_mediaMatches: 'handleGet_mediaMatches',
  handleSet_currentFlatFiltered: 'handleSet_currentFlatFiltered',
  handleSet_matchDataUpdates: 'handleSet_matchDataUpdates',
  handleSet_gsMatches: 'handleSet_gsMatches',
  handleSet_gsRankings: 'handleSet_gsRankings',
  handleSet_gsRosters: 'handleSet_gsRosters',
  handleSet_mediaResults: 'handleSet_mediaResults',
  handleSet_schoolResults: 'handleSet_schoolResults',
  handleUpdate_gsMatchesSport: 'handleUpdate_gsMatchesSport',
  handleUpdate_gsRankings: 'handleUpdate_gsRankings',
  ...grts,
}

/**
 * 
 * @param {object} state 
 * @param {object} action 
 * @returns state
 * @description a sports season consists of multiple collections (teams, matches, rankings, history, scheduleDates, playoffs)
 * 
 */
export const sportsDataReducer = (state, action) => {

  const {
    gameScoringType,
    latestSeason,
    links_score,
    _matchStatusGroups,
    matches_flat,
    matches_info,
    pathViews,
    sportsKey,
    teams,
    sportPermissions,
  } = state

  const { values } = matches_flat ?? {}
  const { season: matches_flat_season, playoffs: matches_flat_playoff } = values ?? {}
  const { matches } = matches_info ?? {}

  const { type, dispatch } = action

  const sportsData_handlers = sportsDataHandlers(dispatch)
  const { handleFunctionResponse, handleSet_gsMatches, handleSet_gsRankings, handleSet_gsRosters, handleAmmend_gsMatches, handleSet_mediaResults, handleSet_schoolResults } = sportsData_handlers

  switch (type) {

    case rts.handleGet_matchesData:
      return { ...state, matches_flat: getFlatMatches(matches_info, action) }

    case rts.handleGet_gsMatches:
      fsfn_sheets.getGoogleSheetRange(action.gsValues ? action.gsValues : links_score, handleSet_gsMatches, null, null, null, state.latestSeason, action.forPlayoffs, action.forMedia)
      return { ...state, forPlayoffs: action.forPlayoffs }

    case rts.handleGet_gsRankings:
      fsfn_sheets.getGoogleSheetRange(action.gsValues ? action.gsValues : links_score, handleSet_gsRankings)
      return { ...state }

    case rts.handleGet_gsRosters:
      fsfn_sheets.getGoogleSheetRange(action.gsValues ? action.gsValues : links_score, handleSet_gsRosters)
      return { ...state }

    case rts.handleSet_gsRosters:
      const { rosters_gs } = action
      console.log('rosters_gs', rosters_gs)
      return { ...state, updating: false, rosters_info: {} }

    case rts.handleSet_gsMatches:
      console.log('action', action)
      ammendMatchesFromGsOrMedia(action.matches_gs, matches_flat, matches_info, teams, gameScoringType, handleAmmend_gsMatches, action.forPlayoffs)
      return { ...state, sportsDataSearchType: action.forPlayoffs ? sportsDataSearchTypes.googleSheets_playoffResults : sportsDataSearchTypes.googleSheets_results }

    case rts.handleSet_gsRankings:
      const { rankings_gs } = action
      const _rankings = ammendRankingsFromGs(rankings_gs)
      console.log('_rankings', _rankings)
      return { ...state, updating: false, rankings_info: { rankings: _rankings } }

    case rts.handleGet_mediaMatches:
      getMediaResults(action.dateGroupKeys, action.forPlayoffs, state.links_score, handleSet_mediaResults)
      return { ...state, sportsDataSearchType: action.forPlayoffs ? sportsDataSearchTypes.media_playoffResults : sportsDataSearchTypes.media_results }

    case rts.handleSet_mediaResults:
      ammendMatchesFromGsOrMedia(action.mediaMatches, matches_flat, matches_info, teams, gameScoringType, handleAmmend_gsMatches, action.forPlayoffs)
      return { ...state }

    case rts.handleAmmend_gsMatches:
      console.log('handleAmmend_gsMatches state', state)
      console.log('action', action)
      return {
        ...state,
        updating: false,
        gsMatchedRefreshed: true,
        forPlayoffs: action.forPlayoffs,
        mediaMatches: action.matchesInfo,
        ...action.matchesInfo,
      }

    case rts.handleSet_schoolResults:
      return { ...state, schoolData: action.schoolData }

    case rts.handleGet_gsMatchesTest:
      getGsTest('17wW4AqQOkVkHTAQ9ttl__AIQQhHadfVf4OppcAZSkLg', 'Results 2024')
      return { ...state }

    case rts.handleSet_matchDataUpdates:
      return { ...state, _matchDataUpdates: action.matchDataUpdates }

    case rts.handleSet_currentFlatFiltered:
      const _matches_flat = { ...matches_flat }
      const { ft } = action
      let _updateCaption = 'Update Match Results'
      let updateAllowed;
      switch (ft) {
        case matchStatusTypes.allUpdates:
        case matchStatusTypes.cancelled:
        case matchStatusTypes.dateChanged:
        case matchStatusTypes.inversed:
        case matchStatusTypes.new:
        case matchStatusTypes.nonDistrict:
        case matchStatusTypes.postponed:
        case matchStatusTypes.scoreChanged:
        case matchStatusTypes.scoresChanged:
        case matchStatusTypes.scoreNew:
        case matchStatusTypes.scoresNew:
        case matchStatusTypes.tournament:
        case matchStatusTypes.existing:
          updateAllowed = true
          break;
        default:
          // (1652
          updateAllowed = ft ? false : true
      }
      if (ft) {
        const x = _matchStatusGroups ? _matchStatusGroups[ft] : []
        _matches_flat.counts.filtered = x ? Object.keys(x).length : 0
        console.log('xxxxxxxxxxxxx', x)
        _matches_flat.filtered.all = x
        return { ...state, matches_flat: _matches_flat, filterType: ft, updateCaption: _updateCaption, updateAllowed }
      } else {
        if (state.forPlayoffs) {
          _matches_flat.counts.playoffs = matches_flat_playoff ? Object.keys(matches_flat_playoff).length : 0
          _matches_flat.filtered.playoffs = matches_flat_playoff
          return { ...state, matches_flat: _matches_flat, filterType: null, updateCaption: _updateCaption, updateAllowed }
        } else {
          _matches_flat.counts.season = matches_flat_season ? Object.keys(matches_flat_season).length : 0
          _matches_flat.filtered.season = matches_flat_season
          return { ...state, matches_flat: _matches_flat, filterType: null, updateCaption: _updateCaption, updateAllowed }
        }
      }

    case rts.handleUpdate_gsMatchesSport:

      const { _matchDataUpdates, useScoringCollection, useScoringCollectionOnly } = state

      const { filterType, forPlayoffs, matchDataToUpdate } = action

      const _cb = (res) => {
        const response_data = { success: true }
        console.log('response_data', response_data)
        handleFunctionResponse(response_data, dispatch)
      }

      const _updateProps = {
        callback: _cb,
        collectionName: _matchSeasonalCollection,
        forPlayoffs,
        matchDataUpdates: _matchDataUpdates,
        pathViews,
        pks: seasonalHelpers.getPks(pathViews, latestSeason, sportsKey),
        seasonalUpdateType: seasonalUpdateTypes.matchesList,
        useScoringCollection,
        useScoringCollectionOnly,
        sportPermissions,
      }

      if (_matchDataUpdates) {
        if (filterType) {
          switch (filterType) {
            case matchStatusTypes.allUpdates:
              seasonalHelpers.updateAllSeasonalMatchesByLevel(_updateProps)
              break;
            default:
              const _matchDataUpdate = filterType ? _matchDataUpdates[filterType] : {}
              if (_matchDataUpdate && Object.keys(_matchDataUpdate).length > 0) {
                _updateProps.dataToUpdate = _matchDataUpdate
                seasonalHelpers.updateSeasonalMatchesByLevel(_updateProps)
              }
          }
        } else {
          seasonalHelpers.updateAllSeasonalMatchesByLevel(_updateProps)
        }
      }

      return { ...state }

    case rts.handleUpdate_gsRankings:
      const { rankings } = action.rankings_info ?? {}
      const _cbr = (res) => {
        const response_data = { success: true }
        handleFunctionResponse(response_data, dispatch)
      }
      if (rankings) {
        const data_rankings = {}
        Object.keys(rankings).forEach(selectedWeek => {
          const ranking = rankings[selectedWeek]
          Object.keys(ranking).forEach(selectedLevel => {
            data_rankings['rankings' + '.' + selectedWeek + '.' + selectedLevel] = ranking[selectedLevel];
          })
        })
        const _pkss = seasonalHelpers.getPks(pathViews, state.latestSeason, sportsKey, ['teams'])
        const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, _pkss, action.callback, 'rankings', null, data_rankings, null, rankings)
        seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.rankings, _updateProps)
        _cbr()
      }
      return { ...state }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation }, { gsMatchedRefreshed: false })

    default:
      return { ...state }
  }
}

export const sportsDataHandlers = (dispatch) => {
  return {
    handleAmmend_gsMatches: (matchesInfo, forPlayoffs) => { dispatch({ type: rts.handleAmmend_gsMatches, dispatch, matchesInfo, forPlayoffs }) },
    handleGet_gsMatches: (gsValues, forPlayoffs, forMedia) => { dispatch({ type: rts.handleGet_gsMatches, dispatch, gsValues, forPlayoffs, forMedia }) },
    handleGet_gsMatchesTest: (gsValues) => { dispatch({ type: rts.handleGet_gsMatchesTest, dispatch, gsValues }) },
    handleGet_gsRankings: (gsValues) => { dispatch({ type: rts.handleGet_gsRankings, dispatch, gsValues }) },
    handleGet_gsRosters: (gsValues) => { dispatch({ type: rts.handleGet_gsRosters, dispatch, gsValues }) },
    handleGet_matchesData: (matchDate) => { dispatch({ type: rts.handleGet_matchesData, dispatch, matchDate }) },
    handleGet_mediaMatches: (date, dateGroupKeys, forPlayoffs) => { dispatch({ type: rts.handleGet_mediaMatches, dispatch, date, dateGroupKeys, forPlayoffs }) },
    handleSet_matchDataUpdates: (matchDataUpdates) => { dispatch({ type: rts.handleSet_matchDataUpdates, dispatch, matchDataUpdates }) },
    handleSet_currentFlatFiltered: (ft) => { dispatch({ type: rts.handleSet_currentFlatFiltered, dispatch, ft }) },
    handleSet_gsMatches: (matches_gs, team, forPlayoffs) => { dispatch({ type: rts.handleSet_gsMatches, dispatch, matches_gs, team, forPlayoffs }) },
    handleSet_gsRankings: (rankings_gs) => { dispatch({ type: rts.handleSet_gsRankings, dispatch, rankings_gs }) },
    handleSet_gsRosters: (rosters_gs) => { dispatch({ type: rts.handleSet_gsRosters, dispatch, rosters_gs }) },
    handleSet_mediaResults: (mediaMatches, forPlayoffs) => { dispatch({ type: rts.handleSet_mediaResults, dispatch, mediaMatches, forPlayoffs }) },
    handleSet_schoolResults: (schoolData) => { dispatch({ type: rts.handleSet_schoolResults, dispatch, schoolData }) },
    handleUpdate_gsMatchesSport: (filterType, forPlayoffs, matchDataToUpdate) => { dispatch({ type: rts.handleUpdate_gsMatchesSport, dispatch, filterType, forPlayoffs, matchDataToUpdate }) },
    handleUpdate_gsRankings: (rankings_info) => { dispatch({ type: rts.handleUpdate_gsRankings, dispatch, rankings_info }) },
    ...responseHandlers(dispatch),
  }
}

export const sportsDataInitialState = (init_state) => {
  return { ...init_state }
};

const getGsTest = async (spreadsheetId, sheetName) => {

  const callData = {
    spreadsheetId: spreadsheetId,
    sheetName: sheetName,
    pathViews: {
      clients: 'b4C6wHP0A5AgQPTD78ue',
      events: 'pojoSports',
    },
    allowUpdates: false,
    matchStatusType: matchStatusTypes.scoreChanged,
    showLogs: true,
    testOnly: false,
  }

  const config = getFbConfig()
  const firebaseApp = initializeApp(config)
  const functions = getFunctions(firebaseApp);
  // console.log('callData', callData)
  const _rsp = (await httpsCallable(functions, 'ts_updateData')(callData))
  // console.log('_rsp', _rsp)
}

export const sendMatchMessage = async (matchStatus, _holeResult) => {

  const { statusLine } = matchStatus

  const message = {
    token: 'cHU1Pw2ElBIc2eBNRuei7Y:APA91bFSdwHHD9cyd_83GhyEoeAkVbmg7Bac6yzlQJVRxULjpRiZ-4PtIRyWdLbysIA-J8rpcywUheqwVyp2dGa383nv06pyNgRSP-ouLUleiyLRe_g9Tq9Po5xDnbret9GLWDU5TPDk',
    notification: {
      title: "PRC Update",
      body: _holeResult ? _holeResult + '\n' + statusLine : statusLine
    }
  }

  const config = getFbConfig()
  const firebaseApp = initializeApp(config)
  const functions = getFunctions(firebaseApp);
  const callData = { message }
  // console.log('callData', callData)
  const _rsp = (await httpsCallable(functions, 'ts_sendPush_direct')(callData))
  // console.log('_rsp', _rsp)
}

export const sendFcmMessage = async (currentUserId) => {

  const message = {
    token: 'cHU1Pw2ElBIc2eBNRuei7Y:APA91bFSdwHHD9cyd_83GhyEoeAkVbmg7Bac6yzlQJVRxULjpRiZ-4PtIRyWdLbysIA-J8rpcywUheqwVyp2dGa383nv06pyNgRSP-ouLUleiyLRe_g9Tq9Po5xDnbret9GLWDU5TPDk',
    notification: {
      title: "TS Message",
      body: currentUserId
    }
  }

  const config = getFbConfig()
  const firebaseApp = initializeApp(config)
  const functions = getFunctions(firebaseApp);
  const callData = { message }
  // console.log('callData', callData)
  const _rsp = (await httpsCallable(functions, 'ts_sendPush_direct')(callData))
  // console.log('_rsp', _rsp)
}

const getFlatMatches = (matches_info, action) => {

  const matches_flat = {
    actual: {
      season: sportsHelpers.getMatches_flat(matches_info.matches),
      playoffs: sportsHelpers.getMatches_flat(matches_info.playoffMatches),
    },
    values: {
      season: null,
      playoffs: null,
    },
    filtered: {
      all: null,
      season: null,
      playoffs: null,
    },
    counts: {
      season: 0,
      playoffs: 0,
      filtered: 0,
    },
  }

  matches_flat.values.season = _.values(matches_flat.actual.season)
  matches_flat.values.playoffs = _.values(matches_flat.actual.playoffs)

  matches_flat.counts.season = matches_flat.values.season ? matches_flat.values.season.length : 0
  matches_flat.counts.playoffs = matches_flat.values.playoffs ? matches_flat.values.playoffs.length : 0

  return matches_flat
}