import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { credentialingHandlers, credentialingInitialState, credentialingReducer } from '../reducers/CredentialingReducer';
import { ParentContext } from './ParentContext';

/** Global VideoConferences (Icon, Image, PDF) for the Event taken from the database */
export const CredentialingContext = createContext();

/** This will get the homeSettings and clientSettings */
const CredentialingProvider = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { page_state, paps_state } = states
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}
  const { appCredentialing, topMenu } = aps_global ?? {}
  const { allowCredentialing, allowDirectScanValidation, notifyAdmins } = appCredentialing ?? {}
  const { appUserCollection } = aps_appUserSettings ?? {}
  const { mainFixedCaption } = topMenu ?? {}

  // local state
  const init_state = { pathViews, allowCredentialing, allowDirectScanValidation, appUserCollection, notifyAdmins, mainFixedCaption }
  const [credentialing_state, credentialingDispatch] = useReducer(credentialingReducer, credentialingInitialState(init_state));
  const credentialing_handlers = credentialingHandlers(credentialingDispatch, credentialing_state)
  const { checkIns } = credentialing_state ?? {}

  useEffect(
    () => {
      // only get videoConferences in the events collection  
      credentialing_handlers.handleGet_checkIns()
    },
    // eslint-disable-next-line
    [] // does not re-rednder unless uivi has changed
  )

  const providerContext = () => {
    return <CredentialingContext.Provider value={{ credentialing_state, credentialing_handlers }}>
      {props.children}
    </CredentialingContext.Provider>
  }

  if (checkIns) { return providerContext() }

  return <div></div>

}

export default CredentialingProvider