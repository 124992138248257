import { getAppConfigSettings } from '../../../project/appConfiguration';
import { currentHelpers } from '../../redirection/current';
import { grts, responseHandlers } from './reducerHelpers/dispatchProps';

const _showConsoleLog = false
const _sw_normal = 'service-worker.js';

export const _serviceWorkerMessageTypes = {
  skipWaiting: 'SKIP_WAITING',
  checkForUpdate: 'CHECK_FOR_UPDATE',
  newVersionAvailable: 'NEW_VERSION_AVAILABLE',
  newVersionNumber: 'NEW_VERSION_NUMBER',
  ammendAppSettings: 'AMMEND_APP_SETTINGS',
}

const rts = {
  handleAlert_newServiceWorker: 'handleAlert_newServiceWorker',
  handleAmmend_currentAppVersion: 'handleAmmend_currentAppVersion',
  handleAmmend_serviceWorkerMessage: 'handleAmmend_serviceWorkerMessage',
  handleCheckForUpdate: 'handleCheckForUpdate',
  handleInit_serviceWorker: 'handleInit_serviceWorker',
  handleSend_messageToServiceWorker: 'handleSend_messageToServiceWorker',
  handleSet_deferredPrompt: 'handleSet_deferredPrompt',
  handleSet_registration: 'handleSet_registration',
  handleSet_registrationSuccess: 'handleSet_registrationSuccess',
  ...grts
}

export const serviceWorkerReducer = (state, action) => {

  const { type, dispatch } = action
  const { registration } = state

  const { handleSet_registration, handleSet_registrationSuccess, handleAlert_newServiceWorker, handleSet_deferredPrompt, handleAmmend_serviceWorkerMessage } = serviceWorkerHandlers(dispatch)

  switch (type) {

    case rts.handleInit_serviceWorker:
      registerServiceWorker(handleSet_registration, handleSet_registrationSuccess)
      return { ...state }

    case rts.handleAmmend_currentAppVersion:
      return { ...state, currentAppVersion: action.currentAppVersion }

    case rts.handleSet_registration:
      _showConsoleLog && console.log('handleSet_registration', action.reg)
      addListener_updateFound(action.reg, handleAlert_newServiceWorker)
      addListner_beforeInstallPrompt(handleSet_deferredPrompt)
      addListener_navMessage(handleAmmend_serviceWorkerMessage)
      return { ...state, registration: action.reg, registrationSuccess: true };

    // triggered from  
    case rts.handleSet_registrationSuccess:
      _showConsoleLog && console.error('Service Worker registration failed:', action.error);
      return { ...state, registrationSuccess: action.success, registrationError: action.error };

    case rts.handleSet_deferredPrompt:
      _showConsoleLog && console.log('handleSet_deferredPrompt', action.deferredPrompt)
      return { ...state, deferredPrompt: action.deferredPrompt };

    case rts.handleAmmend_serviceWorkerMessage:
      const lastSeen = currentHelpers.storageItem_get('hasSeenUpdatePromptRecently')
      const currentTime = new Date().getTime();
      // let allowPrompt;
      // if (!lastSeen || currentTime - parseInt(lastSeen) > 24 * 60 * 60 * 1000) {
      //   allowPrompt = true
      // }
      const { message } = action
      const { currentVersion, newVersion } = message ?? {}
      _showConsoleLog && console.log('currentVersion, newVersion', currentVersion, newVersion)
      if (lastSeen && newVersion && newVersion !== currentVersion) {
        return { ...state, newVersionAvailable: action.newVersionAvailable, newVersionNumber: newVersion }
      } else {
        return { ...state }
      }

    case rts.handleCheckForUpdate:
      if (registration) {
        registration.update();
      }
      return { ...state };

    case rts.handleAlert_newServiceWorker:
      _showConsoleLog && console.log('handleAlert_newServiceWorker', action)
      return { ...state };

    case rts.handleSend_messageToServiceWorker:
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        console.log('message', action.message)
        console.log('handleSend_messageToServiceWorker', 'controller is available')
        navigator.serviceWorker.controller.postMessage({
          type: _serviceWorkerMessageTypes.ammendAppSettings,
          data: action.message,
        });
      } else {
        console.log('handleSend_messageToServiceWorker', 'controller is NOT available')
      }
      return { ...state }

    default:
      return { ...state };
  }
}

export const serviceWorkerHandlers = (dispatch) => {

  return {
    handleAlert_newServiceWorker: (response) => { dispatch({ type: rts.handleAlert_newServiceWorker, dispatch, response }) },
    handleAmmend_serviceWorkerMessage: (message) => { dispatch({ type: rts.handleAmmend_serviceWorkerMessage, dispatch, message }) },
    handleCheckForUpdate: () => { dispatch({ type: rts.handleCheckForUpdate, dispatch }) },
    handleInit_serviceWorker: () => { dispatch({ type: rts.handleInit_serviceWorker, dispatch }) },
    handleSend_messageToServiceWorker: (message) => { dispatch({ type: rts.handleSend_messageToServiceWorker, dispatch, message }) },
    handleSet_currentAppVersion: (currentAppVersion) => { dispatch({ type: rts.handleAmmend_currentAppVersion, dispatch, currentAppVersion }) },
    handleSet_deferredPrompt: (deferredPrompt) => { dispatch({ type: rts.handleSet_deferredPrompt, dispatch, deferredPrompt }) },
    handleSet_registration: (reg) => { dispatch({ type: rts.handleSet_registration, dispatch, reg }) },
    handleSet_registrationSuccess: (success, error) => { dispatch({ type: rts.handleSet_registrationSuccess, dispatch, success, error }) },
    ...responseHandlers(dispatch)
  }
}

export const serviceWorkerInitialState = (initState) => {
  return { ...initState }
};

const registerServiceWorker = async (handleSet_registration, handleSet_registrationSuccess) => {
  const sw_normal_url = `${process.env.PUBLIC_URL}/${_sw_normal}`;
  try {
    const reg = await navigator.serviceWorker.register(sw_normal_url);
    handleSet_registration(reg);
  } catch (error) {
    handleSet_registrationSuccess(false, error);
  }
}

const addListener_updateFound = (registration, callback) => {
  registration.addEventListener('updatefound', () => {
    const newWorker = registration.installing;
    newWorker.addEventListener('statechange', () => {
      if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
        callback('New service worker installed, waiting to be activated')
      }
    });
  });
}

const addListner_beforeInstallPrompt = (callback) => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    callback(e)
  });
}

/**
 * Sets a `message` listener. If a message is sent from the service-worker.js file, it will be picked up here.
 * @param {object} callback 
 */
const addListener_navMessage = (callback) => {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data) {
      switch (event.data.type) {
        case _serviceWorkerMessageTypes.newVersionAvailable:
          callback(event.data)
          break;
        case _serviceWorkerMessageTypes.newVersionNumber:
          console.log('addListener_navMessage', event.data)
          callback(event.data)
          break;
        default:
          callback(true)
          break;
      }
    }
  });
} 