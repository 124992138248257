import _ from 'lodash';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { ammendUiValue } from '../../common/convert';

const size = 'large'

const advDiv = (additionalValues) => {
  return _.map(additionalValues, (value, index) => (
    <div>{value}</div>
  ))
}

export const numberLabel = (index, text, additionalValues) => {
  if (additionalValues && additionalValues.length > 0) {
    const x = <div>
      <div>{text}</div>
      <div>{advDiv(additionalValues)}</div>
    </div>
    return <Label className='labels-sorted'><Label color={'blue'} >{index}</Label>{x}</Label>
  } else {
    return <Label><Label color={'blue'} >{index}</Label>{text}</Label>
  }
}

export const extraLabel = (index, text, item, additionalProps) => {
  const lbls = []
  lbls.push(<div>{text}</div>)
  additionalProps.forEach(ap => {
    if (item[ap]) {
      lbls.push(<div><Label color={'blue'} size='mini'>{item[ap]}</Label></div>)
    }
  })
  return <Label className='grid-row' > {lbls}</Label>
}

export const labels = (lbProps) => {

  const { page_fns, rootPaths, navigate, itemKey, items, viewKey, sortGroupName, handleDrill, numbered, numberLimit, ignoreStatic, staticList, additionalProps, uniqueProps } = lbProps

  const _additionalProps = additionalProps ? additionalProps : ['startDate']

  const itemz = sortGroupName ? _.sortBy(items, 'position') : items

  const handleClick = (e, item) => {
    e.stopPropagation()
    handleDrill && handleDrill(e, item)
    if (navigate && rootPaths && rootPaths.events) {
      page_fns.pushSimple({ key: itemKey }, item.key)
    }
  }

  const lbs = (itemz) => {
    const lbs = []
    if (itemz) {
      Object.keys(itemz).forEach((key, index) => {
        const item = itemz[key]

        const { name, firstName, lastName } = item ?? {}
        const trueName = lastName ? firstName + ' ' + lastName : name

        let caption = trueName ? trueName : key

        caption = ammendUiValue(caption, key)

        if (!ignoreStatic && staticList && caption && staticList[caption]) {
          const svi = staticList[caption]
          const { name, lastName, firstName } = svi ?? {}
          if (lastName && firstName) {
            caption = firstName + ' ' + lastName
          } else if (name) {
            caption = name
          }
        }

        let additionalValues = [];

        _.each(uniqueProps, (up, keyOrIndex) => {
          switch (up) {
            case 'name':
            case 'lastName':
            case 'firstName':
              break;
            default:
              if (item[up]) {
                additionalValues.push(item[up])
              }
          }
        })

        if (numbered) {
          if (!numberLimit) {
            lbs.push(<div className={'drag-number'}>{numberLabel(index + 1, caption, additionalValues)}</div>)
          } else {
            if (index + 1 <= numberLimit) {
              lbs.push(<div className={'drag-number'}>{numberLabel(index + 1, caption, additionalValues)}</div>)
            } else {
              lbs.push(<div className={'drag-number'}>{numberLabel('--', caption, additionalValues)}</div>)
            }
          }
        } else {
          if (_additionalProps) {
            if (viewKey === key) {
              lbs.push(extraLabel(index + 1, caption, item, _additionalProps))
            } else {
              lbs.push(extraLabel(index + 1, caption, item, _additionalProps))
            }
          } else {
            if (viewKey === key) {
              lbs.push(<Label size={size} className='selected' key={key}>{caption}</Label>)
            } else {
              lbs.push(<Label size={size} key={key} onClick={(e) => { handleClick(e, item) }}>{caption}</Label>)
            }
          }

        }
      })
    }
    return lbs
  }

  return lbs(itemz)

}
