import { initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { fbAppConfigs } from "../../../project/appConfigs";
import { envTypes } from "../../../project/appConfiguration";
import { sportsDbCollections } from "../../../projectSpecific/sports/cnr/reducers/SportsSeasonReducer";
import { removeFromObject } from "../../common/sorting";
import { copyObj } from "../../common_web/copy";
import { allDataModes } from "../../components/viewers/AppDashboard";
import { gEnums } from "../../enums/globalEnums";
import { createRefPath, createRefPath_client } from "../../firestoreData/appData/appRefPaths";
import { fs_get_data } from "../../firestoreData/appData/fsAppData";
import { fs_set_doc } from "../../firestoreData/appData/fsData";
import { fsfn_storage } from "../../functions/fbStorageFunctions";
import { fsfn_transfer } from "../../functions/fbTransfer";
import { currentHelpers } from "../../redirection/current";
import { _settingsFs } from "../../viewSettings/actions/getSettings";
import { convertSnapshot } from "../contexts/contextHelpers";
import { _appFormsCollectionName, _appSettingsCollectionName } from "./BaseSettingsReducer";
import { grts, responseHandlers, responseReducers } from "./reducerHelpers/dispatchProps";
import { startTexts } from './StartReducer';

const _allowTransfer = {
  event: true,
  settings: true,
  collections: true
}

export const transferDirectionTypes = {
  export: 'export',
  import: 'import',
}

export const rts = {
  handleAltUser: 'handleAltUser',
  handleCallback_dataTransfer: 'handleCallback_dataTransfer',
  handleCallback_storageTransfer: 'handleCallback_storageTransfer',
  handleCommit_dataTransfer: 'handleCommit_dataTransfer',
  handleCommit_dataTransferDirect: 'handleCommit_dataTransferDirect',
  handleCommit_fileStorageTransfer: 'handleCommit_fileStorageTransfer',
  handleKillConfirmation: 'handleKillConfirmation',
  handleSelect_direction: 'handleSelect_direction',
  handleSelect_clearCollections: 'handleSelect_clearCollections',
  handleSelect_setting: 'handleSelect_setting',
  handleSet_firestores: 'handleSet_firestores',
  handleSet_settings: 'handleSet_settings',
  handleShowConfirmation: 'handleShowConfirmation',
  handleUpdateFormData: 'handleUpdateFormData',
  handleSelect_appTransferMode: 'handleSelect_appTransferMode',
  ...grts
}

export const transferReducerInitialState = (initState) => {
  return { ...initState }
};

const test = async () => {
  const fs = getFirestore()
  const refPath = 'clients/tIF9yAQFMR80Pyax38bk/events/1KmNqnrTjT1uZpuVYDF4'
  const _doc = doc(fs, refPath)
  const _ddoc = await getDoc(_doc)
  const ssd = convertSnapshot(_ddoc, true)
  return ssd
}

export const transferReducer = (state, action) => {

  const { formData, currentDirection, currentFirestores, fs_alt, fs_current, pvs, pathViews, settingsDocName, clearCollections } = state

  const _formData = formData ? copyObj(formData) : {}
  const { appName, environment, transferTypes, storageTransferTypes } = formData ?? {}
  const _env = environment === envTypes.development ? 'dev' : 'prod'
  const _fsKey = appName && environment ? appName + '_' + environment : null

  let altCollections;

  switch (appName) {
    case 'thumbstat':
      altCollections = sportsDbCollections
      break;
    default:
    //nothing
  }

  let _import = currentDirection === 'import'

  const fss = {
    source: _import ? fs_alt : fs_current,
    destination: _import ? fs_current : fs_alt,
  }

  let _settingsKey;

  if (pvs) {
    if (pvs.events) {
      _settingsKey = pvs.events
    } else if (pvs.clients) {
      _settingsKey = pvs.clients
    } else {
      _settingsKey = settingsDocName
    }
  }

  const pvKeys = pvs && pathViews ? {
    source: {
      clients: _import ? pvs.clients : pathViews.clients,
      events: _import ? pvs.events : pathViews.events,
      settingsKey: settingsDocName,
    },
    destination: {
      clients: _import ? pathViews.clients : pvs.clients,
      events: _import ? pathViews.events : pvs.events,
      settingsKey: _settingsKey ? _settingsKey : settingsDocName
    },
  } : {
    source: {
      settingsKey: settingsDocName,
    },
    destination: {
      settingsKey: _settingsKey ? _settingsKey : settingsDocName,
    },
  }

  const { type, dispatch } = action

  const { handleResponse_commit, handleCallback_storageTransfer } = transferHandlers(dispatch)

  switch (type) {

    case rts.handleSet_firestores:
      if (appName && environment) {
        const _currentFirestores = { ...currentFirestores }
        const _config_alt = fbAppConfigs[appName][_env]
        const _init_alt = initializeApp(_config_alt, _fsKey)
        const _fs_alt = getFirestore(_init_alt)
        const _fs_current = getFirestore()
        _currentFirestores[_fsKey] = _init_alt
        return { ...state, currentFirestores: _currentFirestores, fs_current: _fs_current, fs_alt: _fs_alt, init_alt: _init_alt }
      } else {
        return { ...state, currentConfig: action.currentConfig }
      }

    case rts.handleCommit_dataTransfer:

      const projectIds = getProjectIds(appName, _env)

      console.log('projectIds', projectIds)

      switch (action.appTransferMode) {
        case allDataModes.transferData:
          let transfers = {}
          if (transferTypes.includes(gEnums.dataTransferTypes.all)) {
            transfers = {
              settings: true,
              event: true,
              collectionsData: true,
              others: true,
              documents: true,
            }
          } else {
            transfers = {
              settings: transferTypes && transferTypes.includes(gEnums.dataTransferTypes.settings) ? true : false,
              event: transferTypes && transferTypes.includes(gEnums.dataTransferTypes.event) ? true : false,
              collectionsData: transferTypes && transferTypes.includes(gEnums.dataTransferTypes.collectionsData) ? true : false,
              others: transferTypes && transferTypes.includes(gEnums.dataTransferTypes.others) ? true : false,
              documents: transferTypes && transferTypes.includes(gEnums.dataTransferTypes.documents) ? true : false,
            }
          }
          fsfn_transfer.transferCollectionsData(projectIds, pvKeys, true, transfers, clearCollections, handleResponse_commit)
          return { ...startTexts }

        case allDataModes.transferSettings:
          // goes to settings
          fsfn_transfer.transferSettings(projectIds, pvKeys, handleResponse_commit)
          return { ...state }

        case allDataModes.transferManifests:
          // goes to manifests 
          fsfn_transfer.transferManifests(projectIds, pvKeys, handleResponse_commit)
          return { ...state }

        case allDataModes.transferAppSettings:
          // goes to _appSettings
          fsfn_transfer.transferAppSettings(projectIds, handleResponse_commit)
          return { ...state }

        default:
        // nothing
      }
      break;

    case rts.handleResponse_commit:
      return { ...state, updating: false, showConfirmation: false }

    case rts.handleCommit_dataTransferDirect:
      fsfn_transfer.transferDirect(fss, false, action.paths, action.transferCollections)
      return { ...state, showConfirmation: false }

    case rts.handleCommit_fileStorageTransfer:

      const storageTransfers = {
        clientProfileStorage: storageTransferTypes && storageTransferTypes.includes(gEnums.storageTransferTypes.clientProfileStorage) ? true : false,
        eventFolderToClient: storageTransferTypes && storageTransferTypes.includes(gEnums.storageTransferTypes.eventFolderToClient) ? true : false,
        clientToClient: storageTransferTypes && storageTransferTypes.includes(gEnums.storageTransferTypes.clientToClient) ? true : false,
        clientProfilesToClientProfiles: storageTransferTypes && storageTransferTypes.includes(gEnums.storageTransferTypes.clientProfilesToClientProfiles) ? true : false,
        eventStorage: storageTransferTypes && storageTransferTypes.includes(gEnums.storageTransferTypes.eventStorage) ? true : false,
      }

      fsfn_storage.transferStorage(fss, pvKeys, storageTransfers, handleCallback_storageTransfer, action.viewItemKey, action.fileName, formData)

      return { ...state, showConfirmation: false }

    case rts.handleCallback_dataTransfer:
      return { ...state, showConfirmation: false, dcUpdates: action.done ? action.dcUpdates : action.dcUpdates, updating: action.done ? null : state.updating }

    case rts.handleCallback_storageTransfer:
      return { ...state, showConfirmation: false, dcUpdates: action.done ? action.dcUpdates : action.dcUpdates, updating: action.done ? null : state.updating }

    case rts.handleSet_settings:
      return { ...state, settings: action.settings, settingsKey: action.settingsKey }

    case rts.handleUpdateFormData:
      const { d } = action
      let _pvs = {};
      if (d.urlPath) {
        _pvs = currentHelpers.getPathViews(d.urlPath)
        if (_pvs.clients) { d.clientKey = fixKey(_pvs.clients) }
        if (_pvs.events) {
          d.eventKey = fixKey(_pvs.events)
        } else {
          d.eventKey = fixKey(pathViews.events)
          _pvs.events = pathViews.events
        }
        return { ...state, formData: { ..._formData, ...d }, pvs: _pvs }
      } else {
        if (d.clientKey) { _pvs.clients = d.clientKey }
        if (d.eventKey) { _pvs.events = d.eventKey }
        return { ...state, formData: { ..._formData, ...d }, pvs: _pvs }
      }

    case rts.handleAltUser:
      return { ...state, altUser: action.altUser }

    case rts.handleKillConfirmation:
      return { ...state, showConfirmation: false }

    case rts.handleShowConfirmation:
      return { ...state, showConfirmation: true }

    case rts.handleSelect_setting:
      return { ...state, currentSetting: action.currentSetting }

    case rts.handleSelect_direction:
      return { ...state, currentDirection: action.currentDirection }

    case rts.handleSelect_clearCollections:
      return { ...state, clearCollections: action.clearCollections }

    case rts.handleSelect_appTransferMode:
      return { ...state, appTransferMode: action.appTransferMode }

    case rts.handleStartUpdate:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
    case rts.updateError:
      return responseReducers(state, action, { questionProps: null })

    default:
      return { ...state }
  }
}

export const transferHandlers = (dispatch) => {
  return {
    handleAltUser: (altUser) => { dispatch({ type: rts.handleAltUser, dispatch, altUser }) },
    handleCallback_dataTransfer: (dcUpdates, done) => { dispatch({ type: rts.handleCallback_dataTransfer, dispatch, dcUpdates, done }) },
    handleCallback_storageTransfer: (dcUpdates, done) => { dispatch({ type: rts.handleCallback_storageTransfer, dispatch, dcUpdates, done }) },
    handleCommit_fileStorageTransfer: (viewItemKey, fileName) => { dispatch({ type: rts.handleCommit_fileStorageTransfer, dispatch, viewItemKey, fileName }) },
    handleCommit_dataTransfer: (appTransferMode) => { dispatch({ type: rts.handleCommit_dataTransfer, dispatch, appTransferMode }) },
    handleCommit_dataTransferDirect: (paths, transferCollections) => { dispatch({ type: rts.handleCommit_dataTransferDirect, dispatch, paths, transferCollections }) },
    handleKillConfirmation: () => { dispatch({ type: rts.handleKillConfirmation, dispatch }) },
    handleResponse_commit: (response) => { dispatch({ type: rts.handleResponse_commit, dispatch, response }) },
    handleSelect_clearCollections: (clearCollections) => { dispatch({ type: rts.handleSelect_clearCollections, dispatch, clearCollections }) },
    handleSelect_direction: (currentDirection) => { dispatch({ type: rts.handleSelect_direction, dispatch, currentDirection }) },
    handleSelect_appTransferMode: (appTransferMode) => { dispatch({ type: rts.handleSelect_appTransferMode, dispatch, appTransferMode }) },
    handleSelect_setting: (currentSetting) => { dispatch({ type: rts.handleSelect_setting, dispatch, currentSetting }) },
    handleSet_firestores: (currentConfig) => { dispatch({ type: rts.handleSet_firestores, dispatch, currentConfig }) },
    handleSet_settings: (settings, settingsKey) => { dispatch({ type: rts.handleSet_settings, dispatch, settings, settingsKey }) },
    handleShowConfirmation: (transferType) => { dispatch({ type: rts.handleShowConfirmation, dispatch, transferType }) },
    handleUpdateFormData: (d) => { dispatch({ type: rts.handleUpdateFormData, dispatch, d }) },
    ...responseHandlers(dispatch)
  }
}

const getProjectIds = (appName, _env) => {
  const _config_alt = fbAppConfigs[appName][_env]
  const { projectId } = _config_alt ?? {}
  const fs_current = getFirestore()
  const fss = { source: fs_current }
  const projectIds = {
    source: fs_current._databaseId && fs_current._databaseId.projectId ? fss.source._databaseId.projectId : null,
    destination: projectId
  }
  return projectIds
}

/**
 * 
 * @param {object} state 
 * @param {object} fss  
 */
const transferEvent = (fss, pvKeys, callback) => {

  // callback after the collections are fetched
  const _cb2 = async (_event_source, sourceDataCollections, settings) => {
    const _refPathEvent_destination = createRefPath_client(pvKeys.destination, ['events', pvKeys.destination.events])
    // get the event from the source
    let eventDestination = await fs_get_data({ refPath: _refPathEvent_destination, opts: { returnFirstObject: true } })
    if (_event_source && !eventDestination) {
      removeFromObject(_event_source, ['id', '_itemKey', '_new'])
      if (_allowTransfer.event) {
        // updates the EVENT
        eventDestination = await fs_set_doc(_refPathEvent_destination, _event_source, false, null, null, fss.destination)
      } else {
        console.log('*** Transfer to ' + fss.destination._databaseId.projectId + ' Event')
        console.log('_refPath', _refPathEvent_destination, _event_source)
      }
    }

    // if we have the sourceDataCollections
    if (sourceDataCollections) {
      // transfers the data
      await updateDestinationEventCollectionsPromise(_refPathEvent_destination, fss.destination, sourceDataCollections, pvKeys.destination, callback)
      // not tranfer the settings
      transferSettings(fss, pvKeys, settings, false, true, callback)
    }
  }

  // callback - get event and dataCollections from the source
  const _cb = async (settings) => {
    const { viewItems } = settings
    const _refPathE = createRefPath(['clients', pvKeys.source.clients, 'events', pvKeys.source.events])
    const _event_source = await fs_get_data({ refPath: _refPathE, fs: fss.source, opts: { returnFirstObject: true } })
    const viewItemKeys = Object.keys(viewItems)

    // GET ALL the data collections from the source
    const dataCollections = await getDataCollections(fss.source, pvKeys.source, viewItemKeys)
    // send the response the the callback
    _cb2(_event_source, dataCollections, settings)
  }

  // get the settings from the source
  getSourceSettings(fss.source, pvKeys.source, false, _cb)

}

const transferSettings = (fss, pvKeys, settings, clientSettingsOnly, tranferOtherData, callback) => {

  const _cb = async (settingz) => {
    const _settingsKey = clientSettingsOnly ? pvKeys.destination.clients : pvKeys.destination.events
    await updateDestinationSettingsPromise(settingz, _settingsKey, fss.destination)
    if (tranferOtherData) {
      transferOther(fss, pvKeys, callback)
    } else {
      callback()
    }
  }

  if (settings) {
    _cb(settings)
  } else {
    getSourceSettings(fss.source, pvKeys.source, clientSettingsOnly, _cb)
  }
}

const transferOther = (fss, pvKeys, callback) => {

  const _cb2 = async (sourceDataCollections) => {
    const _refPathEvent_destination = createRefPath_client(pvKeys.destination, ['events', pvKeys.destination.events])
    if (sourceDataCollections) {
      await updateDestinationEventCollectionsPromise(_refPathEvent_destination, fss.destination, sourceDataCollections, pvKeys.destination, callback)
    }
  }
  const _cb = async () => {
    const viewItemKeys = ['_globals', '_notifications', '_zones']
    // const viewItemKeys = ['_notifications']
    const dataCollections = await getDataCollections(fss.source, pvKeys.source, viewItemKeys)
    _cb2(dataCollections)
  }

  _cb()

}

/**
 * Transfers data from one source to another
 * @param {object} fss 
 * @param {object} pvKeys 
 * @param {function} callback 
 */
const transferData = (fss, pvKeys, callback, altCollections) => {

  const callback_data = async (sourceDataCollections) => {
    const _refPathEvent_destination = createRefPath_client(pvKeys.destination, ['events', pvKeys.destination.events])
    if (sourceDataCollections) {
      updateDestinationEventCollectionsPromise(_refPathEvent_destination, fss.destination, sourceDataCollections, pvKeys.destination, callback)
    }
  }

  // callback - get event and dataCollections from the source
  const callback_settings = async (settings) => {
    const { viewItems } = settings
    let viewItemKeys = []
    if (altCollections) {
      if (altCollections.normal) {
        Object.keys(altCollections.normal).forEach(nk => {
          viewItemKeys.push(nk)
        })
      }
      if (altCollections.seasonal) {
        Object.keys(altCollections.seasonal).forEach(sk => {
          viewItemKeys.push('_' + sk)
        })
      }
    } else {
      viewItemKeys = Object.keys(viewItems)
    }

    const dataCollections = await getDataCollections(fss.source, pvKeys.source, viewItemKeys)
    callback_data(dataCollections)
  }

  // get the settings from the source
  getSourceSettings(fss.source, pvKeys.source, false, callback_settings)

}

/**
 * Transfers data from one source to another
 * @param {object} fss 
 * @param {object} pvKeys 
 * @param {function} callback 
 */
const transferAppSettings = (appName, _env, callback) => {

  const _config_alt = fbAppConfigs[appName][_env]
  // const { projectId } = _config_alt ? _config_alt : {}
  // const fs_current = getFirestore()
  const fss = {
    source: getFirestore(),
    destination: getFirestore(_config_alt),
  }

  const callback_data = async (res) => {
    const _appSettings = res[0] ? res[0] : {}
    const _appForms = res[1] ? res[1].appForms : {}
    transferAppSettingsPromise(_appSettings, _appForms, fss).then(res => {
      console.log('res', res)
      callback()
    })
  }

  getAppSettingsPromise(fss).then(res => {
    callback_data(res)
  })

}

/**
 * 
 * @param {object} appSettings 
 * @param {object} appForms 
 * @param {object} fss  
 * @returns a promise after the _appSettings and _appForms have been updated
 */
const transferAppSettingsPromise = async (appSettings, appForms, fss) => {
  const promises = []
  if (appSettings) {
    Object.keys(appSettings).forEach(ask => {
      const appSetting = appSettings[ask]
      const _refPath1 = createRefPath([_appSettingsCollectionName, 'app', 'settings', ask])
      promises.push(fs_set_doc(_refPath1, appSetting, false, null, null, fss.destination))
    })
  }

  if (appForms) {
    const _refPath2 = createRefPath([_appFormsCollectionName, 'appForms'])
    promises.push(fs_set_doc(_refPath2, appForms, false, null, null, fss.destination))
  }
  return Promise.all(promises)
}

// const getSourceAppSettings = (fsSource, callback) => {
//   const _refPath = createRefPath([_appSettingsCollectionName, 'app'])
//   fs_get_data({ refPath: _refPath, fs: fsSource }).then(res_status => {
//     callback(res_status)
//   })
// }

/**
 * 
 * @param {object} fss 
 * @returns a promise with data for the _appSettings and _appForms
 */
const getAppSettingsPromise = async (fss) => {
  const promises = []
  const _refPath1 = createRefPath([_appSettingsCollectionName, 'app', 'settings'])
  promises.push(fs_get_data({ refPath: _refPath1, fs: fss.source }))
  const _refPath2 = createRefPath([_appFormsCollectionName, 'appForms'])
  promises.push(fs_get_data({ refPath: _refPath2, fs: fss.source }))
  return Promise.all(promises)
}

/**
 * Returns (via callback) the settings (global, views,viewItems) for the _settingsKey
 * @param {object} fsSource 
 * @param {object} pvKeys 
 * @param {boolean} clientSettingsOnly 
 * @param {function} callback 
 */
const getSourceSettings = (fsSource, pvKeys, clientSettingsOnly, callback) => {

  const { events: eventKey, clients: clientKey } = pvKeys ?? {}
  const _eventKey = fixKey(eventKey)

  const _settingsKey = clientSettingsOnly ? clientKey : _eventKey

  const _refPath = createRefPath([_settingsFs.root, _settingsKey])

  fs_get_data({ refPath: _refPath, fs: fsSource, opts: { returnFirstObject: true } }).then(res_status => {
    if (res_status) {
      const docRef_source_2 = createRefPath([_settingsFs.collection], _refPath)
      fs_get_data({ refPath: docRef_source_2, fs: fsSource, opts: { ignoreIds: true } }).then(res_gvvi => {
        if (res_gvvi) {
          const { global, views, viewItems } = res_gvvi
          if (global) { removeFromObject(global, ['id', '_itemKey']) }
          if (views) { removeFromObject(views, ['id', '_itemKey']) }
          if (viewItems) { removeFromObject(viewItems, ['id', '_itemKey']) }
          callback({
            status: res_status,
            global,
            views,
            viewItems,
          })
        }
      })
    }
  })
}

/**
 * 
 * @param {object} fs_source 
 * @param {object} pvKeys_source 
 * @param {object} viewItemKeys 
 * @returns data for each of the pvKeys_source
 */
const getDataCollections = async (fs_source, pvKeys_source, viewItemKeys) => {
  const _dcs = {}
  const dcs = await getEventCollectionsPromise(fs_source, pvKeys_source, viewItemKeys)
  if (dcs) {
    viewItemKeys.forEach((vik, index) => {
      if (dcs[index] && Object.keys(dcs[index]).length > 0) {
        _dcs[vik] = dcs[index]
      }
    })
  }
  return _dcs
}

const getEventCollectionsPromise = async (fs_source, pvKeys_source, viewItemKeys) => {
  const { clients: clientKey, events: eventKey } = pvKeys_source ?? {}
  const _clientKey = fixKey(clientKey)
  const _eventKey = fixKey(eventKey)
  const promises = []
  if (viewItemKeys) {
    viewItemKeys.forEach(vik => {
      const _refPath = createRefPath(['clients', _clientKey, 'events', _eventKey, vik])
      promises.push(fs_get_data({ refPath: _refPath, fs: fs_source }))
    })
  }
  return Promise.all(promises)
}

/**
 * LejUlcRAnUAnCASg7DXo
 * @param {string} refPathEvent_destination 
 * @param {object} fs_destination 
 * @param {object} sourceDataCollections 
 * @returns A Promise containing all the fs_set_doc for each item in the collection of collections
 */
const updateDestinationEventCollectionsPromise = async (refPathEvent_destination, fs_destination, sourceDataCollections, pvKeys_destination, callback) => {

  const allowAll = true

  // const promises = []
  const checkDone = (updates) => {
    let done = true
    Object.keys(updates).forEach(key => {
      const update = updates[key]
      if (update.applied && !update.updated) {
        done = false
      }
    })
    return done
  }

  if (sourceDataCollections) {

    const dataCollectionKeys = Object.keys(sourceDataCollections)
    const dcUpdates = {}

    dataCollectionKeys.forEach((k, index) => {
      let _allow;
      if (allowAll) {
        _allow = true
      } else {
        _allow = index === 0 ? true : false
      }
      if (_allow) {
        dcUpdates[k] = { updating: false, updated: false, applied: true }
      }
    })

    // loop the collections in the sourceDataCollections
    Object.keys(sourceDataCollections).forEach((collectionKey, index) => {

      let _allow;
      if (allowAll) {
        _allow = true
      } else {
        _allow = index === 0 ? true : false
      }

      if (_allow) {
        dcUpdates[collectionKey].updating = true
        callback && callback(dcUpdates)

        const dataCollection = sourceDataCollections[collectionKey]

        if (dataCollection) {
          updateCollectionPromise(collectionKey, dataCollection, refPathEvent_destination, fs_destination, pvKeys_destination).then(res => {
            dcUpdates[collectionKey].updating = false
            dcUpdates[collectionKey].updated = true
            callback && callback(dcUpdates, checkDone(dcUpdates))
          }).catch(error => {
            dcUpdates[collectionKey].updating = false
            dcUpdates[collectionKey].updated = false
            dcUpdates[collectionKey].error = error
            callback && callback(dcUpdates, checkDone(dcUpdates))
          })
        } else {
          dcUpdates[collectionKey].updating = false
          dcUpdates[collectionKey].updated = true
          callback && callback(dcUpdates, checkDone(dcUpdates))
        }
      }
    })
  }
}

/**
 * Sets (updates) each item in the dataCollection
 * @param {string} collectionKey 
 * @param {object} dataCollection 
 * @param {string} refPathEvent_destination 
 * @param {object} fs_destination 
 * @returns 
 */
const updateCollectionPromise = async (collectionKey, dataCollection, refPathEvent_destination, fs_destination, pvKeys_destination) => {

  if (_allowTransfer.collections) {
    // await fsfn_deleteEventCollection(pvKeys_destination, collectionKey, 100)
  }

  const collectionsLogs = []
  const promises = []

  Object.keys(dataCollection).forEach(dcKey => {
    const itemData = dataCollection[dcKey]
    const _itemRef = createRefPath([collectionKey, dcKey], refPathEvent_destination)
    removeFromObject(itemData, ['id', '_itemKey', '_new'])
    if (_allowTransfer.collections) {
      collectionsLogs.push({ dcKey, _itemRef, itemData })
      promises.push(fs_set_doc(_itemRef, itemData, null, null, null, fs_destination))
    } else {
      collectionsLogs.push({ dcKey, _itemRef, itemData })
    }
  })

  console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' - ' + collectionKey)
  console.log('collectionsLogs', collectionsLogs)

  return Promise.all(promises)
}

const updateDestinationSettingsPromise = async (settings, settingsKey, fs_destination) => {
  const promises = []
  console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' Settings')
  Object.keys(settings).forEach(sk => {
    const setting = settings[sk]
    switch (sk) {
      case 'status':
        const ref_s = createRefPath([_settingsFs.root, settingsKey])
        if (_allowTransfer.settings) {
          fs_set_doc(ref_s, setting, null, null, null, fs_destination)
        } else {
          console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' - ' + settingsKey)
          console.log('ref_s', ref_s, setting)
        }
        break;
      default:
        const ref_o = createRefPath([_settingsFs.root, settingsKey, _settingsFs.collection, sk])
        if (_allowTransfer.settings) {
          fs_set_doc(ref_o, setting, null, null, null, fs_destination)
        } else {
          console.log('*** Transfer to ' + fs_destination._databaseId.projectId + ' - ' + settingsKey)
          console.log('ref_o', ref_o, setting)
        }
    }
  })
  return Promise.all(promises)
}

const fixKey = (key) => key ? key.replace(/%20/g, ' ') : ''