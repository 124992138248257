import _ from 'lodash';
import React, { useContext } from 'react';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { frameworkRightTypes } from '../cnr/reducers/FrameworkReducer';
import { clickOriginTypes } from '../enums/globalEnums';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';

const AppSettingsSidebarWithProvider = React.lazy(() => import('../viewSettings/AppSettingsSidebar'));
const DataLinkingSidebar = React.lazy(() => import('../viewSettings/data/DataLinkingSidebar'));
const GoogleDashboardWithProvider = React.lazy(() => import('../components/viewers/GoogleDashboard'));
const ItemDataSidebar = React.lazy(() => import('../viewSettings/data/ItemDataSidebar'));
const MediaDataSidebar = React.lazy(() => import('../viewSettings/data/MediaDataSidebar'));
const SettingsFormSidebar = React.lazy(() => import('../viewSettings/data/SettingsFormSidebar'));
const UiDataModifications = React.lazy(() => import('../pageItem/modification/UiDataModifications'));

const PageFrameworkRight = (props) => {

  const { showLeftSettings } = props

  const frameworkContext = useContext(FrameworkContext);
  const { framework_state } = frameworkContext ?? {}
  const { frInfo, _appSettingsOn, frameworkRightType, dataModifyType } = framework_state ?? {}

  console.log('frameworkRightType', frameworkRightType)

  switch (frameworkRightType) {
    case frameworkRightTypes.pageSettingsFull:
      return _appSettingsOn && <AppSettingsSidebarWithProvider showLeftSettings={showLeftSettings} />

    case frameworkRightTypes.pageDataAdd:
    case frameworkRightTypes.pageDataEdit:
    case frameworkRightTypes.pageSettings:
      return <ItemDataSidebar />

    case frameworkRightTypes.pageLinkData:
      return <DataLinkingSidebar />

    case frameworkRightTypes.dataConstraints:
    case frameworkRightTypes.display:
    case frameworkRightTypes.props:
      return <SettingsFormSidebar settingsType={frameworkRightType} />

    case frameworkRightTypes.pageMedia:
      return <MediaDataSidebar />

    case frameworkRightTypes.google:
      return <GoogleDashboardWithProvider />

    case frameworkRightTypes.dataModification:
    case frameworkRightTypes.filters:
    case frameworkRightTypes.sorting:
      let header;
      const { captions } = frInfo ?? {}
      const { add, edit } = captions ?? {}
      switch (dataModifyType) {
        case 'add':
          header = add
          break;
        case 'edit':
          header = edit
          break;
        case 'sorting':
          break;
        default:
          header = _.startCase(dataModifyType)
      }

      const rcContent = frameworkRightType && <UiDataModifications
        clickOriginType={clickOriginTypes.dataViewer}
        dataModificationDisplayType={'direct'}
        modifyActionType={dataModifyType}
        frameworkRightType={frameworkRightType}
      />

      return <Wrapper
        header={header}
        content={rcContent}
        wrapperType={wrapperTypes.paddedHeader}
      ></Wrapper>
      break;
    default:
    // nothing
  }

  return <div>{frameworkRightType}</div>;

}

export default PageFrameworkRight
