import { getDatabase, onValue, ref } from "firebase/database";
import { collection, getFirestore } from "firebase/firestore";
import { convertHelpers, getFirstObject } from '../../common/convert';
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { gEnums } from '../../enums/globalEnums';
import { fs_get_data, _dataOptions } from './fsAppData';
import { createRefPath_event } from './appRefPaths';
import { addIdsToDataItem, addParentKeysToDataItem } from "./ammendData";
import { ammendAllDates } from "../../common/dateFormatting";

/** clients/clientKey/events/eventKey/collectionName */
export const getEventCollectionRef = (pathViews, collectionName) => {
  const fs = getFirestore()
  return collection(fs, createRefPath_event(pathViews, [collectionName]))
}

/** Gets the data from the database and add the data via the handleLoadData callback*/
export const getCollectionData = (dProps, altProps) => {

  const { refProps, view, uivi, isCombinedData, viewKey, timeZone } = dProps

  const {
    appDataDocuments,
    callback,
    currentPageData,
    dataCaptionProps,
    firestore_fns,
    returnFsr,
    appDataSource,
  } = altProps

  const { useAppDataDocuments } = appDataSource ?? {}

  const { viewItem_g, viewItem } = dataCaptionProps
  const { dataConnection } = viewItem_g ?? {}
  const { firebaseType, useGoogleSheetRealtime } = dataConnection ?? {}

  const { dataSource, display } = viewItem ?? {}
  const { useSubDataCollection, subDataCollectionName } = dataSource ?? {}
  const { showTimeZoneDifferences } = display ?? {}
  const { singleDataItem } = refProps

  let { firestoreRef, firestoreRefs, refPath, googleSheetsId, refPaths, whereOpts, dataLimit } = refProps
  let { priors: wheres } = whereOpts ?? {}

  let trueUivi = uivi;

  const trueDbType = useGoogleSheetRealtime ? gEnums.firebaseTypes.realtimeDatabase : firebaseType
  const _dataResultProps = { name: uivi, singleDataItem, dataRef: firestoreRef, dataId: null }

  /**
   * handles the callback from fs_get_data
   * @param {object} result_data 
   */
  const callback_data = (result_data, a, fsr) => {
    if (useSubDataCollection && subDataCollectionName) {
      const count = Object.keys(result_data).length
      handleSubCollection(callback, result_data, _dataResultProps, dataSource, uivi, count)
    } else {
      callback(result_data, _dataResultProps, refProps)
    }
  }

  if (useAppDataDocuments && appDataDocuments && appDataDocuments[trueUivi]) {
    const appDataDocument = appDataDocuments[uivi]
    convertHelpers.createItemKeys(appDataDocument)
    if (viewKey && view === uivi) {
      callback_data({ [viewKey]: appDataDocument[viewKey] })
    } else {
      callback_data(appDataDocument)
    }
  } else {
    switch (uivi) {
      case 'home':
        _dataResultProps.origin = 'home'
        callback({}, _dataResultProps)
        break;

      default:
        switch (trueDbType) {
          // REALTIME FIREBASE
          case gEnums.firebaseTypes.realtimeDatabase:
            const refString = singleDataItem ? googleSheetsId + '/' + refPath + '/' + viewKey : googleSheetsId + '/' + refPath
            const db = getDatabase();
            const _ref = ref(db, refString);
            onValue(_ref, (snapshot) => {
              _dataResultProps.origin = 'realtime'
              _dataResultProps.dataRef = 'ref'
              let resultData = singleDataItem ? { [viewKey]: snapshot.val() } : convertHelpers.createKeyedObject(snapshot.val())
              callback(resultData, _dataResultProps)
            });
            break;

          default:
            // FIRESTORE
            if (firestoreRefs && firestoreRefs.length > 0) {
              const ress = getDatas(firestoreRefs)
              ress.then(x => {
                const d1 = x && x[0] ? convertSnapshot(x[0], singleDataItem, { uivi: trueUivi, isCombinedData }) : {}
                const d2 = x && x[1] ? convertSnapshot(x[1], singleDataItem, { uivi: trueUivi, isCombinedData }) : {}
                const d3 = { ...d1, ...d2 }
                _dataResultProps.origin = 'datas'
                callback(d3, _dataResultProps)
              })
            } else {
              if (1 === 3 && currentPageData && uivi && currentPageData[uivi]) {
                const _cpd = currentPageData[uivi]
                callback({ [uivi]: _cpd }, _dataResultProps)
              } else {

                const fsProps = {
                  refPath: refPaths,
                  wheres,
                  cbProps: _dataResultProps,
                  callback: callback_data,
                  opts: {
                    dataLimit,
                    [_dataOptions.listen]: true,
                    returnFsr,
                    timeZone,
                    showTimeZoneDifferences,
                  }
                }

                console.log('GET DATA', trueUivi)

                if (firestore_fns) {
                  firestore_fns.handle_getData(fsProps)
                } else {
                  fs_get_data(fsProps)
                }
              }
            }
        }
    }
  }


}

const handleSubCollection = (callback, result_data, _dataResultProps, dataSource, uivi, count) => {

  const { subDataFieldName, subDataGroup, addParentKeysToData } = dataSource ?? {}

  if (count > 1) {
    if (addParentKeysToData) { addParentKeys(result_data, uivi, subDataFieldName) }
    const _mergedData = mergeGetData(result_data, uivi, subDataFieldName)
    callback(_mergedData, _dataResultProps)
  } else {
    const { item: firstObj } = getFirstObject(result_data)
    if (firstObj) {
      const { parentKeys } = firstObj
      const firstData = firstObj[subDataFieldName ? subDataFieldName : uivi]
      addIdsToDataItem(firstData)
      if (addParentKeysToData) { addParentKeysToDataItem(parentKeys, firstData) }
      if (subDataGroup && firstObj && firstObj.parentKeys && firstObj.parentKeys[subDataGroup]) {
        Object.keys(firstData).forEach(key => {
          firstData[key][subDataGroup] = firstObj.parentKeys[subDataGroup]
        })
      }
      callback(firstData, _dataResultProps)
    } else {
      callback(uivi)
    }
  }
}

const getDatas = async (firestoreRefs) => {
  const promises = []
  firestoreRefs.forEach(firestoreRef => {
    promises.push(firestoreRef.get())
  })
  return Promise.all(promises)
}

const mergeGetData = (resultData, uivi, subDataFieldName) => {
  const _mergedData = []
  Object.keys(resultData).forEach(rdKey => {
    const rdi = resultData[rdKey]
    const { parentKeys } = rdi
    const { teams } = parentKeys ?? {}
    const rdiData = rdi[subDataFieldName ? subDataFieldName : uivi]
    if (rdiData) {
      Object.keys(rdiData).forEach(keyy => {
        if (teams) { rdiData[keyy].teams = teams }
        rdiData[keyy].key = keyy
        _mergedData.push(rdiData[keyy])
      })
    }
  })
  return _mergedData
}

const addParentKeys = (resultData, uivi, subDataFieldName) => {
  if (resultData) {
    const itemKey = subDataFieldName ? subDataFieldName : uivi
    Object.keys(resultData).forEach(key => {
      const rdi = resultData[key]
      if (rdi.parentKeys) {
        const items = rdi[itemKey]
        if (items) {
          Object.keys(items).forEach(itemKey => {
            const item = items[itemKey]
            Object.keys(rdi.parentKeys).forEach(pk => {
              item[pk] = rdi.parentKeys[pk]
            })
          })
        }
      }
    })
  }
} 