import { deleteField } from 'firebase/firestore';
import _ from 'lodash';
import { removeFromObject } from '../../../../../global/common/sorting';
import { copyObj } from '../../../../../global/common_web/copy';
import { createRefPath_event } from '../../../../../global/firestoreData/appData/appRefPaths';
import { fs_update_doc } from '../../../../../global/firestoreData/appData/fsData';
import { areObjectsEqual } from '../../../../../global/validation/validation';
import { _districtDashboardTypes, _districtDataIssueTypes } from '../SportsDistrictReducer';
import { _districtCollectionItems } from './sportsDistrictData';

const useBackupTeams = false
const useBackupMatches = false
const backupName = '_backup'

const allowUpdates = {
  any: true,
  fixSeasonalTeams: false,
  fixTeams: false,
  fixSeasonalMatches: true,
  fixSeasonalBadMatches: false,
  fixSeasonalBadSectionMatches: true,
  backup: false,
  backupPlayoffs: true,
  split: true,
  seasonal: true,
}

const _removeProps = [
  '_itemKey',
  '_matchKey',
  '_matchLevelKey',
  '_matchSeasonKey',
  '_newMatchLevelKey',
  '_teamSchoolKey',
  '_sportName',
  '_sportsKey',
  '_sportsYears',
  '_teamLevelKey',
  '_teamSeasonKey',
  '_teamSportsKey',
  'key',
]

export const updateFixedData_district = (pathViews, action, districtData) => {

  const { currentItemKey } = action
  const { groupedData } = districtData ?? {}
  const { matches_issues } = groupedData ?? {}
  const {
    modifiedTeams,
    notFoundTeams,
    missingData,
    missingTeamObjects,
    badSectionMatches,
  } = matches_issues ?? {}

  const cb = (results) => {
    console.log('results', results)
  }

  switch (currentItemKey) {

    case _districtDataIssueTypes.badSectionMatches:
      const allowAll = true
      if (badSectionMatches) {
        const grouped_season = _.groupBy(badSectionMatches, '_matchSeasonKey')
        Object.keys(grouped_season).forEach(async (matchSeasonKey, miIndex) => {
          if (miIndex === 0 || allowAll) {
            const matches_season = grouped_season[matchSeasonKey]
            const grouped_level = _.groupBy(matches_season, '_matchLevelKey')
            Object.keys(grouped_level).forEach(async (lkey, lIndex) => {
              if (lIndex === 0 || allowAll) {
                const matches_level = grouped_level[lkey]
                await updateMatchSectionAndLevels(matches_level, pathViews, matchSeasonKey, lkey)
              }
            })
          }
        })
      }
      break;

    case _districtDataIssueTypes.missingTeamObjects:
      if (missingTeamObjects) {
        const grouped_season = _.groupBy(missingTeamObjects, '_matchSeasonKey')
        Object.keys(grouped_season).forEach(async (matchSeasonKey, miIndex) => {
          const matches_season = grouped_season[matchSeasonKey]
          const grouped_level = _.groupBy(matches_season, '_matchLevelKey')
          Object.keys(grouped_level).forEach(async (lkey, lIndex) => {
            const matches_level = grouped_level[lkey]
            await removeLevelMatches(matches_level, pathViews, matchSeasonKey, lkey)
          })
        })
      }
      break;

    case _districtDataIssueTypes.matchTeamsNotFound:

      if (notFoundTeams) {

        const _teams_season = _.groupBy(notFoundTeams, '_teamSeasonKey')

        Object.keys(_teams_season).forEach(teamSeasonKey => {

          const teams = _teams_season[teamSeasonKey]

          const cn = useBackupTeams ? _districtCollectionItems._teams.collectionName + '_backup' : _districtCollectionItems._teams.collectionName
          const _refPath = createRefPath_event(pathViews, [cn, teamSeasonKey])

          const dataToUpdate = {}

          teams.forEach(team => {
            const { name, name_season, teamKey } = team ?? {}
            const _team = {
              ...team,
              name,
              old: {
                name: name_season
              }
            }
            delete _team.teamKey
            delete _team._teamSeasonKey
            delete _team._itemKey
            delete _team.name_season
            dataToUpdate['teams.' + teamKey] = _team
          })

          console.log('_refPath', _refPath, dataToUpdate)
          if (allowUpdates.any && allowUpdates.fixTeams) {
            fs_update_doc(_refPath, dataToUpdate, cb)
          }
        })

      }
      break;

    case _districtDataIssueTypes.modifiedMatchTeams:

      if (modifiedTeams) {

        const mm_seasons = _.groupBy(modifiedTeams, '_matchSeasonKey')

        Object.keys(mm_seasons).forEach(async (matchSeasonKey, miIndex) => {
          const matchMismatch_season = mm_seasons[matchSeasonKey]
          const mm_levels = _.groupBy(matchMismatch_season, 'levels')

          Object.keys(mm_levels).forEach(async (lkey, lIndex) => {
            const levelMatches = mm_levels[lkey]
            switch (lkey) {
              case 'undefined':
              case undefined:
                // console.log('undefined', levelMatches)
                // nothing
                break;
              default:
                await fixLevelMatches(levelMatches, pathViews, matchSeasonKey, lkey)

            }
          })
        })
      }
      break;

    case _districtDataIssueTypes.mismatchedMatchData:
      if (missingData) {
        const mm_seasons_bad = _.groupBy(missingData, '_matchSeasonKey')
        Object.keys(mm_seasons_bad).forEach(async (matchSeasonKey, miIndex) => {
          const matchMismatch_season_bad = mm_seasons_bad[matchSeasonKey]
          const mm_levels_bad = _.groupBy(matchMismatch_season_bad, '_matchLevelKey')
          Object.keys(mm_levels_bad).forEach(async (lkey, lIndex) => {
            const levelMatches_bad = mm_levels_bad[lkey]
            await removeLevelMatches(levelMatches_bad, pathViews, matchSeasonKey, lkey)
          })
        })
      }
      break;

    default:
    // nothing
  }
}


export const backupData_district = (pathViews, action, districtData) => {

  const { currentItemKey, selectedSport, selectedYear, forPlayoff } = action
  const { _teams: teamsSeasonal, _matches: matchesSeasonal, _playoffs } = districtData

  console.log('districtData', forPlayoff, districtData)

  const cb = (results) => {
    console.log('results', results)
  }

  if (forPlayoff) {
    Object.keys(_playoffs).forEach(pk => {
      const playoff = _playoffs[pk]
      const { playoffMatches, parentKeys: parentKeys_playoff } = playoff ?? {}
      Object.keys(matchesSeasonal).forEach(msk => {
        const matchesSeason = matchesSeasonal[msk]
        const { parentKeys: parentKeys_season } = matchesSeason ?? {}
        if (areObjectsEqual(parentKeys_playoff, parentKeys_season)) {
          const cn = useBackupTeams ? _districtCollectionItems._matches.collectionName + '_backup' : _districtCollectionItems.districtCollectionItems._matches.collectionName
          const _refPathP = createRefPath_event(pathViews, [cn, msk, 'matches', 'playoffs'])
          const dataToUpdate = { matches: playoffMatches }
          console.log('_refPathP', _refPathP, dataToUpdate)
          if (allowUpdates.any && allowUpdates.backupPlayoffs) {
            fs_update_doc(_refPathP, dataToUpdate, cb)
          }
        }
      })
    })
  } else {
    switch (currentItemKey) {
      case _districtDashboardTypes.matchTeamsNotFound:
        Object.keys(teamsSeasonal).forEach(tsk => {
          const teamSeasonal = teamsSeasonal[tsk]
          const cn = _districtCollectionItems._teams.collectionName + backupName
          const _refPath = createRefPath_event(pathViews, [cn, tsk])
          console.log('_refPath', _refPath, teamSeasonal)
          if (allowUpdates.any && allowUpdates.backup) {
            fs_update_doc(_refPath, teamSeasonal, cb)
          }
        })
        break;

      case _districtDashboardTypes.matchesBySport:

        Object.keys(matchesSeasonal).forEach(msk => {

          const matchSeasonal = matchesSeasonal[msk]
          const { parentKeys } = matchSeasonal ?? {}
          const { sportsYears } = parentKeys ?? {}

          let allowUpdate;

          if (selectedSport && selectedYear) {
            allowUpdate = (msk === selectedSport) && (sportsYears === selectedYear)
          } else {
            allowUpdate = true
          }

          if (allowUpdate) {
            delete matchSeasonal.id
            delete matchSeasonal._itemKey
            delete matchSeasonal.matches
            const cn = _districtCollectionItems._matches.collectionName + backupName
            const _refPath = createRefPath_event(pathViews, [cn, msk])
            console.log('_refPath', _refPath, matchSeasonal)
            if (allowUpdates.any && allowUpdates.backup) {
              fs_update_doc(_refPath, matchSeasonal, cb)
            }
          }
        })
        break;
      default:
      // nothing
    }
  }
}

export const updateFixedData_teamAndMatches = async (pathViews, districtData) => {

  const { mergeData } = districtData
  const { matches_seasonal, teams_seasonal, teams } = mergeData ?? {}

  if (teams) {
    await updateFixedData_teams(pathViews, teams)
    console.log('DONE: teams')
  }

  if (teams_seasonal) {
    await updateFixedData_seasonalTeams(pathViews, teams_seasonal)
    console.log('DONE: teams_seasonal')
  }

  if (matches_seasonal) {
    await updateFixedData_seasonalMatches(pathViews, matches_seasonal)
    console.log('DONE: matches_seasonal')
  }

}

const updateFixedData_teams = async (pathViews, teams) => {

  console.log('Fix Teams', allowUpdates.any && allowUpdates.fixTeams)

  const cb = (r) => { console.log(r) }

  if (teams) {
    Object.keys(teams).forEach(async tk => {
      const team = teams[tk]
      delete team.id
      delete team.sport
      delete team._itemKey
      const cn = _districtCollectionItems.teams.collectionName
      const _refPath = createRefPath_event(pathViews, [cn, tk])
      console.log('_refPath', _refPath)
      console.log('dataToUpdate', team)

      if (allowUpdates.any && allowUpdates.fixTeams) {
        await fs_update_doc(_refPath, team, cb)
      }
    })
  }
}

const updateFixedData_seasonalTeams = async (pathViews, teams_seasonal) => {

  console.log('Fix Teams Seasonal', allowUpdates.any && allowUpdates.fixSeasonalTeams)

  const cb = (r) => { console.log(r) }

  const _teams_season = _.groupBy(teams_seasonal, '_teamSeasonKey')

  if (_teams_season) {
    Object.keys(_teams_season).forEach(async teamSeasonKey => {
      const seasonTeams = _teams_season[teamSeasonKey]
      const dataToUpdate = {}
      seasonTeams.forEach(team => {
        const _itemKey = team._itemKey
        removeFromObject(team, _removeProps)
        dataToUpdate['teams.' + _itemKey] = { ...team }
      })
      const cn = useBackupTeams ? _districtCollectionItems._teams.collectionName + '_backup' : _districtCollectionItems._teams.collectionName
      const _refPath = createRefPath_event(pathViews, [cn, teamSeasonKey])
      console.log('_refPath', _refPath)
      console.log('dataToUpdate', dataToUpdate)
      if (allowUpdates.any && allowUpdates.fixSeasonalTeams) {
        await fs_update_doc(_refPath, dataToUpdate, cb)
      }
    })
  }
}

const updateFixedData_seasonalMatches = async (pathViews, matches_seasonal) => {

  const cb = (r) => { console.log(r) }

  const _matches_seasonal_c = copyObj(matches_seasonal)
  const _matches_season = _.groupBy(_matches_seasonal_c, '_matchSeasonKey')

  if (_matches_season) {

    console.log('Fix Seasonal Matches', allowUpdates.fixSeasonalMatches)

    Object.keys(_matches_season).forEach(async matchSeasonKey => {
      const seasonMatches = _matches_season[matchSeasonKey]
      const _matches_level = _.groupBy(seasonMatches, '_matchLevelKey')

      if (_matches_level) {

        Object.keys(_matches_level).forEach(async matchLevelKey => {

          const dataToUpdate = {}
          const matches = _matches_level[matchLevelKey]

          if (matches) {
            matches.forEach(match => {
              const { _itemKey } = match
              removeFromObject(match, _removeProps)
              dataToUpdate['matches.' + _itemKey] = { ...match }
            })
          }

          const cn = useBackupMatches ? _districtCollectionItems._matches.collectionName + '_backup' : _districtCollectionItems._matches.collectionName
          const _refPath = createRefPath_event(pathViews, [cn, matchSeasonKey, 'matches', matchLevelKey])
          console.log('_refPath', _refPath)
          console.log('dataToUpdate', dataToUpdate)
          if (allowUpdates.any && allowUpdates.fixSeasonalMatches) {
            await fs_update_doc(_refPath, dataToUpdate, cb)
          }
        })

      }

    })
  }
}

const fixLevelMatches = async (levelMatches, pathViews, matchSeasonKey, lkey) => {

  const dataToUpdate = {}

  const cb = (results) => { console.log('results', results) }

  if (levelMatches) {
    levelMatches.forEach((match, mIndex) => {
      const { away, home, _itemKey, _matchKey } = match
      const matchKey = _matchKey ? _matchKey : _itemKey
      switch (matchKey) {
        case 'undefined':
        case undefined:
          break;
        default:
          removeFromObject(match, _removeProps)
          dataToUpdate['matches.' + matchKey] = { ...match }
          if (away && away.name_season) {
            dataToUpdate['matches.' + matchKey].away.name = away.name
            dataToUpdate['matches.' + matchKey].away.old = { name: away.name_season }
            delete dataToUpdate['matches.' + matchKey].away.name_season
          } else if (home && home.name_season) {
            dataToUpdate['matches.' + matchKey].home.name = home.name
            dataToUpdate['matches.' + matchKey].home.old = { name: home.name_season }
            delete dataToUpdate['matches.' + matchKey].home.name_season
          }
      }
    })

    const cn = useBackupMatches ? _districtCollectionItems._matches.collectionName + '_backup' : _districtCollectionItems._matches.collectionName
    const _refPath = createRefPath_event(pathViews, [cn, matchSeasonKey, 'matches', lkey])
    console.log('_refPath', _refPath, dataToUpdate)
    if (allowUpdates.any && allowUpdates.fixSeasonalMatches) {
      fs_update_doc(_refPath, dataToUpdate, cb)
    }
  }
}

const removeLevelMatches = async (levelMatches, pathViews, matchSeasonKey, lkey) => {

  const dataToUpdate = {}

  const cb = (results) => { console.log('results', results) }

  if (levelMatches) {
    levelMatches.forEach((match, mIndex) => {
      const { _itemKey } = match
      dataToUpdate['matches.' + _itemKey] = deleteField()
    })

    const cn = _districtCollectionItems._matches.collectionName
    const _refPath = createRefPath_event(pathViews, [cn, matchSeasonKey, 'matches', lkey])
    console.log('_refPath', _refPath, dataToUpdate)
    if (allowUpdates.any && allowUpdates.fixSeasonalBadMatches) {
      fs_update_doc(_refPath, dataToUpdate, cb)
    }
  }
}

const updateMatchSectionAndLevels = async (levelMatches, pathViews, matchSeasonKey, lkey) => {

  const dataToRemove = {}
  const dataToAdd = {}

  const cb = (results) => { console.log('results', results) }

  if (levelMatches) {
    levelMatches.forEach(match => {
      const { _itemKey } = match
      removeFromObject(match, _removeProps)
      dataToRemove['matches.' + _itemKey] = deleteField()
      dataToAdd['matches.' + _itemKey] = { ...match }
    })

    const cn = _districtCollectionItems._matches.collectionName
    const _refPathOld = createRefPath_event(pathViews, [cn, matchSeasonKey, 'matches', lkey])
    const _refPathNew = createRefPath_event(pathViews, [cn, matchSeasonKey, 'matches', 'Non'])

    console.log('_refPathNew', _refPathNew, dataToAdd)
    console.log('_refPathOld', _refPathOld, dataToRemove)

    if (allowUpdates.any && allowUpdates.fixSeasonalBadSectionMatches) {
      fs_update_doc(_refPathNew, dataToAdd, cb).then(res => {
        fs_update_doc(_refPathOld, dataToRemove, cb)
      })
    }
  }
}