import { deleteField } from 'firebase/firestore'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Icon, Label, List, Segment } from 'semantic-ui-react'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { convertHelpers } from '../../../../global/common/convert'
import { filteredDates } from '../../../../global/common/filtering'
import { uniqueKey } from '../../../../global/common/keys'
import { sortDateArray } from '../../../../global/common/sorting'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths'
import { fs_update_doc } from '../../../../global/firestoreData/appData/fsData'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext, _resultsProp } from '../../cnr/contexts/SportsSeasonContext'
import { matchStatusTypes } from '../../cnr/reducers/SportsDataReducer'
import { _matchSeasonalCollection } from '../../cnr/reducers/SportsSeasonReducer'
import { seasonalHelpers } from '../../cnr/reducers/reducerHelpers/seasonalSportsData'
import { matchCalcs } from '../../helpers/match'

const _matchUpdateTypes = {
  none: 'none',
  cancelMatch: 'cancelMatch',
  deleteMatch: 'deleteMatch',
  deleteScore: 'deleteScore',
  notifyMatch: 'notifyMatch',
  postponeMatch: 'postponeMatch',
  moveLevel: 'moveLevel',
  toggleHomeAndAway: 'toggleHomeAndAway',
}

const _matchAmmendTypes = {
  none: 'none',
  toggleHomeAndAway: 'toggleHomeAndAway',
}

const _lblSize = 'mini'

const allowUpdate = true

const MatchList = (props) => {

  const { matches: matches_list, handleToggle, handleDateSearch, filterType, allowEdits, showEditDate } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states
  const { pathViews } = paps_state ?? {}

  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { sportsKey, latestSeason, gameScoringType, teams_info } = sportsSeason_state ?? {}

  const [currentMatchList, setCurrentMatchList] = useState()
  const [matchOptions, setMatchOptions] = useState()
  const [dateGroupKeys, setDateGroupKeys] = useState()
  const [dateGroupKeyz, setDateGroupKeyz] = useState()
  const [dateGroups, setDateGroups] = useState()
  const [selectedDateIndex, setSelectedDateIndex] = useState()
  const [selectedDateGroup, setSelectedDateGroup] = useState()
  const [matchActions, setMatchActions] = useState({})

  useEffect(() => {
    matchCalcs.calcAllVballMatches(matches_list, gameScoringType)
    setCurrentMatchList(matches_list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches_list]);

  useEffect(() => {

    if (allowEdits) {
      const _matchUpdateOptions = _.map(_matchUpdateTypes, (lut, index) => ({
        key: lut,
        text: _.startCase(lut),
        value: lut,
      }))
      setMatchOptions(_matchUpdateOptions)
    } else {
      const _matchUpdateOptions = _.map(_matchAmmendTypes, (lut, index) => ({
        key: lut,
        text: _.startCase(lut),
        value: lut,
      }))
      setMatchOptions(_matchUpdateOptions)
    }


    if (currentMatchList) {
      switch (filterType) {
        case matchStatusTypes.duplicated:
          setDateGroups(currentMatchList)
          setDateGroupKeys(Object.keys(currentMatchList))
          break;

        // case matchStatusTypes.allUpdates:
        //   console.log('currentMatchList', currentMatchList)
        //   break;

        default:
          convertHelpers.createItemKeys(currentMatchList)
          const _dateGroups = _.groupBy(currentMatchList, 'startDate')
          const dateGroupKeys = sortDateArray(Object.keys(_dateGroups))
          ammendMatches(_dateGroups)
          setDateGroups(_dateGroups)
          setDateGroupKeys(dateGroupKeys)
          const fd = filteredDates(dateGroupKeys, true)
          setDateGroupKeyz(fd)
          if (allowEdits) { setSelectedDateIndex(0) }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMatchList]);

  const ammendMatches = (dgs) => {
    const { teams } = teams_info ?? {}
    if (dgs) {
      _.each(dgs, (dg) => {
        _.each(dg, (m) => {
          const { away, home } = m ?? {}
          const _away = !_.isObject(away) ? _.find(teams, { name: away }) : away
          const _home = !_.isObject(away) ? _.find(teams, { name: home }) : home
          if (_away) { m.away = _away }
          if (_home) { m.home = _home }
        })
      })
    }
  }

  const handleDateSelectIndex = (i) => {
    if (i === selectedDateIndex) {
      setSelectedDateIndex(null)
      setSelectedDateGroup(null)
    } else {
      setSelectedDateIndex(i)
    }
  }
  const handleDateSelectGroup = (i) => setSelectedDateGroup(i === selectedDateGroup ? null : i)

  const handleActionChange = (e, d) => {

    if (allowEdits) {
      const _matchActions = { ...matchActions }
      _matchActions[d.matchkey] = { action: d.value, levelKey: d.levelkey }
      setMatchActions(_matchActions)
    } else {
      switch (d.value) {
        case _matchAmmendTypes.toggleHomeAndAway:
          handleToggle()
          break;
        default:
        // nothing
      }
    }
  }

  const handleUpdateMatches = async () => {

    const callback = (r) => {
      console.log('r', r)
    }

    let _dataType = 'matches'

    const dataUpdates = {}

    Object.keys(matchActions).forEach(matchKey => {

      const _mk = _dataType + '.' + matchKey

      const matchAction = matchActions[matchKey]
      const { action, levelKey } = matchAction

      if (!dataUpdates[levelKey]) { dataUpdates[levelKey] = {} }

      switch (action) {
        case _matchUpdateTypes.deleteMatch:
          dataUpdates[levelKey][_mk] = deleteField();
          break;

        case _matchUpdateTypes.deleteScore:
          dataUpdates[levelKey][_mk + '.results'] = deleteField();
          break;

        case _matchUpdateTypes.postponeMatch:
          dataUpdates[levelKey][_mk + '.postponed'] = true;
          dataUpdates[levelKey][_mk + '.cancelled'] = deleteField();
          break;

        case _matchUpdateTypes.cancelMatch:
          dataUpdates[levelKey][_mk + '.cancelled'] = true;
          dataUpdates[levelKey][_mk + '.postponed'] = deleteField();
          break;

        default:
        // nothing

      }
    })

    const pks = seasonalHelpers.getPks(pathViews, latestSeason, sportsKey, ['teams'])
    const _collectionName = '_' + _matchSeasonalCollection
    const _sportsYearCollectionItem = await seasonalHelpers.getSports_collectionItem(pathViews, pks, _collectionName, true)

    if (_sportsYearCollectionItem && !_.isEmpty(_sportsYearCollectionItem)) {
      const { _itemKey } = _sportsYearCollectionItem
      if (dataUpdates) {
        Object.keys(dataUpdates).forEach(levelKey => {
          const dataToUpdate = dataUpdates[levelKey]
          const _refPathS = createRefPath_event(pathViews, [_collectionName, _itemKey, 'matches', levelKey])
          allowUpdate && fs_update_doc(_refPathS, dataToUpdate, callback)
        })
      }
    }

  }

  const icons_match = (msts, isPlayoff) => {
    const _icons = []
    let _newMatch = false

    if (msts) {
      msts.forEach(mst => {
        switch (mst) {
          case matchStatusTypes.dateChanged:
            _icons.push({ name: 'calendar alternate outline', color: 'purple', title: _.startCase(mst) })
            break;
          case matchStatusTypes.existing:
            _icons.push({ name: 'check circle outline', color: 'blue', title: _.startCase(mst) })
            break;
          case matchStatusTypes.duplicated:
            _icons.push({ name: 'copy', color: 'teal', title: _.startCase(mst) })
            break;
          case matchStatusTypes.new:
            _icons.push({ name: 'add', color: 'green', title: _.startCase(mst) })
            _newMatch = true
            break;
          case matchStatusTypes.nonDistrict:
            _icons.push({ name: 'ban', color: 'orange', title: _.startCase(mst) })
            break;
          case matchStatusTypes.scoreChanged:
            _icons.push({ name: 'star outline', color: 'blue', title: _.startCase(mst) })
            break;
          case matchStatusTypes.scoreNew:
          case matchStatusTypes.scoresNew:
            _icons.push({ name: 'star', color: 'green', title: _.startCase(mst) })
            break;
          case matchStatusTypes.cancelled:
            _icons.push({ name: 'cancel', color: 'red', title: _.startCase(mst) })
            break;
          case matchStatusTypes.exhibition:
          case matchStatusTypes.postponed:
            _icons.push({ name: 'question circle outline', color: 'brown', title: _.startCase(mst) })
            break;
          default:
          // nothing
        }
      })
    }

    if (isPlayoff) {
      _icons.push({ name: 'trophy', color: 'blue' })
    }

    return { _icons, _newMatch }
  }

  const matchItem = (m, matchIndex) => {

    const {
      _gsid,
      _itemKey,
      _matchCollectionKey,
      _matchStatusTypes,
      away,
      home,
      isPlayoff,
      level,
      location,
      old,
      poolKey,
      section,
      startDate,
      _status
    } = m ?? {}

    const results = m ? m[_resultsProp] : undefined;

    const { scoreDisplays } = results ?? {}
    const { full, scores } = scoreDisplays ?? {}
    const { matchName } = _status ?? {}

    const matchStatus = {
      dateChanged: _matchStatusTypes && _matchStatusTypes.includes(matchStatusTypes.dateChanged) ? true : false,
      scoreChanged: _matchStatusTypes && _matchStatusTypes.includes(matchStatusTypes.scoreChanged) ? true : false,
      scoresChanged: _matchStatusTypes && _matchStatusTypes.includes(matchStatusTypes.scoresChanged) ? true : false,
      scoreNew: _matchStatusTypes && _matchStatusTypes.includes(matchStatusTypes.scoreNew) ? true : false,
      scoresNew: _matchStatusTypes && _matchStatusTypes.includes(matchStatusTypes.scoresNew) ? true : false,
      inversed: _matchStatusTypes && _matchStatusTypes.includes(matchStatusTypes.inversed) ? true : false,
    }

    let _scoreDisplay;
    let _scoreDisplay_inv;
    let _oldStartDate;

    const _home = _.isObject(home) ? home.name : home
    const _away = _.isObject(home) ? away.name : away
    const _location = location && location.name ? location.name : location

    _scoreDisplay = full

    const at = isPlayoff ? ' vs ' : ' @ '

    if (!_scoreDisplay) { _scoreDisplay = _away + at + _home }
    if (matchStatus.inversed && old) { _scoreDisplay_inv = old.away + at + old.home }
    if (matchStatus.dateChanged && old) { _oldStartDate = old.startDate }

    const { _icons, _newMatch } = icons_match(_matchStatusTypes, isPlayoff)

    const icons = _icons ? _icons.map(i => (<List.Icon name={i.name} color={i.color} title={i.title}></List.Icon>)) : null

    return <List.Item key={uniqueKey('mlg', 'list-item', matchIndex)}>
      {icons && <div>{icons}</div>}
      <List.Content>
        {/* {<List.Content floated='right'>
          <Dropdown placeholder='action' search selection options={matchOptions} className={_lblSize} matchkey={_itemKey} levelkey={_matchCollectionKey} onChange={handleActionChange} />
        </List.Content>} */}
        <List.Header>{_scoreDisplay ? _scoreDisplay : matchName}</List.Header>
        {isPlayoff && _location && <List.Description>
          <Label size={_lblSize} color={_home === _location ? null : 'blue'}><Icon name='map outline' />{'Location: '}{_location}</Label>
        </List.Description>}
        <List.Description>
          <Label size={_lblSize} color={'grey'}>{'Collection: '}{_matchCollectionKey} </Label>
          <Label size={_lblSize} color={'grey'}>{'Level: '}{level}</Label>
          {section && <Label size={_lblSize} color={'grey'}>{'Section: '}{section}</Label>}
          {isPlayoff && poolKey && <Label size={_lblSize} color={'grey'}>{'Round: '}{_.startCase(poolKey)}</Label>}
        </List.Description>

        {_oldStartDate && <List.Description>
          <Label size={_lblSize} color={'red'}>{_oldStartDate}</Label>
          <Label size={_lblSize} color={'green'}>{startDate}</Label>
        </List.Description>}
        {(matchStatus.scoreChanged || matchStatus.scoreNew || matchStatus.scoresNew) && full && <List.Description>
          <Label size={_lblSize} color={'blue'}>{full}</Label>
        </List.Description>}
        {(matchStatus.scoresChanged || matchStatus.scoresNew || matchStatus.scoresNew) && full && <List.Description>
          <Label size={_lblSize} color={'blue'}>{scores}</Label>
        </List.Description>}
        {matchStatus.inversed && <List.Description>
          <Label size={_lblSize} color={'red'}>{_scoreDisplay_inv}</Label>
        </List.Description>}
        {filterType === matchStatusTypes.duplicated && <List.Description>
          <Label size={_lblSize} color={'red'}>{startDate}</Label>
        </List.Description>}
        <List.Description>
          <Label size={_lblSize} color={_newMatch ? 'green' : 'grey'}>{'Match Key: '}{_itemKey}</Label>
          <Label size={_lblSize} color={_newMatch ? 'green' : 'grey'}>{'GSID: '}{_gsid}</Label>
        </List.Description>
      </List.Content>
    </List.Item>
  }

  const dateIcon = (dgKey) => <Icon name={'search'} style={{ float: 'right' }} onClick={(e) => { handleDateSearch(e, dgKey, dateGroupKeyz) }}></Icon>

  const segGroupHeader = (groupIndex, dgKey, _counts) => <Segment key={uniqueKey('ml', groupIndex, 'seg', 1)} onClick={(e) => { handleDateSelectIndex(groupIndex) }}>
    <Label size={_lblSize} color='black'>{dgKey}</Label>
    <Label size={_lblSize} color='green'>{_counts.total}</Label>
    <Label size={_lblSize} color='blue'>{_counts.section}</Label>
    <Label size={_lblSize} color='orange'>{_counts.nonSection}</Label>
    <Label size={_lblSize} color='grey'>{_counts.nonDistrict}</Label>
    <Label size={_lblSize} color='purple'>{_counts.tournament}</Label>
    {handleDateSearch && dateIcon(dgKey)}
  </Segment>

  const segGrouped = (_matches_date, groupIndex) => {
    const segs = []
    const _matches_date_grouped = _.groupBy(_matches_date, '_matchCollectionKey')
    Object.keys(_matches_date_grouped).forEach(nsk => {
      const mdg = _matches_date_grouped[nsk]
      const mls = []
      mdg.forEach((m, index) => {
        mls.push(matchItem(m, index))
      })
      const list = <List divided relaxed className='gs-match-list'>
        {mls}
      </List>
      const allowSeg = !_.isNull(selectedDateIndex) && (selectedDateIndex === groupIndex)
      const allowGroup = !_.isNull(selectedDateGroup) && (selectedDateGroup === nsk)
      segs.push(<Segment style={allowSeg ? { display: 'block', backgroundColor: 'grey', color: 'white', textAlign: 'center' } : { display: 'none', backgroundColor: 'grey', color: 'white', textAlign: 'center' }} key={uniqueKey('ml', groupIndex, nsk, 1)} onClick={(e) => { handleDateSelectGroup(nsk) }}>{nsk}</Segment>)
      segs.push(<Segment style={allowSeg && allowGroup ? { display: 'block' } : { display: 'none' }} key={uniqueKey('ml', groupIndex, nsk, 2)}>{list}</Segment>)
    })
    return segs
  }

  const matchSegment = () => {
    const groups = []
    if (dateGroupKeys && dateGroups) {
      dateGroupKeys.forEach((dgKey, groupIndex) => {
        const matches_date = dateGroups[dgKey]
        const _matches_date = _.orderBy(matches_date, ['level', 'section'])
        const _counts = {
          total: _matches_date.length,
          tournament: _.filter(_matches_date, { level: 'tournament' }).length,
          nonSection: _.filter(_matches_date, { section: 'Non' }).length,
          nonDistrict: _.filter(_matches_date, { level: 'nonDistrict' }).length,
          section: 0
        }

        _counts.section = _counts.total - (_counts.tournament + _counts.nonDistrict + _counts.nonSection)
        if (_counts.section < 0) { _counts.section = 0 }

        groups.push(<Segment.Group key={uniqueKey('mlg', groupIndex)} >
          {segGroupHeader(groupIndex, dgKey, _counts)}
          {segGrouped(_matches_date, groupIndex)}
        </Segment.Group >)
      })
    }

    return groups
  }

  const matchList_dups = () => {
    const groups = []
    if (dateGroupKeys && dateGroups) {
      dateGroupKeys.forEach((dgKey, groupIndex) => {
        const mls = []
        const matchGroup = dateGroups[dgKey]

        if (matchGroup) {
          matchGroup.forEach((m, index) => {
            mls.push(matchItem(m, index, true))
          })
        }

        const list = <List divided relaxed className='gs-match-list'>
          {mls}
        </List>

        groups.push(<Segment.Group key={uniqueKey('mlg', groupIndex)} >
          <Segment key={uniqueKey('ml', groupIndex, 'seg', 1)} onClick={(e) => { handleDateSelectIndex(groupIndex) }}>{dgKey} </Segment>
          {((!_.isNull(selectedDateIndex) && selectedDateIndex === groupIndex)) && <Segment key={uniqueKey('ml', groupIndex, 'seg', 2)}>{list}</Segment>}
        </Segment.Group >)
      })
    }

    return groups
  }


  const footer_edits = () => {
    const btns = []
    btns.push({ oc: handleUpdateMatches, caption: 'Update Matches', icon: 'save' })
    return btns
  }

  switch (filterType) {
    case matchStatusTypes.duplicated:
      return matchList_dups()
    default:
      if (allowEdits) {
        return <Wrapper
          content={matchSegment()}
          footer={<UiSaveButtons others={footer_edits()} />}
          wrapperType={wrapperTypes.paddedFooter}
          css={{ container: 'app-dark-mode' }}
        ></Wrapper>
      } else {

        return matchSegment()
      }
  }

}

export default MatchList