import React, { useContext, useEffect, useState } from 'react';
import { getFirstObject, getFirstObjectKey } from '../../../global/common/convert';
import { PapsContext } from '../../../global/cnr/contexts/PapsContext';
import { fs_get_data_all, fs_get_eventData_global } from '../../../global/firestoreData/appData/fsAppData';
import FullPageWrapper from '../../../global/wrappers/FullPageWrapper';
import SingleTicket, { ticketTypes } from './SingleTicket';

const DirectTicket = (props) => {

  // papsContext
  const papsContext = useContext(PapsContext);
  const { paps_state } = papsContext ?? {}
  const { pathViews } = paps_state ?? {}
  const ticketKeys = pathViews ? pathViews.tickets : null
  const _ticketKeys = ticketKeys ? ticketKeys.split('_') : []

  let mainTicketKey;
  let userTicketKey;

  if (_ticketKeys && _ticketKeys.length === 2) {
    mainTicketKey = _ticketKeys[0]
    userTicketKey = _ticketKeys[1]
  }

  const [ticket, setTicket] = useState()
  const [assignedTickets, setAllTickets] = useState()
  const [directTicket, setDirectTicket] = useState()

  const { name } = ticket ? ticket : { name: 'Pass' }

  const handleTicketCallback = (t) => {
    const { item: _ticket } = t ? getFirstObject(t) : null
    const _ticketKey = t ? getFirstObjectKey(t) : null
    _ticket._itemKey = _ticketKey
    setTicket(_ticket)
  }

  useEffect(() => {
    if (props && props.ticket) {
      setTicket(props.ticket)
    } else {
      fs_get_data_all(pathViews, 'tickets', handleTicketCallback, { docKey: mainTicketKey })
      fs_get_eventData_global(pathViews, '_ticketing', setAllTickets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    if (ticket && assignedTickets) {
      if (assignedTickets && assignedTickets[mainTicketKey]) {
        const eventTickets = assignedTickets[mainTicketKey]
        const { userTickets } = eventTickets ?? {}
        if (userTickets[userTicketKey]) {
          const userTicket = userTickets[userTicketKey]
          const tick = {
            ...ticket,
            directTicketKey: userTicketKey,
            userTickets: userTicket
          }
          setDirectTicket(tick)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [assignedTickets, ticket]);

  const singleTicketItem = () => directTicket ? <SingleTicket ticket={directTicket} ticketType={ticketTypes.display} /> : <div></div>

  const fullPageWrapper = () => <FullPageWrapper
    content={singleTicketItem()}
    topperCaption={name}
    noModal={true}
  />

  return fullPageWrapper()
}

export default DirectTicket