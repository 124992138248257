import { deleteField } from "firebase/firestore";
import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from "../../../../global/cnr/reducers/reducerHelpers/dispatchProps";
import { getFirstObject } from '../../../../global/common/convert';
import { deleteFromObject, getGroupListData, removeFromObject, removeFromObjects, sumObjectProp } from '../../../../global/common/sorting';
import { copyObj } from "../../../../global/common_web/copy";
import { createRefPath, createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { fs_get_data, fs_get_data_all } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_delete_doc, fs_set_doc, fs_update_doc } from '../../../../global/firestoreData/appData/fsData';
import { golfEnums } from "../../enums/golfEnums";
import { calcGolfRounds } from '../../golfCalcs/calcGolfRounds';
import { _allowGolfEventUpdates } from "./GolfEventReducer";
import { fixFromMatches, fixGolferHandicaps, fixGolfers_tournament, fixTournament } from "./reducerHelpers/golfFix";
import { ammendTournamentGolfers, ammendLineupsWithGolfers, ammendMatchGolfers, getAllMatches, getSeletedTeamGolfers, getTournamentCourses, roundColor } from "./reducerHelpers/golfTournamentHelpers";
import { calcMatchStatus } from "../../golfCalcs/calcMatchStatus";
import { sendMatchMessage } from "../../../sports/cnr/reducers/SportsDataReducer";

const removeFields_golferTournament = ['_itemKey', 'id', 'handicap', 'checked']
const deleteFields_golferTournament = [
  '_authId',
  '_existingKey',
  '_itemKey',
  'firstName',
  'handicapIndex',
  'handicapSourceType',
  'lastName',
  'name',
  'phoneNumber',
  'shortName',
  'teebox',
  'id',
  'key',
]

const removeFields_golferMatch = [
  '_existingKey',
  'auth_uid',
  'courseHandicap',
  'courseHandicaps',
  'courseTeeboxes',
  'email',
  'firstName',
  'handicapIndex',
  'handicapSourceType',
  'id',
  'itemKey',
  'key',
  'lastName',
  'name',
  'shortName',
  'tournamentHandicapIndex',
  'tournamentHandicapSourceType',
  '_dateProps',
  '_updateDate',
  'matchHandicap'
]

const _golfTournamentCollection = 'golfTournaments'
const _golfTournamenCollection_details = 'details'
const _golfTournamentDocument_details = 'details'
const _golfTournamentDocument_matches = 'matches'

export const fields_golferTournament = ['courseHandicaps', 'courseTeeboxes', 'tournamentHandicapIndex', 'teamNumber', 'noTrueCourseHandicap']

export const rts = {
  handleChange_golferTeam: 'handleChange_golferTeam',
  handleChange_golferTournament: 'handleChange_golferTournament',
  handleCheck_eventGolfer: 'handleCheck_eventGolfer',
  handleClear_roundMatches: 'handleClear_roundMatches',
  handleCreate_roundMatches: 'handleCreate_roundMatches',
  handleFix_golfersTournament: 'handleFix_golfersTournament',
  handleFix_golfersTournamentHandicaps: 'handleFix_golfersTournamentHandicaps',
  handleFix_golfTournament: 'handleFix_golfTournament',
  handleFix_golfTournamentFromMatches: 'handleFix_golfTournamentFromMatches',
  handleGet_golfTournamentData: 'handleGet_golfTournamentData',
  handleGetSelectedTeamGolfers: 'handleGetSelectedTeamGolfers',
  handleGoTo_match: 'handleGoTo_match',
  handleGoTo_round: 'handleGoTo_round',
  handleLineup_init: 'handleLineup_init',
  handleLineup_select: 'handleLineup_select',
  handleLineup_sort: 'handleLineup_sort',
  handleLineup_update: 'handleLineup_update',
  handleResponse_lineupUpdated: 'handleResponse_lineupUpdated',
  handleSet_dataDetails: 'handleSet_dataDetails',
  handleSet_golfDataMatches: 'handleSet_golfDataMatches',
  handleSet_golfTournament: 'handleSet_golfTournament',
  handleSet_initGolfTournament: 'handleSet_initGolfTournament',
  handleSet_tournamentInfo: 'handleSet_tournamentInfo',
  handleShow_chart: 'handleShow_chart',
  handleShow_createRounds: 'handleShow_createRounds',
  handleUpdate_golfCourse: 'handleUpdate_golfCourse',
  handleUpdate_golfMatchHoleWinners: 'handleUpdate_golfMatchHoleWinners',
  handleUpdate_golfMatchScore: 'handleUpdate_golfMatchScore',
  handleUpdate_round: 'handleUpdate_round',
  handleUpdate_roundActive: 'handleUpdate_roundActive',
  handleUpdate_roundOpenScoring: 'handleUpdate_roundOpenScoring',
  handleUpdate_tournamentGolfer: 'handleUpdate_tournamentGolfer',
  handleUpdate_tournamentGolfersHandicaps: 'handleUpdate_tournamentGolfersHandicaps',
  handleUpdate_tournamentResults: 'handleUpdate_tournamentResults',
  // updateGolfers: 'updateGolfers',
  // updateGolfTournamentDoc: 'updateGolfTournamentDoc',
  // handleGet_tournamentCourses: 'handleGet_tournamentCourses',
  // handleUpdate_lineups: 'handleUpdate_lineups',
  // setGolfData: 'setGolfData',
  ...grts,
}

export const golfTournamentReducer = (state, action) => {

  const { pushSimple, pathViews, golfTournament, golfEvent_state } = state

  const { type, matchUpdate, roundKey, matches, dispatch } = action
  const { id: gtid } = golfTournament ?? {}

  const _handlers = golfTournamentHandlers(dispatch)

  const { handleUpdate_golfCourse, handleSet_dataDetails, handleSet_golfDataMatches, handleSet_tournamentInfo, handleResponse_lineupUpdated } = _handlers

  switch (type) {

    case rts.handleCheck_eventGolfer:
      const { item } = action
      const cg = item._itemKey === state.checkedGolfer ? null : item._itemKey
      return { ...state, checkedGolfer: cg }

    case rts.handleGet_golfTournamentData:
      if (action.existing) {
        return { ...state, ...action.existing }
      } else {
        if (action.golfTournamentKey) {
          pathViews.golfTournaments = action.golfTournamentKey
        }
        getGolfTournamentDetails(pathViews.golfTournaments, pathViews, handleSet_dataDetails, handleSet_golfDataMatches, handleSet_tournamentInfo)
      }
      return { ...state }

    case rts.handleSet_tournamentInfo:
      return { ...state, info: action.info }

    case rts.handleSet_golfDataMatches:
      return { ...state, matches: action.matches }

    case rts.handleSet_dataDetails:

      const { detailsData } = action
      const { mainData: t, details: d, _details: dd } = detailsData ? detailsData : {}
      const { golfers } = d ? d : {}
      if (t && t.golfCourse) {
        fs_get_data_all(pathViews, 'golfCourses', handleUpdate_golfCourse, { docKey: t.golfCourse, clientPath: true })
      }

      let playersPerTeam = 2

      if (golfers) {
        const totalGolfers = Object.keys(golfers).length
        playersPerTeam = totalGolfers / 2
        t.playersPerTeam = playersPerTeam

        Object.keys(golfers).forEach(key => {
          if (golfers[key].teamNumber) {
            golfers[key].teamNumber = parseInt(golfers[key].teamNumber)
            golfers[key].key = key
          }
        })
      }
      return { ...state, details: d, golfTournament: t, _details: dd }

    case rts.handleCreate_roundMatches:
      createRoundMatchesInDatabase(gtid, pathViews, matches, roundKey, action.callback)
      return { ...state }

    case rts.handleClear_roundMatches:
      clearRoundMatchesInDatabase(gtid, pathViews, matches, roundKey, action.callback)
      return { ...state }

    case rts.handleUpdate_roundActive:
      updateRoundActive(gtid, pathViews, action.roundKey, action.roundIsActive, action.callback)
      return { ...state }

    case rts.handleUpdate_roundOpenScoring:
      updateRoundOpenScoring(gtid, pathViews, action.roundKey, action.allowOpenScoring, action.callback)
      return { ...state }

    case rts.handleUpdate_tournamentGolfer:
      const { golfer: _golfer } = action
      const { _itemKey } = _golfer
      if (_itemKey) {
        removeFromObject(_golfer, removeFields_golferTournament)
        deleteFromObject(_golfer, deleteFields_golferTournament)
        const dtu = {
          golfers: {
            [_itemKey]: _golfer
          }
        }
        updateGolfTournamentDetails(gtid, pathViews, { ...dtu }, action.callback)
      }
      return { ...state }

    case rts.handleUpdate_tournamentGolfersHandicaps:
      const _golfers = copyObj(action.golfers)
      Object.keys(_golfers).forEach(k => {
        const g = _golfers[k]
        removeFromObject(g, removeFields_golferTournament)
        deleteFromObject(g, deleteFields_golferTournament)
      })
      const dtua = { golfers: _golfers }
      updateGolfTournamentDetails(gtid, pathViews, dtua, action.callback)
      return { ...state }

    case rts.handleLineup_update:
      const gtlu = { ['lineups.' + action.teamKey + '.' + action.roundType]: action.lineup }
      updateGolfTournamentDetails(gtid, pathViews, gtlu, handleResponse_lineupUpdated)
      return { ...state, cb_action: action.callback }

    case rts.handleResponse_lineupUpdated:
      if (state.cb_action) { state.cb_action() }
      return { ...state, updating: false }

    case rts.handleUpdate_round:
      const gtlx = { ['rounds.' + action.roundType + '.golfCourse']: action.selectedCourse }
      console.log('gtlx', gtlx)
      console.log('gtlx', gtlx)
      // updateGolfTournamentDetails(gtid, pathViews, gtlx, action.callback)
      return { ...state }

    case rts.handleChange_golferTournament:
      const { lineups: lineups_changed, golfers: golfer_changed } = changeTournanemtGolfer(action, state)
      updateGolfTournamentDetails(gtid, pathViews, { 'golfers': golfer_changed, 'lineups': lineups_changed }, action.callback)
      return { ...state }

    case rts.handleUpdate_tournamentResults:
      const { tournamentStatus } = state
      const { team1Points, team1Wins, team1Halved, team2Points, team2Wins, team2Halved } = tournamentStatus ?? {}
      const dataToUpate = {
        results: { team1Points, team1Wins, team1Halved, team2Points, team2Wins, team2Halved }
      }
      console.log('tournamentStatus', tournamentStatus)
      console.log('dataToUpate', dataToUpate)
      updateGolfTournamentResults(gtid, pathViews, dataToUpate)
      return { ...state }

    case rts.handleSet_golfTournament:
      return {
        golfTournaments: action.gt,
        ...state,
      }

    case rts.handleSet_initGolfTournament:
      const a = initGolfTournamentData(state, golfEvent_state)
      const tournamentGolfCourses = getTournamentCourses(state)
      const p = {
        ...state,
        ...a,
        tournamentGolfCourses
      }
      return {
        ...p
      }

    case rts.handleUpdate_golfCourse:
      const { item: gc } = getFirstObject(action.golfCourse)
      return { ...state, golfCourse: gc }

    case rts.handleGoTo_round:
      pushSimple({ key: 'tournamentRounds' }, roundKey)
      return { ...state }

    case rts.handleGoTo_match:
      pushSimple({ key: 'golfMatches' }, action.gm._itemKey)
      return { ...state }

    case rts.handleLineup_init:
      const _selectedKeys = []
      const { lineupInfo } = action
      const { teamRoundLineup, golfers_lineup, golfers_team } = lineupInfo ? lineupInfo : {}
      if (teamRoundLineup) {
        teamRoundLineup.forEach(g => {
          _selectedKeys.push(g.golferKey)
        })
        const _golfers = getSeletedTeamGolfers(_selectedKeys, golfers_lineup, golfers_team)
        const _sorted = _golfers ? getGroupListData(_golfers, null, 2, 'group') : {}
        return { ...state, lineupInfo: { selectedKeys: _selectedKeys, golfers: _golfers, sorted: _sorted }, selectedLineupItems: _selectedKeys }
      }
      return { ...state, lineupInfo: {} }

    case rts.handleLineup_select:
      const _lineupInfo = { ...state.lineupInfo }
      _lineupInfo.selectedKeys = action.selectedKeys
      return { ...state, lineupInfo: _lineupInfo }

    case rts.handleLineup_sort:
      const _lineupInfo2 = { ...state.lineupInfo }
      _lineupInfo2.sorted = action.sortData
      return { ...state, lineupInfo: _lineupInfo2 }

    case rts.handleUpdate_golfMatchHoleWinners:
      const { golfMatch: _gm } = matchUpdate
      const _matchData = { ..._gm }
      updateMatchScore(pathViews.golfTournaments, pathViews, _matchData, action.callback)
      return { ...state }

    case rts.handleUpdate_golfMatchScore:

      const { golfMatch, holeNumber, winningTeam, playerScoring } = matchUpdate
      const matchData = { ...golfMatch }

      // create holeWinners if it does not exist
      if (!matchData.holeWinners) { matchData.holeWinners = {} }
      matchData.holeNumber = holeNumber

      // update holeWinners
      switch (winningTeam) {
        case -1:
          removeMatchScore(pathViews.golfTournaments, pathViews, matchData, holeNumber, action.callback)
          break;
        default:
          if (playerScoring) {
            if (!matchData.holeScores) { matchData.holeScores = {} }
            let _playerScoring = {}
            if (playerScoring.team1) { _playerScoring = { ...playerScoring.team1 } }
            if (playerScoring.team2) { _playerScoring = { ..._playerScoring, ...playerScoring.team2 } }
            matchData.holeScores[holeNumber] = _playerScoring
          }
          const count = Object.keys(matchData.holeWinners).length
          matchData.holeWinners[holeNumber] = { winningTeam: winningTeam, order: count }

          try {
            let _holeResult;
            if (winningTeam > 0) {
              const _team = state.teams ? _.find(state.teams, { teamNumber: winningTeam }) : {}
              const { name: name_winningTeam } = _team ? _team : {}
              if (name_winningTeam) {
                _holeResult = name_winningTeam + ' has won hole #' + holeNumber + '.'
              } else {
                _holeResult = winningTeam + ' has won hole #' + holeNumber + '.'
              }
            } else {
              _holeResult = 'Hole # ' + holeNumber + ' was tied.'
            }
            const _matchStatus = calcMatchStatus(matchData, state.holes, state.teams)
            sendMatchMessage(_matchStatus, _holeResult)
          } catch (error) {

          }

          updateMatchScore(pathViews.golfTournaments, pathViews, matchData, action.callback)
      }

      return { ...state }

    // case golfTournamentTypes.saveGolfMatches:
    //   const { golfMatches } = action
    //   const ufPropsM = getUfProps('golfMatches', null, golfMatches, gEnums.dataUpdateTypes.updates)
    //   if (allowUpdates) firestore_handlers.handleUpdate_firestoreData(dispatch, paps_state, ufPropsM)
    //   return { ...state }



    case rts.handleChange_golferTeam:
      const { tournamentGolfers } = state
      const { player, teamIndex, rowIndex } = action
      const { key: playerKey } = player ? player : {}
      const tgp = tournamentGolfers[playerKey]
      tgp.teamNumber = teamIndex
      return { ...state, tournamentGolfers, rowIndex }

    // case rts.updateGolfTournamentDoc:

    //   const { tournamentGolfers: tg, lineups: tl, tournamentRounds: tr, tournamentTeams: tt, golfMatches: gm, golfTournaments: g_t } = state
    //   const gte = { golfers: tg, lineups: tl, rounds: tr, teams: tt, matches: gm }
    //   const gtd = { golfers: null, lineups: null, rounds: null, teams: null, matches: null }

    //   if (gte.teams) { gtd.teams = gte.teams }
    //   if (gte.golfers) { gtd.golfers = gte.golfers }
    //   if (gte.lineups) {
    //     const _lineups = { 1: {}, 2: {} }
    //     Object.keys(gte.lineups).forEach(key => {
    //       const lineup = gte.lineups[key]
    //       const { tournamentTeams: team, tournamentRounds: lineup_round } = lineup ? lineup : {}
    //       if (lineup_round && lineup_round[0] && gte.rounds && gte.rounds[lineup_round[0]]) {
    //         const rt = gte.rounds[lineup_round[0]].roundType
    //         _lineups[team][rt] = lineup
    //         _lineups[team][rt].round = rt
    //         _lineups[team][rt].golfers = lineup.tournamentGolfers
    //         delete _lineups[team][rt].tournamentRounds
    //         delete _lineups[team][rt].tournamentGolfers
    //       }
    //     })
    //     gtd.lineups = _lineups
    //   }
    //   if (gte.rounds) {
    //     const _rounds = {}
    //     Object.keys(gte.rounds).forEach(key => {
    //       const round = gte.rounds[key]
    //       const { lineups } = round
    //       if (lineups) {
    //         Object.keys(lineups).forEach(tlKey => {
    //           lineups[tlKey] = round.roundType
    //         })
    //       }
    //       _rounds[round.roundType] = round
    //     })
    //     gtd.rounds = _rounds
    //   }
    //   if (gte.matches) {
    //     gtd.matches = gte.matches
    //     Object.keys(gtd.matches).forEach(matchKey => {
    //       const match = gtd.matches[matchKey]
    //       const { tournamentRounds: match_round } = match ?? {}
    //       if (match_round && gte.rounds && gte.rounds[match_round]) {
    //         const rt = gte.rounds[match_round].roundType
    //         match.round = rt
    //         delete match.tournamentRounds
    //       }
    //     })
    //   }

    //   const { id } = g_t

    //   const _setRef = createRefPath_event(pathViews, [_golfTournamentCollection, id])
    //   fs_set_doc(_setRef, g_t, true).then(res => {
    //     const _detailsRef = createRefPath([_golfTournamenCollection_details, _golfTournamentDocument_details], _setRef)
    //     fs_set_doc(_detailsRef, gtd, true)
    //   })

    //   // gtRef.set(g_t, { merge: true }).then(res => {
    //   //   const gtcRef = gtRef.collection('details')
    //   //   gtcRef.doc('details').set(gtd, { merge: true })
    //   // })

    //   return { ...state }

    case rts.handleFix_golfTournament:
      fixTournament(state)
      return { ...state }

    case rts.handleFix_golfTournamentFromMatches:
      fixFromMatches(state)
      return { ...state }

    case rts.handleFix_golfersTournamentHandicaps:
      fixGolferHandicaps(state)
      return { ...state }

    case rts.handleFix_golfersTournament:
      fixGolfers_tournament(state)
      return { ...state }

    case rts.handleShow_createRounds:
      action.e && action.e.stopPropagation()
      return { ...state, showCreateRounds: !state.showCreateRounds }

    case rts.handleShow_chart:
      const _showRoundCharts = state.showRoundCharts ? state.showRoundCharts : {}
      _showRoundCharts[action.roundKey] = !_showRoundCharts[action.roundKey]
      return { ...state, showRoundCharts: _showRoundCharts }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.updateError:
    case rts.updateSuccess:
    case rts.updateSuccessAlt:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterConfirmation })

    default:
      return { ...state }
  }
}

export const golfTournamentHandlers = (dispatch) => {
  return {
    handleChange_golferTeam: (player, teamIndex, rowIndex) => { dispatch({ type: rts.handleChange_golferTeam, player, teamIndex, rowIndex }) },
    handleChange_golferTournament: (existingGolferKey, newGolfer) => { dispatch({ type: rts.handleChange_golferTournament, existingGolferKey, newGolfer }) },
    handleCheck_eventGolfer: (e, item, itemChecked) => { dispatch({ type: rts.handleCheck_eventGolfer, dispatch, e, item, itemChecked }) },
    handleCreate_roundMatches: (matches, roundKey, callback) => { dispatch({ type: rts.handleCreate_roundMatches, dispatch, matches, roundKey, callback }) },
    handleClear_roundMatches: (matches, roundKey, callback) => { dispatch({ type: rts.handleClear_roundMatches, dispatch, matches, roundKey, callback }) },
    handleFix_golfersTournament: () => { dispatch({ type: rts.handleFix_golfersTournament, dispatch }) },
    handleFix_golfersTournamentHandicaps: () => { dispatch({ type: rts.handleFix_golfersTournamentHandicaps, dispatch }) },
    handleFix_golfTournament: () => { dispatch({ type: rts.handleFix_golfTournament, dispatch }) },
    handleFix_golfTournamentFromMatches: () => { dispatch({ type: rts.handleFix_golfTournamentFromMatches, dispatch }) },
    handleGet_golfTournamentData: (existing, golfTournamentKey) => { dispatch({ type: rts.handleGet_golfTournamentData, dispatch, existing, golfTournamentKey }) },
    handleGoTo_match: (gm) => { dispatch({ type: rts.handleGoTo_match, gm }) },
    handleGoTo_round: (roundKey) => { dispatch({ type: rts.handleGoTo_round, roundKey }) },
    handleLineup_init: (lineupInfo) => { dispatch({ type: rts.handleLineup_init, dispatch, lineupInfo }) },
    handleLineup_select: (selectedKeys) => { dispatch({ type: rts.handleLineup_select, dispatch, selectedKeys }) },
    handleLineup_sort: (sortData) => { dispatch({ type: rts.handleLineup_sort, dispatch, sortData }) },
    handleLineup_update: (teamKey, roundType, lineup, callback) => { dispatch({ type: rts.handleLineup_update, dispatch, teamKey, roundType, lineup, callback }) },
    handleResponse_lineupUpdated: () => { dispatch({ type: rts.handleResponse_lineupUpdated, dispatch }) },
    handleSet_dataDetails: (detailsData) => { dispatch({ type: rts.handleSet_dataDetails, detailsData, dispatch }) },
    handleSet_golfDataMatches: (matches) => { dispatch({ type: rts.handleSet_golfDataMatches, matches, dispatch }) },
    handleSet_golfTournament: (gt) => { dispatch({ type: rts.handleSet_golfTournament, dispatch, gt }) },
    handleSet_initGolfTournament: () => { dispatch({ type: rts.handleSet_initGolfTournament }) },
    handleSet_tournamentInfo: (info) => { dispatch({ type: rts.handleSet_tournamentInfo, info, dispatch }) },
    handleShow_createRounds: (e) => { dispatch({ type: rts.handleShow_createRounds, dispatch, e }) },
    handleUpdate_golfCourse: (golfCourse) => { dispatch({ type: rts.handleUpdate_golfCourse, golfCourse, dispatch }) },
    handleUpdate_golfMatchHoleWinners: (matchUpdate, callback) => { dispatch({ type: rts.handleUpdate_golfMatchHoleWinners, matchUpdate, callback, dispatch }) },
    handleUpdate_golfMatchScore: (matchUpdate, callback) => { dispatch({ type: rts.handleUpdate_golfMatchScore, matchUpdate, callback, dispatch }) },
    handleUpdate_round: (roundType, selectedCourse, callback) => { dispatch({ type: rts.handleUpdate_round, dispatch, roundType, selectedCourse, callback }) },
    handleUpdate_tournamentGolfer: (golfer) => { dispatch({ type: rts.handleUpdate_tournamentGolfer, golfer, dispatch }) },
    handleUpdate_tournamentGolfersHandicaps: (golfers, callback) => { dispatch({ type: rts.handleUpdate_tournamentGolfersHandicaps, golfers, callback }) },
    handleUpdate_tournamentResults: () => { dispatch({ type: rts.handleUpdate_tournamentResults }) },
    handleUpdate_roundActive: (roundKey, roundIsActive, callback) => { dispatch({ type: rts.handleUpdate_roundActive, roundKey, roundIsActive, callback }) },
    handleUpdate_roundOpenScoring: (roundKey, allowOpenScoring, callback) => { dispatch({ type: rts.handleUpdate_roundOpenScoring, roundKey, allowOpenScoring, callback }) },
    handleShow_chart: (roundKey) => { dispatch({ type: rts.handleShow_chart, roundKey }) },
    // updateGolfTournamentDoc: () => { dispatch({ type: rts.updateGolfTournamentDoc, dispatch }) },
    // handleGet_tournamentCourses: () => { dispatch({ type: rts.handleGet_tournamentCourses }) },
    // updateGolfers: (golfers) => { dispatch({ type: rts.updateGolfers, golfers, dispatch }) },
    // setGolfData: (golfData, viKey) => { dispatch({ type: rts.setGolfData, golfData, viKey }) },
    // handleUpdate_lineups: (lineups, callback) => { dispatch({ type: rts.handleUpdate_lineups, dispatch, lineups, callback }) },
    ...responseHandlers(dispatch)
  }
}

export const golfTournamentInitialState = (init_state) => {
  return { ...init_state }
};

const initGolfTournamentData = (state, golfEvent_state) => {

  const { golfTournament, golfCourse, matches, details, tournamentGolfCourses } = state
  const { rounds, teams, golfers: golfers_tournament, lineups } = details

  const golfTournamentMatches = getAllMatches(rounds, matches)

  const { teams: teams_event, golfers: golfers_event, golfers_auth, allGolfers, golfCourses } = golfEvent_state ?? {}

  if (rounds && golfCourses) {
    Object.keys(rounds).forEach(rk => {
      const round = rounds[rk]
      if (round.golfCourse) {
        const rgc = golfCourses[round.golfCourse]
        if (rgc) {
          round.golfCourseName = rgc.name
        }
      }
    })
  }

  const { description } = golfTournament ?? {}
  const { holes, teeBoxes } = golfCourse ? golfCourse : {}

  let golfCoursePar = holes ? sumObjectProp(holes, 'par') : 72
  const missing = {}

  const _golfers = ammendTournamentGolfers(golfers_event, golfers_tournament, golfers_auth)

  ammendMatchGolfers(golfTournamentMatches, _golfers, allGolfers, tournamentGolfCourses, rounds, missing, golfers_auth)
  ammendLineupsWithGolfers(lineups, _golfers)

  const golfTeam1_event = teams_event ? teams_event.team1 : {}
  const golfTeam2_event = teams_event ? teams_event.team2 : {}

  const golfTeam1 = teams ? _.find(teams, { teamNumber: 1 }) : {}
  const golfTeam2 = teams ? _.find(teams, { teamNumber: 2 }) : {}

  const golfTeam_1 = teams ? _.find(teams, { teamNumber: '1' }) : {}
  const golfTeam_2 = teams ? _.find(teams, { teamNumber: '2' }) : {}

  const _golfTeam1 = golfTeam1 ? golfTeam1 : golfTeam_1
  const _golfTeam2 = golfTeam2 ? golfTeam2 : golfTeam_2

  const golfClasses = {
    active: 'status-active',
    completed: 'status-completed',
    default: 'status-default',
    neutral: 'status-neutral',
    pending: 'status-pending',
  }

  const activeStyle = { backgroundColor: 'green', color: 'white' }
  const completedStyle = { backgroundColor: '#575757', color: 'white' }
  const defaultStyle = { backgroundColor: 'grey', color: 'white' }
  const noneStyle = { backgroundColor: 'darkgrey', color: 'white' }
  const neutralStyle = { backgroundColor: 'darkGrey', color: 'white' }
  const pendingStyle = { backgroundColor: '#6e6e6e', color: 'white' }
  const removeStyle = { backgroundColor: '#ff9e81' }

  const roundColors = {
    [golfEnums.roundTypes.alternateShot]: roundColor(golfEnums.roundTypes.alternateShot),
    [golfEnums.roundTypes.alternateShot1]: roundColor(golfEnums.roundTypes.alternateShot1),
    [golfEnums.roundTypes.alternateShot2]: roundColor(golfEnums.roundTypes.alternateShot2),

    [golfEnums.roundTypes.bestBall]: roundColor(golfEnums.roundTypes.bestBall),
    [golfEnums.roundTypes.bestBall1]: roundColor(golfEnums.roundTypes.bestBall1),
    [golfEnums.roundTypes.bestBall2]: roundColor(golfEnums.roundTypes.bestBall2),

    [golfEnums.roundTypes.singles]: roundColor(golfEnums.roundTypes.singles),
    [golfEnums.roundTypes.scramble]: roundColor(golfEnums.roundTypes.scramble),
  }

  const golfTeam1Style = {
    backgroundColor: golfTeam1_event && golfTeam1_event.teamColor ? golfTeam1_event.teamColor : 'white',
    color: golfTeam1_event && golfTeam1_event.teamTextColor ? golfTeam1_event.teamTextColor : 'black',
  }

  const golfTeam2Style = {
    backgroundColor: golfTeam2_event && golfTeam2_event.teamColor ? golfTeam2_event.teamColor : 'white',
    color: golfTeam2_event && golfTeam2_event.teamTextColor ? golfTeam2_event.teamTextColor : 'black',
  }

  let roundsStatus = {}
  let roundsStatusByHole = {}

  const ts = {
    team1Leading: 0,
    team1Virtual: 0,
    team1Wins: 0,
    team1Dormies: 0,
    team1Halved: 0,
    team1Square: 0,
    team2Leading: 0,
    team2Virtual: 0,
    team2Wins: 0,
    team2Dormies: 0,
    team2Halved: 0,
    team2Square: 0,
    matchesSquare: 0,
    matchesHalved: 0,
    tournamentComplete: true
  }

  if (rounds) {
    const { roundsStatus: _roundsStatus, roundsStatusByHole: _roundsStatusByHole } = calcGolfRounds(ts, golfTournament, rounds, matches, holes, teams)
    roundsStatus = _roundsStatus
    roundsStatusByHole = _roundsStatusByHole
    if (roundsStatus) {
      Object.keys(roundsStatus).forEach(key => {
        const rs = roundsStatus[key]
        if (!rs.roundComplete) {
          ts.tournamentComplete = false
        }
      })
    } else {
      ts.tournamentComplete = false
    }
  } else {
    ts.tournamentComplete = false
  }

  const gts = {
    matchDataIsLocked: golfTournament.dataIsLocked,
    description,
    golfCourse,
    golfCoursePar,
    // golfCourseTeeBox,
    matches,
    golfTournamentMatches,
    golfers: _golfers,
    golfTeam1: _golfTeam1,
    golfClasses: golfClasses,
    golfStyles: {
      activeStyle,
      completedStyle,
      defaultStyle,
      golfTeam1Style,
      golfTeam2Style,
      neutralStyle,
      noneStyle,
      pendingStyle,
      roundColors,
      removeStyle,
    },
    golfTeam2: _golfTeam2,
    golfTournament,
    holes,
    teams,
    teeBoxes,
    lineups,
    roundsStatus,
    roundsStatusByHole,
    rounds,
    tournamentStatus: ts,
  }

  return gts
}

/** Get the details */
const getGolfTournamentDetails = (gtid, pathViews, detailsCallback, matchesCallback, infoCallback) => {

  const _cb_details = (detailsData, cbProps, returnFsr, cbData) => {
    const { mainData } = cbData ? cbData : {}
    const { details } = detailsData ? detailsData : {}
    if (detailsCallback) {
      const _details = details ? global.structuredClone(details) : {}
      detailsCallback({ mainData, details, _details })
    }
  }

  const _cb_matches2 = (matchesData) => matchesCallback(matchesData)

  const _cb_matches = (matchesData) => {
    if (matchesCallback) {
      const matches = matchesData[_golfTournamentDocument_matches]
      matchesCallback(matches)
      // Object.keys(matches).forEach((rtk, index) => {
      //   const matches_round = matches[rtk]
      //   const matches_round_cleand = deepClean(matches_round)
      //   const _matchesRef_update = createRefPath([_golfTournamentDocument_matches, rtk], _basePath)
      //   fs_update_doc(_matchesRef_update, matches_round_cleand)
      // })
    } else {
      return {}
    }
  }

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid])

  console.log('_basePath', _basePath)

  fs_get_data({ refPath: _basePath, opts: { returnFirstObject: true } }).then(mainData => {

    const _detailsRef = createRefPath([_golfTournamenCollection_details, _golfTournamentDocument_details], _basePath)
    const _matchesRef = createRefPath([_golfTournamentDocument_matches], _basePath)
    const _infoRef = createRefPath([_golfTournamenCollection_details, 'info'], _basePath)

    fs_get_data({ refPath: _detailsRef, callback: _cb_details, opts: { ignoreId: true, listen: false, cbData: { mainData } } })
    fs_get_data({ refPath: _matchesRef, callback: _cb_matches2, opts: { ignoreId: true, listen: true } })

    fs_get_data({ refPath: _infoRef }).then(infoData => {
      if (infoCallback) {
        const { info } = infoData
        infoCallback(info)
      } else {
        return {}
      }
    })
  })
}

const updateGolfTournamentResults = async (gtid, pathViews, dataToUpdate, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid])

  if (_allowGolfEventUpdates.tournamentResults) {
    await fs_update_doc(_basePath, dataToUpdate)
    callback && callback()
    // fs_set_doc(_basePath, dataToUpdate, true, callback)
  } else {
    console.log('_basePath', _basePath)
    console.log('dataToUpdate', dataToUpdate)
    callback && callback()
  }
}

/** Updates the details */

/**
 * 
 * @param {string} gtid 
 * @param {object} pathViews 
 * @param {object} dataToUpdate 
 * @param {function} callback 
 */
const updateGolfTournamentDetails = async (gtid, pathViews, dataToUpdate, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_details])

  if (_allowGolfEventUpdates.detailsUpdate) {
    await fs_update_doc(_basePath, dataToUpdate)
    callback && callback()
    // fs_set_doc(_basePath, dataToUpdate, true, callback)
  } else {
    console.log('_basePath', _basePath)
    console.log('dataToUpdate', dataToUpdate)
    callback && callback()
  }
}

/** Updates the match score */

/**
 * 
 * @param {string} gtid 
 * @param {object} pathViews 
 * @param {object} matchData 
 * @param {function} callback 
 */
const updateMatchScore = (gtid, pathViews, matchData, callback) => {

  const { round, holeWinners, holeScores, id } = matchData

  if (holeWinners) {
    Object.keys(holeWinners).forEach(k => {
      if (holeWinners[k].winningTeam === -1) {
        holeWinners[k] = deleteField()
      }
    })
  }

  const dataToUpdate = {
    [id]: { 'holeWinners': holeWinners }
  }

  if (holeScores) {
    dataToUpdate[id].holeScores = holeScores
  }

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamentDocument_matches, round])

  // console.log('_basePath', _basePath)
  // console.log('dataToUpdate', dataToUpdate)

  if (_allowGolfEventUpdates.roundMatchUpdate) {
    fs_set_doc(_basePath, dataToUpdate, true, callback)
  }
}

const removeMatchScore = (gtid, pathViews, matchData, holeNumber, callback) => {

  const { round, holeScores, id } = matchData

  const dataToUpdate = {
    [id]: {
      'holeWinners': {
        [holeNumber]: deleteField()
      },
    }
  }

  if (holeScores) {
    dataToUpdate[id].holeScores = holeScores
  }

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamentDocument_matches, round])

  if (_allowGolfEventUpdates.roundMatchUpdate) {
    fs_set_doc(_basePath, dataToUpdate, true, callback)
  }

}

/** Creates the matches */
const createRoundMatchesInDatabase = async (gtid, pathViews, matches, roundKey, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamentDocument_matches, roundKey])

  let _matches = {}

  if (matches) {
    if (callback) {
      Object.keys(matches).forEach((key, index) => {
        const match = matches[key]
        delete match.lowCourseHandicap
        delete match.matchHandicaps
        const { team1, team2 } = match ?? {}
        removeFromObjects(team1, removeFields_golferMatch)
        removeFromObjects(team2, removeFields_golferMatch)
        const matchId = 'match_' + roundKey + '_' + (index + 1)
        _matches[matchId] = {
          ...match,
          id: matchId
        }
      })
    } else {
      _matches = matches
    }

    const dataToUpdate = { ..._matches }

    console.log('_basePath', _basePath)
    console.log('dataToUpdate', dataToUpdate)

    if (_allowGolfEventUpdates.roundMatchCreate) {
      // delete the data first 
      await fs_delete_doc(_basePath)
      fs_set_doc(_basePath, dataToUpdate, true, callback)
    }
  }
}


/** Clear the matches */
const clearRoundMatchesInDatabase = async (gtid, pathViews, matches, roundKey, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamentDocument_matches, roundKey])

  console.log('_basePath', _basePath)
  if (_allowGolfEventUpdates.roundMatchClear) {
    await fs_delete_doc(_basePath)
  }
}

const updateRoundActive = async (gtid, pathViews, roundKey, roundIsActive, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_details])

  const dataToUpdate = { ['rounds.' + roundKey + '.roundIsActive']: !roundIsActive }
  console.log('_basePath', _basePath)
  console.log('dataToUpdate', dataToUpdate)

  if (_allowGolfEventUpdates.roundMatchActivate) {
    // delete the data first 
    fs_update_doc(_basePath, dataToUpdate, callback)
  }
}


const updateRoundOpenScoring = async (gtid, pathViews, roundKey, allowOpenScoring, callback) => {

  const _basePath = createRefPath_event(pathViews, [_golfTournamentCollection, gtid, _golfTournamenCollection_details, _golfTournamentDocument_details])

  const dataToUpdate = { ['rounds.' + roundKey + '.allowOpenScoring']: !allowOpenScoring }
  console.log('_basePath', _basePath)
  console.log('dataToUpdate', dataToUpdate)

  if (_allowGolfEventUpdates.roundMatchOpenScoring) {
    // delete the data first 
    fs_update_doc(_basePath, dataToUpdate, callback)
  }
}


const changeTournanemtGolfer = (action, state) => {

  const { existingGolferKey, newGolfer } = action
  const { _details } = state
  const { lineups, golfers } = _details

  const existingGolfer = golfers ? golfers[existingGolferKey] : {}
  const { teamNumber } = existingGolfer ? existingGolfer : 0
  newGolfer.teamNumber = teamNumber

  // change in lineups
  if (lineups) {
    Object.keys(lineups).forEach(lik => {
      const lineupTeam = lineups[lik]
      if (lineupTeam) {
        Object.keys(lineupTeam).forEach(k => {
          const lineupRound = lineupTeam[k]
          const existingGolfer = _.find(lineupRound, { 'golferKey': existingGolferKey })
          if (existingGolfer) {
            existingGolfer.golferKey = newGolfer._itemKey
          }
        })
      }
    })
  }

  delete golfers[existingGolferKey]
  golfers[newGolfer._itemKey] = newGolfer
  return { lineups, golfers }

}