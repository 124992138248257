import _ from 'lodash';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import DropdownOptionsMenu from '../../../../global/components/forms/elements/DropdownOptionsMenu';
import DataSidebar from '../../../../global/components/swipers/DataSidebar';
import JsonViewer from '../../../../global/components/viewers/JsonViewer';
import { appIconTypes } from '../../../../global/enums/appIconTypes';
import { gEnums } from '../../../../global/enums/globalEnums';
import { newPropEditItem } from '../../../../global/viewSettings/helpers/settingsHelpers';
import { openExternal } from '../../../../global/viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { SportsEditMatchContext } from '../../cnr/contexts/SportsEditMatchContext';
import { SportsSeasonContext, _sportsFormProps } from '../../cnr/contexts/SportsSeasonContext';
import { sportsDataHandlers, sportsDataInitialState, sportsDataReducer } from '../../cnr/reducers/SportsDataReducer';
import { _sdm } from '../dashboard/SportsDashboard';
import SportsRankings from '../rankings/SportsRankings';

const _csv_match = ['id', '_itemKey', 'startDate', 'section', 'level', 'combined', 'away', 'awayScore', 'home', 'homeScore', 'extra']
const _csv_matchPlayoff = ['id', 'startDate', 'startTime', 'level', 'poolKey', 'matchNumber', 'location', 'awaySeed', 'away', 'awayScore', 'homeSeed', 'home', 'homeScore', 'away1', 'home1', 'away2', 'home2', 'away3', 'home3', 'away4', 'home4', 'away5', 'home5']

const _rightModes = {
  exportResults: 'exportResults',
  exportPlayoffResults: 'exportPlayoffResults',
  exportResultsMedia: 'exportResultsMedia',
  exportPlayoffResultsMedia: 'exportPlayoffResultsMedia',
  confirmResults: 'confirmResults',
  confirmRankings: 'confirmRankings'
}

const _fetchModes = {
  results: 'results',
  playoffResults: 'playoffResults',
  resultsMedia: 'resultsMedia',
  playoffResultsMedia: 'playoffResultsMedia',
}

const SeasonRankingsUpdate = (props) => {

  const { sportsDataMode, forSportsManagement, fromApp, handleCancel, currentMenuKey } = props

  console.log('SeasonRankingsUpdate')

  const parentContext = useContext(ParentContext)
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state } = sportsDistrictContext ?? {}
  const { gls_scores } = sportsDistrict_state ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { sportPermissions, sportsKey, latestSeason, gameScoringType, matches_info, teams_info, links_score, rankings_info, details_info } = sportsSeason_state ?? {}

  // sportsEditMatchContext
  const sportsEditMatchContext = useContext(SportsEditMatchContext)
  const { sportsEditMatch_state } = sportsEditMatchContext ?? {}
  const { matchPendingUpdate } = sportsEditMatch_state ?? {}
  const { matchUpdateType } = matchPendingUpdate ?? {}

  // sportsData
  const init_state = { teams: teams_info && teams_info.teams, sportsKey, links_score, pathViews, latestSeason, gameScoringType, matches_info, updateCaption: 'Update Match Results' }
  const [sportsData_state, sportsData_dispatch] = useReducer(sportsDataReducer, init_state, sportsDataInitialState)
  const sportsData_handlers = sportsDataHandlers(sportsData_dispatch, sportsData_state)

  const {
    confirmation,
    filterType,
    rankings_info: rankings_info_data,
    updating,
  } = sportsData_state ?? {}

  const [appFormItems, setAppFormItems] = useState()
  const [formProps, setFormProps] = useState()
  const [linkValues, setLinkValues] = useState({})
  const [mode_right, setMode_right] = useState()
  const [mode_gsTab, setMode_gsTab] = useState()

  const _updateStatus_rankings = sportPermissions.any && sportPermissions.rankings ? true : false

  useEffect(() => {
    const form_props = _sportsFormProps[sportsDataMode]
    const _appFormItems = {}
    if (form_props) {
      Object.keys(form_props).forEach(fp => {
        _appFormItems[fp] = newPropEditItem(fp, gEnums.formItemTypes.text, null, null, true)
      })
    }
    setFormProps(form_props)
    setAppFormItems(_appFormItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [currentMenuKey]);

  useEffect(() => {
    const _linkValues = {}
    switch (sportsDataMode) {
      case _sdm.district.updateDistrictSeasonScores:
        const xxx = gls_scores && currentMenuKey && gls_scores[currentMenuKey] ? gls_scores[currentMenuKey] : {}
        if (formProps) {
          Object.keys(formProps).forEach(fp => {
            _linkValues[fp] = xxx ? xxx[fp] : null
          })
        }
        break;
      default:
        if (formProps) {
          Object.keys(formProps).forEach(fp => {
            _linkValues[fp] = links_score ? links_score[fp] : null
          })
        }
    }
    setLinkValues(_linkValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sportsDataMode, formProps, links_score, currentMenuKey]);


  const handleMode_right = (mr) => {
    let _tabName;
    let _forMedia;
    switch (mr) {
      case _rightModes.exportResults:
        _tabName = linkValues.scoresSheet
        break;
      case _rightModes.exportPlayoffResults:
        _tabName = linkValues.playoffScoresSheet
        break;
      case _rightModes.exportResultsMedia:
        _tabName = linkValues.scoresSheet
        _forMedia = true
        break;
      case _rightModes.exportPlayoffResultsMedia:
        _tabName = linkValues.playoffScoresSheet
        _forMedia = true
        break;
    }
    _tabName = _forMedia ? _tabName + ' ' + latestSeason + ' Media' : _tabName + ' ' + latestSeason + ' Latest'
    setMode_gsTab(_tabName)
    setMode_right(mr)
  }

  const handleConfirmation_rankings = () => handleMode_right(_rightModes.confirmRankings)

  const handleOpenGoogleSheets = () => openExternal.googleSheets(linkValues.googleSheetsId)

  const handleUpdate_rankings = () => {
    sportsData_handlers.handleStartUpdate()
    sportsData_handlers.handleUpdate_gsRankings(rankings_info_data)
  }

  const handleGoogleSearch_rankings = () => {
    linkValues.googleSheetsId = linkValues.rankingsGoogleSheetsId
    linkValues.sheetName = linkValues.rankingsSheet
    if (sportsData_handlers) {
      sportsData_handlers.handleStartUpdate()
      sportsData_handlers.handleGet_gsRankings(linkValues, latestSeason, sportsKey)
    }
  }

  const btns_search = () => {
    const btns = []
    btns.push({ caption: 'Search Google Sheets Rankings', oc: handleGoogleSearch_rankings, color: 'blue', icon: 'search', fl: true })
    btns.push({ caption: 'Open Google Sheets', oc: handleOpenGoogleSheets, color: 'orange', icon: appIconTypes.externalAlternate, fl: true })
    return btns
  }

  const footer = () => {

    const btns = []
    const btns_s = btns_search()

    let _allBtns;

    _allBtns = [...btns_s]
    const _updateDisabled_rankings = !rankings_info
    btns.push({ caption: 'Update Rankings', oc: handleConfirmation_rankings, color: _updateStatus_rankings ? 'blue' : 'olive', icon: 'save', disabled: _updateDisabled_rankings })

    return <div className={'header-flex'}>
      <div>
        <DropdownOptionsMenu menuButtons={_allBtns} caption={'Options'} icon={'list ul'} color={'blue'} />
      </div>
      <div>
        <UiSaveButtons
          others={btns}
          color={'blue'}
        />
      </div>
    </div>
  }

  let cn = 'match-data-results'
  if (appFormItems && !fromApp) { cn += ' frapp' }

  const content_right = () => {
    const btns = []
    const { rankings: jsonDataa } = rankings_info_data ?? {}
    const headerCaption = 'Update Rankings?'
    const wrapperContent = <JsonViewer json={jsonDataa} name={'rankingUpdates'} />
    btns.push({ caption: 'Update Rankings', oc: handleUpdate_rankings, icon: 'save' })
    return <Wrapper
      header={headerCaption}
      content={wrapperContent}
      footer={btns && btns.length > 0 && <UiSaveButtons others={btns} />}
      wrapperType={wrapperTypes.paddedHeaderAndFooter}
      updating={updating}
    />
  }

  const content_rankings = () => <SportsRankings rankings_info={rankings_info} rankings_info_data={rankings_info_data} />
  const sidebar_right = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && content_right()} onHide={handleMode_right} />
  const content = () => rankings_info ? content_rankings() : <div></div>

  const wrapper = () => <Wrapper
    content={content()}
    footer={footer()}
    confirmation={confirmation}
    wrapperType={wrapperTypes.padded}
    updating={updating}
    css={{ container: 'season-rankings app-dark-mode' }}
  />

  const sidebar_full = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(mode_right ? true : false)}
    <Sidebar.Pusher dimmed={mode_right ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar_full()}
    topperCaption={_.startCase(matchUpdateType)}
    handleCancel={handleCancel}
  />

  return forSportsManagement ? sidebar_full() : fullPageWrapper()
}

export default SeasonRankingsUpdate 