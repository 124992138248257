import { getFirestore } from 'firebase/firestore';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Label, List, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext';
import { getObjectLength } from '../../../../global/common/convert';
import { uniqueKey } from '../../../../global/common/keys';
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons';
import { appViewerColors } from '../../../../global/components/viewers/AppDashboard';
import { appIconTypes } from '../../../../global/enums/appIconTypes';
import { gEnums } from '../../../../global/enums/globalEnums';
import { iconColorTypes } from '../../../../global/enums/settingsIconTypes';
import { fsfn_transfer } from '../../../../global/functions/fbTransfer';
import { openExternal } from '../../../../global/viewSettings/helpers/settingsLinks';
import FullPageWrapper from '../../../../global/wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper';
import { fbAppConfigs } from '../../../../project/appConfigs';
import { SportsDistrictContext } from '../../cnr/contexts/SportsDistrictContext';
import { _districtDashboardTypes } from '../../cnr/reducers/SportsDistrictReducer';
import { gameScoringTypes } from '../../cnr/reducers/SportsSeasonReducer';
import { matchCalcs } from '../../helpers/match';
import SportsDataSidebar, { sportAccessTypes } from '../SportsDataSidebar';
import { _resultsProp } from '../../cnr/contexts/SportsSeasonContext';

const _schoolContentTypes = {
  accessTeam: 'accessTeam',
}

const ValidateSports = (props) => {

  const _asat = gEnums.availableSubActionTypes
  const { fromDashboard, handleCancel } = props

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state } = states ?? {}
  const { pathViews } = paps_state ?? {}

  // sportsDistrictContext
  const sportsDistrictContext = useContext(SportsDistrictContext)
  const { sportsDistrict_state, sportsDistrict_handlers } = sportsDistrictContext ?? {}
  const { districtData, gls_district } = sportsDistrict_state ?? {}
  const { googleSheetsId } = gls_district ?? {}

  const {
    _teams: teamsSeasonal,
    groupedData,
    schools,
    sports,
    teams,
  } = districtData ?? {}

  const {
    matches_season,
    teams_bySchool,
    teams_bySport,
    teams_seasonal
  } = groupedData ?? {}

  const sportsMode = {
    organization: pathViews && pathViews.organizations && !pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    district: pathViews && pathViews.districts && !pathViews.schools && !pathViews.matches && !pathViews.sports && !pathViews.teams ? true : false,
    match: pathViews && pathViews.matches ? true : false,
    school: pathViews && pathViews.schools ? true : false,
    sport: pathViews && pathViews.sports ? true : false,
    team: false,
  }

  const [allItems, setAllItems] = useState({})
  const [showItemOptions, setShowItemOptions] = useState()
  const [currentItemKey, setCurrentItemKey] = useState(_districtDashboardTypes.menu)
  const [selectedSportItem, setSelectedSportItem] = useState()
  const [selectedYear, setSelectedYear] = useState()
  const [mergeItems, setMergeItems] = useState({})
  const [teamSearchName, setTeamSearchName] = useState()
  const [teamChangeName, setTeamChangeName] = useState()
  const [useBackup, setUseBackup] = useState()
  const [teamYears, setTeamYears] = useState()
  const [yearMinMax, setYearMinMax] = useState()

  let _wrapperType;

  switch (currentItemKey) {
    default:
      _wrapperType = wrapperTypes.padded
  }

  const itemValue = (index, header, value) => {
    switch (header) {
      case 'id':
        return index + 1
      default:
        const hv = value && !_.isObject(value) && !_.isArray(value) ? value : ''
        switch (header) {
          case 'section':
            return hv.replace('section', '')
          default:
            return hv
        }
    }
  }

  const getGoogleSheetDataValues = (data, headers, name) => {
    const _headers = headers.map(h => (_.startCase(h)))
    // Convert the match objects to a 2D array of values
    const values = [_headers, ...data.map((obj, index) => headers.map(
      header => itemValue(index, header, obj[header])))
    ];
    return {
      values,
      tabName: useBackup ? name + ' Backup' : name
    }
  }

  const handleOpenGoogleSheets = () => openExternal.googleSheets(googleSheetsId)

  const handleExportToGoogle = () => {

    let headers;
    let data;
    let tn;

    switch (currentItemKey) {
      case _districtDashboardTypes.schools:
        headers = ['id', '_itemKey', 'name', 'nickname', 'color', 'webSite', 'address', 'city', 'state', 'zipCode', 'phoneNumber']
        data = _.sortBy(schools, 'name')
        tn = gls_district.schoolsSheet
        break;

      case _districtDashboardTypes.teams_bySport:
        headers = ['id', '_itemKey', 'name', 'sport']
        if (yearMinMax) {
          for (var y = yearMinMax.yearMin; y <= yearMinMax.yearMax; y++) {
            headers.push(y + ' Level')
            headers.push(y + ' Section')
          }
        }
        const teams = teams_bySport && selectedSportItem && teams_bySport[selectedSportItem._itemKey] ? teams_bySport[selectedSportItem._itemKey].teams : null

        data = _.sortBy(teams, 'name')

        if (teamYears) {
          data.forEach(t => {
            const teamYear = teamYears[t._itemKey]
            const { years } = teamYear ?? {}
            if (years && !_.isEmpty(years)) {
              Object.keys(years).forEach(yk => {
                const year = years[yk]
                if (year.levels && year.sections) {
                  t[yk + ' Level'] = year.levels
                  t[yk + ' Section'] = year.sections
                } else {
                  console.log('No years', t.name)
                }
              })
            }
          })
        }
        tn = selectedSportItem.name
        break;
      default:
      // nothing
    }

    if (headers && data && googleSheetsId && tn) {
      const { values, tabName } = getGoogleSheetDataValues(data, headers, tn)
      console.log('values', values)
      console.log('googleSheetsId', googleSheetsId, tabName)
      // fsfn_sheets.updateGoogleSheetValues(googleSheetsId, tabName, values)
    }

  }

  const exportToTs = () => {
    const _config_alt = fbAppConfigs['thumbstat']['dev']
    const { projectId } = _config_alt ?? {}
    const fs_current = getFirestore()
    // const fs_destination = getFirestore(_init_alt) 
    const fss = {
      source: fs_current,
      // destination: fs_destination
    }
    const projectIds = {
      source: fs_current._databaseId && fs_current._databaseId.projectId ? fss.source._databaseId.projectId : null,
      destination: projectId
    }
    const paths = {
      sourceRootPath: 'clients/b4C6wHP0A5AgQPTD78ue/events/pojoSports',
      destinationRootPath: 'clients/pojo/events/thumbstat'
    }

    fsfn_transfer.transferDirect_ts(projectIds, true, paths)
  }


  const handleSelectedSport = (e, value) => {
    e.stopPropagation()
    value === selectedSportItem ? setSelectedSportItem() : setSelectedSportItem(value)
  }

  const handleSelectedYear = (e, value) => {
    e.stopPropagation()
    value === selectedYear ? setSelectedYear() : setSelectedYear(value)
  }

  useEffect(() => {
    sportsDistrict_handlers.handleGet_districtData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mergeItems && getObjectLength(mergeItems) === 2) {
      sportsDistrict_handlers.handleGet_alternateTeamsAndMatches(mergeItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeItems]);

  useEffect(() => {
    let _contentTypes = {}
    const items = {}
    if (sportsMode.district) {
      _contentTypes = _districtDashboardTypes
    } else if (sportsMode.school) {
      _contentTypes = _schoolContentTypes
    }
    Object.keys(_contentTypes).forEach(ict => {
      items[ict] = { name: _.startCase(ict), _itemKey: ict }
    })
    const _allItems = { ...items }
    setAllItems(_allItems)
    if (_allItems && Object.keys(_allItems).length === 1) {
      setCurrentItemKey(Object.keys(_allItems)[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);


  useEffect(() => {
    if (selectedSportItem) {
      if (teams_seasonal) {
        const teams_ss = {}
        let yearMin = 10000
        let yearMax = 0
        const _teams_seasonal = _.filter(teams_seasonal, item => item.parentKeys.sports === selectedSportItem._itemKey);
        const _teams_bySport = _.find(teams_bySport, item => item._itemKey === selectedSportItem._itemKey);
        const { teams } = _teams_bySport ?? {}
        if (_teams_seasonal && teams) {
          teams.forEach(team => {
            const { _itemKey, name } = team
            if (!teams_ss[_itemKey]) { teams_ss[_itemKey] = { name } }
            _teams_seasonal.forEach(ts => {
              const { teams: teams_s, parentKeys } = ts ?? {}
              if (teams_s && teams_s[_itemKey] && parentKeys) {
                const { sportsYears } = parentKeys ?? {}
                const team_s = teams_s[_itemKey]
                if (team_s) {
                  if (sportsYears < yearMin) { yearMin = sportsYears }
                  if (sportsYears > yearMax) { yearMax = sportsYears }
                  if (!teams_ss[_itemKey].years) { teams_ss[_itemKey].years = {} }
                  teams_ss[_itemKey].years[sportsYears] = {
                    levels: team_s.levels,
                    sections: team_s.sections,
                  }
                }
              }
            })

          })
        }
        setTeamYears(teams_ss)
        setYearMinMax({ yearMin, yearMax })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSportItem && selectedSportItem._itemKey]);

  const handleItemSelect = (key) => {
    setShowItemOptions()
    setCurrentItemKey(key)
  }

  const handleBackup = () => sportsDistrict_handlers.handleBackup(currentItemKey, selectedSportItem, selectedYear)
  const handleBackupPlayoff = () => sportsDistrict_handlers.handleBackup(currentItemKey, selectedSportItem, selectedYear, true)
  const handleCreateSeasonal = () => sportsDistrict_handlers.handleCreate_seasonal()
  const handleShowItemOptions = () => setShowItemOptions(!showItemOptions)
  const handleSplit = () => sportsDistrict_handlers.handleSplitMatchesByLevel()

  const handleTeamSearchName = (e, data) => setTeamSearchName(data.value)
  const handleTeamChangeName = (e, data) => setTeamChangeName(data.value)

  const handleUseBackup = () => setUseBackup(!useBackup)

  const handleTeamSearch = () => {
    const _mergeItems = {
      1: { name: teamSearchName },
      2: { name: teamChangeName },
    }
    sportsDistrict_handlers.handleGet_alternateTeamsAndMatches(_mergeItems)
  }

  const optionsMenu = () => {
    const mis = []
    const _items = _.sortBy(allItems, 'name')
    _items.forEach(item => {
      let count = -1
      switch (item._itemKey) {
        case _districtDashboardTypes.schools:
          count = getObjectLength(schools)
          break;
        case _districtDashboardTypes.sports:
          count = getObjectLength(sports)
          break;
        case _districtDashboardTypes.teams_bySchool:
          count = getObjectLength(teams_bySchool)
          break;
        case _districtDashboardTypes.teams_bySport:
          count = getObjectLength(teams_bySport)
          break;
        default:
        // nothing
      }
      mis.push(<Menu.Item
        key={uniqueKey('dv', 'mi', item._itemKey)}
        onClick={() => { handleItemSelect(item._itemKey) }}
      >
        {_.startCase(item.name)}
        {count >= 0 && <Label>{count}</Label>}
      </Menu.Item>)
    })
    return <Segment basic><Menu vertical fluid inverted className={'av-menu vs'}>{mis}</Menu></Segment>
  }

  const OptionsSidebar = ({ visible }) => (
    <Sidebar
      as={Segment}
      animation={'overlay'}
      direction={'left'}
      icon='labeled'
      vertical
      visible={visible}
      width='very wide'
      className='vsl-container'
      onHide={() => handleShowItemOptions()}
    >
      {allItems && optionsMenu()}
    </Sidebar>
  )

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    <OptionsSidebar visible={showItemOptions ? true : false} />
    <Sidebar.Pusher dimmed={showItemOptions ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>


  const itemOptionsButton = () => {
    const btns = [
      { caption: _.startCase(currentItemKey), oc: handleShowItemOptions, icon: 'content' },
    ]
    return <UiSaveButtons
      others={btns}
      color={appViewerColors.dmv}
      rows={1}
    />
  }

  const list = (listData) => {
    const listItems = []
    const dataItems = _.sortBy(listData, 'name')
    dataItems.forEach(dataItem => {
      const { name } = dataItem
      listItems.push(<List.Item >
        <List.Icon name='star' color={'blue'} />
        <List.Content>
          <List.Header>{name}</List.Header>
        </List.Content>
      </List.Item>)
    })
    return <List>{listItems}</List>
  }

  const meta_years = (yearItem) => {
    if (yearItem) {
      const { years } = yearItem ?? {}
      let text = ''
      if (years) {
        Object.keys(years).forEach(yk => {
          const year = years[yk]
          text += yk + ': ' + year.levels + '/' + year.sections + '\n'
        })
      }
      return <Card.Description>{text.split('\n').map((line, index) => (
        <p key={index}>{line}</p>
      ))}</Card.Description>
    }
  }

  const card = (header, meta, descriptions, yearItem) => < Card >
    <Card.Content>
      {header && <Card.Header>{header}</Card.Header>}
      {meta && <Card.Meta>{meta}</Card.Meta>}
      {descriptions && descriptions.map(d => <Card.Meta>{d}</Card.Meta>)}
      {yearItem && meta_years(yearItem)}
    </Card.Content>
  </Card >

  const teamCards = (teams) => {
    switch (currentItemKey) {

      case _districtDashboardTypes.teams_bySchool:
        return Object.keys(teams).map(tk => (card(_.startCase(teams[tk].sport), teams[tk]._itemKey)))

      case _districtDashboardTypes.teams_bySport:
        const _teams = _.sortBy(teams, 'name')
        return _teams.map(team => {
          let yearItem;
          if (teamYears && teamYears[team._itemKey]) {
            yearItem = teamYears[team._itemKey]
          }
          return card(team.name, team._itemKey, null, yearItem)
        })
      default:
      // nothing
    }
  }

  const teamList = (listData) => {
    const listItems = []
    const dataItems = _.sortBy(listData, 'name')
    dataItems.forEach(item => {
      const { name, teams, _itemKey } = item
      const selected = selectedSportItem && selectedSportItem._itemKey === _itemKey
      listItems.push(<List.Item onClick={(e) => { handleSelectedSport(e, item) }}>
        <List.Icon name='list' color={selected ? 'blue' : 'grey'} />
        <List.Content>
          <List.Header>{_.startCase(name)}</List.Header>
          {selected && <List.List>
            <Card.Group>
              {teamCards(teams)}
            </Card.Group>
          </List.List>}
        </List.Content>
      </List.Item>)
    })
    return <List>{listItems}</List>
  }

  const matchItems = (matches) => {
    const matchItems = []
    if (matches) {
      Object.keys(matches).forEach(mk => {
        const match = matches[mk]
        matchCalcs.calcVballMatch(match, gameScoringTypes.match)
        const { away, home, _status } = match ?? {}
        const { matchName } = _status ?? {}
        const results = match ? match[_resultsProp] : undefined;
        const { scoreDisplays } = results ?? {}
        const { normal } = scoreDisplays ?? {}
        if (home && away) {
          matchItems.push(card(matchName, match.startDate, [normal]))
        }
      })
    }
    return matchItems
  }

  const listMatchesAll = () => {

    const sportItems = []

    Object.keys(matches_season).forEach(k => {

      const sport = matches_season[k]
      const { name, years } = sport ?? {}

      const yearItems = []

      if (years) {
        Object.keys(years).forEach(yk => {
          const year = years ? years[yk] : {}
          const { matches } = year ?? {}
          yearItems.push(
            <List.Item onClick={(e) => { handleSelectedYear(e, yk) }} >
              <List.Icon name='calendar alternate outline' color={'blue'} />
              <List.Content>
                <List.Header>{yk}</List.Header>
                <List.List><Card.Group>{selectedYear === yk ? matchItems(matches) : <div></div>} </Card.Group></List.List>
              </List.Content>
            </List.Item>
          )
        })
      }

      sportItems.push(
        <List.Item onClick={(e) => { handleSelectedSport(e, sport) }} >
          <List.Icon name='list' color={'blue'} />
          <List.Content>
            <List.Header>{name}</List.Header>
            <List.List className={selectedSportItem !== k ? 'hdn' : null} >{yearItems}</List.List>
          </List.Content>
        </List.Item>
      )

    })
    return <List>{sportItems}</List>
  }

  const content = () => {
    switch (currentItemKey) {
      case _districtDashboardTypes.menu:
        return <Segment basic>Please select a menu item</Segment>
      case _districtDashboardTypes.schools:
        return list(schools)
      case _districtDashboardTypes.teams:
        return list(teams)
      case _districtDashboardTypes.sports:
        return list(sports)
      case _districtDashboardTypes.teamsSeason:
        return list(teamsSeasonal)
      case _districtDashboardTypes.matchesBySport:
        return listMatchesAll()
      case _districtDashboardTypes.teams_bySchool:
        return teamList(teams_bySchool)
      case _districtDashboardTypes.teams_bySport:
        return teamList(teams_bySport)
      case _schoolContentTypes.accessTeam:
        return <SportsDataSidebar sportAccessType={sportAccessTypes.sport} handleCancel={setCurrentItemKey} forSportsManagement={true} subActionType={_asat.updateSchoolSports} />
      default:
        return <div>Menu</div>
    }
  }

  const header = () => <div className={'header-flex'}>
    {itemOptionsButton()}
  </div>

  const saveButtons = () => {
    const btns = []
    switch (currentItemKey) {
      case _districtDashboardTypes.matchesBySport:
        if (selectedSportItem && selectedYear) {
          btns.push({ oc: handleBackup, caption: 'Backup Seasonal Matches (' + selectedSportItem + '/' + selectedYear + ')', icon: 'save' })
        } else {
          btns.push({ oc: handleBackup, caption: 'Backup Seasonal Matches', icon: 'save' })
          btns.push({ oc: handleBackupPlayoff, caption: 'Backup Seasonal Playoff Matches', icon: 'save' })
          btns.push({ oc: handleSplit, caption: 'Split Matches By Level', icon: 'save' })
          btns.push({ oc: handleCreateSeasonal, caption: 'Create Matches Seasonal', icon: 'save' })
        }
        break;

      case _districtDashboardTypes.schools:
        btns.push({ caption: 'Open GS (' + googleSheetsId + ')', oc: handleOpenGoogleSheets, icon: appIconTypes.externalAlternate, color: iconColorTypes.external, disabled: !googleSheetsId, fl: true })
        btns.push({ oc: exportToTs, caption: 'Download ' + _.startCase(currentItemKey) + ' to TS', icon: 'save' })
        btns.push({ oc: handleExportToGoogle, caption: 'Download ' + _.startCase(currentItemKey) + ' to GS', icon: 'save' })
        btns.push({ oc: handleBackup, caption: 'Backup ' + _.startCase(currentItemKey), icon: 'save' })
        btns.push({ oc: handleUseBackup, caption: 'Backup', icon: 'check', color: (useBackup ? 'green' : 'grey') })
        break;

      case _districtDashboardTypes.teams_bySport:
        btns.push({ caption: 'Open GS (' + googleSheetsId + ')', oc: handleOpenGoogleSheets, icon: appIconTypes.externalAlternate, color: iconColorTypes.external, disabled: !googleSheetsId, fl: true })
        btns.push({ oc: handleExportToGoogle, caption: 'Download ' + _.startCase(currentItemKey) + ' to GS', icon: 'save', disabled: !selectedSportItem })
        btns.push({ oc: handleUseBackup, caption: 'Backup', icon: 'check', color: (useBackup ? 'green' : 'grey') })
        break;
      default:
    }
    return btns
  }

  const footer = () => {
    const btns = saveButtons()
    return btns.length > 0 ? <UiSaveButtons
      others={saveButtons()}
    /> : null
  }

  const wrapper = () => <Wrapper
    header={header()}
    content={content()}
    footer={footer()}
    wrapperType={_wrapperType}
    css={{ header: 'bcg' }}
    updating={!districtData}
  />

  const fullPageWrapper = () => <FullPageWrapper
    content={sidebar()}
    topperCaption={'Google Link'}
    handleCancel={handleCancel}
  />

  return fromDashboard ? sidebar() : fullPageWrapper()

}

export default ValidateSports