import _ from 'lodash';
import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { gEnums } from '../../../enums/globalEnums';
import { allPageTypes } from '../../../enums/allPageTypes';

export const allowedItemsMain = (props) => {

  const { parentContext, framework_state, gridPageItems, gcProps, hidden } = props

  const { states, fns, settings } = parentContext ?? {}

  const { homeSettings } = settings
  const { appUser_state, page_state, paps_state, questionsAndAnswers_state } = states
  const { page_fns, storage_fns } = fns

  const { questionsAndAnswersActive } = questionsAndAnswers_state ?? {}

  // papsContext 
  const { pathViews } = paps_state ?? {}

  // appUserContext 
  const { appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)
  const { appUserAccessType, appUserSessionKey, events: events_appUser } = appUserAccess ?? {}
  const currentAppUserEvent = events_appUser && pathViews.events ? events_appUser[pathViews.events] : null
  const { appUserKey } = currentAppUserEvent ? currentAppUserEvent : {}
  const _appUserSessionKey = appUserKey ? appUserKey : appUserSessionKey

  // frameworkContext  
  const { desktopMode, platform } = framework_state ?? {}
  const { isMobileDevice } = platform ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_appUserSettings } = pageSettings ?? {}

  const { global: global_home } = homeSettings ?? {}
  const { appUrls: homeAppUrls } = global_home ?? {}
  const { appUrls: appUrlz } = homeAppUrls ?? {}

  const weatherLink = page_fns.getLink(appUrlz, 'weather', isMobileDevice)

  const { eventOptions, clientOptions, appGallery, appQuestionsAndAnswers } = aps_global ?? {}
  const { allowGallery, galleryCaption, galleryIcon } = appGallery ? appGallery : {}
  const { allowQuestionsAndAnswers, showOnLandingPage, landingPageLocation, qAndACaption, useIconImage: useIconImage_qa, iconImage: iconImage_qa } = appQuestionsAndAnswers ?? {}

  const _appOptions = { ...clientOptions, ...eventOptions }
  let { showLyft, showLyftOnHomeScreen, showUber, showUberOnHomeScreen, showWeatherOnHomeScreen, showWeather, weatherZip } = _appOptions ?? {}
  const { appUserCollection, showMyProfileLinkOnHomeScreen, myProfileCaption, myProfileIcon, myProfileIconLocation, allowNonEventAppUsers } = aps_appUserSettings ?? {}
  const { useIconImage, iconImage } = aps_appUserSettings

  let _allowQandA;
  let allowQandA = questionsAndAnswersActive && questionsAndAnswersActive.length > 0
  _allowQandA = allowQuestionsAndAnswers && showOnLandingPage && allowQandA && landingPageLocation !== gEnums.landingPageLocations.appUser

  let _allowMyProfileIcon;

  if (showMyProfileLinkOnHomeScreen) {
    switch (appUserAccessType) {
      case gEnums.appUserAccessTypes.appAdmin:
      case gEnums.appUserAccessTypes.appDataAdmin:
      case gEnums.appUserAccessTypes.appDataAdminLimited:
      case gEnums.appUserAccessTypes.appProfile:
      case gEnums.appUserAccessTypes.appSubAdmin:
        if (_appUserSessionKey || allowNonEventAppUsers) {
          _allowMyProfileIcon = true
        }
        break;
      default:
      // nothing
    }
  }

  // const gridItem = (viewItem, segProps, gcProps) => gridItemIconGroup(viewItem, segProps, gcProps, hidden)

  const allowedItem = (viewItem, segProps, gcProps, hidden) => {
    const { key } = viewItem ?? {}
    return { viewItem, itemKey: key, segProps, gcProps, hidden }
  }

  const myProfileGridItem = (items) => {

    const iconImageUrls = storage_fns.getIconUrl(useIconImage, iconImage)

    const giProps = {
      caption: myProfileCaption ? myProfileCaption : _.startCase('myProfile'),
      icon: myProfileIcon ? myProfileIcon : 'user',
      cloned: true,
      iconImageUrls,
    }

    if (appUserAccess && pathViews.events && appUserAccess.events && appUserAccess.events[pathViews.events] && appUserAccess.accessLevel > gEnums.accessLevels.guest.value) {
      switch (myProfileIconLocation) {
        case gEnums.iconLocations.last:
          items.push(allowedItem({ key: appUserCollection, itemKey: _appUserSessionKey }, giProps, gcProps))
          break;
        default:
          items.unshift(allowedItem({ key: appUserCollection, itemKey: _appUserSessionKey }, giProps, gcProps))
      }
    } else {
      items.unshift(allowedItem({ key: appUserCollection, itemKey: _appUserSessionKey }, giProps, gcProps))
    }
  }

  // const favoritesGridItem = (items) => {
  //   const giProps = {
  //     caption: _.startCase('favorites'),
  //     icon: 'star outline',
  //   }
  //   items.push(allowedItem({ key: 'favorites', itemKey: _appUserSessionKey }, giProps, gcProps))
  // }

  const items = []
  const altItems = []

  // for each viewItem, create an element and view
  if (gridPageItems) {
    _.each(gridPageItems, (viewItem, vit) => {
      const { key } = viewItem
      switch (key) {
        case 'events':
          // do';t add
          break;
        default:
          const segProps = page_fns.getGVIProps(vit, viewItem, storage_fns, desktopMode)
          items.push(allowedItem(viewItem, segProps, gcProps, hidden))
      }
    })
  }

  if (_allowQandA) {
    const iconImageUrls_qa = storage_fns.getIconUrl(useIconImage_qa, iconImage_qa)
    if (landingPageLocation === gEnums.landingPageLocations.normalFirst) {
      items.unshift(allowedItem({ key: allPageTypes.qAndA }, { caption: qAndACaption ? qAndACaption : 'Q&A', icon: 'question circle', iconImageUrls: iconImageUrls_qa }, gcProps))
    } else {
      items.push(allowedItem({ key: allPageTypes.qAndA }, { caption: qAndACaption ? qAndACaption : 'Q&A', icon: 'question circle', iconImageUrls: iconImageUrls_qa }, gcProps))
    }
  }

  if (_allowMyProfileIcon) { myProfileGridItem(items) }

  if (allowGallery) { items.push(allowedItem({ key: 'gallery' }, { caption: galleryCaption ? galleryCaption : 'Gallery', icon: galleryIcon ? galleryIcon : 'images outline' }, gcProps)) }

  if (showLyft && showLyftOnHomeScreen) { altItems.push(allowedItem({ key: 'lyft' }, { caption: 'Lyft', icon: 'lyft' }, gcProps)) }
  if (showUber && showUberOnHomeScreen) { altItems.push(allowedItem({ key: 'uber' }, { caption: 'Uber', icon: 'uber' }, gcProps)) }
  if (showWeather && showWeatherOnHomeScreen && weatherLink && weatherZip) { altItems.push(allowedItem({ key: 'weather' }, { caption: 'Weather', icon: 'cloud' }, gcProps)) }

  return [...items, ...altItems]

}
