import _ from 'lodash';
import React from 'react';
import { Label, Menu } from 'semantic-ui-react';
import { sortObject } from '../../common/sorting';
import { uniqueKey } from '../../common/keys';

const rts = {
  handleAmmend_draggableIds: 'handleAmmend_draggableIds',
  handleDrag_end: 'handleDrag_end',
  handleSet_dndColumn: 'handleSet_dndColumn',
  handleSet_dndGroups: 'handleSet_dndGroups',
  handleSet_sorted: 'handleSet_sorted',
  handleSet_sortedAlpha: 'handleSet_sortedAlpha',
  handleCreate_dndGroups: 'handleCreate_dndGroups',
}

export const dragNDropReducer = (state, action) => {

  const { dispatch, type } = action
  const { dndColumns, dndGroups, ddKey, isLabelSort, groupChangeKey } = state
  const _dndColumns = { ...dndColumns }

  const { handleSet_sorted } = dragNDropHandlers(dispatch)

  switch (type) {

    case rts.handleAmmend_draggableIds:
      const _draggableIds = [...state.draggableIds]
      _draggableIds.push(action.draggableId)
      return { ...state, draggableIds: _draggableIds }

    case rts.handleDrag_end:
      const _keys = state.draggableIds
      const uniqueKeys = _.uniq(_keys);
      const duplicates = _.difference(_keys, uniqueKeys);

      if (duplicates.length > 0) {
        console.log('duplicates', duplicates)
      }
      handleDragEnd(action.result, dndColumns, dndGroups, ddKey, isLabelSort, groupChangeKey, handleSet_sorted)
      return { ...state }

    case rts.handleSet_sorted:
      return { ...state, dndProps: action.dndProps }

    case rts.handleSet_sortedAlpha:
      _dndColumns[action.opts.columnKey] = action.dndColumn
      return { ...state, dndColumns: _dndColumns }

    case rts.handleSet_dndColumn:
      _dndColumns[action.name] = action.ddColumn
      return { ...state, dndColumns: _dndColumns }

    case rts.handleSet_dndGroups:
      return { ...state, dndGroups: action.dndGroups }

    case rts.handleCreate_dndGroups:
      const x = createDdGroup(action.dcProps)
      _dndColumns[action.dcProps.ddKey] = x
      return { ...state, dndColumns: _dndColumns, drg: x }

    default:
      return { ...state }
  }
}

export const dragNDropInitialState = (initState) => {
  return { ...initState, dndColumns: {}, draggableIds: [] }
};

export const dragNDropHandlers = (dispatch) => {
  return {
    handleAmmend_draggableIds: (draggableId) => { dispatch({ type: rts.handleAmmend_draggableIds, dispatch, draggableId }) },
    handleDrag_end: (result, groupKey) => { dispatch({ type: rts.handleDrag_end, dispatch, result, groupKey }) },
    handleSet_dndColumn: (name, ddColumn) => { dispatch({ type: rts.handleSet_dndColumn, dispatch, name, ddColumn }) },
    handleSet_dndGroups: (dndGroups) => { dispatch({ type: rts.handleSet_dndGroups, dispatch, dndGroups }) },
    handleSet_sorted: (dndProps) => { dispatch({ type: rts.handleSet_sorted, dispatch, dndProps }) },
    handleSet_sortedAlpha: (dndColumn, opts) => { dispatch({ type: rts.handleSet_sortedAlpha, dispatch, dndColumn, opts }) },
    handleCreate_dndGroups: (dcProps) => { dispatch({ type: rts.handleCreate_dndGroups, dispatch, dcProps }) },
  }
}

const createDdGroup = (dcProps) => {

  const { menuItems, ddKey, showCount } = dcProps
  const elements = menuMenuItems(menuItems, showCount)
  const _ddGroups = {}
  _ddGroups[ddKey] = { elements, dataItems: menuItems }

  return {
    allowSort: true,
    columnKey: ddKey,
    dndGroups: sortObject(_ddGroups, 'key'),
    columnName: ddKey,
  }
}

const handleDragEndish = (result, dndColumns, dndGroups, ddKey, isLabelSort, groupChangeKey, callback) => {

}

const handleDragEnd = (result, dndColumns, dndGroups, ddKey, isLabelSort, groupChangeKey, callback) => {

  // fixed from dnd
  const { source, destination } = result;

  if (dndColumns) {

    // each will have: columnKey, groupKey, dndGroup
    const dest_props = destination ? getDrps(destination, dndColumns, dndGroups) : null
    const source_props = source ? getDrps(source, dndColumns, dndGroups) : null

    // dropped outside the list
    if (!destination) { return; }

    // containerKey is the columnKey-groupKey 
    if (dest_props && source_props) {

      const _compares = {
        isSameContainer: dest_props.containerKey === source_props.containerKey,
        isSameGroup: dest_props.groupKey === source_props.groupKey,
        isSameColumn: dest_props.columnKey === source_props.columnKey
      }

      console.log('_compares', _compares)

      if (_compares.isSameContainer) {
        // dest and source are the same, reorder only
        const reorderedItems = reorder(
          dest_props.dndGroup,
          source_props.itemPosition,
          dest_props.itemPosition,
        );
        if (isLabelSort) {
          const roi = _.sortBy(reorderedItems, 'position')
          dest_props.dndGroup.dataItems = roi
        } else {
          // dest_props.dndGroup = reorderedItems
          dest_props.dndGroup.dataItems = reorderedItems
        }
        const dndProps = { dndColumns, dest_props, source_props }
        callback && callback(dndProps)
      } else {
        if (_compares.isSameColumn) {
          moveGroup(source_props.dndGroup, dest_props.dndGroup, source_props, dest_props, true, groupChangeKey)
          const dndProps = { dndColumns, dest_props, source_props }
          callback && callback(dndProps)
        } else {
          moveGroup(source_props.dndGroup, dest_props.dndGroup, source_props, dest_props, true, groupChangeKey)
          const dndProps = { dndColumns, dest_props, source_props }
          callback && callback(dndProps)
        }
      }
    } else {
      console.log('source_props', source_props)
      console.log('dest_props', dest_props)
    }
  } else {
    if (dndGroups) {
      const destinationGroup = dndGroups[destination.droppableId]
      const sourceGroup = dndGroups[source.droppableId]

      if (source.droppableId === destination.droppableId) {
        const reorderedItems = reorder(
          destinationGroup,
          source.itemPosition,
          destination.itemPosition
        );
        if (isLabelSort) {
          const roi = _.sortBy(reorderedItems, 'position')
          dndGroups[destination.droppableId].dataItems = roi
        } else {
          dndGroups[destination.droppableId].dataItems = reorderedItems
        }
        const dndProps = {
          dndGroups,
          ddKey
        }
        callback && callback(dndProps)
      } else {
        moveGroup(sourceGroup, destinationGroup, source, destination, true, groupChangeKey)
        dndGroups[destination.droppableId] = destinationGroup
        dndGroups[source.droppableId] = sourceGroup
        const dndProps = {
          dndGroups
        }
        callback && callback(dndProps)
      }
    }
  }
}

/**
 * splits the droppableId of the obj by ('_')
 * add a dndGroup to the returned dndGroup
 * @param {object} obj 
 * @param {object} dndColumns 
 * @param {object} dndGroups 
 * @returns 
 */
const getDrps = (obj, dndColumns, dndGroups) => {
  const { droppableId, index } = obj ? obj : {}
  const droppableSplit = droppableId.split('_')
  const prps = {
    itemPosition: index,
    containerKey: droppableId,
    columnKey: droppableSplit.length === 2 ? droppableSplit[0] : droppableId,
    groupKey: droppableSplit.length === 2 ? droppableSplit[1] : droppableId,
    droppableId,
  }
  if (dndColumns && Object.keys(dndColumns).length > 0) {
    if (dndColumns[prps.columnKey] && dndColumns[prps.columnKey].dndGroups && dndColumns[prps.columnKey].dndGroups[prps.groupKey]) {
      prps.dndGroup = dndColumns[prps.columnKey].dndGroups[prps.groupKey]
    }
  } else if (dndGroups) {
    if (dndGroups[prps.groupKey]) {
      prps.dndGroup = dndGroups[prps.groupKey]
    }
  }
  return prps
}

const reorder = (group, sourceIndex, destinationIndex) => {

  // this is an array
  let dataItems = group.dataItems;

  // if (!_.isArray(group.dataItems)) {
  //   dataItems = createArray(group.dataItems, keyProp)
  //   isArray = false
  // } else {
  //   dataItems = group.dataItems
  // }

  if (group.noDrop) { return Array.from(dataItems) }

  const result = _.isArray(dataItems) ? dataItems : Array.from(dataItems);

  // remove the source...
  const [removed] = result.splice(sourceIndex, 1);

  // insert the source/removed at the destinationIndex
  result.splice(destinationIndex, 0, removed);

  // add the position for each of the items in the result
  // NOTE: the position is the GROUP position 
  result.forEach((r, pos) => { r.position = pos })

  return result

  // if (!isArray) {
  //   return creatingHelpers.createObject(result, keyProp)
  // } else {
  //   return result;
  // }
};

const moveGroup = (sourceGroup, destinationGroup, ds, dd, removeMoved, groupChangeKey, positionProp) => {

  // sourceGroup:{
  //   columnKey: '',
  //   dataItems: {},
  //   elements: []
  // }

  if (destinationGroup && sourceGroup) {
    if (destinationGroup.noDrop) { return false }
    if (sourceGroup.noMove && destinationGroup.noMove) { return false }

    // we are looking to change the dataItems from each of the groups

    // get both the source and destination dataItems
    const dataItems = {
      source: Array.from(sourceGroup.dataItems),
      destination: Array.from(destinationGroup.dataItems),
    }

    if (removeMoved) {
      const [removed] = dataItems.source.splice(ds.itemPosition, 1);
      dataItems.destination.splice(dd.itemPosition, 0, removed);
    } else {
      // move the source item into the destination array 
      const sourceItem = dataItems.source[ds.itemPosition]
      dataItems.destination.push(sourceItem);
    }

    // create the dataItems from the cloned data
    sourceGroup.dataItems = dataItems.source;
    destinationGroup.dataItems = dataItems.destination;

    // update the POSITION for each item in the SOURCE
    if (sourceGroup.dataItems && sourceGroup.dataItems.length > 0) {
      sourceGroup.dataItems.forEach((r, pos) => { r.position = pos })
    }

    // update the POSITION for each item in the DESTINATION
    if (destinationGroup.dataItems && destinationGroup.dataItems.length > 0) {
      destinationGroup.dataItems.forEach((r, pos) => {
        if (r) {
          r.position = pos
          if (groupChangeKey) { r[groupChangeKey] = dd.droppableId }
        }
      })
    }
  }


};

const moveGroup_alt = (sourceGroup, destinationGroup, ds, dd, removeMoved, groupChangeKey, positionProp) => {

  if (destinationGroup.noDrop) { return false }
  if (sourceGroup.noMove && destinationGroup.noMove) { return false }

  // get both the source and destination dataItems
  const sourceClone = Array.from(sourceGroup.dataItems);
  const destClone = Array.from(destinationGroup.dataItems);

  if (removeMoved) {
    const [removed] = sourceClone.splice(ds.index, 1);
    destClone.splice(dd.index, 0, removed);
  } else {
    destClone.push(sourceClone[ds.index]);
  }

  // create the dataItems from the cloned data
  sourceGroup.dataItems = sourceClone;
  destinationGroup.dataItems = destClone;

  // add the `positionProp` for each of the items in the result
  if (sourceGroup.dataItems && sourceGroup.dataItems.length > 0) {
    sourceGroup.dataItems.forEach((r, index) => { r[positionProp] = index })
  }

  // add the `positionProp` for each of the items in the result
  if (destinationGroup.dataItems && destinationGroup.dataItems.length > 0) {
    destinationGroup.dataItems.forEach((r, index) => {
      if (r) {
        r[positionProp] = index
        if (groupChangeKey) { r[groupChangeKey] = dd.droppableId }
      }
    })
  }
};

const menuMenuItems = (menuItems, showCount, cn) => {
  const ds = []
  Object.keys(menuItems).forEach((key, index) => {
    const di = menuItems[key]
    const { name } = di ? di : {}
    ds.push(menuItem(cn, name, key, index + 1, showCount))
  })
  return ds
}

const menuItem = (cn, name, key, pos, showCount, di) => <Menu.Item className={cn} key={uniqueKey('spca', key, pos)}>
  {showCount && numberLabel(pos)}
  <div>{name}</div>
  {di && lsLabel(di)}
</Menu.Item>

const numberLabel = (pos, text) => <Label color={'blue'} >{pos}</Label>
const lsLabel = (di) => <Label color={'blue'} >{di.levels}{' : '}{di.sections}</Label>
