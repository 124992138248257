import { currentHelpers } from "../../redirection/current";

export const papsInitialState = () => {
  return {}
};

export const rts = {
  handleAmmend_currentPath: 'handleAmmend_currentPath',
  handleHomePageSeen: 'handleHomePageSeen',
  handleUpdate_messagingSupported: 'handleUpdate_messagingSupported',
  handleWaiverSeen: 'handleWaiverSeen',
}

export const papsReducer = (state, action) => {

  const { type, pathname, previousPathName } = action

  switch (type) {

    case rts.handleAmmend_currentPath:
      const { url } = {}
      const current = currentHelpers.getCurrent(url, pathname)
      return {
        ...current,
        previousPathName: previousPathName,
        updated: true
      }

    case rts.handleHomePageSeen:
      const item_hp = action.eventKey ? 'homePageSeen-' + action.eventKey : 'homePageSeen'
      currentHelpers.storageItem_set(item_hp, true);
      return { ...state }

    case rts.handleWaiverSeen:
      const item_w = action.eventKey ? 'waiverSeen-' + action.eventKey : 'waiverSeen'
      currentHelpers.storageItem_set(item_w, true);
      return { ...state }

    case rts.handleUpdate_messagingSupported:
      return { ...state, messagingSupported: action.messagingSupported }

    default:
      return { ...state }
  }
}

export const papsHandlers = (dispatch) => {
  return {
    handleAmmend_currentPath: (pathname, previousPathName) => { dispatch({ type: rts.handleAmmend_currentPath, dispatch, pathname, previousPathName }) },
    handleHomePageSeen: (eventKey) => { dispatch({ type: rts.handleHomePageSeen, eventKey }) },
    handleWaiverSeen: (eventKey) => { dispatch({ type: rts.handleWaiverSeen, eventKey }) },
    handleUpdate_messagingSupported: (messagingSupported) => { dispatch({ type: rts.handleUpdate_messagingSupported, messagingSupported }) },
  }
}