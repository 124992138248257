import { getParentKeyData_sports } from '../cnr/reducers/reducerHelpers/parentKeySportsData';
import { seasonalHelpers, seasonalUpdateTypes } from '../cnr/reducers/reducerHelpers/seasonalSportsData';

export const updateAllStarTeams = async (sportPermissions, paps_state, latestSeason, actionProps, handleDone) => {

  const { pathViews } = paps_state
  const { data } = actionProps
  const pks = seasonalHelpers.getPks(pathViews, latestSeason)
  // updates `_allStarTeams`
  const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, handleDone, 'allStarTeams', 'athletes', data)
  seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.allStarTeams, _updateProps, 'allStarTeams', 'athletes', data, handleDone)

}

export const updateSportsHistory = async (sportPermissions, paps_state, latestSeason, actionProps, handleDone) => {

  const { pathViews } = paps_state
  const { data } = actionProps
  const pks = seasonalHelpers.getPks(pathViews, latestSeason)
  // updates `_history`
  const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews, pks, handleDone, 'history', 'history', data)
  seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.history, _updateProps)

}

/** Returns a list a team with `levels` and `sections` already attached */
export const getTeamAlignment = (paps_state, sportsKey, latestSeason, viewItem, setTeamAlignment) => {

  const { pathViews } = paps_state
  const { dataSource } = viewItem ?? {}
  const { dataParents, useSeasonals } = dataSource ?? {}

  const handleTeamAlignment = (seasonalItem) => {
    const si = seasonalItem[Object.keys(seasonalItem)[0]]
    setTeamAlignment(si)
  }

  const handleTeams = (teams) => {
    // console.log('teams', teams)
  }

  seasonalHelpers.getSeasonalData(pathViews, sportsKey, latestSeason, handleTeamAlignment)
  getParentKeyData_sports(pathViews, { collectionName: viewItem.key }, dataParents, handleTeams)

  // updateSchoolSports(pathViews)
  // updateGlobals(pathViews)

}

