import { getDoc, getFirestore } from "firebase/firestore";
import _ from "lodash";
import { isMeProd } from '../../../project/appConfiguration';
import { convertSnapshot } from '../../cnr/contexts/contextHelpers';
import { gEnums } from "../../enums/globalEnums";
import { createRefPath } from "../../firestoreData/appData/appRefPaths";
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { ref_get } from "../../firestoreData/appData/fsRefs";

import { defaultSettings } from '../defaults/defaultSettings';

export const rootSettings_collection_sandbox = 'settings_sandbox'
export const rootSettings_collection_backup = 'settings_backup'
export const settings_global_pageItems = 'settings_global_pageItems'
export const _settingsFs = {
  root: 'settings',
  collection: 'settings',
  googleCollection: 'settings_gss'
}

export const _useSettingsSeparation = true
const _allowListen = true

export const getHCEAppSettings = (apsaProps) => {

  const {
    view,
    settingsDocKeys,
    handleAmmend_hceAppSettings,
    pathViews,
    pathname
  } = apsaProps

  const defaultView = view
  let defaultViewItems;
  let defaultViews;

  // get the settings for each of the settingsDocKeys
  getAppSettingsPromise(settingsDocKeys).then(res => {
    if (res) {
      const x = {}
      res.forEach(r => {
        if (!r.empty) {
          const cc = convertSnapshot(r, true, { returnFirstObject: true })
          console.log('cc', cc)
          apsaProps.ignoreAppUpdate = true
          apsaProps.settingsDocName = cc.id
          x[cc.id] = ammendAppSettings(cc, apsaProps, defaultView, defaultViews, defaultViewItems)
        }
      })
      handleAmmend_hceAppSettings(x, pathViews, pathname)
    }
  })
}

/**
 * 
 * @param {object} settingsDocKeys 
 * @returns a promise from the fetching of the setting of each of the settingsDocKeys
 */
export const getAppSettingsPromise = (settingsDocKeys) => {
  const fs = getFirestore()
  const promises = []
  Object.keys(settingsDocKeys).forEach(sdnKey => {
    const sdn = settingsDocKeys[sdnKey]
    const dr = ref_get(fs, [_settingsFs.root, sdn])
    promises.push(getDoc(dr))
  })
  return Promise.all(promises)
}

/**
 * gets the settings data from the settings collection
 * @param {object} apsProps (allowSettingsListen, backupOnly, settingsDocName, userSettingsOnly)
 */
export const getAppSettingsFromDb = (apsProps) => {

  const {
    allowSettingsListen,
    backupOnly,
    settingsDocName,
    userSettingsOnly,
  } = apsProps

  let settingsDocRef;
  let _refPath;

  if (backupOnly) {
    _refPath = createRefPath([rootSettings_collection_backup, settingsDocName])
  } else if (userSettingsOnly) {
    _refPath = createRefPath([rootSettings_collection_sandbox, settingsDocName])
  } else {
    _refPath = createRefPath([_useSettingsSeparation ? _settingsFs.root : _settingsFs.root + '_dev', settingsDocName])
  }

  const _isMeProd = isMeProd()

  // IMPORTANT: Settings - Get the settings from the database for the settingsDocNames  
  const callback = (data) => ammendAppSettings(data, apsProps)

  /**
   * This is the callback for the getSettingsGroup call
   * @param {object} data 
   * @param {string} settingsDocName 
   */
  const callback_new = (cbd) => {
    const { data, status, error } = cbd
    if (!data.global) {
      ammendAppSettings(defaultSettings, apsProps, null, null, null, error)
    } else {
      if (status) { data.status = status }
      ammendAppSettings(data ?? {}, apsProps, null, null, null, error)
    }
  }

  const callback_alt = (cbd) => {
    const { settings, status, error } = cbd
    if (!settings.global) {
      ammendAppSettings(defaultSettings, apsProps, null, null, null, error)
    } else {
      if (status) { settings.status = status }
      ammendAppSettings(settings ?? {}, apsProps, null, null, null, error)
    }
  }

  if (_isMeProd && !allowSettingsListen && 1 === 3) {
    settingsDocRef.get().then(res => {
      if (!res.empty) {
        const cc = convertSnapshot(res, true, { returnFirstObject: true })
        ammendAppSettings(cc, apsProps)
      } else {
        ammendAppSettings({}, apsProps)
      }
    })
  } else {
    if (_useSettingsSeparation) {
      getSettingsAndStatus(settingsDocName, false, _allowListen ? callback_new : callback_alt)
    } else {
      fs_get_data({ refPath: _refPath, callback, opts: { returnFirstObject: true, listen: false } })
    }
  }
}

/**
 * Gets the settings from the database for the `settingsDocName`
 * @param {string} settingsDocName 
 * @param {function} callback 
 */
const getSettingsAndStatus = async (settingsDocName, listen, callback) => {

  const rsc = 'settings'

  const dr_status = createRefPath([rsc, settingsDocName])
  const dr_settings = createRefPath([rsc, settingsDocName, 'settings'])

  const opts_status = { returnFirstObject: true, ignoreId: true }
  const opts_settings = { listen, ignoreId: true, cbo: true }

  if (_allowListen) {
    fs_get_data({ refPath: dr_status, opts: opts_status }).then(status => {
      // data, cbProps, ref, cbData, error
      const _callback = (settings, b, c, d, e) => {
        const s = { ...settings, status }
        callback(s, settingsDocName)
      }
      fs_get_data({ refPath: dr_settings, opts: opts_settings, callback: _callback })
    }).catch(error => {
      console.log('error', error)
    })
  } else {
    try {
      const status = await fs_get_data({ refPath: dr_status, opts: opts_status })
      const settings = await fs_get_data({ refPath: dr_settings, opts: opts_settings })
      const s = { settings, status }
      callback(s, settingsDocName)
    } catch (error) {
      console.log('error', error)
    }
  }
}

/**
* 
* @param {object} docData 
* @param {object} apsProps 
* @param {string} defaultView 
* @param {array} defaultViews 
* @param {array} defaultViewItems 
* @returns The ammended appSettings
*/
const ammendAppSettings = (docData, apsProps, defaultView, defaultViews, defaultViewItems, error) => {

  const {
    manifestIcons,
    pathViews,
    pathname,
    previewOnly,
    settingsDocName,
    settingsDocKey,
    handleAmmend_appSettings,
    userSettingsOnly,
    ignoreAppUpdate,
  } = apsProps

  const { global, views, status } = docData ?? {}
  let { viewItems } = docData ?? {}

  const useDefault = false

  createAppComponents(global)

  const x = {
    global: global ? setFullViewSettings(global, defaultView) : {},
    viewItems: viewItems ?? {},
    views: views ? setFullViewsSettings(views, true) : {},
    missingSettings: global ? false : true,
    status,
    error
  }

  if (useDefault) {
    x.viewItems = defaultViewItems
    x.views = defaultViews
  }

  if (previewOnly) {
    const preview = {
      global: x.global,
      viewItems: x.viewItems,
      views: x.views
    }
    const previewData = {
      projectSettings: {
        global: preview.global,
        viewItems: preview.viewItems,
        views: preview.views
      }
    }
    !ignoreAppUpdate && handleAmmend_appSettings({ type: 'showPreview', previewInfo: previewData });
    return previewData
  } else {
    x.isGlobalStatic = global ? false : true
    x.settingsDocName = settingsDocName
    x.settingsDocKey = settingsDocKey
    x.manifestIcons = manifestIcons
    x.showSplash = true
    x.time_stamp = Date.now()
    x.isUserSettings = userSettingsOnly
    !ignoreAppUpdate && handleAmmend_appSettings(x, pathViews, null, pathname)
    return x
  }
}

// IMPORTANT: Settings - setFullViewSettings
const setFullViewSettings = (settings) => settings ?? {}

const setFullViewsSettings = (views, ignoreThisView) => {

  const avs = {}

  if (views) {
    Object.keys(views).forEach(key => {
      const viewSetting = views[key]
      let view = null
      if (ignoreThisView) {
        view = viewSetting ?? {}
      } else {
        view = viewSetting ?? {}
      }
      if (view.viewItems) {
        Object.keys(view.viewItems).forEach(key => {
          const vi = view.viewItems[key]
          createDataSource(vi)
        })
      }
      avs[key] = view
    })
  } else {
    const thisStaticView = {}
    avs[thisStaticView.viewType] = thisStaticView
    avs[thisStaticView.viewType].isViewStatic = true
  }
  return avs
}

const createAppComponents = (global) => {
  if (global && global.appSettings && global.appSettings.useComponentContent && !global.appComponents) {
    global.appComponents = {
      useComponentContent: global.appSettings.useComponentContent,
      componentContent: global.appSettings.componentContent
    }
    global.appComponents.fetchCollectionList = null
  }

  if (global && global.appComponents) {
    delete global.appComponents.fetchCollectionList
  }
}

const createDataSource = (viewItem) => {

  const { key: key_viewItem, pageItemData, dataConstraints } = viewItem ?? {}

  if (viewItem && _.isObject(viewItem)) {

    if (!viewItem.dataSource) {

      viewItem.dataSource = {}

      if (!viewItem.dataSource.dataSourceType) { viewItem.dataSource.dataSourceType = gEnums.dataSourceTypes.firebase }
      viewItem.dataSource.dataCollectionName = key_viewItem

      if (pageItemData) {

        const {
          addParentKeysToData,
          dataCollectionName,
          dataLinkType,
          dataParents,
          docViewItemProp,
          ignorePathViewNulls,
          pageDataCollection,
          subDataCollection,
          subDataGroup,
          subDataName,
          useSeasonals,
          useSubDataCollection,
        } = pageItemData

        viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.none

        switch (dataLinkType) {
          case 'component':
            viewItem.dataSource.dataSourceType = gEnums.dataSourceTypes.component
            break;
          case 'docGroup':
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.arrayContains
            break;
          case 'docLink':
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.equalTo
            break;
          case 'docPropLink':
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.propEqualTo
            viewItem.dataSource.pageConstraintTypeProp = docViewItemProp
            break;
          default:
        }

        if (subDataGroup) { console.log('subDataGroup', subDataGroup) }

        if (dataCollectionName) { viewItem.dataSource.altDataCollectionName = dataCollectionName }

        if (dataParents) {
          viewItem.dataSource.dataParents = dataParents
          if (useSubDataCollection) {
            viewItem.dataSource.pageConstraintType = gEnums.pageConstraintTypes.dataParentKeys
          }
        }

        if (useSubDataCollection) {
          viewItem.dataSource.useSubDataCollection = useSubDataCollection
          viewItem.dataSource.subDataCollectionName = subDataCollection
        }

        if (addParentKeysToData) { viewItem.dataSource.addParentKeysToData = addParentKeysToData }
        if (ignorePathViewNulls) { viewItem.dataSource.ignorePathViewNulls = ignorePathViewNulls }
        if (useSeasonals) { viewItem.dataSource.useSeasonals = useSeasonals }
        if (subDataName) { viewItem.dataSource.subDataFieldName = subDataName }
        if (subDataGroup) { viewItem.dataSource.subDataGroup = subDataGroup }

        if (pageDataCollection) {
          viewItem.dataSource.pageDataCollection = pageDataCollection
          viewItem.dataSource.dataSourceType = gEnums.dataSourceTypes.pageDataCollection
        }

      }

      if (dataConstraints) {
        const {
          ignoreConstraintsAdminLevel,
          dataConstraintItems,
          appUserIgnoreProp,
          dataIgnoreValues,
          ignoreConstraintsAppUserList,
          ignoreConstraintsList,
        } = dataConstraints

        if (dataConstraintItems) {
          viewItem.dataSource.useDataConstraints = dataConstraintItems ? true : false
          viewItem.dataSource.dataConstraintItems = dataConstraintItems
        }

        if (ignoreConstraintsAdminLevel) { viewItem.dataSource.ignoreConstraintsAdminLevel = ignoreConstraintsAdminLevel }
        if (appUserIgnoreProp) { viewItem.dataSource.appUserIgnoreProp = appUserIgnoreProp }
        if (dataIgnoreValues) { viewItem.dataSource.dataIgnoreValues = dataIgnoreValues }
        if (ignoreConstraintsAppUserList) { viewItem.dataSource.ignoreConstraintsAppUserList = ignoreConstraintsAppUserList }
        if (ignoreConstraintsList) { viewItem.dataSource.ignoreConstraintsList = ignoreConstraintsList }

        if (ignoreConstraintsAdminLevel || appUserIgnoreProp || dataIgnoreValues || ignoreConstraintsAppUserList || ignoreConstraintsList) {
          viewItem.dataSource.allowConstraintOverrides = true
        }
      }

      delete viewItem.pageItemData
      delete viewItem.dataConstraints
    }
  }

}