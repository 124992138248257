import { projectSemanticTypes } from '../../projectSpecific/components/subs/sem/semElems';
import { dispatchConfirmationTypes } from '../cnr/reducers/reducerHelpers/dispatchProps';
import { rssFeedTypes } from './rssFeedTypes'

export const externalLinks = {
  semanticIcons: 'https://react.semantic-ui.com/elements/icon/',
  productionApp: 'https://mobile.events.meetingevolution.net/#/',
  weatherCodes: 'https://weather.codes/',
}

export const clickOriginTypes = {
  dataViewer: 'dataViewer',
  framework: 'framework',
  iconGrid: 'iconGrid',
  navigation: 'navigation',
  sidebar: 'sidebar',
}

export const dynListTypes = {
  activeAppUserStaticView: 'activeAppUserStaticView',
  activeAppUserView: 'activeAppUserView',
  activeAppUserViewProps: 'activeAppUserViewProps',
  activeComponentList: 'activeComponentList',
  appUserViewTypeProps: 'appUserViewTypeProps',
  /** list from currentViPropList */
  activePropList: 'activePropList',
  activePropsList: 'activePropsList',
  /** list from currentViPropList NOT IN USE */
  activePropplsList: 'activePropplsList',
  /** list from sectionsFromProps(currentViPropList) */
  activeSectionList: 'activeSectionList',
  /** list from staticViews */
  activeStaticViewsList: 'activeStaticViewsList',
  activeStaticView: 'activeStaticView',
  /** list from viewItemsList */
  activeViList: 'activeViList',
  activeViPropList: 'activeViPropList',
  activeViPropList2: 'activeViPropList2',
  /** list from viewItemsList with updateProps exceptions */
  activeViListExceptSelf: 'activeViListExceptSelf',
  /** list from viewItemsList with ...c exceptions */
  activeViListPlusClient: 'activeViListPlusClient',
  /** list from pageContext.propsList */
  allPropList: 'allPropList',
  /** list from pageContext.viewItemsList */
  viewItemsList: 'viewItemsList',
  appAddActions: 'appAddActions',
  /** list from pageContext.homeSettings_global.homeAppUrls.appUrls */
  appActions: 'appActions',
  /** list from pageContext.homeSettings_global.homeAppUrls.appUrls */
  appUrls: 'appUrls',
  appPdfs: 'appPdfs',
  itemPdf: 'itemPdf',
  /** list from viewItems[menu.captionPage] */
  activeCollectionPropList: 'activeCollectionPropList',
  clientEventsList: 'clientEventsList',
  rssFeeds: 'rssFeeds',
  formTypes: 'formTypes',
  settingsHandlers: 'settingsHandlers',
  settingsHandlerRts: 'settingsHandlerRts',
}

export const listTypes = {
  accessLevels: 'accessLevels',
  accessStatusTypes: 'accessStatusTypes',
  actionFsTypes: 'actionFsTypes',
  addModeTypes: 'addModeTypes',
  addToHomeScreenTypes: 'addToHomeScreenTypes',
  adminTopicTypes: 'adminTopicTypes',
  allAuthLevels: 'allAuthLevels',
  altDisplayTypes: 'altDisplayTypes',
  alphaDisplayTypes: 'alphaDisplayTypes',
  alphaFilterTypes: 'alphaFilterTypes',
  appActions: 'appActions',
  appAdminTypes: 'appAdminTypes',
  appAreaTypes: 'appAreaTypes',
  appFormTypes: 'appFormTypes',
  appNames: 'appNames',
  appNavigationLocations: 'appNavigationLocations',
  appSubAdminLevels: 'appSubAdminLevels',
  appUserFormTypes: 'appUserFormTypes',
  appUserPersonalPageTypes: 'appUserPersonalPageTypes',
  appUserPrivacyPageTypes: 'appUserPrivacyPageTypes',
  appUserProfileImageTypes: 'appUserProfileImageTypes',
  appUserProfilePageTypes: 'appUserProfilePageTypes',
  appUserViewTypeTypes: 'appUserViewTypeTypes',
  appViewModes: 'appViewModes',
  authLevels: 'authLevels',
  autoFillTypes: 'autoFillTypes',
  availableAddTypes: 'availableAddTypes',
  availableSubActionTypes: 'availableSubActionTypes',
  bannerLocationTypes: 'bannerLocationTypes',
  bannerTypes: 'bannerTypes',
  bannerUrlSizeTypes: 'bannerUrlSizeTypes',
  boxShadowTypes: 'boxShadowTypes',
  captionAlignmentTypes: 'captionAlignmentTypes',
  captionTypes: 'captionTypes',
  captionWidths: 'captionWidths',
  cardDisplayTypes: 'cardDisplayTypes',
  caseTypes: 'caseTypes',
  clickToTypes: 'clickToTypes',
  clientAuthLevels: 'clientAuthLevels',
  clientStatusTypes: 'clientStatusTypes',
  clientTypes: 'clientTypes',
  collectionLocationTypes: 'collectionLocationTypes',
  collectionRelationshipTypes: 'collectionRelationshipTypes',
  colorDisplayTypes: 'colorDisplayTypes',
  componentContentTypes: 'componentContentTypes',
  consoleItemTypes: 'consoleItemTypes',
  consoleTypes: 'consoleTypes',
  constraintMathodTypes: 'constraintMathodTypes',
  contentTypes: 'contentTypes',
  contentWidths: 'contentWidths',
  countries: 'countries',
  createProfileTypes: 'createProfileTypes',
  customAddTypes: 'customAddTypes',
  dataFilters: 'dataFilters',
  dataFilterTypes: 'dataFilterTypes',
  dataLinkTypes: 'dataLinkTypes',
  dataList: 'dataList',
  dataListItem: 'dataListItem',
  dataListTypes: 'dataListTypes',
  dataRetrievalTypes: 'dataRetrievalTypes',
  dataSourceTypes: 'dataSourceTypes',
  dataTransferTypes: 'dataTransferTypes',
  dataTypes: 'dataTypes',
  dateTypes: 'dateTypes',
  dbFetchTypes: 'dbFetchTypes',
  desktopLayoutTypes: 'desktopLayoutTypes',
  directSignInTypes: 'directSignInTypes',
  dispatchConfirmationTypes: 'dispatchConfirmationTypes',
  displayDirectionTypes: 'displayDirectionTypes',
  displaySizeTypes: 'displaySizeTypes',
  displayTypes: 'displayTypes',
  drillDownTypes: 'drillDownTypes',
  elemPropTypes: 'elemPropTypes',
  emailValidationTypes: 'emailValidationTypes',
  environmentTypes: 'environmentTypes',
  eventAdminLevels: 'eventAdminLevels',
  eventStatusTypes: 'eventStatusTypes',
  eventTypes: 'eventTypes',
  filterStatusTypes: 'filterStatusTypes',
  filterTypes: 'filterTypes',
  firebaseTypes: 'firebaseTypes',
  fixedOptions: 'fixedOptions',
  fontWeightTypes: 'fontWeightTypes',
  formItemDefaultTypes: 'formItemDefaultTypes',
  formItemTypes: 'formItemTypes',
  generic: 'generic',
  giftCardDisplayTypes: 'giftCardDisplayTypes',
  golfDisplayTypes: 'golfDisplayTypes',
  golfTournamentTypes: 'golfTournamentTypes',
  groupAsTypes: 'groupAsTypes',
  groupTypes: 'groupTypes',
  help: 'help',
  helpDisplayTypes: 'helpDisplayTypes',
  homePageTypes: 'homePageTypes',
  iconLocations: 'iconLocations',
  imageLocations: 'imageLocations',
  imageMapCollectionNames: 'imageMapCollectionNames',
  imageSizes: 'imageSizes',
  imageTypes: 'imageTypes',
  itemLinkTypes: 'itemLinkTypes',
  itemListTypes: 'itemListTypes',
  itemPermissionActionTypes: 'itemPermissionActionTypes',
  itemWrapTypes: 'itemWrapTypes',
  landingPageLocations: 'landingPageLocations',
  layoutTypes: 'layoutTypes',
  listOptions: 'listOptions',
  listPropTypes: 'listPropTypes',
  loginTypes: 'loginTypes',
  logTypes_googleSheets: 'logTypes_googleSheets',
  manifestDisplayTypes: 'manifestDisplayTypes',
  manifestSizes: 'manifestSizes',
  marginTypes: 'marginTypes',
  maskDisplayTypes: 'maskDisplayTypes',
  matchScheduleDisplayTypes: 'matchScheduleDisplayTypes',
  menuCaptionSources: 'menuCaptionSources',
  menuDisplayTypes: 'menuDisplayTypes',
  menuLocations: 'menuLocations',
  menuPermissionTypes: 'menuPermissionTypes',
  messageTypes: 'messageTypes',
  notificationAreaTypes: 'notificationAreaTypes',
  notificationDeliveryTypes: 'notificationDeliveryTypes',
  notificationDisplayTypes: 'notificationDisplayTypes',
  notificationFrequencyTypes: 'notificationFrequencyTypes',
  notificationPositions: 'notificationPositions',
  notificationRecipientTypes: 'notificationRecipientTypes',
  notificationScheduleTypes: 'notificationScheduleTypes',
  numberOptions: 'numberOptions',
  opacityTypes: 'opacityTypes',
  pageAreaTypes: 'pageAreaTypes',
  pageConstraintTypes: 'pageConstraintTypes',
  pageDataOriginTypes: 'pageDataOriginTypes',
  pageDataSourceTypes: 'pageDataSourceTypes',
  permissionColors: 'permissionColors',
  permissionTypes: 'permissionTypes',
  labDisplayTypes: 'labDisplayTypes',
  previewTypes: 'previewTypes',
  profileTypes: 'profileTypes',
  projectTypes: 'projectTypes',
  propDirectionTypes: 'propDirectionTypes',
  propDisplayTypes: 'propDisplayTypes',
  propsList: 'propsList',
  publishedTypes: 'publishedTypes',
  pushDisplayTypes: 'pushDisplayTypes',
  qrCodeTypes: 'qrCodeTypes',
  qrTypes: 'qrTypes',
  queryTypes: 'queryTypes',
  relationshipTypes: 'relationshipTypes',
  roundTypes: 'roundTypes',
  rssFeedTypes: 'rssFeedTypes',
  scheduleDisplayTypes: 'scheduleDisplayTypes',
  sectionAlignmentTypes: 'sectionAlignmentTypes',
  sectionBodyTypes: 'sectionBodyTypes',
  sectionDisplayTypes: 'sectionDisplayTypes',
  sectionHeaderTypes: 'sectionHeaderTypes',
  sectionLayoutTypes: 'sectionLayoutTypes',
  semSizes: 'semSizes',
  settingGroupTypes: 'settingGroupTypes',
  settingsCategoryTypes: 'settingsCategoryTypes',
  settingsMenuGroupTypes: 'settingsMenuGroupTypes',
  signInFormTypes: 'signInFormTypes',
  signInProviderTypes: 'signInProviderTypes',
  siteAuthorizationTypes: 'siteAuthorizationTypes',
  sizeTypes: 'sizeTypes',
  sportsDisplayTypes: 'sportsDisplayTypes',
  staticDataCollectionTypes: 'staticDataCollectionTypes',
  staticList: 'staticList',
  statusTypes: 'statusTypes',
  storageTransferTypes: 'storageTransferTypes',
  subActionTypes: 'subActionTypes',
  tagPositions: 'tagPositions',
  themeColors: 'themeColors',
  themedItems: 'themedItems',
  themeItems: 'themeItems',
  thicknessTypes: 'thicknessTypes',
  ticketDisplayTypes: 'ticketDisplayTypes',
  timeTypes: 'timeTypes',
  timeZones: 'timeZones',
  toggleTypes: 'toggleTypes',
  topicOriginTypes: 'topicOriginTypes',
  transitionTypes: 'transitionTypes',
  trueFalse: 'trueFalse',
  uiDisplayTypes: 'uiDisplayTypes',
  usaStates: 'usaStates',
  videoConferenceTypes: 'videoConferenceTypes',
  viewDisplayTypes: 'viewDisplayTypes',
  voteTypes: 'voteTypes',
  waiverTypes: 'waiverTypes',
}

const profileColors = {
  none: 'grey',
  public: 'grey',
  guest: 'brown',
  user: 'olive',
  appProfile: 'green',
  appDataAdmin: 'teal',
  appDataAdminLimited: 'yellow',
  appSubAdmin: 'blue',
  appAdmin: 'violet',
  admin: 'orange',
  superAdmin: 'red',
  superAdminDeveloper: 'pink',
  superDuper: 'yellow',
}

// red/orange/yellow/olive/green/teal/blue/violet/purple/pink/brown/grey/black
export const appUserLevels = {
  none: { caption: '---', value: 1, key: 1, color: profileColors.none, icon: 'user outline' },
  public: { caption: 'Public', value: 1, key: 1, color: profileColors.public, icon: 'user outline' },
  guest: { caption: 'Guest', value: 2, key: 2, color: profileColors.guest, icon: 'user outline' },
  user: { caption: 'User', value: 3, key: 3, color: profileColors.user, icon: 'user outline' },
  appProfile: { caption: 'App Profile', value: 4, key: 4, color: profileColors.appProfile, icon: 'user' },
  appDataAdmin: { caption: 'App Data Admin', value: 5, key: 5, color: profileColors.appDataAdmin, icon: 'user circle' },
  appDataAdminLimited: { caption: 'App Data Admin Limited', value: 5, key: 5, color: profileColors.appDataAdminLimited, icon: 'user circle' },
  appSubAdmin: { caption: 'App Sub Admin', value: 6, key: 6, color: profileColors.appSubAdmin, icon: 'user circle' },
  appAdmin: { caption: 'App Admin', value: 7, key: 7, color: profileColors.appAdmin, icon: 'user circle' },
  admin: { caption: 'Admin', value: 10, key: 10, color: profileColors.admin, icon: 'user secret' },
  superAdmin: { caption: 'Super Admin', value: 11, key: 11, color: profileColors.superAdmin, icon: 'user secret' },
  superAdminDeveloper: { caption: 'Super Admin Developer', value: 12, key: 12, color: profileColors.superAdminDeveloper, icon: 'user secret' },
  superDuper: { caption: 'Super Duper', value: 100, key: 100, color: profileColors.superDuper, icon: 'user secret' },
}

const appUserEnums = {
  accessStatusTypes: {
    approved: 'approved',
    denied: 'denied',
    pending: 'pending'
  },
  appAdminTypes: {
    full: 'full',
    selected: 'selected',
  },
  appUserProfileImageTypes: {
    appUser: 'appUser',
    profile: 'profile',
  },
  appUserAccessTypes: {
    admin: 'admin',
    appAdmin: 'appAdmin',
    appDataAdmin: 'appDataAdmin',
    appDataAdminLimited: 'appDataAdminLimited',
    appProfile: 'appProfile',
    appSubAdmin: 'appSubAdmin',
    appUser: 'appUser',
    none: 'none',
    superAdmin: 'superAdmin',
    superAdminDeveloper: 'superAdminDeveloper',
    superDuper: 'superDuper',
  },
  allAuthLevels: {
    none: appUserLevels.none,
    public: appUserLevels.public,
    guest: appUserLevels.guest,
    user: appUserLevels.user,
    appProfile: appUserLevels.appProfile,
    appDataAdmin: appUserLevels.appDataAdmin,
    appDataAdminLimited: appUserLevels.appDataAdminLimited,
    appSubAdmin: appUserLevels.appSubAdmin,
    appAdmin: appUserLevels.appAdmin,
    admin: appUserLevels.admin,
    superAdmin: appUserLevels.superAdmin,
    superAdminDeveloper: appUserLevels.superAdminDeveloper,
    superDuper: appUserLevels.superDuper
  },
  altDisplayTypes: {
    test: 'test'
  },
  appViewModes: {
    desktop: 'desktop',
    googleSheets: 'googleSheets',
    settings: 'settings',
  },
  authLevels: {
    none: appUserLevels.none,
    appDataAdmin: appUserLevels.appDataAdmin,
    appDataAdminLimited: appUserLevels.appDataAdminLimited,
    appSubAdmin: appUserLevels.appSubAdmin,
    appAdmin: appUserLevels.appAdmin,
    admin: appUserLevels.admin,
    superAdmin: appUserLevels.superAdmin,
    superAdminDeveloper: appUserLevels.superAdminDeveloper,
    superDuper: appUserLevels.superDuper,
  },
  authListTypes: {
    recent: 'recent',
    found: 'found',
    notFound: 'notFound',
  },
  clickToTypes: {
    app: 'app',
    detectApp: 'detectApp',
    noClick: 'noClick',
    normal: 'normal',
    page: 'page',
    pdf: 'pdf',
    url: 'url',
  },
  clientAuthLevels: {
    none: appUserLevels.none,
    appProfile: appUserLevels.appProfile,
    appDataAdmin: appUserLevels.appDataAdmin,
    appDataAdminLimited: appUserLevels.appDataAdminLimited,
    appSubAdmin: appUserLevels.appSubAdmin,
    appAdmin: appUserLevels.appAdmin,
  },
  appAccessLevels: {
    none: appUserLevels.none,
    guest: appUserLevels.guest,
    appProfile: appUserLevels.appProfile,
  },
  accessLevels: {
    none: appUserLevels.none,
    public: appUserLevels.public,
    guest: appUserLevels.guest,
    user: appUserLevels.user,
    appProfile: appUserLevels.appProfile,
    appDataAdmin: appUserLevels.appDataAdmin,
    appDataAdminLimited: appUserLevels.appDataAdminLimited,
    appSubAdmin: appUserLevels.appSubAdmin,
    appAdmin: appUserLevels.appAdmin,
    admin: appUserLevels.admin,
    superAdmin: appUserLevels.superAdmin,
    superAdminDeveloper: appUserLevels.superAdminDeveloper,
    superDuper: appUserLevels.superDuper,
  },
}

export const gEnums = {
  matchScheduleDisplayTypes: {
    allDates: 'allDates',
    selectedDate: 'selectedDate',
    clickedDates: 'clickedDates',
    levelDates: 'levelDates',
    levelSelectedDate: 'levelSelectedDate',
  },
  fieldTripDisplayTypes: {
    fieldTrip: 'fieldTrip',
    fieldTrips: 'fieldTrips',
    status: 'status',
  },
  additionalPageItemTypes: {
    clientProfiles: 'clientProfiles',
    feeds: 'feeds',
    gallery: 'gallery',
    geoList: 'geoList',
    geoLocation: 'geoLocation',
    imageLinks: 'imageLinks',
    media: 'media',
    news: 'news',
    pdfs: 'pdfs',
    photos: 'photos',
    questionsAndAnswers: 'questionsAndAnswers',
    tickets: 'tickets',
    twitter: 'twitter',
    videoLinks: 'videoLinks',
    voting: 'voting',
  },
  addModeTypes: {
    additionalPageItems: 'additionalPageItems',
    api: 'api',
    cameraImage: 'cameraImage',
    clientKey: 'clientKey',
    copyExisting: 'copyExisting',
    dataTable: 'dataTable',
    eventSpecific: 'eventSpecific',
    existingItems: 'existingItems',
    googleSheets: 'googleSheets',
    googleSheetsOnly: 'googleSheetsOnly',
    image: 'image',
    input: 'input',
    list: 'list',
    listAdd: 'listAdd',
    normal: 'normal',
    pageItems: 'pageItems',
    pasteImport: 'pasteImport',
    previousEvent: 'previousEvent',
    registrationKey: 'registrationKey',
    registrationKeyAndGoogleSheets: 'registrationKeyAndGoogleSheets',
    select: 'select',
    subEvent: 'subEvent',
    url: 'url',
    views: 'views',
  },
  // App User Levels 
  alphaDisplayTypes: {
    block: 'block',
    group: 'group',
    tab: 'tab',
    vertical: 'vertical',
    verticalFull: 'verticalFull'
  },
  actionFsTypes: {
    /** Updates a docArray of a document in `/global/pageItem/SemUI.js` : `handleOptionClick` */
    updateDocArray: 'updateDocArray',
    addToDocumentArray: 'addToDocumentArray',
    /** Displays a modal with options in `SemUI.js` : `handleActionToggleClick' */
    showOptions: 'showOptions'
  },
  addToHomeScreenTypes: {
    full: 'full',
    partial: 'partial'
  },
  alphaFilterTypes: {
    none: 'none',
    always: 'always',
    afterLimit: 'afterLimit',
  },
  appBaseAreaTypes: {
    clients: 'clients',
    clubs: 'clubs',
    home: 'home',
    leagues: 'leagues',
  },
  appAreaTypes: {
    all: 'all',
    app: 'app',
    client: 'client',
    clients: 'clients',
    event: 'event',
    home: 'home',
  },
  appUserPersonalPageTypes: {
    chat: 'chat',
    connections: 'connections',
    conversations: 'conversations',
    events: 'events',
    favorites: 'favorites',
    meetings: 'meetings',
    notes: 'notes',
    notifications: 'notifications',
    qrCode: 'qrCode',
    ticketing: 'ticketing',
    videoConferencing: 'videoConferencing',
  },
  appUserProfilePageTypes: {
    appUserAboutMe: 'appUserAboutMe',
    appUserBio: 'appUserBio',
    appUserFamily: 'appUserFamily',
    appUserGallery: 'appUserGallery',
    appUserProfile: 'appUserProfile',
    appUserProfileImage: 'appUserProfileImage',
  },
  appUserPrivacyPageTypes: {
    appUserPrivacy: 'appUserPrivacy',
  },
  autoFillTypes: {
    appUserId: 'appUserId',
    currentTimestamp: 'currentTimestamp',
  },
  availableAddTypes: {
    golfTournament: 'golfTournament',
    golfMatch: 'golfMatch',
    sports: 'sports'
  },
  // availableComponentContentTypes: {
  //   golfTournament: 'golfTournament'
  // },
  availableSubActionTypes: {
    assignStartHole: 'assignStartHole',
    // backupSeasons: 'backupSeasons',
    createAlignment: 'createAlignment',
    createAllStarTeams: 'createAllStarTeams',
    createAthletes: 'createAthletes',
    createGolfLineups: 'createGolfLineups',
    createGolfTournament: 'createGolfTournament',
    createGolfTournamentRounds: 'createGolfTournamentRounds',
    createHistory: 'createHistory',
    createLabRun: 'createLabRun',
    createMatch: 'createMatch',
    createPlayoff: 'createPlayoff',
    createRankings: 'createRankings',
    createRoster: 'createRoster',
    createRoundMatches: 'createRoundMatches',
    createSchedule: 'createSchedule',
    createScheduleDates: 'createScheduleDates',
    createSchools: 'createSchools',
    createSeason: 'createSeason',
    createSportsTeams: 'createSportsTeams',
    createStaff: 'createStaff',
    createTempScorecard: 'createTempScorecard',
    createTempScorecards: 'createTempScorecards',
    createTickets: 'createTickets',
    createTournament: 'createTournament',
    createWeeklyLabRun: 'createWeeklyLabRun',
    editMatch: 'editMatch',
    editSeason: 'editSeason',
    editSeasonDetails: 'editSeasonDetails',
    editTryoutRoster: 'editTryoutRoster',
    findLabResult: 'findLabResult',
    importGolfTournament: 'importGolfTournament',
    nominatePlayers: 'nominatePlayers',
    scanQrCode: 'scanQrCode',
    sendAppUserEmail: 'sendAppUserEmail',
    sportsDashboard: 'sportsDashboard',
    submitRankings: 'submitRankings',
    updateDistricts: 'updateDistricts',
    updateLabResult: 'updateLabResult',
    updateLeagueSports: 'updateLeagueSports',
    updateMatchRealtimeScore: 'updateMatchRealtimeScore',
    updateMatchScore: 'updateMatchScore',
    updateOrganizations: 'updateOrganizations',
    updateOrganizationSports: 'updateOrganizationSports',
    updateSchoolSports: 'updateSchoolSports',
  },
  bannerLocationTypes: {
    bottom: 'bottom',
    top: 'top',
  },
  bannerTypes: {
    none: 'none',
    directUrl: 'directUrl',
    fromFile: 'fromFile',
  },
  bannerUrlSizeTypes: {
    full: 'full',
    thumbnail: 'thumbnail'
  },
  boxShadowTypes: {
    none: 'none',
    thinnest: 'thinnest',
    thin: 'thin',
    normal: 'normal',
    wide: 'wide',
    veryWide: 'veryWide',
  },
  captionAlignmentTypes: {
    left: 'left',
    right: 'right',
  },
  captionTypes: {
    none: 'none',
    fixed: 'fixed',
    fromData: 'fromData'
  },
  captionWidths: {
    thin: 'thin', // 2
    normal: 'normal',  //3 
    wide: 'wide', // 5
    veryWide: 'veryWide', // 6
  },
  cardDisplayTypes: {
    break: 'break',
    busSchedule: 'busSchedule',
    description: 'description',
    event: 'event',
    client: 'client',
    giftCard: 'giftCard',
    location: 'location',
    mini: 'mini',
    nameSectionOnly: 'nameSectionOnly',
    news: 'news',
    normal: 'normal',
    page: 'page',
    profile: 'profile',
    profilePics: 'profilePics',
    profilePlus: 'profilePlus',
    profilePlusPlus: 'profilePlus',
    qrCode: 'qrCode',
    restaurant: 'restaurant',
    schedule: 'schedule',
    schedulePlus: 'schedulePlus',
    taggedOnly: 'taggedOnly',
    ticket: 'ticket',
    venue: 'venue',
  },
  golfDisplayTypes: {
    course: 'course',
    courses: 'courses',
    golfer: 'golfer',
    golfers: 'golfers',
    info: 'info',
    lineup: 'lineup',
    lineups: 'lineups',
    match: 'match',
    matchScore: 'matchScore',
    round: 'round',
    rounds: 'rounds',
    roundsAll: 'roundsAll',
    selectedMatch: 'selectedMatch',
    settings: 'settings',
    team: 'team',
    teams: 'teams',
    tournament: 'tournament',
    tournaments: 'tournaments',
  },
  sportsDisplayTypes: {
    current: 'current',
    active: 'active',
    allStarTeams: 'allStarTeams',
    athletes: 'athletes',
    districts: 'districts',
    history: 'history',
    match: 'match',
    playoffSchedule: 'playoffSchedule',
    rankings: 'rankings',
    schedule: 'schedule',
    schools: 'schools',
    schoolSports: 'schoolSports',
    sports: 'sports',
    standings: 'standings',
    staff: 'staff',
    teamOverview: 'teamOverview',
    tournament: 'tournament',
    tournaments: 'tournaments',
  },
  labDisplayTypes: {
    sample: 'sample',
    weeklyLabSamples: 'weeklyLabSamples',
  },
  caseTypes: {
    none: 'none',
    lowerCase: 'lowerCase',
    upperCase: 'upperCase',
  },
  menuItemOptionTypes: {
    adminOnly: 'adminOnly',
    address: 'address',
    contact: 'contact',
    check: 'check',
    cssed: 'cssed',
    drilled: 'drilled',
    fix: 'fix',
    header: 'header',
    highlight: 'highlight',
    image: 'image',
    location: 'location',
    link: 'link',
    remove: 'remove',
    restrictAdd: 'restrictAdd',
    pin: 'pin',
    schedule: 'schedule',
    secret: 'secret',
    show: 'show',
    social: 'social',
    tagged: 'tagged',
    tags: 'tags',
  },
  clientTypes: {
    normal: 'normal',
    eventBased: 'eventBased',
  },
  clientStatusTypes: {
    none: 'none',
    active: 'active',
    underMaintenance: 'underMaintenance',
  },
  collectionLocationTypes: {
    top: 'top',
    document: 'document'
  },
  collectionRelationshipTypes: {
    none: 'none',
    equals: 'equals',
    contains: 'contains',
    parentEquals: 'parentEquals',
    parentContains: 'parentContains',
    is: 'is',
  },
  colorDisplayTypes: {
    bottomCard: 'bottomCard',
    lineLabel: 'lineLabel',
    sideCard: 'sideCard',
    tagLabel: 'tagLabel',
    topCard: 'topCard',
  },
  constraintMathodTypes: {
    pre: 'pre',
    post: 'post',
  },
  componentContentTypes: {
    none: 'none',
    normal: 'normal',
    fieldTrips: 'fieldTrips',
    giftCard: 'giftCard',
    golfEvent: 'golfEvent',
    golfTournament: 'golfTournament',
    lab: 'lab',
    sports: 'sports',
    sportsDistrict: 'sportsDistrict',
    sportsOrganization: 'sportsOrganization',
    sportsSeason: 'sportsSeason',
  },
  consoleTypes: {
    current: 'current',
    paths: 'paths'
  },
  consoleItemTypes: {
    other: 'other',
    pr: 'pr',
    ps: 'ps',
    vi: 'vi',
    vw: 'vw',
  },
  contentTypes: {
    none: 'none',
    description: 'description',
    extra: 'extra',
    header: 'header',
    meta: 'meta',
  },
  createProfileTypes: {
    profiles: 'profiles',
    clientProfiles: 'clientProfiles',
  },
  createOriginTypes: {
    createSettings: 'createSettings',
    createDataModify: 'createDataModify',
    createDataSettings: 'createDataSettings',
    dataModify: 'dataModify',
    viewSettings: 'viewSettings',
  },
  customAddTypes: {
    none: 'none',
    golfTournament: 'golfTournament',
    golfMatch: 'golfMatch',
    golfCourse: 'golfCourse',
    sports: 'sports'
  },
  dataFilterTypes: {
    excludeProp: 'excludeProp',
    propEquals: 'propEquals',
    parentKeyContained: 'parentKeyContained',
    parentKeyIs: 'parentKeyIs',
  },
  dataHeaderTypes: {
    dataModify: 'dataModify',
    dataSubHeader: 'dataSubHeader',
    itemHeader: 'itemHeader',
    menuHeader: 'menuHeader',
    sectionHeader: 'sectionHeader',
    viewData: 'viewData',
  },
  dataLinkTypes: {
    none: 'none',
    dataParents: 'dataParents',
    subCollection: 'subCollection',
  },
  dataListTypes: {
    authLevels: 'authLevels',
    dataList: 'dataList',
    dataProp: 'dataProp',
    decimal: 'decimal',
    staticViews: 'staticViews',
    fixed: 'fixed',
    number: 'number',
  },
  dataListerTypes: {
    collectionData: 'collectionData',
    viewData: 'viewData',
    simple: 'simple',
  },
  dataViewModes: {
    jsonDataViewer: 'jsonDataViewer',
    jsonViewer: 'jsonViewer',
    paste: 'paste',
    realtime: 'realtime',
    reg: 'reg',
    sheets: 'sheets',
  },
  /** Sets the dataRetrievalType for the view */
  dataRetrievalTypes: {
    collection: 'collection',
    document: 'document',
  },
  dataSourceTypes: {
    none: 'none',
    appComponent: 'appComponent',
    component: 'component',
    firebase: 'firebase',
    firebaseDocument: 'firebaseDocument',
    googleSpreadSheet: 'googleSpreadSheet',
    pageDataCollection: 'pageDataCollection',
    static: 'static',
    urlFeed: 'urlFeed',
  },
  pageDataOriginTypes: {
    registration: 'registration',
    googleSheets: 'googleSheets',
  },
  pageDataSourceTypes: {
    none: 'none',
    appComponent: 'appComponent',
    component: 'component',
    firebase: 'firebase',
    firebaseDocument: 'firebaseDocument',
    googleSpreadSheet: 'googleSpreadSheet',
    pageDataCollection: 'pageDataCollection',
    static: 'static',
    urlFeed: 'urlFeed',
  },
  dataUpdateTypes: {
    addData: 'addData',
    addListDoc: 'addListDoc',
    copy: 'copy',
    deleteCollectionItems: 'deleteCollectionItems',
    mergeData: 'mergeData',
    push: 'push',
    updateAccessRequest: 'updateAccessRequest',
    updateAppUserDoc: 'updateAppUserDoc',
    updateCollection_prop: 'updateCollection_prop',
    updateCollection: 'updateCollection',
    updateData: 'updateData',
    updateDoc: 'updateDoc',
    updateImageLinks: 'updateImageLinks',
    updateRating: 'updateRating',
    updateSortedCollection: 'updateSortedCollection',
  },
  dataTypes: {
    boolean: 'boolean',
    currency: 'currency',
    date: 'date',
    image: 'image',
    number: 'number',
    string: 'string',
  },
  dateTypes: {
    fullDate: 'fullDate',
    shortDate: 'shortDate',
    shorterDate: 'shorterDate',
    shortestDate: 'shortestDate',
  },
  appNavigationLocations: {
    hidden: 'hidden',
    left: 'left',
    top: 'top',
  },
  deviceNames: {
    android: 'android',
    apple: 'apple',
    desktop: 'desktop',
    iphone: 'iphone',
  },
  displayDirectionTypes: {
    horizontal: 'horizontal',
    vertical: 'vertical'
  },
  displaySizes: {
    single: 'single',
    full: 'full',
    mini: 'mini',
  },
  displayTypes: {
    card: 'card',
    css: 'css',
    clientProfiles: 'clientProfiles',
    document: 'document',
    event: 'event',
    feeds: 'feeds',
    fieldTrip: 'fieldTrip',
    fieldTrips: 'fieldTrips',
    fieldTripStatus: 'fieldTripStatus',
    gallery: 'gallery',
    giftCard: 'giftCard',
    golf: 'golf',
    geoList: 'geoList',
    geoLocation: 'geoLocation',
    imageLinks: 'imageLinks',
    imageOnly: 'imageOnly',
    item: 'item',
    lab: 'lab',
    list: 'list',
    mapAddress: 'mapAddress',
    mapList: 'mapList',
    media: 'media',
    message: 'message',
    pdfOnly: 'pdfOnly',
    pdfs: 'pdfs',
    photos: 'photos',
    questionsAndAnswers: 'questionsAndAnswers',
    schedule: 'schedule',
    sports: 'sports',
    table: 'table',
    taggedTable: 'taggedTable',
    ticket: 'ticket',
    tickets: 'tickets',
    transportationSchedule: 'transportationSchedule',
    twitter: 'twitter',
    usaMap: 'usaMap',
    videoLinks: 'videoLinks',
    videoPlayer: 'videoPlayer',
    weather: 'weather',
    ...projectSemanticTypes
  },
  displaySizeTypes: {
    single: 'single',
    full: 'full',
    mini: 'mini',
  },
  contentWidths: {
    full: 'full',
    normal: 'normal',
    thin: 'thin',
    thinnest: 'thinnest',
    veryWide: 'veryWide',
    wide: 'wide',
  },
  desktopLayoutTypes: {
    singlePage: 'singlePage',
    horizontalSliders: 'horizontalSliders',
    pages: 'pages',
    swipeable: 'swipeable',
  },
  dbFetchTypes: {
    read: 'read',
    realtime: 'realtime',
  },
  drillDownTypes: {
    props: 'props',
    sections: 'sections'
  },
  elemPropTypes: {
    addressOnly: 'addressOnly',
    addressMap: 'addressMap',
    addressWithMap: 'addressWithMap',
    date: 'date',
    document: 'document',
    golfScorecard: 'golfScorecard',
    golfTeeboxes: 'golfTeeboxes',
    image: 'image',
    itemList: 'itemList',
    miniSort: 'miniSort',
    normal: 'normal',
    pdf: 'pdf',
    qrCode: 'qrCode',
    time: 'time',
    urlList: 'urlList',
    video: 'video',
  },
  environmentTypes: {
    development: 'development',
    production: 'production'
  },
  emailValidationTypes: {
    accepted: 'accepted',
    activated: 'activated',
    denied: 'denied',
    none: 'none',
    requested: 'requested',
    requestedNonLink: 'requestedNonLink',
  },
  eventStatusTypes: {
    none: 'none',
    active: 'active',
    completed: 'completed',
    live: 'live',
    ongoing: 'ongoing',
    pending: 'pending',
    recent: 'recent',
    restricted: 'restricted',
    underMaintenance: 'underMaintenance',
  },
  eventTypes: {
    normal: 'normal',
    golfTournament: 'eventBased',
  },
  fileModes: {
    view: 'view',
    viewManifest: 'viewManifest',
    viewWithModify: 'viewWithModify',
    viewWithPick: 'viewWithPick',
    viewWithSelect: 'viewWithSelect',
    viewWithSelectDirect: 'viewWithSelectDirect',
  },
  filterTypes: {
    none: 'none',
    dropdown: 'dropdown',
    fullPage: 'fullPage',
    tags: 'tags',
  },
  filterStatusTypes: {
    none: 'none',
    showing: 'showing',
    active: 'active',
  },
  firebaseTypes: {
    realtimeDatabase: 'realtimeDatabase',
    cloudFirestore: 'cloudFirestore',
  },
  firestoreTypes: {
    firebase: 'firebase',
    firestore: 'firestore',
    parentData: 'parentData',
  },
  fixedOptions: {
    countries: 'countries',
    usaStates: 'usaStates',
  },
  fontWeightTypes: {
    none: 'none',
    lighter: 'lighter',
    normal: 'normal',
    bold: 'bold',
    bolder: 'bolder',
  },
  formItemDefaultTypes: {
    address: 'address',
    backgroundColorSelectSingle: 'backgroundColorSelectSingle',
    checkbox: 'checkbox',
    colorSelect: 'colorSelect', // GOOD
    colorSelectSingle: 'colorSelectSingle',
    date: 'date',
    dropdown: 'dropdown',
    gridRowSelect: 'gridRowSelect',
    gridRowSelectFull: 'gridRowSelectFull',
    numberPicker: 'numberPicker',
    select: 'select',
    slider: 'slider',
    tableMap: 'tableMap',
    text: 'text',
    textArea: 'textArea',
    upload: 'upload',
  },
  formItemTypes: {
    address: 'address',
    appUserDataConstraintList: 'appUserDataConstraintList',
    appUserDataModificationList: 'appUserDataModificationList',
    autoFill: 'autoFill',
    backgroundColorSelectSingle: 'backgroundColorSelectSingle',
    button: 'button',
    checkbox: 'checkbox', // GOOD
    colorSelect: 'colorSelect', // GOOD
    colorSelectSingle: 'colorSelectSingle',
    component: 'component',
    constraintBuilder: 'constraintBuilder',
    dataConstraintList: 'dataConstraintList',
    dataMatchConstraintList: 'dataMatchConstraintList',
    date: 'date',// GOOD 
    dropdown: 'dropdown',// GOOD
    dropdownAdd: 'dropdownAdd',// GOOD
    dropdownGroup: 'dropdownGroup',// GOOD
    dropdownSelect: 'dropdownSelect',// GOOD
    fileItemSelect: 'fileItemSelect',
    fileItemSelectDirect: 'fileItemSelectDirect',
    fileManifestSelect: 'fileManifestSelect',
    fileSelect: 'fileSelect',
    gridRowSelect: 'gridRowSelect',
    gridRowSelectFull: 'gridRowSelectFull',
    image: 'image', // ???
    imageMappingList: 'imageMappingList',
    imagePropList: 'imagePropList',
    imageUrl: 'imageUrl',
    inlineItems: 'inlineItems', // GOOD
    itemArrayMulti: 'itemArrayMulti', // GOOD  
    itemList: 'itemList', // GOOD 
    itemMap: 'itemMap', // GOOD 
    listAdd: 'listAdd',
    listAdder: 'listAdder',
    listOf: 'listOf',
    listSelect: 'listSelect',
    listView: 'listView',
    menuItemSelect: 'menuItemSelect',
    menuItemSelectFull: 'menuItemSelectFull',
    menuItemSelectFullCount: 'menuItemSelectFullCount',
    nameSelect: 'nameSelect',
    numberPicker: 'numberPicker',
    pageConstraintList: 'pageConstraintList',
    phoneNumber: 'phoneNumber',
    select: 'select', // GOOD
    slider: 'slider', // GOOD
    tableMap: 'tableMap', // GOOD
    text: 'text',
    textArea: 'textArea', // GOOD
    textAreaSimple: 'textAreaSimple', // GOOD
    textConfig: 'textConfig',
    textReadOnly: 'textReadOnly',
    upload: 'upload', // GOOD
    year: 'year',
  },
  giftCardDisplayTypes: {
    multi: 'multi',
    single: 'single',
  },
  golfTournamentTypes: {
    matchPlay: 'matchPlay',
    ryderCup2Day: 'ryderCup2Day',
    ryderCup3Day: 'ryderCup3Day',
    ryderCupSingleRound: 'ryderCupSingleRound',
    scramble: 'scramble',
    strokePlay: 'scramble',
    selected: 'selected',
  },
  globalTypes: {
    genders: 'genders',
    levels: 'levels',
    seasons: 'seasons',
    sections: 'sections',
    sportLevels: 'sportLevels',
    sports: 'sports',
  },
  groupAsTypes: {
    address: 'address',
    group: 'group',
    inline: 'inline'
  },
  groupTypes: {
    none: 'none',
    accordion: 'accordion',
    calendarSidebar: 'calendarSidebar',
    menuSidebar: 'menuSidebar',
    segment: 'segment',
    schedule: 'schedule',
    scheduleTop: 'scheduleTop',
    swipeableTab: 'swipeableTab',
    swipeableGroup: 'swipeableGroup',
  },
  helpDisplayTypes: {
    belowCaption: 'belowCaption',
    popupIcon: 'popupIcon',
  },
  homePageTypes: {
    always: 'always',
    once: 'once',
  },
  icons: {
  },
  iconLocations: {
    none: 'none',
    first: 'first',
    last: 'last'
  },
  imageAreas: {
    bottom: 'bottom',
    none: 'none',
    top: 'top',
  },
  imageLocations: {
    bottom: 'bottom',
    bottomLeft: 'bottomLeft',
    bottomRight: 'bottomRight',
    inline: 'inline',
    left: 'left',
    none: 'none',
    right: 'right',
    top: 'top',
    topLeft: 'topLeft',
    topRight: 'topRight',
  },
  imageMapCollectionNames: {
    locations: 'locations'
  },
  imageSizes: {
    avatar: 'avatar',
    mini: 'mini',
    tiny: 'tiny',
    small: 'small',
    medium: 'medium',
    large: 'large',
    huge: 'huge',
    massive: 'massive',
  },
  imageTypes: {
    avatar: 'avatar',
    normal: 'normal',
    bordered: 'bordered',
  },
  imageMapTypes: {
    courtMapping: 'courtMapping',
    itemLocation: 'itemLocation',
    itemMapping: 'itemMapping',
    locationMapping: 'locationMapping',
    zoneMapping: 'zoneMapping'
  },
  itemLinkTypes: {
    linkProfile: 'linkProfile',
    linkItems: 'linkItems',
    linkAllItems: 'linkAllItems',
  },
  itemListTypes: {
    array: 'array',
    map: 'map',
    props: 'props',
    names: 'names'
  },
  itemPermissionActionTypes: {
    clone: 'clone',
    notification: 'notification',
    permission: 'permission'
  },
  itemWrapTypes: {
    container: 'container',
    card: 'card',
  },
  landingPageLocations: {
    normalFirst: 'normalFirst',
    normalLast: 'normalLast',
    appUser: 'appUser',
  },
  layoutTypes: {
    golfEvent: 'golfEvent',
    golfTournament: 'golfTournament',
    icons: 'icons',
    pageList: 'pageList',
    pageIcons: 'pageIcons',
    singlePage: 'singlePage',
    horizontalSliders: 'horizontalSliders',
    swipeable: 'swipeable',
    swipeableHidden: 'swipeableHidden',
  },
  listActionTypes: {
    delete: 'delete',
    emailSignInLink: 'emailSignInLink'
  },
  listOptions: {
    countries: 'countries',
    decimal: 'decimal',
    font: 'font',
    list: 'list',
    number: 'number',
    team: 'team',
    time: 'time',
    time24: 'time24',
    usaStates: 'usaStates',
  },
  listPropTypes: {
    tags: 'tags',
    types: 'types',
  },
  logTypes_googleSheets: {
    collectionPath: 'collectionPath',
    data: 'data',
    dbPath: 'dbPath',
    googleSheetsDoc: 'googleSheetsDoc',
    itemRefAndData: 'itemRefAndData',
    rangeData: 'rangeData',
    rangeDataItem: 'rangeDataItem',
  },
  manifestDisplayTypes: {
    browser: 'browser',
    fullscreen: 'fullscreen',
    minimalUi: 'minimal-ui',
    standalone: 'standalone',
  },
  mapTypes: {
    array: 'array',
    dropDownMap: 'dropDownMap',
    map: 'map',
    arrayMulti: 'arrayMulti',
  },
  marginTypes: {
    none: 'none',
    thinnest: 'thinnest',
    thin: 'thin',
    normal: 'normal',
    wide: 'wide',
    veryWide: 'veryWide',
  },
  maskDisplayTypes: {
    icon: 'icon',
    label: 'label',
    line: 'line'
  },
  mediaDeviceTypes: {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
  },
  menuCaptionSources: {
    mainData: 'mainData',
    fixed: 'fixed',
  },
  menuDisplayTypes: {
    menuSidebars: 'menuSidebars',
    menuButtons: 'menuButtons',
  },
  menuLocations: {
    bottom: 'bottom',
    top: 'top',
    topFixed: 'topFixed',
  },
  menuPermissionTypes: {
    add: 'add',
    addImage: 'addImage',
    allClients: 'allClients',
    allEvents: 'allEvents',
    approve: 'approve',
    appSettings: 'appSettings',
    clients: 'clients',
    create: 'create',
    createMessage: 'createMessage',
    dataImport: 'dataImport',
    delete: 'delete',
    edit: 'edit',
    editImage: 'editImage',
    eventSettings: 'eventSettings',
    home: 'home',
    listModify: 'listModify',
    modify: 'modify',
    other: 'other',
    paste: 'paste',
    projectImport: 'projectImport',
    public: 'public',
    ribbons: 'ribbons',
    showAllProps: 'showAllProps',
    sort: 'sort',
    todo: 'todo',
    uploadDocument: 'uploadDocument',
    uploadImage: 'uploadImage',
    uploadMap: 'uploadMap',
    uploadMapProps: 'uploadMapProps',
    viewSettings: 'viewSettings',
  },
  menuTypes: {
    none: 'none',
    apply: 'apply',
    applyColor: 'applyColor',
    block: 'block',
    propItem: 'propItem',
    propSections: 'propSections',
    viewItem: 'viewItem',
    viewItemData: 'viewItemData',
  },
  messageTypes: {
    discussion: 'discussion',
    multi: 'multi',
    notification: 'notification',
    singleMessage: 'singleMessage',
  },
  notificationAreaTypes: {
    header: 'header',
    bottomRight: 'bottomRight',
  },
  notificationDeliveryTypes: {
    email: 'email',
    inApp: 'inApp',
    push: 'push',
    sms: 'sms',
  },
  notificationDisplayTypes: {
    popup: 'popup',
    container: 'container',
  },
  notificationFrequencyTypes: {
    daily: 'daily',
    hourly: 'hourly',
    monthly: 'monthly',
    weekly: 'weekly',
  },
  notificationOriginTypes: {
    appNotification: 'appNotification',
    uiItem: 'uiItem',
  },
  notificationPositions: {
    modal: 'modal',
    slideOut: 'slideOut',
    topOfPage: 'topOfPage',
    topAndSide: 'topAndSide',
  },
  notificationRecipientTypes: {
    dataList: 'dataList',
    staticViews: 'staticViews',
  },
  notificationScheduleTypes: {
    direct: 'direct',
    scheduled: 'scheduled',
  },
  opacityTypes: {
    none: 'none',
    lightest: 'lightest',
    light: 'light',
    medium: 'medium',
    dark: 'dark',
    darkest: 'darkest',
  },
  pageAreaTypes: {
    all: 'all',
    global: 'global',
    page: 'page',
  },
  pageConstraintTypes: {
    none: 'none',
    arrayContains: 'arrayContains',
    arrayContainsProp: 'arrayContainsProp',
    dataParentKeys: 'dataParentKeys',
    equalTo: 'equalTo',
    propEqualTo: 'propEqualTo',
  },
  permissionColors: {
    auth: 'green',
    link: 'blue',
    profileOnly: 'grey',
    profileAttendee: 'blue',
    profileVerified: 'green',
    public: 'green',
    search: 'blue',
  },
  permissionTypes: {
    allowAdd: 'allowAdd',
    allowEdit: 'allowEdit',
  },
  previewTypes: {
    card: 'card',
    dataFilters: 'dataFilters',
    display: 'display',
    golferTeamSelect: 'golferTeamSelect',
    header: 'header',
    list: 'list',
    manifest: 'manifest',
    profile: 'profile',
    sectionBody: 'sectionBody',
    sectionHeader: 'sectionHeader',
    signIn: 'signIn',
    splashScreen: 'splashScreen',
  },
  profileImageLocations: {
    alternate: 'alternate',
    left: 'left',
    right: 'right',
    top: 'top',
  },
  profileSelectTypes: {
    accessLevels: 'accessLevels',
    appProfile: 'appProfile',
    appProfiles: 'appProfiles',
    attendees: 'attendess',
    auth: 'auth',
    authentication: 'authentication',
    clientProfiles: 'clientProfiles',
    profileAccess: 'profileAccess',
    profiles: 'profiles',
  },
  projectStyles: {
    actionButton: 'actionButton',
    actionItems: 'actionItems',
    alphabet: 'alphabet',
    appBanner: 'appBanner',
    appBanners: 'appBanners',
    appInstallation: 'appInstallation',
    background: 'background',
    bottomMenu: 'bottomMenu',
    defaultTheme: 'defaultTheme',
    desktop: 'desktop',
    desktopFooter: 'desktopFooter',
    filters: 'filters',
    gridIcons: 'gridIcons',
    gridSettingsIcons: 'gridSettingsIcons',
    gridViewItemIcons: 'gridViewItemIcons',
    listItems: 'listItems',
    manifest: 'manifest',
    navigation: 'navigation',
    pageItemDimmer: 'pageItemDimmer',
    saveButtons: 'saveButtons',
    sideMenu: 'sideMenu',
    signOut: 'signOut',
    splashScreen: 'splashScreen',
    subMenu: 'subMenu',
    tabs: 'tabs',
    tabsAppUser: 'tabsAppUser',
    topMenu: 'topMenu',
  },
  profileTypes: {
    adminProfiles: 'adminProfiles',
    clientProfiles: 'clientProfiles',
  },
  projectTypes: {
    event: 'event',
    giftCard: 'giftCard',
    golf: 'golf',
    menu: 'menu',
    sports: 'sports',
  },
  propDirectionTypes: {
    column: 'column',
    row: 'row',
  },
  propDisplayTypes: {
    none: 'none',
    captions: 'captions',
    labelCaptions: 'labelCaptions',
    noCaptions: 'noCaptions',
  },
  publishedTypes: {
    notPublished: 'notPublished',
    published: 'published'
  },
  pushDisplayTypes: {
    small: 'small',
    large: 'large',
    full: 'full'
  },
  scheduleDisplayTypes: {
    normal: 'normal',
    vertical: 'vertical',
    horizontal: 'horizontal',
  },
  sectionAlignmentTypes: {
    none: 'none',
    center: 'center',
    left: 'left',
    right: 'right',
  },
  qrTypes: {
    profile: 'profile',
    schedule: 'schedule',
    page: 'page',
  },
  qrCodeTypes: {
    appLink: 'appLink',
    appUser: 'appUser',
    itemData: 'itemData',
  },
  queryTypes: {
    arrayContains: 'arrayContains',
    arrayContainsAny: 'arrayContainsAny',
    equalTo: 'equalTo',
    greaterThan: 'greaterThan',
    greaterThanOrEqualTo: 'greaterThanOrEqualTo',
    in: 'in',
    lessThan: 'lessThan',
    lessThanOrEqualTo: 'lessThanOrEqualTo',
    notEqualTo: 'notEqualTo',
    notIn: 'notIn',
    none: 'none',
  },
  relationshipTypes: {
    aunt: 'brother',
    brother: 'brother',
    cousin: 'cousin',
    father: 'father',
    grandFather: 'grandFather',
    grandMother: 'grandMother',
    greatGrandFather: 'greatGrandfather',
    greatGrandMother: 'greatGrandMother',
    mother: 'mother',
    nephew: 'nephew',
    niece: 'niece',
    other: 'other',
    sister: 'sister',
    uncle: 'uncle',
  },
  roundTypes: {
    alternateShot: 'alternateShot',
    alternateShot1: 'alternateShot1',
    alternateShot2: 'alternateShot2',
    bestBall: 'bestBall',
    bestBall1: 'bestBall1',
    bestBall2: 'bestBall2',
    scramble: 'scramble',
    singles: 'singles'
  },
  rssFeedTypes: { ...rssFeedTypes },
  sectionHeaderNames: {
    name: 'name',
    details: 'details',
    status: 'status',
  },
  sectionHeaderTypes: {
    none: 'none',
    block: 'block',
    blockAttached: 'blockAttached',
    clearing: 'clearing',
    divider: 'divider',
    hidden: 'hidden',
    section: 'section',
  },
  sectionBodyTypes: {
    none: 'none',
    captions: 'captions',
    inline: 'inline',
    header: 'header',
    labelCaptions: 'labelCaptions',
    noCaptions: 'noCaptions',
    table: 'table',
  },
  sectionDisplayTypes: {
    none: 'none',
    address: 'address',
    addressMap: 'addressMap',
    bio: 'bio',
    description: 'description',
    document: 'document',
    gallery: 'gallery',
    image: 'image',
    itemList: 'itemList',
    golfTeeboxes: 'golfTeeboxes',
    golfScorecard: 'golfScorecard',
    hotelInfo: 'hotelInfo',
    normal: 'normal',
    pdf: 'pdf',
    separate: 'separate',
    urlList: 'urlList',
    video: 'video',
  },
  settingsCategoryTypes: {
    data: 'data',
    display: 'display',
  },
  settingsMenuGroupTypes: {
    accessManagement: 'accessManagement',
    appDashboard: 'appDashboard',
    appManagement: 'appManagement',
    appModules: 'appModules',
    appOptions: 'appOptions',
    appUserManagement: 'appUserManagement',
    authSettings: 'authSettings',
    console: 'console',
    dataManagement: 'dataManagement',
    developerOptions: 'developerOptions',
    emailConfiguration: 'emailConfiguration',
    eventDashboard: 'eventDashboard',
    external: 'external',
    fieldTripManagement: 'fieldTripManagement',
    fileManagement: 'fileManagement',
    globalAppHelp: 'globalAppHelp',
    globalAppManagement: 'globalAppManagement',
    golfManagement: 'golfManagement',
    googleOptions: 'googleOptions',
    layout: 'layout',
    layoutType: 'layoutType',
    none: 'none',
    packages: 'packages',
    pageManagement: 'pageManagement',
    pageOptions: 'pageOptions',
    parentManagement: 'parentManagement',
    permissionsConsole: 'permissionsConsole',
    projectActions: 'projectActions',
    projectManagement: 'projectManagement',
    projectModules: 'projectModules',
    project: 'project',
    settings: 'settings',
    sportsManagement: 'sportsManagement',
    stylingAndThemes: 'stylingAndThemes',
    quickLinks: 'quickLinks',
    view: 'view',
    viewItems: 'viewItems',
  },
  settingsOriginTypes: {
    app: 'app',
    normal: 'normal',
    page: 'page',
  },
  showTypes: {
    show: 'show',
    showDesktop: 'showDesktop',
  },
  directSignInTypes: {
    email: 'email',
    phoneNumber: 'phoneNumber',
  },
  signInProviderTypes: {
    emailLink: 'emailLink',
    emailPassword: 'emailPassword',
    emailPasswordOrPhoneNumber: 'emailPasswordOrPhoneNumber',
    google: 'google',
    passcode: 'passcode',
    passcodeLink: 'passcodeLink',
    phoneNumber: 'phoneNumber',
  },
  siteAuthorizationTypes: {
    authorized: 'authorized',
    passcode: 'passcode',
    public: 'public',
    restricted: 'restricted',
    temp: 'temp',
  },
  sizeTypes: {
    none: 'none',
    tiny: 'tiny',
    small: 'small',
    normal: 'normal',
    large: 'large',
    big: 'big',
    huge: 'huge',
    massive: 'massive',
  },
  staticDataCollectionTypes: {
    clients: 'clients',
    events: 'events',
  },
  statusTypes: {
    active: 'active',
    approved: 'approved',
    beta: 'beta',
    complete: 'complete',
    delete: 'delete',
    hidden: 'hidden',
    inactive: 'inactive',
    pending: 'pending',
  },
  storageLocationTypes: {
    clientProfiles: 'clientProfiles',
    clientProfile: 'clientProfile',
    event: 'event',
    eventGallery: 'eventGallery',
    fullGallery: 'fullGallery',
    pageDirect: 'pageDirect',
    pageGallery: 'pageGallery',
    pagePdf: 'pagePdf',
    profiles: 'profiles',
    profileGallery: 'profileGallery',
  },
  storageTypes: {
    icon: 'icon',
    image: 'image',
    floormap: 'floormap',
    manifest: 'manifest',
    pdf: 'pdf',
  },
  themeItems: {
    none: 'none',
    active: 'active',
    alternative: 'alternative',
    background: 'background',
    main: 'main',
    secondary: 'secondary',
  },
  thicknessTypes: {
    none: 'none',
    thin: 'thin',
    normal: 'normal',
    thick: 'thick',
  },
  topicOriginTypes: {
    page: 'page',
    global: 'global',
    manager: 'manager'
  },
  adminTopicTypes: {
    appUserLogin: 'appUserLogin',
    appUserCheckIn: 'appUserCheckIn',
  },
  ticketDisplayTypes: {
    multi: 'multi',
    single: 'single',
  },
  dataTransferTypes: {
    all: 'all',
    collectionsData: 'collectionsData',
    documents: 'documents',
    event: 'event',
    others: 'others',
    settings: 'settings',
  },
  storageTransferTypes: {
    clientProfileStorage: 'clientProfileStorage',
    eventFolderToClient: 'eventFolderToClient',
    clientToClient: 'clientToClient',
    clientProfilesToClientProfiles: 'clientProfilesToClientProfiles',
    eventStorage: 'eventStorage',
  },
  transitionTypes: {
    none: 'none',
    css: 'css'
  },
  trueFalse: {
    true: 'true',
    false: 'false'
  },
  viewSettingTypes: {
    appAccessRequests: 'appAccessRequests',
    appActions: 'appActions',
    appData: 'appData',
    appOptions: 'appOptions',
    appPermissions: 'appPermissions',
    appSignIn: 'appSignIn',
    appUserSignInEmails: 'appUserSignInEmails',
    auth: 'auth',
    banner: 'banner',
    baseSettings: 'baseSettings',
    dataCollections: 'dataCollections',
    dataFilters: 'dataFilters',
    dashboard: 'dashboard',
    eventAdmins: 'eventAdmins',
    files: 'files',
    general: 'general',
    globalPageItems: 'globalPageItems',
    googleActions: 'googleActions',
    googleSheets: 'googleSheets',
    json: 'json',
    layout: 'layout',
    mappedCollections: 'mappedCollections',
    menu: 'menu',
    productionActions: 'productionActions',
    profiles: 'profiles',
    propOrganize: 'propOrganize',
    props: 'props',
    propSection: 'propSection',
    propSections: 'propSections',
    query: 'query',
    raw: 'raw',
    rssFeeds: 'rssFeeds',
    sectionPermissions: 'sectionPermissions',
    sections: 'sections',
    settingsActions: 'settingsActions',
    settingsConsole: 'settingsConsole',
    settingsOptions: 'settingsOptions',
    sideMenu: 'sideMenu',
    styles: 'styles',
    themedItems: 'themedItems',
    ui: 'ui',
    uiSections: 'uiSections',
    viewActions: 'viewActions',
    viewItems: 'viewItems',
    views: 'views',
  },
  timeTypes: {
    ampm: 'ampm',
    full: 'full',
  },
  sectionLayoutTypes: {
    swipeable: 'swipeable',
    vertical: 'vertical',
  },
  semSizes: {
    mini: 'mini',
    tiny: 'tiny',
    small: 'small',
    large: 'large',
    huge: 'huge',
    massive: 'massive',
  },
  signInFormTypes: {
    appUserSettings: 'appUserSettings',
    confirmation: 'confirmation',
    resetPassword: 'resetPassword',
    signInAuthPasscode: 'signInAuthPasscode',
    signInEmail: 'signInEmail',
    signInEvent: 'signInEvent',
    signInEventPassword: 'signInEventPassword',
    signInGlobal: 'signInGlobal',
    signInPassword: 'signInPassword',
    signInPhoneNumber: 'signInPhoneNumber',
    signInTemp: 'signInTemp',
    signInUserName: 'signInUserName',
    signUpEmail: 'signUpEmail',
    signUpEmailLink: 'signUpEmailLink',
    signUpPhoneNumber: 'signUpPhoneNumber',
  },
  signInOptionTypes: {
    email: 'email',
    phoneNumber: 'phoneNumber',
  },
  swipeContentTypes: {
    pageAreaContent: 'pageAreaContent',
    groupedItems: 'groupedItems',
    normalItems: 'normalItems'
  },
  tagPositions: {
    bottom: 'bottom',
    top: 'top'
  },
  toggleTypes: {
    clone: 'clone',
    modifySubArray: 'modifySubArray',
    sendMessage: 'sendMessage'
  },
  videoConferenceTypes: {
    microsoftTeams: 'microsoftTeams',
    jitsi: 'jitsi',
    zoom: 'zoom'
  },
  viewDisplayTypes: {
    appProfile: 'appProfile',
    signIn: 'signIn',
  },
  viewPermissionTypes: {
    allow: 'allow',
    allowOverride: 'allowOverride',
    allowTemp: 'allowTemp',
    allowToAppUserAdmin: 'allowToAppUserAdmin',
    allow_clientLimited: 'allow_clientLimited',
    deny: 'deny',
    deny_noEventKey: 'deny_noEventKey',
    deny_noClientKey: 'deny_noClientKey',
  },
  voteTypes: {
    yesNo: 'yesNo',
    multipleChoice: 'multipleChoice',
    select: 'select',
  },
  waiverTypes: {
    force: 'force',
    once: 'once',
  },
  ...appUserEnums,
  dispatchConfirmationTypes,
}
