import { golfEnums } from "../../../enums/golfEnums"
import { getMatchHandicaps } from "../../../golfCalcs/calcMatchStatus"

export const getGolfMatchInfo = (golfMatch, holes) => {
  const { team1: golfers_team1, team2: golfers_team2, round } = golfMatch ?? {}
  const golfers = { team1: {}, team2: {} }
  if (golfers_team1) { ammendMatchGolfers(golfers, 'team1', golfers_team1) }
  if (golfers_team2) { ammendMatchGolfers(golfers, 'team2', golfers_team2) }
  const matchLine = getMatchLine(golfers)
  const matchHcps = getMatchHandicaps(golfers, round)
  const allGolfersStroking = holes ? getAllGolfersStroking(golfers, holes, matchHcps, round) : {}
  return { golfers, matchLine, matchHcps, allGolfersStroking }
}

const ammendMatchGolfers = (gs, teamName, matchGolfers) => {
  Object.keys(matchGolfers).forEach((key, index) => {
    const mg = matchGolfers[key]
    const {
      courseHandicap,
      courseTeebox,
      firstName,
      handicapIndex,
      matchHandicap,
      lastName,
      position,
      shortName,
      tournamentHandicapIndex,
      _authData,
    } = mg
    gs[teamName][key] = {
      courseHandicap,
      courseTeebox,
      handicapIndex,
      key: key,
      matchHandicap,
      name: firstName + ' ' + lastName,
      position,
      shortName,
      tournamentHandicapIndex,
      _authData
    }
  })
}

const getMatchLine = (gs) => {
  if (gs) {
    let _matchLine = ''
    Object.keys(gs).forEach((key, index) => {
      const golfers_team = gs[key]
      Object.keys(golfers_team).forEach((key, gIndex) => {
        const g = golfers_team[key]
        let { name: fullName, lastName, firstName } = g
        const fullName_split = fullName ? fullName.split(' ') : []
        if (!firstName && fullName_split.length >= 2) { firstName = fullName_split[0] }
        if (!lastName && fullName_split.length >= 2) { lastName = fullName_split[1] }
        if (fullName_split.length === 3) (lastName = fullName_split[1] + ' ' + fullName_split[2])
        const fni = firstName.substring(0, 1)
        if (lastName) { _matchLine += fni + '. ' + lastName }
        if (gIndex === 0 && Object.keys(golfers_team).length > 1) { _matchLine += ' and ' }
      })
      if (index === 0) { _matchLine += ' vs. ' }
    })
    return _matchLine
  }
}


const getAllGolfersStroking = (golfers, holes, _matchHcps, round) => {
  const { lowHandicapG } = _matchHcps ? _matchHcps : {}
  const { lowHandicap } = lowHandicapG ? lowHandicapG : {}
  const items = {}
  for (let hn = 1; hn <= 18; hn++) {
    items[hn] = getGolfersStroking(golfers, holes, hn, lowHandicap, round, _matchHcps)
  }
  return items
}

const getGolfersStroking = (golfers, holes, activeHoleNumber, lowHandicap, round, _matchHcps) => {
  const hole = activeHoleNumber && holes ? holes[activeHoleNumber] : null
  const { handicap: handicap_hole } = hole ? hole : {}
  const _golfersStroking = []
  switch (round) {
    case golfEnums.roundTypes.alternateShot:
    case golfEnums.roundTypes.alternateShot1:
    case golfEnums.roundTypes.alternateShot2:
      const { team1Handicaps, team2Handicaps } = _matchHcps ? _matchHcps : {}
      const { team1: golfers_team1, team2: golfers_team2 } = golfers ? golfers : {}
      if (golfers_team1 && team1Handicaps && team1Handicaps.actual_alt >= handicap_hole) {
        ammendGolferStrokes(golfers_team1, _golfersStroking, 1)
      }
      if (golfers_team2 && team2Handicaps && team2Handicaps.actual_alt >= handicap_hole) {
        ammendGolferStrokes(golfers_team2, _golfersStroking, 2)
      }
      break;
    default:
      if (golfers) {
        Object.keys(golfers).forEach((gk, index) => {
          const tg = golfers[gk]
          if (tg) {
            Object.keys(tg).forEach(tgk => {
              const g = tg[tgk]
              const { courseHandicap, matchHandicap } = g
              const ch = matchHandicap - lowHandicap
              if (ch >= handicap_hole) {
                _golfersStroking.push({ team: gk, name: g.shortName, teamNumber: index + 1 })
              }
            })
          }
        })
      }
  }

  return _golfersStroking
}

const ammendGolferStrokes = (golfers, _golfersStroking, teamNumber) => {
  Object.keys(golfers).forEach(gk => {
    const g = golfers[gk]
    _golfersStroking.push({ team: gk, name: g.shortName, teamNumber })
  })
}
