import _ from 'lodash';
import React, { useContext } from 'react';
import { Divider, Icon, Image, Label, Segment } from 'semantic-ui-react';
import { uniqueKey } from '../../common/keys';
import { propHelpers } from '../../common/tester';
import { gEnums } from '../../enums/globalEnums';
import { getSectionClass } from '../../styles/formatting';
import { renderHtml } from '../html/renderHtml';
import ImageGallery from '../imaging/ImageGallery';
import { createSemPropElements } from '../props/propsCreate';
import { getAllowedProps, getAllowedPropsElements } from '../props/propsShown';
import TableList from '../tables/TableList';
import SectionHeader from './SectionHeader';
import { getCardColor, getCardStyle } from './semHelpers';

const StarRating = React.lazy(() => import('../ratings/StarRating'));
/**
 * 
 * @param {object} props (parentContext, componentContexts, itemData, showEventStatus)
 * @returns Object of Sections(key: items (array of elements))
 */
const GetSectionElements = (props) => {

  const { parentContext, itemData, showEventStatus, componentContexts } = props
  const { states, fns } = parentContext


  // componentContexts
  const { frameworkContext, uiItemContext, displayContext } = componentContexts ?? {}

  const { paps_state, page_state, appSettings_state } = states ?? {}
  const { page_fns } = fns ?? {}
  const { getGviDeps, pushUrl } = page_fns ?? {}

  // appSettingsContext  
  const { highlightThemesOn, themeSelected } = appSettings_state ?? {}

  // frameworkContext 
  const { framework_state } = frameworkContext ?? {}
  const { desktopMode } = framework_state ?? {}

  // papsContext  
  const { lastPathName, viewKey, view } = paps_state ?? {}

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems, aps_page } = pageSettings
  const { viewItems: viewItems_page } = aps_page
  const { desktop, display: display_global, propItems: gPropItems, appUserPermissions } = aps_global ?? {}
  const { hideEmptySections, propsToSeparate: propsToSeparate_global } = display_global ?? {}
  const { useDefaultLayoutType, defaultDisplayType } = desktop ?? {}
  const { propValueColors } = gPropItems ?? {}

  const { allowRating, ratingCollections } = appUserPermissions ?? {}

  const pageViewItemKeys = viewItems_page ? Object.keys(viewItems_page) : []

  // uiItemContext 
  const { item_state, getParents } = uiItemContext ?? {}
  const { viewItem, propSections_allowed } = item_state ?? {}

  const { key: viewItemKey, display, desktopDisplay } = viewItem ?? {}
  const _display = desktopMode && desktopDisplay ? desktopDisplay : display
  const { imageProp, showIfLinked, showTagDash } = _display ?? {}
  const _showRating = allowRating && ratingCollections && ratingCollections.includes(view)

  let { displayType, cardDisplayType } = _display ?? {}

  if (desktopMode && useDefaultLayoutType && defaultDisplayType) { displayType = defaultDisplayType }

  const viewItem_app = aps_viewItems && aps_viewItems[viewItemKey] ? aps_viewItems[viewItemKey] : {}
  const { dataLinks, propSections: app_viewItem_propSections } = viewItem_app ?? {}

  // displayContext 
  const { display_state } = displayContext ?? {}
  const { displayProps } = display_state ?? {}
  const { props_viewItem, ddGroupKey, ddProps } = displayProps ?? {}

  let sectionData = itemData

  if (itemData && ddGroupKey && lastPathName && itemData[ddGroupKey] && itemData[ddGroupKey][lastPathName]) {
    sectionData = itemData[ddGroupKey][lastPathName]
  }

  const { ratingData } = itemData ?? {}
  let itemIsLinked = false
  let linkIcon = 'linkify'

  if (showIfLinked && dataLinks && dataLinks.links) {
    Object.keys(dataLinks.links).forEach((plKey) => {
      const dl = dataLinks.links[plKey]
      if (dl.viewItem === viewItem.key && dl.itemLinkType) {
        const ad = dl.arrayName ? itemData[dl.arrayName] : itemData[viewItem.key]
        itemIsLinked = _.includes(ad, viewItemKey) ? true : false
        if (dl.icon) { linkIcon = dl.icon }
      }
      if (dl.viewItem === view && dl.itemLinkType) {
        const ad = dl.arrayName ? itemData[dl.arrayName] : itemData[view]
        itemIsLinked = _.includes(ad, viewKey) ? true : false
        if (dl.icon) { linkIcon = dl.icon }
      }
    })
  }

  if (itemData && ddProps) {
    let dd = { ...itemData }
    Object.keys(ddProps).forEach(key => {
      const v = ddProps[key]
      if (dd[key] && dd[key][v]) {
        dd = dd[key][v]
      }
    })
    sectionData = dd
  }

  const _sections = {};

  if (propSections_allowed) {

    // loop the sections
    _.each(propSections_allowed, (propSection_allowed, propSectionKey) => {

      let allowSection = true

      const { _props_allowed, _styles } = propSection_allowed ?? {}
      const { sectionBody, sectionHeader, sectionDisplay, sectionBodyStyle } = _styles ?? {}

      let { bodyDisplayType } = sectionBody ?? {}
      let { separator, headerDisplayType, dividerSize } = sectionHeader ?? {}
      let { sectionDisplayType, sectionHeaderCaption } = sectionDisplay ?? {}

      const propSection_app = app_viewItem_propSections[propSectionKey]

      const isNameSection = propSection_allowed.key === gEnums.sectionHeaderNames.name

      const { secret } = propSection_app ?? {}

      let _sectionElements = []

      let hasSectionUi;

      const propItems_shown = getAllowedPropsElements(parentContext, componentContexts, viewItem, sectionData, _props_allowed)
      const sectionSemElements = createSemPropElements(parentContext, propSectionKey, propItems_shown, propSection_allowed, sectionBody, componentContexts, sectionData, separator, showEventStatus, hasSectionUi, showTagDash, secret)

      if (hideEmptySections && propSectionKey !== 'name' && sectionSemElements && sectionSemElements.length === 0) { allowSection = false }

      if (propsToSeparate_global && sectionSemElements && sectionSemElements.length === 1 && propsToSeparate_global.includes(propSectionKey)) {
        sectionDisplayType = gEnums.sectionDisplayTypes.separate
      }

      // section header !!!
      // if (allowSection) {

      if (allowSection) {
        if (headerDisplayType && headerDisplayType !== gEnums.sectionHeaderTypes.none) {

          switch (cardDisplayType) {
            case gEnums.cardDisplayTypes.mini:
              bodyDisplayType = gEnums.sectionBodyTypes.none
              break;

            default:
              switch (sectionDisplayType) {
                case gEnums.sectionDisplayTypes.separate:
                  break;
                default:
                  const shProps = { propSection: propSection_allowed, propSectionKey, itemData, sectionHeader, componentContexts, highlightThemesOn, themeSelected, secret, sectionDisplayType }
                  _sectionElements.push(<SectionHeader key={uniqueKey('ss', 'sh', propSectionKey)} shProps={shProps} />)
              }
          }
        }

        let sectionBodyCn = 'sec-content ' + cardDisplayType
        sectionBodyCn = hasSectionUi ? sectionBodyCn : getSectionClass(sectionBodyCn, sectionBody, hasSectionUi)

        if (isNameSection && _showRating) { sectionBodyCn += ' lot' }

        const snc = { style: sectionBodyStyle, className: sectionBodyCn }

        switch (displayType) {

          case gEnums.displayTypes.imageOnly:
            _sectionElements.push(<Image key={uniqueKey('ss', 'i', propSectionKey)} src={sectionData[imageProp]} alt={'image'}></Image>)
            break;

          default:

            if (isNameSection && itemIsLinked) { sectionSemElements.unshift(<Icon color={'blue'} name={linkIcon} />) }
            if (isNameSection && _showRating) { sectionSemElements.push(<StarRating key={uniqueKey('ss', 'sr', propSectionKey)} ratingData={ratingData} />) }

            switch (sectionDisplayType) {
              case gEnums.sectionDisplayTypes.golfScorecard:
                addColor(getGviDeps, sectionSemElements, viewItem, sectionData, propValueColors, getParents, propSectionKey)
                allowSection && _sectionElements.push(cssDivWrap(sectionSemElements, snc, propSection_allowed))
                break;

              case gEnums.sectionDisplayTypes.separate:
                let _element;
                let _separateData = itemData[propSectionKey]
                _element = _separateData
                if (propHelpers.isUrl(_separateData)) { _element = <div className={'ip-url'} onClick={e => pushUrl(e, _separateData)} >{_separateData}</div > }
                if (propHelpers.isHtml(_separateData)) { _element = renderHtml(_separateData) }
                _sectionElements.push(<Segment key={uniqueKey('ss', 'sh', 'sep', propSectionKey)} basic>{_element ? _element : 'No ' + _.startCase(propSectionKey)}</Segment>)
                break;

              case gEnums.sectionDisplayTypes.bio:
                // sectionItems = []
                let _bio = itemData.bio
                if (propHelpers.isHtml(_bio)) { _bio = renderHtml(_bio) }
                _sectionElements.push(<Segment key={uniqueKey('ss', 'sh', 'bio', propSectionKey)} basic>{_bio ? _bio : 'No Bio'}</Segment>)
                break;

              case gEnums.sectionDisplayTypes.description:
                let _description = itemData.description
                if (propHelpers.isHtml(_description)) { _description = renderHtml(_description) }
                _sectionElements.push(<Segment key={uniqueKey('ss', 'sh', 'desc', propSectionKey)} basic>{_description ? _description : 'No Description'}</Segment>)
                break;

              case gEnums.sectionDisplayTypes.gallery:
                _sectionElements.push(<ImageGallery
                  key={uniqueKey('ss', 'img', propSectionKey)}
                  storageLocationType={gEnums.storageLocationTypes.profileGallery}
                  storageType={gEnums.storageTypes.image}
                  viewOnly={true}
                />)
                break;


              default:
                switch (bodyDisplayType) {
                  case gEnums.sectionBodyTypes.header:
                  case gEnums.sectionBodyTypes.inline:
                    // this is where the ITEM comes into play    
                    addColor(getGviDeps, sectionSemElements, viewItem, sectionData, propValueColors, getParents, propSectionKey)
                    allowSection && _sectionElements.push(cssDivWrap(sectionSemElements, snc, propSection_allowed))
                    break;

                  case gEnums.sectionBodyTypes.captions:
                  case gEnums.sectionBodyTypes.labelCaptions:
                  case gEnums.sectionBodyTypes.none:
                    sectionSemElements && sectionSemElements.length > 0 && _sectionElements.push(cssDivWrap(sectionSemElements, snc, propSection_allowed))
                    break;

                  case gEnums.sectionBodyTypes.table:
                    const tl = <TableList key={uniqueKey('ss', 't', propSectionKey)} sectionSemElements={sectionSemElements} snc={snc} />
                    _sectionElements.push(tl)
                    break;

                  default:
                    sectionSemElements && sectionSemElements.length > 0 && _sectionElements.push(cssDivWrap(sectionSemElements, snc, propSection_allowed))
                }
            }
        }

        if (_sectionElements && _sectionElements.length > 0) {
          switch (sectionDisplayType) {
            case gEnums.sectionDisplayTypes.separate:
              // case gEnums.sectionDisplayTypes.gallery:
              _sectionElements.unshift(<Divider key={uniqueKey('ss', 'h', propSectionKey)} className={dividerSize ? 'ds-' + dividerSize : null} horizontal>{sectionHeaderCaption ? sectionHeaderCaption : _.startCase(propSectionKey)}</Divider>)
              break;
            default:
            // nothing
          }
        }

        if (_sectionElements && _sectionElements.length > 0) {
          _sections[propSectionKey] = { items: _sectionElements }
        }
      }
    })
  } else {
    _sections['normal'] = { items: [] }
    // parentContext, componentContexts, viewItem, sectionData, props_section, taggedIgnored, pageViewItemKeys
    const propItems = getAllowedProps(parentContext, componentContexts, viewItem, sectionData, props_viewItem, null, pageViewItemKeys)
    const siprs = createSemPropElements(parentContext, 'normal', propItems, null, null, componentContexts, sectionData)
    _sections['normal'].items.push(siprs)
  }

  return _sections

}

const addColor = (getGviDeps, sectionSemElements, viewItem, itemData, propValueColors, getParents, index) => {

  let prcs = null
  if (propValueColors) { prcs = _.groupBy(propValueColors, 'propItem') }

  const { itemColor } = viewItem
  let { colorDisplayType, usePropColor } = itemColor ?? {}

  if (usePropColor) {
    switch (colorDisplayType) {
      case gEnums.colorDisplayTypes.lineLabel:
      case gEnums.colorDisplayTypes.tagLabel:
        const { propColors } = {}
        const colorProps = {}
        let color = getCardColor(getGviDeps, viewItem, itemData, itemColor, propColors, colorProps, prcs, getParents);
        let csp = getCardStyle(usePropColor, color, colorDisplayType);
        sectionSemElements.unshift(<Label key={uniqueKey('ss', 'pc', index)} className={csp.labelCn} color={color}></Label>)
        break;
      default:
      // nothing
    }
  }
}

const cssDivWrap = (content, snc, propSection, _statusIcon) => {
  if (!snc.className) { snc.className = 'sec-content' }
  snc.className = snc.className.trim()
  const keyy = 'psw-' + propSection.key
  return <div key={uniqueKey('ss', keyy)} {...snc}>{content}</div>
}

export default GetSectionElements