import { AnimatePresence } from 'framer-motion';
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import { addIndexHtmlLinks } from '../../links/AppLinks';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { RoutesContext } from '../cnr/contexts/RoutesContext';
import PageError, { pageErrorReasonTypes } from '../components/alerts/PageError';
import StartDimmer from '../components/alerts/StartDimmer';
import { _animateTypes } from '../motions/AnimateComponent';

/**
 * 
 * @param {object} props 
 * @returns a Route for the page
 */
const AppPage = (props) => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers } = parentContext ?? {}
  const { manifest_state, page_state, transition_state } = states
  const { transition_handlers } = handlers
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { manifest } = aps_global ?? {}
  const { name: name_manifest } = manifest ?? {}

  const { transitions } = transition_state ?? {}
  const transition = transitions ? transitions[_animateTypes.route] : null
  const { showTransition } = transition ?? {}

  // routesContext
  const routesContext = useContext(RoutesContext)
  const { routes_state } = routesContext ?? {}
  const { viewRoutes, genericRoutes } = routes_state ?? {}

  // pageContext 
  const { projectOptions } = aps_global ?? {}
  const { useEventViewRoutes } = projectOptions ?? {}

  const _routes = useEventViewRoutes && 1 === 3 ? viewRoutes : genericRoutes

  useEffect(() => {
    addIndexHtmlLinks(aps_global, manifest_state)
    // if (useCssFile && cssFile) { addCssFile(cssFile) }
    // addCssFile('CssTest.css')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name_manifest]);

  return _routes ? <PageSwitch {...props} routes={_routes} usePageTransitions={showTransition} transition_handlers={transition_handlers} /> : <div></div>
}

/**
 * 
 * @param {object} props 
 * @returns a Route for the page
 */
const PageSwitch = (props) => {

  const { routes, usePageTransitions, transition_handlers } = props ?? {}
  const location = useLocation()

  const [trueLocation, setTrueLocation] = useState(location)
  const [previousLocation, setPreviousLocation] = useState(null);

  const _newRoute = previousLocation ? trueLocation.pathname === previousLocation.pathname : false

  useEffect(() => {
    setTrueLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    setPreviousLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [location]);

  useEffect(() => {
    transition_handlers.handleIsNewRoute(_newRoute)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [_newRoute]);

  if (trueLocation) {
    if (usePageTransitions) {
      return (
        // <RouteContext.Provider value={{ route_state: { newRoute: _newRoute } }}>
        <AnimatePresence exitBeforeEnter>
          <Routes location={trueLocation} key={trueLocation.key}>
            {routes}
            <Route path="*" element={<PageError reason={pageErrorReasonTypes.noPage} />}></Route>
          </Routes>
        </AnimatePresence>
        // </RouteContext.Provider>
      )
    } else {
      return (
        <Routes location={trueLocation} key={trueLocation.key}>
          {routes}
          <Route path="*" element={<PageError reason={pageErrorReasonTypes.noPage} />}></Route>
        </Routes>
      )
    }
  }

  return <StartDimmer />


}

export default AppPage