import _ from 'lodash';
import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';
import { PageDataContext } from '../../../cnr/contexts/PageDataContext';
import { ParentContext } from '../../../cnr/contexts/ParentContext';
import { UiItemContext } from '../../../cnr/contexts/UiItemContext';
import UiSaveButtons from '../../../components/buttons/UiSaveButtons';
import FullPageWrapper from '../../../wrappers/FullPageWrapper';
import Wrapper, { wrapperTypes } from '../../../wrappers/Wrapper';

const UiRequestAccess = (props) => {

  const { direct } = props ?? {}

  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { appUser_state, paps_state, eventInfo_state } = states

  // authContext 
  const { appUser } = appUser_state ?? {}

  // papsContext  
  const { pathName, pathViews } = paps_state ?? {}

  // eventInfoContext
  const { staticViews } = eventInfo_state ?? {}

  // pageDataContext
  const pageDataContext = useContext(PageDataContext)
  const { pageData_state } = pageDataContext ?? {}
  const { currentPageDataCaption } = pageData_state ?? {}

  // uiItemContext
  const uiItemContext = useContext(UiItemContext);
  const { item_handlers } = uiItemContext ?? {}

  const handleRequest = () => item_handlers.handle_fsUpdateRequestAccess(appUser, pathName, currentPageDataCaption)

  const container = () => {
    return <Wrapper
      header={'Would you like to request access to `' + currentPageDataCaption + '`?'}
      content={pathViewsList(pathViews, staticViews)}
      footer={item_handlers && <UiSaveButtons save={{ oc: handleRequest, caption: 'Send Request' }} />}
      wrapperType={wrapperTypes.padded}
      css={{ container: (direct ? 'ah' : '') }}
    />
  }

  const stepWizard = () => {
    return <FullPageWrapper
      content={container()}
      topperCaption={"Request Access"}
    />
  }

  return stepWizard()
}

const pathViewsList = (pathViews, staticViews) => {
  const list = () => Object.keys(pathViews).map(pvKey => (
    <List.Item>
      <List.Icon name='linkify' size='large' verticalAlign='middle' />
      <List.Content>
        <List.Header >{_.startCase(pvKey)}</List.Header>
        <List.Description as='a'>{pathViews[pvKey]}</List.Description>
        {staticViews && staticViews[pvKey] && staticViews[pvKey][pathViews[pvKey]] && <List.Description as='a'>{staticViews[pvKey][pathViews[pvKey]].name}</List.Description>}
      </List.Content>
    </List.Item>
  ))

  return <List divided relaxed>
    {list()}
  </List>
}

export default UiRequestAccess