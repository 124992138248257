import _ from 'lodash';
import { dispatchConfirmationTypes, grts, responseHandlers, responseReducers } from '../../../../global/cnr/reducers/reducerHelpers/dispatchProps';
import { convertHelpers, getFirstObject } from '../../../../global/common/convert';
import { getCalStartDates } from '../../../../global/common/filtering';
import { copyObj } from '../../../../global/common_web/copy';
import { createRefPath_event } from '../../../../global/firestoreData/appData/appRefPaths';
import { createFsDocKey } from '../../../../global/firestoreData/appData/fsAppData';
import { fs_get_doc } from '../../../../global/firestoreData/appData/fsData';
import { createTournamentInfo, sortPlayoffLevelTeams } from '../../helpers/tournamentData';
import { addTeamInfoToTeamRosters, ammendLevelSectionsFromCounts, ammendPlayoffSeeding, ammendSomething, ammendSportsDataFromResult, createLevelsAndSections, createTheSeasonMatches, getCurrentSeasonInfo, getSportsDataInfo, getData_createSeasonInit, getFullPlayoffInfo, getRankingData, getSectionCounts, getSelectedPlayoffInfo, getSelectedTeams, getTeamCountOptions, getTeamsFromLevelSections, ammendCurrentSeasonInfo } from './reducerHelpers/createSportsData';
import { seasonalHelpers, seasonalUpdateTypes } from './reducerHelpers/seasonalSportsData';
import { createSeasonFromGrid } from './reducerHelpers/sportsSeasonMatchGrid';
import { updateSportsTeams } from './reducerHelpers/updateSportsTeams';
import { dataFix } from '../../../../global/common/dataFix';
import { reorderDateStrings } from '../../../../global/common/sorting';

// Mens Basketball - uY1a6FAsOf3sr6IrCNYf
// Mens Volleyball - eDeXpEonuj9pFjghH6ZF
// Mens Volleyball - JXi2tmd5MY3t8T4tFOO6
// Womens Volleyball - V8h4h1sJDQpc4Mb969ko

// const _schedule = {
//   details: {

//   },
//   parentKeys: {

//   },
//   sectionCounts: {

//   },
//   sectionDates: {

//   },
//   crossSectionDates: {

//   },
//   teams: {

//   },
//   scheduleDates: {

//   }
// }

const rts = {
  handleAmmend_schoolsToTeams: 'handleAmmend_schoolsToTeams',
  handleAmmend_schoolTeamInfo_all: 'handleAmmend_schoolTeamInfo_all',
  handleAmmend_teamsToSchools: 'handleAmmend_teamsToSchools',
  handleAmmend_seasonSectionDateGroup: 'handleAmmend_seasonSectionDateGroup',
  handleAmmend_sectionCrossovers: 'handleAmmend_sectionCrossovers',
  handleCreate_levelSections: 'handleCreate_levelSections',
  handleCreate_playoff: 'handleCreate_playoff',
  handleCreate_pools: 'handleCreate_pools',
  handleCreate_seasonFromGrid: 'handleCreate_seasonFromGrid',
  handleCreate_seasonMatches: 'handleCreate_seasonMatches',
  handleCreate_staff: 'handleCreate_staff',
  handleCreate_teamRoster: 'handleCreate_teamRoster',
  handleCreate_tournament: 'handleCreate_tournament',
  handleDbUpdate_seasonDetails: 'handleDbUpdate_seasonDetails',
  handleGet_seasonalData: 'handleGet_seasonalData',
  handleGet_sportsYearSeasonalData: 'handleGet_sportsYearSeasonalData',
  handleInit_sportsData: 'handleInit_sportsData',
  handleInit_teamsInfo: 'handleInit_teamsInfo',
  handleResults_seasonalData: 'handleResults_seasonalData',
  handleResults_sportsData: 'handleResults_sportsData',
  handleResults_sportsYearSeasonalData: 'handleResults_sportsYearSeasonalData',
  handleSeasonal_update: 'handleSeasonal_update',
  handleSectionSelect: 'handleSectionSelect',
  handleSelect_playoffTeam: 'handleSelect_playoffTeam',
  handleSelected_seasonDates: 'handleSelected_seasonDates',
  handleSet_homeAway: 'handleSet_homeAway',
  handleSet_crossover: 'handleSet_crossover',
  handleSet_levelQualifyCount: 'handleSet_levelQualifyCount',
  handleSet_levelSectionCount: 'handleSet_levelSectionCount',
  handleSet_levelSectionTeams: 'handleSet_levelSectionTeams',
  handleSet_levelsFromPaste: 'handleSet_levelsFromPaste',
  handleSet_next: 'handleSet_next',
  handleSet_nominations: 'handleSet_nominations',
  handleSet_seasonDetails: 'handleSet_seasonDetails',
  handleSet_sectionHomeAway: 'handleSet_sectionHomeAway',
  handleSet_selectedAthletes: 'handleSet_selectedAthletes',
  handleSet_selectedTeams: 'handleSet_selectedTeams',
  handleSet_sortedLevels: 'handleSet_sortedLevels',
  handleSet_teamsCreateSeason: 'handleSet_teamsCreateSeason',
  handleSet_teamRosters: 'handleSet_teamRosters',
  handleShow_importFromGs: 'handleShow_importFromGs',
  handleUpdate_alignment: 'handleUpdate_alignment',
  handleUpdate_allStarTeams: 'handleUpdate_allStarTeams',
  handleUpdate_playoffInfo: 'handleUpdate_playoffInfo',
  handleUpdate_pools: 'handleUpdate_pools',
  handleUpdate_poolsFromSeeds: 'handleUpdate_poolsFromSeeds',
  handleUpdate_rankings: 'handleUpdate_rankings',
  handleUpdate_seededTeams: 'handleUpdate_seededTeams',
  handleUpdate_sportsTeams: 'handleUpdate_sportsTeams',
  ...grts,
}

export const sectionCountTypes = {
  checked: 'checked',
  qualifyCount: 'qualifyCount',
  sectionCount: 'sectionCount',
}

export const createScheduleTypes = {
  season: 'season',
  playoff: 'playoff',
}

export const seasonalUpdateSteps = {
  qualifyCount: 'qualifyCount',
  seasonSectionAlignment: 'seasonSectionAlignment',
  seasonDetails: 'seasonDetails',
  seasonSchedule: 'seasonSchedule',
  seasonSectionCount: 'seasonSectionCount',
  seasonSectionDates: 'seasonSectionDates',
  seasonSectionTeams: 'seasonSectionTeams',
  seasonCrossovers: 'seasonCrossovers',
  seasonTeams: 'seasonTeams',
  teams: 'teams',
  year: 'year',
}

// Data Structure
// const schools = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0, 
//   }
// }

// const teams = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0,
//     sports: 0,
//     schools: 0,
//   }
// }

// const _seasonal = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0,
//     sports: 0,
//     sportsYears: 0
//   },
//   sectionDates: {
//     5: [],
//     6: [],
//     7: [],
//     8: [],
//     9: [],
//   },
//   crossSectionDates: {
//     5: [],
//     6: [],
//     7: [],
//     8: [],
//     9: [],
//   }
//   teams: {
//     teamKey1: {
//       levels: '6A',
//       name: 'Team Name',
//       position: 0,
//       sections: 'Section `'
//     }
//   }
// }

// const _teams = {
//   parentKeys: {
//     districts: 0,
//     organizations: 0,
//     sports: 0,
//     sportsYears: 0
//   },
//   teams: {
//     teamKey1: {
//       levels: '6A',
//       name: 'Team Name',
//       position: 0,
//       sections: 'Section `'
//       record: {
//          ...a bunch of data
//       }
//     }
//   }
// }


/** 
 * @param {object} state 
 * @param {object} action 
 * @returns 
 */
export const createSportsReducer = (state, action) => {

  const {
    grid_info,
    levels_info,
    parentDataKeys,
    pathViews,
    pickedTeams,
    playoff_info,
    pools,
    season_info,
    sports_data,
    sportsDetails,
    sportsKey,
    sportPermissions,
  } = state

  let {
    schoolTeamInfo,
    teams_info
  } = state

  const _updateProps = seasonalHelpers.getUpdateProps(sportPermissions, pathViews)

  const _seasonInfo = copyObj(season_info)
  const _levels_info = copyObj(levels_info)
  const { existing, next } = _levels_info
  const _existing = { ...existing }
  const _next = { ...next }

  const _playoffInfo = { ...playoff_info }
  const { playoffTeams } = _playoffInfo ?? {}

  const { type, dispatch, levelItem, sectionItem, itemChecked } = action

  const { matches: seasonMatches } = season_info ?? {}

  const { year: sportsYears } = sportsDetails ?? {}

  const { levels, sections, teams, schools } = sports_data

  const { gridMatches } = grid_info ?? {}

  const _sportsData = { ...sports_data }

  const { selected: teams_selected } = teams_info ?? {}
  const tcc = _next ? getTeamCountOptions(_.pickBy(_next, { checked: true })) : []

  const { handleStopUpdate, handleResults_sportsData, handleSet_levelSectionTeams, handleResults_seasonalData, handleUpdate_poolsFromSeeds, handleUpdate_playoffInfo, handleResults_sportsYearSeasonalData } = createSportsHandlers(dispatch)

  switch (type) {

    // gets the existing data from the database
    case rts.handleInit_sportsData:
      getData_createSeasonInit(pathViews, sportsKey, handleResults_sportsData)
      return { ...state }

    // handles the returned initial data
    case rts.handleResults_sportsData:

      const { _teams, _hasTeams, _school_data } = ammendSportsDataFromResult(_sportsData, action)

      return {
        ...state,
        sports_data: _sportsData,
        teams_info: ammend_teamInfo(state, _teams, tcc, null, null, null, _school_data),
        schools_info: {
          ..._school_data,
        },
        hasTeams: _hasTeams,
        seasonal_current: { ...state.seasonal_current, teams: _teams }
      }

    case rts.handleCreate_seasonFromGrid:
      const gridInfo = createSeasonFromGrid(action.result, state)
      const _grid_info = {
        dataFromGrid: true,
        gridDates: gridInfo.playDates,
        gridMatches: gridInfo.matches,
        matchesFromGrid: true
      }
      const _scts = getSectionCounts(gridInfo.levels_info)
      if (_scts) {
        ammendLevelSectionsFromCounts(gridInfo.levels_info, _scts)
      }

      const _cif = { ...state.currentInfo, selected: gridInfo.teams, nonSelected: {} }

      return {
        ...state,
        levels_info: gridInfo.levels_info,
        showImportFromGs: false,
        grid_info: _grid_info,
        section_info: gridInfo.section_info,
        sectionCounts: _scts,
        ready_sectionCounts: _scts ? true : false,
        teams_info: ammend_teamInfo(state, null, null, null, null, gridInfo.teams),
        currentInfo: _cif
      }

    case rts.handleGet_seasonalData:
      // this only happens when we default from last season 
      seasonalHelpers.getSeasonalData(pathViews, sportsKey, action.latestSeason, handleResults_seasonalData)
      return { ...state }

    case rts.handleGet_sportsYearSeasonalData:
      const parentKeys = seasonalHelpers.getPks(pathViews, action.sportsYears, sportsKey)
      seasonalHelpers.getSports_collectionItem(pathViews, parentKeys, action.collectionName, true, { sportsYears: action.sportsYears }, handleResults_sportsYearSeasonalData)
      return { ...state }

    case rts.handleResults_sportsYearSeasonalData:

      const cbm = (data) => {
        // console.log('data', data)
      }

      const { data, opts } = action
      const { cbProps } = opts ?? {}
      const { sportsYears: _sportsYears } = cbProps ?? {}
      const { collectionName, sportsSeasonalData, parentKeys: pks } = data

      const {
        id: _seasonId,
        details: details_ssd,
        sectionCounts: levelSectionCounts_ssd,
        sectionCrossovers,
        sectionDates: sectionDates_ssd,
        crossSectionDates: crossSectionDates_ssd,
        teams: teams_ssd,
      } = sportsSeasonalData ?? {}

      if (details_ssd && _sportsYears) {
        details_ssd.year = _sportsYears
      }

      if (collectionName) {

      } else {
        if (teams_ssd) {
          // loop the teams, find the school and assign the parentKeys
          Object.keys(teams_ssd).forEach(k => {
            const t = teams_ssd[k]
            t.id = k
            let _s = schools ? _.find(schools, { name: t.schools }) : null
            if (schools && !_s) { _s = _.find(schools, { name: t.name }) }
            const _pkz = { ...pks }
            if (_s) { _pkz.schools = _s.id }
            t.parentKeys = _pkz
          })
          _seasonInfo.teams = teams_ssd
          handleResults_seasonalData({ teams: teams_ssd })
        }

        if (sectionDates_ssd) {
          const _ssd = {}
          Object.keys(sectionDates_ssd).forEach(sd => {
            _ssd[sd] = reorderDateStrings(sectionDates_ssd[sd])
          })
          _seasonInfo.sectionDates = _ssd
        }

        if (crossSectionDates_ssd) {
          const _ssd = {}
          Object.keys(crossSectionDates_ssd).forEach(sd => {
            _ssd[sd] = reorderDateStrings(crossSectionDates_ssd[sd])
          })
          _seasonInfo.crossSectionDates = _ssd
        }

      }

      if (levelSectionCounts_ssd) {
        ammendLevelSectionsFromCounts(_levels_info, levelSectionCounts_ssd)
      }

      const _refm = createRefPath_event(pathViews, ['_seasonals', _seasonId, 'matches', 'matches'])
      fs_get_doc(_refm, cbm)

      return {
        ...state,
        _seasonId,
        levels_info: _levels_info,
        ready_levels: _levels_info && _levels_info.next ? _.find(_levels_info.next, { checked: true }) : null,
        ready_sectionCounts: levelSectionCounts_ssd ? true : false,
        season_info: _seasonInfo,
        section_info: {
          counts: _levels_info ? getSectionCounts(_levels_info) : {},
        },
        sectionCrossovers,
        sportsDetails: details_ssd ? details_ssd : sportsDetails,
        seasonal_current: { ...state.seasonal_current, details: details_ssd, teams: teams_ssd },
      }

    case rts.handleResults_seasonalData:

      const { retData, fk } = action ?? {}
      const { teams: teams_seasonal } = retData ?? {}
      const _parentDataKeys = { ...state.parentDataKeys }


      if (fk) {
        _parentDataKeys['seasonals'] = fk
      }

      const {
        _selectedSchoolIndexes,
        _selectedTeamIndexes,
        homeAways,
        levels_info: lst,
        playoff_teamz,
        ready_levels,
      } = getSelectedTeams(teams_seasonal, state, _levels_info, true)

      const _teamData = getSportsDataInfo(_sportsData.teams, _selectedTeamIndexes, teams_seasonal)
      const _schoolData = getSportsDataInfo(_sportsData.schools, _selectedSchoolIndexes, teams_seasonal)

      _playoffInfo.playoffTeams = playoffTeams

      const _tcs = getTeamCountOptions(_.pickBy(_next, { checked: true }))

      if (_tcs) {
        _tcs.forEach(key => {
          if (!_seasonInfo.sectionDates[key]) {
            _seasonInfo.sectionDates[key] = []
          }
          if (!_seasonInfo.crossSectionDates[key]) {
            _seasonInfo.crossSectionDates[key] = []
          }
        })
      }

      if (homeAways) {
        _seasonInfo.homeAways = homeAways
      }

      return {
        ...state,
        ready_alignment: ready_levels,
        schools_info: {
          ..._schoolData,
        },
        teams_info: ammend_teamInfo(state, null, null, _teamData, playoff_teamz),
        ready_levels: ready_levels,
        levels_info: lst,
        playoff_info: _playoffInfo,
        season_info: _seasonInfo,
        parentDataKeys: _parentDataKeys,
        seasonal_current: { ...state.seasonal_current, teams: teams_seasonal }
      }

    case rts.handleSet_teamsCreateSeason:
      return { ...state }
    // console.log('teams_info', teams_info)
    // const _teams_info = { ...teams_info, ...action.teams_info }
    // return { ...state, teams_info: _teams_info }

    case rts.handleAmmend_schoolsToTeams:
      return handleAmmend_schoolsToTeams(state, action)

    case rts.handleAmmend_teamsToSchools:
      return handleAmmend_schoolsToTeams(state, action)

    case rts.handleAmmend_schoolTeamInfo_all:
      const { schools_list: _schools_list, schools_withTeamKey: _schools_withTeamKey } = schoolTeamInfo ?? {}
      if (_schools_list) {
        Object.keys(_schools_list).forEach(k => {
          const s = _schools_list[k]
          if (!s._teamKey) {
            s._teamKey = 'newKey'
            s._isNew = true
          } else {
            s._teamKey = null
            s._isNew = false
          }
        })
        const [schools_withTeamKey, schools_withoutTeamKey] = _.partition(_schools_list, '_teamKey');
        schoolTeamInfo.schools_withTeamKey = schools_withTeamKey
        schoolTeamInfo.schools_withoutTeamKey = schools_withoutTeamKey
      }
      return { ...state, schoolTeamInfo: schoolTeamInfo }

    case rts.handleSet_levelsFromPaste:
      const { levels: lvs, teams: tms, nonSchools } = createLevelsFromPaste(state, action.items, action.levelName)
      handleSet_levelSectionTeams(lvs, 'next', nonSchools, tms)
      return { ...state }

    case rts.handleSet_levelSectionTeams:
      switch (action.columnKey) {
        case 'existing':
          _levels_info.existing = action.items
          return { ...state, levels_info: _levels_info, csStatus: state.csStatus === 'a' ? 'b' : 'a' }
        case 'next':
          _levels_info.next = { ..._levels_info.next, ...action.items }
          if (!teams_info) { teams_info = {} }
          teams_info.counts = _next ? getTeamCountOptions(_.pickBy(_levels_info.next, { checked: true })) : []
          if (action.teams) {
            if (teams_info.selected) {
              teams_info.selected = [...teams_info.selected, ...action.teams]
            } else {
              teams_info.selected = action.teams
            }
            return { ...state, teams_info, levels_info: _levels_info, csStatus: state.csStatus === 'a' ? 'b' : 'a', teams: teams_info, ready_levels: _.find(_levels_info.next, { checked: true }), ready_alignment: _.find(_levels_info.next, { checked: true }) }
          } else {
            const { teams: _lstu } = getTeamsFromLevelSections(levels_info, state.seasonal_current)
            return { ...state, teams_info, seasonal_current: { ...state.seasonal_current, teams: _lstu }, levels_info: _levels_info, csStatus: state.csStatus === 'a' ? 'b' : 'a', nonSchools: action.nonSchools, ready_levels: _.find(_levels_info.next, { checked: true }), ready_alignment: _.find(_levels_info.next, { checked: true }) }
          }
        case 'teams':
          return { ...state, teams_selected: action.items, csStatus: state.csStatus === 'a' ? 'b' : 'a' }
        default:
        // nothing
      }
      return { ...state }

    case rts.handleSet_next:
      _levels_info.next = action.next
      const { teams: xxxx } = getTeamsFromLevelSections(_levels_info, state.seasonal_current)
      return { ...state, seasonal_current: { ...state.seasonal_current, teams: xxxx }, levels_info: _levels_info }

    case rts.handleUpdate_seededTeams:
      return { ...state, seededTeams: action.seededTeams }

    case rts.handleInit_teamsInfo:
      const fff = ammendSomething(state)
      if (action.teamsFromSchools) {
        return { ...fff, currentInfo: fff.schools_info, teamsFromSchools: action.teamsFromSchools }
      } else {
        return { ...fff, currentInfo: fff.teams_info, teamsFromSchools: action.teamsFromSchools }
      }

    // case rts.handleNonSelectedSchools:
    //   return { ...state, schools_nonSelected: _.sortBy(action.selected, 'name') }

    case rts.handleSet_selectedTeams:
      const st = []
      if (teams && action.selected) {
        action.selected.forEach(si => {
          if (teams[si]) {
            st.push(teams[si])
          }
        })
      }
      const _tt = {
        selected: st, //_.sortBy(st, 'name'),
        // selectedIndexes: action.selected
      }
      return { ...state, teams: _tt }

    case rts.handleSelect_playoffTeam:
      if (_.includes(playoffTeams, action.team)) {
        _.remove(playoffTeams, function (n) {
          return n.id === action.team.id;
        });
      } else {
        playoffTeams.push(action.team)
      }
      _playoffInfo.playoffTeams = playoffTeams
      _playoffInfo.playoffLevelTeams = sortPlayoffLevelTeams(_playoffInfo)
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleSet_levelQualifyCount:

      const { data: data_qualify } = action
      const { value: value_qualify, lskey: lskey_qualify } = data_qualify

      if (_next && _next[lskey_qualify]) {
        _next[lskey_qualify][sectionCountTypes.qualifyCount] = value_qualify
      }
      _levels_info.next = _next

      let _qualifyReady = true

      if (_next) {
        Object.keys(_next).forEach(nk => {
          if (_next[nk].checked && !_next[nk][sectionCountTypes.qualifyCount])
            _qualifyReady = false
        })
      }

      return {
        ...state,
        levels_info: _levels_info,
        qualifyReady: _qualifyReady,
      }

    case rts.handleSet_levelSectionCount:

      const { data: data_levelCount } = action
      const { value: sectionCount, lskey: _levelKey } = data_levelCount

      ammendLevelSectionsFromCounts(_levels_info, null, _levelKey, sectionCount)

      return {
        ...state,
        levels_info: _levels_info,
        ready_levels: _.find(_next, { checked: true }),
        seasonal_current: { ...state.seasonal_current, sectionCounts: getSectionCounts(_levels_info) }
      }

    case rts.handleSectionSelect:
      if (_existing[action.level] && _existing[action.level].sections) {
        const _sections = _existing[action.level].sections
        const sectionKeys = action.value
        Object.keys(_sections).forEach(sk => {
          if (sectionKeys.includes(sk)) {
            _existing[action.level].sections[sk].checked = true
          } else {
            _existing[action.level].sections[sk].checked = false
          }
        })
      }
      return { ...state, levels_info: _levels_info }

    // ****** POOLS *********

    case rts.handleCreate_pools:
      const _pools = {}
      for (var i = 1; i <= action.poolCount; i++) { _pools['pool' + i] = [] }
      return { ...state, pools: _pools }

    case rts.handleUpdate_pools:
      const { groupkey: poolKey, value: poolTeamKey } = action.selectedItem
      if (teams_selected) {
        const selectedTeam = _.find(teams_selected, { id: poolTeamKey })
        if (selectedTeam) {
          if (pools && pools[poolKey]) {
            pools[poolKey].push(selectedTeam)
            const picked = pickedTeams ? [...pickedTeams] : []
            if (!picked.includes(poolTeamKey)) { picked.push(poolTeamKey) }
            return { ...state, pools, pickedTeams: picked, ready_alignment: true }
          }
        }
      }

      return { ...state }

    case rts.handleUpdate_poolsFromSeeds:
      const { tournament } = action
      const { rounds } = tournament
      const round1 = rounds.round1
      const { pools: pools_round } = round1 ?? {}
      if (pools_round) {
        Object.keys(pools_round).forEach(poolKey => {
          const pool = pools_round[poolKey]
          if (pools && pools[poolKey] && pool.teams) {
            Object.keys(pool.teams).forEach(teamKey => {
              const poolTeam = pool.teams[teamKey]
              pools[poolKey].push(poolTeam)
            })
          }
        })
      }
      return { ...state, pools, tournament }

    case rts.handleAmmend_seasonSectionDateGroup:
      const csi1 = getCurrentSeasonInfo(state, action)
      return { ...state, season_info: csi1, currentSectionCount: action.currentSectionCount }

    case rts.handleAmmend_sectionCrossovers:
      return { ...state, sectionCrossovers: action.sectionCrossovers }

    case rts.handleSelected_seasonDates:
      const csi2 = getCurrentSeasonInfo(state, action)
      return { ...state, season_info: csi2, seasonal_current: { ...state.seasonal_current, sectionDates: csi2.sectionDates, crossSectionDates: csi2.crossSectionDates } }

    case rts.handleSet_seasonDetails:
      let _monthStartDates;
      if (action.sportsDetails && action.sportsDetails.seasonStartDate) {
        const { monthStartDates } = action.sportsDetails.seasonStartDate ? getCalStartDates([action.sportsDetails.seasonStartDate], 3) : {}
        if (monthStartDates) {
          _monthStartDates = monthStartDates
        }
      }
      return { ...state, seasonal_current: { ...state.seasonal_current, details: action.sportsDetails }, sportsDetails: action.sportsDetails, monthStartDates: _monthStartDates }

    // case createSportsTypes.handleUpdate_poolsFromSeeds:
    //   return { ...state, tournament: action.tournament }

    case rts.handleSet_crossover:
      const _crossoverOn = !state.crossoverOn
      const _season_info = { ...state.season_info }
      ammendCurrentSeasonInfo(_season_info, _crossoverOn)
      return { ...state, season_info: _season_info, crossoverOn: _crossoverOn }

    case rts.handleSet_homeAway:
      if (!_seasonInfo.homeAways) { _seasonInfo.homeAways = {} }
      if (_seasonInfo.homeAways) {
        Object.keys(_seasonInfo.homeAways).forEach(k => {
          _seasonInfo.homeAways[k] = !_seasonInfo.homeAways[k]
        })
      }
      //  const { sectionDates,  matches: seasonMatches } = season_info ?? {}
      const { seasonMatches: sms3, crossoverMatches: cm3 } = createTheSeasonMatches(next, _seasonInfo, null, gridMatches, null, state.sectionCrossovers)
      _seasonInfo.matches = sms3
      _seasonInfo.crossoverMatches = cm3
      return { ...state, season_info: _seasonInfo }

    case rts.handleSet_sectionHomeAway:
      const _levelSectionKey = action.levelKey + '_' + action.sectionKey
      if (!_seasonInfo.homeAways) { _seasonInfo.homeAways = {} }
      _seasonInfo.homeAways[_levelSectionKey] = action.homeAway
      const { seasonMatches: sms2, crossoverMatches: cm2 } = createTheSeasonMatches(next, _seasonInfo, null, gridMatches, null, state.sectionCrossovers)
      _seasonInfo.matches = sms2
      _seasonInfo.crossoverMatches = cm2
      return { ...state, season_info: _seasonInfo }

    case rts.handleCreate_seasonMatches:
      const { seasonMatches: sms, seasonTeams: stms, crossoverMatches: cm } = createTheSeasonMatches(next, _seasonInfo, action.homeAway, gridMatches, action.gameScoringType, state.sectionCrossovers)
      _seasonInfo.matches = sms
      _seasonInfo.crossoverMatches = cm
      _seasonInfo.teams = stms
      return { ...state, season_info: _seasonInfo }

    case rts.handleUpdate_playoffInfo:
      _playoffInfo.tournaments = action.tournaments
      _playoffInfo.matches = action.playoffMatches
      return { ...state, playoff_info: _playoffInfo }

    case rts.handleCreate_playoff:
      createTournamentInfo(true, state, handleUpdate_playoffInfo)
      return { ...state }

    case rts.handleCreate_tournament:
      // teams_selected - array
      // seededTeams - object
      createTournamentInfo(false, state, handleUpdate_poolsFromSeeds)
      return { ...state }

    case rts.handleSet_teamRosters:
      if (action.rosters) {
        const _teamRosters = addTeamInfoToTeamRosters(action.rosters, teams)
        const rosters = { teams: _.groupBy(_teamRosters, 'teams'), levels: _.groupBy(_teamRosters, 'levels'), sport: _teamRosters }
        return { ...state, rosters: rosters }
      }
      return { ...state }

    case rts.handleSet_nominations:
      const { nominations } = action
      const { item: _first } = getFirstObject(nominations)
      const { athletes } = _first ?? {}
      let _nominations = {}
      if (athletes) {
        Object.keys(athletes).forEach(teamKey => {
          const team = athletes[teamKey]
          const { item: _level } = getFirstObject(team)
          if (_level) {
            _nominations = { ..._nominations, ..._level }
          }
        })
      }
      const _noms = _.groupBy(_nominations, 'levels')
      return { ...state, nominations: _noms }

    case rts.handleSet_selectedAthletes:
      let _selectedAthletes = { ...state.selectedAthletes }
      if (!_selectedAthletes) { _selectedAthletes = {} }
      if (!_selectedAthletes) { _selectedAthletes[levelItem] = _selectedAthletes[levelItem] = {} }
      _selectedAthletes[levelItem] = action.selectedAthletes
      let total = 0
      if (_selectedAthletes) {
        Object.keys(_selectedAthletes).forEach(k => {
          if (_selectedAthletes[k]) {
            total += Object.keys(_selectedAthletes[k]).length
          }
        })
      }
      return { ...state, selectedAthletes: _selectedAthletes, totalAthletes: total }

    case rts.handleSet_sortedLevels:
      return {
        ...state,
        createRankings_info: {
          sortedLevels: action.sortedLevels,
          selectedLevel: action.selectedLevel,
          selectedWeek: action.selectedWeek
        }
      }

    // ****** LEVELS AND SECTIONS *********

    case rts.handleCreate_levelSections:
      const { levels_info: _lst, teamCounts: _tc } = createLevelsAndSections(levels, sections, _levels_info)
      return { ...state, levels_info: _lst, teamCounts: _tc }

    // ****** UPDATES *********

    case rts.handleUpdate_sportsTeams:
      const cbt = (result) => { console.log('Updated', result) }
      const parentKeys2 = seasonalHelpers.getPks(pathViews, null, sportsKey)
      updateSportsTeams(action.teams, pathViews, parentKeys2, sportsKey, state.parentDataKeys, cbt)
      return { ...state }

    case rts.handleSeasonal_update:

      const cb0 = (result) => {
        console.log('Updated', result)
        const response_data = { success: true }
        handleStopUpdate(response_data, dispatch)
      }

      const { seasonal_current, section_info, sectionCrossovers: _sectionCrossovers } = state
      const { teams: _teamsWithSections, sectionDates: _sectionDates, crossSectionDates: _crossSectionDates, teams: _teamz } = seasonal_current
      const { counts: _sectionCounts } = section_info
      const { selected: _teamz_selected } = teams_info

      if (_teamsWithSections) {
        Object.keys(_teamsWithSections).forEach(k => {
          delete _teamsWithSections[k].record
          delete _teamsWithSections[k].parentKeys
        })
      }

      const { latestSeason, opts: opts_a } = action
      const { currentMatches, isTemp, isBackup, isCommit, matchesOnly, teamsOnly } = opts_a

      if (currentMatches) {
        Object.keys(currentMatches).forEach(cmk => {
          if (!currentMatches[cmk]._status) { currentMatches[cmk]._status = {} }
          if (!currentMatches[cmk]._status.setsToWin) { currentMatches[cmk]._status.setsToWin = 3 }
          if (!currentMatches[cmk]._status.setCount) { currentMatches[cmk]._status.setCount = 5 }
          delete currentMatches[cmk]._itemKey
        })
      }

      const _pks = seasonalHelpers.getPks(pathViews, sportsYears, sportsKey)
      const _pksValid = seasonalHelpers.isValidPks(_pks)

      seasonalHelpers.ammendUpdateProps(_updateProps, _pks, cb0)

      if (_pksValid) {
        if (isCommit) {
          if (matchesOnly) {
            // only the matches
            seasonalHelpers.ammendUpdateNameProps(_updateProps, 'matches', 'matches', currentMatches, { subCollectionName: 'matches' })
            seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matchesCreate, _updateProps)
          } else if (teamsOnly) {
            // only the teams
            seasonalHelpers.ammendUpdateNameProps(_updateProps, 'teams', 'teams', _teamsWithSections)
            seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teams, _updateProps)
          } else {
            // both teams and matches
            seasonalHelpers.ammendUpdateNameProps(_updateProps, 'teams', 'teams', _teamsWithSections)
            seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teams, _updateProps)

            const _updateProps2 = copyObj(_updateProps)``
            seasonalHelpers.ammendUpdateNameProps(_updateProps2, 'matches', 'matches', currentMatches, { subCollectionName: 'matches' })
            seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matchesCreate, _updateProps2)
          }
        } else if (isBackup) {
          const _pksb = seasonalHelpers.getPks(pathViews, latestSeason, sportsKey)
          seasonalHelpers.ammendUpdateProps(_updateProps, _pksb, cb0)
          seasonalHelpers.ammendUpdateNameProps(_updateProps, 'backup_matches', 'matches', currentMatches)
          seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.backupMatches, _updateProps)
        } else {
          switch (action.updateType) {

            case seasonalUpdateSteps.seasonSectionAlignment:
              // _seasonals(parentKeys)/teams
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'teams', _teamsWithSections)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teams, _updateProps)
              break;

            case seasonalUpdateSteps.seasonTeams:
              // _seasonals(parentKeys)/teams
              const _teamsObj = convertHelpers.createObjectFromArray(_teamz_selected)
              const _data_teams = dataFix.removeAllKeys(_teamsObj, ['checked', 'disabled', 'key', '_itemKey', '_teamKey'])
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'teams', _data_teams)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teams, _updateProps)
              break;

            case seasonalUpdateSteps.seasonSectionTeams:
              // _seasonals(parentKeys)/teams
              // seasonalHelpers.ammendUpdateNameProps(_updateProps, 'backup_matches', 'matches', currentMatches)
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'teams', _teamz)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.teams, _updateProps)
              break;

            case seasonalUpdateSteps.seasonDetails:
            case seasonalUpdateSteps.year:
              const details = dataFix.removeEmpties(seasonal_current.details)
              delete details._deleteProps
              // _seasonals(parentKeys)/details 
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'details', details)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.details, _updateProps)
              break;

            case seasonalUpdateSteps.seasonSchedule:
              // _matches(parentKeys)/matches 
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'matches', seasonMatches, { subCollectionName: 'matches' })
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.matches, _updateProps)
              break;

            case seasonalUpdateSteps.seasonSectionDates:
              // _seasonals(parentKeys)/sectionDates
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'sectionDates', _sectionDates)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sectionDates, _updateProps)

              const _updateProps2 = copyObj(_updateProps)
              seasonalHelpers.ammendUpdateNameProps(_updateProps2, null, 'crossSectionDates', _crossSectionDates)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sectionDates, _updateProps2)
              break;

            case seasonalUpdateSteps.seasonSectionCount:
              // _seasonals(parentKeys)/sectionCounts
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'sectionCounts', _sectionCounts)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sectionCounts, _updateProps)
              break;

            case seasonalUpdateSteps.seasonCrossovers:
              // _seasonals(parentKeys)/sectionCounts
              seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'sectionCrossovers', _sectionCrossovers)
              seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.sectionCrossovers, _updateProps)
              break;

            default:
            // nothing
          }
        }
      } else {
        console.log('Invalid PKS', _pks)
      }

      return { ...state }

    case rts.handleCreate_teamRoster:

      const { newAthletes, newRosters, rosterType } = action
      const cb7 = () => { console.log('Added') }
      switch (rosterType) {
        case 'athletes':
          const pks_athletes = seasonalHelpers.getPks(pathViews, action.latestSeason, sportsKey)
          seasonalHelpers.ammendUpdateProps(_updateProps, pks_athletes, cb7)
          seasonalHelpers.ammendUpdateNameProps(_updateProps, 'athletes', null, newAthletes)
          seasonalHelpers.updateSportsCollectionItemsWithParentKeys(seasonalUpdateTypes.athletes, _updateProps, 'athletes', newAthletes)
          break;
        case 'roster':
          const pks_roster = seasonalHelpers.getPks(pathViews, action.latestSeason, sportsKey)
          seasonalHelpers.ammendUpdateProps(_updateProps, pks_roster, cb7)
          seasonalHelpers.ammendUpdateNameProps(_updateProps, 'rosters', 'athletes', newRosters)
          seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.rosters, _updateProps)
          break;
        default:
        // nothing
      }

      return { ...state }

    case rts.handleCreate_staff:
      const cb8 = () => { console.log('Added') }
      const pks_staff = seasonalHelpers.getPks(pathViews, sportsYears, sportsKey)
      seasonalHelpers.ammendUpdateProps(_updateProps, pks_staff, cb8)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, 'staff', 'staff', action.staff, { ignoreSeason: true, updateToMain: true })
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.staff, _updateProps)
      return { ...state }

    case rts.handleUpdate_allStarTeams:
      // LOOK
      // seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.allStarTeams, _updateProps, 'athletes', action.data, action.collectionName, true)
      return { ...state }

    case rts.handleUpdate_alignment:
      return { ...state }

    case rts.handleUpdate_rankings:
      const data_rankings = getRankingData(action)
      const _pkss = seasonalHelpers.getPks(pathViews, state.latestSeason, sportsKey)
      seasonalHelpers.ammendUpdateProps(_updateProps, _pkss, action.callback)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, 'rankings', null, data_rankings)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.rankings, _updateProps)
      return { ...state }

    case rts.handleDbUpdate_seasonDetails:
      const cbx = (result) => { console.log('Updated', result) }
      const _pkssx = seasonalHelpers.getPks(pathViews, state.latestSeason, sportsKey)
      // _seasonals(parentKeys)/teams/details
      seasonalHelpers.ammendUpdateProps(_updateProps, _pkssx, cbx)
      seasonalHelpers.ammendUpdateNameProps(_updateProps, null, 'details', action.details)
      seasonalHelpers.updateSeasonalOrGlobal(seasonalUpdateTypes.details, _updateProps)
      return { ...state }

    case rts.handleShow_importFromGs:
      return { ...state, showImportFromGs: !state.showImportFromGs }

    case rts.handleCloseConfirmation:
    case rts.handleFunctionResponse:
    case rts.handleStartUpdate:
    case rts.handleStopUpdate:
      return responseReducers(state, action, { dispatch, dispatchConfirmationType: dispatchConfirmationTypes.closeAfterUpdate }, { gsMatchedRefreshed: false })

    default:
      return { ...state }
  }
}

export const createSportsHandlers = (dispatch) => {
  return {
    handleAmmend_schoolsToTeams: (school, checked) => { dispatch({ type: rts.handleAmmend_schoolsToTeams, dispatch, school, checked }) },
    handleAmmend_schoolTeamInfo_all: (schoolKey, schoolName, checked) => { dispatch({ type: rts.handleAmmend_schoolTeamInfo_all, dispatch, schoolKey, schoolName, checked }) },
    handleAmmend_teamsToSchools: (team, checked, teamsFromSchools) => { dispatch({ type: rts.handleAmmend_teamsToSchools, dispatch, team, checked, teamsFromSchools }) },
    handleAmmend_seasonSectionDateGroup: (currentSectionCount) => { dispatch({ type: rts.handleAmmend_seasonSectionDateGroup, dispatch, currentSectionCount }) },
    handleAmmend_sectionCrossovers: (sectionCrossovers) => { dispatch({ type: rts.handleAmmend_sectionCrossovers, dispatch, sectionCrossovers }) },
    handleCreate_levelSections: () => { dispatch({ type: rts.handleCreate_levelSections, dispatch }) },
    handleCreate_playoff: () => { dispatch({ type: rts.handleCreate_playoff, dispatch }) },
    handleCreate_pools: (poolCount) => { dispatch({ type: rts.handleCreate_pools, dispatch, poolCount }) },
    handleCreate_seasonFromGrid: (result) => { dispatch({ type: rts.handleCreate_seasonFromGrid, dispatch, result }) },
    handleCreate_seasonMatches: (homeAway, gameScoringType) => { dispatch({ type: rts.handleCreate_seasonMatches, dispatch, homeAway, gameScoringType }) },
    handleCreate_staff: (staff) => { dispatch({ type: rts.handleCreate_staff, dispatch, staff }) },
    handleCreate_teamRoster: (latestSeason, newAthletes, newRosters, rosterType) => { dispatch({ type: rts.handleCreate_teamRoster, dispatch, latestSeason, newAthletes, newRosters, rosterType }) },
    handleCreate_tournament: () => { dispatch({ type: rts.handleCreate_tournament, dispatch }) },
    handleDbUpdate_seasonDetails: (details, latestSeason, callback) => { dispatch({ type: rts.handleDbUpdate_seasonDetails, dispatch, details, latestSeason, callback }) },
    handleGet_seasonalData: (latestSeason) => { dispatch({ type: rts.handleGet_seasonalData, dispatch, latestSeason }) },
    handleGet_sportsYearSeasonalData: (dataParents, sportsYears, collectionName) => { dispatch({ type: rts.handleGet_sportsYearSeasonalData, dispatch, dataParents, sportsYears, collectionName }) },
    handleInit_sportsData: () => { dispatch({ type: rts.handleInit_sportsData, dispatch }) },
    handleInit_teamsInfo: (teamsFromSchools) => { dispatch({ type: rts.handleInit_teamsInfo, dispatch, teamsFromSchools }) },
    handleResults_seasonalData: (retData, fk) => { dispatch({ type: rts.handleResults_seasonalData, dispatch, retData, fk }) },
    handleResults_sportsData: (dataResult) => { dispatch({ type: rts.handleResults_sportsData, dispatch, dataResult }) },
    handleResults_sportsYearSeasonalData: (data, opts) => { dispatch({ type: rts.handleResults_sportsYearSeasonalData, dispatch, data, opts }) },
    handleSeasonal_update: (updateType, latestSeason, opts) => { dispatch({ type: rts.handleSeasonal_update, dispatch, updateType, latestSeason, opts }) },
    handleSectionSelect: (level, value) => { dispatch({ type: rts.handleSectionSelect, dispatch, level, value }) },
    handleSelect_playoffTeam: (team) => { dispatch({ type: rts.handleSelect_playoffTeam, dispatch, team }) },
    handleSelected_seasonDates: (seasonDateProps) => { dispatch({ type: rts.handleSelected_seasonDates, dispatch, seasonDateProps }) },
    handleSet_crossover: () => { dispatch({ type: rts.handleSet_crossover, dispatch }) },
    handleSet_homeAway: () => { dispatch({ type: rts.handleSet_homeAway, dispatch }) },
    handleSet_levelQualifyCount: (data) => { dispatch({ type: rts.handleSet_levelQualifyCount, dispatch, data }) },
    handleSet_levelSectionCount: (data) => { dispatch({ type: rts.handleSet_levelSectionCount, dispatch, data }) },
    handleSet_levelSectionTeams: (items, columnKey, nonSchools, teams) => { dispatch({ type: rts.handleSet_levelSectionTeams, dispatch, items, columnKey, nonSchools, teams }) },
    handleSet_levelsFromPaste: (items, levelName) => { dispatch({ type: rts.handleSet_levelsFromPaste, dispatch, items, levelName }) },
    handleSet_next: (next) => { dispatch({ type: rts.handleSet_next, dispatch, next }) },
    handleSet_nominations: (nominations) => { dispatch({ type: rts.handleSet_nominations, dispatch, nominations }) },
    handleSet_seasonDetails: (sportsDetails) => { dispatch({ type: rts.handleSet_seasonDetails, dispatch, sportsDetails }) },
    handleSet_sectionHomeAway: (levelKey, sectionKey, homeAway) => { dispatch({ type: rts.handleSet_sectionHomeAway, dispatch, levelKey, sectionKey, homeAway }) },
    handleSet_selectedAthletes: (level, selectedAthletes) => { dispatch({ type: rts.handleSet_selectedAthletes, dispatch, level, selectedAthletes }) },
    handleSet_selectedTeams: (selected) => { dispatch({ type: rts.handleSet_selectedTeams, dispatch, selected }) },
    handleSet_sortedLevels: (sortedLevels, selectedLevel, selectedWeek) => { dispatch({ type: rts.handleSet_sortedLevels, dispatch, sortedLevels, selectedLevel, selectedWeek }) },
    handleSet_teamsCreateSeason: (teams_info) => { dispatch({ type: rts.handleSet_teamsCreateSeason, dispatch, teams_info }) },
    handleSet_teamRosters: (rosters) => { dispatch({ type: rts.handleSet_teamRosters, dispatch, rosters }) },
    handleShow_importFromGs: () => { dispatch({ type: rts.handleShow_importFromGs, dispatch }) },
    handleUpdate_alignment: (selectedItem) => { dispatch({ type: rts.handleUpdate_alignment, dispatch, selectedItem }) },
    handleUpdate_allStarTeams: (data, collectionName) => { dispatch({ type: rts.handleUpdate_allStarTeams, dispatch, data, collectionName }) },
    handleUpdate_playoffInfo: (tournaments, playoffMatches) => { dispatch({ type: rts.handleUpdate_playoffInfo, dispatch, tournaments, playoffMatches }) },
    handleUpdate_pools: (selectedItem) => { dispatch({ type: rts.handleUpdate_pools, dispatch, selectedItem }) },
    handleUpdate_poolsFromSeeds: (tournament) => { dispatch({ type: rts.handleUpdate_poolsFromSeeds, dispatch, tournament }) },
    handleUpdate_rankings: (actionProps, handleDone) => { dispatch({ type: rts.handleUpdate_rankings, dispatch, actionProps, handleDone }) },
    handleUpdate_seededTeams: (seededTeams) => { dispatch({ type: rts.handleUpdate_seededTeams, dispatch, seededTeams }) },
    handleUpdate_sportsTeams: (teams) => { dispatch({ type: rts.handleUpdate_sportsTeams, dispatch, teams }) },
    ...responseHandlers(dispatch),
  }
}

export const createSportsInitialState = (init_state) => {

  const { teams_info, teams, pathViews, teams_fromMatches, parentDataKeys } = init_state
  const { sports } = pathViews

  const _playoffTeams = getPlayoffTeamsInit(teams)
  const _playoffLevelTeams = getPlayoffLevelTeamsInit(teams)

  const pdts = ammendPlayoffDatesNeeded(_playoffLevelTeams)

  return {
    ...init_state,
    currentSport: sports,
    seasonal_current: {},
    sportsDetails: {},
    levels_info: {
      existing: {},
      next: {},
      ready: false,
    },
    schools_info: {
      existing: {},
      nonSelected: {},
      selected: {},
    },
    teams_info: teams_info ? teams_info : {
      counts: [],
      created: [],
      fromMatches: teams_fromMatches,
      nonSelected: {},
      playoff: {},
      selected: {},
    },
    sports_data: {
      levels: {},
      sections: {},
      schools: {},
      teams: {}
    },
    season_info: {
      sectionDates: {},
      crossSectionDates: {},
      sectionDatesReady: {}
    },
    playoff_info: {
      playoffTeams: _playoffTeams,
      playoffLevelTeams: _playoffLevelTeams,
      ...pdts
    },
    parentDataKeys
  };
}

const ammendPlayoffDatesNeeded = (playoffLevelTeams) => {

  const playoffDates = {}
  const playoffDates_ready = {}
  const playoffDates_needed = {}

  if (playoffLevelTeams) {
    Object.keys(playoffLevelTeams).forEach(key => {
      const lt = playoffLevelTeams[key]
      playoffDates[key] = []
      playoffDates_ready[key] = false
      playoffDates_needed[key] = datesNeeded(lt.length)
    })
  }

  return { playoffDates, playoffDates_ready, playoffDates_needed }

}

const ammend_teamInfo = (state, teams, counts, teamData, playoffTeams, teams_grid, _school_data) => {

  const { teams_info, pathViews, sportsKey, sportsDetails } = state ?? {}
  const { defaultFromLastSeason } = sportsDetails ?? {}
  const _teams_info = { ...teams_info, ...teamData }

  if (counts) { _teams_info.counts = counts }
  if (playoffTeams) { _teams_info.playoff = playoffTeams }
  if (teams_grid) { _teams_info.selected = teams_grid }

  if (defaultFromLastSeason && _teams_info) {

  }

  if (_teams_info.fromMatches) {

    const _parentKeys = {
      districts: pathViews.districts,
      organizations: pathViews.organizations,
      sports: sportsKey,
    }

    const _existing = {}

    Object.keys(_teams_info.fromMatches).forEach(k => {
      const t = _teams_info.fromMatches[k]
      _existing[k] = {
        name: t.name,
        parentKeys: _parentKeys,
        id: k,
        _itemKey: k,
      }
    })

    if (_school_data) {
      updateTeamSchoolParentKeys(_teams_info.existing, _school_data.existing)
    }
  }

  return _teams_info

}

const updateTeamSchoolParentKeys = (teams_existing, schools_existing) => {

  Object.keys(teams_existing).forEach(tk => {
    const _team_existing = _.cloneDeep(teams_existing[tk]);
    const { parentKeys } = _team_existing ?? {}
    const { schools: key_school } = parentKeys ?? {}
    if (!key_school) {
      const _schoolKey = _.findKey(schools_existing, { name: _team_existing.name })
      if (_schoolKey) {
        _team_existing.parentKeys.schools = _schoolKey
        _team_existing._isNew = true
      }
    }
    teams_existing[tk] = _team_existing
  })

}

const datesNeeded = (teamCount) => {
  switch (true) {
    case teamCount > 16 && teamCount <= 32:
      return 5
    case teamCount > 8 && teamCount <= 16:
      return 4
    case teamCount > 4 && teamCount <= 8:
      return 3
    case teamCount > 3 && teamCount <= 4:
      return 2
    default:
    // nothing
  }
}

/**
 * 
 * @param {object} teams 
 * @returns a list of teams whose record indicates that they are a playoffTeam
 */
const getPlayoffTeamsInit = (teams) => {
  const _teams = _.filter(teams, (t) => t.record && t.record.playoffTeam)
  return _teams
}

/**
 * 
 * @param {*} teams 
 * @returns a list of teams whose record indicates that they are a playoffTeam, grouped by the `levels` of the teams
 */
const getPlayoffLevelTeamsInit = (teams) => {
  const _teams = _.filter(teams, (t) => t.record && t.record.playoffTeam)
  const teams_levels = _teams ? _.groupBy(_teams, 'levels') : null
  return teams_levels
}

const ammendSchoolsInfo = () => {

}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns  teams_createInfo, schools_info 
 */
const handleAmmend_schoolsToTeams = (state, action) => {

  // Destructure the `school` object from the `action` object
  const { school, team, teamsFromSchools } = action;

  // Extract specific properties from the `school` object
  const { _itemKey: key_school, name: schoolName, _teamKey, _teamKeyOld } = school ?? {};

  // Extract specific properties from the `team` object
  const { _itemKey: key_team, name: teamName, _schoolKey, parentKeys } = team ?? {};
  const { schools: schoolKey_pk } = parentKeys ?? {};

  const _fromTeam = team ? true : false
  const _currentTeamKey = _fromTeam ? key_team : _teamKey
  let _currentSchoolKey = _fromTeam ? _schoolKey : key_school
  if (_currentSchoolKey && schoolKey_pk) { _currentSchoolKey = schoolKey_pk }

  // Destructure 'teams_info' and `schools_info` from the `state` object
  const { sports_data, teams_info, schools_info } = state;

  // Destructure `existing` from `schools_info` (default to an empty object if `schools_info` is undefined)
  const { existing: schools_existing } = schools_info ?? {};

  // Destructure `teams_existing` from `teams_info`
  const { existing: teams_existing } = teams_info;

  // Find the existing team in `teams_existing` that matches the given `_currentTeamKey`
  const _team = _.findKey(teams_existing, { _itemKey: _currentTeamKey });

  const _existingSchool = getExistingSchool(schools_existing, _currentSchoolKey)

  // If the team doesn't exist
  if (!_team) {
    // Generate a new team key if `_teamKeyOld` is not present
    const _newTeamKey = _teamKeyOld ? _teamKeyOld : createFsDocKey();

    // Add a new team to the `teams_existing` array
    teams_existing.push({
      name: schoolName,
      _itemKey: _newTeamKey,
      _schoolKey: _currentSchoolKey,
      _isNew: true,
    });

    _existingSchool._teamKey = _newTeamKey
  } else {
    // If the team exists, check if the school exists in `schools_existing`
    if (_existingSchool) {
      // Store the current `_teamKey` as `_teamKeyOld`
      _existingSchool._teamKeyOld = _existingSchool._teamKey;

      // Remove the current `_teamKey`
      delete _existingSchool._teamKey;
    }
    if (teamsFromSchools) {
      teams_existing.splice(_team, 1);
    } else {
      _.update(teams_existing, `[${_team}].checked`, checked => !checked);
    }
    // Remove the team from the `teams_preExissting` array
  }

  if (teams_existing) {
    teams_info.created = _.filter(teams_existing, { _isNew: true })
    teams_info.selected = _.filter(teams_existing, { checked: true })
  }

  return { ...state, schools_info, teams_info }

}

const getExistingSchool = (schools_existing, _currentSchoolKey) => {
  const schoolIndex = schools_existing.findIndex(school => school._itemKey === _currentSchoolKey);
  return schools_existing[schoolIndex]
};

const createLevelsFromPaste = (state, items, levelName) => {

  const { next, teams_selected, schools_info, nonSchools } = state

  const { existing: schools_existing } = schools_info ?? {}

  const levels = next ?? {}
  const teams = teams_selected ?? {}
  const _nonSchools = nonSchools ?? {}

  if (!levels[levelName]) {
    levels[levelName] = {
      checked: true,
      name: levelName,
      sections: {}
    }
  } else {
    levels[levelName].checked = true
  }

  const level = levels[levelName]

  items.forEach(item => {
    Object.keys(item).forEach((sectionKey) => {

      const teamName = item[sectionKey]
      const { school: _school, tn } = findSchool(teamName, schools_existing)

      if (!level.sections[sectionKey]) {
        level.sections[sectionKey] = {
          checked: true,
          name: _.startCase(sectionKey),
          teams: []
        }
      } else {
        level.sections[sectionKey].checked = true
      }

      const team = {
        id: createFsDocKey(),
        name: tn,
        sections: sectionKey,
        levels: levelName,
        parentKeys: {
          schools: _school ? _school._itemKey : 0
        },
      }
      if (!_school) { _nonSchools.push(teamName) }
      teams.push(team)
      if (!level.sections[sectionKey].teams) { level.sections[sectionKey].teams = [] }
      const tsc = level.sections[sectionKey].teams.length
      team.position = tsc
      level.sections[sectionKey].teams.push(team)
    })
  })
  return { levels, teams, nonSchools: _nonSchools }
}

export const ammendSchoolName = (teamName) => {

  let tn = teamName

  switch (tn) {
    case 'Aquinas Academy of Pittsburgh':
      tn = 'Aquinas Academy'
      break;

    case 'Bethlehem-Center':
    case 'Bethlehem Center':
      tn = 'Beth Center'
      break;

    case 'Canon McMillan':
    case 'Canon-McMillian':
      tn = 'Canon McMillan'
      break;

    case 'Ellwood City Lincoln':
      tn = 'Ellwood City'
      break;

    case 'The Ellis School':
      tn = 'Ellis School'
      break;

    case 'Hillel Academy  of Pittsburgh':
      tn = 'Hillel Academy'
      break;

    case 'Lincoln Park Performing Arts Charter School':
      tn = 'Lincoln Park'
      break;

    case 'OLSH':
    case 'Our Lady of the Sacred Heart':
      tn = 'O.L.S.H.'
      break;

    case 'Seton LaSalle Catholic':
      tn = 'Seton LaSalle'
      break;

    case 'Sto Rox':
      tn = 'Sto-Rox'
      break;

    case 'Trinity Christian School':
      tn = 'Trinity Christian'
      break;

    case 'The Neighborhood Academy':
      tn = 'Neighborhood Academy'
      break;

    case 'Waynesburg Central':
      tn = 'Waynesburg'
      break;

    case 'Eden Christian Acad.':
      tn = 'Eden Christian'
      break;

    case 'Hillcrest Christ. Acad.':
      tn = 'Hillcrest Christian'
      break;

    // case 'Serra Catholic':
    //   tn = 'Serra'
    //   break;

    case 'Geibel Catholic':
      tn = 'Geibel'
      break;

    case 'South Side':
    case 'South Side Beaver':
      tn = 'South Side Area'
      break;

    default:
    // nothing 
  }

  return tn

}

export const findSchool = (teamName, schools_existing) => {

  let tn = ammendSchoolName(teamName)

  let school = _.find(schools_existing, { name: tn })

  if (!school) {
    tn = tn.replace('-', ' ')
    school = _.find(schools_existing, { name: tn })
  }

  if (!school) {
    if (tn.indexOf('Co.') >= 0) {
      tn = tn.replace('Co.', 'County')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('C.C.') >= 0) {
      tn = tn.replace('C.C.', 'Central Catholic')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Ft.') >= 0) {
      tn = tn.replace('Ft.', 'Fort')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Saint') >= 0) {
      tn = tn.replace('Saint', 'St.')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Area') >= 0) {
      tn = tn.replace(' Area', '')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Greater') >= 0) {
      tn = tn.replace('Greater ', '')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    if (tn.indexOf('Neighborhood Acad ©') >= 0) {
      tn = tn.replace('Neighborhood Acad ©', 'Neighborhood Academy')
      school = _.find(schools_existing, { name: tn })
    }
  }

  if (!school) {
    tn += ' Area'
    school = _.find(schools_existing, { name: tn })
  }

  if (!school) {
    console.log('school', school)
  }

  return { school, tn }

}

function isValidDate(dateString) {

  // Regular expression to match YYYY-MM-DD format
  // const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;

  if (!regex.test(dateString)) {
    return false; // Invalid format
  }

  const parts = dateString.split("/");
  const month = parseInt(parts[0]);
  const day = parseInt(parts[1]);
  const year = parseInt(parts[2]);

  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}