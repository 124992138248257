import { updateNotificationsDirect } from '../firestoreData/notifications/notificationsData';
import { callFunction, callFunctionDirect } from "./fbAll";

export const _allowNotificationSend = true

const notificationGenericTypes = {
  notificationGeneric: 'notificationGeneric',
}

export const notificationFunctionTypes = {
  addTopicGroups: 'addTopicGroups',
  addTopicToFcmTokens: 'addTopicToFcmTokens',
  getClientTopics: 'getClientTopics',
  removeTokensFromTopics: 'removeTokensFromTopics',
  sendCustomEmail: 'sendCustomEmail',
  sendCustomTokenEmail: 'sendCustomTokenEmail',
  sendLinkEmail: 'sendLinkEmail',
  sendNotificationByData: 'sendNotificationByData',
  sendScheduledNotifications_gcs_direct: 'sendScheduledNotifications_gcs_direct',
  sendSms: 'sendSms',
  sendGenericPush: 'sendGenericPush',
}

/**
 * 
 * @param {object} callData 
 * @returns - The `data` object {success,result, error}
 */
const callFsFunction = async (callData) => {
  const data = await callFunction(notificationGenericTypes.notificationGeneric, callData)
  return data
}

export const fsfn_notifications = {
  addTopicGroups: (appProfileData, unsubscribe, logging) => fsfn_addTopicGroups(appProfileData, unsubscribe, logging),
  addTopicToFcmTokens: (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => fsfn_addTopicToFcmTokens(fcmTokens, topic, unsubscribe, pathViews, useEventKey),
  checkNotification_direct: (testOnly) => fsfn_checkNotification_direct(testOnly),
  getClientTopics: (data) => fsfn_getClientTopics(data),
  removeTokensFromTopics: (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => fsfn_removeTokensFromTopics(fcmTokens, topic, unsubscribe, pathViews, useEventKey),
  sendCustomTokenEmail: (notificationData) => fsfn_sendCustomTokenEmail(notificationData),
  sendEmail: (notificationData) => fsfn_sendEmail(notificationData),
  sendLinkEmail: (notificationData) => fsfn_sendLinkEmail(notificationData),
  sendNotificationByData: (notificationData, pathViews, tokens, topic, callback, logging, callbackSelf, itemKey, useEventKey) => fsfn_sendNotificationByData(notificationData, pathViews, tokens, topic, callback, logging, callbackSelf, itemKey, useEventKey),
  sendNotificationToSelf: (props) => sendNotificationToSelf(props),
  sendScheduledNotifications_gcs_direct: () => fsfn_sendScheduledNotifications_gcs_direct(),
  sendSms: (data, logging, callback) => fsfn_sendSms(data, logging, callback),
}

/**
 * 
 * @param {object} data  
 */
const fsfn_checkNotification_direct = async (testOnly) => {
  try {
    const callData = { testOnly: testOnly, showLogs: true }
    await callFunctionDirect('checkNotification_direct', callData)
  } catch (error) {
    console.error('sendNotification_hourly - error', error)
  }
}

/**
 * 
 * @param {object} data  
 */
const fsfn_sendScheduledNotifications_gcs_direct = async () => {
  try {
    const data = {
      notificationFunctionType: notificationFunctionTypes.sendScheduledNotifications_gcs_direct,
      showLogs: true
    }
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error('fsfn_getClientTopics - error', error)
  }
}

/**
 * 
 * @param {object} data 
 * @deprecated
 */
const fsfn_getClientTopics = async (data) => {
  try {
    data.notificationFunctionType = notificationFunctionTypes.getClientTopics
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error('fsfn_getClientTopics - error', error)
  }
}

/** sends a notifcation to the appUsers tokens
 * {notificationData, appUserAccess, paps_state, callback: handleShowNormal} */
const sendNotificationToSelf = async (props) => {
  const { notificationData, appUserAccess, pathViews, callback, logging } = props
  return fsfn_sendNotificationByData(notificationData, pathViews, appUserAccess.fcmTokens, null, null, logging, callback, appUserAccess._itemKey)
}

/** sends a notifcation to the given tokens
 * {notificationData, appUserAccess, paps_state, callback: handleShowNormal} */
/**
 * 
 * @param {object} notificationData 
 * @param {string} pathViews 
 * @param {array} tokens 
 * @param {function} callback 
 * @param {object} logging 
 * @param {function} callbackSelf 
 * @description sends a notifcation to the given tokens
 * @callback - sends a notifcation to the given tokens
 */
const fsfn_sendNotificationByData = async (notificationData, pathViews, tokens, topic, callback, logging, callbackSelf, itemKey, useEventKey) => {
  try {

    const callData = {
      notificationFunctionType: notificationFunctionTypes.sendGenericPush,
      token: tokens ? tokens[0] : null,
      notificationType: 'data',
      notification: { ...notificationData },
      tokens,
      topic,
      itemKey,
      showLogs: logging && logging.allowLogging && logging.logNotifications,
      pathViews,
      useEventKey
    }
    if (_allowNotificationSend) {
      const response_data = await callFsFunction(callData) // OK
      callback && updateNotificationsDirect(pathViews, notificationData, response_data, callback)
      callbackSelf && callbackSelf(response_data)
      return response_data
    } else {
      console.log('NS - fsfn_sendNotificationByData', callData)
    }
  } catch (error) {
    console.error('#fsfn_sendNotificationByData - error', error)
  }
}

/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
const fsfn_sendEmail = async (notificationData) => {
  try {
    notificationData.notificationFunctionType = notificationFunctionTypes.sendCustomEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
const fsfn_sendCustomTokenEmail = async (notificationData) => {
  try {
    console.log('fsfn_sendCustomTokenEmail', notificationData)
    notificationData.notificationFunctionType = notificationFunctionTypes.sendCustomTokenEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}
/**
 * 
 * @param {object} notificationData 
 * @returns response_data {success, result, error}
 */
const fsfn_sendLinkEmail = async (notificationData) => {
  try {
    notificationData.notificationFunctionType = notificationFunctionTypes.sendLinkEmail
    const response_data = await callFsFunction(notificationData) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} data 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
const fsfn_sendSms = async (data, logging, callback) => {
  try {
    const { to, text } = data
    const _showLogs = logging && logging.allowLogging && logging.logSms
    const sendData = { notificationFunctionType: notificationFunctionTypes.sendSms, to: to, text, showLogs: true }
    const response_data = await callFsFunction(sendData) // OK  
    console.log('response_data', response_data)
    callback && callback(response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 
 * @param {object} appProfileData 
 * @param {boolean} unsubscribe 
 * @param {object} logging 
 * @returns response_data {success, result, error}
 */
const fsfn_addTopicGroups = async (appProfileData, unsubscribe, logging) => {
  try {
    const data = { notificationFunctionType: notificationFunctionTypes.addTopicGroups, appProfileData, unsubscribe, showLogs: logging && logging.allowLogging && logging.logNotificationTopics }
    const response_data = await callFsFunction(data) // OK
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/**
 * Add the fcmTokens in to the topic
 * @param {array} fcmTokens 
 * @param {string} topic 
 * @param {boolean} unsubscribe 
 * @returns response_data {success, result, error}
 */
const fsfn_addTopicToFcmTokens = async (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => {
  try {
    const data = { notificationFunctionType: notificationFunctionTypes.addTopicToFcmTokens, fcmTokens, topic, unsubscribe, pathViews, useEventKey, showLogs: true }
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}

const fsfn_removeTokensFromTopics = async (fcmTokens, topic, unsubscribe, pathViews, useEventKey) => {
  try {
    const data = {
      notificationFunctionType: notificationFunctionTypes.removeTokensFromTopics,
      fcmTokens,
      topic,
      unsubscribe,
      pathViews,
      useEventKey,
      showLogs: true
    }
    const response_data = await callFsFunction(data) // OK
    console.log('response_data', response_data)
    return response_data
  } catch (error) {
    console.error(error)
  }
}


/** Sends a notification to a topic */
// export const sendNotification_topic = async (notificationData, appUserAccess, paps_state) => {
//   const { rootPaths } = paps_state ?? {}
//   const { events } = rootPaths ? rootPaths : {}
//   let topicPath = events ? events.replace(/\//g, '_') : null
//   const { topicOriginGroup, topicOriginGroupKey } = notificationData
//   topicPath += topicOriginGroup + '_' + topicOriginGroupKey
//   const sendProps = appUserAccess ? { profileId: appUserAccess.profileId } : {}
//   const _notificationData = getNotificationData(notificationTypes.notification, {}, { topic: topicPath }, { title, body })
//   const result = await fsfn_sendNotification(sendProps, _notificationData, pathViews)
//   return result
// }
