import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAppUserAccess } from '../../auth/appUserAccessPermissions';
import { getFirstObject } from '../../common/convert';
import { SettingsViewerContext } from '../../components/viewers/FullSettingsViewer';
import { gEnums } from '../../enums/globalEnums';
import { ammendViewItemsSettings, getAllowPopups, itemHandlers, itemInitialState, itemReducer } from '../reducers/ItemReducer';
import { DataContext } from './DataContext';
import { FilterContext } from './FilterContext';
import { PageAreaContext } from './PageAreaContext';
import { PageAreaParentContext } from './PageAreaParentContext';
import { ParentContext } from './ParentContext';
import { ammendUiItem, ammendIcons, getProps_viewItem } from './contextHelpers/ammendUiItem';

/** 
 @provides itemAction_handlers
 @provides isCombinedDatea
 @provides uiFilter_handlers
 @provides uiFilter_state
 @provides uiItemsStyle
 @provides updates
 @provides view
 @provides viewItem
 @provides viewListData 
 */

export const UiItemContext = createContext();

const UiItemProvider = (props) => {

  const useMerge = false

  // ParentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, settings } = parentContext ?? {}
  const { appSettings_state, appUser_state, appUsers_state, page_state, paps_state, eventInfo_state } = states
  const { appUser_fns, eventSettings_fns, page_fns } = fns ?? {}
  const { pageSettings } = page_state ?? {}
  const { homeSettings } = settings ?? {}
  const { global: homeSettings_global } = homeSettings ?? {}
  const { logging, permissionsConsole } = homeSettings_global ?? {}
  const { dataManagementSettings } = permissionsConsole ?? {}
  const { aps_global, aps_page, aps_viewItems, aps_appUserSettings } = pageSettings ?? {}
  const { dataOptions, appDataSource } = aps_global ?? {}
  const { propOverrideOn } = appSettings_state ?? {}
  const { useModifyRoutes } = dataOptions ?? {}

  const { appUser, appUsers } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  // appUsersContext  
  const { appUserProfiles } = appUsers_state ?? {}
  // only need this for bio, aboutMe, appUserPrivacy information

  // pageAreaParentContext
  const pageAreaParentContext = useContext(PageAreaParentContext)
  const { states: parent_states_p, handlers: parent_handlers_p } = pageAreaParentContext ?? {}
  const { framework_state } = parent_states_p ?? {}
  const { framework_handlers } = parent_handlers_p ?? {}

  // papsContext  
  const { pathViews, view, viewKey, _isDataModify, _dataModifyType } = paps_state ?? {}

  const viewItem_g = aps_viewItems ? aps_viewItems[view] : null
  const { dataFilters, props: props_vig } = viewItem_g ?? {}

  // frameworkContext 
  const { desktopMode, tempSettings } = framework_state ?? {}

  // settingsViewerContext
  const settingsViewerContext = useContext(SettingsViewerContext)
  const { settingsViewer_state } = settingsViewerContext ?? {}
  const { viewItem_preview } = settingsViewer_state ?? {}

  // pageContext 
  const { appAccess, desktop, display: display_global, groupingOptions, imageOptions, dataModifications: dataModifications_global } = aps_global ?? {}

  const { displaySingleItemsAsSingle, showDefaultIconsInDisplay, showDefaultIconsInDisplayIfNull, defaultIconColor } = display_global ?? {}
  const { appAccessType } = appAccess ?? {}
  const { itemWrapType } = desktop ?? {}
  let { layout, desktopLayout } = aps_page ?? {}
  const _layout = desktopMode ? desktopLayout : layout
  const { layoutType } = _layout

  // pageAreaContext
  const pageAreaContext = useContext(PageAreaContext)
  const { pageArea_state } = pageAreaContext ?? {}
  const { pageItemsShown, pageViewItemKeys } = pageArea_state ?? {}

  // dataContext
  const dataContext = useContext(DataContext);
  const { data_state } = dataContext ?? {}
  const { isCombinedData, uivi, viewListData, viewListDataCount, time_stamp, alphabetValue, excludedData } = data_state ?? {}

  const _tempSettings_vi = tempSettings && tempSettings[uivi]
  const { display: display_temp } = _tempSettings_vi ?? {}

  // filterContext
  const filterContext = useContext(FilterContext);
  const { uiFilter_state } = filterContext ?? {}
  const { filterStatusType } = uiFilter_state ?? {}
  const { filterSidebarOpen, divideIntoGroups, filterValues, searchValue, alphabetActive, alphaValue, groupDataProps, selectedFilters } = uiFilter_state ?? {}

  let { selectedTab } = uiFilter_state ?? {}
  const { groups } = groupDataProps ?? {}

  // Now for the viewItem  
  let viewItem = pageItemsShown ? pageItemsShown[uivi] : {}
  if (viewItem_preview) { viewItem = viewItem_preview }

  let isPublicSite = false

  switch (appAccessType) {
    case gEnums.siteAuthorizationTypes.public:
    case gEnums.siteAuthorizationTypes.passcode:
      isPublicSite = true
      break;
    default:
    // nothing
  }

  const uviProps = {
    aps_global, appUser, desktopMode, groupDataProps, pageViewItemKeys,
    pathViews, isPublicSite, appAccessType, selectedTab, viewItem, viewKey,
    appUser_fns, display_global
  }

  const { display, desktopDisplay, filter, search, grouping, images, dataModifications } = viewItem ?? {}

  let _display = desktopMode && desktopDisplay ? desktopDisplay : display
  if (display_temp) { _display = display_temp }

  const { caption, displayType, cardDisplayType, showExtra, extraDisplayType, showExtraInternal } = _display ?? {}

  const { showFilter } = filter ?? {}
  const { showSearch, searchOnPage } = search ?? {}

  if (groupDataProps && groupDataProps.defaultTab && !selectedTab) { selectedTab = groupDataProps.defaultTab }

  /**
   * 
   * @param {string} viKey 
   * @returns 
   */

  const getInitState = () => {

    const viewItem_key = viewItem ? viewItem.key : null

    const _filterOpts = {
      alphaValue, alphabetActive, divideIntoGroups, filterSidebarOpen, filterStatusType,
      filterValues, groups, searchValue, selectedFilters, dataFilters,
    }

    const { item: _dataItem } = viewListData ? getFirstObject(viewListData) : {}
    const dataItemCount = viewListData ? Object.keys(viewListData).length : 0

    const { email } = _dataItem ?? {}

    const { showOverview, appUserOverviewPages } = aps_appUserSettings ?? {}

    if (showOverview && appUserProfiles && appUserProfiles[email]) {
      const appUserProfile = appUserProfiles[email]
      const { bio, aboutMe, appUserPrivacy } = appUserProfile ?? {}
      const { hideBioFromApp, hideAboutMeFromApp } = appUserPrivacy ?? {}
      if (!hideAboutMeFromApp && appUserOverviewPages.includes(gEnums.appUserProfilePageTypes.appUserAboutMe) && aboutMe) { _dataItem.aboutMe = aboutMe }
      if (!hideBioFromApp && appUserOverviewPages.includes(gEnums.appUserProfilePageTypes.appUserBio) && bio) { _dataItem.bio = bio }
      if (appUserOverviewPages.includes(gEnums.appUserProfilePageTypes.appUserGallery)) { _dataItem.gallery = 'gallery' }
    }

    if (displaySingleItemsAsSingle && dataItemCount === 1 && cardDisplayType === gEnums.cardDisplayTypes.nameSectionOnly) {
      _display.cardDisplayType = gEnums.cardDisplayTypes.normal
      // _display._adjusted = true
    }

    const { name, firstName, lastName } = _dataItem ?? {}
    const dataName = lastName ? firstName + ' ' + lastName : name

    if (useMerge) { eventSettings_fns.mergeViewItemProps(viewItem) }

    const viewItemStatus = {
      isViewItemSolo: (view === viewItem_key) && viewKey && dataItemCount === 1 ? true : false,
      isViewItemList: (view === viewItem_key) && !viewKey ? true : false,
      isViewItemSubList: (view !== viewItem_key) && viewKey ? true : false,
    }

    const props_viewItem = getProps_viewItem(viewItem, viewListData, props_vig)
    ammendIcons(props_vig, props_viewItem, aps_viewItems, defaultIconColor, showDefaultIconsInDisplay, showDefaultIconsInDisplayIfNull)

    let allowAppUserEdit;

    if (appUser_fns.validateAccess_eventUserPage(pathViews)) { allowAppUserEdit = true }

    const _allowPopups = getAllowPopups(layoutType, filterStatusType, viewItem_preview)

    const _uiAmmends = ammendUiItem({
      appUserAccess,
      dataModifications_global,
      dataModifications,
      grouping,
      groupingOptions,
      imageOptions,
      images,
      props_viewItem,
      view,
      viewItemStatus,
      viewKey,
      viewListDataCount,
      viKey: viewItem_key,
    })

    const _pageOpts = { _display, itemWrapType, layout, layoutType }
    const _viewItemOpts = { caption, displayType, cardDisplayType, showExtra, extraDisplayType, showExtraInternal, searchOnPage, showFilter, showSearch }
    const _dataOpts = { alphabetValue, isCombinedData, time_stamp, uivi, viewListData }

    return {
      ..._uiAmmends,
      _display,
      _filterOpts,
      _pageOpts,
      _viewItemOpts,
      allowAppUserEdit,
      allowPopups: _allowPopups,
      appDataSource,
      appUser_fns, // for validation
      appUserAccess,
      dataItemCount,
      dataName,
      dataOpts: _dataOpts,
      excludedData,
      itemData: _dataItem,
      logging,
      page_fns,
      paps_state, // view, pathViews 
      props_viewItem, // from viewitem not needed?? 
      tabIndex: props.tabIndex,
      view,
      viewItem_key,
      viewItem_g,
      viewItem,
      viewItemStatus,
      viewKey,
      viewListData,
      modifyActionType: useModifyRoutes && _isDataModify && _dataModifyType ? _dataModifyType : null,
      propOverrideOn,
      _propsToggled
    }
  }

  const [item_state, itemDispatch] = useReducer(itemReducer, itemInitialState({}));
  const item_handlers = itemHandlers(itemDispatch, item_state)
  const { _propsToggled, overridesChanged } = item_state ?? {}

  useEffect(() => {
    if (viewItem) {
      const _init = getInitState()
      const _initOther = ammendViewItemsSettings(uviProps, _init, aps_appUserSettings)
      const _fullInit = { ..._init, ..._initOther }
      item_handlers.handleInit_item(_fullInit, dataManagementSettings, aps_appUserSettings, pageSettings)
    }
    // eslint-disable-next-line
  }, [
    alphabetValue,
    aps_appUserSettings,
    filterSidebarOpen,
    filterStatusType,
    pageSettings,
    propOverrideOn,
    tempSettings,
    uivi,
    viewItem,
    viewListData,
    _propsToggled
  ]);

  useEffect(() => {
    item_handlers.handleGet_validationAccess({ alphaValue, filterValues })
    // eslint-disable-next-line
  }, [alphaValue, filterValues && filterValues.length]);

  useEffect(() => {
    framework_handlers.handleChanged_override(overridesChanged)
    // eslint-disable-next-line
  }, [overridesChanged]);

  useEffect(() => {
    item_handlers.handleSet_updateFilterOpts({ alphaValue, filterValues })
    // eslint-disable-next-line
  }, [alphaValue, filterValues && filterValues.length]);

  return <UiItemContext.Provider value={{ item_state, item_handlers }}>
    {props.children}
  </UiItemContext.Provider>

};

export default UiItemProvider 