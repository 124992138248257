import _ from 'lodash';
import { copyObj } from '../../../../../global/common_web/copy';
import { matchStatusTypes } from '../SportsDataReducer';
import { ammendTeamName } from '../SportsMatchReducer';
import { getMatchUpdates } from './sportsGsData';
import { getMatchInfo } from './sportsGsUpdates';

const _removeProps = [
  '_itemKey',
  'parentKeys',
  'position',
  'combined',
]

export const _teamsFoundTypes = {
  bothTeams: 'bothTeams',
  singleTeam: 'singleTeam',
  noTeams: 'noTeams'
}

export const _matchFoundTypes = {
  new: 'new',
  cancelled: 'cancelled',
  exhibition: 'exhibition',
  existing: 'existing',
  scoreChanged: 'scoreChanged',
  inversed: 'inversed',
  nonDistrict: 'nonDistrict',
}

export const ammendMatchesFromGsOrMedia = (matches_gs, matches_flat, matches_info, teams, gameScoringType, callback, forPlayoffs) => {

  const _matches_info = []

  const { matches, playoffMatches } = matches_info ?? {}
  const { values } = matches_flat ?? {}
  const { season: matches_flat_season, playoffs: matches_flat_playoff } = values ?? {}

  if (matches_gs && (matches_flat_season || matches_flat_playoff)) {

    const _matches_db = forPlayoffs ? playoffMatches : matches

    // get only the gs matches that have a startDate, home and away teams.
    const matches_gs_actual = _.filter(matches_gs, (match) => (!_.isNil(match.startDate) && !_.isNil(match.away) && !_.isNil(match.home)));

    ammendMatchTeamNames(matches_gs_actual)

    const { matchCompares, _matchDataUpdates, _matchStatusGroups } = getMatchUpdates(_matches_db, matches_gs_actual, teams, forPlayoffs)

    const m_flat = forPlayoffs ? matches_flat_playoff : matches_flat_season

    const _matches_flat = copyObj(m_flat)

    // loop the matches_gs
    Object.keys(matches_gs_actual).forEach(dk => {
      const match_gs = matches_gs_actual[dk]
      match_gs._matchStatusTypes = []
      const match_info = getMatchInfo(_matches_flat, match_gs, teams, _matches_db)
      _matches_info.push(match_info)
    })

    const matches_info_grouped = _.groupBy(_matches_info, 'matchFoundType')
    console.log('matches_info_grouped', matches_info_grouped)

    Object.keys(matches_info_grouped).forEach(mft => {
      switch (mft) {
        case _matchFoundTypes.cancelled:
          // nothing
          break;

        case _matchFoundTypes.existing:
        case _matchFoundTypes.new:
          const matches_info_group = matches_info_grouped[mft]
          Object.keys(matches_info_group).forEach(mgk => {
            const _match_info = matches_info_group[mgk]
            const _match_flat = _matches_flat[_match_info.matchFlatKey]
            if (_match_flat) {
              _match_flat._matchStatusTypes = _match_info.matchStatusTypes
              ammendFlatMatch(_match_flat, _match_info)
            }
          })
          break;
        default:
        // nothing
      }
    })

    const matchesInfo = {
      matchCompares,
      _matchDataUpdates,
      _matchStatusGroups,
      matches_gs,
    }

    callback(matchesInfo, forPlayoffs)
  }
}

/**
 * Changes the team names to names to the correct names, if needed.
 * @param {object} matches_gs 
 */
const ammendMatchTeamNames = (matches_gs) => {
  Object.keys(matches_gs).forEach(k => {
    const match_gs = matches_gs[k]
    const { home, away } = match_gs
    if (home && away) {
      match_gs.home = ammendTeamName(home)
      match_gs.away = ammendTeamName(away)
    }
  })
}

const convertGsMatches = (matches_gs, teams) => {
  const gsMatches = copyObj(matches_gs)
  Object.keys(gsMatches).forEach(k => {
    const match_gs = gsMatches[k]
    const { home, away } = match_gs
    delete match_gs.combined
    if (home && away) {
      const _home = _.find(teams, { name: home })
      const _away = _.find(teams, { name: away })
      if (_home) {
        match_gs.home = {
          name_gs: home,
          id: _home.id,
          name: _home.name,
          sections: _home.sections,
          levels: _home.levels
        }
      }
      if (_away) {
        match_gs.away = {
          name_gs: away,
          id: _away.id,
          name: _away.name,
          sections: _away.sections,
          levels: _away.levels
        }
      }
    }
  })
  return gsMatches
}

/**
 * 
 * @param {object} match_flat 
 * @param {object} match_group 
 */
const ammendFlatMatch = (match_flat, match_group) => {

  const { match_gs, matchScores, inversed } = match_group ? match_group : {}
  let { startDate: startDate_gs, postponed, cancelled, exhibition } = match_gs ? match_gs : {}

  if (!match_flat._matchStatusTypes) {
    match_flat._matchStatusTypes = []
  }

  // chaget the date if needed
  if (match_group && startDate_gs && startDate_gs !== match_group.startDate) {
    match_flat._matchStatusTypes.push(matchStatusTypes.dateChanged)
    match_flat._newStartDate = startDate_gs
  }

  // still updating the match, but ammending the matchStatusTypes
  if (postponed) { match_flat._matchStatusTypes.push(matchStatusTypes.postponed) }
  if (cancelled) { match_flat._matchStatusTypes.push(matchStatusTypes.cancelled) }
  if (exhibition) { match_flat._matchStatusTypes.push(matchStatusTypes.exhibition) }

  if (matchScores && matchScores.gs) {
    // if `gs` has scores for `home` and `away`
    if (_.isNumber(matchScores.gs.away) && _.isNumber(matchScores.gs.home)) {
      // if `found` has scores for `home` and `away`
      if (matchScores.db && _.isNumber(matchScores.db.away) && _.isNumber(matchScores.db.home)) {
        // if `found` and `gs` scores are different
        if ((matchScores.gs.home !== matchScores.db.home) || (matchScores.gs.away !== matchScores.db.away)) {
          match_flat._matchStatusTypes.push(matchStatusTypes.scoreChanged)
          if (inversed) {
            inverseMatch(match_flat, matchScores)
          } else {
            match_flat.awayScore = parseInt(matchScores.gs.away)
            match_flat.homeScore = parseInt(matchScores.gs.home)
          }
        }
      } else {
        // if `gs` has scores for `home` and `away`
        if (_.isNumber(matchScores.gs.away) && _.isNumber(matchScores.gs.home)) {
          match_flat._matchStatusTypes.push(matchStatusTypes.scoreChanged)
          if (inversed) {
            inverseMatch(match_flat, matchScores)
          } else {
            match_flat.awayScore = parseInt(matchScores.gs.away)
            match_flat.homeScore = parseInt(matchScores.gs.home)
          }
        }
      }
    }
  }
}

const inverseMatch = (match_flat, matchScores) => {
  match_flat.old = {
    away: match_flat.away,
    home: match_flat.home
  }
  match_flat.away = match_flat.old.home
  match_flat.home = match_flat.old.away
  match_flat.awayScore = matchScores.gs.away
  match_flat.homeScore = matchScores.gs.home
  match_flat._matchStatusTypes.push(matchStatusTypes.inversed)
}