import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Sidebar } from 'semantic-ui-react'
import { ParentContext } from '../../../../global/cnr/contexts/ParentContext'
import { updateArrayInclusion } from '../../../../global/common/sorting'
import { copyObj } from '../../../../global/common_web/copy'
import UiSaveButtons from '../../../../global/components/buttons/UiSaveButtons'
import DataSidebar from '../../../../global/components/swipers/DataSidebar'
import JsonViewer from '../../../../global/components/viewers/JsonViewer'
import { appIconTypes } from '../../../../global/enums/appIconTypes'
import Wrapper, { wrapperTypes } from '../../../../global/wrappers/Wrapper'
import { SportsSeasonContext, _resultsProp } from '../../cnr/contexts/SportsSeasonContext'
import { _matchSeasonalCollection } from '../../cnr/reducers/SportsSeasonReducer'
import { seasonalHelpers, seasonalUpdateTypes } from '../../cnr/reducers/reducerHelpers/seasonalSportsData'
import { sportsHelpers } from '../../cnr/reducers/reducerHelpers/sportsHelpers'
import MatchPlayoffPending from './MatchPlayoffPending'

const _allowExistings = false

const MatchPlayoffUpdate = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states } = parentContext ?? {}
  const { paps_state, page_state } = states
  const { pathViews } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { sportSettings } = aps_global ?? {}

  // sportsSeasonContext
  const sportsSeasonContext = useContext(SportsSeasonContext)
  const { sportsSeason_state } = sportsSeasonContext ?? {}
  const { sportPermissions, teams_info, matches_info, latestSeason, sportsKey } = sportsSeason_state ?? {}

  const {
    useScoringCollection,
    useScoringCollectionOnly,
  } = sportSettings ?? {}


  const [pendings, setPendings] = useState({})
  const [showConfirmation, setShowConfirmation] = useState()
  const [selectedMatchKeys, setSelectedMatchKeys] = useState([])
  const [selectedMatchGroups, setSelectedMatchGroups] = useState({})

  const _matchDataUpdatesCount = selectedMatchKeys.length
  const allowUpdate = sportPermissions.any && sportPermissions[seasonalUpdateTypes.matchesList]

  const handleUpdateMatches_confirm = () => setShowConfirmation(!showConfirmation)

  const handleUpdateMatches = async () => {
    const cb = (r) => { console.log('r', r) }
    const _updateProps = {
      callback: cb,
      collectionName: _matchSeasonalCollection,
      forPlayoffs: true,
      dataToUpdate: selectedMatchGroups,
      pathViews,
      pks: seasonalHelpers.getPks(pathViews, latestSeason, sportsKey),
      seasonalUpdateType: seasonalUpdateTypes.matchesList,
      useScoringCollection,
      useScoringCollectionOnly,
    }
    await seasonalHelpers.updateSeasonalMatchesByLevel(_updateProps)
  }

  const handleSelectedMatchKeys = (matchKey, levels) => {
    const _selectedMatchKeys = [...selectedMatchKeys]
    updateArrayInclusion(_selectedMatchKeys, matchKey)
    setSelectedMatchKeys(_selectedMatchKeys)
  }

  /**
   * 
   * @param {object} matches 
   * @returns all the playoff matches that have a result
   */
  const matchesWithResults = (matches) => _.pickBy(matches, (match) => {
    const hasResultObject = _.has(match, 'results');
    return hasResultObject;
  });

  const removeProps = (obj) => {
    delete obj._existingInfo
    delete obj._exists
    delete obj._full
  }

  useEffect(() => {

    const { teams } = teams_info ?? {}
    const { playoffMatches } = matches_info ?? {}

    const _playoffMatches = playoffMatches ? copyObj(playoffMatches) : {}

    if (_playoffMatches) {

      const _pendings = {
        levelMatchUpdates: {},
        matchWinners: {},
        matchLosers: {}
      }

      let levelKey;

      const _matchesWithResults = matchesWithResults(_playoffMatches)

      _.each(_matchesWithResults, (mwr, pmk) => {
        const results = mwr ? mwr[_resultsProp] : undefined;
        const { scoreDisplays } = results ?? {}
        const { full } = scoreDisplays ?? {}
        const { _levelKey } = sportsHelpers.getPendingMatchInfo(mwr, _playoffMatches, _pendings, teams)
        levelKey = _levelKey
      })

      setPendings(_pendings)

      const _mpldu = copyObj(_pendings.levelMatchUpdates)

      Object.keys(_mpldu).forEach(k => {
        removeProps(_mpldu[k])
      })

      const _selectedMatchKeys = []
      const _selectedMatchUpdates = {}

      _.each(_pendings.matchWinners, (wm, k) => {
        const updateKey = 'matches.' + k + '.home'
        const { levels } = wm ?? {}
        const wmu = _mpldu[levels] && _mpldu[levels][updateKey]
        if (wmu && (!wm._homeAlreadyExists || _allowExistings)) {
          _selectedMatchUpdates[updateKey] = wmu
          _selectedMatchKeys.push(k)
        }
        if (wmu && (!wm._awayAlreadyExists || _allowExistings)) {
          _selectedMatchUpdates[updateKey] = wmu
          _selectedMatchKeys.push(k)
        }
      })
      setSelectedMatchKeys(_selectedMatchKeys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (pendings && pendings.levelMatchUpdates && selectedMatchKeys) {
      const _mpldu = copyObj(pendings.levelMatchUpdates)

      Object.keys(_mpldu).forEach(k => {
        delete _mpldu[k]._existingInfo
        delete _mpldu[k]._exists
        delete _mpldu[k]._full
      })

      const _selectedMatchUpdates = {}

      _.each(_mpldu, (mls, levelKey) => {
        _.each(mls, (match, matchKey) => {
          const matchKeyS = matchKey.split('.')
          if (selectedMatchKeys.includes(matchKeyS[1])) {
            if (!_selectedMatchUpdates[levelKey]) { _selectedMatchUpdates[levelKey] = {} }
            removeProps(match)
            _selectedMatchUpdates[levelKey][matchKey] = match
          }
        })
      })
      setSelectedMatchGroups(_selectedMatchUpdates)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatchKeys]);

  const content = () => <div className='playoffs-pending'>
    <div>Winner Matches</div>
    <MatchPlayoffPending matches={pendings.matchWinners} handleSelectedMatchKeys={handleSelectedMatchKeys} selectedMatchKeys={selectedMatchKeys} />
    <div>Consulation Matches</div>
    <MatchPlayoffPending matches={pendings.matchLosers} handleSelectedMatchKeys={handleSelectedMatchKeys} selectedMatchKeys={selectedMatchKeys} />
  </div>

  const wrapper = () => <Wrapper
    content={content()}
    footer={<UiSaveButtons save={{ oc: handleUpdateMatches_confirm, icon: 'arrow right', caption: 'Update Playoff Matches (' + _matchDataUpdatesCount + ')', color: allowUpdate ? 'blue' : 'olive', disabled: _matchDataUpdatesCount === 0 }} />}
    wrapperType={wrapperTypes.paddedFooter}
  />

  const footer_right = () => {
    const btns = []
    btns.push({ oc: handleUpdateMatches, caption: 'Update ' + _.startCase('selectedMatchUpdates'), icon: appIconTypes.save, color: sportPermissions.any ? 'green' : 'olive' })
    return <UiSaveButtons
      others={btns}
      color={'blue'}
    />
  }

  const content_right = () => {
    return <Wrapper
      header={_.startCase('selectedMatchUpdates')}
      content={<JsonViewer json={selectedMatchGroups} name={'selectedMatchUpdates'} />}
      footer={footer_right()}
      wrapperType={wrapperTypes.paddedHeaderAndFooter}
    ></Wrapper>
  }

  const sidebar_right = (visible) => <DataSidebar direction={'right'} visible={visible} content={visible && content_right()} onHide={handleUpdateMatches_confirm} />

  const wrapper_sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_right(showConfirmation ? true : false)}
    <Sidebar.Pusher dimmed={showConfirmation ? true : false} className={'h100'}>
      {wrapper()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>


  return pendings.matchWinners ? wrapper_sidebar() : <div></div>

}

export default MatchPlayoffUpdate