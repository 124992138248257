import { getAppUserAccess } from '../../../auth/appUserAccessPermissions';
import { createRefPath } from '../../appData/appRefPaths';
import { fs_set_doc } from '../../appData/fsData';

/**
 * Ammends a single field (amendType) in a single document
 * @param {*} props 
 */
export const fsUpdate_updateAppUserDoc = (props) => {
  const { baseRef, dispatch, ufProps } = props
  const { refPath } = baseRef
  const { vit, appUser, itemData } = ufProps ?? {}
  const appUserAccess = getAppUserAccess(null, appUser)
  const { uid, appUserSessionKey } = appUserAccess ?? {}
  const _uid = uid ? uid : appUserSessionKey
  const _setRef = createRefPath([vit, _uid], refPath)
  fs_set_doc(_setRef, itemData, true, dispatch)
}