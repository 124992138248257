import { getAuth, isSignInWithEmailLink } from "firebase/auth";
import React, { useContext, useEffect, useState } from 'react';
import { Button, Header, HeaderContent, Icon, Input, Segment } from 'semantic-ui-react';
import { ValidationContext } from "../cnr/contexts/ValidationContext";
import { _emailSignInStorageItem } from "../cnr/reducers/ValidationReducer";
import { gEnums } from "../enums/globalEnums";
import { currentHelpers } from '../redirection/current';
import Wrapper, { wrapperTypes } from '../wrappers/Wrapper';
import { validateEmail } from './validation';
import { createRefPath } from "../firestoreData/appData/appRefPaths";
import { fs_get_doc } from "../firestoreData/appData/fsData";
import { renderHtml } from "../components/html/renderHtml";
import { useNavigate } from "react-router-dom";

const _alwaysValidate = false

/**
 * 
 * @returns a FullPageWrapper with controls to validate an email sign in.
 */
const EmailSignInConfirmation = () => {

  console.log('EmailSignInConfirmation')

  const storageConfirmationEmail = _alwaysValidate ? null : currentHelpers.storageItem_get(_emailSignInStorageItem)

  let { href, queryValues } = currentHelpers.getHashPath()

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches || navigator.standalone === true

  const navigate = useNavigate()

  const url = href
  const _url = new URL(url)
  const { origin, hash } = _url ?? {}
  const root = hash.split('?')[0]
  const _openPath = origin + '/' + root

  const { lang, testing, email: email_param } = queryValues ?? {}

  const _navigatePath = lang ? lang.replace('en#', '') : null
  const splitArray = queryValues.lang.split('/')
  const resultArray = splitArray.slice(1);
  const resultString = resultArray.join('/');

  // validationContext
  const validationContext = useContext(ValidationContext)
  const { validation_state, validation_handlers } = validationContext ?? {}
  const { isSignInLink, validatedAppUser, validationStatus, validationResponse } = validation_state ?? {};
  const { status: emailValidationStatus, email } = validatedAppUser ?? {};
  const { success: signInSuccess, error: signInError } = validationResponse ?? {};

  // retrieved, lists   
  const [appUserEmail, setAppUserEmail] = useState()
  const [appUserTempEmail, setAppUserTempEmail] = useState()
  const [emailConfimationHelp, setEmailConfimationHelp] = useState()

  const _closeAvailable = typeof window.close === 'function'

  const {
    allowAppClose,
    allowAppLaunch,
    forceEmailConfirmation,
    emailConfirmationAcceptedText,
    emailConfirmationActivatedText,
    emailConfirmationCloseText,
    emailConfirmationConfirmEmailText,
    emailConfirmationConfirmText,
    emailConfirmationFailedText,
    emailConfirmationSafeleyCloseText,
    emailConfirmationSuccessText,
    emailConfirmationTitleText,
  } = emailConfimationHelp ?? {}

  const updateUserValidation = (email, status) => validation_handlers.handleUpdate_appUserValidation(email, status)

  const confirmationTexts = {
    accepted: emailConfirmationAcceptedText ? renderHtml(emailConfirmationAcceptedText) : 'You have already signed in to the App.',
    activated: emailConfirmationActivatedText ? renderHtml(emailConfirmationActivatedText) : 'You have already signed in to the App.',
    buttonClose: emailConfirmationCloseText ? emailConfirmationCloseText : 'Close',
    buttonConfirm: emailConfirmationConfirmText ? emailConfirmationConfirmText : 'Confirm',
    confirmEmail: emailConfirmationConfirmEmailText ? renderHtml(emailConfirmationConfirmEmailText) : 'Please confirm your email address.',
    failed: emailConfirmationFailedText ? renderHtml(emailConfirmationFailedText) : 'There was an issue signing into the app. The email link may be invalid or may have timed out.',
    safeleyClose: emailConfirmationSafeleyCloseText ? emailConfirmationSafeleyCloseText : 'You can safely close this window.',
    success: emailConfirmationSuccessText ? renderHtml(emailConfirmationSuccessText) : 'You have successfully signed in to the App.',
    title: emailConfirmationTitleText ? emailConfirmationTitleText : 'Email Confirmation',
  }

  const _allowOpen = (allowAppLaunch && validationStatus === 'success')
  const _allowTestOpen = (allowAppLaunch && validationStatus === 'success') || testing

  // Woohoo!
  // Firebase CLI Login Successful

  // You are logged in to the Firebase Command-Line interface. You can immediately close this window and continue using the CLI.

  // sets the text for the divs
  useEffect(() => {
    const cb = (results) => {
      const { emailConfimationHelp: _emailConfimationHelp } = results ?? {}
      setEmailConfimationHelp(_emailConfimationHelp)
    }
    const _refPath = createRefPath(['settings', 'home', 'settings', 'global'])
    fs_get_doc(_refPath, cb)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // sets the appUserEmail from the storageConfirmationEmail
  useEffect(() => {
    if (emailConfimationHelp) {
      if (!forceEmailConfirmation) {
        setAppUserEmail(storageConfirmationEmail)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [emailConfimationHelp]);

  // sets isSignInLink
  useEffect(() => {
    const auth = getAuth()
    // the firebase method to validate if the URL is an email link
    // firebase
    const _isSignInLink = isSignInWithEmailLink(auth, url)
    validation_handlers.handleSet_isSignInLink(_isSignInLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  // runs when confirmationEmail exists
  // useEffect(() => {
  //   if (isSignInLink && appUserEmail) {
  //     validation_handlers.handleGet_validationByUrl(url, appUserEmail)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps 
  // }, [isSignInLink, appUserEmail]);

  useEffect(() => {
    if (isSignInLink && email_param) {
      // get the document from _appUserValidation
      validation_handlers.handleGet_validationByEmail(email_param)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isSignInLink, email_param]);

  // updates the _appUserValidation document IF not 'request'
  useEffect(() => {

    if (!testing && appUserEmail && emailValidationStatus) {

      console.log('emailValidationStatus', emailValidationStatus)

      switch (emailValidationStatus) {
        case gEnums.emailValidationTypes.requested:
          updateUserValidation(appUserEmail, emailValidationStatus)
          break;

        case gEnums.emailValidationTypes.requestedNonLink:
          updateUserValidation(appUserEmail, emailValidationStatus)
          break;

        case gEnums.emailValidationTypes.accepted:
          validation_handlers.handleSignIn_withValidatedEmailLink(validatedAppUser)
          // if (_closeAvailable) {
          //   handleCloseApp()
          // }
          break;
        default:
        // nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [emailValidationStatus]);

  // if the appUserEmail = the email of the validatedAppUser, set validationStatus = true
  useEffect(() => {
    if (!testing && appUserEmail && email && (appUserEmail === email)) {
      validation_handlers.handleSet_isValidated(true)
      updateUserValidation(appUserEmail, gEnums.emailValidationTypes.accepted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [appUserEmail, email]);

  const handleConfirmEmail = () => setAppUserEmail(appUserTempEmail)
  const handleUpdateParentData = (e, d) => setAppUserTempEmail(d.value)

  const handleCloseApp = () => {
    try {
      window.close();
    } catch (error) {

    }
  }

  const handleOpenApp = () => {
    if (isStandalone) {
      navigate(_navigatePath)
    } else {
      window.location.href = _openPath
    }
    // window.location.href = _openPath
    // window.location.reload(true);
    // Add an event listener to perform a hard refresh when the new window is loaded
    // if (openedWindow) {
    //   openedWindow.addEventListener('load', function () {
    //     // Perform a hard refresh
    //     openedWindow.location.reload(true);
    //   });
    // }
  }

  const validConfirmationEmail = validateEmail(appUserEmail)

  const header_pending = () => <Header as='h4'>
    <Icon name='spinner' loading />
    <HeaderContent>{'Validating...'}</HeaderContent>
  </Header>

  const header_success = (items) => <Header as='h4'>
    <Icon name='thumbs up' color={'green'} />
    <HeaderContent>{items}</HeaderContent>
  </Header>

  const header_error = (items) => <Header as='h4'>
    <Icon name='thumbs down' color={'red'} />
    <HeaderContent>{items}</HeaderContent>
  </Header>

  const seg_middle = (m_s, m_e) => {
    if (!testing && validationStatus) {
      return <Segment placeholder >
        {m_s && m_s.length > 0 && header_success(m_s)}
        {m_e && m_e.length > 0 && header_error(m_e)}
      </Segment>
    } else {
      if (testing) {
        return <Segment placeholder >
          {'This is just a test.'}
        </Segment>
      } else {
        return <Segment placeholder >
          {header_pending()}
        </Segment>
      }

    }
  }

  const seg_note = () => <Segment>
    {confirmationTexts.safeleyClose}
  </Segment>

  const message_validated = () => {

    let mss = [];
    let mse = [];

    if (signInSuccess) {
      mss.push(confirmationTexts.success)
    } else if (signInError) {
      mse.push(confirmationTexts.failed)
    }

    switch (emailValidationStatus) {
      case gEnums.emailValidationTypes.accepted:
        mss.push(confirmationTexts.success)
        break;
      case gEnums.emailValidationTypes.activated:
        mss.push(confirmationTexts.accepted)
        break;
      default:
      // nothing
    }

    if (validationStatus === 'failed') {
      mse.push(confirmationTexts.failed)
    }

    const m_s = mss.map((m, index) => (<p key={index}>{m}</p>))
    const m_e = mse.map((m, index) => (<p key={index}>{m}</p>))

    return <Segment.Group align={'center'}>
      <Segment style={{ fontWeight: 'bold' }} >{confirmationTexts.title}</Segment>
      <Segment>
        {'Email: ' + appUserEmail}
      </Segment>
      {seg_middle(m_s, m_e)}
      {seg_note()}
      <Segment>
        {_closeAvailable && allowAppClose && <Button primary onClick={() => { handleCloseApp() }}>{confirmationTexts.buttonClose}</Button>}
        {_allowOpen && <Button primary onClick={() => { handleOpenApp() }}>Open</Button>}
        {_allowTestOpen && <Button primary onClick={() => { handleOpenApp() }}>Test Open</Button>}
        {testing && <div>{'Testing Only: '}{_openPath}</div>}
      </Segment>
    </Segment.Group>
  }

  const form_email = () => {
    return <Segment.Group align={'center'}>
      <Segment style={{ fontWeight: 'bold' }} ><Icon name='sign in' color={'blue'} style={{ marginRight: '1em' }} />{confirmationTexts.title}</Segment>
      <Segment>
        {confirmationTexts.confirmEmail}
      </Segment>
      <Segment placeholder >
        <Input onChange={handleUpdateParentData} style={{ width: '100%', marginTop: '2em' }} />
      </Segment>
      {seg_note()}
      <Segment>
        {!testing && <Button primary disabled={!appUserTempEmail} onClick={() => { handleConfirmEmail() }}>{confirmationTexts.buttonConfirm}</Button>}
        {testing && <Button primary onClick={() => { handleOpenApp() }}>Open</Button>}
        {testing && <div>{_openPath}</div>}
      </Segment>
    </Segment.Group>
  }

  const wrapper_validated = () => <Wrapper
    content={message_validated()}
    wrapperType={wrapperTypes.padded}
    css={{ content: 'content-center' }}
  />

  const wrapper_validationForm = () => <Wrapper
    content={form_email()}
    wrapperType={wrapperTypes.padded}
    css={{ content: 'content-center' }}
  />

  const fullPageWrapper_validated = () => <Segment basic style={{ backgroundColor: 'white', height: '100vh' }}>
    {wrapper_validated()}
  </Segment>

  const fullPageWrapper_validationForm = () => <Segment basic style={{ backgroundColor: 'white', height: '100vh' }}>
    {wrapper_validationForm()}
  </Segment>

  if (appUserEmail || validationStatus) {
    return fullPageWrapper_validated()
  } else {
    return fullPageWrapper_validationForm()
  }

}

export default EmailSignInConfirmation

