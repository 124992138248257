import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useReducer } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { rightInitialState, rightMenuHandlers, rightMenuReducer, rightMenuTriggerTypes, rightMenuTypes } from '../cnr/reducers/RightMenuReducer';
import { defaultHelpers, withDefaultProps } from '../common/defaultProps';
import { uniqueKey } from "../common/keys";
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import { gEnums } from '../enums/globalEnums';
import { openExternal } from '../viewSettings/helpers/settingsLinks';

const SettingsAppRightMenu = (props) => {

  const { allowSideSettings, allowSideData } = props ?? {}

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, fns, handlers } = parentContext ?? {}
  const { appSettings_state, appUser_state, paps_state, page_state } = states
  const { appSettings_handlers } = handlers
  const { propOverrideOn } = appSettings_state ?? {}
  const { appArea, pathViews, pathModes, pathName, view } = paps_state ?? {}
  const { pageSettings } = page_state ?? {}
  const { aps_global } = pageSettings ?? {}
  const { dataModifications } = aps_global ?? {}
  const { appUsers } = appUser_state ?? {}
  const { appUser_fns } = fns

  const appUserAccess = getAppUserAccess(appUsers)
  const { accessLevel: appUserAccessLevel, isSuperAdminDeveloper } = appUserAccess ?? {}
  const _allowDatabase = isSuperAdminDeveloper
  const authSettingsPermissionType = appUser_fns.validate_trueSettingsAuth(appUserAccess)
  const allowAdmin = authSettingsPermissionType === gEnums.viewPermissionTypes.allow

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers, framework_state } = frameworkContext ?? {}
  const { frAllows, pathViews: pathViews_fw, frInfo, overridesChanged, showRightMenuCaptions, navigationOptions, swipeItemKey } = framework_state ?? {}
  const { viewItem } = frInfo ?? {}
  const { key: key_viewItem } = viewItem ?? {}

  const isLanding = view === 'landing' || view === 'clients' || view === 'home'
  const allowSd = pathModes.sport || pathModes.district || pathModes.organization
  const isEvent = pathViews.events

  let dashboardType;

  if (pathModes.sport) {
    dashboardType = 'Sports'
  } else if (pathModes.district) {
    dashboardType = 'District'
  } else if (pathModes.organization) {
    dashboardType = 'Organization'
  }

  const _settings = {
    _allowDatabase,
    allowAdmin,
    allowSd,
    allowSideData,
    allowSideSettings,
    appUserAccess,
    appUserAccess,
    dataModifications,
    frAllows,
    isEvent,
    isLanding,
    key_viewItem,
    overridesChanged,
    propOverrideOn,
    showRightMenuCaptions,
    navigationOptions,
    view,
  }

  const initState = rightInitialState({ _settings })
  const [rightMenu_state, rightMenu_dispatch] = useReducer(rightMenuReducer, initState);
  const rightMenu_handlers = rightMenuHandlers(rightMenu_dispatch)
  const { rightOptions, currentMenuType, triggerActionType, frt, dmt } = rightMenu_state ?? {}
  const { handleInit_rightMenu, handleSet_currentMenuType } = rightMenu_handlers ?? {}

  useEffect(() => {
    handleInit_rightMenu(_settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appArea,
    appUserAccessLevel,
    dataModifications,
    frAllows,
    isLanding,
    navigationOptions,
    overridesChanged,
    pathName,
    pathViews,
    propOverrideOn,
    showRightMenuCaptions,
    swipeItemKey,
  ]);

  useEffect(() => {
    if (triggerActionType) {
      console.log('triggerActionType', triggerActionType)
      switch (triggerActionType) {
        case rightMenuTriggerTypes.toggle:
          framework_handlers.handleToggle_rightMenu()
          break;
        case rightMenuTriggerTypes.frameworkRight:
          framework_handlers.handleShow_frameworkRight(view, frt, dmt)
          break;
        case rightMenuTriggerTypes.openDb:
          openExternal.db(pathViews_fw, null, view, pathViews_fw && pathViews_fw[view])
          break;
        case rightMenuTriggerTypes.settings:
          appSettings_handlers.handleToggle_settingsRightOn()
          break;
        case rightMenuTriggerTypes.propsSave:
          framework_handlers.handleUpdate_propsFromToggled()
        default:
        // nothing
      }
      handleSet_currentMenuType()
    }
    appSettings_handlers.handlePropOverride(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerActionType]);

  useEffect(() => {
    switch (currentMenuType) {
      case rightMenuTypes.propsOverride:
        appSettings_handlers.handlePropOverride(true)
        break;
      default:
        appSettings_handlers.handlePropOverride(false)
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuType]);

  const icon = (item) => {
    const { icon, color, iconCorner, iconCornerColor, showIconCorner, disabled } = withDefaultProps(item, defaultHelpers.iconDefaults);
    if (iconCorner) {
      return <Icon.Group>
        <Icon name={icon} color={color ? color : null} />
        <Icon corner name={iconCorner} color={iconCornerColor ? iconCornerColor : 'blue'} />
      </Icon.Group>
    } else {
      return <Icon name={icon} color={color ? color : null} disabled={disabled} />
    }
  }

  const menuItems = (opts, forData) => {
    const mis = _.map(opts, (item, key) => {
      const { caption, disabled, text, oc, frt, rmt, dmt, showCaption, dividerBefore, headerBefore } = item
      let cn = dividerBefore ? 'sbm-top' : ''
      if (headerBefore) { cn += ' hdr' }
      const _caption = showRightMenuCaptions || showCaption ? text : caption
      if (_caption) { cn += ' left-sub' }
      if (forData) { cn += ' dat' }
      return <Menu.Item
        key={uniqueKey('svs', 'mi', key)}
        name={_caption}
        onClick={() => oc && oc({ frt, rmt, dmt })}
        title={text}
        disabled={disabled}
        className={cn ? cn : null}
      >
        {icon(item)}
        {_caption && _caption}
      </Menu.Item>
    })
    return mis
  }

  const menu = () => {

    const divs = []

    switch (currentMenuType) {
      case rightMenuTypes.propsOverride:
        divs.push(<div>{menuItems(rightOptions.propz)}</div>)
        break;
      case rightMenuTypes.subActions:
        divs.push(<div>{menuItems(rightOptions.subActions)}</div>)
        break;
      default:
        return <Menu inverted icon vertical fixed="right" className='frm-right'>
          <div>
            {menuItems(rightOptions.page)}
            {menuItems(rightOptions.data, true)}
            {rightOptions.subAction && rightOptions.subAction.length > 0 && menuItems(rightOptions.subAction, true)}
          </div>
          <div>{menuItems(rightOptions.home)}</div>
        </Menu>
    }

    return <Menu inverted icon vertical fixed="right" className='frm-right'>
      {divs}
    </Menu>
  }

  return <Suspense fallback={<SuspenseDimmer caption={'appSub'} origin={'SettingsAppRightMenu'} />}>
    {rightOptions ? menu() : <div></div>}
  </Suspense>

}

export default SettingsAppRightMenu