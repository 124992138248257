
import _ from 'lodash'
import { itemActionTypes } from '../viewSettings/enums/itemActionTypes'

const optionTypes = {
  appUserItems: 'appUserItems',
  dataManagement: 'dataManagement',
  other: 'other',
  userItems: 'userItems',
}

export const sidebarOptions = {
  dataManagement: (dataModification) => dataManagementOptions(dataModification),
  appUserDirect: (pageNavOptions, uivi) => appUserDirectOptions(pageNavOptions, uivi),
  appUserAction: (appUserAccess, appUserActionItems, itemData, appUserCollection) => appUserActionOptions(appUserAccess, appUserActionItems, itemData, appUserCollection),
  other: (data, dataManagement) => otherOptions(data, dataManagement),
}

const dataManagementOptions = (dataModification) => {
  const midm = []
  _.each(dataModification, (item, dmiKey) => {
    switch (dmiKey) {
      case 'subAction':
        const { allowAction, allow, subActionTypes } = item ?? {}
        if ((allowAction || allow) && subActionTypes) {
          subActionTypes.sort()
          subActionTypes.forEach(sat => {
            midm.push({ item, dmiKey, sat, optionType: optionTypes.dataManagement })
          })
        }
        break;
      default:
        midm.push({ item, dmiKey, optionType: optionTypes.dataManagement })
    }
  })
  return { midm }
}

/** returns the valid menuItems */
const appUserDirectOptions = (pageNavOptions, uivi) => {

  const mis = []
  const misi = []

  // loop all the itemActionTypes
  if (pageNavOptions.appUserDirect) {

    // loog the itemActionTypes
    _.each(itemActionTypes, (mat) => {
      if (pageNavOptions.appUserDirect[mat]) {

        const iat = pageNavOptions.appUserDirect[mat]
        const { actionCaption } = iat ?? {}

        switch (mat) {

          case itemActionTypes.dataLinking:
          case itemActionTypes.ticketing:
            misi.push(menuItemItem(mat, uivi, null, null, null, null, optionTypes.appUserItems))
            break;

          default:
            misi.push(menuItemItem(mat, actionCaption, null, null, null, null, optionTypes.appUserItems))
        }
      }
    })
  }

  return { mis, misi }

}

const appUserActionOptions = (appUserAccess, appUserActionItems, itemData, appUserCollection) => {
  const misu = []
  const misui = []
  const { appUserSessionKey } = appUserAccess ?? {}
  if (itemData && appUserActionItems && appUserActionItems.length > 0) {
    _.each(appUserActionItems, (actionItem) => {
      const ad = itemData[appUserCollection]
      const checked = _.includes(ad, appUserSessionKey) ? true : false
      actionItem.checked = checked
      misui.push({ actionItem, optionType: optionTypes.userItems })
    })
  }
  return { misu, misui }
}

const otherOptions = (data, dataManagement) => {
  const mido = []
  if (data && Object.keys(data).length > 0) {
    mido.push(menuItemItem(itemActionTypes.dataActions, null, null, true, null, null, optionTypes.other))
  }

  if (dataManagement && Object.keys(dataManagement).length > 0) {
    mido.push(menuItemItem(itemActionTypes.dataManagement, null, null, true, null, null, optionTypes.other))
  }
  return { mido }
}

const menuItemItem = (pat, sat, _arrayIndex, _forData, subItems, directClick, optionType) => {
  return { pat, sat, _arrayIndex, _forData, subItems, directClick, optionType }
}
