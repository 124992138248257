import { gEnums } from '../../enums/globalEnums';
import { fs_get_data } from '../../firestoreData/appData/fsAppData';
import { createRefPath } from '../../firestoreData/appData/appRefPaths';
import { fs_delete_doc, fs_set_doc } from '../../firestoreData/appData/fsData';
// import { fsfn_sendVideoConferenceNotification } from '../../functions/fbVideoConferenceNotification';

// const MEETING_NUMBER = '85857735422';
// const MEETING_PASSWORD = '174641';

const MEETING_NUMBER = '81318222546'
const MEETING_PASSWORD = '673532'

const zoomApiKey = 'RdGiw4y1TPqu8jnZbu_m0g'
const zoomApiSecret = '6r8WvCPPuLoR7weGl5hP7cxn4rL6CweHJrCE'

const ZoomMtg = {}

const rts = {
  handleCancelVc: 'handleCancelVc',
  handleInitVc: 'handleInitVc',
  // handleSetVc: 'handleSetVc',
  handleSetVideoConferences: 'handleSetVideoConferences',
  handleStartVc: 'handleStartVc',
  handleJoinVc: 'handleJoinVc',
  handleUpdateCurrentData: 'handleUpdateCurrentData',
  handleGetVideoConferences: 'handleGetVideoConferences',
  confirmZoom: 'confirmZoom',
}

export const _collectionName = '_videoConferences'

export const videoConferenceReducer = (state, action) => {

  const {
    appUserAccess,
    collectionRef,
    meetingRooms,
    page_fns,
    pathViews,
    roomName,
  } = state

  const { dispatch, type } = action
  const { getViRef } = page_fns ?? {}

  // eslint-disable-next-line
  const { handleSetVideoConferences, confirmZoom } = videoConferenceHandlers(dispatch)

  switch (type) {

    case rts.handleGetVideoConferences:
      if (pathViews.events) {
        const baseRef = getViRef(_collectionName)
        const { refPath } = baseRef
        const _refPath = createRefPath([_collectionName], refPath)
        fs_get_data({ refPath: _refPath }).then(vcs => {
          const mrs = { ...meetingRooms }
          if (vcs) {
            Object.keys(vcs).forEach(key => {
              mrs[key] = vcs[key]
            })
          }
          handleSetVideoConferences(vcs, mrs)
        })
        return { ...state, collectionRef: _refPath }
      }
      return { ...state, videoConferences: {} }

    // sets the data after the handleGetVideoConferences call
    case rts.handleSetVideoConferences:
      return { ...state, videoConferences: action.vcs, meetingRooms: action.mrs }

    // on jitsiMeeting useEffect once
    case rts.handleInitVc:

      // data_current, appUserAccess, view, viewKey 
      const { view, viewKey } = action
      const _roomName = view + '-' + viewKey
      let _jitsiConfig;
      let _zoomConfig;

      switch (action.videoConferenceType) {
        case gEnums.videoConferenceTypes.jitsi:
          _jitsiConfig = {
            roomName: _roomName,
            displayName: action.appUserAccess.displayName,
            parentNode: 'jitsi-container',
          }
          break;

        case gEnums.videoConferenceTypes.zoom:
          _zoomConfig = {
            apiKey: zoomApiKey,
            apiSecret: zoomApiSecret,
            meetingNumber: MEETING_NUMBER,
            password: MEETING_PASSWORD,
            roomName: _roomName,
            userName: 'Phil O',
            leaveUrl: 'http://localhost:3001/',
            role: 0
          }
          break;
        default:
          break;
      }
      console.log('_zoomConfig', _zoomConfig)
      return { ...state, ...action, jitsiConfig: _jitsiConfig, zoomConfig: _zoomConfig, roomName: _roomName }

    // updates the vc form data
    case rts.handleUpdateCurrentData:
      return {
        ...state,
        data_current: action.data_current,
        isReady: true
      }

    case rts.handleStartVc:
      if (roomName) {
        const _startRef = createRefPath([roomName], collectionRef)
        const startData = {
          meetingInProgress: true,
          meetingAuthId: appUserAccess.uid,
          participants: [
            appUserAccess.uid
          ]
        }
        fs_set_doc(_startRef, startData).then(res1 => {
          const _meetingRooms = { ...meetingRooms }
          _meetingRooms[roomName] = { meetingInProgress: true }
          // launchMeeting(zoomConfig, confirmZoom)
          // if (notifyStatus && notifyStatus.notifyRecips) {
          //   fsfn_sendVideoConferenceNotification(action.pathViews, recipients, 'Video Conference', 'You have been invited to a video conference', pathName + '/videoConference')
          // }
          return { ...state, meetingRooms: _meetingRooms }
        })
      }
      return { ...state }

    case rts.confirmZoom:
      return { ...state }

    case rts.handleJoinVc:
      return { ...state }

    case rts.handleCancelVc:
      const _deletRef = createRefPath([roomName], collectionRef)
      fs_delete_doc(_deletRef)
      return { ...state }

    default:
      return { ...state }
  }
}

export const videoConferenceInitialState = (initState) => {
  return { ...initState, meetingRooms: {} }
};

export const videoConferenceHandlers = (dispatch) => {
  return {
    handleGetVideoConferences: (pathViews) => { dispatch({ type: rts.handleGetVideoConferences, dispatch, pathViews }) },
    handleCancelVc: () => { dispatch({ type: rts.handleCancelVc }) },
    handleInitVc: (videoConferenceType, data_current, appUserAccess, view, viewKey) => { dispatch({ type: rts.handleInitVc, dispatch, videoConferenceType, data_current, appUserAccess, view, viewKey }) },
    handleSetVideoConferences: (vcs, mrs) => { dispatch({ type: rts.handleSetVideoConferences, vcs, mrs }) },
    handleStartVc: (recipients, notifyStatus) => { dispatch({ type: rts.handleStartVc, recipients, notifyStatus }) },
    handleJoinVc: () => { dispatch({ type: rts.handleJoinVc }) },
    handleUpdateCurrentData: (data_current, ready) => { dispatch({ type: rts.handleUpdateCurrentData, data_current, isReady: ready }) },
    confirmZoom: () => { dispatch({ type: rts.confirmZoom }) },
  }
}

// eslint-disable-next-line
const launchMeeting = (zoomConfig, callback) => {
  // generateSignature should only be used in development
  ZoomMtg.generateSignature({
    apiKey: zoomConfig.apiKey,
    apiSecret: zoomConfig.apiSecret,
    role: zoomConfig.role,
    meetingNumber: zoomConfig.meetingNumber,
    success(res) {
      ZoomMtg.init({
        leaveUrl: zoomConfig.leaveUrl,
        screenShare: true,
        success() {
          ZoomMtg.join({
            meetingNumber: zoomConfig.meetingNumber,
            userName: zoomConfig.userName,
            signature: res.result,
            apiKey: zoomConfig.apiKey,
            // userEmail: 'rwilson@meetingevolution.net',
            password: zoomConfig.password,
            success() {
              ZoomMtg.showMeetingHeader()
              callback()
              // ZoomMtg.getAttendeeslist({ success: setAttendees, error: setError })
            },
            error(res) { console.error(res); }
          }
          )
        },
        error(res) {
          console.error(res);
        }
      });
    }
  });
}