import _ from 'lodash';
import { getFirstObject } from '../../common/convert';
import { ammendDate } from '../../common/dateFormatting';
import { sortObject } from '../../common/sorting';
import { _ignoreKeys } from '../../common/dataFix';

export const convertSnapshotToRealtimeData = (value, viewItem) => {

  const { propSections, props } = viewItem ?? {}

  let sProps;
  if (props) { sProps = sortObject(props, 'position') }

  const headers = []
  let allValues = {}

  if (!sProps) {
    value.forEach(doc => {
      const data = doc.data()
      Object.keys(data).forEach(key => {
        if (!headers.includes(key) && key !== 'id') { headers.push(key) }
      })
    })
  } else {
    if (props && propSections) {
      const pss = sortObject(propSections, 'position')
      Object.keys(pss).forEach(psKey => {
        const prs = _.filter(props, { 'propSection': psKey })
        if (prs.length > 0) {
          const x = _.sortBy(prs, 'position')
          if (x.length > 0) {
            x.forEach(item => {
              if (item.key !== 'id') { headers.push(item.key) }
            })
          }
        }
      })
    } else {
      Object.keys(sProps).forEach(hKey => {
        if (hKey !== 'id') { headers.push(hKey) }
      })
    }
  }

  value.forEach(doc => {
    const data = doc.data()
    allValues[doc.id] = {}
    headers.forEach(header => {
      if (data[header]) {
        allValues[doc.id][header] = data[header]
      } else {
        allValues[doc.id][header] = ""
      }
    })
  })
  return allValues
}

export const convertJsonToSheetsData = (json) => {

  const headers = []
  let allValues = []

  json.forEach(data => {
    Object.keys(data).forEach(key => {
      if (!headers.includes(key)) { headers.push(key) }
    })
  })

  json.forEach(data => {
    const dataA = []
    headers.forEach(header => {
      if (header === 'id') {
        dataA.push(data.id)
      } else {
        if (data[header]) {
          dataA.push(data[header])
        } else {
          dataA.push("")
        }
      }
    })
    allValues.push(dataA)
  })
  allValues.unshift(headers)
  return allValues
}

/**
 * 
 * @param {object} value 
 * @param {object} viewItem  
 * @returns 
 */
export const convertSnapshotToSheetsData = (value, viewItem) => {

  const { props } = viewItem ?? {}
  let sProps;
  if (props) { sProps = sortObject(props, 'position') }

  let headers = []
  let allValues = []

  if (!sProps) {
    value.forEach(doc => {
      const data = doc.data()
      Object.keys(data).forEach(key => {
        if (!headers.includes(key)) { headers.push(key) }
      })
    })
  } else {
    headers.push('id')
    Object.keys(sProps).forEach(hKey => {
      if (!_ignoreKeys.includes(hKey)) {
        headers.push(hKey)
      }
    })
  }

  headers = headers.sort()
  // array_move(headers, headers.indexOf('bio'), headers.length - 1)

  if (_.isArray(value)) {
    value.forEach(doc => {
      const dataA = []
      const data = doc.data()
      headers.forEach(header => {
        if (header === 'id') {
          dataA.push(doc.id)
        } else {
          if (data[header]) {
            dataA.push(data[header])
          } else {
            dataA.push("")
          }
        }
      })
      allValues.push(dataA)
    })
  }

  headers.forEach((header, index) => {
    for (var i = 0; i < 20; i++) {
      if (header.endsWith('_' + i)) {
        header = header.replace('_' + i, '')
      }
    }
    headers[index] = _.startCase(header)
  })

  allValues.unshift(headers)
  return allValues
}




// Selling Eclipse Accumulator & BGA IUL II
// Selling Eclipse Accumulator & BGA IUL II

/** Ammends the data for each item in the data collection
 * @note Adds an `id` property to each item from the `documentId`
 * @opts uivi, isCombinedData, ignoreId, returnFirstObject
 */
export const convertSnapshot = (value, singleDataItem, opts) => {

  const { uivi, isCombinedData, ignoreId, returnFirstObject, warnMultipleObjects, warnMultipleObjectIndex, returnKey, timeZone, cbProps } = opts ?? {}
  const { empty, query: isQuery, _document: isDocument } = value ?? {}
  const { _refPath } = cbProps ?? {}

  let allDocs = {}

  try {
    if (!singleDataItem || isQuery) {
      if (!empty && value) {
        value.forEach(doc => {
          ammendThisData(allDocs, doc, uivi, isCombinedData, ignoreId, timeZone)
        })
      }
    } else {
      if (!empty && isDocument) {
        ammendThisData(allDocs, value, uivi, isCombinedData, ignoreId, timeZone)
      }
    }
  } catch (error) {
    console.error('error', error)
  }

  if (returnFirstObject) {
    let docIndex = 0;
    let _hasMultipleObjects;
    if (warnMultipleObjects && allDocs && Object.keys(allDocs).length > 1) {
      docIndex = warnMultipleObjectIndex ? 1 : 0
      _hasMultipleObjects = true
      console.log('-------------------------------------')
      console.log('_refPath', _refPath)
      console.log('warnMultipleObjects', 'allDocs', Object.keys(allDocs))
      console.log('-------------------------------------')
      Object.keys(allDocs).forEach((dk, index) => {
        const d = allDocs[dk]
        const { parentKeys } = d
        const { isDuplicate } = parentKeys ?? {}
        if (!isDuplicate) {
          docIndex = index
        } else {
          console.log('bad object', dk)
        }
        // if (dk === 'yZZSe2d0i1f6SaZ3eCwJ') {
        //   docIndex = index
        // } else {
        //   console.log('bad object', dk)
        // }
        // if (!parentKeys.teams) {
        //   docIndex = index
        // }
      })
    }
    const { item, fk } = getFirstObject(allDocs, docIndex)
    if (returnKey) {
      return { item, fk }
    } else {
      if (warnMultipleObjectIndex) {
        return { item, _hasMultipleObjects }
      } else {
        return item
      }
    }
  } else {
    return allDocs
  }
}

/**
 * add a KEY and updates the DATES to each of the data items
 * @param {object} data 
 */
export const modifyParentKeyData = (data) => {
  Object.keys(data).forEach(key => {
    const d = data[key]
    if (d) {
      d.key = key
      ammendDate(d)
    }
  })
}

/** Ammends the data
 * @note Adds an `id` property to each item from the `documentId`
 */
const ammendThisData = (o, doc, uivi, isCombinedData, ignoreId, timeZone) => {
  if (doc.data) {
    const d = doc.data()
    if (d) {
      ammendDate(d, timeZone)
      o[doc.id] = { ...d }
      if (!ignoreId) {
        o[doc.id].id = doc.id
        o[doc.id]._itemKey = doc.id
      }
      if (isCombinedData) { o[doc.id].asVi = uivi }
    }
  }
}

