import React, { useContext } from 'react';
import { Header, Icon, Label, Menu } from 'semantic-ui-react';
import { AppSettingsContext } from '../../../cnr/contexts/AppSettingsContext';
import { FrameworkContext } from '../../../cnr/contexts/FrameworkContent';
import { SettingsMenuContext } from '../../../cnr/contexts/SettingsMenuContext';
import { iconColorTypes } from '../../../enums/settingsIconTypes';
import { _settingsFs, rootSettings_collection_sandbox } from '../../actions/getSettings';

const backIcon = {
  color: 'red', // 'grey'
  icon: 'delete', // 'arrow left'
}

/**
 * 
 * @param {object} props (sideMenu, iconLeft, iconLeftColor, title, rightMenuItemProps)
 * @returns The `Menu` for the header of the `SettingsArea`
 */
const SettingsAreaMenuHeader = (props) => {

  const { isGlobal, sideMenu, iconLeft, iconLeftColor, title, subCaption, rightMenuItemProps } = props

  // appSettingsContext
  const appSettingsContext = useContext(AppSettingsContext)
  const { appSettings_state, appSettings_handlers } = appSettingsContext ?? {}
  const { sandboxOn } = appSettings_state ?? {}

  const settingsMenuContext = useContext(SettingsMenuContext)
  const { settingsMenu_handlers } = settingsMenuContext ?? {}
  const { handleShowQuickSearch } = settingsMenu_handlers ?? {}

  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_handlers } = frameworkContext ?? {}
  const { handleShow_appSidebar } = framework_handlers ?? {}

  const { icon: iconRight, handleUpdateAll, handleAllowGroupMenuSort, show: showRight, allowGroupMenuSort } = rightMenuItemProps ?? {}
  const { fullMobileWidth } = sideMenu ?? {}

  const rsc = sandboxOn ? rootSettings_collection_sandbox : _settingsFs.root
  const lblColor = sandboxOn ? iconColorTypes.settingsUser : iconColorTypes.settingsLive

  const handleSettingsOn = () => {
    if (isGlobal) {
      appSettings_handlers.handleToggle_settingsLeftOn()
    } else {
      appSettings_handlers.handleToggle_settingsRightOn()
    }
  }

  const subHeader = () => <Header.Subheader>
    <Label size={'mini'} color={lblColor} style={{ margin: 0, marginTop: 6 }}>
      {rsc + ': ' + subCaption}
    </Label>
  </Header.Subheader>

  const menuItem_right = () => <Menu.Menu position='right'>
    {fullMobileWidth && <Menu.Item onClick={handleShow_appSidebar}>
      <Icon name={'delete'} />
    </Menu.Item>}
    {showRight && <Menu.Item onClick={handleUpdateAll}>
      <Icon name={iconRight} />
    </Menu.Item>}
  </Menu.Menu>

  const menuItem_edit = () => <Menu.Menu className={'samhe'} position='right'>
    <Menu.Item onClick={handleAllowGroupMenuSort}>
      <Icon name={'sort'} color={allowGroupMenuSort ? 'blue' : null} />
    </Menu.Item>
  </Menu.Menu>

  return <Menu borderless inverted className={'samh'}>
    <Menu.Item>
      <Icon.Group onClick={handleSettingsOn}>
        <Icon name={iconLeft} size={'large'} color={iconLeftColor} />
        <Icon corner='bottom left' name={backIcon.icon} size='tiny' color={backIcon.color} />
      </Icon.Group>
    </Menu.Item>
    <Menu.Item>
      <Header inverted>
        <Header.Content>
          {title}
          {subHeader()}
        </Header.Content>
      </Header>
    </Menu.Item>
    {(showRight || fullMobileWidth) && menuItem_right()}
    {allowGroupMenuSort && menuItem_edit()}
    {/* <Menu.Item onClick={handleShowQuickSearch}>
      <Icon name={'caret down'} size={'large'} color={iconLeftColor} />
    </Menu.Item> */}
  </Menu>
}

export default SettingsAreaMenuHeader