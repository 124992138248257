import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Grid, Sidebar } from 'semantic-ui-react';
import ActionProvider from '../cnr/contexts/ActionContext';
import { FrameworkContext } from '../cnr/contexts/FrameworkContent';
import HelpProvider from '../cnr/contexts/HelpContext';
import { ParentContext } from '../cnr/contexts/ParentContext';
import { frameworkLeftTypes, frameworkRightTypes } from '../cnr/reducers/FrameworkReducer';
import { googleModeTypes } from '../cnr/reducers/GoogleSheetsReducer';
import { g_cns } from '../common/cns';
import SuspenseDimmer from '../components/alerts/SuspenseDimmer';
import DataSidebar from '../components/swipers/DataSidebar';
import AppDashboard from '../components/viewers/AppDashboard';
import { mobileDeviceTypes } from '../devices';
import { allPageTypes } from '../enums/allPageTypes';
import { clickOriginTypes, gEnums } from '../enums/globalEnums';
import AppContent from './AppContent';
import PageFrameworkLeft from './PageFrameworkLeft';
import PageFrameworkRight from './PageFrameworkRight';
import SettingsAppLeftMenu from './SettingsAppLeftMenu';
import SettingsAppRightMenu from './SettingsAppRightMenu';
import { PageItemOptionsMenu } from '../sidebars/PageItemOptionsMenu';
import FullPageContent from '../components/layout/FullPageContent';
import { getAppUserAccess } from '../auth/appUserAccessPermissions';

const AppSettingsSidebarWithProvider = React.lazy(() => import('../viewSettings/AppSettingsSidebar'));
const UIDisplayEditor = React.lazy(() => import('../pageItem/modification/UIDisplayEditor'));

const useItemWizard = true
const wrapGap = 12
const wrapBorder = wrapGap / 2

const _useGridDarkMode = true

const pageWidths = {
  desktop: {
    sides: 4,
    middle: 8
  },
  mobile: {
    sides: 4,
    middle: 8
  }
}


/** Controls the settings for the app
 * @param appContent  
 * @description Creates a three column grid. 
 * The left column contains the global settings. 
 * The right column contains the view/Wi settings. 
 * The middle column contains the app/mobileContent, which is passed in via props.
 */
const PageFramework = () => {

  // parentContext
  const parentContext = useContext(ParentContext);
  const { states, handlers, fns, settings, navigate } = parentContext ?? {}
  const { appUser_state, appSettings_state, page_state, paps_state, googleSheets_state, storage_state } = states
  const { appSettings_handlers } = handlers ?? {}
  const { appUser_fns, page_fns } = fns
  const { homeSettings } = settings ?? {}
  const { global: global_home } = homeSettings ?? {}
  const { settingsOptions: settingsOptions_home } = global_home ?? {}
  const { handleShow_appDashboard: handleShow_appDashboard_as } = appSettings_handlers ?? {}
  const { pushSimple } = page_fns ?? {}
  const { appUrls: homeAppUrls } = global_home ?? {}
  const { appUrls: appUrlz } = homeAppUrls ?? {}
  const { globalFiles } = storage_state ?? {}

  // papsContext 
  const { lastRootPath, pathViews, view } = paps_state ?? {}

  const { appUsers, notificationToken } = appUser_state ?? {}
  const appUserAccess = getAppUserAccess(appUsers)

  const _viewPermissionType_settings = appUser_fns.validate_settingsAuth(appUserAccess, true)
  const _viewPermissionType_data = appUser_fns.validate_dataAuth(null, true)

  const allowSideSettings = _viewPermissionType_settings === gEnums.viewPermissionTypes.allow
  const allowSideData = _viewPermissionType_data === gEnums.viewPermissionTypes.allow

  // appSettingsContext 
  const { showAppDashboard: showAppDashboard_as, desktopOn, appSettingsOn, globalSettingsOn, settingsLeftOn, settingsRightOn, currentDevice, settingsPreviewOn, tempDataSource } = appSettings_state ?? {}
  const { msettings } = currentDevice ?? {}
  const { googleModeType } = googleSheets_state ?? {}


  // frameworkContext
  const frameworkContext = useContext(FrameworkContext);
  const { framework_state, framework_handlers } = frameworkContext ?? {}
  const { iconClickProps, appDimensions, showAppDashboard, selectedViewKey, frameworkRightType, frameworkLeftType, appViewMode, desktopMode, desktopMobileOn, mediaDeviceType, modalContent, fullMode, isMobileDevice } = framework_state ?? {}
  const { handleShow_fullPage, handleShow_frameworkRight, handleShow_frameworkLeft, handleShow_appDashboard, handleShow_appBottomSidebar } = framework_handlers ?? {}
  let { width, height } = appDimensions ?? {}

  const _showAppDashboard = showAppDashboard || showAppDashboard_as

  const handleCancel = () => {
    if (handleShow_appDashboard) {
      handleShow_appDashboard()
    } else if (handleShow_appDashboard_as) {
      handleShow_appDashboard_as()
    }
  }

  // pageContext 
  const { pageSettings } = page_state ?? {}
  const { aps_global, aps_viewItems } = pageSettings ?? {}
  const { clientOptions, eventOptions, appDataSource, appSurvey, display: display_global } = aps_global ?? {}
  const { appDataSourceType } = appDataSource ?? {}
  const { useDarkMode } = settingsOptions_home ?? {}
  const { fontFamily, useFontFamily } = display_global ?? {}
  const { surveyUrl } = appSurvey ?? {}

  const _appOptions = { ...clientOptions, ...eventOptions }
  let { weatherZip, weatherCountryCode, weatherCode } = _appOptions ?? {}

  const _dataFromGs = appDataSourceType === gEnums.dataSourceTypes.googleSpreadSheet
  const _dataFromGsTemp = tempDataSource === gEnums.dataSourceTypes.googleSpreadSheet

  // const [showFullPage, setShowFullPage] = useState()
  const [frtLeftOn, setFrtLeftOn] = useState()
  const [frtRightOn, setFrtRightOn] = useState()

  const pageNavHome = {
    page: null,
    path: null,
    icon: null,
  }

  const getPagePath = (name) => {
    let pagePath = ''
    let done = false
    const spl = lastRootPath.split('/')
    spl.forEach(sp => {
      if (sp) {
        if (sp === name) {
          pagePath += sp + '/'
          pagePath += pathViews[name]
          done = true
        } else if (!done) {
          pagePath += sp + '/'
        }
      }
    })

    if (pathViews) {
      Object.keys(pathViews).forEach(pv => {
        if (pv !== view) {
          if (aps_viewItems && aps_viewItems[pv]) {
            const { pageNavigation } = aps_viewItems[pv]
            const { showPageHome, pageHomeIcon } = pageNavigation ?? {}
            if (showPageHome) {
              pageNavHome.path = getPagePath(pv)
              pageNavHome.page = pv
              pageNavHome.icon = pageHomeIcon
            }
          }
        }
      })
    }


    return pagePath
  }

  useEffect(() => {
    if (iconClickProps) {
      const { item, itemKey } = iconClickProps ?? {}
      const { key, itemClick } = item ?? {}
      const { clickToType, pdf: pdf_name } = itemClick ?? {}

      switch (clickToType) {
        case gEnums.clickToTypes.pdf:
          const { pdf } = globalFiles ?? {}
          if (pdf && pdf[pdf_name]) {
            const _currentPdf = pdf[pdf_name]
            if (_currentPdf && _currentPdf.urls && _currentPdf.urls.full) {
              window.open(_currentPdf.urls.full, '_blank')
            }
          }
          break;

        default:
          switch (key) {
            case 'slideout':
              framework_handlers.handleShow_appSidebar()
              break;

            case allPageTypes.navOptions:
              handleShow_appBottomSidebar(fpw_navOptions())
              // setShowPageItemOptions(true)
              break;

            case allPageTypes.media:
              pushSimple(item)
              break;

            case allPageTypes.appProfilesManager:
            case allPageTypes.appTicketing:
            case allPageTypes.appUserRequests:
            case allPageTypes.appUsersManager:
            case allPageTypes.chat:
            case allPageTypes.conversations:
            case allPageTypes.credentialScan:
            case allPageTypes.favorites:
            case allPageTypes.gallery:
            case allPageTypes.imageMapping:
            case allPageTypes.meetingsManager:
            case allPageTypes.notes:
            case allPageTypes.notifications:
            case allPageTypes.notificationsManager:
            case allPageTypes.qAndA:
            case allPageTypes.qAndAManager:
            case allPageTypes.surveyManager:
            case allPageTypes.ticketingManager:
            case allPageTypes.ticketingScan:
            case allPageTypes.topicsManager:
            case allPageTypes.voting:
            case allPageTypes.votingManager:
              if (useItemWizard) {
                handleShow_fullPage(key)
              } else {
                pushSimple(item, itemKey)
              }
              break;

            case allPageTypes.survey:
              window.open(surveyUrl, '_blank')
              break;

            case allPageTypes.weather:
              const weatherLink = page_fns.getLink(appUrlz, 'weather', isMobileDevice)

              if (weatherLink) {
                let url = weatherLink
                url = weatherCode ? url.replace('ZIPCODE', weatherCode) : url.replace('ZIPCODE', weatherZip)
                if (weatherCode) {
                  url = url.replace(':4:US', '')
                } else {
                  if (weatherCountryCode) { url = url.replace('US', weatherCountryCode) }
                }
                window.open(url, '_blank')
              }
              break;
            default:
              if (item.key === pageNavHome.page) {
                navigate(pageNavHome.path)
              } else {
                pushSimple(item)
              }
          }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconClickProps]);

  useEffect(() => {

    switch (frameworkLeftType) {
      case frameworkLeftTypes.appProfilesManager:
      case frameworkLeftTypes.appData:
      case frameworkLeftTypes.appUsersManager:
      case frameworkLeftTypes.appUsersPresense:
      case frameworkLeftTypes.bulkImageUpload:
      case frameworkLeftTypes.createNewEvent:
      case frameworkLeftTypes.recreateNewEvent:
      case allPageTypes.credentialScan:
      case allPageTypes.meetingsManager:
      case allPageTypes.notificationsManager:
      case allPageTypes.qAndAManager:
      case allPageTypes.surveyManager:
      case allPageTypes.ticketingManager:
      case allPageTypes.votingManager:
        setFrtLeftOn(true)
        break;
      default:
        setFrtLeftOn(false)
      // nothing

    }

    switch (frameworkRightType) {
      case frameworkRightTypes.dataConstraints:
      case frameworkRightTypes.dataModification:
      case frameworkRightTypes.display:
      case frameworkRightTypes.filters:
      case frameworkRightTypes.google:
      case frameworkRightTypes.pageDataAdd:
      case frameworkRightTypes.pageDataEdit:
      case frameworkRightTypes.pageLinkData:
      case frameworkRightTypes.pageMedia:
      case frameworkRightTypes.props:
      case frameworkRightTypes.sorting:
        setFrtRightOn(true)
        break;
      default:
        setFrtRightOn(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frameworkLeftType, frameworkRightType]);

  const _appSettingsOn = appSettingsOn || settingsLeftOn || settingsRightOn || frameworkRightType && frameworkRightType === frameworkRightTypes.pageSettingsFull || globalSettingsOn ? true : false
  const desktopView = desktopMode || desktopOn ? true : false

  // local
  const authPermissionType = appUser_fns.validate_settingsAuth(appUserAccess)

  const allowSettings = authPermissionType === gEnums.viewPermissionTypes.allow

  const mdsx = []

  if (msettings && msettings.w) { width = msettings.w }
  if (msettings && msettings.h) { height = msettings.h }

  const mds = mobileDeviceTypes()

  mds.forEach(item => {
    const z = { ...item }
    delete z.msettings
    mdsx.push(z)
  })

  const segWrapperStyle = (desktopView) ? { height: '100%', width: '100%' } : { height: height + wrapGap, width: width + wrapGap, border: wrapBorder + 'px solid' }
  const segStyle = (desktopView) ? { height: '100%', width: '100%', border: 0, padding: 0 } : { height: height, width: width, border: 0, padding: 0 }
  if (useFontFamily && fontFamily) { segStyle.fontFamily = fontFamily }

  let showLeftSettings = false

  const settingsOnly = appViewMode === gEnums.appViewModes.settings

  switch (view) {
    case 'home':
    case 'clients':
    case 'events':
      showLeftSettings = true
      break;
    default:
      break;
  }


  const fpw_navOptions = () => <PageItemOptionsMenu />

  /**
   * 
   * @param {boolean} isGlobal 
   * @returns an 
   */
  const appSidebar_settings = (isGlobal) => <Suspense fallback={<SuspenseDimmer origin={'PageFramework'} />}>
    <AppSettingsSidebarWithProvider isGlobal={isGlobal} showLeftSettings={showLeftSettings} />
  </Suspense>


  const displayEditor = (contents) => {
    return <div className={'display-editor'}>
      {contents}
      <UIDisplayEditor segStyle={segStyle} />
    </div>
  }


  const handleShowWizard = () => handleShow_fullPage()

  const smartPhoneAppContent = () => {

    const _showEditor = false

    let cn = desktopView ? g_cns.settings_app_desktop_wrap : g_cns.settings_app_mobile_wrap

    if (settingsPreviewOn) {
      cn += ' settings-preview global'
    } else {
      cn += ' not-live'
    }

    if (fullMode) { cn += ' fullMode' }

    // if (showFullPage) {
    //   return <FullPageContent fullPageType={showFullPage} handleCancel={handleShowWizard} clickOriginType={clickOriginTypes.iconGrid} />
    // }

    if (desktopMobileOn) {
      return <AppContent />
    } else {
      if (_showEditor) {
        return displayEditor(<div className={cn} style={segWrapperStyle}>
          <div style={segStyle} id={'page-framework'} className='page-content-container'>
            <AppContent />
            {modalContent && modalContent}
          </div>
        </div>)
      } else {
        return <div className={cn}>
          <div style={segStyle} id={'page-framework'} className='page-content-container'>
            <AppContent />
            {modalContent && modalContent}
          </div>
        </div>
      }
    }
  }

  const BlankColumn = (isGlobal, w) => {
    if (isGlobal) {
      if (mediaDeviceType !== gEnums.mediaDeviceTypes.mobile) {
        return <Grid.Column width={w} className={'side'} ><SettingsAppLeftMenu allowSideSettings={allowSideSettings} /></Grid.Column>
      }
    } else {
      if (mediaDeviceType !== gEnums.mediaDeviceTypes.mobile && (allowSideSettings || allowSideData)) {
        return <Grid.Column width={w} className={'side'} ><SettingsAppRightMenu allowSideSettings={allowSideSettings} allowSideData={allowSideData} /></Grid.Column>
      } else {
        return <Grid.Column width={w} className={'side'} ></Grid.Column>
      }
    }
  }

  const sidebar_left = (visible) => <DataSidebar
    direction={'left'}
    visible={visible}
    content={visible && <PageFrameworkLeft />}
    onHide={handleShow_frameworkLeft}
  />

  const sidebar_right = (visible) => <DataSidebar
    direction={'right'}
    visible={visible}
    content={visible && <PageFrameworkRight showLeftSettings={showLeftSettings} />}
    onHide={handleShow_frameworkRight}
  />

  const SettingsColumn = (isGlobal, w) => <Grid.Column width={w} className={'side'}>
    {_appSettingsOn && appSidebar_settings(isGlobal)}
  </Grid.Column>

  /** Column container for either the global or page/view settings. 
   * @description only available for uses with permissions
   */
  const SideColumn = (isGlobal, w) => {
    if (!allowSettings) { return BlankColumn(isGlobal, w) }
    if (!isGlobal) {
      switch (view) {
        case allPageTypes.appProfile:
          return BlankColumn(false, w)
        default:
        // nothing
      }
    }
    return SettingsColumn(isGlobal, w)
  }

  /**
   * 
   * @returns smartPhoneAppContent
   */
  const appContainer = () => {

    let cn_sa_containter = g_cns.settings_app_container
    let cn_sa_content = g_cns.settings_app_content

    if (desktopView) { cn_sa_content += ' dt-mode' }

    if (googleModeType && googleModeType !== googleModeTypes.none) { cn_sa_containter += ' google ' + googleModeType }
    if (_dataFromGsTemp) { cn_sa_containter += ' gds temp' }
    if (_dataFromGs) { cn_sa_containter += ' gds' }

    return <div className={cn_sa_containter} >
      <div className={cn_sa_content} >
        {smartPhoneAppContent()}
      </div>
    </div>
  }

  /** @returns Grid.Column containing the header and the actual smart phone app. This appContent is being passed in */
  const AppColumn = (w) => <Grid.Column className={'app'} width={w} >
    {appContainer()}
  </Grid.Column>


  let cn_framework = g_cns.framwork_container
  if (useDarkMode) { cn_framework += ' settings-dark-mode' }
  if (settingsOnly) { cn_framework += ' so' }

  /**
   * @returns a grid with 3 columns.
   * @leftColumn - App Settings
   * @centerColumn - App Content
   * @rightColumn - Page Settings
   */
  const gridContent = () => {

    let cngSettings = g_cns.app_settings_grid

    if (desktopMobileOn) { cngSettings += ' dmo' }

    if (settingsOnly) {
      return appSidebar_settings(true)
    } else if (mediaDeviceType === gEnums.mediaDeviceTypes.mobile) {
      return <AppContent />
    } else {
      if (desktopMobileOn) {
        if (appSettingsOn) {
          return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings}>
            {SideColumn(true, pageWidths.desktop.sides)}
            {AppColumn(pageWidths.desktop.middle)}
            {SideColumn(false, pageWidths.desktop.sides)}
          </Grid>
        } else {
          return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings}>
            {BlankColumn(true, pageWidths.desktop.sides)}
            {AppColumn(pageWidths.desktop.middle)}
            {BlankColumn(false, pageWidths.desktop.sides)}
          </Grid>
        }
      } else {
        if (desktopView) {
          if (settingsLeftOn) {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={2} className={cngSettings}>
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(12)}
            </Grid>
          } else if (settingsRightOn) {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={2} className={cngSettings}>
              {AppColumn(12)}
              {SideColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings}>
              {BlankColumn(true, 2)}
              {AppColumn(12)}
              {BlankColumn(false, 2)}
            </Grid>
          }
        } else {
          // mobile view
          if (appSettingsOn) {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {SideColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else if (globalSettingsOn) {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {BlankColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else if (settingsRightOn) {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
              {BlankColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {SideColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else if (settingsLeftOn) {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
              {SideColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {BlankColumn(false, pageWidths.desktop.sides)}
            </Grid>
          } else if (frameworkRightType) {
            switch (frameworkRightType) {
              case frameworkRightTypes.pageSettingsFull:
                return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
                  {BlankColumn(true, pageWidths.desktop.sides)}
                  {AppColumn(pageWidths.desktop.middle)}
                  {SideColumn(false, pageWidths.desktop.sides)}
                </Grid>

              case frameworkRightTypes.dataConstraints:
              case frameworkRightTypes.dataModification:
              case frameworkRightTypes.display:
              case frameworkRightTypes.filters:
              case frameworkRightTypes.google:
              case frameworkRightTypes.pageDataAdd:
              case frameworkRightTypes.pageDataEdit:
              case frameworkRightTypes.pageLinkData:
              case frameworkRightTypes.pageMedia:
              case frameworkRightTypes.pageSettings:
              case frameworkRightTypes.props:
              case frameworkRightTypes.sorting:
              case frameworkRightTypes.staticViews:
                return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
                  {BlankColumn(true, pageWidths.desktop.sides)}
                  {AppColumn(pageWidths.desktop.middle)}
                  {SideColumn(false, pageWidths.desktop.sides)}
                </Grid>
            }
          } else {
            return <Grid inverted={_useGridDarkMode && useDarkMode} columns={3} className={cngSettings} >
              {BlankColumn(true, pageWidths.desktop.sides)}
              {AppColumn(pageWidths.desktop.middle)}
              {BlankColumn(false, pageWidths.desktop.sides)}
            </Grid>
          }
        }
      }
    }
  }

  const appViewerContent = () => {
    if (_showAppDashboard) {
      return <ActionProvider><AppDashboard handleCancel={handleCancel} selectedDataMode={selectedViewKey} /></ActionProvider>
    }
  }

  const sidebar = () => <Sidebar.Pushable style={{ overflow: 'hidden' }}>
    {sidebar_left(frtLeftOn)}
    {sidebar_right(frtRightOn)}
    <Sidebar.Pusher dimmed={frtRightOn} className={'h100'}>
      {appViewerContent()}
      {gridContent()}
    </Sidebar.Pusher>
  </Sidebar.Pushable>

  const pfContent = () => <div className={cn_framework}>
    {sidebar()}
  </div>

  return <HelpProvider>
    {pfContent()}
  </HelpProvider>

}

export default PageFramework 